import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PopupModal from 'common/PopupModal/PopupModal';
import {ReactComponent as UnselectAllIcon} from 'assets/UnselectAllIcon.svg';
import { 
  CLEAR_ALL_SELECTED_GEOMETRY,
  CLEAR_SEARCH_GEOMETRY,
  SET_ACTIVE_TOOL } from '../MapDucks';
import { widgetTypes } from '../MapWidgetLayer';
import { getReducer } from 'utils/ReduxUtils';
import { LanternTools } from 'app/constants';
import { isToolAvailable } from 'utils/IdentityManagerUtils';

export const UnselectAllWidget = ({
  mapType,
  className,
  sketchWidget,
  unselectWidget,
  measureWidget,
  currentTool,
  activeSlideoutTool,
  isSlideoutOpen,
  unselectSearchGeometry
}:any) => {
  const dispatch = useDispatch()
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const mapServiceReducerName = getReducer(mapType)
  const {
    selectedLines,
    selectedPoints,
    selectedGrids,
    searchGrids,
    searchLines,
    searchPoints} = useSelector((state:any) => state[mapServiceReducerName])
  const roleId = useSelector((state:any) => state.LoginReducer.selectedUserRole)
  const preselectedSurveyReviewLines:Array<any> = useSelector((state:any) => state[mapServiceReducerName].surveyReviewLines)
  const preselectedSurveyReviewPoints:Array<any> = useSelector((state:any) => state[mapServiceReducerName].surveyReviewPoints)
  const preselectedSurveyReviewGrids:Array<any> = useSelector((state:any) => state[mapServiceReducerName].surveyReviewGrids)

  function handleUnselectClick() {
    dispatch({type: SET_ACTIVE_TOOL + mapType, tool: widgetTypes.unselectAll})
    measureWidget?.clear()
    unselectWidget?.cancel()
    sketchWidget?.cancel()
  }

  useEffect(() => {
    setIsDisabled(
      unselectSearchGeometry ?
        // check if searh geometry is cleared
        (!searchLines.length && !searchPoints.length && !searchGrids.length) :
        // check if there are changes to the "preselected" features which would either be empty or filled based on reducer
        ((selectedLines.length + searchLines.length) === preselectedSurveyReviewLines.length &&
          (selectedPoints.length + searchPoints.length) === preselectedSurveyReviewPoints.length &&
          (selectedGrids.length + searchGrids.length) === preselectedSurveyReviewGrids.length))
  }, [searchGrids, searchLines, searchPoints,
      selectedLines, selectedPoints, selectedGrids,
      preselectedSurveyReviewLines.length, preselectedSurveyReviewPoints.length,
      preselectedSurveyReviewGrids.length])

  if (isToolAvailable(LanternTools.SELECT,roleId)) {
    return(
      <div
        id = 'unselectAll-container'
        className = {`${className} widget-btn`}
        title = 'Unselect All'
      >
        <PopupModal
          title = "Unselect All?"
          confirmBtn = 'Confirm'
          cancelBtn = 'Cancel'
          message = {`Do you want to unselect all the ${unselectSearchGeometry? "search ":""}geometry on the map?`}
          confirmClick = {() => {
            unselectSearchGeometry ?
              dispatch({type: CLEAR_SEARCH_GEOMETRY + mapType}) :
              dispatch({
                type:CLEAR_ALL_SELECTED_GEOMETRY + mapType,
                preselectedSurveyReviewLines,
                preselectedSurveyReviewPoints,
                preselectedSurveyReviewGrids
              })
            dispatch({type: SET_ACTIVE_TOOL + mapType, tool: widgetTypes.move})
          }}
          onClose = {() => dispatch({type: SET_ACTIVE_TOOL + mapType, tool: widgetTypes.move})}
          isOpenButtonDisabled = {isDisabled}
          openBtn = {
          <UnselectAllIcon
            className = {`unSelect-btn ${
              (currentTool === widgetTypes.unselectAll) ?
              "btn-active":
              ""}`}
            onClick = {() => handleUnselectClick() }
          /> }
          openButtonColor = 'white'
        />
      </div>
    )
  }
  else {
    return null
  }
}
