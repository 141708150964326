import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from "react-router-dom";
import MapIcon from '@material-ui/icons/Public';
import './NavWidget.scss'

export interface INavWidgetProp {
  shouldShow: boolean
}

const NavWidget: React.FunctionComponent<INavWidgetProp> = (props) => {
  const currentMapService = useSelector((state:any) => state.LoginReducer.selectedMapService.decodedLabel)
  const { isSideNavOpen } = useSelector((state:any) => state.SettingsReducer)

  return(
    <div className={
      "setting-tab" +
      (props.shouldShow ? '' : ' hidden') +
      (isSideNavOpen ? '' : ' collapsed')
    }>
      <div className="setting-container">
        <div className="title">Loaded Map Service</div>
        <div className="current-map-service">
          <MapIcon className="map-icon"/>
          <span className="map-service-name" title={currentMapService}>{currentMapService}</span>
          <NavLink
            to = "/MapServices"
            className = "switch-button"
          >
            Switch
          </NavLink>
        </div>
      </div>
    </div>
  )
}

export default NavWidget
