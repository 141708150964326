import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "common/Button/Button";
import DragableItem from "./DraggableItem";
import { SET_ACTIVE_TOOL, setSelectedBaseMap } from "common/Map/MapDucks";
import { widgetTypes } from "common/Map/MapWidgetLayer";
import { ITableOfContentsLayersTab } from "./TableOfContentsWidget";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Container, Styledul } from "./list.styles";

export const BasemapLayersTab = ({ mapType }: ITableOfContentsLayersTab) => {
  const dispatch = useDispatch();
  const basemapsList = useSelector(
    (state: any) => state.MapPageMapReducer.basemapsList
  );

  function handleOnDragEnd(result: any) {
    if (!result.destination) return;
    const items = Array.from(basemapsList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    dispatch(
      setSelectedBaseMap({
        basemapsList: items,
      })
    );
  }

  return (
    <div className="tab-contents">
      <Container className="layer-table">
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="mapLayer">
            {(provided) => (
              <Styledul {...provided.droppableProps} ref={provided.innerRef}>
                {basemapsList.map((basemap: any, index: any) => (
                  <DragableItem {...{ basemap, index, basemapsList }} />
                ))}
                {provided.placeholder}
              </Styledul>
            )}
          </Droppable>
        </DragDropContext>
      </Container>
    </div>
  );
};
