import { each } from "lodash";

/**
 * Summary: Handlers server errors and throws them
 *
 * @param args response from get calls can be an array of calls or a single
 */
export const axiosErrorHandler = (args:any) => {
  //handles an array of calls
  if(args.length !== undefined){
    for (var index = 0; index < args.length; index++){
      if(args[index]?.data?.error) {
        // Will probably create a function to sort through all the error codes later on
        throw args[index].data.error
      }
    }
  }
  //handles a single call
  else {
    if(args?.data?.error) {
      throw args.data.error
    }
  }
}

/**
 * Summary: returns formatted request body for the post req,
 * which eventually translated to Post FormData Object
 *
 * @param config config for the current request
 */

export const formatRequestBody = (config:any) =>{
  if(!config || !config.data){
    return;
  }
  let data = '';
  if(config?.data){
    let index = 0;
    const keys = Object.keys(config.data);
    each( config.data, ( val, key ) => { 
      index++;
      data+= `${key}=${val}`
      if(index < keys.length && index > 0){
        data+= '&';
      }
    });
  }
  return data;
}