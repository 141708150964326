import React from 'react';
import {useSelector} from 'react-redux';
import { store } from "app/store";
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import GreyLine from "../GreyLine/GreyLine";
import AsyncDowndrop from '../Dropdown/AsyncDropdown/AsyncDropdown';
import Button from 'common/Button/Button';
import { surveyInitiationMapType, surveyAreaMapType } from 'common/Map/MapDucks';
import { selectOperations } from 'common/Map/SelectFeatures';
import './GridSearch.scss';
import { requiredAsterisk } from 'utils/FieldsUtils';
import { getLayerOrTableById } from 'utils/GridUtils';

interface IGridSearchProp {
  selectedGrids: Array<any>
  handleChange: Function
  mapType: string
  asyncOptionCallback?:any
  id?: string
  isEditable?: boolean
}

const GridSearch: React.FunctionComponent<IGridSearchProp> = (props) => {
  const polygonTypes = useSelector((state:any) => state.SettingsReducer.layers.GridLayers)
  const surveyAreaMapReducer = useSelector((state:any) => state.SurveyAreaMapReducer)
  const surveyInitiationMapReducer = useSelector((state:any) => state.SurveyInitiationMapReducer)
  let settingsLayer = store.getState().SettingsReducer.settings.Layers;
  let activePolygonType = ''
  if (props.mapType === surveyInitiationMapType) {
    activePolygonType = surveyInitiationMapReducer.activeGridType
  } else if (props.mapType === surveyAreaMapType) {
    activePolygonType = surveyAreaMapReducer.activeGridType
  }

  var empty =
      <div>
        <GreyLine/>
        <GreyLine/>
        <GreyLine/>
        <GreyLine/>
        <GreyLine/>
        <p className="empty-message">
          There are no grids added to this survey area.<br/>
          {
            props.isEditable &&
            <span>Use the toolbar to add at least one.</span>
          }
        </p>
      </div>

  var gridName = polygonTypes[0].GridNameFieldName
  var selectedGridList : Array<JSX.Element> = [];
  var selectedGridGlobalIds: Array<string> = [];
  let gridLayerName = polygonTypes[0].GridNameFieldName

  polygonTypes.forEach((type:any) => {
    if (activePolygonType === type.Name) {
      // set the variable to find the name for the corresponding grid type
      gridName = type.GridNameFieldName
      gridLayerName = type.Name
    }
  })

  const asyncDowndrop =
    <AsyncDowndrop
      id = {props.id}
      showSelected={false}
      AsyncOptionCallBack={props.asyncOptionCallback}
      handleChange={(option:any)=>{
        //Add object id field properties to grid before adding to geometry
        const grid = {...option.value}
        const gridLayerId = settingsLayer.find((e:any) => e.Name === gridLayerName).Id
        const gridObjectIdField = getLayerOrTableById(gridLayerId)?.FeatureClass?.OidFieldName || "OBJECTID"
        grid.objectIdField = gridObjectIdField
        props.handleChange(grid, selectOperations.add)
      }
    }
      placeholder='Search Grid'
      noSearchMessage='Begin typing to search for grids'
    />

  const removeIcon = (gridGlobalId: string, grid: any) => {
    return (
      <RemoveCircleOutlineIcon
        className="remove-icon"
        onClick={() => {
          // remove from list of seen GlobalIds
          let index = selectedGridGlobalIds.indexOf(gridGlobalId)
          if (index > -1) selectedGridGlobalIds.splice(index, 1)

          props.handleChange(grid, selectOperations.remove)
        }}
      />
    )
  }

  //finding and matching the grid layerID to the selected grid
  //then adding that as a grid property which is later read by the MapDucks
  props.selectedGrids.forEach((grid, i) => {
    const gridLayerId = settingsLayer.find((e:any) => e.Name === gridLayerName).Id
    const gridObjectIdField = getLayerOrTableById(gridLayerId)?.FeatureClass?.OidFieldName || "OBJECTID"
    const gridObjectId = grid.attributes[gridObjectIdField]
    
    if (!selectedGridGlobalIds.includes(gridObjectId)) {
      // add to the list of seen GlobalIds
      selectedGridGlobalIds.push(gridObjectId)

      selectedGridList.push(
        <div className="selected-grid" key={i}>
          <span>
            {grid.attributes?grid.attributes[gridName]:grid}
          </span>
          {
            props.isEditable &&
            removeIcon(gridObjectId, grid)
          }
        </div>
      )
    }
  })

  return (
    <div className="grid-search">
      <div className="input-title">
        Grids
        {
          props.isEditable &&
          requiredAsterisk()
        }
      </div>
      {
        props.isEditable &&
        asyncDowndrop
      }
      {
        props.isEditable &&
        props.selectedGrids.length > 0 &&
        <div className="remove-all-btn-container">
            <Button
                boxColor="blue"
                size="s"
                onClick={()=> {
                  props.selectedGrids.forEach((grid) => {
                    props.handleChange(grid, selectOperations.remove)
                  })
                }}
            >
                Remove All
            </Button>
        </div>
      }
      <div className="survey-grids">
        {props.selectedGrids.length?
          selectedGridList:
          empty
        }
      </div>
    </div>
  )
}

export default GridSearch;
