import axios from "axios";
import qs from "qs";
import { store } from "app/store";

import { history, urlConfig } from "index";
import {
  getMapServices,
  getMapUrlsConfig,
  setSecurityConfig,
  getUserValidate,
  getUserRoles,
  getSurveyWorktype,
  getFolders,
  getArcGISToken
} from "api";
import { registerToken } from "utils/IdentityManagerUtils";

export const RESET_LOGIN = "RESET_LOGIN";
export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const MAP_SERVICES_FOLDER_LOADING = "MAP_SERVICES_FOLDER_LOADING";
export const MAP_SERVICES_FOLDER_SUCCESS = "MAP_SERVICES_FOLDER_SUCCESS";
export const MAP_SERVICES_FOLDER_ERROR = "MAP_SERVICES_FOLDER_ERROR";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const MAP_SERVICES_LOADING = "MAP_SERVICES_LOADING";
export const MAP_SERVICES_SUCCESS = "MAP_SERVICES_SUCCESS";
export const MAP_SERVICES_ERROR = "MAP_SERVICES_ERROR";
export const SET_SELECTED_MAP_FOLDER = "SET_SELECTED_MAP_FOLDER";
export const SET_SELECTED_MAP_SERVICES = "SET_SELECTED_MAP_SERVICES";
export const SET_USER_ROLES = "SET_USER_ROLES";
export const SET_SELECTED_USER_ROLE = "SET_SELECTED_USER_ROLE";
export const SET_NO_ACTIVE_USER_ROLE = "SET_NO_ACTIVE_USER_ROLE";
export const SET_SELECT_ROLE_ERROR = "SET_SELECT_ROLE_ERROR";
export const SET_SURVEY_WORK_TYPES = "SET_SURVEY_WORK_TYPES";

/**
 * Summary: Authenticates user and runs fuction to get map services
 * @param userName user's username in plain-text
 * @param password user's password in plain-text
 */

 export const logIn =
   (account: any, instance: any, loginRequest: any) =>
   async (dispatch: any) => {
     dispatch({ type: LOGIN_LOADING });
     dispatch({ type: RESET_LOGIN });
     try {
       const token = await instance.acquireTokenSilent({
         ...loginRequest,
         account,
       });
       localStorage.setItem("token", token.accessToken);
       registerToken();
       const encodedEmail = account.username.replace("@", "%40").toLowerCase();
       const userResponse = await getUserValidate(encodedEmail);
       const userRolesResponse = await getUserRoles(userResponse);
       const selectedUserRoleId = userRolesResponse.data[0].roleId;

       dispatch({
         type: LOGIN_SUCCESS,
         loggedInUser: userResponse.data.username,
         userId: userResponse.data.id,
         userRoles: userRolesResponse.data,
         selectedUserRole: selectedUserRoleId,
       });
       history.push("/MapServices");
     } catch (error) {
       console.log(error);
       dispatch({ type: LOGIN_ERROR });
     }
   };

/**
 * Summary: Gets default map services and folders available to user
 * @param userName user's userName
 * @param folderName Map Folder that is currently selected
 */
export const getAvailableMapServices =
  (userName: string, folderName = "") =>
  async (dispatch: any) => {
    dispatch({ type: MAP_SERVICES_LOADING });
    let requestBody = {
      post: "thundercats70542564",
    };
    
    try {
      await Promise.all([
        getMapUrlsConfig(),
        getMapServices(folderName),
        setSecurityConfig(requestBody),
      ]).then(
        axios.spread(function (baseUrl, mapServices, mapServicePermissions) {
          let mapServiceUrls: { [index: string]: any } = {};
          let shownMapServiceUrls = [];
          let userRoles = [];
          let userPermissions: any;
          let adminMapServicePermissions =
            mapServicePermissions.data.Permissions.mapServicePermissions;
          let rootUrl = baseUrl.data[0].url;
          let { services } = mapServices.data;

          //append root url to available services to get base map service url
          //only run if services are returned from above calls
          if (services) {
            services.forEach((service: any) => {
              const encodedMapserviceName = service.name.replace("/", "%2F");
              if (service.name in mapServiceUrls) {
                if (service.type === "MapServer") {
                  mapServiceUrls[
                    service.name
                  ].url = `${rootUrl}/${service.name}/${service.type}`;
                } else if (service.type === "FeatureServer") {
                  mapServiceUrls[
                    service.name
                  ].featureUrl = `${rootUrl}/${service.name}/${service.type}`;
                }
              } else {
                if (service.type === "MapServer") {
                  mapServiceUrls[service.name] = {
                    label: encodedMapserviceName,
                    url: `${rootUrl}/${service.name}/${service.type}`,
                  };
                } else if (service.type === "FeatureServer") {
                  mapServiceUrls[service.name] = {
                    label: encodedMapserviceName,
                    featureUrl: `${rootUrl}/${service.name}/${service.type}`,
                  };
                }
              }
            });
            //find user permissions
            userPermissions = mapServicePermissions.data.Users.find(
              (elm: any) => elm.UserName === userName
            );
            //get list of user's name and value for their user roles
            for (let i = 0; i < userPermissions.RoleIds.length; i++) {
              for (
                let j = 0;
                j < mapServicePermissions.data.Roles.length;
                j++
              ) {
                if (
                  userPermissions.RoleIds[i] ===
                  mapServicePermissions.data.Roles[j].RoleId
                ) {
                  userRoles.push({
                    RoleId: mapServicePermissions.data.Roles[j].RoleId,
                    RoleName: mapServicePermissions.data.Roles[j].RoleName,
                  });
                  break;
                }
              }
            }

            // filters out allowed map service by user permission from master list using urls
            for (let i = 0; i < adminMapServicePermissions.length; i++) {
              //check if roleIds in map permission exist in the user permissions
              if (
                adminMapServicePermissions[i].roleIds.some((r: any) =>
                  userPermissions.RoleIds.includes(r)
                )
              ) {
                for (let service in mapServiceUrls) {
                  if (
                    mapServiceUrls[service].url ===
                    adminMapServicePermissions[i].mapServiceURL
                  ) {
                    shownMapServiceUrls.push(mapServiceUrls[service]);
                  }
                }
              }
            }
          }
          dispatch({
            type: MAP_SERVICES_SUCCESS,
            loggedInUser: userName,
            rootUrl: rootUrl,
            mapServices: shownMapServiceUrls,
            userRoles: userRoles,
          });
        })
      );
    } catch (e) {}
  };

/**
 * Summary: Fetches all folders in map server. Will not go deeper than 1 level
 */
export const getAvailableFolders = () => async (dispatch: any) => {
  dispatch({
    type: MAP_SERVICES_FOLDER_LOADING,
  });
  // const tkn= localStorage.getItem('ArcGISToken');
  getFolders().then((mapServices:any) => {
    let { folders } = mapServices.data;
    folders = folders.map((folder: any) => {
      return { label: folder, value: folder, indent: true };
    });
    folders = [{ label: "Default", value: "" }, ...folders];

    dispatch({
      type: MAP_SERVICES_FOLDER_SUCCESS,
      mapServerFolder: folders,
    });
  });
};

export const getSurveyWorkTypes = () => async (dispatch: any) => {
  const mapServiceName = store.getState().LoginReducer.selectedMapService.label;

  const resp = await getSurveyWorktype(mapServiceName);
  const SWTs = resp.data;
  const SWTNames = SWTs.map((swt: any) => {
    return swt.name;
  });
  dispatch({
    type: SET_SURVEY_WORK_TYPES,
    surveyWorkTypes: SWTNames.sort(),
  });

  // axios
  //   .get(
  //     `${urlConfig.reactLanternNewServerUrl}/v1/surveyworktype/${mapServiceName}`
  //   )
  //   .then((res) => {
  //     const SWTs = res.data;
  //     const SWTNames = SWTs.map((swt: any) => {
  //       return swt.name;
  //     });
  //     dispatch({
  //       type: SET_SURVEY_WORK_TYPES,
  //       surveyWorkTypes: SWTNames.sort(),
  //     });
  //   });
};

const initialState: any = {
  loginSuccess: "",
  selectedMapFolder: "",
  mapServerFolder: [],
  mapServices: [{}],
  loggedInUser: "",
  error: {},
  selectedMapService: {
    url: "",
    label: "",
  },
};

export default function reducer(state = initialState, action: any) {
  switch (action.type) {
    case RESET_LOGIN:
      return {
        ...state,
        loginSuccess: "",
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loginSuccess: true,
        loggedInUser: action.loggedInUser,
        userId: action.userId,
        userRoles: action.userRoles,
        selectedUserRole: action.selectedUserRole,
        token: action.token,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        loginSuccess: false,
      };
    case MAP_SERVICES_SUCCESS:
      return {
        ...state,
        mapServices: action.mapServices,
        rootUrl: action.rootUrl,
        userRoles: action.userRoles,
      };
    case MAP_SERVICES_FOLDER_SUCCESS:
      return {
        ...state,
        mapServerFolder: action.mapServerFolder,
      };
    case SET_SELECTED_MAP_FOLDER:
      return {
        ...state,
        selectedMapFolder: action.selectedMapFolder,
      };
    case SET_SELECTED_MAP_SERVICES:
      return {
        ...state,
        selectedMapService: {
          url: action.url,
          featureUrl: action.featureUrl,
          label: action.label,
          decodedLabel: action.decodedLabel,
        },
      };

    case SET_SELECTED_USER_ROLE:
      return {
        ...state,
        selectedUserRole: action.userRole,
      };
    case SET_NO_ACTIVE_USER_ROLE:
      return {
        ...state,
        noActiveUserRole: true,
      };
    case SET_SELECT_ROLE_ERROR:
      return {
        ...state,
        selectRoleError: action.selectRoleError,
      };
    case SET_SURVEY_WORK_TYPES:
      return {
        ...state,
        surveyWorkTypes: action.surveyWorkTypes,
      };
    default:
      return state;
  }
}
