import { store } from "app/store"
import { 
  getTableIndexByName, 
  getGridIdFieldByName, 
  getGridLayerFieldValue, 
  gridLayerFields 
} from "utils/GridUtils"
import { generateID } from "utils/FieldsUtils"
import { getCurrentDate } from "utils/DateUtils"
import { isUndefined } from "lodash"

//TODO: make these fields dynamic if possible
const nonDynamicFields = ["SurveyAreaID", "CreatedOn", "CreatedBy", "ModifiedOn", "ModifiedBy"]
const nonDynamicPolygonFields = ["SurveyPolygonID", "PolygonName", "PolygonID", "PolygonType", "SurveyAreaName"]

/**
 * summary: generate the data for posting a new survey area in the correct format
 *
 * @param data all the information for the survey area to be stored
 */
export const populateCreatePostPayload = (data:any, surveyAreaID:any) => {
  let grids = data[0]
  let details = data[1]
  let gridName = ''
  let surveyAreaTableID = 0
  let surveyPolygonTableID = 0
  let currentUser = ''
  let currentDate = getCurrentDate(true, 'iso')
  let gridLayers = []

  gridLayers = store.getState().SettingsReducer.layers.GridLayers
  // get grid name
  gridLayers.forEach((type:any) => {
    if (details.PolygonType === type.Name) {
      gridName = type.GridNameFieldName
    }
  })

  currentUser = store.getState().LoginReducer.loggedInUser
  surveyAreaTableID = getTableIndexByName(
    store.getState().SettingsReducer.globalAliases.LanternSurveyArea)

  surveyPolygonTableID = getTableIndexByName(
    store.getState().SettingsReducer.globalAliases.LanternSurveyAreaPolygon)

  let surveyAreaFields = store.getState().SettingsReducer.settings.Tables[surveyAreaTableID].Fields
  let surveyPolygonFields = store.getState().SettingsReducer.settings.Tables[surveyPolygonTableID].Fields
  // get survey polygon id
  let polygonID = generateID()
  // get information for each selected grid
  let gridInfo = ``

  grids.forEach((grid:any) => {
    let polygonAttributes = ``
    surveyPolygonFields.forEach((field:any) => {
      if (!nonDynamicFields.includes(field.Name) && !nonDynamicPolygonFields.includes(field.Name)) {
        let value = grid.attributes[field.Name]
        polygonAttributes += `"${field.Name}":"${isUndefined(value)? "": value}",`
      }
    })
    gridInfo += `
    ,
    {
      "objectId": -1,
      "attributes": {
        ${polygonAttributes}
        "SURVEYPOLYGONID": "{${polygonID}}",
        "POLYGONNAME": "${grid.attributes[gridName]}",
        "POLYGONID": "${grid.attributes[getGridIdFieldByName(details.PolygonType)]}",
        "POLYGONTYPE": "${details.PolygonType}",
        "SURVEYAREAID": "{${surveyAreaID}}",
        "SURVEYAREANAME": "${details.SurveyAreaName}",
        "CREATEDON": "${currentDate}",
        "CREATEDBY": "${currentUser}",
        "MODIFIEDON": "${currentDate}",
        "MODIFIEDBY": "${currentUser}"
      },
      "type": 1,
      "oldAttributes": {},
      "tableId": ${surveyPolygonTableID},
      "hasBeenUndone": false
    }`
  })

  let surveyAreaAttributes = ``
  surveyAreaFields.forEach((field:any) => {
    if (!nonDynamicFields.includes(field.Name)) {
      let value = details[field.Name] ? details[field.Name] : ''
      surveyAreaAttributes += `"${field.Name}":"${value}",`
    }
  })

  return(
    `{
      "objectId": -1,
      "attributes": {
        ${surveyAreaAttributes}
        "SURVEYAREAID": "{${surveyAreaID}}",
        "CREATEDON": "${currentDate}",
        "CREATEDBY": "${currentUser}",
        "MODIFIEDON": "${currentDate}",
        "MODIFIEDBY": "${currentUser}"
      },
      "type": 1,
      "oldAttributes": {},
      "tableId":${surveyAreaTableID},
      "hasBeenUndone": false
    }
    ${gridInfo}`
  )
}

/**
 * Summary: generate data to allow for editing existing survey areas
 *
 */
export const populateEditPostPayload = (data:any) => {
  let details = data[1]
  let gridName = getGridLayerFieldValue(details.PolygonType, gridLayerFields.GridNameFieldName)
  let currentDate = getCurrentDate(true, 'iso')
  let currentUser = store.getState().LoginReducer.loggedInUser
  let surveyAreaTableID = getTableIndexByName(
    store.getState().SettingsReducer.globalAliases.LanternSurveyArea)
  let surveyPolygonTableID = getTableIndexByName(
    store.getState().SettingsReducer.globalAliases.LanternSurveyAreaPolygon)
  let surveyAreaObjectID =store.getState().SurveyAreaReducer.surveyAreaData.OBJECTID
  let surveyAreaID = store.getState().SurveyAreaReducer.surveyAreaData.SurveyAreaID
  let selectedGrids = store.getState().SurveyAreaMapReducer.selectedGrids
  let uneditedSurveyAreaGridData = store.getState().SurveyAreaReducer.uneditedSurveyAreaGridData

  let surveyAreaFields = store.getState().SettingsReducer.settings.Tables[surveyAreaTableID].Fields
  let surveyPolygonFields = store.getState().SettingsReducer.settings.Tables[surveyPolygonTableID].Fields

  //Find all non matching grids to be added and removed from DB
  let addedGrids = selectedGrids.filter(function(selectedGrid:any) {
    return !uneditedSurveyAreaGridData.some(function(uneditedGrid:any) {
      return selectedGrid.attributes.OBJECTID === uneditedGrid.attributes.OBJECTID
    });
  })

  let removedGrids = uneditedSurveyAreaGridData.filter(function(uneditedGrid:any) {
    return !selectedGrids.some(function(selectedGrid:any) {
      return selectedGrid.attributes.OBJECTID === uneditedGrid.attributes.OBJECTID
    });
  })

  let addedGridsPayload = ``
  let removedGridsPayload = ``
  let polygonID = generateID()

  addedGrids.forEach((grid:any) => {
    let polygonAttributes = ``
    surveyPolygonFields.forEach((field:any) => {
      if (!nonDynamicFields.includes(field.Name) && !nonDynamicPolygonFields.includes(field.Name)) {
        let value = grid.attributes[field.Name]
        polygonAttributes += `"${field.Name}":"${value}",`
      }
    })
    addedGridsPayload += `
    {
      "objectId": -1,
      "attributes": {
        ${polygonAttributes}
        "SURVEYPOLYGONID": "{${polygonID}}",
        "POLYGONNAME": "${grid.attributes[gridName]}",
        "POLYGONID": "${grid.attributes[getGridIdFieldByName(details.PolygonType)]}",
        "POLYGONTYPE": "${details.PolygonType}",
        "SURVEYAREAID": "${surveyAreaID}",
        "SURVEYAREANAME": "${details.SurveyAreaName}",
        "CREATEDON": "${currentDate}",
        "CREATEDBY": "${currentUser}",
        "MODIFIEDON": "${currentDate}",
        "MODIFIEDBY": "${currentUser}"
      },
      "type": 1,
      "oldAttributes": {},
      "tableId": ${surveyPolygonTableID},
      "hasBeenUndone": false
    },`
  })

  removedGrids.forEach((grid:any)=>{
    removedGridsPayload += `
    {
      "objectId": ${grid.oldObjectID},
      "type": 1,
      "oldAttributes": {},
      "tableId": ${surveyPolygonTableID},
      "hasBeenUndone": false
    },`
  })

  let surveyAreaAttributes = ``
  surveyAreaFields.forEach((field:any) => {
    if (!nonDynamicFields.includes(field.Name)) {
      let value = details[field.Name] ? details[field.Name] : ''
      surveyAreaAttributes += `"${field.Name}":"${value}",`
    }
  })

  return(
   [ `{
      "objectId": ${surveyAreaObjectID},
      "attributes": {
        ${surveyAreaAttributes}
        "MODIFIEDON": "${currentDate}",
        "MODIFIEDBY": "${currentUser}"
      },
      "type": 1,
      "oldAttributes": {},
      "tableId":${surveyAreaTableID},
      "hasBeenUndone": false
    }`,
    addedGridsPayload,removedGridsPayload]
  )
}
