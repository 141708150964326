import React, { useRef, useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import { useDispatch } from "react-redux";
import { getAttachmentRelationships, uploadAttachments } from './AttachmentsDucks';
import { getLayerOrTableByName } from "utils/GridUtils";

interface IAttachments {
  attachmentsEditsProps: {
    featureId: number, objectId: number, fieldName: string
  },
  layerName: string | undefined
}

const Attachments = ({ attachmentsEditsProps, layerName = '' } : IAttachments) => {
  const hiddenFileInput = useRef<any>(null);
  const dispatch = useDispatch();

  const getLayersHyperlinkPath = async () => {
    // make API call to get attachment relationships info
    const attachmentrelationships = await getAttachmentRelationships();
    const layer = getLayerOrTableByName(layerName)
    
    // use the layer's id and match it with the ids for relationships from the api
    const relationship = attachmentrelationships.find(
      (relationship: any) => relationship.originId === layer.Id
    );
    return relationship?.hyperlinkPath;
  }

  const handleButtonClick = (event: any) => {
    event.preventDefault();
    hiddenFileInput?.current?.click();
  };

  const handleChange = async (event: any) => {
    // Read the file
    const fileUploaded = event.target.files[0];
    const fileReader = new FileReader();

    if (fileReader && fileUploaded) {
      // const layerHyperlinkPath = 'https://gis02.jana.local/attachments';
      const layerHyperlinkPath = await getLayersHyperlinkPath();

      fileReader.readAsDataURL(fileUploaded);
      fileReader.onload = function () {
        if (fileReader.result) {
          const base64result = (fileReader.result as string).split(",")[1];
          const fileData = {
            file: base64result,
            fileName: fileUploaded.name,
            hyperlinkPath: `${layerHyperlinkPath}/${fileUploaded.name}`,
          };
          const stringObjectId = attachmentsEditsProps.objectId.toString();
          dispatch(uploadAttachments(fileData, stringObjectId));
        }
      };
    }
  };

  return (
    <FormControl>
      <div>
        <button type="button" onClick={handleButtonClick}>
          Attach image
        </button>
        <input
          ref={hiddenFileInput}
          onChange={handleChange}
          style={{ display: "none" }}
          type="file"
          id="attachments"
          name="attachments"
          accept="image/png, image/jpeg"
        ></input>
      </div>
    </FormControl>
  );
};

export default Attachments;
