import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import axios from 'axios'
import 'typeface-montserrat'
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { ThemeProvider } from '@mui/material/styles';
import { loadModules } from 'esri-loader';

import './web.config'
import './index.scss';
import { store } from './app/store';
import App from './app/AppRouter/AppRouter';
import { initalizeEsri } from 'utils/EsriUtils';
import theme from './muiTheme/theme'


export const history = createBrowserHistory()
declare global {
  interface Window { urlConfig: any, authConfig:any }
}

export const { urlConfig , authConfig} = window

const msalInstance = new PublicClientApplication(authConfig.msalConfig);

/**
 * Summary: deferred mounting of the mock Service Worker
 * @returns
 */
const prepare = () => {
  // Uncomment the following two lines to enable MSW, allowing it to override API calls
  // dev/QA branches will have it commented out, ensure not to merge it uncommented
  // const { worker } = require('./mocks/browser')
  // worker.start({ onUnhandledRequest: 'bypass' })

  return Promise.resolve()
}

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
let initalLoad = new Promise((resolve) => initalizeEsri(resolve))
//Extend timeout for ArcGIS from 1 minute (default) to 5 minutes
loadModules(['esri/config']).then(([esriConfig]) => {
  esriConfig.request.timeout = 300000;
})

prepare().then(() => {
  initalLoad.then(() => {
    ReactDOM.render(
      <Provider store={store}>
        <Router history={history}>
          <MsalProvider instance={msalInstance}>
            <ThemeProvider theme={theme}>
              <App />
            </ThemeProvider>
          </MsalProvider>
        </Router>
      </Provider>,
      document.getElementById("root")
    );
  });
});
