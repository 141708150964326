import {store} from 'app/store';
import { isEmpty, isEqual, isUndefined } from 'lodash';
import { getNameMapping } from './NameMappingUtils';
import { TablePageChangeActions, TablePageChangeProps, TableSortActions } from 'common/Tables/PaginatedTable/TableUtils/TableUtils';
import { dateRangeFiltersValues } from 'common/Tables/PaginatedTable/TableUtils/PredefinedFilterTemplates/DateRanges';
import { transformSingleQuoteChars, escapeSpecialChars, getUrlParam, surveyJobStatus } from './FieldsUtils';
import { surveyJobsFilteredRowsParams } from 'features/SurveyAssignment/SurveyAssignmentUtils';
import { arcgisQueryParams, postArcgisQuery } from 'api';
import { encodeUriAll } from './UrlUtils';
import { axiosErrorHandler } from './AxiosUtils';
import { ERROR, fetchActiveJobSurveyors } from 'features/SurveyAssignment/SurveyAssignmentDucks';

// the possible polygon types that can be set for each grid layer
export enum PolygonTypes {
  grid = "Survey Grid",
  corrosionArea = "Corrosion Area",
  pipelineId = "Pipeline"
}

// the polygon type - this applies to the polygon object regardless of type
//TODO: set this type for all polygons
export interface Polygon {
  attributes: {
    [key: string]: string | number | null;
  },
  geometry: any
}

export enum gridLayerFields {
  Name = "Name",
  GridIdFieldName = "GridIdFieldName",
  GridNameFieldName = "GridNameFieldName"
}

export enum tableNameAttribute {
  DisplayName = "DisplayName",
  FullName = "FullName"
}

/**
 * Summary: get the field values from the GridLayer with the given name
 * @param name name of the grid layer
 * @param field name of the field to get the value of
 */
export const getGridLayerFieldValue = (name: string, field: gridLayerFields) => {
  const gridLayers = store.getState().SettingsReducer.layers.GridLayers

  const gridLayer = gridLayers.find((gridLayer: any) => name === gridLayer.Name)
  return gridLayer ? gridLayer[field] : undefined
}

//returns all layers in map service
export const getAllLayerNames = () => {
  const mapServiceLayers = store.getState().SettingsReducer.mapServiceLayersList
  const layers = store.getState().SettingsReducer.settings.Layers
  return mapServiceLayers.map((layerDetail:any, index:number) => {
    return {label: layerDetail.name, value: layers[index].Name}
  })
}

//returns all tables in map service
export const getAllTableNames = (nameType: tableNameAttribute = tableNameAttribute.DisplayName) => {
  const mapServiceTables = store.getState().SettingsReducer.tableMapServicesList
  const tables = store.getState().SettingsReducer.settings.Tables
  const workAssignmentServiceId = getTableIdByName(
    store.getState().SettingsReducer.globalAliases.LanternSurveyJobWorkAssignments)
  tables.filter((tableDetails: any) => tableDetails.Id !== workAssignmentServiceId)
  return mapServiceTables
    // exclude SurveyJobWorkAssignments
    .filter((tableDetails: any) => tableDetails.id !== workAssignmentServiceId)
    .map((tableDetails:any, index:number) => {
      return {label: tableDetails.name, value: tables[index][nameType]}
    })
}

/** Summary: confirms if the given layer/table name is a layer or not */
export const isLayer = (name: string) => {
  const layers = store.getState().SettingsReducer.settings.Layers;
  return !isUndefined(layers.find((layer: any) => layer.Name === name));
}

//returns all tables and layers in map service
export const getAllTableAndLayerNames = (tableNameType: tableNameAttribute = tableNameAttribute.DisplayName) => {
  const layers = getAllLayerNames()
  const tables = getAllTableNames(tableNameType)
  return tables.concat(layers)
}

// Returns the ids of all the layers in the in the map service (same order as MXD)
export const getAllLayerIds = () => {
  const layers = store.getState().SettingsReducer.mapServiceLayersList
  const AllLayerIds = []

  for (let i = 0; i < layers.length; i++) {
    AllLayerIds.push(i)
  }
  return AllLayerIds.reverse()
}

/**
 * Get the name of layers/tables from the relationships of a layer/table
 * (tables for layers, layers for tables)
 * @param layerName name of layer or table to get the relationships from
 */
export const getMapServiceRelationship = (
  layerName:string, 
  tableNameType: tableNameAttribute = tableNameAttribute.DisplayName
) => {
  const layers = store.getState().SettingsReducer.settings.Layers
  const tables = store.getState().SettingsReducer.settings.Tables
  // OriginId for tables is the equivalent of DestinationId for layers
  const relationshipList = []
  const layerRelationships = []
  const tableRelationships = []

  let foundInLayers = false
  for (let i = 0; i < layers.length; i++ ) {
    if (layers[i].Name === layerName) {
      foundInLayers = true
      for (let j = 0; j < layers[i].FeatureClass.Relationships.length; j++) {
        if (layers[i].Id === layers[i].FeatureClass.Relationships[j].OriginId && layers[i].FeatureClass.Relationships[j].OriginIsLayer) {
          if (layers[i].FeatureClass.Relationships[j].DestinationIsLayer) {
            layerRelationships.push(layers[i].FeatureClass.Relationships[j].DestinationId)
          }
          else {
            tableRelationships.push(layers[i].FeatureClass.Relationships[j].DestinationId)
          }
        }
      }
    }
  }
  if (!foundInLayers) {
    for (let i = 0; i < tables.length; i++ ) {
      if (tables[i][tableNameType] === layerName) {
        for (let j = 0; j < tables[i].Relationships.length; j++) {
          if (tables[i].Id === tables[i].Relationships[j].OriginId && !tables[i].Relationships[j].OriginIsLayer) {
            if (tables[i].Relationships[j].DestinationIsLayer) {
              layerRelationships.push(tables[i].Relationships[j].DestinationId)
            }
            else {
              tableRelationships.push(tables[i].Relationships[j].DestinationId)
            }
          }
        }
      }
    }
  }
  for (let i = 0; i < layers.length; i++) {
    for (let j = 0; j < layerRelationships.length; j++) {
      if (layers[i].Id === layerRelationships[j]) {
        relationshipList.push(layers[i].Name)
      }
    }
  }
  for (let i = 0; i < tables.length; i++) {
    for (let j = 0; j < tableRelationships.length; j++) {
      if (tables[i].Id === tableRelationships[j]) {
        relationshipList.push(tables[i][tableNameType])
      }
    }
  }
  return relationshipList
}

//Gets all the fields from a specfic layer or table
export const getAllFieldsForMapService = (
  layerName:string, 
  tableNameType: tableNameAttribute = tableNameAttribute.DisplayName
) => {
  const layers = store.getState().SettingsReducer.settings.Layers
  const tables = store.getState().SettingsReducer.settings.Tables
  const fields = []

  for(let i = 0; i < layers.length; i++ ) {
    if(layers[i].Name === layerName) {
      for(let j = 0; j < layers[i].Fields.length; j++) {
        fields.push(layers[i].Fields[j].Name)
      }
    }
  }

  for(let i = 0; i < tables.length; i++ ) {
    if(tables[i][tableNameType] === layerName) {
      for(let j = 0; j < tables[i].Fields.length; j++) {
        fields.push(tables[i].Fields[j].Name)
      }
    }
  }

  return fields
}

/**
 * Gets all the fields and data from a specfic layer or table
 * @param layerName name of layer or table
 */
export const getAllFieldDataForMapService = (layerName:string) => {
  const layers = store.getState().SettingsReducer.settings.Layers
  const tables = store.getState().SettingsReducer.settings.Tables
  const fields = []

  for(let i = 0; i < layers.length; i++ ) {
    if(layers[i].Name === layerName) {
      for(let j = 0; j < layers[i].Fields.length; j++) {
          fields.push(layers[i].Fields[j])
      }
    }
  }

  for(let i = 0; i < tables.length; i++ ) {
    if(tables[i].DisplayName === layerName) {
      for(let j = 0; j < tables[i].Fields.length; j++) {
          fields.push(tables[i].Fields[j])
      }
    }
  }

  return fields
}

//Gets all the domain values for a certain field in a certain layer
export const getFieldDomainValues = (layerName:string, fieldName:string) => {
  const layers = store.getState().SettingsReducer.settings.Layers
  const tables = store.getState().SettingsReducer.settings.Tables
  const domainValues = []

  for(let i = 0; i < layers.length; i++ ) {
    if(layers[i].Name === layerName) {
      for(let j = 0; j < layers[i].Fields.length; j++) {
        if(layers[i].Fields[j].Name === fieldName && layers[i].Fields[j].Domain){
          for(let k = 0; k < layers[i].Fields[j].Domain.Name.length; k++){
            domainValues.push({
              label:layers[i].Fields[j].Domain.Names[k],
              value: layers[i].Fields[j].Domain.Values[k]
            })
          }
        }
      }
    }
  }

  for(let i = 0; i < tables.length; i++ ) {
    if(tables[i].DisplayName === layerName) {
      for(let j = 0; j < tables[i].Fields.length; j++) {
        if(tables[i].Fields[j].Name === fieldName && tables[i].Fields[j].Domain){
          for(let k = 0; k < tables[i].Fields[j].Domain.Names.length; k++){
            domainValues.push({
              label:tables[i].Fields[j].Domain.Names[k],
              value: tables[i].Fields[j].Domain.Values[k]
            })
          }
        }
      }
    }
  }

  return domainValues
}

export const getFieldMetadata = (
  layerName:string, 
  fieldName:string, 
  tableNameType: tableNameAttribute = tableNameAttribute.DisplayName
) => {
  const layers = store.getState().SettingsReducer.settings.Layers
  const tables = store.getState().SettingsReducer.settings.Tables

  for(let i = 0; i < layers.length; i++ ) {
    if(layers[i].Name === layerName) {
      for(let j = 0; j < layers[i].Fields.length; j++) {
        if(layers[i].Fields[j].Name === fieldName){
          return layers[i].Fields[j]
        }
      }
    }
  }

  for(let i = 0; i < tables.length; i++ ) {
    if(tables[i][tableNameType] === layerName) {
      for(let j = 0; j < tables[i].Fields.length; j++) {
        if(tables[i].Fields[j].Name === fieldName){
          return tables[i].Fields[j]
        }
      }
    }
  }

  return {}
}

/**
 * Summary: finding single Layer map service ID given a Name
 *
 * @param Name Name of gridlayer attempting to find
 */
export const getLayerMapServiceIdByName = (name:string) => {
  const layers = store.getState().SettingsReducer.mapServiceLayersList
  for (let index = 0; index < layers.length; index++){
    if(layers[index].name === name){
      return layers[index].id
    }
  }
  return NaN
}

/**
 * Summary: finding single Layer/table map service ID given a Name
 *
 * @param Name Name of gridlayer/table attempting to find
 */
export const getLayerOrTableMapServiceIdByName = (name:string) => {
  const layers = store.getState().SettingsReducer.mapServiceLayersList
  for (let index = 0; index < layers.length; index++){
    if(layers[index].name === name){
      return layers[index].id
    }
  }
  const tableList = store.getState().SettingsReducer.tableMapServicesList
  for (let index = 0; index < tableList.length; index++){
    if(tableList[index].name === name){
      return tableList[index].id
    }
  }
  return NaN
}

/**
 * Summary: finding single layer/table feature server ID given a name
 *
 * @param name name of layer/table to get id from
 */
export const getLayerTableFeatureServerIdByName = (name:string) => {
  const layers = store.getState().SettingsReducer.editableLayers
  for (const layer of layers) {
    if (layer.name.trim() === name) {
      return layer.featureServerId;
    }
  }
  const tables = store.getState().SettingsReducer.editableTables
  for (const table of tables) {
    if (table.name.trim() === name) {
      return table.featureServerId;
    }
  }
  //Default to map server ids if not found
  return getLayerOrTableMapServiceIdByName(name)
}

/**
 * Return the props map layer id when given the layer name.
 * @param mapLayersList props.map.layers.items from mapWidgetLayer
 * @param layerName name of the layer in the info tool
 * @returns props map layer id
 */
export function getPropsMapLayerIdByLayerName(
  mapLayersList: any,
  layerName: string
): number {
  const featureId = getLayerTableFeatureServerIdByName(layerName);
  return mapLayersList.findIndex((element: any) => element?.layerId === featureId && element?.title?.includes(layerName));
}

/**
 * Summary: Gets list of Map Service Id's corresponding to
 * feature layers needed to be placed on maps
 *
 * @return Array of map service Ids
 */
export const getGroupMapServiceIdByName = () => {
  const gridLayers = store.getState().SettingsReducer.layers.GridLayers
  const layers = store.getState().SettingsReducer.settings.Layers
  let MapServiceIds = []
  for (let index = 0; index < gridLayers.length;index++){
    let layerIndex = layers.findIndex((layer:any)=>layer.Name === gridLayers[index].Name)
    if (layerIndex !== -1) {
      MapServiceIds.push(layers[layerIndex].Id)
    }
  }
  return MapServiceIds
}

/**
 * Summary: Returns index of the corresponding tableId.
 * Which is typically the order in which the
 * tableId comes out of the rest of the Map and
 *
 * @param globalAlias Alias name of table attempting to find
 */
export const getTableIdByName = (globalAlias:string) => {
  const tableList = store.getState().SettingsReducer.tableMapServicesList
  for (let index = 0; index < tableList.length; index++){
    if(tableList[index].name === globalAlias){
      return tableList[index].id
    }
  }
  return NaN
}

/**
 * Summary: Returns index of the corresponding tableId .
 * Which is typically the order in which the
 * table service comes out of the rest of the Table Ids
 *
 * @param globalAlias Alias name of table attempting to find
 */
export const getTableIndexByName = (
  globalAlias:string, 
  nameType: tableNameAttribute = tableNameAttribute.DisplayName
) => {
  const tableList = store.getState().SettingsReducer.settings.Tables
  for (let index = 0; index < tableList.length; index++){
    if(tableList[index][nameType].replace(/ /g, "") === globalAlias.replace(/ /g, "")){
      return tableList[index].Id
    }
  }
  return NaN
}

/**
 * Summary: get the global ID field name using the layer ID
 * @param layerID id of the feature layer
 */
export const getGlobalIdFieldNameByLayerID = (layerID: number) => {
  const layers = store.getState().SettingsReducer.settings.Layers;
  const tables = store.getState().SettingsReducer.settings.Tables;
  const featureLayer = [...layers, ...tables].find((feature: any) => {return feature.Id === layerID});
  return featureLayer?.FeatureClass?.GlobalIdFieldName || featureLayer?.GlobalIdFieldName
}

/**
 *  Summary : Gets the grid id field name for the given grid layer name
 */
export const getGridIdFieldByName = (gridLayerName:string) => {
  const gridList = store.getState().SettingsReducer.layers.GridLayers
  for (var index = 0; index < gridList.length; index++){
    if(gridList[index].Name === gridLayerName){
      return gridList[index].GridIdFieldName
    }
  }
  return ''
}

/**
 *  Summary: Gets the grid layer name with the given polygon type (the first one that is found)
 */
 export const getNameFieldByPolygonType = (polygonType:string) => {
  const gridList = store.getState().SettingsReducer.layers.GridLayers
  for (var index = 0; index < gridList.length; index++){
    if (gridList[index].polygonType === polygonType){
      return gridList[index].Name
    }
  }
  return ''
}

/**
 *  Summary: Gets the grid layer id with the given polygon type (the first one that is found)
 */
 export const getMapServiceIdByPolygonType = (polygonType:string) => {
  const name = getNameFieldByPolygonType(polygonType)
  let mapServiceId = getLayerMapServiceIdByName(name)
  if (!isNaN(mapServiceId)) {
    return mapServiceId
  }
  mapServiceId = getTableIdByName(name)
  return mapServiceId
}

/**
 *  Summary: Gets the specified grid layer field with the given polygon type (the first one that is found)
 */
 export const getGridLayerFieldByPolygonType = (polygonType:string, field: gridLayerFields) => {
  const layerName = getNameFieldByPolygonType(polygonType)
  return getNameMapping(getGridLayerFieldValue(layerName, field))
}

/**
 * Summary: Gets layers from settings and returns it in dropdown format
 */
export function getLayerOptions() {
  const layers = store.getState().SettingsReducer.settings.Layers
  return layers.map((layer:any) => {
    return {label:layer.Name, value:layer}
  })
}


/**
 * Summary: Gets tables from settings and its name from table map services and returns it in
 * dropdown format
 */
export function getTableOptions() {
  const layers = store.getState().SettingsReducer.settings.Layers
  const tables = store.getState().SettingsReducer.settings.Tables
  const tableMapServices = store.getState().SettingsReducer.tableMapServicesList
  const workAssignmentServiceId = getTableIdByName(
    store.getState().SettingsReducer.globalAliases.LanternSurveyJobWorkAssignments)
  return tables
    // exclude SurveyJobWorkAssignments
    .filter((table: any) => table.Id !== workAssignmentServiceId - layers.length)
    .map((table:any, i:number) => {return {label:tableMapServices[i].name, value:table}})
}

/**
 * Summary: Gets fields from a layer and returns it in dropdown format
 * @param layer layer that fields are being retrieved from
 */
export function getFieldOptions(layer:any) {
  return layer.Fields.map((option:any) => {
    return {label:option.AliasName, value:option, fieldType:option.FieldType}
  })
}

/**
 * Summary: Remove duplicate esri features from an array
 * @param features array of esri features
 */
export function removeDuplicateFeatures(features:any) {
  if (features.length < 1) {
    return []
  }
  const objectIdFieldName = features[0]?.layer?.objectIdField || features[0]?.objectIdField || "OBJECTID"
  return features.filter((feature:any, index:number, self:any) =>
    !feature.attributes[objectIdFieldName] ||  index === self.findIndex((t:any) => (
      (!t.sourceLayer || !feature.sourceLayer || t.sourceLayer.layerId === feature.sourceLayer.layerId) &&
      t.attributes[objectIdFieldName] === feature.attributes[objectIdFieldName]
    ))
  )
}

/**
 * Summary: Filters out the polygon types that are not grids and returns the result.
 * Currently, these are the Corrosion Areas and Pipelines polygon types. 
 * 
 * @param polygonTypes array polygon types
 * @returns array of polygon types with non-grid polygon types filtered out
 */
export function filterNonGridPolygonTypes(polygonTypes:Array<any>) {
  const polygonTypesToRemove = [PolygonTypes.corrosionArea, PolygonTypes.pipelineId]
  return polygonTypes.filter((polygonType)=> {
    return !(polygonTypesToRemove.includes(polygonType.polygonType))
  })
}

/**
 * Get layer or table from name
 * @param name layer or table name
 * @returns layer or table from settings reducer
 */
export function getLayerOrTableByName(name:string) {
  const layers = store.getState().SettingsReducer.settings.Layers;
  const tables = store.getState().SettingsReducer.settings.Tables;
  let id = null;
  for (let index = 0; index < layers.length; index++){
    if(layers[index].Name === name){
      return layers[index]
    }
  }
  for (let index = 0; index < tables.length; index++){
    if(tables[index].DisplayName === name){
      return tables[index]
    }
  }

  id = getLayerMapServiceIdByName(name) 
  if (isNaN(id)) {
    id = getTableIdByName(name)
  }
  
  if (id) {
    return getLayerOrTableById(id)
  }
  return null
}

/**
 * Get layer or table from id
 * @param id layer or table id
 * @returns layer or table name
 */
export function getLayerOrTableById(id:number) {
  const layers = store.getState().SettingsReducer.settings.Layers;
  const tables = store.getState().SettingsReducer.settings.Tables;
  for (let index = 0; index < layers.length; index++){
    if(layers[index].Id === id){
      return layers[index]
    }
  }
  id -= layers.length
  for (let index = 0; index < tables.length; index++){
    if(tables[index].Id === id){
      return tables[index]
    }
  }
  return null
}

/**
 * Get layer/table name from map service lists
 * @param id layer/table id
 * @returns map service list layer/table name
 */
export function getMapServiceNamebyId(id: number) {
  const mapServiceLists = store.getState().SettingsReducer.mapServiceLayersList
    .concat(store.getState().SettingsReducer.tableMapServicesList)
  for (let i = 0; i < mapServiceLists.length; i++) {
    if (mapServiceLists[i].id === id) {
      return mapServiceLists[i].name
    }
  }
  return ''
}

/**
 * Find equivalent feature based on id
 * @param features: array of features to search through
 * @param searchFeature: feature to search against
 * @returns feature with matching id
 */
export function findFeatureFromID(features: Array<any>, searchFeature: any) {
  const layer = getLayerOrTableById(searchFeature.layer.layerId)
  const globalIdFieldName = layer.FeatureClass.GlobalIdFieldName
  let foundFeature = null
  if (globalIdFieldName) {
    foundFeature = features.find((feature:any) => 
      feature.attributes[globalIdFieldName] === searchFeature.attributes[globalIdFieldName])
  }
  else {
    const objectIdFieldName = layer.FeatureClass.OidFieldName
    foundFeature = features.find((feature:any) => 
      feature.layer.layerId === searchFeature.layer.layerId
      && feature.attributes[objectIdFieldName] === searchFeature.attributes[objectIdFieldName])
  }
  return foundFeature
}



/**
 * Summary: constructs where clause with filtered values.
 *
 * @param formatWhereClause string: default where clause
 *
 * @param filteredSortColumnMap string: current filter and sort mapping for the columns
 * 
 * @return string: returns constructed where clause
 *
 */
export const constructFiltersForQuery = (formatWhereClause:any, filteredSortColumnMap: any) => {
  if(!filteredSortColumnMap) return; 
  Object.keys(filteredSortColumnMap).forEach(colName =>{
    let columnFilter = '';
    const filterValues = filteredSortColumnMap[colName]?.filter; //getting the filter values for the current column
    //Doesn't work for surveyors, exclude because it will break the query
    if (colName === 'Surveyors') return
    if (filterValues && Array.isArray(filterValues)) { //column with multiple filter values
      columnFilter += (filterValues.length > 0) ? ` and ${colName} in (${filterValues.map(value => {
        if (typeof value === 'string') {
          return `'${transformSingleQuoteChars(escapeSpecialChars(value))}'`;  
        } else {
          return `'${value}'`;
        } 
      }).join(', ')})` : "";
    } else if (!isUndefined(filterValues)) {
      //compliance date filter
      if (colName?.toLowerCase() === "compliancedate" || colName?.toLowerCase() === "compliance date") {
        if (filterValues === -1) {
          columnFilter = ` and ${colName} <= CURRENT_DATE() - 1`;
        } else {
          columnFilter = ` and ${colName} BETWEEN CURRENT_DATE() - 1 AND CURRENT_DATE() + ${filterValues.addAmount.days} - 1`;
        }
      } 
      //survey year filter
      else if (colName?.toLowerCase() === "surveyyear" || colName?.toLowerCase() === "survey year") {
        if (typeof filterValues === 'string' && isEmpty(filterValues?.trim())) {
          columnFilter = ` and ${colName} is null`;
        } else {
          columnFilter = ` and ${colName} in ('${filterValues}')`;
        }
      }      
      else {
        //column with single filter value
        columnFilter = ` and ${colName} in ('${transformSingleQuoteChars(escapeSpecialChars(filterValues))}')`;
      }
    }
    formatWhereClause += columnFilter;
  })
  return formatWhereClause;
};

/**
 * Summary: constructs order By clause with sorted values.
 *
 * @param formatWhereClause string: default order by clause
 *
 * @param filteredSortColumnMap string: current filter and sort mapping for the columns
 * 
 * @return string: returns constructed order by clause
 *
 */
export const constructSortForQuery = (formatOrderByClause: string = '', filteredSortColumnMap: any): string => {
  if(!filteredSortColumnMap) return formatOrderByClause; 
  let columnSort = '';
  Object.keys(filteredSortColumnMap).forEach(colName =>{
    const sortValues = filteredSortColumnMap[colName]?.sort; //getting the sort values for the current column
    columnSort += (sortValues ? `${colName} ${sortValues}` : ''); //column with sort value
  });
  const column = formatOrderByClause.split(' ');
  //TODO: for now if user applies sorting, applying the default sort as the user applied sorting and making the default applied sort as the second sort
  let formatOrderByClauseResult = ''
  if (columnSort !== '' && column && column[0]) {
    if (columnSort.toLowerCase().includes(column[0].toLowerCase())) {
      formatOrderByClauseResult = columnSort
    }
    else {
      formatOrderByClauseResult = columnSort +`, ${formatOrderByClause}`
    }
  } 
  else {
    formatOrderByClauseResult = formatOrderByClause
  }
  return formatOrderByClauseResult;
};

/**
 * Summary: given an object it returns true if it has atleast one key.
 *
 * @param obj object: expects an object as input
 * 
 * @return boolean: returns true if it has atleast one key otherwise false.
 *
 */
export const isObjectNotEmpty = (obj:any) =>{
  if(typeof obj === 'object' && obj !== null)
  return obj && Object.keys(obj).length;
};

//callback to handle the page change from the regular table component
//a specific page number would be set for the state variable which inturn
//triggers fetch page data from ArcGIS
export const pageChangeCallback = ({action, pageNumber, pageCount}:TablePageChangeProps, currentPageNumber: number):any =>{
  if(action === TablePageChangeActions.FirstPage){
    return 1;
  } else if(action === TablePageChangeActions.LastPage && pageCount){
    return pageCount;
  } else if(action === TablePageChangeActions.PrevPage){
    return currentPageNumber - 1;
  } else if(action === TablePageChangeActions.NextPage){
    return currentPageNumber + 1;
  } else if(action === TablePageChangeActions.SpecificPage && pageNumber){
    return pageNumber;
  }
};

export const updatePrevFilteredSortColumnMap = (
  prevFilteredSortColumnMap: any,
  currFilteredSortColumnMap: any
) => {
  if (prevFilteredSortColumnMap?.current) {
    prevFilteredSortColumnMap.current = currFilteredSortColumnMap;
  }
};

//callback to handle filters change from the regular table component
//selected filters would be set to the state variable which inturn
//triggers filtered page data from ArcGIS
export const filterChangeCallback = (id:any, value: any, setFilteredSortColumnMap:any, prevFilteredSortColumnMap?:any) => {
  setFilteredSortColumnMap((prevFiltersAndSorts: any) => {
    const isEmptyOrUndefined =
      isUndefined(value) || (Array.isArray(value) && value.length === 0);

    if (
      isEmptyOrUndefined &&
      id in prevFiltersAndSorts &&
      !("sort" in prevFiltersAndSorts[id])
    ) {
      // if value is undefined or empty array, and id is in prevFiltersAndSorts without a sort, then we delete it
      const { [id]: deleted, ...rest } = prevFiltersAndSorts;
      updatePrevFilteredSortColumnMap(prevFilteredSortColumnMap, prevFiltersAndSorts);
      return rest;
    } else if ((isEmptyOrUndefined && !(id in prevFiltersAndSorts)) || isEqual(prevFiltersAndSorts?.[id]?.filter, value)) {
      // if value is undefined, and the id is not in prevFiltersAndSorts, we don't need to modify anything
      // if value is equal to the current value, we don't need to change anything
      return prevFiltersAndSorts;
    } else {
      updatePrevFilteredSortColumnMap(prevFilteredSortColumnMap, prevFiltersAndSorts);
      return {
        ...prevFiltersAndSorts, [id]: {
          ...prevFiltersAndSorts[id],
          filter: value
        }
      }
    }
  })
}

//callback to handle sort change from the regular table component
//selected sorted columns would be set to the state variable which inturn
//triggers sort change from ArcGIS
export const sortChangeCallback = ({ id, sortValue }: any, prevFilteredSortColumnMap: any, setFilteredSortColumnMap:any) => {
  const currentFilterValues = { id, sortValue }
    //verifying the actual sort change
    if(!isEqual(currentFilterValues, prevFilteredSortColumnMap?.current)){
      prevFilteredSortColumnMap.current = { id, sortValue }
      setFilteredSortColumnMap((prevFilters: any) => ({
        ...prevFilters, [id]: {
          filter: prevFilters[id]?.filter,
          sort: sortValue !== TableSortActions.CLEAR ? sortValue : ''
        }
      }))
    }
}

interface applyColumnFilterProps{
  defaultColumn:any; 
  columns:any; 
  currentColIndex: any;
}

function getComplianceDateLabelFromValue(value: any) {
  for (const filter of dateRangeFiltersValues) {
    if (isEqual(filter.value, value)) return filter.label
  }
}

export const applyFilterToGridColumns = ({defaultColumn, columns, currentColIndex}: applyColumnFilterProps) =>{
  let currentColumn = columns[currentColIndex];
  if(Array.isArray(defaultColumn?.appliedFilterValues)){
    defaultColumn?.appliedFilterValues?.filter((col:any) => {
      if(currentColumn.id in col){
        currentColumn.filterValue = col[currentColumn.id];
        if (
          currentColumn.Header?.toLowerCase() === "compliance date" ||
          currentColumn.Header?.toLowerCase() === "compliancedate"
        ) {
          // compliance date value is typically an object, so we must get its label as well
          currentColumn.filterLabel = getComplianceDateLabelFromValue(
            currentColumn.filterValue
          );
        }
      }
    })
  }
  return currentColumn;
}

export const getAppliedFilterAndSorts = (filteredSortColumnMap:any, filterSortCriterias:any) =>{
  let appliedFilterValues: any= [], appliedSortedValues: any = [];
  if(!isEmpty(filteredSortColumnMap)){
    let filtersLength = Object.keys(filterSortCriterias).length;
    if(filtersLength){
      Object.keys(filterSortCriterias).forEach(item => {
        appliedFilterValues.push( { [item] : filterSortCriterias[item]?.filter})
        if(!isEmpty(filterSortCriterias[item]?.sort)){
          appliedSortedValues.push( { id: item, desc : filterSortCriterias[item]?.sort === 'DESC'})
        }
      });
    }
  }
  return {appliedFilterValues, appliedSortedValues}
}

  /**
   * Get survey job prefilter values (so the dropdowns will have more than just the page's values)
   * @param location page url
   * @param filteredSortColumnMap current filters and sorts
   */
  export const getSurveyJobsDistinctFilteredRows = (
    {
      location,
      filteredSortColumnMap,
      isFromAssignPlanDetails,
      initialLoad,
      gridFilterValue
    }: surveyJobsFilteredRowsParams
  ) => async (dispatch: any) => {
    const polygonNameField = "PolygonName";

    let surveyPlanIDList = ``;
    getUrlParam(location, "id")
      ?.split(",")
      .forEach((id: string) => {
        surveyPlanIDList += `'${id}',`;
      });
    surveyPlanIDList = surveyPlanIDList.substring(0, surveyPlanIDList.length - 1);

    let surveyJobTableId = getTableIdByName(
      store.getState().SettingsReducer.globalAliases.LanternSurveyJob
    );
    let surveyJobTable = getLayerOrTableByName(
      store.getState().SettingsReducer.globalAliases.LanternSurveyJob
    );
    const selectedMapService =
      store.getState().LoginReducer.selectedMapService.decodedLabel;

    const displayFieldName = surveyJobTable.DisplayFieldName

    const existingJobTableConfigData = store.getState()[isFromAssignPlanDetails ? "SurveyAssignmentReducer" : "SurveyReviewReducer"].existingJobTableConfigData;
    let existingJobTableConfigFields = existingJobTableConfigData.map((config:any) => config.accessor);

    existingJobTableConfigFields = surveyJobTable.Fields.filter((field:any) => {
      return existingJobTableConfigFields.includes(field.Name) || existingJobTableConfigFields.includes(field.Name.toUpperCase())
    }).map((field:any) => field.Name)
    
    const mandatoryOutfields = ["Surveyors", "SurveyJobID", "OBJECTID", "Status", polygonNameField, displayFieldName]
    
    mandatoryOutfields.forEach((field:string) => {
      if (!existingJobTableConfigFields.includes(field)) {
        existingJobTableConfigFields.push(field);
      }
    })
  
    const statusQuery = (isFromAssignPlanDetails) ? ` AND STATUS IN ('${surveyJobStatus.active}', '${surveyJobStatus.planned}', '${surveyJobStatus.inProgress}')` : ``;
    let formattedWhereClause = `SurveyPlanID IN (${surveyPlanIDList}) ${statusQuery}`
    formattedWhereClause = gridFilterValue ? formattedWhereClause + ` AND PolygonName like '${gridFilterValue}%'` : formattedWhereClause
    let formattedOrderByClause = `${polygonNameField} ASC`  
    if(isObjectNotEmpty(filteredSortColumnMap)){
      formattedWhereClause = constructFiltersForQuery(formattedWhereClause, filteredSortColumnMap);
      formattedOrderByClause = constructSortForQuery(formattedOrderByClause, filteredSortColumnMap);
    }
    let defaultOutFields = existingJobTableConfigFields.join(", ");
    const queryingParams = {
      [arcgisQueryParams.where]: encodeUriAll(formattedWhereClause),
      [arcgisQueryParams.outFields]: defaultOutFields,
      [arcgisQueryParams.returnDistinctValues]: "true",
      [arcgisQueryParams.orderByFields]: encodeUriAll(formattedOrderByClause)
    };
    
    postArcgisQuery(
      selectedMapService,
      surveyJobTableId,
      queryingParams
    )
      .then((res: any) => {
        axiosErrorHandler(res);
        if (res.data.error) {
          throw res.data.error;
        }
        fetchActiveJobSurveyors(
          res.data.features,
          dispatch,
          filteredSortColumnMap,
          isFromAssignPlanDetails,
          true,
          undefined,
          initialLoad
        );
      })
      .catch((err:any) => {
        dispatch({
          type: ERROR,
          error: {
            message: err,
            type: "fetchExisting",
          },
        });
      });
  };
