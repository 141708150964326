import { store } from "app/store";
import {
  RESET_SEARCH,
  RESET_SEARCH_SEEN,
  SET_MAP_NOTIFICATION,
  SET_SEARCH_GEOMETRY,
  searchTableFields
} from "common/Map/MapDucks"
import { SQLConcatOperators } from "../../QueryWidget/QuerySlideoutBody";
import { getFieldOptions, getTableOptions } from "utils/GridUtils"
import { MAX_TABLE_QUERY_LENGTH } from "features/MapPage/MapPageDucks";
import { fieldType, searchMatchType, whereQueryFieldTypeConversion } from "utils/FieldsUtils";

/**
 * Summary: Function to search by tables
 *
 * @param e value being searched against
 * @param layer layer being searched against
 * @param field field being searched against
 * @param mapType map we are searching on
 * @param dispatch dispatch from useDispatch()
 */
export function tableSearchSubmit(
  e:any,
  layer:any,
  field:any,
  mapType:string,
  dispatch:any
) {
  const layers = store.getState().SettingsReducer.settings.Layers
  let fields = []
  dispatch({type:RESET_SEARCH + mapType})
  dispatch({type:RESET_SEARCH_SEEN + mapType})
  dispatch({type:SET_MAP_NOTIFICATION + mapType, message: ""})
  dispatch({type:SET_SEARCH_GEOMETRY + mapType, grids: [], lines: [], points: []})
  if (layer === 'All') {
    let tables = getTableOptions()
    //Count total number of calls, including if fields are divided
    let fieldSliceCount = tables.reduce((totalCount:number, table:any) => {
      return totalCount + Math.ceil(table.value.Fields.length/MAX_TABLE_QUERY_LENGTH)
    }, 0)
    tables.forEach((table:any, index:number) => {
      fields = table.value.Fields
      //If too many fields, break array down
      for (let i = 0; i < fields.length; i += MAX_TABLE_QUERY_LENGTH) {
        const fieldsSlice = fields.slice(i, i + MAX_TABLE_QUERY_LENGTH).map((field:any) => {return {label: field.Name, fieldType: field.FieldType}})
        dispatch(
          searchTableFields(
            layers.length + table.value.Id,
            getTableWhereClause(e, fieldsSlice),
            mapType,
            true,
            index === 0 && i === 0,
            fieldSliceCount
          )
        )
      }
    })
  }
  else if (field === 'All') {
    fields = getFieldOptions(layer)
    for (let i = 0; i < fields.length; i += MAX_TABLE_QUERY_LENGTH) {
      const fieldsSlice = fields.slice(i, i + MAX_TABLE_QUERY_LENGTH)
      dispatch(
        searchTableFields(
          layers.length + layer.Id,
          getTableWhereClause(e, fieldsSlice),
          mapType,
          fields.length > MAX_TABLE_QUERY_LENGTH,
          false,
          Math.ceil(fields.length/MAX_TABLE_QUERY_LENGTH)
        )
      )
    }
  }
  else {
    dispatch(
      searchTableFields(
        layers.length + layer.Id, 
        `${whereQueryFieldTypeConversion(field.Name, field.FieldType, e, searchMatchType.anyMatch)}`, 
        mapType
      )
    )
  }
}

/**
 * Summary: Gets where clause for table search based off fields and input
 * @param input search input
 * @param fields fields being searched against
 * @returns SQL where clause for table search
 */
const getTableWhereClause = (input:any, fields:any) => {
  //Expects dates in yyyy/mm/dd format. Otherwise filter out date fields to avoid failed queries.
  const dateRegex = /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/
  if (!dateRegex.test(input)) {
    fields = fields.filter((field:any) => field.fieldType !== fieldType.date)
  }
  //Avoid querying numeric fields with non-numeric values
  if (isNaN(input)) {
    fields = fields.filter((field:any) => field.fieldType >= 4)
  }
  let fieldLikeStrings = fields.map((field:any) => {
    return `${whereQueryFieldTypeConversion(field.label, field.fieldType, input, searchMatchType.anyMatch)}`
  })
  return fieldLikeStrings.join(` ${SQLConcatOperators.orOperator} `)
}
