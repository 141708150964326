import React from 'react';
import { useDispatch } from 'react-redux';
import { ReactComponent as UnselectIcon } from 'assets/UnselectIcon.svg';
import { widgetTypes, IWidgetProps } from '../MapWidgetLayer';
import { SET_ACTIVE_TOOL } from '../MapDucks';

export const UnselectWidget = ({
  currentTool,
  activeSlideoutTool,
  isSlideoutOpen,
  sketchWidget,
  unselectWidget,
  mapType
}:IWidgetProps) => {
  const dispatch = useDispatch()

  function handleClick() {
    if (currentTool === widgetTypes.unselect) {
      unselectWidget?.cancel()
      dispatch({type: SET_ACTIVE_TOOL + mapType, tool: widgetTypes.move})
    }
    else {
      sketchWidget?.cancel()
      unselectWidget?.create(widgetTypes.rectangle);
      dispatch({type: SET_ACTIVE_TOOL + mapType, tool: widgetTypes.unselect})
    }
  }

  return (
    <button
      title="Unselect"
      id="unselect-btn"
      className={"expanded-btn" + (currentTool===widgetTypes.unselect?" btn-active":"")}
      onClick={handleClick}
    >
      <UnselectIcon/>
    </button>
  )
}
