import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Draggable } from "react-beautiful-dnd";

import { ToggleSpan, StyledLabel, IconSpan } from "./list.styles";
import { CustomSlider, StyledDrag } from "./slider.styles";
import { setSelectedBaseMap } from "common/Map/MapDucks";
import Toggle from "common/Toggle/Toggle"

function DragableItem({ basemap, index, basemapsList }: any) {
  const dispatch = useDispatch();

  return (
    <Draggable key={basemap.name} draggableId={basemap.name} index={index}>
      {(provided) => (
        <li {...provided.draggableProps} ref={provided.innerRef}>
          <IconSpan {...provided.dragHandleProps}>
            <StyledDrag />
          </IconSpan>
          <StyledLabel>{basemap.name}</StyledLabel>
          <ToggleSpan>
            <Toggle
              checked={basemap.checked}
              handleChange={(event) => {
                dispatch(
                  setSelectedBaseMap({
                    basemapsList,
                    basemap,
                    checked: event.target.checked,
                  })
                );
              }}
            />
          </ToggleSpan>
          <CustomSlider
            value={basemap.defaultOpacity}
            onChange={(e, newValue) => {
              basemap.checked &&
                dispatch(
                  setSelectedBaseMap({
                    basemapsList,
                    basemap,
                    opacity: newValue,
                  })
                );
            }}
          />
        </li>
      )}
    </Draggable>
  );
}

export default DragableItem;
