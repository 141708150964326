import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { getFieldType, getFields, fieldType } from 'utils/FieldsUtils';
function NearestFeatureAttributesTable({ selectedFeature, selectedLayer }: any) {
  
/**
 * Summary: this function converts epoch time to human time
 * @param: dateString - this string is to specify which date attribute we are converting to human time
 */
  function getShortDate(dateString:string){
    if(selectedFeature.attributes[dateString]){
      const date = new Date (selectedFeature.attributes[dateString]);
      return date.toISOString().substring(0,10);
    } else return selectedFeature.attributes[dateString] 
  }

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{fontSize: "12px", fontWeight: 600}}>Field</TableCell>
            <TableCell sx={{fontSize: "12px", fontWeight: 600}} align="right">Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(selectedFeature.attributes).map((attribute, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="attribute" sx={{fontSize: "12px"}}>
                {attribute}
              </TableCell>
              <TableCell align="right" sx={{fontSize: "12px"}}>
                {getFieldType((getFields(selectedLayer)), attribute) === fieldType.date ? getShortDate(attribute) : selectedFeature.attributes[attribute]}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default NearestFeatureAttributesTable;
