import React, { useState, useEffect, useMemo } from 'react';
import WindowedSelect, { components } from 'react-windowed-select';
import './MultiSelectDropdown.scss';
import SearchIcon from '@material-ui/icons/Search';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { isUndefined } from 'lodash';

interface IDropdownProp {
  items: Array<any>
  handleChange: (e: any) => void
  placeHolder?: string
  borderless?: boolean
  defaultValue?: Object
  fixedValue?: Object
  isReset?: boolean
  preselectedValuesOnReset?: []
  grouped?: boolean
  className?: string
  dropUp?: boolean
  icon?: string
  existingSelections?: any
  onValueChange?: (e: any) => void
  onReset?: () => void
}

const MultiSelectDropdown: React.FunctionComponent<IDropdownProp> = (props) => {
  const [value, setValue] = useState<Array<string | any> | string | null | void>(null);
  /**
   * summary: provides a callback to access selected values
   *
   * @param selectedOption
   */
  const handleChange: Function = (selectedOption: any) => {
    if (selectedOption) {
      props.handleChange(selectedOption.map(((data: any) => data.value)));
    }
    else if (selectedOption !== null) {
      props.handleChange(null);
    }
  }

  //Styling and using material ui's api
  var customStyles = {
    control: (base: Object) => ({
      ...base,
      border: props.borderless ? 'none' : '',
      'borderRadius': '0',
      // This line disable the blue border
      boxShadow: '0',
      '&:hover': {
        border: props.borderless ? 'none' : '',
      },
      'padding': props.borderless ? '0' : '2px 4px',
    }),
    singleValue: (base: Object) => ({
      ...base,
      color: props.fixedValue ? "#929497" : "black"
    }),
    indicatorSeparator: () => ({ display: 'none' }),
    option: (styles: Object, { isFocused }: any) => ({
      ...styles,
      background: isFocused ? '#0088c1' : 'white',
      color: isFocused ? 'white' : 'black',
    }),
  };

  /**
   * Summary: Renders selected values unless greater than 3 which then renders 'x items chosen'
   *
   * @param selectedProps Single selected props
   * @param data entire dataset for the dropdown
   */
  let multiValueContainer = ({ selectProps, data }: any) => {
    const label = data.label;
    const allSelected = selectProps.value;
    const index = allSelected.findIndex((selected: any) => selected.label === label);
    const isLastSelected = index === allSelected.length - 1;
    if (allSelected.length === 1) {
      return allSelected[0].label
    }
    if (isLastSelected) {
      return allSelected.length + " items chosen"
    }
    return ''
  }

  useEffect(() => {
    if (props.onValueChange) {
      props.onValueChange(value)
    }
  }, [value])

  /**
   * summary: clear the selection field when reset is updated
   */
  useEffect(() => {
    if (props.isReset === true) {
      setValue(props.preselectedValuesOnReset ?? [])
      if (!isUndefined(props.onReset)) {
        props.onReset()
      }
    }
  }, [props.isReset]);

  let icon = <ExpandMoreIcon />
  if (props.icon === 'search') {
    icon = <SearchIcon />
  }
  else if (props.dropUp) {
    icon = <ExpandLessIcon />
  }

  let DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        {icon}
      </components.DropdownIndicator>
    );
  };

  let options = useMemo(() => {
    const sortedItems = props.items.concat().sort()
    return props.grouped ? sortedItems :
      (sortedItems.map((item) => {
        if (item?.label) {
          return { value: item.value, label: item.label }
        }
        return { value: item, label: item }
      }))
  }, [props.grouped, props.items])

  return (
    <div className={props.className}>
      <WindowedSelect
        // if there is no fixed label, use user selection
        // if a value is not selected, use the default value
        value={props.fixedValue || value || props.defaultValue}
        defaultValue={props.existingSelections}
        options={options}
        isMulti
        placeholder={props.placeHolder}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{
          Option,
          MultiValueContainer: multiValueContainer,
          DropdownIndicator
        }}
        onChange={(e: any) => {
          handleChange(e)
          setValue(e)
        }}
        styles={customStyles}
        menuPlacement={props.dropUp ? "top" : "bottom"}
      />
    </div>
  );
}

const Option = (props: any) => {
  return (
    <span id="option-container">
      <components.Option {...props}>
        <div className="checkbox-container">
          <input
            className={"checkmark-input"}
            type="checkbox"
            onChange={() => null}
            checked={props.isSelected}
          />
          <span className={"box white checkmark"}></span>
        </div>
        {" "}
        <span className="option-label">{props.label}</span>
      </components.Option>
    </span>
  );
};

MultiSelectDropdown.defaultProps = {
  placeHolder: "Select an option",
  borderless: false
}

export default MultiSelectDropdown;
