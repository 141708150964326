import axios from "axios";
import { store } from "app/store";
import {
  surveyAssignmentMapType,
  SET_GEOMETRY,
  SET_GRID_TYPE,
  surveyReviewMapType,
} from "common/Map/MapDucks";
import {
  getConfgurationForMapService,
  getActiveJobSurveyors,
  updateAssignedSurveyors,
  createBufferToolInfoSchedule
} from "api/legacy/surveyAssignmentDucks";
import { esriGeometryType } from "common/Map/SelectFeatures";
import { blankLabel } from "common/Dropdown/Dropdown";
import { axiosErrorHandler } from "utils/AxiosUtils";
import { getCurrentDate } from "utils/DateUtils";
import {
  joinSurveyJobAndPlan,
  getUrlParam,
  generateID,
  whereQueryFieldTypeConversion,
  surveyJobStatus,
  escapeSpecialChars,
  transformSingleQuoteChars,
} from "utils/FieldsUtils";
import {
  getTableIdByName,
  getTableIndexByName,
  getLayerMapServiceIdByName,
  getLayerOrTableMapServiceIdByName,
  getGridIdFieldByName,
  getLayerOrTableByName,
  isObjectNotEmpty,
  constructFiltersForQuery,
  constructSortForQuery,
  getLayerOrTableById,
} from "utils/GridUtils";
import { getTableConfigData } from "common/Tables/PaginatedTable/TableUtils/TableConfigUtils";
import { fillAllSurveyorInfo, massageGridData } from "utils/MapServiceUtils";
import { mapAttribute, mapQueryResults } from "utils/NameMappingUtils";
import { encodeUriAll } from "utils/UrlUtils";
import { getArcgisQuery, getSurveyJobInfo, getSurveyPlanInfo, postArcgisQuery } from "api";
import { arcgisQueryParams, concatQueryingParameters } from "api/ArcGIS/constants";
import { isNil, map } from "lodash";
import {
  SURVEY_REVIEW_JOBS_SUCCESS,
  SURVEY_REVIEW_SURVEY_JOBS_PRE_FILTER_VALUES_SUCCESS,
  SURVEY_REVIEW_SURVEY_JOBS_ALL_PRE_FILTER_VALUES_SUCCESS,
} from "features/SurveyReview/SurveyReviewDucks";
import { statusTypes } from "common/Tables/PaginatedTable/TableUtils/PredefinedFilterTemplates/StatusTypes";
import {
  SURVEY_JOBS_LOADING as SR_SURVEY_JOBS_LOADING,
  SURVEY_JOBS_ERROR as SR_SURVEY_JOBS_ERROR,
} from "features/SurveyReview/SurveyReviewDucks";
import { surveyJobsFilteredRowsParams } from "./SurveyAssignmentUtils";

export const SURVEY_PLAN_FILTERS_LOADING = "SURVEY_PLAN_FILTERS_LOADING";
export const SURVEY_PLANS_LOADING = "SURVEY_PLANS_LOADING";
export const SURVEY_PLANS_SUCCESS = "SURVEY_PLANS_SUCCESS";
export const SURVEY_PLANS_ERROR = "SURVEY_PLANS_ERROR";
export const SURVEY_PLANS_BY_PAGE_SUCCESS = "SURVEY_PLANS_BY_PAGE_SUCCESS";
export const SURVEY_PLANS_BY_PAGE_LOADING = "SURVEY_PLANS_BY_PAGE_LOADING";
export const SURVEY_PLANS_BY_PAGE_ERROR = "SURVEY_PLANS_BY_PAGE_ERROR";
export const SURVEY_PLANS_PRE_FILTER_VALUES_SUCCESS = "SURVEY_PLANS_PRE_FILTER_VALUES_SUCCESS";
export const SURVEY_PLANS_ALL_PRE_FILTER_VALUES_SUCCESS = "SURVEY_PLANS_ALL_PRE_FILTER_VALUES_SUCCESS"
export const SELECTED_SURVEY_PLANS_LOADING = "SELECTED_SURVEY_PLANS_LOADING";
export const SELECTED_SURVEY_PLANS_SUCCESS = "SELECTED_SURVEY_PLANS_SUCCESS";
export const SELECTED_SURVEY_PLANS_ERROR = "SELECTED_SURVEY_PLANS_ERROR";
export const FILTER_SURVEY_PLANS_SUCCESS =
  "SURVEYASSIGNMENT_FILTER_SURVEY_PLANS_SUCCESS";
export const SURVEY_JOBS_LOADING = "SURVEY_JOBS_LOADING";
export const SURVEY_JOBS_SUCCESS = "SURVEY_JOBS_SUCCESS";
export const SURVEY_JOBS_ERROR = "SURVEY_JOBS_ERROR";
export const SURVEYORS_SUCCESS = "SURVEYORS_SUCCESS";
export const SURVEY_JOBS_PRE_FILTER_VALUES_SUCCESS = "SURVEY_JOBS_PRE_FILTER_VALUES_SUCCESS";
export const SURVEY_JOBS_ALL_PRE_FILTER_VALUES_SUCCESS = "SURVEY_JOBS_ALL_PRE_FILTER_VALUES_SUCCESS"
export const CLEAR_GRID_FILTER = "SURVEYASSIGNMENT_CLEAR_GRID_FILTER";
export const SET_STATUS_MESSAGE = "SET_STATUS_MESSAGE";
export const CLEAR_STATUS_MESSAGE = "ASSIGNMENT_CLEAR_STATUS_MESSAGE";
export const UNASSIGN_SURVEYOR_SUCCESS =
  "SURVEYASSIGNMENT_UNASSIGN_SURVEYOR_SUCCESS";
export const UNASSIGN_SURVEYOR_ERROR =
  "SURVEYASSIGNMENT_UNASSIGN_SURVEYOR_ERROR";
export const CLEAR_ERROR = "ASSIGNMENT_CLEAR_ERROR";
export const ASSIGN_LOADING = "ASSIGN_LOADING";
export const ASSIGN_SUCCESS = "ASSIGN_SUCCESS";
export const ASSIGN_ERROR = "ASSIGN_ERROR";
export const SET_SURVEY_WORK_TYPE = "SURVEY_ASSIGNMENT_SET_SURVEY_WORK_TYPE";
export const PLAN_TABLE_CONFIG = "SURVEY_ASSIGNMENT_PLAN_TABLE_CONFIG";
export const PLAN_TABLE_CONFIG_SUCCESS =
  "SURVEY_ASSIGNMENT_PLAN_TABLE_CONFIG_SUCCESS";
export const JOBS_TABLE_CONFIG = "SURVEY_ASSIGNMENT_JOBS_TABLE_CONFIG";
export const JOBS_TABLE_CONFIG_SUCCESS =
  "SURVEY_ASSIGNMENT_JOBS_TABLE_CONFIG_SUCCESS";
export const ERROR = "ERROR";

export const assignmentSuccessMessage = "assignmentSuccess";
export const unassignmentSuccessMessage = "unassignmentSuccess";
export const assignmentError = "surveyAssignment";
export const filterPlansError = "filterPlansError";
export const createdOnField = "CreatedOn";
export const surveyAssignmentDefaultStatusOptions = []

const polygonNameField = "PolygonName";

// function to get a list of the filter values for all the rows
export const getSurveyPlansDistinctFilteredRows =
  (
    surveyWorkType: string,
    gridFilterValue?: any,
    filteredSortColumnMap?: any,
    existingPlanTableColumns?: any,
    initialLoad?: boolean
  ) =>
  async (dispatch: any) => {
  // if(gridFilterValue !== "") dispatch({ type: SURVEY_PLAN_FILTERS_LOADING });

  let surveyPlanTableId = getTableIdByName(
    store.getState().SettingsReducer.globalAliases.LanternSurveyPlan
  );
  let surveyJobTableId = getTableIdByName(
    store.getState().SettingsReducer.globalAliases.LanternSurveyJob
  );
  const selectedMapService =
    store.getState().LoginReducer.selectedMapService.decodedLabel;
  const tableId = gridFilterValue ? surveyJobTableId : surveyPlanTableId;

  let formattedWhereClause = `1=1 and Status IN ('${statusTypes.active}', '${statusTypes.planned}') and SurveyWorkType='${surveyWorkType}'`
  let formattedOrderByClause = `${createdOnField} DESC`  
  if(isObjectNotEmpty(filteredSortColumnMap)){
    formattedWhereClause = constructFiltersForQuery(formattedWhereClause, filteredSortColumnMap);
    formattedOrderByClause = constructSortForQuery(formattedOrderByClause, filteredSortColumnMap);
  }
  let defaultOutFields = "SurveyPlanName, PlanCategory, Region, District, SurveyYear, Status, ComplianceDate, CreatedOn, SurveyExecType, ReportingType";
  defaultOutFields = existingPlanTableColumns?.length > 0 ? existingPlanTableColumns.toString() + `,${createdOnField}` : defaultOutFields;
  const queryingParams = concatQueryingParameters({
    [arcgisQueryParams.where]: encodeUriAll(formattedWhereClause),
    [arcgisQueryParams.outFields]: defaultOutFields,
    [arcgisQueryParams.returnDistinctValues]: "true",
    [arcgisQueryParams.orderByFields]: encodeUriAll(formattedOrderByClause)
  });
  
  getArcgisQuery(
    selectedMapService,
    tableId,
    queryingParams
  )
    .then((res: any) => {
      axiosErrorHandler(res);
      if (res.data.error) {
        throw res.data.error;
      }
      res=mapQueryResults(res)
      if (initialLoad) {
        dispatch({
          type: SURVEY_PLANS_ALL_PRE_FILTER_VALUES_SUCCESS,
          surveyPlansAllPreFilterValues: res.data.features,
        });
      } else {
        dispatch({
          type: SURVEY_PLANS_PRE_FILTER_VALUES_SUCCESS,
          surveyPlansPreFilterValues: res.data.features
        });
      }
    })
    .catch((err: any) => {
      dispatch({
        type: ERROR,
        error: {
          message: err,
          type: "fetchExisting",
        },
      });
      dispatch({ type: "SURVEYINITIATION_EXISTING_AREA_ERROR" });
    });
};

export const getSurveyPlansByPage = (
  pageSize: number,
  surveyWorkType: string,
  gridFilterValue: any,
  includeTotalCount: boolean = true,
  pageNumber?: number,
  filteredSortColumnMap?: any, 
  totalCount?: number
) => async (dispatch: any) => {
  dispatch({ type: SURVEY_PLANS_LOADING });

  let surveyPlanTableId = getTableIdByName(
    store.getState().SettingsReducer.globalAliases.LanternSurveyPlan
  );
  let surveyJobTableId = getTableIdByName(
    store.getState().SettingsReducer.globalAliases.LanternSurveyJob
  );
  const selectedMapService =
    store.getState().LoginReducer.selectedMapService.decodedLabel;
  const tableId = gridFilterValue ? surveyJobTableId : surveyPlanTableId;
  //Mila: I am reverting to the old code here as it's having issues here for the filters panel
  //will discuss once I am back
  let formattedWhereClause: any =
  `SurveyWorkType='${surveyWorkType}'` +
  `${gridFilterValue ? `AND PolygonName='${gridFilterValue}'` : ``}` + 
  `AND Status IN ('${statusTypes.active}', '${statusTypes.planned}')`;
  let formattedOrderByClause:any = "CreatedOn DESC";  
  if(isObjectNotEmpty(filteredSortColumnMap)){
    formattedWhereClause = constructFiltersForQuery(formattedWhereClause, filteredSortColumnMap);
    formattedOrderByClause = constructSortForQuery(formattedOrderByClause, filteredSortColumnMap);
  }

  let totalCountQueryParams: any, dataQueryParams:any = {
    [arcgisQueryParams.where]: encodeUriAll(formattedWhereClause),
    [arcgisQueryParams.outFields]: "*",
    [arcgisQueryParams.orderByFields]: formattedOrderByClause,
    [arcgisQueryParams.resultRecordCount]: pageSize
  };

  if(includeTotalCount){
    totalCountQueryParams = concatQueryingParameters({...dataQueryParams, [arcgisQueryParams.returnCountOnly]: "true"});
  }
  if (pageNumber && pageNumber !== 1) {
    dataQueryParams = {...dataQueryParams, [arcgisQueryParams.resultOffset]: (pageNumber - 1) * pageSize}
  }
  const queryingParams = concatQueryingParameters(dataQueryParams);

  if(includeTotalCount){
    //gets the data for a specific page with total count    
    getSurveyPlanInfo(
      selectedMapService,
      tableId,
      totalCountQueryParams
    ) 
    .then((surveyPlans:any) => {
        axiosErrorHandler(surveyPlans);
        const totalCount = surveyPlans.data.count;
        //gets the first page data along with totalcount
        dispatch(getSurveyPlansData(queryingParams, selectedMapService, tableId, filteredSortColumnMap, totalCount));
    })
    .catch(() => {
      dispatch({ type: SURVEY_PLANS_ERROR });
    });
  } else{
    dispatch(getSurveyPlansData(queryingParams, selectedMapService, tableId, filteredSortColumnMap, totalCount));
  }
};

export const getSurveyPlansData = (
  queryingParams: any,
  selectedMapService: any,
  tableId: any,
  filteredSortColumnMap?: any, 
  totalCount?: number
) => async (dispatch: any) => {  
    getSurveyPlanInfo(
      selectedMapService,
      tableId,
      queryingParams
    )
    .then((plans:any) => {
      axiosErrorHandler(plans);
      plans = mapQueryResults(plans);
      let surveyPlans:object = { data: plans.data.features };
      //adding totalCount value to the object for the first page load
      surveyPlans = !isNil(totalCount) ? {...surveyPlans, totalCount} : surveyPlans;
      surveyPlans = isObjectNotEmpty(filteredSortColumnMap) ? { ...surveyPlans, surveyPlanFilters: { ...filteredSortColumnMap } } : { ...surveyPlans, surveyPlanFilters: {} };
    
      dispatch({
        type: SURVEY_PLANS_SUCCESS,
        surveyPlans: surveyPlans
      });
    })
    .catch((err:any) => {
      dispatch({
        type: ERROR,
        error: {
          message: err,
          type: "fetchSurveyPlans",
        },
      });
      dispatch({ type: SURVEY_PLANS_ERROR });
    });
};

export const getSelectedSurveyPlans =
  (surveyplanIDs: string[]) => async (dispatch: any) => {
    dispatch({ type: SELECTED_SURVEY_PLANS_LOADING });
    let surveyPlanTableId = getTableIdByName(
      store.getState().SettingsReducer.globalAliases.LanternSurveyPlan
    );
    const selectedMapService =
      store.getState().LoginReducer.selectedMapService.decodedLabel;

    const requestArray = surveyplanIDs.map((surveyPlanId: string) => {
      const queryingParams = concatQueryingParameters({
        [arcgisQueryParams.where]: encodeUriAll(
          `SurveyPlanID = '{${surveyPlanId.slice(1, 37)}}'`
        ),
        [arcgisQueryParams.outFields]: "*",
      });
      return getSurveyPlanInfo(selectedMapService, surveyPlanTableId, queryingParams);
    });
    Promise.all(requestArray)
      .then((res) => {
        const selectedSurveyPlans = res.map(
          (item) => mapQueryResults(item).data.features[0].attributes
        );
        dispatch({
          type: SELECTED_SURVEY_PLANS_SUCCESS,
          selectedSurveyPlans,
        });
      })
      .catch((e) => {
        dispatch({
          type: SELECTED_SURVEY_PLANS_ERROR,
        });
      });
  };

/**
 * summary: get the configuraton data for the existing survey assign plan table
 *
 * @param surveyWorkType the survey work type to get the config data for
 */
export const getExistingPlanTableConfigData =
  (surveyWorkType: string) => async (dispatch: any) => {
    const tableName = "Survey Assignment Plans";
    const actionType = PLAN_TABLE_CONFIG;
    dispatch(getTableConfigData(tableName, actionType, surveyWorkType));
  };

/**
 * summary: get the configuraton data for the existing survey assign job table
 */
export const getExistingJobTableConfigData =
  (surveyWorkType: string) => async (dispatch: any) => {
    const tableName = "Survey Assignment Details";
    const actionType = JOBS_TABLE_CONFIG;
    dispatch(getTableConfigData(tableName, actionType, surveyWorkType));
  };

/**
 * Summary: get the ids of the survey plans that have the specified survey work type
 * and (optionally) that have a grid that matches the searched grid name.
 *
 * note: if gridFilterValue is given, this will filter by survey jobs,
 * otherwise, it will filter by survey plans
 *
 * @param surveyWorkType the survey work type to filter by
 * @param gridFilterValue the survey job grid name to filter by
 */
export const getFilteredPlans =
  (surveyWorkType: string, gridFilterValue?: any) => async (dispatch: any) => {
    // do not filter by grids if the gridFilterValue is blankLabel
    if (gridFilterValue === blankLabel) {
      gridFilterValue = undefined;
    }

    const selectedMapService =
      store.getState().LoginReducer.selectedMapService.decodedLabel;

    const surveyPlanTableId = getTableIdByName(
      store.getState().SettingsReducer.globalAliases.LanternSurveyPlan
    );
    const surveyJobTableId = getTableIdByName(
      store.getState().SettingsReducer.globalAliases.LanternSurveyJob
    );
    const tableId = gridFilterValue ? surveyJobTableId : surveyPlanTableId;

    const whereClause =
    `SurveyWorkType='${surveyWorkType}'` +
    `${gridFilterValue ? `AND PolygonName='${gridFilterValue}'` : ``}` +
    encodeUriAll(
      `AND STATUS IN ('${surveyJobStatus.active}', '${surveyJobStatus.planned}', '${surveyJobStatus.inProgress}')`
    );

    const queryingParams = concatQueryingParameters({
      [arcgisQueryParams.where]: whereClause,
      [arcgisQueryParams.returnDistinctValues]: "true",
      [arcgisQueryParams.outFields]: "SurveyPlanID",
    });
    getArcgisQuery(
      selectedMapService,
      tableId,
      queryingParams
    )
      .then((filteredSurveyPlanIDs: any) => {
        axiosErrorHandler(filteredSurveyPlanIDs);
        filteredSurveyPlanIDs = mapQueryResults(filteredSurveyPlanIDs);
        dispatch({
          type: FILTER_SURVEY_PLANS_SUCCESS,
          filteredSurveyPlanIDs: filteredSurveyPlanIDs.data.features,
        });
      })
      .catch((err: any) => {
        // dispatch({
        //   type: ERROR,
        //   error: {
        //     message: err,
        //     type: filterPlansError,
        //   },
        // });
        dispatch({ type: SURVEY_PLANS_ERROR });
      });
  };

  /**
   * Get current page of survey jobs
   * @param location page url
   * @param pageSize number of survey jobs on page
   * @param pageNumber current page number
   * @param filteredSortColumnMap current filters and sorts
   * @param totalCount total count of survey jobs for this survey plan
   * @param includeTotalCount whether to make a call for the total count of survey jobs
   * @param isFromAssignPlanDetails to determine the call initiator page
   * @param getSurveyorData whether to get surveyor data for the survey jobs
   * @param getGridData whether to get grid data for this survey plan
   */
  export const getSurveyJobsByPage = (
    location: any,
    pageSize: number,
    pageNumber: number,
    filteredSortColumnMap: any, 
    totalCount: number,
    isFromAssignPlanDetails: boolean = true,
    includeTotalCount: boolean = true,
    getGridData: boolean = false,
    gridFilterValue?: string
  ) => async (dispatch: any) => {
    //We only get grid data when page is loading
    // if (getGridData) {
      dispatch({ type: (isFromAssignPlanDetails) ? SURVEY_JOBS_LOADING : SR_SURVEY_JOBS_LOADING})
    // }

    // get list of jobs based on the selected survey plan
    let surveyPlanIDList = ``;
    getUrlParam(location, "id")
      ?.split(",")
      .forEach((id: string) => {
        surveyPlanIDList += `'${id}',`;
      });
    surveyPlanIDList = surveyPlanIDList.substring(0, surveyPlanIDList.length - 1);
  
    let surveyJobTableId = getTableIdByName(
      store.getState().SettingsReducer.globalAliases.LanternSurveyJob
    );
    const selectedMapService =
      store.getState().LoginReducer.selectedMapService.decodedLabel;
    const statusQuery = (isFromAssignPlanDetails) ? ` AND STATUS IN ('${surveyJobStatus.active}', '${surveyJobStatus.planned}', '${surveyJobStatus.inProgress}')` : ``;
    let formattedWhereClause:any = `SurveyPlanID IN (${surveyPlanIDList}) ${statusQuery}`
    formattedWhereClause = gridFilterValue ? formattedWhereClause + ` AND PolygonName like '${gridFilterValue}%'` : formattedWhereClause
    let formattedOrderByClause:any = `${polygonNameField}`;  
    if (isObjectNotEmpty(filteredSortColumnMap)) {
      formattedWhereClause = constructFiltersForQuery(formattedWhereClause, filteredSortColumnMap);
      formattedOrderByClause = constructSortForQuery(formattedOrderByClause, filteredSortColumnMap);
    }
  
    let totalCountQueryParams: any, dataQueryParams:any = {
      [arcgisQueryParams.where]: encodeUriAll(formattedWhereClause),
      [arcgisQueryParams.outFields]: "*",
      [arcgisQueryParams.orderByFields]: formattedOrderByClause,
      [arcgisQueryParams.resultRecordCount]: pageSize
    };
  
    if(includeTotalCount){
      totalCountQueryParams = concatQueryingParameters({...dataQueryParams, [arcgisQueryParams.returnCountOnly]: "true"});
    }
    if (pageNumber !== 1) {
      dataQueryParams = {...dataQueryParams, [arcgisQueryParams.resultOffset]: (pageNumber - 1) * pageSize}
    }
    const queryingParams = concatQueryingParameters(dataQueryParams);
  
    if(includeTotalCount){
      //gets the data for a specific page with total count    
      getSurveyJobInfo(
        selectedMapService,
        surveyJobTableId,
        totalCountQueryParams
      )    
      .then((surveyJobs:any) => {
          axiosErrorHandler(surveyJobs);
          const totalCount = surveyJobs.data.count;
          //gets the first page data along with totalcount
          dispatch(
            getSurveyJobsData(
              queryingParams, 
              selectedMapService, 
              surveyJobTableId,
              filteredSortColumnMap,
              totalCount,
              surveyPlanIDList,
              getGridData,
              isFromAssignPlanDetails
            )
          );
      })
      .catch(() => {
        dispatch({
          type: isFromAssignPlanDetails
            ? SURVEY_JOBS_ERROR
            : SR_SURVEY_JOBS_ERROR,
        });
      });
    } else{
      dispatch(
        getSurveyJobsData(
          queryingParams, 
          selectedMapService, 
          surveyJobTableId,
          filteredSortColumnMap,
          totalCount,
          surveyPlanIDList,
          getGridData,
          isFromAssignPlanDetails
        )
      );
    }
  };

  export const getSurveyJobsData = (
    queryingParams: any,
    selectedMapService: any,
    tableId: any,
    filteredSortColumnMap: any,
    totalCount: number,
    surveyPlanIDList: string,
    getGridData: boolean,
    isFromAssignPlanDetails: boolean = true,
  ) => async (dispatch: any) => {  
      getSurveyJobInfo(
        selectedMapService,
        tableId,
        queryingParams
      )
      .then((jobs:any) => {
        axiosErrorHandler(jobs);
        jobs = mapQueryResults(jobs);
        
        fetchActiveJobSurveyors(jobs.data.features, dispatch, filteredSortColumnMap, isFromAssignPlanDetails, false, totalCount);

        if (isFromAssignPlanDetails && jobs.data.features[0] && getGridData) {
          const polygonType = jobs.data.features[0].attributes.PolygonType
          fetchGridGeometry(surveyPlanIDList, polygonType, dispatch);
          dispatch({
            type: SET_GRID_TYPE + surveyAssignmentMapType,
            gridType: polygonType
          });
        }
      })
      .catch((err:any) => {
        dispatch({
          type: ERROR,
          error: {
            message: err,
            type: "fetchSurveyJobs",
          },
        });
        dispatch({
          type: isFromAssignPlanDetails
            ? SURVEY_JOBS_ERROR
            : SR_SURVEY_JOBS_ERROR,
        });
      });
  };

/**
 * summary: finds the geometry of every job and update selectedGrids
 *
 * @param surveyPlanIDList list of survey plan ids
 * @param polygonType polygon type for these survey jobs
 * @param dispatch dispatch action to update store values
 */
function fetchGridGeometry(surveyPlanIDList: string, polygonType: string, dispatch: any) {
  const gridData: Array<any> = [];
  const selectedMapService =
    store.getState().LoginReducer.selectedMapService.decodedLabel;

  let surveyJobTableId = getTableIdByName(
    store.getState().SettingsReducer.globalAliases.LanternSurveyJob
  );
  getArcgisQuery(
    selectedMapService,
    surveyJobTableId,
    concatQueryingParameters({
      [arcgisQueryParams.where]: `SurveyPlanID IN (${surveyPlanIDList}) AND STATUS IN ('${surveyJobStatus.active}', '${surveyJobStatus.planned}', '${surveyJobStatus.inProgress}')`,
      [arcgisQueryParams.outFields]: "POLYGONID"
    })
  ).then((res: any) => {
    const jobs = res.data.features
    let polygonIdList: Array<string> = jobs.map((job:any) => `'${job.attributes.POLYGONID}'`)
    if (polygonIdList.length > 0) {
      const mapServiceId = getLayerMapServiceIdByName(polygonType);
      const gridIdName = getGridIdFieldByName(polygonType);
      if (!mapServiceId || !gridIdName) return;

      postArcgisQuery(
        selectedMapService,
        mapServiceId,
        {
          [arcgisQueryParams.where]: `${gridIdName} IN (${polygonIdList.join(", ")})`,
          [arcgisQueryParams.outFields]: '*'
        }
      )
      .then((res: any) => {
        // linked Survey Grids may now be deleted, hence a lack of features
        if (res.data?.features?.length) {
          const gridObjectIdField = getLayerOrTableById(mapServiceId)?.FeatureClass?.OidFieldName || "OBJECTID"
          res.data.features.forEach((grid:any) => {
            gridData.push(
              massageGridData(
                {features: [grid], spatialReference: res.data.spatialReference},
                esriGeometryType.esriGeometryPolygon,
                grid.attributes[gridObjectIdField]
              )
            );
          })
        }
        dispatch({ type: SET_GEOMETRY + surveyAssignmentMapType, grids: gridData });
      })
      .catch((err: any) =>
        dispatch({
          type: ERROR,
          error: {
            message: err,
            type: "fetchDetails",
          },
        })
      )
    }
  })
}

/** Summary: fetch a list of all available surveyors */
export const getSurveyors = () => async (dispatch: any) => {
  let allSurveyors: Array<any> = [];
  const selectedMapService =
    store.getState().LoginReducer.selectedMapService.url;

  // Todo: Remove this code after testing
    // url to find SurveyorRole variable name
  // const roleNameUrl =
  //   `${urlConfig.reactLanternServerUrl}` +
  //   `/Configuration.ashx/configuration?mapserviceurl=` +
  //   `${selectedMapService}`;

  // axios.get(roleNameUrl)
  getConfgurationForMapService(selectedMapService)
  .then((res) => {
    axiosErrorHandler(res);
    fillAllSurveyorInfo(res, allSurveyors);
    dispatch({ type: SURVEYORS_SUCCESS, allSurveyors: allSurveyors });
  });
};

/**
 * summary: function to fetch surveyors currently assigned to an active job
 *
 * @param jobs list of jobs to find surveyors assigned to
 * @param totalCount total count of survey jobs for this survey plan
 * @param dispatch dispatch action to update store values
 */
export function fetchActiveJobSurveyors(
  jobs: Array<any>, 
  dispatch: any, 
  filteredSortColumnMap: any,
  isFromAssignPlanDetails: boolean = true,
  forSurveyJobsDistinctFilteredRows:boolean = false,
  totalCount?: number,
  initialLoad?: boolean
) {
  let jobsData: Array<any> = [];
  const selectedMapServiceName =
    store.getState().LoginReducer.selectedMapService.label;

  const workAssignmentsTableIndex = getTableIndexByName(
    store.getState().SettingsReducer.globalAliases.LanternWorkAssignments
  );

  jobs = jobs.map((job: any) => {
    return {attributes: mapAttribute(job.attributes)}
  });

  let surveyJobList: Array<string> = []
  jobs.forEach((job:any) => {
    if (job.attributes.Status === surveyJobStatus.active || job.attributes.Status === surveyJobStatus.inProgress) {
      surveyJobList.push(`'${job.attributes.SurveyJobID}'`)
    }
  })

  if (surveyJobList.length > 0) {
    let requestBody = {
      where: `SurveyJobID IN (${surveyJobList.join(", ")})`,
      enforcelimit: "false",
      mapservicename: selectedMapServiceName,
      outfields: "UserID,ObjectId,SurveyJobID",
    };
  
    getActiveJobSurveyors(requestBody, workAssignmentsTableIndex).then((res) => {
      axiosErrorHandler(res)
      jobs.forEach((job: any) => {
        const assignedSurveyors = res.data.Rows.filter(
          (surveyor: any) =>
          job.attributes.SurveyJobID ===
            surveyor.Attributes.SURVEYJOBID
        );
        if (assignedSurveyors) {
          assignedSurveyors.forEach((surveyor: any, index: number) => {
            assignedSurveyors[index].Attributes = mapAttribute(
              assignedSurveyors[index].Attributes
            );
          });
          if (job?.attributes) {
            job.attributes.Surveyors = assignedSurveyors
          }
        }
        jobsData.push(job);
      });
      dispatchSurveyJobs(
        jobsData,
        dispatch,
        filteredSortColumnMap,
        isFromAssignPlanDetails,
        forSurveyJobsDistinctFilteredRows,
        totalCount,
        initialLoad
      )
    })
    .catch(() => {
      dispatch({
        type: isFromAssignPlanDetails
          ? SURVEY_JOBS_ERROR
          : SR_SURVEY_JOBS_ERROR,
      });
    });
  }
  else {
    dispatchSurveyJobs(
      jobs,
      dispatch,
      filteredSortColumnMap,
      isFromAssignPlanDetails,
      forSurveyJobsDistinctFilteredRows,
      totalCount,
      initialLoad
    )
  }
}

const dispatchSurveyJobs = (
  jobs: Array<any>, 
  dispatch: any, 
  filteredSortColumnMap: any,
  isFromAssignPlanDetails: boolean = true,
  forSurveyJobsDistinctFilteredRows:boolean = false,
  totalCount?: number,
  initialLoad?: boolean
) => {
  if (forSurveyJobsDistinctFilteredRows) {
    if (initialLoad) {
      dispatch({
        type: isFromAssignPlanDetails ? SURVEY_JOBS_ALL_PRE_FILTER_VALUES_SUCCESS : SURVEY_REVIEW_SURVEY_JOBS_ALL_PRE_FILTER_VALUES_SUCCESS,
        surveyJobsAllPreFilterValues: jobs
      });
    } else {
      dispatch({
        type: isFromAssignPlanDetails ? SURVEY_JOBS_PRE_FILTER_VALUES_SUCCESS : SURVEY_REVIEW_SURVEY_JOBS_PRE_FILTER_VALUES_SUCCESS,
        surveyJobsPreFilterValues: jobs, 
        filteredSortColumnMap: filteredSortColumnMap 
      });
    }
  }
  else {
    dispatch({
      type: isFromAssignPlanDetails ? SURVEY_JOBS_SUCCESS : SURVEY_REVIEW_JOBS_SUCCESS,
      surveyJobs: {data: jobs, totalCount: totalCount}, 
      filteredSortColumnMap: filteredSortColumnMap 
    });
    if(!isFromAssignPlanDetails){
      dispatch({
        type: SET_GRID_TYPE + surveyReviewMapType,
        gridType: jobs[0]?.attributes.PolygonType,
      });
    }
  }
}

/**
 * Summary: Update the surveyors of the given survey job
 * @param surveyJobIndex index of the survey job to update
 * @param surveyJobId id of the survey job to update
 */
export const updateSurveyor =
  (surveyJobIndex: number, surveyJobId: string, location: any) =>
  async (dispatch: any) => {
    const selectedMapServiceName =
      store.getState().LoginReducer.selectedMapService.label;
    const workAssignmentsTableIndex = getTableIndexByName(
      store.getState().SettingsReducer.globalAliases.LanternWorkAssignments
    );
    // Todo: Remove this code after testing
    // const url = `${urlConfig.reactLanternServerUrl}/Map.ashx/tables/${workAssignmentsTableIndex}/query`;

    let requestBody = {
      where: `SurveyJobID='${surveyJobId}'`,
      enforcelimit: "false",
      mapservicename: selectedMapServiceName,
      outfields: "UserID,ObjectId",
    };
    getActiveJobSurveyors(requestBody, workAssignmentsTableIndex).then((res) => {
      axiosErrorHandler(res);
      res.data.Rows.forEach((surveyor: any, index: number) => {
        res.data.Rows[index].Attributes = mapAttribute(
          res.data.Rows[index].Attributes
        );
      });
      let surveyJobs = [...store.getState().SurveyAssignmentReducer.surveyJobs];
      surveyJobs[surveyJobIndex].Surveyors = res.data.Rows;
      dispatch({ type: ASSIGN_SUCCESS, surveyJobs });
      dispatch({
        type: SET_STATUS_MESSAGE,
        message: assignmentSuccessMessage,
      });
    });
  };

/**
 * Summary: Removes work assignment for a single surveyor from a single work grid given
 * object ID of work assignment
 * @param surveyorUserId name of the surveyor
 * @param surveyorId OBJECTID of the surveyor
 */
export const postUnassignSurveyors =
  (surveyorObjectId: string) => async (dispatch: any) => {

    // Todo: Remove this code after testing
    // const url = `${urlConfig.reactLanternServerUrl}/Editing.ashx/tables/commit`;

    const selectedMapServiceName =
      store.getState().LoginReducer.selectedMapService.label;

    const workAssignmentsTableIndex = getTableIndexByName(
      store.getState().SettingsReducer.globalAliases.LanternWorkAssignments
    );

    let requestBody = {
      mapservicename: selectedMapServiceName,
      editcontainer: `{
      "modifyEditItems":[],
      "createEditItems":[],
      "deleteEditItems":[{
        "objectId": ${surveyorObjectId},
        "attributes": {
          "OBJECTID": ${surveyorObjectId}
        },
        "tableId": ${workAssignmentsTableIndex},
        "type": 2,
        "hasBeenUndone": false
     }]
    }`,
      token: "",
    };

    updateAssignedSurveyors(requestBody)
    .then(() => {
      //locally remove unassigned surveyor
      let surveyJobs = [...store.getState().SurveyAssignmentReducer.surveyJobs];

      for (let jobIndex = 0; surveyJobs.length > jobIndex; jobIndex++) {
        let surveyorsPerJob = surveyJobs[jobIndex].Surveyors;
        for (
          let surveyorIndex = 0;
          surveyorsPerJob?.length > surveyorIndex;
          surveyorIndex++
        ) {
          const surveyorMatches =
            surveyorsPerJob[surveyorIndex].Attributes.OBJECTID ===
            surveyorObjectId;
          if (surveyorMatches) {
            surveyJobs[jobIndex].Surveyors.splice(surveyorIndex, 1);
            dispatch({ type: UNASSIGN_SURVEYOR_SUCCESS, surveyJobs });
            dispatch({
              type: SET_STATUS_MESSAGE,
              message: unassignmentSuccessMessage,
            });
          }
        }
      }
    });
  };

/**
 * summary: assigns all selected surveyors to an array of selected survey jobs
 *
 * @param data data of all the survey jobs
 * @param selectedIds ids of the selected rows
 * @param assignedSurveyPlans survey plans to be assigned
 * @param surveyors array of surveyors to assign to the survey jobs
 * @param prioritize whether the jobs should be prioritized or not
 * @param bufferWidth Overridden value for buffer widths
 * @param bufferLength Overridden value for buffer widths
 */
export const postAssignSurveys =
  (
    data: Array<any>,
    selectedIds: Object,
    assignedSurveyPlans: Array<any>,
    surveyors: any,
    prioritize: boolean,
    bufferWidth: string,
    bufferLength: string,
    location: any
  ) =>
  async (dispatch: any) => {
    dispatch({ type: ASSIGN_LOADING });
    // const url = `${urlConfig.reactLanternServerUrl}/Editing.ashx/tables/commit`;
    let postRequests: Array<any> = [];

    const currentDate = getCurrentDate(true, "iso");
    const userName = store.getState().LoginReducer.loggedInUser;
    const SurveyJobsTableIndex = getTableIndexByName(
      store.getState().SettingsReducer.globalAliases.LanternSurveyJob
    );
    const workAssignmentsTableIndex = getTableIndexByName(
      store.getState().SettingsReducer.globalAliases.LanternWorkAssignments
    );
    const selectedMapServiceName =
      store.getState().LoginReducer.selectedMapService.label;

    for (const id in selectedIds) {
      let job = data.find((item:any) => item.OBJECTID === parseInt(id))
      let assignedSurveyPlan = assignedSurveyPlans.find((plan: any) => {
        return plan.SurveyPlanID === job.SurveyPlanID;
      });
      if (job.Status === "Planned") {
        scheduleBuffers(
          job,
          assignedSurveyPlan,
          prioritize,
          bufferWidth,
          bufferLength
        );
      }
      let payload = ``;

      surveyors.forEach((surveyor: string) => {
        // loop through the list of surveyors assigned to the current job
        const assigned = job.Surveyors
          ? job.Surveyors.find(
              (assignedSurveyor: any) =>
                surveyor === assignedSurveyor.Attributes.UserID
            )
          : undefined;

        // TODO: make CreatedBy, ModifiedBy, and mapservicename fields dynamic
        if (assigned === undefined) {
          // if surveyor is not assigned to current job, assign surveyor
          let assignmentID = generateID();
          payload += `
          {
            "attributes":
              {
                "OBJECTID": -1,
                "AssignmentID": "{${assignmentID}}",
                "USERID": "${surveyor}",
                "SurveyJobID": "${job.SurveyJobID}",
                "CreatedBy": "${userName}",
                "CreatedOn": "${currentDate}"
              },
            "tableId": ${workAssignmentsTableIndex},
            "type": 1,
            "objectId": -1,
            "oldAttributes": {},
            "hasBeenUndone": false
          },`;
        }
      });
      payload = payload.substring(0, payload.length - 1);

      let requestBody = {
        mapservicename: selectedMapServiceName,
        editcontainer: `{
          "modifyEditItems":[{
            "attributes":
              {
                "STATUS": "Active",
                "OBJECTID": "${job.OBJECTID}"
              },
            "tableId": ${SurveyJobsTableIndex},
            "type": 3,
            "objectId": "${job.OBJECTID}",
            "oldAttributes": {},
            "hasBeenUndone": false
          }],
          "createEditItems":[${payload}],
          "deleteEditItems":[]
        }`,
      };

      postRequests.push(
        updateAssignedSurveyors(requestBody).then((res) => {
          axiosErrorHandler(res);
        })
      );
    }

    axios
      .all(postRequests)
      .then(
        axios.spread(function (...responses) {
          axiosErrorHandler(arguments);

          let surveyJobs = [
            ...store.getState().SurveyAssignmentReducer.surveyJobs,
          ];
          Object.keys(selectedIds).forEach((id: string) => {
            let jobIndex = surveyJobs.findIndex((job:any) => job.OBJECTID === parseInt(id))
            let surveyJobId = surveyJobs[jobIndex].SurveyJobID;
            dispatch(updateSurveyor(jobIndex, surveyJobId, location));
          });
        })
      )
      .catch((err) => {
        dispatch({ type: ASSIGN_ERROR });
        dispatch({
          type: ERROR,
          error: {
            message: err,
            type: assignmentError,
          },
        });
      });
  };

/**
 * summary: schedules buffer for survey job
 *
 * @param job the survey job to be scheduled
 * @param assignedSurveyPlan the survey plan associated with the survey job
 * @param prioritize whether the job should be prioritized or not
 * @param bufferWidth Overridden value for buffer widths
 * @param bufferLength Overridden value for buffer lengths
 */
function scheduleBuffers(
  job: any,
  assignedSurveyPlan: any,
  prioritize: boolean,
  bufferWidth: string,
  bufferLength: string
) {
  let mapServiceID = getLayerOrTableMapServiceIdByName(job.PolygonType);
  if (!mapServiceID && isNaN(mapServiceID)) {
    return;
  }
  let Assets = store.getState().SettingsReducer.layers.Assets;
  let ProgramTypes = store.getState().SettingsReducer.layers.PSTs;
  const selectedMapService =
    store.getState().LoginReducer.selectedMapService.url;
  const selectedMapServiceName =
    store.getState().LoginReducer.selectedMapService.decodedLabel;
  const userName = store.getState().LoginReducer.loggedInUser;
  const polygonId = getGridIdFieldByName(job.PolygonType);
  let mainAssetID = 0;
  let serviceAssetID = 0;
  let ServiceCriteria = "";
  let MainLineCriteria = "";

  // find the asset id for mains and services
  Assets.some((asset: any) => {
    if (asset.AssetType === "Mains") {
      mainAssetID = asset.Id;
    } else if (asset.AssetType === "Services") {
      serviceAssetID = asset.Id;
    }
    return mainAssetID && serviceAssetID;
  });

  // find the corresponding criteria with the asset id, for the program type
  ProgramTypes.forEach((type: any) => {
    if (type.Name === assignedSurveyPlan.ProgramType) {
      type.Criteria.some((criteria: any) => {
        if (assignedSurveyPlan.SurveyWorkType !== 'Leak Survey') {
          MainLineCriteria = criteria.Criteria;
        } else {
          if (criteria.AssetId === mainAssetID) {
            MainLineCriteria = criteria.Criteria;
          } else if (criteria.AssetId === serviceAssetID) {
            ServiceCriteria = criteria.Criteria;
          }
        }
        return MainLineCriteria && ServiceCriteria;
      });
    }
  });

  // retrieve the geometry info
  const jobField =  getLayerOrTableByName(job.PolygonType).Fields;
  const jobFieldType = jobField.filter((item:any) => item.Name === polygonId)[0].FieldType
  const geometryWhereClause = whereQueryFieldTypeConversion(
    polygonId,
    jobFieldType,
    transformSingleQuoteChars(escapeSpecialChars(job.PolygonID))
  );
  const queryingParams = concatQueryingParameters({
    [arcgisQueryParams.outFields]: '*',
    [arcgisQueryParams.returnGeometry]: 'true',
    [arcgisQueryParams.outSR]: '102100',
    [arcgisQueryParams.where]: geometryWhereClause,
    [arcgisQueryParams.returnDistinctValues]: 'false'
  })
  getArcgisQuery(selectedMapServiceName, mapServiceID, queryingParams).then(
    (res: any) => {
      axiosErrorHandler(res);
      res = mapQueryResults(res);
      // Schedule the BufferTool info

      // Todo: Remove this code after testing
      // const bufferUrl = `${urlConfig.reactLanternServerUrl}/Configuration.ashx/schedule/bufferTool`;
      // const config = {
      //   headers: {
      //     "content-type": "application/x-www-form-urlencoded",
      //   },
      // };
      // this line of code (in the case where res.data.features[0] is true) was originally from
      // PR #117, commit hash 8b9d9f426abbb869eff359f093e737c5aa10c0e5
      const GridPolygon = res.data.features[0]?.geometry
        ? `{'features':[{'geometry':{'spatialReference':{'wkid':102100},'rings': ${res.data.features[0].geometry.rings}}}]}`
        : "null";
      let requestBody = {
        bufferToolRequest: `{
          "MapServiceName": "${selectedMapServiceName}",
          "BufferWidth": ${bufferWidth ? bufferWidth : job.BufferWidthDefault},
          "ServiceCriteria": "${ServiceCriteria}",
          "BufferLength": ${
            bufferLength ? bufferLength : job.BufferLengthDefault
          },
          "AreaCode": "${job.Region}",
          "CreatedBy": "${userName}",
          "IsaName": "${job.SurveyPlanName}",
          "AssetStatus": "Planned",
          "Priority": ${prioritize ? 1 : 0},
          "IsaId": "${job.SurveyPlanID}",
          "MainLineCriteria": "${MainLineCriteria}",
          "GridName": "['${escapeSpecialChars(job.PolygonName)}']",
          "Woid": "${job.SurveyJobID}",
          "ScheduleType": null,
          "GridPolygon": "${GridPolygon}"
        }`,
      };
      createBufferToolInfoSchedule(
        requestBody,
        "application/x-www-form-urlencoded"
      ).then((res) => {
        axiosErrorHandler(res);
      });
    }
  );
}

const initialState: any = {
  surveyPlans: [],
  selectedSurveyPlans: [],
  surveyJobs: [],
  allSurveyors: [],
  notificationMessage: "",
  error: {},
  surveyWorkType: "",
  gridFilterValue: blankLabel,
  filteredSurveyPlanIDs: [],
  existingPlanTableConfigData: [],
  existingPlanTableColumns: [],
  existingJobTableConfigData: [],
  surveyPlanTotalCount: 0,
  surveyPlansFilters: {},
  surveyPlansPreFilterValues: [],
  surveyJobTotalCount: 0,
  surveyJobsPreFilterValues: [],
  planSurveyResetSurveyWorkType: false 
};

export default function reducer(state = initialState, action: any) {
  switch (action.type) {
    case SURVEY_JOBS_SUCCESS:
      let massagedJobs: Array<any> = [];
      let surveyJobsData: Array<any> = action.surveyJobs.data;
      let totalCount: number = action.surveyJobs.totalCount;
      if (Object.keys(action.filteredSortColumnMap).includes('Surveyors') && action.filteredSortColumnMap.Surveyors.filter !== '') {
        surveyJobsData = action.surveyJobs.data.filter((feature:any) => {
          return Array.isArray(feature.attributes.Surveyors) && 
          feature.attributes.Surveyors.some((surveyor:any) => {
            return surveyor.Attributes.UserID === action.filteredSortColumnMap.Surveyors.filter
          })
        })
        totalCount = action.surveyJobs.data.length
      }
      surveyJobsData.forEach((job: any) => {
        // assign the appropriate values based on the status of the job
        let newJob = { ...job.attributes };
        if (job.attributes.Status === "Planned") {
          newJob["numberOfMains"] = job.attributes.ForecastMetersMain;
          newJob["numberOfServices"] = job.attributes.ForecastNoAssets;
          newJob["unsurveyedBuffers"] = job.attributes.ForecastMetersMain;
          newJob["unsurveyedServices"] = job.attributes.ForecastNoAssets;
        } else {
          newJob["numberOfMains"] = job.attributes.SurveyBufferCount;
          newJob["numberOfServices"] = job.attributes.ServiceCount;
          newJob["unsurveyedBuffers"] = job.attributes.UncompletedSurveyBuffers;
          newJob["unsurveyedServices"] = job.attributes.UncompletedServices;
        }
        massagedJobs.push(newJob);
      });

      return {
        ...state,
        surveyJobs: massagedJobs,
        surveyJobTotalCount: totalCount
      };

    case SURVEY_JOBS_PRE_FILTER_VALUES_SUCCESS:
      let preFilterValues: Array<any> = action.surveyJobsPreFilterValues
      if (Object.keys(action.filteredSortColumnMap).includes('Surveyors') && action.filteredSortColumnMap.Surveyors.filter !== '') {
        preFilterValues = preFilterValues.filter((feature:any) => {
          return Array.isArray(feature.attributes.Surveyors) && 
          feature.attributes.Surveyors.some((surveyor:any) => {
            return surveyor.Attributes.UserID === action.filteredSortColumnMap.Surveyors.filter
          })
        })
      }
      return {
        ...state,
        surveyJobsPreFilterValues: preFilterValues
      };  

    case SURVEY_JOBS_ALL_PRE_FILTER_VALUES_SUCCESS:
      return {
        ...state,
        surveyJobsAllPreFilterValues: action.surveyJobsAllPreFilterValues
      }
    
    case PLAN_TABLE_CONFIG_SUCCESS:
      return {
        ...state,
        existingPlanTableConfigData: action.tableConfig,
        existingPlanTableColumns: map(action.tableConfig, (col) => col.accessor).filter(col => col !== '')
      };

    case JOBS_TABLE_CONFIG_SUCCESS:
      return {
        ...state,
        existingJobTableConfigData: action.tableConfig
      };

    case SURVEY_PLANS_SUCCESS:
      let massagedSurveyPlans = action.surveyPlans.data.map((feature: any) => {
        return {
          ...feature.attributes,
        };
      });
      const { surveyPlanFilters:actionFilters } = action;
      const surveyPlanFilters = { ...state.surveyPlanFilters, ...actionFilters};
      return {
        ...state,
        surveyPlans: joinSurveyJobAndPlan(massagedSurveyPlans),
        surveyPlanTotalCount: !isNil(action.surveyPlans.totalCount) ? action.surveyPlans.totalCount : state.surveyPlanTotalCount,
        surveyPlanFilters
      };
    case SURVEY_PLANS_PRE_FILTER_VALUES_SUCCESS:
      return {
        ...state,
        surveyPlansPreFilterValues: action.surveyPlansPreFilterValues,
      };  
    case SURVEY_PLANS_ALL_PRE_FILTER_VALUES_SUCCESS:
      return {
        ...state,
        surveyPlansAllPreFilterValues: action.surveyPlansAllPreFilterValues,
      };
    case SURVEY_PLANS_BY_PAGE_SUCCESS:
      const data = action.surveyPlans.data.map((feature: any) => {
        return {
          ...feature.attributes
        };
      });
      return {
        ...state,
        surveyPlans: data
      };  
    case SURVEYORS_SUCCESS:
      return {
        ...state,
        allSurveyors: action.allSurveyors,
      };

    case FILTER_SURVEY_PLANS_SUCCESS:
      return {
        ...state,
        filteredSurveyPlanIDs: action.filteredSurveyPlanIDs,
      };

    case SELECTED_SURVEY_PLANS_SUCCESS:
      return {
        ...state,
        selectedSurveyPlans: action.selectedSurveyPlans,
      };

    case ASSIGN_SUCCESS:
      return {
        ...state,
        surveyJobs: action.surveyJobs,
      };

    case UNASSIGN_SURVEYOR_SUCCESS:
      return {
        ...state,
        surveyJobs: action.surveyJobs,
      };

    case SET_STATUS_MESSAGE:
      return {
        ...state,
        notificationMessage: action.message,
      };

    case CLEAR_STATUS_MESSAGE:
      return {
        ...state,
        notificationMessage: "",
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: "",
      };

    case ERROR:
      return {
        ...state,
        error: action.error,
      };

    default:
      return state;
  }
}
