import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        background: {
          light: '#F2F3F4',
          main: '#3f50b5',
          dark: '#002884',
          contrastText: '#fff',
        },
        text: {
          light: '#ff7961',
          main: '#f44336',
          dark: '#ba000d',
          contrastText: '#000',
        },
      },
  });

  export default theme