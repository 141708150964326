import axios from "axios";
import { getArcGISToken } from "api/v1";
import { refreshToken } from "app/Auth/refreshToken";

const ashxBaseUrl: any = axios.create({
  baseURL: `${window.urlConfig.reactLanternServerUrl}`,
  headers: {},
  params: {},
});

ashxBaseUrl.interceptors.request.use(
  async (config: {
    params: { token: any };
    method: string;
    data: { token: any };
    headers: { Authorization: string, 'X-Esri-Authorization': string };
  }) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
    if (config.method === "post") {
      if (typeof config.data == "object") {
        config.data.token = localStorage.getItem("ArcGISToken");
      }
    } else {
      config.headers['X-Esri-Authorization'] = `${localStorage.getItem("ArcGISToken")}`;
    }
    return config;
  }
);

ashxBaseUrl.interceptors.response.use(
  async function (response: any) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with resp
    const originalRequest = response.config;
    if (response.data?.error?.code === 498 && !originalRequest._retryArcGISToken) {
      originalRequest._retryArcGISToken = true;
      await getArcGISToken();
      return ashxBaseUrl(originalRequest);
    }

    return response;
  },
  async function (error: any) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const originalRequest = error.config;
    if (
      error.response.data === "Invalid Azure Token" &&
      !originalRequest._retryAzureToken
    ) {
      originalRequest._retryAzureToken = true;
      await refreshToken();
      return ashxBaseUrl(originalRequest);
    }

    return Promise.reject(error);
  }
);

export default ashxBaseUrl;
export * from "./QueueProcessingDucks";
export * from "./settingsDucks";
export * from "./surveyAreaDucks";
export * from "./surveyAssignmentDucks";
export * from "./surveyInitiationDucks";
export * from "./AttachmentsDucks";
export * from "./loginDucks";
export * from "./mapPageDucks";
