import React, { useContext, useState } from "react"
import Box from '@material-ui/core/Box';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Dropdown from 'common/Dropdown/Dropdown';
import TextField from '@material-ui/core/TextField';
import Button from 'common/Button/Button';
import { CSVLink } from "react-csv";
import InfoSlideoutBodyContext from "./InfoSlideoutBodyContext";
import { useSelector } from "react-redux";
import { getLayerOrTableByName, isLayer } from "utils/GridUtils";
import { isUndefined } from "lodash";

interface IDownloadWidget {
    data: any,
    filename: string,
    handleDownloadButtonCancelClick: any,
    handleDownloadButtonExportClick: any,
    currentPageIndex: number,
    selectedItems: object,
    selectedLayerOrTableName: string | undefined
}

enum exportTypes {
    current = 'current',
    all = 'all',
    specify = 'specify'
}

const DownloadWidget = ({
    data,
    filename,
    handleDownloadButtonCancelClick,
    handleDownloadButtonExportClick,
    currentPageIndex,
    selectedLayerOrTableName
}:IDownloadWidget) => {
    interface IDownloadOptions {
        fileformat: {
            value: string,
            label: string
        },
        exportType: string,
        selectedItems: object,
        fromPage: number,
        toPage: number,
        currentPageIndex: number,
    }

    // Todo: add support for XLSX and KMZ in future
    const supportedFileFormats = [{
        value: 'csv',
        label: 'CSV'
    }];

    const layers = useSelector((state: any) => state.SettingsReducer.settings.Layers);
    const tables = useSelector((state: any) => state.SettingsReducer.settings.Tables);
    const selectedRowIds = useContext(InfoSlideoutBodyContext);
    const [downloadOptions, setDownloadOptions] = useState<IDownloadOptions>({
        fileformat: supportedFileFormats[0],
        exportType: exportTypes.current,
        selectedItems: {},
        fromPage: 0,
        toPage: 0,
        currentPageIndex
    });
    let numOfPage = Math.ceil(data.length/14); //getting number of pages
    const supportedFileFormatNames = supportedFileFormats.reduce((acc: any, fileFormat: any) => {
        return [...acc, fileFormat.name];
    }, []);

    const supportedFileFormatIds = supportedFileFormats.reduce((acc: any, fileFormat: any) => {
        return [...acc, fileFormat.id];
    }, []);

    let dataToExport: any = [];
    const pageSize = 14;
    switch(downloadOptions.exportType) {
        case 'current':
            const offset = (currentPageIndex*pageSize);
            dataToExport = data.slice(offset, offset + pageSize);
            break;
        case 'all':
            dataToExport = data;
            break;
        case 'specify':
            if(downloadOptions.fromPage-1 >= 0 && downloadOptions.toPage-1 > downloadOptions.fromPage-1) {
                dataToExport = data.slice((downloadOptions.fromPage-1)*pageSize, (downloadOptions.toPage)*pageSize);
            }
            break;
        case 'selected':
            break;
        default:
            dataToExport = [];
    }

    if (!isUndefined(selectedLayerOrTableName)) {
        let invisibleFields: any[];

        if (selectedLayerOrTableName.includes("Survey Event")) {
            // Edge case with inconsistencies with tables with Survey Event in the name and layer logic
            const selectedTableId = tables.find((table: any) => {
                const tableFullName = table.FullName.toLowerCase()
                const selectedTableName = selectedLayerOrTableName.toLowerCase().replaceAll(" ", "")
                return tableFullName.includes(selectedTableName);
            })?.Id;
            invisibleFields = tables[selectedTableId]?.Fields.filter((field: any) => !field.IsVisible);
        } else {
            const selectedLayerOrTableId = getLayerOrTableByName(selectedLayerOrTableName)?.Id;
            const selectedLayerOrTable = isLayer(selectedLayerOrTableName)
                ? layers[selectedLayerOrTableId]
                : tables[selectedLayerOrTableId];
            invisibleFields = selectedLayerOrTable?.Fields.filter((field: any) => !field.IsVisible);
        }

        dataToExport.forEach((dataExportRow: any) => {
            invisibleFields.forEach((fieldToNotInclude: any) => {
                delete dataExportRow[fieldToNotInclude.Name];
            })
        })
    }

    return (
        <Box className='download-container' >
            <FormControl>
                <div className={"export-container"}>
                    <div id="demo-radio-buttons-group-label">Export</div>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="current"
                        name="radio-buttons-group"
                    >
                        <FormControlLabel
                            value={exportTypes.current}
                            onChange={() => setDownloadOptions({ ...downloadOptions, exportType: exportTypes.current })}
                            control={<Radio />}
                            label="Current Page"
                        />
                        <FormControlLabel
                            value={exportTypes.all}
                            onChange={() => setDownloadOptions({ ...downloadOptions, exportType: exportTypes.all })} 
                            control={<Radio />} 
                            label="All Pages" 
                        />
                        {data.length>14 ? (<>
                            <FormControlLabel
                                value={exportTypes.specify}
                                onChange={() => setDownloadOptions({ ...downloadOptions, exportType: exportTypes.specify })} 
                                control={<Radio />}
                                label="Specify Pages"
                            />

                            {downloadOptions.exportType === exportTypes.specify ? (<div className="pages-container">
                                <TextField
                                    type="number" 
                                    onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                                    variant="outlined"
                                    onChange={e => setDownloadOptions({ ...downloadOptions, fromPage: parseInt(e.target.value) })}
                                    error={(downloadOptions.fromPage > numOfPage) }
                                    helperText = {(downloadOptions.fromPage > numOfPage ? 'Invalid Range!':'')}
                                    InputProps={{ inputProps:{min:1} }} 
                                    className="page-input" 
                                    />
                                {downloadOptions.fromPage !== 0 ? (<>
                                    <span className="page-text">to</span>
                                    <TextField 
                                        type="number"
                                        onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                                        variant="outlined" 
                                        onChange={e => setDownloadOptions({ ...downloadOptions, toPage: parseInt(e.target.value) })}
                                        error= {(downloadOptions.toPage <= downloadOptions.fromPage) || (downloadOptions.toPage > numOfPage)}
                                        helperText={(downloadOptions.toPage <= downloadOptions.fromPage && downloadOptions.toPage!== 0) 
                                                    || (downloadOptions.toPage > numOfPage) ? 'Invalid Range!':''}
                                        InputProps={{inputProps: {min: downloadOptions.fromPage + 1 } }} 
                                        className="page-input" 
                                    />
                                </>) : ""}
                            </div>) : ''} 
                        </>):''}
                        {/* Todo: enable this code once we fix selection issue */}
                        {/* <FormControlLabel 
                            value="selected" onChange={() => setDownloadOptions({ ...downloadOptions, exportType: 'selected' })} 
                            disabled={Object.keys(downloadOptions.selectedItems).length !== 0} 
                            control={<Radio />} 
                            label="Selected Records" 
                        /> */}
                        {/* {Object.keys(downloadOptions.selectedItems).length === 0 ? (<span className="MuiTypography-root MuiFormControlLabel-label Mui-disabled MuiTypography-body1 none-selected-text">None selected</span>) : `${Object.keys(downloadOptions.selectedItems).length} items selected`} */}
                    </RadioGroup>
                </div>
                <div className={"format-label"}>Format</div>
                <Dropdown
                    className='savedqueries-dropdown'
                    items={supportedFileFormats}
                    defaultValue={downloadOptions.fileformat}
                    handleChange={(e) => {}}
                />
                <div className="button-container">
                    <Button
                        boxColor="grey"
                        size="s"
                        className="button"
                        onClick={()=> handleDownloadButtonCancelClick()}>
                            Cancel
                    </Button>
                    <CSVLink
                        onClick={()=> handleDownloadButtonExportClick()}
                        className={"export-button"}
                        data={dataToExport}
                        filename={filename}
                        target="_blank">
                        <Button
                            isDisabled = {isNaN(downloadOptions.toPage) || isNaN(downloadOptions.fromPage) || 
                                (downloadOptions.exportType === exportTypes.specify &&
                                    (downloadOptions.toPage <= downloadOptions.fromPage
                                    ||downloadOptions.fromPage > numOfPage
                                    ||downloadOptions.toPage > numOfPage))}
                            boxColor="blue"
                            size="s"
                            className="button">
                                Export
                        </Button>
                    </CSVLink>
                </div>
            </FormControl>
        </Box>
    )
}

export default DownloadWidget
