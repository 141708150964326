import React, { useEffect, useState } from 'react';
import { store } from "app/store"
import { v4 as uuidv4 } from 'uuid';
import { isUndefined } from 'lodash';
import DatePicker from '../common/DatePicker/DatePicker';
import Dropdown from '../common/Dropdown/Dropdown';
import { surveyAreaMapType, surveyInitiationMapType } from 'common/Map/MapDucks';
import TextInput from 'common/TextInput/TextInput';
import {maxLengthCheck} from './NumUtils';
import {getTableIndexByName} from './GridUtils';
import {getCurrentDate, convertDate} from './DateUtils';
import { populateInitiatePostPayload } from 'features/SurveyInitiation/SurveyInitiationUtils';
import { getNameMapping } from './NameMappingUtils';
import { populateCreatePostPayload, populateEditPostPayload } from 'features/SurveyArea/SurveyAreaTabs/SurveyAreaUtils';
import { surveyAreaStatuses } from 'features/SurveyArea/SurveyAreaTabs/ExistingArea/ExistingArea';

export enum fieldType {
  shortInteger = 0,
  longInteger = 1,
  float = 2,
  double = 3,
  text = 4,
  date = 5,
  objectID = 6,
  textInput = 8,
  globalID = 10,
  arcGISGlobalID = 11
}

export enum inputType {
  number = 'number',
  text = 'text'
}

export enum searchMatchType {
  //search where phrase appears anywhere (percent on both ends, ex: LIKE %VALUE%)
  anyMatch = 'anyMatch',
  //search where phrase must be at the beginning position (percent on end, ex: LIKE VALUE%)
  beginningMatch = 'beginningMatch',
  //search where phrase must be exact (no percents, ex: = VALUE)
  exactMatch = 'exactMatch'
}

// includes commas (,) and negative sign (-)
export const fieldCharLimit: { [fieldTypeName: string]: any } = {
  "shortInteger": 7,  // –32,767 to 32,767
  "longInteger": 14,  // -2,147,483,648 to 2,147,483,647
  "objectID": 14, // (ArcGIS objectIDs are 32-bit)
  "float": null,
  "double": null,
}

export enum surveyAreaOperations {
  create = 'create',
  edit = 'edit',
  archive = 'archive',
  lock = 'lock',
  initiate = 'initiate'
}

export enum surveyJobStatus {
  planned = 'Planned',
  inProgress = 'In Progress',
  active = 'Active',
  completed='Completed'
}


/**
 * Summary: Add a required asterisk depending on the param.
 * @param field if no param, add the asterisk, otherwise, determine if the field is mandatory
 * @returns JSX span tag element
 */
export const requiredAsterisk = (
  field: undefined | { IsRequired: boolean; IsNullable: boolean } = undefined
) => {
  return (
    <span className="required">
      {isUndefined(field) || field.IsRequired || !field.IsNullable ? "*" : ""}
    </span>
  );
};

/**
 * summary: return an aggregated and formatted survey area data for a post request
 * @param data survey area information
 * @param operation "create" or "edit" to determine the type of operation
 *
 * TODO: reformat function to be more flexible
 */
export const formatData = (data:any, operation:string) => {
  let ID = generateID()
  let payload= "";
  const selectedMapServiceName = store.getState().LoginReducer.selectedMapService.label

  if (operation === surveyAreaOperations.create) {
    payload = `{
      "modifyEditItems": [],
      "deleteEditItems": [],
      "createEditItems": [ ${populateCreatePostPayload(data, ID)} ]
    }`
  }
  else if (operation === surveyAreaOperations.edit) {
    let [surveyareaData, addedGridsPayload, removedGridsPayload] = populateEditPostPayload(data)
    payload = `{
      "modifyEditItems": [${surveyareaData}],
      "deleteEditItems": [${removedGridsPayload}],
      "createEditItems": [${addedGridsPayload}]
    }`
  }
  else if (operation === surveyAreaOperations.archive) {
    let tableId = getTableIndexByName(store.getState().SettingsReducer.globalAliases.LanternSurveyArea)
    payload = `
      {
        "deleteEditItems":[],
        "createEditItems":[],
        "modifyEditItems":
        [
          {
            "tableId":${tableId},
            "geometry":null,
            "objectId":${data},
            "attributes":
          {
            "OBJECTID":${data},
            "STATUS":"${surveyAreaStatuses.historical}"
          },
            "oldGeometry":null,
            "oldAttributes":{},
            "hasBeenUndone":false,
            "type":3
          }
        ],
        "reshapeEditItems":[]
     }`
  }
  else if (operation === surveyAreaOperations.lock) {
    let surveyAreaTableID = getTableIndexByName(store.getState().SettingsReducer.globalAliases.LanternSurveyArea)
    payload = `{
      "modifyEditItems": [
        {
          "objectId": ${data},
          "attributes": {
            "OBJECTID":${data},
            "STATUS": "${surveyAreaStatuses.locked}"
          },
          "type": 1,
          "oldAttributes": {},
          "tableId":${surveyAreaTableID},
          "hasBeenUndone": false
        }
      ],
      "deleteEditItems": [],
      "createEditItems": []
    }`
  }
  else if (operation === surveyAreaOperations.initiate) {
    payload = `{
      "modifyEditItems": [],
      "deleteEditItems": [],
      "createEditItems": [ ${populateInitiatePostPayload(data, ID)} ]
    }`
  }

  let requestBody = {
    mapservicename: selectedMapServiceName,
    editcontainer: payload
  }

  // Temporary fix for LANM-2289 removing undefined fields
  requestBody.editcontainer = requestBody.editcontainer.replaceAll('undefined', '')

  return {requestBody, ID}
}

/**
 * Summary: generates a uuidv4
 */
export function generateID() {
  return uuidv4().toUpperCase()
}

/**
 * Summary: escapes special characters
 * as of now only double quotes but we will 
 * be using same to update more any other scenarios
 * ex: "Panhandle Line 16" (571)" ----> "Panhandle Line 16: quot; (571)"
 */
export const escapeSpecialChars = (str: any) => {
  if (typeof str === 'number'){
    return str
  }
return str.replaceAll("\"","&quot;")
}

/**
 * Summary: transforms single quotes characters
 * to single single quotes 
 * ex: "St. Mary'S Line (850)" ----> "St. Mary''S Line (850)"
 */
export const transformSingleQuoteChars = (str: string) => {
  return str.replaceAll("'","''")
}

/**
 * summary: function to determine if a field should be generated in the inputs table
 * @param field the field to check
 */
export function shouldGenerate(field:any) {
  const omittedFields = [
    "PolygonType",
    "SurveyAreaName",
    "PlanCategory",
    "SurveyPlanName",
    "ProgramType",
    "AssetType",
    "SurveyWorkType",
  ];
  return (!omittedFields.includes(field.Name))
}

/**
 * summary: renders a dynamic input field based on the properties of that field
 *
 * @param field contains all the metadata about the input field
 * @param create true if generating a newly created field
 *
 * NOTE: some field types have the same type of input and some have unconfirmed input types
 */
export const GenerateSingleField = (field:any, updateState:Function, oldData:any, create:boolean, currentValue = null) : any => {
  let value:any = undefined
  if ((oldData?.[field.Name] !== null) && (oldData?.[field.Name] !== "Null")) {
    value = oldData?.[field.Name]
  }

  if (currentValue) {
    value = currentValue
  }

  if (field.Name === "CreatedBy" && create) {
    value = store.getState().LoginReducer.loggedInUser
  }

  let name = field?.Domain?.Names[field.Domain.Values.findIndex((val:any) => val === currentValue)] || value

  /**
   * Get default value for dropdown field
   * @returns default value
   */
  const getDefaultValue = () => {
    if (value) {
      return {label: name, value: value}
    }
    if (field.DefaultValue) {
      return {value: field.DefaultValue, label: field.DefaultValue}
    }
    return undefined
  }

  if (field.IsEditable) {
    switch(field.FieldType) {
      case 0:
      case 1:
      case 3:
        // number input
        if (field.Domain === null) {
          return(
            <td>
              <input
                type = {inputType.number}
                min = {1}
                maxLength = {(field.Precision>0)?field.Precision:field.Length}
                onChange = {(e)=>{updateState(e.target.value, field.Name)}}
                onInput = {maxLengthCheck}
                defaultValue = {value}
              />
            </td>
          );
        }
        else {
          return (
            <td className="dropdown-row">
              <Dropdown
                handleChange={(val) => updateState(val, field.Name)}
                items={field.Domain.Values.map((val:any, index:number) => {
                  return {label:field.Domain.Names[index], value:val}
                })}
                borderless={true}
                defaultValue={getDefaultValue()}
              />
            </td>
          );
        }
      case 4:
        // drop down or text input
        if (field.Domain === null) {
          return(
            <td >
              <input
                maxLength={(field.Precision>0)?field.Precision:field.Length}
                onChange={(e)=>{updateState(e.target.value, field.Name)}}
                defaultValue={value}
              />
            </td>
          )
        }
        else {
          return(
            <td className="dropdown-row">
              <Dropdown
                handleChange={(val) => updateState(val, field.Name)}
                items={field.Domain.Values.map((val:any, index:number) => {
                  return {label:field.Domain.Names[index], value:val}
                })}
                borderless={true}
                defaultValue={getDefaultValue()}
              />
            </td>
          );
        }
      case 5:
        return(
          <td>
            <DatePicker updateDate={(date : dateFns)=>updateState(date, field.Name)} defaultDate={value?new Date(value):new Date()}/>
          </td>
        );
      case 8:
      case 10:
        // text input
        return(
          <td>
            <input
              maxLength={(field.Precision>0)?field.Precision:field.Length}
              onChange={(e)=>{updateState(e.target.value, field.Name)}}
              defaultValue={value}
            />
          </td>
        );
      default:
        return(<td>unknown input type</td>)
    }
  }
  else {
    if( ["CreatedOn", "ModifiedOn", "EditTrackingDate"].includes(field.Name)) {
      if (value!= null) {
        value = convertDate(value, false, false, 'date');
      }
      else {
        value = (field.Name === "CreatedOn")? getCurrentDate(false, 'date'):'';
      }
    }
    return(<td className="uneditable">{value}</td>)
  }
}

/**
 * Renders field based on field type
 * @param field field metadata
 * @param updateState onchange function
 * @param value field value
 * @param isReset whether to reset the field for dropdowns and datepickers
 */
export const renderConfigurableField = (field:any, updateState:Function, value:string|null=null, isReset:boolean=false) => {
  switch(field.FieldType) {
      // number input
    case fieldType.shortInteger:
    case fieldType.longInteger:
    case fieldType.double:
      if (field.Domain === null) {
        if (value === null) {
          return (
            <TextInput
              type = {inputType.number}
              onChange = {(e)=>{updateState(e.target.value, field.Name)}}
              maxLength={!isUndefined(getFieldMaxLength(field.FieldType)) ? 
                getFieldMaxLength(field.FieldType) : field.Length}
            />
          );
        }
        return (
          <TextInput
            type = {inputType.number}
            onChange = {(e)=>{updateState(e.target.value, field.Name)}}
            value = {value}
            maxLength={!isUndefined(getFieldMaxLength(field.FieldType)) ? 
              getFieldMaxLength(field.FieldType) : field.Length}
          />
        );
      }
      else {
        return (
          <Dropdown
            handleChange={(val)=> {
              if (!isUndefined(val?.value)) {
                updateState(val.value, field.Name)
              }
              else {
                updateState(val, field.Name)
              }
            }}
            items={field.Domain.Values.map((val:any, index:number) => {
              return {label:field.Domain.Names[index], value:val}
            })}
            isReset={isReset}
            forceLabel={field.Domain.Names[field.Domain.Values.findIndex((val:any) => val === value)]}
            forceValue={value}
            allowOptionsTextWrap = {true}
          />
        );
      }
    // drop down or text input
    case fieldType.text:
      if (field.Domain === null) {
        if (value === null) {
          return (
            <TextInput
              type = {inputType.text}
              onChange = {(e)=>{updateState(e.target.value, field.Name)}}
              maxLength={!isUndefined(getFieldMaxLength(field.FieldType)) ? 
                getFieldMaxLength(field.FieldType) : field.Length}
            />
          );
        }
        return (
          <TextInput
            type = {inputType.text}
            onChange = {(e)=>{updateState(e.target.value, field.Name)}}
            value = {value}
            maxLength={!isUndefined(getFieldMaxLength(field.FieldType)) ? 
              getFieldMaxLength(field.FieldType) : field.Length}
          />
        )
      }
      else {
        return (
          <Dropdown
            handleChange={(val)=> {
              if (!isUndefined(val?.value)) {
                updateState(val.value, field.Name)
              }
              else {
                updateState(val, field.Name)
              }
            }}
            items={field.Domain.Values.map((val:any, index:number) => {
              return {label:field.Domain.Names[index], value:val}
            })}
            isReset={isReset}
            forceLabel={field.Domain.Names[field.Domain.Values.findIndex((val:any) => val === value)]}
            forceValue={value}
            allowOptionsTextWrap = {true}
          />
        );
      }
    //Date input
    case fieldType.date:
      return(
        <DatePicker
          updateDate={(date : dateFns)=>updateState(date, field.Name)}
          isReset={isReset}
        />
      );
    // text input
    case fieldType.textInput:
    case fieldType.globalID:
      if (value === null) {
        return (
          <TextInput
            maxLength={(field.Precision>0)?field.Precision:field.Length}
            onChange={(e)=>{updateState(e.target.value, field.Name)}}
          />
        );
      }
      return (
        <TextInput
          maxLength={(field.Precision>0)?field.Precision:field.Length}
          onChange={(e)=>{updateState(e.target.value, field.Name)}}
          value = {value}
        />
      );
    default:
      if (value === null) {
        return (
          <TextInput
            type = {inputType.text}
            onChange = {(e)=>{updateState(e.target.value, field.Name)}}
            maxLength={!isUndefined(getFieldMaxLength(field.FieldType)) ? 
              getFieldMaxLength(field.FieldType) : field.Length}
          />
        );
      }
      return (
        <TextInput
          type = {inputType.text}
          onChange = {(e)=>{updateState(e.target.value, field.Name)}}
          value = {value}
          maxLength={!isUndefined(getFieldMaxLength(field.FieldType)) ? 
            getFieldMaxLength(field.FieldType) : field.Length}
        />
      );
  }
}

/**
 * Summary: Extracts field data for a particular page and sorts it according to the order set in Admin tool
 *
 * @param fieldSettings field data for the tables
 * @param fieldGroupName String refering to the name of the table we are trying to extract
 * @param setError Error setting function if user wants to set an error upon not matching any table name
 *
 * @return Sorted list of fields that need to be generated
 */
 export const sortFieldsByDisplayName = (
   fieldSettings:any,
   fieldGroupName:string, 
   setError:Function|undefined = undefined,
   error:string = ''
  ) => {
  var ExtractedFields = []
  for (var i = 0; i < fieldSettings.length; i++) {
    if (fieldSettings[i].DisplayName === fieldGroupName) {
        // gets the field options for survey area creation and sort it
        ExtractedFields = fieldSettings[i].Fields.sort((a : any, b : any) => (a.SortOrder > b.SortOrder) ? 1 : -1)
        return ExtractedFields
    }
    //Set error if fieldGroupName doesn't match any of the field tables
    else if (setError && i === fieldSettings.length - 1) {
      setError(error)
    }
  }
  return []
}

/**
 * summary: validates that all the mandatory fields are met including the grid.
 * to note some 'required' fields coming from the backend do not require user input and is handeled by arcgis
 * which is listed in the excludeValidationNames
 * @param detail User input for the fields
 * @param fields fields configurations coming from admin/db3
 * @param type the type of operation we are validating data for
 *             currently the possible types are surveyAreaMapType and surveyInitiationMapType
 *             which can be found under src/common/Map/MapDucks.tsx
 *
 * @return Boolean stateing if the fields are valid
 */
export const validateData = (detail:any,fields:any,type:string) => {
  let valid = true;
  let excludeValidationNames = ['SHAPE','SHAPE.STArea()','SHAPE.STLength()','OBJECTID','GlobalID']
  fields.forEach((field:any) => {
    //ignore default arcgis map configurations
    if(!excludeValidationNames.includes(field.Name)){
      if (((field.IsRequired || !field.IsNullable) && field.FieldType !== fieldType.globalID) && !detail[field.Name]) {
        valid = false;
      }
    }
  })

  if(
    ((type === surveyAreaMapType) && !detail.SurveyAreaName) ||
    ((type === surveyInitiationMapType) && !detail.SurveyPlanName)
  ) {
    valid = false;
  }

  // Previously validating for the following fields: ProgramTypeID, ProgramType, SurveyExecType, Frequency, FreqUnits
  // Validation logic is removed since those fields are expected to be populated by default for relevant plans
  // TODO: discuss with product to decouple the program type from these fields

  return valid
}

/**
 * summary: function that gets information from a url
 *
 * @param location URL of the page calling the function
 * @param searchParam the parameter to search the URL for
 *
 * @return value of the url parameter
 */
export const getUrlParam = (location:any, searchParam:string) => {
  // get active id from URL and set the activeId state
  const params = new URLSearchParams(location.search)
  return params.get(searchParam)
}

/**
 * summary: function to detect when a click event occured outside the ref element
 *
 * @param ref reference to the element that will be clicked outside of
 * @param callback action to be performed when a click event occurs
 */
export const useOutsideClick = (ref:any, callback:any) => {
  const handleClick = (e:any) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};

/**
 * Summary: used to join survey jobs and survey plans 
 * for the Jobs Assigned or Completed progress bar column
 *
 * @param surveyPlans List of survey plans
 * @param plannedSurveyJobList x value for jobs Assigned / Completed column
 * @param totalSurveyJobList y value for jobs Assigned / Completed column
 */
export const joinSurveyJobAndPlan = (
  surveyPlans:any,
  // activeSurveyJobList:Array<any>,
  // totalSurveyJobList:Array<any>
  ) => {
    for(let surveyIndex = 0; surveyIndex < surveyPlans.length; surveyIndex++) {
      surveyPlans[surveyIndex]['jobsProgress'] = {progress:0}
      surveyPlans[surveyIndex].jobsProgress['total'] = 0

      //joining survey plan and count of 'planned' survey jobs
      // for(let activeIndex = 0; activeIndex < activeSurveyJobList.length; activeIndex++) {
      //   if(surveyPlans[surveyIndex].SurveyPlanID === activeSurveyJobList[activeIndex].attributes.SurveyPlanID){
      //     surveyPlans[surveyIndex]['jobsProgress'] = {progress:activeSurveyJobList[activeIndex].attributes.TotalJobs}
      //     break;
      //   }
      // }

      //joining survey plan and count of related survey jobs
      // for(let totalIndex = 0; totalIndex < totalSurveyJobList.length; totalIndex++) {
        // if(surveyPlans[surveyIndex].SurveyPlanID === totalSurveyJobList[totalIndex].attributes.SurveyPlanID){
        //   surveyPlans[surveyIndex].jobsProgress['total'] = totalSurveyJobList[totalIndex].attributes.TotalJobs
        //   break;
        // }
        //if no matching job list default to 0
      //   surveyPlans[surveyIndex].jobsProgress['total'] = 0
      // }
    }
    return surveyPlans
  }

  export const getFieldMaxLength = (fieldTypeValue: any) => {
    const fieldTypeName: string = fieldType[fieldTypeValue]
    return fieldCharLimit[fieldTypeName]
  }

/**
   * Summary: Return IsEditable, IsNullable, Length, and Domain field permissions
   *
   * @param fieldName name of the field
   * @param fieldPerms permissions of all fields of a layer/table
   */
 export const findPermissions = (fieldName: string, fieldPerms: any) => {
  const perms = fieldPerms.filter((perms: any) => perms.Name === fieldName)[0];

  return [perms.IsEditable, perms.IsNullable, perms.Length, perms.Domain];
}

export const getFieldType = (fields: Array<any>, fieldName:string) => {
  for (let i = 0; i < fields.length; i++) {
    if (fields[i].Name === fieldName || getNameMapping(fields[i].Name) === fieldName) {
      return fields[i].FieldType
    }
  }
}

/**
   * Summary: retrieve the correct fields array for the selected layer
   */
export const getFields = (selectedLayer: any) => {
  const fields = store.getState().SettingsReducer.settings.Layers
  const layerFields = fields.find((item:any) => item.Name === selectedLayer.layerName);
  return layerFields ? layerFields.Fields : [];  
}

/**
 * Convert where query to work with both SQL and Oracle
 * @param inputFieldType field type of input
 * @param inputValue input value of where query
 * @param useLike whether to use LIKE for strings
 * @returns where query that should work with both SQL and Oracle
 */
export const whereQueryFieldTypeConversion = (
  inputField: string,
  inputFieldType: fieldType, 
  inputValue: any, 
  searchType:searchMatchType=searchMatchType.exactMatch
) => {
  if (inputFieldType === fieldType.date) {
    return `${inputField}=DATE %27${inputValue}%27`
  }
  //String field types, refer to fieldtype enum line 17
  else if (inputFieldType >= 4) {
    //Remove curly brackets for LIKE query with global id, otherwise it won't find anything
    if (inputFieldType >= fieldType.globalID && searchType !== searchMatchType.exactMatch) {
      inputValue = inputValue.replace("{", "").replace("}", "")
    }
    inputValue = encodeURIComponent(inputValue)
    switch(searchType) {
      //field LIKE '%value%'
      case searchMatchType.anyMatch:
        return `${inputField}+LIKE+%27%25${inputValue}%25%27`
      //field LIKE 'value%'
      case searchMatchType.beginningMatch:
        return `${inputField}+LIKE+%27${inputValue}%25%27`
      //field='value'
      case searchMatchType.exactMatch:
        return `${inputField}=%27${inputValue}%27`
    }
  }
  return `${inputField}=${inputValue}`
}
