import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isNull, isUndefined } from 'lodash';
import { loadModules } from 'esri-loader';
import GridTable from 'common/Tables/GridTable/GridTable';
import Dropdown, { blankLabel, emptyLabel } from 'common/Dropdown/Dropdown';
import DatePicker from 'common/DatePicker/DatePicker';
import { SET_MAP_NOTIFICATION } from 'common/Map/MapDucks';
import { convertDate } from 'utils/DateUtils';
import { getReducer } from 'utils/ReduxUtils';
import { getNameMapping, mapAttribute } from 'utils/NameMappingUtils';
import { getGridIdFieldByName } from 'utils/GridUtils';
import { fieldType, getFieldMaxLength, findPermissions } from 'utils/FieldsUtils';
import { reRegisterToken } from 'utils/IdentityManagerUtils';
import Button from 'common/Button/Button';


interface IChildTable {
  className?: string
  mapType: string
  infoResultsData: any
  setInfoResultsData: any
  checkAttributes: any
  directoryIndex: number
  isRelationship: boolean
  selectedNames?: Array<string>
  relationshipId?: any
  relationshipTable?: any
  setRelationshipTable?: any
  relationshipFields?: any
  infoMainRef?: any
  onFeatureEdiPropsChange?: (featureId: number, objectId: number, globalId: string, fieldName: string) => void
}

/**
   * Summary: return the field names and field values for ModifiedBy and ModifiedOn
   * @param editFeature - object of data for the child tbale
   * @param fieldName - field name for the field edited
   * @param newValue - new value for the field edited
   * @param loggedInUser - currently logged in user
   * @returns 
   */
export const getModifiedByAndOnData = (
  editFeature: any,
  fieldName: string,
  newValue: string,
  loggedInUser: string
): {
  modifiedByFieldName: string,
  modifiedOnFieldName: string,
  newModifiedByValue: string,
  newModifiedOnDate: string
} => {
  const modifiedByFieldName = editFeature.attributes.hasOwnProperty("MODIFIEDBY")
    ? "MODIFIEDBY"
    : getNameMapping("MODIFIEDBY");
  const modifiedOnFieldName = editFeature.attributes.hasOwnProperty("MODIFIEDON")
    ? "MODIFIEDON"
    : getNameMapping("MODIFIEDON");

  const newModifiedByValue =
    fieldName.toLowerCase() === modifiedByFieldName.toLowerCase()
      ? newValue
      : loggedInUser;

  const newModifiedOnDate =
    fieldName.toLowerCase() === modifiedOnFieldName.toLowerCase()
      ? newValue
      : convertDate(new Date(), false, false, "epoch");

  return {modifiedByFieldName, modifiedOnFieldName, newModifiedByValue, newModifiedOnDate};
};

const ChildTable = ({
  className = "",
  mapType,
  infoResultsData,
  setInfoResultsData,
  checkAttributes,
  directoryIndex,
  isRelationship,
  selectedNames,
  relationshipId,
  relationshipTable,
  setRelationshipTable,
  relationshipFields,
  infoMainRef,
  onFeatureEdiPropsChange
}:IChildTable) => {
  const dispatch = useDispatch();
  const layers = useSelector((state: any) => state.SettingsReducer.settings.Layers);
  const editableLayers = useSelector((state: any) => state.SettingsReducer.editableLayers);
  const editableTables = useSelector((state: any) => state.SettingsReducer.editableTables);
  const tables = useSelector((state: any) => state.SettingsReducer.settings.Tables);
  const tableIds = useSelector((state: any) => state.SettingsReducer.tableMapServicesList).map((table:any) => table.id);
  const currentReducer = useSelector((state:any) => state[getReducer(mapType)]);
  const loggedInUser = useSelector((state: any) => state.LoginReducer.loggedInUser);
  const [childIndex, setChildIndex] = useState<number>(0);
  const [childItem, setChildItem] = useState<any>({});
  const [currentFeatureId, setCurrentFeatureId] = useState<number>(0);
  const [currentGlobalId, setCurrentGlobalId] = useState<String>("");
  const [currentObjectId, setCurrentObjectId] = useState<number>(0);
  const [currentIdName, setCurrentIdName] = useState<any>("");
  const [attributes, setAttributes] = useState<any>({});
  const [fields, setFields] = useState<any>([]);
  const [originalName, setOriginalName] = useState<any>(null);
  const [originalValue, setOriginalValue] = useState<any>(null);
  const [editName, setEditName] = useState<any>(null);
  const [editValue, setEditValue] = useState<any>(null);
  const [currentFeatureCheckResult, setCurrentFeatureCheckResult] = useState<any>(false);
  const [editableFeature, setEditableFeature] = useState<any>(false);
  const [editList, setEditList] = useState<any>({});
  const [failedEditFields, setFailedEditFields] = useState<any>([]);
  const [applyEditsResult, setApplyEditsResult] = useState<boolean[]>([]);
  const [infoMainWidth, setInfoMainWidth] = useState<any>(infoMainRef.current.offsetWidth);

  const confirmEditDivRef = useRef<any>(null);

  const featureCheckResults = currentReducer.featureCheck;
  useEffect(() => {
    if (tableIds.includes(currentFeatureId)) {
      setCurrentFeatureCheckResult(true)
    }
    else {
      setCurrentFeatureCheckResult(
        featureCheckResults[currentFeatureId] ?
        featureCheckResults[currentFeatureId][currentObjectId] :
        false
      )
    }
  }, [featureCheckResults, currentFeatureId, currentObjectId])

  useEffect(() => {
    // update the values for attributes to reflect any latest changes made to the parent table
    setAttributes(checkAttributes(relationshipTable, relationshipFields))
  }, [relationshipTable])

  useEffect(() => {
    const isEditable = [...editableLayers, ...editableTables].find((feature:any) => feature.mapServerId === currentFeatureId)
    if(isEditable) {
      setEditableFeature(true)
    }
  }, [currentFeatureId])

  useEffect(() => {
    if (!isRelationship) {
      // childIndex and childItem need to be referenced elsewhere
      let childIndex = directoryIndex - Array.from(new Set(selectedNames)).length;
      let childItem = infoResultsData[childIndex];
      setChildIndex(childIndex);
      setChildItem(childItem);
      if (!isEmpty(childItem)) {
        childItem.attributes = mapAttribute(childItem.attributes)
        const globalIdFieldName = childItem.globalIdFieldName?
          getNameMapping(childItem.globalIdFieldName):
          getGridIdFieldByName(childItem.layerName);
        const oldGlobalId = attributes[currentIdName];
        const newGlobalId = childItem.attributes[globalIdFieldName];
        if (oldGlobalId !== newGlobalId) {
          const featureId = childItem.layerName ? childItem.id : childItem.id + layers.length;
          setCurrentFeatureId(featureId);
          onFeatureEdiPropsChange && onFeatureEdiPropsChange(featureId, childItem.attributes.OBJECTID, childItem.attributes.GlobalID, globalIdFieldName);
          setTimeout(() => {setCurrentGlobalId(newGlobalId)}, 250);
          setCurrentIdName(globalIdFieldName);
          setAttributes(checkAttributes(childItem.attributes, childItem.fields));
          setFields(childItem.fields);
          setCurrentObjectId(childItem.attributes.OBJECTID)
        }
      }
    } else {
      const globalIdFieldName = relationshipId >= layers.length ?
      getNameMapping([...layers, ...tables][relationshipId].GlobalIdFieldName) :
      getNameMapping([...layers, ...tables][relationshipId].FeatureClass.GlobalIdFieldName);
      const oldGlobalId = attributes[currentIdName];
      const newGlobalId = relationshipTable[globalIdFieldName];
      const objectIdFieldName = relationshipId >= layers.length ?
      [...layers, ...tables][relationshipId].OidFieldName :
      [...layers, ...tables][relationshipId].FeatureClass.OidFieldName;
      if ((oldGlobalId === undefined && newGlobalId === undefined) || oldGlobalId !== newGlobalId) {
        setCurrentFeatureId(relationshipId);
        setTimeout(() => {setCurrentGlobalId(newGlobalId)}, 250);
        setCurrentIdName(globalIdFieldName);
        setAttributes(checkAttributes(relationshipTable, relationshipFields));
        setFields(relationshipFields);
        setCurrentObjectId(relationshipTable[objectIdFieldName])
      }
    }
  }, [directoryIndex, infoResultsData, relationshipTable])

  // clear non-submitted edit
  useEffect(() => {
    setOriginalName(null)
    setOriginalValue(null)
    setEditName(null)
    setEditValue(null)
  }, [directoryIndex])

  // calculate width of info-main every time it resizes
  useEffect(() => {
    const observer = new ResizeObserver(entries => {
      setInfoMainWidth(entries[0].contentRect.width)
    })
    observer.observe(infoMainRef.current)
    return () => infoMainRef.current && observer.unobserve(infoMainRef.current)
  }, [])

  /**
   * Summary: On change handler for editable attribute fields
   *
   * @param fieldName name of the field to store
   * @param fieldValue field value to store
   * @param event true if value is from an event
   * @param initial true if storing the initial value
   */
  const storeFieldInfo = (
    fieldName: string | null,
    fieldValue: any,
    event: boolean,
    initial: boolean
  ) => {
    let storedValue = event ? fieldValue.target.value : fieldValue;

    if (initial) {
      if (fieldName !== originalName) {
        setOriginalName(fieldName);
        setOriginalValue(storedValue);
        setEditName(null);
        setEditValue(null);
      }
    } else {
      setEditName(fieldName);
      setEditValue(storedValue);
    }
  };

  const addFieldToEditList = (
    event: any,
    name: string,
    aliasName: string
  ) => {
    let editedFieldName = editName;
    if (event.type === "change") {
      /**
       * `editname` is null during onChange events so we use `name` instead.
       * However, `name` is not null during onBlur events when we want it to be null to exit.
       */
      editedFieldName = name;
    }
    if (!editedFieldName) return;

    const nullCheckedEditValue = editValue === "" ? null : editValue;

    if (
      editList.hasOwnProperty(editedFieldName) &&
      nullCheckedEditValue === attributes[editedFieldName]
    ) {
      const newEditList = editList;
      delete newEditList[editedFieldName];
      setEditList(newEditList);
      return;
    }
    setEditList((prev: any) => ({
      ...prev,
      [editedFieldName]: {
        editValue: editValue === "" ? null : editValue,
        originalValue: attributes[editedFieldName],
        applyEditsParams: [
          currentFeatureId,
          attributes.OBJECTID,
          aliasName,
          name,
          editValue === "" ? null : editValue,
          event,
        ],
      },
    }));
  };

  /**
   * Summary: reflect applied edits to the local data
   *
   * @param fieldName field name to apply edits to
   * @param value new value to update field by
   */
  const changeLocal = (fieldName: string, value: any) => {
    setAttributes((prevState: any) => ({...prevState, [fieldName]: value}));
    setOriginalValue(value);

    if (!isRelationship) {
      let newInfoResultsData = infoResultsData;
      let newChildItem = childItem;
      newChildItem.attributes[fieldName] = value;
      newInfoResultsData[childIndex] = newChildItem;
      setInfoResultsData(newInfoResultsData);
    } else {
      let newRelationshipTable = relationshipTable;
      newRelationshipTable[fieldName] = value;
      setRelationshipTable(newRelationshipTable);
    }
  }

  /**
   * Summary: Set the user feedback notification message after an Info Tool edit submission
   *
   * @param message message to show the user
   * @param success type of notification message
   */
  const infoEditMessage = (message: string, success: boolean) => {
    dispatch({
      type: SET_MAP_NOTIFICATION + mapType,
      message: message,
      success: success
    })
  }


  /**
   * Summary: On submit handler for editable attribute fields
   *
   * @param featureId layer/table id according to Lantern_Survey (MapServer)
   * @param objectId id of that specific layer/table
   * @param aliasName alias name to apply edits to
   * @param fieldName field name to apply edits to
   * @param value new value to update field by
   * @param event HTML event
   * @param isDate if the field being edited is a date
   * @param showNoNotifications boolean - if true, dont show any notifications regarding updates
   * @param lastField boolean - if true, psot a success notification if applicable
   */
  const applyEdit = (
    featureId: number,
    objectId: number,
    aliasName:string,
    fieldName: string,
    value: any,
    event: any = null,
    isDate: boolean = false,
    showNoNotifications: boolean = false,
    lastField: boolean = false
  ): Object | void | undefined => {
    // prevent page refresh on submit
    event?.preventDefault();
    reRegisterToken();
    const currentFeature = [...editableLayers, ...editableTables].find(
      (layer:any) => layer.mapServerId === featureId
    );

    /**
     * convert date back to epoch format
     * convert to empty label if value is undefined/empty 
     */
    let newValue = isDate ? Date.parse(value) : value;
    newValue = isUndefined(newValue) || isNull(newValue) ? emptyLabel: newValue;

    // check isNullable permission (checks for null, undefined, and empty)
    if (
      !findPermissions(fieldName, fields)[1] &&
      (isNull(newValue) || isUndefined(newValue) || newValue === "")
    ) {
      return infoEditMessage(`Setting of value for ${aliasName} cannot be null.`, false);
    }
    loadModules(["esri/layers/FeatureLayer"]).then(([FeatureLayer]) => {
      const featureLayer = new FeatureLayer({ url: currentFeature.url });

      let editFeature: any;
      let query = featureLayer.createQuery();
      query.objectIds = [objectId];
      query.outFields = ["*"];

      featureLayer.queryFeatures(query).then((results: any) => {
        if (isEmpty(results.features)) {
          infoEditMessage(`The feature you are trying to edit cannot be found. Please verify the feature exists in ArcGIS.`, false)
          return;
        }

        editFeature = results.features[0];
        const fieldNameUnaltered = fieldName;

        editFeature.attributes[
          editFeature.attributes.hasOwnProperty(fieldName)
            ? fieldName
            : fieldName.toUpperCase()
        ] = newValue;
        
        // update the MODIFIEDBY and MODIFIEDON fields
        const {
          modifiedByFieldName,
          modifiedOnFieldName,
          newModifiedByValue,
          newModifiedOnDate,
        } = getModifiedByAndOnData(editFeature, fieldName, newValue, loggedInUser);
        
        // update the changes to be reflected in the Backend
        editFeature.attributes[modifiedByFieldName] = newModifiedByValue;
        editFeature.attributes[modifiedOnFieldName] = newModifiedOnDate;

        /** update the changes to be reflected in the Frontend */
        const updateChildTableLocally = () => {
          changeLocal(fieldNameUnaltered, newValue);
          changeLocal(modifiedByFieldName, newModifiedByValue);
          changeLocal(modifiedOnFieldName, newModifiedOnDate);
        }

        featureLayer
          .applyEdits({ updateFeatures: [editFeature] })
          .then((editsResult: any) => {
            const error = editsResult.updateFeatureResults[0].error;
            if (!isNull(error)) {
              infoEditMessage(error.message, false);
            } else {
              updateChildTableLocally();
              if (!showNoNotifications) {
                const successMsg = `Setting value for ${aliasName} was successful.`
                infoEditMessage(successMsg, true);
              }
              
              setApplyEditsResult((prev: any) => [...prev, true]);
              if (lastField && applyEditsResult.every((x: any) => x === true)) {
                // Send success notification
                const successMsg = Array.from(Object.keys(editList)).length === 1 
                  ? "The edited field has been updated successfully."
                  : "All edited fields have been updated successfullly.";
                infoEditMessage(successMsg, true)
              }
            }
          })
          .catch(() => {
            if (showNoNotifications) {
              if  (!failedEditFields.hasOwnProperty(fieldName)) {
                setFailedEditFields((prev: any) => ([ ... prev, fieldName]))
              }
            } else {
              infoEditMessage(`Setting value for ${aliasName} failed.`, false);
            }
          });
      });
    });
  }

  const saveChanges = () => {
    setFailedEditFields([])
    
    Object.values(editList).forEach((editedFieldValue: any, i: number) => {
      const [featureId, objectId, aliasName, fieldName, value, event] =
        editedFieldValue.applyEditsParams;

      applyEdit(
        featureId,
        objectId,
        aliasName,
        fieldName,
        value,
        event,
        false,
        true,
        i === Object.values(editList).length - 1
      );
    })

    resetEdits()
    setEditList({})
  }

  if (!isNull(confirmEditDivRef.current)) {
    confirmEditDivRef.current.style.display = isEmpty(editList) ? "none" : "flex"
  }

  useEffect(() => {
    if (failedEditFields.length === 1) {
      // send a notification
      infoEditMessage("One or more fields have failed to update and their original values remain saved. They're marked in red.", false);
    }
  }, [failedEditFields])

  const resetEdits = () => {
    setEditList({});
    setEditName(null);
    setEditValue(null);
    setFailedEditFields([]);
  }

  return (
    <>
      <div className="save-changes-container" ref={confirmEditDivRef} style={{width: infoMainWidth}}>
        <span className="text left">Remember to click "Save" to save changes!</span>
        <div className="right">
          <Button
            className="button"
            onClick={() => saveChanges()}
            boxColor="blue"
            size="s"
          >
            Save
          </Button>
          <Button
            className="button"
            onClick={() => resetEdits()}
            boxColor="grey"
            size="s"
          >
            Reset
          </Button>
        </div>
      </div>
      <GridTable className="child-table" editable="non-editable">
        {/* TODO: render all future data in all tables using fields (Array) instead of attributes (Object) */}
        {fields.map((field: any, index: number) => {
          let aliasName = field.AliasName;
          let name = field.Name;
          let value = attributes[name];
          // TODO: refactor checkAttributes, as it will be reundant after the refactoring mentioned above
          if (field.IsVisible) {
            if (
              field.IsEditable &&
              currentFeatureCheckResult &&
              editableFeature
            ) {
              let domain = { ...field.Domain };
              // Dropdown input
              if (!isEmpty(domain)) {
                if (field.IsNullable && !domain.Names.includes(blankLabel)) {
                  domain.Names = [blankLabel, ...domain.Names];
                  domain.Values = [null, ...domain.Values];
                }

                const dropdownItems = domain.Names.map(
                  (_: any, index: number) => {
                    if (
                      isNull(domain.Values[index]) ||
                      isUndefined(domain.Values[index])
                    )
                      return emptyLabel;
                    return {
                      label: domain.Names[index],
                      value: domain.Values[index],
                    };
                  }
                );

                return (
                  <tr key={index}>
                    <th>{aliasName}</th>
                    <td
                      onFocus={() => {
                        // store originalName and originalValue
                        storeFieldInfo(
                          name,
                          domain.Values[domain.Values.indexOf(value)],
                          false,
                          true
                        );
                        // reset editName and editValue
                        storeFieldInfo(null, null, false, false);
                      }}
                    >
                      <Dropdown
                        size={"small"}
                        items={dropdownItems}
                        defaultValue={{
                          label: domain.Names[domain.Values.indexOf(value)],
                          value: domain.Values[domain.Values.indexOf(value)],
                        }}
                        handleChange={(value: any) => {
                          setEditName(null);
                          applyEdit(
                            currentFeatureId,
                            attributes.OBJECTID,
                            aliasName,
                            name,
                            domain.Values[domain.Values.indexOf(value)]
                          );
                          attributes[name] =
                            domain.Values[domain.Values.indexOf(value)];
                        }}
                        isReset={attributes.GlobalID !== currentGlobalId}
                        resetValue={true}
                      />
                    </td>
                  </tr>
                );
                // DatePicker input
              } else if (fields[index].FieldType === fieldType.date) {
                return (
                  <tr key={index}>
                    <th>{aliasName}</th>
                    <td
                      onFocus={() => {
                        // store originalName and originalValue
                        storeFieldInfo(name, value, false, true);
                        // reset editName and editValue
                        storeFieldInfo(null, null, false, false);
                      }}
                    >
                      <DatePicker
                        updateDate={(date: dateFns) => {
                          applyEdit(
                            currentFeatureId,
                            attributes.OBJECTID,
                            aliasName,
                            name,
                            date,
                            null,
                            true
                          );
                          attributes[name] = date;
                        }}
                        defaultDate={
                          value
                            ? value.DateTime
                              ? value.DateTime
                              : convertDate(value, false, false, "date")
                            : undefined
                        }
                      />
                    </td>
                  </tr>
                );
                // text field input
              }
              else {
                return (
                  <tr key={index}>
                    <th
                      className={
                        editName === name
                          ? editValue === originalValue
                            ? ""
                            : "name-editing"
                          : failedEditFields.includes(name)
                            ? "name-editing"
                            : undefined
                      }
                    >
                      {aliasName}
                    </th>
                    <td
                      onFocus={(event) =>
                        storeFieldInfo(name, event, true, true)
                      }
                    >
                      <input
                        className={
                          editName === name
                            ? editValue === originalValue
                              ? "input-selected"
                              : (confirmEditDivRef.current.style.display !== "none"
                                ? "input-editing"
                                : undefined)
                            : (editList.hasOwnProperty(name)
                              ? "input-edited"
                              : undefined)
                        }
                        value={
                          editName === name
                            ? editValue
                            : (editList.hasOwnProperty(name)
                              ? editList[name].editValue ?? ""
                              : value || "")
                        }
                        maxLength={
                          !isUndefined(getFieldMaxLength(field.FieldType))
                            ? getFieldMaxLength(field.FieldType)
                            : field.Length
                        }
                        onChange={(event) => {
                          storeFieldInfo(name, event, true, false);
                          addFieldToEditList(event, name, aliasName);
                        }}
                        onBlur={(event) =>
                          addFieldToEditList(event, name, aliasName)
                        }
                      />
                    </td>
                  </tr>
                );
              }
            } else {
              // non-editable date field
              if (fields[index].FieldType === fieldType.date) {
                let dateValue = value
                  ? value.DateTime
                    ? value.DateTime
                    : convertDate(value, false, false, "date")
                  : null;
                return (
                  <tr key={index}>
                    <th>{aliasName}</th>
                    <td>{dateValue}</td>
                  </tr>
                );
                // non-editable text field
              } else {
                return (
                  <tr key={index}>
                    <th>{aliasName}</th>
                    <td>{value}</td>
                  </tr>
                );
              }
            }
          } else {
            return null;
          }
        })}
      </GridTable>
    </>
  );
}

export default ChildTable;
