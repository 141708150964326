import { HttpRequest } from "../httpRequest";
import { LEGACY_API_URL } from "../constants";
import ashxBaseUrl from ".";

export const getMapUrlsConfig = async () => {
  const getMapUrlsConfigUrl = `/View.ashx/configuration/mapurls/`;
    return await ashxBaseUrl.get( getMapUrlsConfigUrl);
};

export const setSecurityConfig = async (
    requestBody: Object
  ) => {
    const setSecurityConfigUrl = `/Security.ashx/configuration/`;
    return await ashxBaseUrl.post(
      setSecurityConfigUrl,
      requestBody,
    );
  };