export let dateRangeFiltersValues = [
  {
    label:'Past Due',
    value:-1
  },
  {
    label:'Within 30 Days',
    value:{
      addAmount:{days:30}
    }
  },
  {
    label:'Within 60 Days',
    value:{
      addAmount:{days:60}
    }
  },
  {
    label:'Within 90 Days',
    value:{
      addAmount:{days:90}
    }
  },
  {
    label:'Within 180 Days',
    value:{
      addAmount:{days:180}
    }
  },
  {
    label:'Over 180 Days',
    value:{
      over:true,
      addAmount:{days:180}
    }
  }
]
