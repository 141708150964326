import Slider from '@material-ui/core/Slider';
import { withStyles, styled } from '@material-ui/core/styles';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

export const CustomSlider = withStyles({
    root: {
      color: '#0088c1',
      height: 2,
      width: "75%", 
    },
    thumb: {
      height: 15,
      width: 15,
      backgroundColor: '#0088c1',
      border: '2px solid currentColor',
      '&:focus, &:hover, &$active': {
        boxShadow: 'inherit',
      },
    },
    active: {},
    valueLabel: {
      left: 'calc(-50% + 1px)',
    },
    track: {
      height: 2,
      borderRadius: 4,
    },
    rail: {
      height: 2,
      borderRadius: 4,
    },
  })(Slider);

export const StyledDrag = styled(DragIndicatorIcon)({
  color: '#D0D2D3',
})
;