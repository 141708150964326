import React from 'react';
import ArrowDownwards from '@material-ui/icons/ArrowDownward';
import ArrowUpwards from '@material-ui/icons/ArrowUpward';
import { TableSortActions } from '../TableUtils/TableUtils';

export const TableHeader = ({headerGroups}:any) => {
  let columnIndex = 0;

  const handleSortArrowsChange = (column: any) => { //function to attach sort arrows
    if (column?.isSorted && column?.canSort) {
      column.sortApplied = true;
      //sort ascending/descending
      if(column && typeof column.sortChangeCB === 'function')
        column.sortChangeCB({ id: column.id, sortValue: column?.isSortedDesc ? TableSortActions.DESCENDIG : TableSortActions.ASCENDING });
      return column?.isSortedDesc ? <ArrowDownwards /> : <ArrowUpwards />;
    } else if (column?.sortApplied) { //clear sort
      column.sortApplied = false;
      if(column && typeof column.sortChangeCB === 'function')
        column.sortChangeCB({ id: column.id, sortValue: TableSortActions.CLEAR });
    } else {
      return "";
    }
  };

  return (
    <thead className="table-header">
      {headerGroups.map((headerGroup:any,index:number) => {
        return (
          <tr
            {...headerGroup.getHeaderGroupProps()}
            className="table-header"
          >
            {headerGroup.headers.map((column:any,headerGroupIndex:number) => {
              if(column.showColumn !== false) {
                columnIndex++
                return (
                  <th
                    className = {`col-${columnIndex-1} ${column.Header == "Jobs Completed" ? "progress-bar-col" : ""}`}
                    {...column.getHeaderProps(
                      column.getSortByToggleProps(
                        {title:typeof column.Header === 'string' ? column.Header : ''
                        }
                      )
                    )}
                  >
                    <div
                      className={column.id === 'selection'?
                        'checkbox-table-header-container':
                        'text-table-header-container'
                      }
                    >
                      <span className="header-title">
                        {column.render('Header')}
                      </span>
                      { handleSortArrowsChange(column) }
                    </div>
                  </th>
                )
              }
              return <React.Fragment key = {headerGroupIndex}/>
            })}
          </tr>
        )
      })}
    </thead>
  )
}

