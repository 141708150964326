import React from 'react';
import {
  CLOSE_SIDE_PANEL,
  SET_ACTIVE_TOOL,
  SET_ACTIVE_SLIDEOUT_TOOL,
  SET_REOPEN_OVERVIEW,
  mapPageMapType
} from '../../common/Map/MapDucks';
import { widgetTypes } from './MapWidgetLayer';
import OverviewSlideoutBody from './Widgets/OverviewWidget/OverviewSlideoutBody';
import QuerySlideoutBody from './Widgets/QueryWidget/QuerySlideoutBody';
import InfoSlideoutBody from './Widgets/InfoWidget/InfoSlideoutBody';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

/**
 * Summary: return the slideout props for the currently active slideout tool
 * @param activeSlideoutTool the tool whose slideout props to generate
 * @param currentReducer the current map type's reducer
 * @param mapType map type
 */
export const getSlideoutProps = (dispatch: any, 
  activeSlideoutTool: widgetTypes, 
  currentReducer: any, 
  mapType: string, 
  queryProps: any = {}
) => {
  if (activeSlideoutTool === widgetTypes.overview) {
    return ({
      icon: null,
      title: "Overview",
      size: "default" as const,
      greyline: false,
      body: <OverviewSlideoutBody mapType={mapType}/>,
      cancel: () => {
        dispatch({type:CLOSE_SIDE_PANEL + mapType})
        dispatch({type:SET_REOPEN_OVERVIEW + mapType, reopen: false})
        dispatch({type:SET_ACTIVE_TOOL + mapType, tool: widgetTypes.move})
      }
    })
  }
  else if (activeSlideoutTool === widgetTypes.info) {
    return ({
      icon: <InfoOutlinedIcon/>,
      title: "Results",
      size: "large" as const,
      body: <InfoSlideoutBody mapType={mapType}/>,
      cancel: () => {
        if (currentReducer.slideOutInfo.reopenOverview && mapType === mapPageMapType) {
          dispatch({type:SET_ACTIVE_SLIDEOUT_TOOL + mapType, tool: widgetTypes.overview})
        } else {
          dispatch({type:CLOSE_SIDE_PANEL + mapType})
          dispatch({type:SET_ACTIVE_TOOL + mapType, tool: widgetTypes.move})
        }
      }
    })
  }
  else {
    return ({
      icon: null,
      title: "Query Tool",
      size: "large" as const,
      body: 
        <QuerySlideoutBody 
          mapType={mapType}
          {...queryProps}
        />,
      cancel: () => {
        if (currentReducer.slideOutInfo.reopenOverview && mapType === mapPageMapType) {
          dispatch({type:SET_ACTIVE_TOOL + mapType, tool: widgetTypes.overview})
          dispatch({type:SET_ACTIVE_SLIDEOUT_TOOL + mapType, tool: widgetTypes.overview})
        } else {
          dispatch({type:CLOSE_SIDE_PANEL + mapType})
          dispatch({type:SET_ACTIVE_TOOL + mapType, tool: widgetTypes.move})
        }
      }
    })
  }
}

/**
 * Summary: return the CSS class for the currently active slideout tool
 * @param activeSlideoutTool the tool whose CSS classes to generate
 */
export const getSlideoutClasses = (activeSlideoutTool: widgetTypes) => {
  if (activeSlideoutTool === widgetTypes.overview) {
    return "overview-slideout"
  }
  else if (activeSlideoutTool === widgetTypes.info) {
    return "info-slideout"
  }
  else if (activeSlideoutTool === widgetTypes.query) {
    return "query-slideout"
  }
  return ""
}
