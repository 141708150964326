import { HttpRequest } from "../httpRequest";
import { LEGACY_API_URL } from "../constants";
import { formDataFormat } from "api/formDataUtils";
import ashxBaseUrl from ".";

export const uploadFeatureAttachment = async (requestBody: Object) => {
  const uploadFeatureAttachmentUrl = `/Editing.ashx/features/upload`;
  return await ashxBaseUrl.post(
    uploadFeatureAttachmentUrl,
    formDataFormat(requestBody)
  );
};