import ashxBaseUrl from ".";

export const getConfigSettings = async () => {
  const getConfigSettingsUrl = `/Framework.ashx/configuration/settings/`;
  return await ashxBaseUrl.get(getConfigSettingsUrl);
};

export const getConfigPerspectives = async () => {
  const getConfigPerspectivesUrl = `/View.ashx/configuration/perspectives/`;
  return await ashxBaseUrl.get(getConfigPerspectivesUrl);
};

//ToDo: Find better naming
export const getSecurityConfig = async (requestBody: Object) => {
  const getSecurityConfigUrl = `/Security.ashx/configuration/`;
  return await ashxBaseUrl.post(getSecurityConfigUrl, requestBody);
};
