import React from 'react';
import { useDispatch } from 'react-redux';
import { ReactComponent as RectangleSelectIcon } from 'assets/RectangleSelectIcon.svg';
import { widgetTypes, IWidgetProps } from '../MapWidgetLayer';
import { SET_ACTIVE_TOOL } from '../MapDucks';

export const RectangleSelectWidget = ({
  currentTool,
  activeSlideoutTool,
  isSlideoutOpen,
  sketchWidget,
  unselectWidget,
  mapType
}:IWidgetProps) => {
  const dispatch = useDispatch()

  function handleClick() {
    if (currentTool === widgetTypes.rectangle) {
      sketchWidget?.cancel()
      dispatch({type: SET_ACTIVE_TOOL + mapType, tool: widgetTypes.move})
    }
    else {
      unselectWidget?.cancel()
      sketchWidget?.create(widgetTypes.rectangle);
      dispatch({type: SET_ACTIVE_TOOL + mapType, tool: widgetTypes.rectangle})
    }
  }

  return (
    <button
      title="Rectangle select"
      id="rectangle-select-btn"
      className={"expanded-btn" + (currentTool===widgetTypes.rectangle?" btn-active":"")}
      onClick={handleClick}
    >
      <RectangleSelectIcon/>
    </button>
  )
}
