import React from 'react';
import '../SurveyArea.scss';
import './SurveyAreaTab.scss';
import {useDispatch, useSelector} from 'react-redux';
import {SET_VIEW} from '../SurveyAreaDucks';
import ExistingAreas from './ExistingArea/ExistingArea';
import SurveyAreaCreation from './SurveyAreaCreation/SurveyAreaCreation';
import OverriddenTabs from 'common/Tabs/OverriddenTabs';

export enum surveyAreaTabValue {
  createTab = 0,
  existingTab = 1
}

const SurveyAreaTab: React.FunctionComponent = () => {
  const dispatch = useDispatch()
  const tabValue = useSelector((state:any) => state.SurveyAreaReducer.tabValue)

  //Callback from OverriddenTabs that supplies the tab state
  function handleTabValue(){
    tabValue === surveyAreaTabValue.createTab ?
      dispatch({type: SET_VIEW, tabValue: surveyAreaTabValue.existingTab}) :
      dispatch({type: SET_VIEW, tabValue: surveyAreaTabValue.createTab})
  }

  return(
    <div id="survey-tab-container">
      <OverriddenTabs
      labels={['SURVEY AREA CREATION','EXISTING AREAS']}
      tabValue={tabValue}
      handleTabValue={handleTabValue}
      />
    {/*Handeling view for tabs */}
      {tabValue === 0 &&
      <SurveyAreaCreation/>}
      {tabValue === 1 &&
      <ExistingAreas/>}
    </div>
  )
}

export default SurveyAreaTab;
