import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as PointSelectIcon } from 'assets/PointSelectIcon.svg';
import { widgetTypes, IWidgetProps } from '../MapWidgetLayer';
import { SET_ACTIVE_TOOL } from '../MapDucks';
import { isToolAvailable } from 'utils/IdentityManagerUtils';
import { LanternTools } from 'app/constants';
import { surveyAreaMapType, surveyInitiationMapType } from '../MapDucks';

export const PointSelectWidget = ({
  currentTool,
  activeSlideoutTool,
  isSlideoutOpen,
  sketchWidget,
  unselectWidget,
  measureWidget,
  className,
  mapType
}:IWidgetProps) => {
  const dispatch = useDispatch()
  const roleId = useSelector((state:any) => state.LoginReducer.selectedUserRole)

  function handleClick() {
    if (currentTool === widgetTypes.point) {
      sketchWidget?.cancel()
      dispatch({type: SET_ACTIVE_TOOL + mapType, tool: widgetTypes.move})
    }
    else {
      measureWidget?.clear()
      unselectWidget?.cancel()
      sketchWidget?.create(widgetTypes.point);
      dispatch({type: SET_ACTIVE_TOOL + mapType, tool: widgetTypes.point})
    }
  }

  if (
    isToolAvailable(LanternTools.SELECT,roleId) ||
    mapType === surveyAreaMapType ||
    mapType === surveyInitiationMapType
  ) {
    return (
      <button
        title="Point select"
        id="point-select-btn"
        className={"widget-btn" + (currentTool===widgetTypes.point?" btn-active":"") + className}
        onClick={handleClick}
      >
        <PointSelectIcon/>
      </button>
    )
  }
  else {
    return null
  }
}
