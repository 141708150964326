import styled from "styled-components";
import { ReactComponent as PanelSmallIcon } from "../../assets/PanelSmallIcon.svg";
import { ReactComponent as PanelMediumIcon } from "../../assets/PanelMediumIcon.svg";
import { ReactComponent as PanelLargeIcon } from "../../assets/PanelLargeIcon.svg";

export const Container = styled.div`
  & > svg {
    margin: 0 2px;
  }
`;

interface IStyledSelectIconProps {
  selected: boolean;
}

export const StyledPanelLargeIcon = styled(PanelLargeIcon)<IStyledSelectIconProps>`
  & .st1 {
    fill: ${(props) => (props.selected ? "#0088C1" : "#D0D2D3")};
  }
  & .st2 {
    fill: ${(props) => (props.selected ? "#0088C1" : "#A6A8AB")};
    stroke: ${(props) => (props.selected ? "#0088C1" : "#A6A8AB")};
  }
`;

export const StyledPanelMediumIcon = styled(PanelMediumIcon)<IStyledSelectIconProps>`
  & .st1 {
    fill: ${(props) => (props.selected ? "#0088C1" : "#D0D2D3")};
  }
  & .st2 {
    fill: ${(props) => (props.selected ? "#0088C1" : "#A6A8AB")};
    stroke: ${(props) => (props.selected ? "#0088C1" : "#A6A8AB")};
  }
`;

export const StyledPanelSmallIcon = styled(PanelSmallIcon)<IStyledSelectIconProps>`
  & .st1 {
    fill: ${(props) => (props.selected ? "#0088C1" : "#D0D2D3")};
  }
  & .st2 {
    fill: ${(props) => (props.selected ? "#0088C1" : "#A6A8AB")};
    stroke: ${(props) => (props.selected ? "#0088C1" : "#A6A8AB")};
  }
`;
