import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './MeasureWidget.scss';
import StraightenIcon from '@material-ui/icons/Straighten';
import CloseIcon from '@material-ui/icons/Close';
import { widgetTypes, IWidgetProps } from '../../MapWidgetLayer';
import { SET_ACTIVE_TOOL } from '../../MapDucks';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import Dropdown from 'common/Dropdown/Dropdown';
import { LanternTools } from 'app/constants';
import { isToolAvailable } from 'utils/IdentityManagerUtils';
import { loadEsriModule } from 'utils/EsriUtils';
import { isUndefined } from 'lodash';

export const MeasureWidget = ({
  currentTool,
  activeSlideoutTool,
  isSlideoutOpen,
  sketchWidget,
  unselectWidget,
  measureWidget,
  coordinateWidget,
  view,
  checkCoordinateMode,
  className,
  mapType
}:IWidgetProps) => {

  enum measureToolTypes {
    point = 'point',
    distance = 'distance',
    area = 'area',
    clear = 'clear'
  }

  enum pointUnitTypes {
    xy = 'xy',
    basemap = 'basemap',
    dd = 'dd',
    ddm = 'ddm',
    dms = 'dms',
    mgrs = 'mgrs',
    usng = 'usng',
    utm = 'utm'
  }

  enum linearUnitTypes {
    metric = 'metric',
    imperial = 'imperial',
    inches = 'inches',
    feet = 'feet',
    USFeet = 'us-feet',
    yards = 'yards',
    miles = 'miles',
    nauticalMiles = 'nautical-miles',
    meters = 'meters',
    kilometers = 'kilometers'
  }

  enum areaUnitTypes {
    metric = 'metric',
    imperial = 'imperial',
    squareInches = 'square-inches',
    squareFeet = 'square-feet',
    squareUSFeet = 'square-us-feet',
    squareYards = 'square-yards',
    squareMiles = 'square-miles',
    squareMeters = 'square-meters',
    squareKilometers = 'square-kilometers',
    acres = 'acres',
    ares = 'ares',
    hectares = 'hectares'
  }

  const dispatch = useDispatch()
  const mapServiceName = useSelector((state:any) => state.LoginReducer.selectedMapService.decodedLabel)
  const [activeMeasureTool, setActiveMeasureTool] = useState<any>()
  const [unit, setUnit] = useState<pointUnitTypes | linearUnitTypes | areaUnitTypes>(linearUnitTypes.metric)
  const [reset, setReset] = useState<boolean>(false)
  const [forceValue, setForceValue] = useState<string>('')
  const [showPointMenu, setShowPointMenu] = useState<'none' | 'initial'>('none')
  const roleId = useSelector((state:any) => state.LoginReducer.selectedUserRole)
  const pointUnits = Object.values(pointUnitTypes)
  const linearUnits = Object.values(linearUnitTypes)
  const areaUnits = Object.values(areaUnitTypes)

  loadEsriModule('esri/core/watchUtils').then((watchUtils:any) => {
    watchUtils.whenFalseOnce(view, 'updating', () => {
      if (activeMeasureTool === measureToolTypes.point && coordinateWidget) {
        coordinateWidget.mode = 'capture'
      }
    })
  })

  function pointMeasurement() {
    clearMeasurements()
    if (activeMeasureTool === measureToolTypes.distance
        || activeMeasureTool === measureToolTypes.area
        || activeMeasureTool === measureToolTypes.clear
      ) {
      setReset(true)
      setForceValue(pointUnitTypes.xy)
    }
    setActiveMeasureTool(measureToolTypes.point)
    if (measureWidget) {
      measureWidget.clear();
    }
    setShowPointMenu('initial')
    setUnit(pointUnitTypes.xy)
  }

  // function to activate disrance measurement
  function distanceMeasurement() {
    clearMeasurements()
    if (measureWidget) {
      // reset the unit dropdown when switching from area or point
      if (activeMeasureTool === measureToolTypes.point
          || activeMeasureTool === measureToolTypes.area
          || activeMeasureTool === measureToolTypes.clear
        ) {
        setReset(true)
        setForceValue(linearUnitTypes.metric)
      }
      setUnit(linearUnitTypes.metric)
      setActiveMeasureTool(measureToolTypes.distance)
      measureWidget.linearUnit = linearUnits[0]
      measureWidget.activeTool = measureToolTypes.distance
      setShowPointMenu('none')
    }
  }

  // function to activate area measurement
  function areaMeasurement() {
    clearMeasurements()
    // reset the unit dropdown when switching from distance or point
    if (measureWidget) {
      if (activeMeasureTool === measureToolTypes.point
        || activeMeasureTool === measureToolTypes.distance
        || activeMeasureTool === measureToolTypes.clear
        ) {
        setReset(true)
        setForceValue(areaUnitTypes.metric)
      }
      setUnit(linearUnitTypes.metric)
      setActiveMeasureTool(measureToolTypes.area)
      measureWidget.areaUnit = areaUnits[0]
      measureWidget.activeTool = measureToolTypes.area;
      setShowPointMenu('none')
    }
  }

  // Clears all measurements
  function clearMeasurements() {
    setActiveMeasureTool(measureToolTypes.clear)
    setShowPointMenu('none')
    setReset(true)
    measureWidget?.clear();
  }

  // handles when user clicks on the measure widget
  function handleClick() {
    if (currentTool === widgetTypes.measure) {
      dispatch({type: SET_ACTIVE_TOOL + mapType, tool: widgetTypes.move})
      measureWidget.clear()
    }
    else {
      sketchWidget?.cancel()
      unselectWidget?.cancel()
      dispatch({type: SET_ACTIVE_TOOL + mapType, tool: widgetTypes.measure})
    }
  }

  // generate the unit options depending on the measure type
  function getOptions() {
    switch(activeMeasureTool) {
      case measureToolTypes.point:
        return pointUnits.map(option => {
          return {label:option, value:option}
        })

      case measureToolTypes.distance:
        return linearUnits.map(option => {
          return {label:option, value:option}
        })

      case measureToolTypes.area:
        return areaUnits.map(option => {
          return {label:option, value:option}
        })

      default:
        return []
    }
  }

  /** Summary: close button click handler of the Measurement Tool panel */
  const closeMeasurePanel = () => {
    clearMeasurements();
    dispatch({type: SET_ACTIVE_TOOL + mapType, tool: widgetTypes.move});
  }

  function getDefaultValue() {
    if (activeMeasureTool===measureToolTypes.point) {
      return {value:pointUnitTypes.xy, label:pointUnitTypes.xy}
    }
    else if (activeMeasureTool) {
      return {value:linearUnits[0], label:linearUnits[0]}
    }
    return ''
  }

  useEffect(() => {
    setReset(false)
    setForceValue('')
  }, [reset])

  // clears the measure type selection when user exits measure tool
  useEffect(() => {
    if (currentTool !== widgetTypes.measure) {
      setActiveMeasureTool('')
      setShowPointMenu('none')
    }
  }, [currentTool])

  //Display coordinates when point is in use
  useEffect(() => {
    const measureTool = document.getElementsByClassName("measure-tool-container")[0] as HTMLElement
    const containerCoordinatesMeasure = view.container.getBoundingClientRect()
    const coordinatesMeasure = measureTool?.getBoundingClientRect()
    if (coordinateWidget && coordinatesMeasure) {
      coordinateWidget.container.style.display = showPointMenu;
      coordinateWidget.container.style.top = `${coordinatesMeasure.y - containerCoordinatesMeasure.y + 112}px`;
    }
  }, [coordinateWidget, showPointMenu])

  //Set coordinate widget's mode to capture when point is in use
  useEffect(() => {
    if (coordinateWidget) {
      if (activeMeasureTool === measureToolTypes.point) {
        coordinateWidget.mode = "capture"
      }
      else {
        coordinateWidget.mode = "live"
      }
    }
  }, [coordinateWidget, activeMeasureTool]);

  // updates the unit for current measure tool
  useEffect(() => {
    const coordinateSelect = coordinateWidget?.container?.firstChild?.firstChild?.firstChild?.children[0]
    if (activeMeasureTool === measureToolTypes.point && coordinateSelect) {
      //Get coordinate type select and change the unit using out of the box dropdown
      coordinateSelect.value = unit
      if (coordinateSelect.options[coordinateSelect.options.selectedIndex]) {
        coordinateSelect.dispatchEvent(new Event('change'));
      }
    }
    else if (activeMeasureTool === measureToolTypes.distance) {
      measureWidget.linearUnit = unit
      measureWidget.activeTool = measureToolTypes.distance
    }
    else if (activeMeasureTool === measureToolTypes.area) {
      measureWidget.areaUnit = unit
      measureWidget.activeTool = measureToolTypes.area
    }
  }, [coordinateWidget, unit])

  if (isToolAvailable(LanternTools.MEASURE,roleId)) {
    return (
      <div className="measure-tool-container">
        <button
          title="Measure"
          id="measure-btn"
          className={"widget-btn" + (currentTool===widgetTypes.measure?" btn-active":"") + className}
          onClick={handleClick}
        >
          <StraightenIcon/>
        </button>
        {currentTool===widgetTypes.measure &&
          <div id="measure-panel">
            <CloseIcon
              className = 'close-btn'
              onClick = {() => {closeMeasurePanel()}}
            />
            <button
              className='delete-measure-btn'
              onClick={clearMeasurements}
            >
              <SettingsBackupRestoreIcon/>
            </button>
            <div className='measurement-types'>
              <div className="panel-title">Measurement Type</div>
              <div className="type-options">
                <div className="radio-btn">
                  <input
                    type='radio'
                    id='point'
                    className='point-measure-btn'
                    onChange={pointMeasurement}
                    checked={activeMeasureTool === measureToolTypes.point}
                  />
                  <label htmlFor='point'>Point</label>
                </div>
                <div className="radio-btn">
                  <input
                    type='radio'
                    id='line'
                    className='line-measure-btn'
                    onChange={distanceMeasurement}
                    checked={activeMeasureTool === measureToolTypes.distance}
                  />
                  <label htmlFor='line'>Line</label>
                </div>
                <div className="radio-btn">
                  <input
                    type='radio'
                    id='area'
                    className='area-measure-btn'
                    onChange={areaMeasurement}
                    checked={activeMeasureTool === measureToolTypes.area}
                  />
                  <label htmlFor='area'>Area</label>
                </div>
              </div>
            </div>
            <div className="unit-select">
              <div className="panel-title">Units</div>
              <Dropdown
                items = {getOptions()}
                handleChange = {(e:any) => {setUnit(e)}}
                className = 'unit-dropdown'
                isReset = {reset}
                forceValue = {forceValue}
                resetValue = {activeMeasureTool === measureToolTypes.clear ? false : true}
                defaultValue = {getDefaultValue()}
              />
            </div>
            <div className='projection-display'>
              <div className="panel-title">Projection</div>
              <div className='map-service-name'>{mapServiceName}</div>
            </div>
          </div>
        }
      </div>
    )
  }
  else {
    return null
  }
}
