import React, { ReactElement } from 'react'

export const renderActionBar = (
    actionButton:(ReactElement|undefined),
    data:any,
    selectedRowIds:Array<any>,
    toggleAllRowsSelected:any
  ) => {
  if(actionButton){
    return React.cloneElement(actionButton,
      {
        selectedRowIds,
        data,
        toggleAllRowsSelected
      }
    )
  }
  return <></>
}
