import React from 'react';
import './ProgressBar.scss';
import {withStyles} from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

//Creating green theme using material-ui's api
const GreenLinearProgress = withStyles ({
  root: {
    flexGrow:1,
    height:12,
    backgroundColor:'#e4e5e6',
    borderRadius:2
  },
  bar: {
    backgroundColor: '#4fbc8f',
  }
})(LinearProgress);

interface IProgressBarProps {
  progressValue: number
  maxValue: number
  className?: string
}

const ProgressBar:React.FunctionComponent<IProgressBarProps> = (props) => {
  /**
   * this will take the props variables
   * and normalize the value so that the max will be 100 while
   * keeping the same ratio so it can be used in the material ui component
   */
  const normalizeValue = () => {
    if(props.maxValue === 0 && props.progressValue === 0){
      return 0
    }
    var normalizedValue = (props.progressValue/props.maxValue)*100
    return normalizedValue
  }

  return(
    <div className = "progress-bar">
      <GreenLinearProgress variant="determinate" value={normalizeValue()} />
      <div className = "value-container">
        <span className = "progress-value">{props.progressValue}</span>
        <span className = "divider">/</span>
        <span className = "max-value">{props.maxValue}</span>
      </div>
    </div>
  )
}

ProgressBar.defaultProps = {
  className:""
}

export default ProgressBar
