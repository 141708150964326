import React from 'react';
import {NavLink} from 'react-router-dom';

interface INavLinkWrapper {
  to: string,
  isRendered?:boolean,
  children?: any,
  exact ?: boolean,
  className?: string,
  activeClassName?:string,
  title?:string,
  onClick?: any
}

const NavLinkWrapper = ({
  isRendered = true,
  children,
  ...props}:INavLinkWrapper) =>
{
  if(isRendered){
    return(
      <NavLink
        {...props}
      >
        {children}
      </NavLink>
    )
  }
  else {
    return null
  }
}

export default NavLinkWrapper
