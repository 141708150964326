import React from 'react';
import janaLogo from 'assets/jana_icon.png';
import './ErrorPage.scss';
import GoBackLink from 'common/Link/GoBackLink/GoBackLink';

interface IErrorPageProp {
  errorObject: any
  className?: string
  showBackLink?: boolean
}

const ErrorPage = ({
  errorObject,
  className,
  showBackLink = true
}:IErrorPageProp) => {
  let errorTitle = errorObject.message.code || 'Error'
  let errorMessage = errorObject.message.message || 'Something went wrong.'

  //Checks if the error message ends with a period and if not will add one
  if(errorMessage[errorMessage.length -1] !== '.') {
    errorMessage = errorMessage.concat('.')
  }

  const renderGoBackLink = () => {
    if(showBackLink === false) {
      return <p>Please refresh and try again.</p>
    }
    else {
      return (
        <GoBackLink>
          Back To Previous Page
        </GoBackLink>
      )
    }
  }

  return(
    <div className={"error-page " + className}>
      <img
        className={"jana-logo"}
        src={janaLogo}
        alt='jana logo'
      />
      <h1 className="error-title">{errorTitle}</h1>
      <p className="error-msg">{`${errorMessage} Please contact your System Administrator.`}</p>
      {renderGoBackLink()}
    </div>
  )
}

export default ErrorPage;
