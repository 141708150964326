import axios from "axios";
import qs from "qs";
import { history, urlConfig } from "index";
import { store } from "app/store";
import { createSurveyArea } from "api/legacy/surveyAreaDucks";
import {
  SET_GEOMETRY,
  SET_GRID_TYPE,
  surveyAreaMapType,
} from "common/Map/MapDucks";
import { esriGeometryType } from "common/Map/SelectFeatures";
import { getTableConfigData } from "common/Tables/PaginatedTable/TableUtils/TableConfigUtils";
import { axiosErrorHandler } from "utils/AxiosUtils";
import { arcgisQueryParams, concatQueryingParameters } from "api/ArcGIS/constants";
import {getArcgisQuery, getTableQueryBySurveyAreaID } from "api";
import { encodeUriAll } from "utils/UrlUtils";
import { statusTypes } from "common/Tables/PaginatedTable/TableUtils/PredefinedFilterTemplates/StatusTypes";
import {
  getTableIdByName,
  getLayerMapServiceIdByName,
  getGridIdFieldByName,
} from "utils/GridUtils";
import { getUrlParam } from "utils/FieldsUtils";

import { massageGridData } from "utils/MapServiceUtils";
import { surveyAreaTabValue } from "./SurveyAreaTabs/SurveyAreaTab";
import { mapQueryResults } from "utils/NameMappingUtils";
import { surveyAreaStatuses } from "./SurveyAreaTabs/ExistingArea/ExistingArea";
import { isArray } from "lodash";

export const SET_VIEW = "lantern/surveyarea/SET_VIEW";
export const SET_STATUS_MESSAGE = "surveyarea/SET_STATUS_MESSAGE";

export const SAVE_GRIDS_COPY = "lantern/surveyarea/SAVE_GRIDS_COPY";
export const REMOVE_EXISTING_AREA = "lantern/surveyarea/REMOVE_EXISTING_AREA";
export const CLEAR_STATUS_MESSAGE = "lantern/surveyarea/CLEAR_SUCCESS_MESSAGE";
export const CLEAR_ERROR = "SURVEY_AREA_CLEAR_ERROR";

export const SET_EXISTING_AREAS_TABLE_CONFIG =
  "SET_EXISTING_AREAS_TABLE_CONFIG";
export const EXISTING_AREA_SUCCESS = "EXISTING_AREA_SUCCESS";
export const EXISTING_AREA_LOADING = "EXISTING_AREA_LOADING";
export const POST_LOADING = "POST_LOADING";
export const POST_SUCCESS = "POST_SUCCESS";
export const ARCHIVE_LOADING = "ARCHIVE_LOADING";
export const ARCHIVE_SUCCESS = "ARCHIVE_SUCCESS";
export const FETCH_SURVEY_AREA_LOADING = "FETCH_SURVEY_AREA_LOADING";
export const FETCH_SURVEY_AREA_SUCCESS = "FETCH_SURVEY_AREA_SUCCESS";
export const EXISTING_AREAS_TABLE_CONFIG = "EXISTING_AREAS_TABLE_CONFIG";
export const EXISTING_AREAS_TABLE_CONFIG_SUCCESS =
  "EXISTING_AREAS_TABLE_CONFIG_SUCCESS";
export const SET_SURVEY_AREA_ON_MOUNT_STATUS_OPTIONS = "SURVEY_AREA_ON_MOUNT_STATUS_OPTIONS";
export const ERROR = "ERROR";
export const surveyAreaDefaultStatusOptions = [ 'Locked', 'Unlocked' ]
/**
 * summary: function for creating a survey area
 *
 * @param data survey area information to be saved
 */
export const postCreateSurveyArea = (data: any) => async (dispatch: any) => {
  dispatch({ type: POST_LOADING });
  dispatch({ type: CLEAR_ERROR });
  // url to post to

  // Todo: Remove this code after testing
  //const url = `${urlConfig.reactLanternServerUrl}/Editing.ashx/tables/commit`;
  //append token to requestbody

  createSurveyArea((data.requestBody))
    .then(() => {
      dispatch({ type: POST_SUCCESS });
      dispatch({
        type: SET_STATUS_MESSAGE,
        statusMessage: "createSuccess",
        status: "success",
      });
      history.push(history.location.pathname + "/ViewArea?id=" + data.ID);
      dispatch({ type: SET_VIEW, tabValue: surveyAreaTabValue.existingTab });
    })
    .catch((err) => {
      dispatch({ type: ERROR, error: { message: err, type: "post" } });
      dispatch({ type: "POST_ERROR" });
    });
};

/**
 *  Summary: Fetch the Historical data
 * @param statusOptions array of status options
 * @param dispatch dispatch
 * @param surveyAreaContainsHistorical boolean if historical data has bene fetched
 */
export const getHistoricalData = (
    statusOptions: any[],
    dispatch: any,
    surveyAreaContainsHistorical: boolean
) => {
  if (isArray(statusOptions)) {
    const newStatusOptions = statusOptions.map(element => element.value)
    // Make the API call to include Historical data only if it hasn't arleady been loaded.
    if (newStatusOptions.includes(surveyAreaStatuses.historical) && !surveyAreaContainsHistorical) {
      dispatch({
        type: SET_SURVEY_AREA_ON_MOUNT_STATUS_OPTIONS,
        onMountStatusOptions: newStatusOptions
      })
      // fetch new data
      dispatch(getExistingAreas(true))
    }
  }
}

/**
 * summary: get the configuraton data for the existing areas table
 */
export const getExistingAreasTableConfigData =
  (surveyWorkType: string) => async (dispatch: any) => {
    const tableName = "Survey Area List";
    const actionType = EXISTING_AREAS_TABLE_CONFIG;
    dispatch(getTableConfigData(tableName, actionType, surveyWorkType));
  };

// function for fetching existing areas
export const getExistingAreas = (fetchHistorical: Boolean = false) => async (dispatch: any) => {
  const selectedMapService = store.getState().LoginReducer.selectedMapService.decodedLabel;
  const surveyAreaAlias =  store.getState().SettingsReducer.globalAliases.LanternSurveyArea;
  const noMatchingTableError = `${surveyAreaAlias} name not found. ` +
  `Check if table name matches global alias for LanternSurveyArea in Admin (currently ${surveyAreaAlias}).`

  dispatch({ type: EXISTING_AREA_LOADING });
  const mapServiceId = getTableIdByName(surveyAreaAlias);
  const statusValues = ['Unlocked', 'Locked'];
  if (fetchHistorical){
    statusValues.push(statusTypes.historical);
  }
  const queryParams = concatQueryingParameters({
    [arcgisQueryParams.where]: encodeUriAll(
      `Status IN ('${statusValues.join("', '")}')`
      ),
    [arcgisQueryParams.outFields]: "*",
    [arcgisQueryParams.orderByFields]: `ModifiedOn DESC`,
  });
  
  getArcgisQuery(
    selectedMapService,
    mapServiceId,
    queryParams
  )

    .then((res:any) => {
      axiosErrorHandler(res);
      res = mapQueryResults(res);
      dispatch({ type: EXISTING_AREA_SUCCESS, existingAreas: res.data });
    })
    .catch((err:any) => {
      if (!mapServiceId) {
        dispatch({
          type: ERROR,
          error: {
            message: {
              message: noMatchingTableError
            },
            type: "fetchExisting",
          },
        });
      }
      else {
        dispatch({
          type: ERROR,
          error: {
            message: err,
            type: "fetchExisting",
          },
        });
      }
      dispatch({ type: "EXISTING_AREA_ERROR" });
    });
};

/**
 * summary: function for generating the details for a survey area
 *
 * @param location url of the current page that stores the survey area ID
 * @param generateFields helper function to generate input fields based on data type
 */
export const getSurveyAreaDetails =
  (location: any, generateFields: Function) => async (dispatch: any) => {
    dispatch({ type: FETCH_SURVEY_AREA_LOADING });
    dispatch({ type: CLEAR_ERROR });
    const selectedMapService =
      store.getState().LoginReducer.selectedMapService.decodedLabel;
    let id = getUrlParam(location, "id");
    // the id in the URL may already have brackets around it (the BE adds them after creating a survey area);
    // if it does not, add them so the query URL does not fail
    if (id && id[0]?.toString() !== "{") {
      id = "{" + id +"}"
    }
    if (!id) {
      dispatch({
        type: ERROR,
        error: {
          message: "Invalid Survey Area ID",
          type: "fetchDetails",
        },
      });
      dispatch({ type: "FETCH_SURVEY_AREA_ERROR" });
    } else {
      let surveyAreaTableID = getTableIdByName(
        store.getState().SettingsReducer.globalAliases.LanternSurveyArea
      );

      let surveyPolygonTableID = getTableIdByName(
        store.getState().SettingsReducer.globalAliases.LanternSurveyAreaPolygon
      );

      axios
        .all([
          getTableQueryBySurveyAreaID(
            selectedMapService,
            surveyAreaTableID,
            id
          ),
          getTableQueryBySurveyAreaID(
            selectedMapService,
            surveyPolygonTableID,
            id
          ),
          
        ])
        .then(
          axios.spread(function (data, grids) {
            axiosErrorHandler(arguments);
            data = mapQueryResults(data);
            grids = mapQueryResults(grids);
            let { PolygonType } = data.data.features[0].attributes;
            fetchGridGeometry(grids.data, dispatch, PolygonType);
            dispatch({
              type: FETCH_SURVEY_AREA_SUCCESS,
              surveyAreaData: data.data.features[0].attributes,
            });
            dispatch({
              type: SET_GRID_TYPE + surveyAreaMapType,
              gridType: PolygonType,
            });
            generateFields();
          })
        )
        .catch((err) => {
          dispatch({
            type: ERROR,
            error: {
              message: err,
              type: "fetchDetails",
            },
          });
          dispatch({ type: "FETCH_SURVEY_AREA_ERROR" });
        });
    }
  };

// helper function to get the selected grids geometry in view and edit page
function fetchGridGeometry(grids: any, dispatch: any, polygonType: string) {
  let gridData: Array<any> = [];
  let fetches: Array<any> = [];
  let MapServiceId = getLayerMapServiceIdByName(
    grids.features[0].attributes.PolygonType
  );
  let globalIdName = getGridIdFieldByName(polygonType);
  const selectedMapService =
    store.getState().LoginReducer.selectedMapService.decodedLabel;
  // const tkn= localStorage.getItem('ArcGISToken');
  
  grids.features.forEach(async (grid: any) => {
    const queryExtension = concatQueryingParameters({
      [arcgisQueryParams.where]: encodeUriAll(
        `${globalIdName}='${grid.attributes.PolygonID}'`),
      [arcgisQueryParams.outFields]: "*",
    });
    
    fetches.push(
      getArcgisQuery(
        selectedMapService,
        MapServiceId,
        queryExtension
      )
      
        .then((res:any) => {
          axiosErrorHandler(res);
          // linked Survey Grids may now be deleted, hence a lack of features
          if (res.data.features[0]) {
            gridData.push(
              massageGridData(
                res.data,
                esriGeometryType.esriGeometryPolygon,
                grid.attributes.OBJECTID
              )
            );
          }
        })
        .catch((err:any) =>
          dispatch({
            type: ERROR,
            error: {
              message: err,
              type: "fetchDetails",
            },
          })
        )
    );
  });
  Promise.all(fetches).then(() => {
    dispatch({ type: SET_GEOMETRY + surveyAreaMapType, grids: gridData });
    dispatch({
      type: SAVE_GRIDS_COPY,
      uneditedSurveyAreaGridData: gridData.slice(0),
    });
  });
}

/**
 * summary: function for editing a survey area
 *
 * @param data survey area information to be saved
 */
export const postEditSurveyArea = (data: any) => async (dispatch: any) => {
  let surveyAreaName =
    store.getState().SurveyAreaReducer.surveyAreaData.SurveyAreaName;
  let surveyAreaID =
    store.getState().SurveyAreaReducer.surveyAreaData.SurveyAreaID;
  // Todo: Remove this code after testing
  //const url = `${urlConfig.reactLanternServerUrl}/Editing.ashx/tables/commit`;
  dispatch({ type: POST_LOADING });
  dispatch({ type: CLEAR_ERROR });
  // url to post to

  createSurveyArea(data.requestBody)
    .then(() => {
      dispatch({ type: POST_SUCCESS });
      dispatch({
        type: SET_STATUS_MESSAGE,
        surveyAreaName,
        statusMessage: "editSuccess",
        status: "success",
      });
      history.push(`/SurveyArea/ViewArea?id=${surveyAreaID}`);
    })
    .catch((err) => {
      dispatch({ type: ERROR, error: { message: err, type: "post" } });
      dispatch({ type: "POST_ERROR" });
    });
};

/**
 * summary: function for archiving a survey area
 *
 * @param data survey area information to be archived
 */
export const postArchiveSurveyArea =
  (data: any, archiveObjectId: number, surveyAreaName: string) =>
  async (dispatch: any) => {
    dispatch({ type: ARCHIVE_LOADING });
    // Todo: Remove this code after testing
    //const url = `${urlConfig.reactLanternServerUrl}/Editing.ashx/tables/commit`;

    createSurveyArea(data.requestBody)
      .then(() => {
        dispatch({
          type: ARCHIVE_SUCCESS,
          archiveObjectId,
        });
        dispatch({
          type: SET_VIEW,
          tabValue: surveyAreaTabValue.existingTab,
        });
        history.push("/SurveyArea");
        dispatch({
          type: SET_STATUS_MESSAGE,
          surveyAreaName,
          statusMessage: "archiveSuccess",
          status: "failure",
        });
      })
      .catch((err) => {
        dispatch({
          type: ERROR,
          error: {
            message: err,
            type: "archive",
          },
        });
        dispatch({ type: "ARCHIVE_ERROR" });
      });
  };

/**
 * Summary: function for locking a survey area
 *
 * @param data survey area information to be locked
 */
export const postLockSurveyArea = (data: any) => async (dispatch: any) => {
  // Todo: Remove this code after testing
  //const url = `${urlConfig.reactLanternServerUrl}/Editing.ashx/tables/commit`;

  createSurveyArea(data.requestBody)
    .then((res) => {})
    .catch((err) => {
      dispatch({
        type: ERROR,
        error: {
          message: err,
          type: "lockSurveyArea",
        },
      });
    });
};

const initialState: any = {
  tabValue: 0,
  error: {},
  statusMessage: "",
  existingSurveyArea: [],
  surveyAreaData: {},
};

export default function reducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_VIEW:
      return {
        ...state,
        tabValue: action.tabValue,
      };
    case SET_STATUS_MESSAGE:
      return {
        ...state,
        notificationMessage: {
          statusMessage: action.statusMessage,
          surveyAreaName: action.surveyAreaName,
          status: action.status,
        },
      };
    case SAVE_GRIDS_COPY:
      return {
        ...state,
        uneditedSurveyAreaGridData: action.uneditedSurveyAreaGridData,
      };
    case EXISTING_AREAS_TABLE_CONFIG_SUCCESS:
      return {
        ...state,
        existingAreasTableConfigData: action.tableConfig,
      };
    case EXISTING_AREA_SUCCESS:
      // Massage data to conform to structure needed for react-table.
      const massagedData = action.existingAreas.features.map((feature: any) => {
        return {
          ...feature.attributes,
          id: {
            objectId: feature.attributes.OBJECTID,
            surveyAreaID: feature.attributes.SurveyAreaID,
          },
        };
      });

      return {
        ...state,
        existingSurveyArea: massagedData,
      };
    case POST_SUCCESS:
      return {
        ...state,
        statusMessage: "postSuccess",
      };
    case CLEAR_STATUS_MESSAGE:
      return {
        ...state,
        notificationMessage: "",
      };
    case ARCHIVE_SUCCESS:
      let existingAreas = state.existingSurveyArea;
      let newExistingSurveyAreas: any[] = [];
      //updates existing area list to remove the archived area
      for (var index = 0; index < existingAreas.length; index++) {
        if (existingAreas[index].id.objectId !== action.archiveObjectId) {
          newExistingSurveyAreas.push(existingAreas[index]);
        }
      }
      return {
        ...state,
        existingSurveyArea: newExistingSurveyAreas,
      };
    case FETCH_SURVEY_AREA_SUCCESS:
      return {
        ...state,
        surveyAreaData: action.surveyAreaData,
      };
    case SET_SURVEY_AREA_ON_MOUNT_STATUS_OPTIONS:
      return {
        ...state,
        onMountStatusOptions: action.onMountStatusOptions
      }
    case CLEAR_ERROR:
      return {
        ...state,
        error: {},
      };
    case ERROR:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}
