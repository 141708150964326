import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import RemoveSurveyorButton from 'common/Tables/PaginatedTable/TableComponents/UnassignButton/UnassignButton';
import { postUnassignSurveyors } from 'features/SurveyAssignment/SurveyAssignmentDucks';

const AssignedToCell = ({data}:any) => {
  let dispatch = useDispatch()
  let [expanded, setExpanded] = useState(false)
  let showSurveyors
  let moreThanFiveSurveyors = data.cell.value?.length > 5
  /**
   * Summary: Unassign a surveyor with the given surveyorUserId and surveyorObjectId
   * @param surveyorUserId name of the surveyor
   * @param surveyorObjectId OBJECTID of the surveyor
   * @param surveyJobId id of the survey job the given surveyor is assigned to
   */
  if (data.cell.value && data.cell.value.length !== 0) {
    if(expanded) {
      showSurveyors = data.cell.value.map((surveyor:any, index:number) =>
      <div className = "surveyors-container">
        <div className="surveyor-container" key = {index}>
          <div className="surveyor-name">
            <span>
              {surveyor.Attributes.UserID}
            </span>
          </div>
          <RemoveSurveyorButton
            itemName="user"
            unassign={() => dispatch(postUnassignSurveyors(surveyor.Attributes.OBJECTID))}
          />
        </div>
      </div>
      )
    }
    else {
      let Surveyors = data.cell.value.slice(0,5)
      showSurveyors =
        <div className = "surveyors-container">
          {Surveyors.map((surveyor:any,index:number) =>
          <div className="surveyor-container" key = {index}>
            <div className="surveyor-name">
              <span>
                {surveyor.Attributes.UserID}
              </span>
            </div>
            <RemoveSurveyorButton
              itemName="user"
              unassign={() => dispatch(postUnassignSurveyors(surveyor.Attributes.OBJECTID))}
            />
          </div>
          )}
          {moreThanFiveSurveyors &&
            <span className = "surveyor-count">
              +{data.cell.value.length - 5}
            </span>
          }
        </div>
    }

      return (
      <div className = "assignto-container">
        <div>
          {showSurveyors}
        </div>
        {moreThanFiveSurveyors &&
          <button
            className='icon-btn expand-icon'
            onClick = {() =>
            expanded? setExpanded(false) : setExpanded(true) }
          >
            {expanded
              ?<ExpandLess/>
              :<ExpandMore/>
            }
          </button>
        }
      </div>
      )
  }

  else {
    return(<div className="unassigned">Unassigned</div>)
  }
}

export default AssignedToCell;
