import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty, isEqual, isUndefined } from 'lodash';
import './Droplist.scss';
import { 
  RESET_INFO_ATTACHMENTS,
  retrieveInfoChildren,
  SET_HIGHLIGHT_INFO_TOOL,
  SET_INFO_ZOOM_GEOMETRY
} from '../../../MapDucks';
import { convertDate } from 'utils/DateUtils';
import { getNameMapping, mapAttribute } from 'utils/NameMappingUtils';
import { getFieldType } from 'utils/FieldsUtils';
import { getLayerOrTableByName, getMapServiceNamebyId, isLayer } from 'utils/GridUtils';

/**
   * Summary: Counts the number of children of each layer/table
   *
   * @param itemNames names of all layers/tables
   * @param name name of a layer/table whose children are being counted
   *
   * @return number of children
   */
export const countChildren = (itemNames: Array<string>, name: string) => {
  let count = 0;
  itemNames.forEach((itemName: string) => {
    if (itemName === name) {
      count++
    }
  })
  return count
}

interface IDroplistProp {
  className?: string
  layerNames: Array<string>
  layerData: any
  tableNames: Array<string>
  tableData: any
  itemWithRelationships: Array<Object>
  infoIds: any
  infoChildren: any
  infoFields: any
  infoIndex: any
  directoryIndex: number
  setDirectoryIndex: any
  setIsParent: any
  setIsRelationship: any
  setRelationshipId: any
  setRelationshipTable: any
  setRelationshipFields: any
  setIsAttachmentActive: any
  setShowAttachment: any
  setNumOfAttachment: any
  mapType: string
  setSelectedDataItem: Function
  setCurrentLayerOrTable: Function
}

const Droplist = ({
  className = "",
  layerNames,
  layerData,
  tableNames,
  tableData,
  itemWithRelationships,
  infoIds,
  infoChildren,
  infoFields,
  infoIndex,
  directoryIndex,
  setDirectoryIndex,
  setIsParent,
  setIsRelationship,
  setRelationshipId,
  setRelationshipTable,
  setRelationshipFields,
  setIsAttachmentActive,
  setShowAttachment,
  setNumOfAttachment,
  mapType,
  setSelectedDataItem,
  setCurrentLayerOrTable
  }:IDroplistProp) => {
  const dispatch = useDispatch();
  const settingsReducer = useSelector((state:any) => state.SettingsReducer);
  const layers = useSelector((state:any) => state.SettingsReducer.settings.Layers);
  const [uniqueLayerNames, setUniqueLayerNames] = useState<Array<string>>([]);
  const [uniqueTableNames, setUniqueTableNames] = useState<Array<string>>([]);
  const [uniqueIds, setUniqueIds] = useState<Array<number>>([]);
  const [prevHighlightedItem, setPrevHighlightedItem] = useState<any>(undefined);
  const [hasSelectedDroplistOptionOnce, setHasSelectedDroplistOptionOnce] = useState<boolean>(false);
  
  let uniqueNames = [...uniqueLayerNames, ...uniqueTableNames];
  let itemData = [...layerData, ...tableData];
  let numOfChildRelationships = 0;
  itemWithRelationships.forEach((item: any) => {
    numOfChildRelationships += item.childRelationships.length;
  })

  useEffect(() => {
    setUniqueLayerNames(getUniqueArray(layerNames))
    setUniqueTableNames(getUniqueArray(tableNames))
  }, [layerNames, tableNames])

  useEffect(() => {
    let uniqueLayerIds = getUniqueArray(layerData.map((data:any) => data.id))
    let uniqueTableIds = getUniqueArray(tableData.map((data:any) => data.id + layers.length))
    setUniqueIds([...uniqueLayerIds, ...uniqueTableIds])
  }, [layerData, tableData])

  /**
   * Summary: Returns unique items as an array
   *
   * @param items array of items (ex: layer or table names/ids)
   *
   * @return array of unique items
   */
  const getUniqueArray = (items: Array<any>) => {
    return Array.from(new Set(items))
  }

  /**
   * Summary: return the number of relationship children prior to the main child at mainChildIndex
   *
   * @param mainChildIndex index of the current main child
   */
  const countRelationshipChildren = (mainChildIndex: number) => {
    let sumOfRelationshipChildren = 0
    for (let i = 0; i < mainChildIndex; i++) {
      if (infoChildren[i]) {
        sumOfRelationshipChildren += infoChildren[i].length
      }
    }
    return sumOfRelationshipChildren
  }

  /**
   * Summary: Passes back the index of the selected item in the Droplist
   *
   * @param index index of the selected item
   * @param isParent boolean if selected item is parent
   * @param isRelationship boolean if selected item is relationship item
   * @param relationshipIndex index of the relationship item
   * @param data Object containing informtion regaridng the child only if isParent === false
   */
  const select = (
      index: number,
      isParent: boolean,
      isRelationship: boolean,
      relationshipIndex: any = 0,
      data: any = {}
    ) => {
    setIsParent(isParent)
    setIsRelationship(isRelationship)
    setIsAttachmentActive(false)

    if (isRelationship) {
      if (isParent) {
        setDirectoryIndex(uniqueNames.length + itemData.length + relationshipIndex + countRelationshipChildren(index))
        if(typeof(directoryIndex) === "number" && tableData[directoryIndex]) {
          setSelectedDataItem(tableData[directoryIndex].attributes.SurveyPlanName)
        }
        setRelationshipTable(infoChildren[index][relationshipIndex[1]])
        setRelationshipFields(infoFields[index][relationshipIndex[1]])
        setRelationshipId(infoIds[relationshipIndex[0]][relationshipIndex[1]])
      } else {
        let selectedTable = infoChildren[relationshipIndex[0]][relationshipIndex[1]].filter((child: any) => {
          return child.GlobalID === relationshipIndex[3]
        })[0];
        setDirectoryIndex(uniqueNames.length + itemData.length + numOfChildRelationships + index)
        setRelationshipTable(selectedTable)
        setRelationshipFields(infoFields[relationshipIndex[0]][relationshipIndex[1]])
        setRelationshipId(infoIds[relationshipIndex[0]][relationshipIndex[1]])
      }
    } else {
      setDirectoryIndex(index)
    }

    if (isParent) {
      setShowAttachment(false)
    }
    const highlightedItem = (!isParent && data.layerName) ? data : {}
    dispatch({
      type: SET_HIGHLIGHT_INFO_TOOL + mapType,
      highlightedItemInfoTool: highlightedItem
    })
  
    if (!isUndefined(highlightedItem) && !isParent) {
      /**
       * Simulate gps click for the following conditions:
       * 1. If we click on an item in the droplist that is RegionBoundary 
       *    - to allow users to see the entirety of the region highlighted
       * 2. If we click on an item right after viewing a region boundary 
       *    - to zoom into an item which won't be visible at a the scale of region boundary
       * 3. Double clicking on an item in the droplist
       */
      if (!isUndefined(prevHighlightedItem) && (prevHighlightedItem.layerName === 'RegionBoundary'
          || highlightedItem.layerName === 'RegionBoundary'
          || isEqual(highlightedItem, prevHighlightedItem))
        ) {
        const geometry = [highlightedItem.geometry]
        if (geometry.length > 0 && highlightedItem.layerName) {
          // gps
          dispatch({
            type: SET_INFO_ZOOM_GEOMETRY,
            infoGeometry: geometry,
            infoLayerName: highlightedItem.layerName
          })
        }
      }
      setPrevHighlightedItem(highlightedItem)
    }
  }

  useEffect(() => {
    dispatch({
      type: SET_HIGHLIGHT_INFO_TOOL + mapType,
      highlightedItemInfoTool: {}
    })

    return () => setCurrentLayerOrTable({name: undefined, isLayer: undefined})
  }, [])

  /**
   * Summary: Changes index of selected item to parent item,
   * applies the container CSS
   *
   * @param index index of the selected item
   *
   * @return CSS class for a selected parent item
   */
  const selectParentItem = (index: number) => {
    return (directoryIndex === index ? "selected-item" : "")
  }

  /**
   * Summary: Changes index of selected item to parent item,
   * applies the text CSS
   *
   * @param index index of the selected item
   *
   * @return CSS class for a selected parent text
   */
  const selectParentText = (index: number) => {
    return (directoryIndex === index ? "selected-text" : "")
  }

  /**
   * Summary: Changes index of selected item to child item,
   * applies the container CSS
   *
   * @param index index of the selected item
   *
   * @return CSS class for a selected child item and text
   */
  const selectChild = (index: number) => {
    return (directoryIndex === index ? "selected-item selected-text" : "")
  }

  /**
   * Set current layer or table name for parent component
   * @param newLayerOrTableName string - new layer name if valid
   */
  const updateCurrentLayerOrTableName = (newLayerOrTableName: string) => {
    if (getLayerOrTableByName(newLayerOrTableName) !== null) {
      setCurrentLayerOrTable({
        name: newLayerOrTableName,
        isLayer: isLayer(newLayerOrTableName)
      })
    }
    setHasSelectedDroplistOptionOnce(true)
  }

  /**
   * Summary: Generate the relationship of the given item
   *
   * @param item data of the item from infoResults
   * @param index item index
   */
  const generateRelationship = (item: any, index: number) => {
    setShowAttachment(false)
    let itemRelationships: any = [];
    item.childRelationships.forEach((relationship: any) => {
      // if relationship is attachment
      if (relationship.DestinationDisplayName === 'Attachment') {
        // Commented this to remove `Add Feature Attachments` feature from prod
        // setShowAttachment(true)
      }
      item.attributes = mapAttribute(item.attributes)
      const fields = relationship.DestinationIsLayer ?
        settingsReducer.settings.Layers[relationship.DestinationId].Fields :
        settingsReducer.settings.Tables[relationship.DestinationId].Fields
      itemRelationships = [
        ...itemRelationships,
        {
          "id": relationship.DestinationId,
          "fieldName": getNameMapping(relationship.DestinationFieldName),
          "fieldValue": item.attributes[getNameMapping(relationship.OriginFieldName)],
          "fieldType": getFieldType(fields, relationship.DestinationFieldName),
          "isLayer": relationship.DestinationIsLayer,
          "fields": fields
        }
      ]
    })

    dispatch({type: RESET_INFO_ATTACHMENTS + mapType})
    dispatch(retrieveInfoChildren(itemRelationships, index, mapType, setNumOfAttachment))
  }

  /**
   * Summary: Return the appropriate display name of the feature layer
   *
   * @param fields feature layer field permissions
   * @param displayFieldName feature layer display name attribute field
   * @param value value of the display name attribute field
   */
  const getDisplayName = (fields: any, displayFieldName: string, value: any) => {
    let field = fields.find((field: any) => getNameMapping(field.Name) === getNameMapping(displayFieldName));

    if (!field) {
      return value;
    }

    // convert date display name from epoch to human-readable
    if (field.FieldType === 5) {
      return convertDate(value, false, false, 'date');
    // use domain name instead of domain value, where appropriate
    } else {
      return field.Domain ? field.Domain.Names[field.Domain.Values.indexOf(value)] : value;
    }
  }

  // Base case of empty data
  if (isEmpty(itemData)) {
    if (directoryIndex !== 0) {
      setDirectoryIndex(0)
    }
  }

  useEffect(() => {
    // only select a default layer/table name when we haven't selected a droplist option
    if (uniqueNames.length > 0 && !hasSelectedDroplistOptionOnce) {
      // unique names only display "SE Service" for all tables that include that name
      const defaultValue = getMapServiceNamebyId(uniqueIds[0])
      setCurrentLayerOrTable((prev: any) => {
        if (prev.name !== defaultValue) {
          return {
            name: defaultValue,
            isLayer: isLayer(defaultValue)
          }
        } return prev;
      })
    }
  }, [uniqueNames])

  return (
    <div className={"droplist " + className}>
      <div className="droplist-item">
        {/* Main Parent */}
        {uniqueNames.map((item: string, mainParentIndex: number) => {
          return (
            <details
              key={mainParentIndex}
            >
              <summary
                className={"droplist-parent " + selectParentItem(mainParentIndex)}
                onClick={() => {
                  select(mainParentIndex, true, false)
                  updateCurrentLayerOrTableName(getMapServiceNamebyId(uniqueIds[mainParentIndex]))
                }}
              >
                <span
                  id="overflow"
                  className={selectParentText(mainParentIndex)}
                >
                  <span
                    data-content-start={getMapServiceNamebyId(uniqueIds[mainParentIndex]) + " "}
                    data-content-end={" (" + countChildren([...layerNames, ...tableNames], item) + ")"}
                  />
                </span>
              </summary>
              {/* Main Child */}
              {itemData.map((data: any, mainChildIndex: number) => {
                data.attributes = mapAttribute(data.attributes)
                const selectChildItem = () => select(uniqueNames.length + mainChildIndex, false, false, 0, data)
                if (data.layerName === item || data.tableName === item) {
                  if (isEmpty(data.childRelationships)) {
                    return (
                      <div
                        key={mainChildIndex}
                        className={"droplist-child " + selectChild(uniqueNames.length + mainChildIndex)}
                        onClick={() => selectChildItem()}
                      >
                        {getDisplayName(data.fields, data.displayFieldName, data.attributes[getNameMapping(data.displayFieldName)]) || data.layerName || data.tableName}
                      </div>
                    )
                  } else {
                    return (
                      <details
                        key={mainChildIndex}
                      >
                        <summary
                          className={"droplist-child " + selectChild(uniqueNames.length + mainChildIndex)}
                          onClick={() => {
                            updateCurrentLayerOrTableName(getMapServiceNamebyId(uniqueIds[mainParentIndex]))
                            generateRelationship(data, mainChildIndex)
                            selectChildItem()
                          }}
                        >
                          {getDisplayName(data.fields, data.displayFieldName, data.attributes[getNameMapping(data.displayFieldName)]) || data.layerName || data.tableName}
                        </summary>
                        {/* Relationship Parent */}
                        {itemData[mainChildIndex].childRelationships.map((childRelationship: any, relationshipParentIndex: number) => {
                          let relationshipChildren;
                          if (isUndefined(infoChildren[mainChildIndex])) {
                            relationshipChildren = [];
                          } else {
                            relationshipChildren = infoChildren[mainChildIndex][relationshipParentIndex] || [];
                          }
                          return (
                            <details
                              key={relationshipParentIndex}
                            >
                              <summary
                                className={
                                  "droplist-child droplist-child-parent " +
                                  selectChild(uniqueNames.length + itemData.length + relationshipParentIndex + countRelationshipChildren(mainChildIndex))
                                }
                                onClick={() => {
                                  select(mainChildIndex, true, true, [mainChildIndex, relationshipParentIndex])
                                  // update the layer name if the child relationship destination name is a layer name
                                  updateCurrentLayerOrTableName(childRelationship.DestinationDisplayName)
                                }}
                              >
                                {getMapServiceNamebyId(
                                  childRelationship.DestinationIsLayer ? 
                                  childRelationship.DestinationId : childRelationship.DestinationId + layers.length
                                )}
                              </summary>
                              {/* Relationship Child */}
                              {relationshipChildren.map((relationshipChild: any, relationshipChildIndex: number) => {
                                relationshipChild = mapAttribute(relationshipChild)
                                let relationshipChildInfoIndex = infoIndex.indexOf(relationshipChild.GlobalID);
                                //nestedLevelIndex so index for each child is unique in the entire list and not just its own parent
                                let nestedLevelIndex = mainChildIndex + relationshipParentIndex;
                                return (
                                  <div
                                    key={relationshipChildIndex}
                                    className={
                                      "droplist-child droplist-child-child " +
                                      selectChild(uniqueNames.length + itemData.length + numOfChildRelationships + relationshipChildInfoIndex + nestedLevelIndex)
                                    }
                                    onClick={() => {
                                      updateCurrentLayerOrTableName(childRelationship.DestinationDisplayName)
                                      select(
                                        relationshipChildInfoIndex + nestedLevelIndex,
                                        false,
                                        true,
                                        [
                                          mainChildIndex,
                                          relationshipParentIndex,
                                          relationshipChildIndex,
                                          relationshipChild.GlobalID,
                                        ]
                                      )
                                    }
                                  }
                                  >
                                    {
                                      getDisplayName(
                                        infoFields[mainChildIndex][relationshipParentIndex],
                                        childRelationship.DestinationDisplayFieldName,
                                        relationshipChild[getNameMapping(childRelationship.DestinationDisplayFieldName)]
                                      ) || childRelationship.DestinationDisplayName
                                    }
                                  </div>
                                )
                              })}
                            </details>
                          )
                        })}
                      </details>
                    )
                  }
                } else {
                  return (null)
                }
              })}
            </details>
          )
        })}
      </div>
    </div>
  )
}

export default Droplist;
