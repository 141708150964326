import axios from 'axios';
import React from 'react';
import { store } from 'app/store';
import { NavLink, useLocation } from 'react-router-dom';
import { isUndefined, uniq } from "lodash";
import { urlConfig } from 'index';
import { getColorCodedDate, convertDate } from 'utils/DateUtils';
import ProgressBar from 'common/ProgressBar/ProgressBar';
import AssignedToCell from './AssignedToCell';
import {
    MultiSelectDropdownFilter,
    filterMultiSelectValueForDates,
    MultiSelectDropdownFilterForDates,
    filterMultiSelectValue,
    MultiSelectDropdownFilterForUsers,
    MultiSelectDropdownFilterForStatus} from './FilterTypes/TableMultiSelectFilter';
import { statusTypes } from './PredefinedFilterTemplates/StatusTypes';
import {
  SelectFilterForDates,
  filterSelectValueForDates,
  filterDateRangeValue,
  SelectFilterForUsers,
  filterSelectValueForUsers } from './FilterTypes/TableSelectFilter';
import { dateRangeFiltersValues } from './PredefinedFilterTemplates/DateRanges';
import { sortFraction } from './SortTypes/FractionSort';
import { filterMultiSelectValueForUsers } from './FilterTypes/TableMultiValueFilter';
import { getNameMapping } from 'utils/NameMappingUtils';
import { pages } from 'utils/UrlUtils'
import {webpageConfig} from "api";

enum cellTypes {
  text = "text",
  date = "date",
  linkToSurveyAreaDetails = "linkToSurveyAreaDetails",
  colorCodedDate = "colorCodedDate",
  progressBar = "ProgressBar",
  jobsAssignedProgressBar = "jobsAssignedProgressBar",
  assignedToEditableList = "assignedToEditableList",
  assignedToList = "assignedToList"
}

// TODO: change return type of Cell to JSX element 
// and fix type of "Filter" "filter"(?) "predefinedFIlterValues" "sortFraction"
interface IColumnConfig {
    Header:string,
    accessor:string,
    showColumn?:boolean,
    disableFilters?:boolean,
    filterOrder?:number,
    Cell:(data:any)=>any,
    Filter?:(e:any)=>any,
    filter?:((rows: any[], filterCategory: string, filterValue: any[]) => any[]) | 
            ((rows: Object, id: number, filterValue: Object) => Object),
    preDefinedFilterValues?:any,
    defaultFilters?:string[],
    sortType?:any,
    disableSortBy?:boolean,
    location?:string
  }

/**
 * Get the BE configuration data for a specific table, with the columns sorted in the order
 * given by the config data.
 * 
 * @param tableConfigData the initial table configuration data
 * @returns the table's sorted configuration data
 */
export const SortTableColumns = (tableConfigData:any) => {
  if(isUndefined(tableConfigData)){
    return []
  }

  const sortedTableConfigData = tableConfigData.sort((columnData1:any, columnData2:any) =>
    columnData1.columnOrder - columnData2.columnOrder
  )

  return sortedTableConfigData
}

/**
 * Retrieve the BE configuration data for a specific table from the API call, with the columns sorted in the order
 * given by the config data. Dispatches an action to store the sorted column information
 * 
 * @param tableName the name of the table to get the data for
 * @param actionType the action to be dispatched to store the data from the call
 * @param surveyWorkType the survey work type to get the table data for 
 *        note: some tables (such as the ones in Survey Area and Initiation) do not require this (the config does not 
 *          change based on the survey work type), but because of the current BE design, this still needs to be passed in, 
 *          specifically, it needs to be one of the survey work types configured for this map service
 */
export const getTableConfigData =
(tableName: string, actionType: string, surveyWorkType: string) =>
async (dispatch: any) => {
  const mapServiceName =
    store.getState().LoginReducer.selectedMapService.label;
  // const url = `${urlConfig.reactLanternNewServerUrl}/v1/webpageconfiguration/${mapServiceName}/${surveyWorkType}/${tableName}`;
  const resp = await webpageConfig(mapServiceName, surveyWorkType, tableName);
  try {
    dispatch({ type: `${actionType}_LOADING` });
    const sortedConfigData = SortTableColumns(resp.data[0]?.columns);
    dispatch({
      type:`${actionType}_SUCCESS`,
      tableConfig: sortedConfigData,
    });
  } catch (err) {
    dispatch({ type:`${actionType}_ERROR`});
  }
};

/**
 * Get the configuration of a column (which will be used to configure a table),
 * given the configuration data from the api call
 * 
 * @param columnConfigData configuration data of the column given by the api call 
 * @param defaultSelectedFilterOptions filter options selected by default
 */
export const getColumnConfig = (columnConfigData:any, defaultSelectedFilterOptions: Array<string> = [], surveyPlansAllFilterValues?: any) => {
    const columnConfig:IColumnConfig = {
        "Header": columnConfigData.displayName,
        "accessor": getNameMapping(columnConfigData.accessor),
        "showColumn": columnConfigData.showColumn,
        "disableFilters": isUndefined(columnConfigData.showFilter) ? false : !columnConfigData.showFilter,
        "filterOrder": columnConfigData.filterOrder,
        "Cell": getCell(columnConfigData.cellType)
    }

    // set other properties based on the cellType

    if (columnConfigData.cellType === cellTypes.date) {
        columnConfig.Filter = SelectFilterForDates
        columnConfig.filter = filterSelectValueForDates
        
    } else if (columnConfigData.cellType === cellTypes.colorCodedDate) {
        columnConfig.filter = filterDateRangeValue
        columnConfig.preDefinedFilterValues = dateRangeFiltersValues

    } else if (columnConfigData.cellType === cellTypes.progressBar || columnConfigData.cellType === cellTypes.jobsAssignedProgressBar) {
        columnConfig.accessor = "jobsProgress"
        columnConfig.sortType = sortFraction
        columnConfig.disableSortBy = true

    } else if (columnConfigData.cellType === cellTypes.assignedToEditableList || columnConfigData.cellType === cellTypes.assignedToList) {
        columnConfig.accessor = "Surveyors"
        columnConfig.disableSortBy = true

        // TODO: move out all the references to "Attribute.UserID" (which is used to access the surveyors' user ids)
        // that are in the filter and Filter values for assignedTo columns, and access it from one source
        if (!columnConfigData.multiSelectFilter) {
          columnConfig.Filter = SelectFilterForUsers
          columnConfig.filter = filterSelectValueForUsers
        }
    }

    // additional configurations

    if (columnConfigData.multiSelectFilter) {
      if (columnConfigData.cellType === cellTypes.date) {
        columnConfig.Filter = MultiSelectDropdownFilterForDates
        columnConfig.filter = filterMultiSelectValueForDates
      } else if (columnConfigData.cellType === cellTypes.assignedToEditableList || columnConfigData.cellType === cellTypes.assignedToList) {
        columnConfig.Filter = MultiSelectDropdownFilterForUsers
        columnConfig.filter = filterMultiSelectValueForUsers
      } else {
        columnConfig.Filter = MultiSelectDropdownFilter
        columnConfig.filter = filterMultiSelectValue
        
        // for admin area multiselect filter
        if (
          [pages.surveyReview, pages.surveyAssignment, pages.surveyInitiation, pages.surveyArea].some((page) =>
            window.location.pathname.includes(page)
          )
        ) {
          columnConfig.preDefinedFilterValues = uniq(
            surveyPlansAllFilterValues
              ?.filter(
                (row: any) => row.attributes[columnConfig.accessor.toUpperCase()] || row.attributes[columnConfig.accessor]
              )
              ?.map(
                (row: any) => row.attributes[columnConfig.accessor.toUpperCase()] || row.attributes[columnConfig.accessor]
              )
          );
        }
        // for status filter in Survey Initiation
        if (columnConfig.accessor === 'Status' && window.location.pathname.includes(pages.surveyInitiation)) {
          columnConfig.Filter = MultiSelectDropdownFilterForStatus
          columnConfig.preDefinedFilterValues = [statusTypes.locked, statusTypes.unlocked]
          columnConfig.defaultFilters = defaultSelectedFilterOptions
          columnConfig.location = pages.surveyInitiation
        }
        // for status filter in Survey Area
        if (columnConfig.accessor === 'Status' && window.location.pathname.includes(pages.surveyArea)) {
          columnConfig.Filter = MultiSelectDropdownFilterForStatus
          columnConfig.preDefinedFilterValues = [statusTypes.locked, statusTypes.unlocked, statusTypes.historical]
          columnConfig.defaultFilters = defaultSelectedFilterOptions
          columnConfig.location = pages.surveyArea
        }
        // for status filter in Survey Assignment Plan Details
        else if (columnConfig.accessor === 'Status' && window.location.pathname.includes(pages.surveyAssignPlanDetails)) {
          columnConfig.preDefinedFilterValues = [statusTypes.planned, statusTypes.active, statusTypes.inprogress]
          columnConfig.defaultFilters = defaultSelectedFilterOptions
          columnConfig.location = pages.surveyAssignPlanDetails
        }
        //Right now this code has no effect on the survey assign page, as multi selection
        //is not enabled for the Survey Assign page Status filter field
        // for status filter in Survey Assignment 
        else if (columnConfig.accessor === 'Status' && window.location.pathname.includes(pages.surveyAssignment)) {
          columnConfig.Filter = MultiSelectDropdownFilterForStatus
          columnConfig.preDefinedFilterValues = [statusTypes.planned, statusTypes.active]
          columnConfig.defaultFilters = defaultSelectedFilterOptions
          columnConfig.location = pages.surveyAssignment
        }
        // for status filter in Survey Review Plan Details
        else if (columnConfig.accessor === 'Status' && window.location.pathname.includes(pages.surveyReviewPlanDetails)) {
          columnConfig.Filter = MultiSelectDropdownFilterForStatus
          columnConfig.preDefinedFilterValues = [statusTypes.planned, statusTypes.active, statusTypes.completed, statusTypes.historical, statusTypes.inprogress]
          columnConfig.defaultFilters = defaultSelectedFilterOptions
          columnConfig.location = pages.surveyReviewPlanDetails
        }
        // for status filter in Survey Review
        else if (columnConfig.accessor === 'Status' && window.location.pathname.includes(pages.surveyReview)) {
          columnConfig.Filter = MultiSelectDropdownFilterForStatus
          columnConfig.preDefinedFilterValues = [statusTypes.planned, statusTypes.active, statusTypes.completed, statusTypes.historical]
          columnConfig.defaultFilters = defaultSelectedFilterOptions
          columnConfig.location = pages.surveyReview
        }
      }
    }

    return columnConfig
}

const getCell = (cellType:string) => {
    if (cellType === cellTypes.date) {
      return (data:any) => {
        const convertedDate = convertDate(data.cell.value, false, false, 'date').toString()
        return (
          <span title = {convertedDate}>
              {convertedDate}
          </span>)
      }
      
    } else if (cellType === cellTypes.linkToSurveyAreaDetails) {
        return (data:any) => (
            <span title = {data.cell.value}>
              <NavLink
                className = "name-link"
                to = {window.location.pathname + "/ViewArea?id=" + data.row.original.SurveyAreaID}
              >
                <span>{data.cell.value}</span>
              </NavLink>
            </span>
        )

    } else if (cellType === cellTypes.colorCodedDate) {
        return (data:any) => getColorCodedDate(data.cell.value)

    } else if (cellType === cellTypes.progressBar || cellType === cellTypes.jobsAssignedProgressBar) {
        return (data:any) => (
            <ProgressBar
              progressValue = {data.cell.value.progress}
              maxValue = {data.cell.value.total}
            />
          )

    } else if (cellType === cellTypes.assignedToEditableList) {
        return (data:any) => <AssignedToCell {...{data}}/>
    
    } else if (cellType === cellTypes.assignedToList) {
        return (data:any) => (
          <span title={generateSurveyorTitle(data?.cell?.value ?? "")}>
            {generateSurveyorString(data?.cell?.value ?? "")}
          </span>
        )
      
    } else { // cellType === cellTypes.text (by default)
        return (data:any) => (
            <span title = {data.cell.value}>
                {data.cell.value}
            </span>
        )
    }
}

// function to generate the string to display in the assigned to column
function generateSurveyorString(surveyors:Array<any>) {
    switch(surveyors?.length) {
      case 0:
        return <span className="unassigned">unassigned</span>
      case 1:
        return surveyors[0].Attributes.UserID
      case 2:
        return `${surveyors[0].Attributes.UserID}, ${surveyors[1].Attributes.UserID}`
      default:
        return `${surveyors[0].Attributes.UserID}, and ${surveyors.length - 1} others`
    }
  }
  
// function to generate the title that displays when hovering over assigned surveyors
function generateSurveyorTitle(surveyors:Array<any>) {
    let surveyorString = ``
  
    if (!surveyors?.length) {
      return 'unassigned'
    }
  
    surveyors.forEach((surveyor:any) => {
      surveyorString += `${surveyor.Attributes.UserID}, `
    })
  
    return surveyorString.substring(0, surveyorString.length - 2)
}
