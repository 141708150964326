import { HttpRequest } from "../httpRequest";
import { NEW_API_URL } from "api/constants"
import { encodeUriAll } from "utils/UrlUtils";

export const getSettingConfiguration = async(selectedMapServiceName: string) => {
    const getSettingConfigurationsUrl = (`/v1/maps/configuration/${selectedMapServiceName}`);
    return await HttpRequest.get(NEW_API_URL, getSettingConfigurationsUrl);
};

export const getFeatureServerConfiguration = async(selectedMapServiceName: string) => {
    const getFeatureServerConfigurationUrl = (`/v1/maps/featureserverconfiguration/${selectedMapServiceName}`);
    return await HttpRequest.get(NEW_API_URL, getFeatureServerConfigurationUrl);
}