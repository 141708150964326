import {
  mapPageMapType,
  surveyAreaMapType,
  surveyInitiationMapType,
  surveyAssignmentMapType,
  surveyReviewMapType
} from '../common/Map/MapDucks';

/**
 * Summary: Get the corresponding reducer for the given mapType
 *
 * @param mapType map page
 *
 * @return corresponding reducer for the mapType
 */
export const getReducer = (mapType: string) => {
  const reducers: any = {
    [mapPageMapType]: "MapPageMapReducer",
    [surveyAreaMapType]: "SurveyAreaMapReducer",
    [surveyInitiationMapType]: "SurveyInitiationMapReducer",
    [surveyAssignmentMapType]: "SurveyAssignmentMapReducer",
    [surveyReviewMapType]: "SurveyReviewMapReducer"
  }
  return reducers[mapType]
}
