import React from 'react';
import { isAfter, add } from 'date-fns';
import { isNull } from 'lodash';

/**
 * Summary: add days to a date
 * @returns New Date returned
 */
export const addDays = (date: Date, numberOfDays: number) => {
  return new Date(date.getTime() + numberOfDays*24*60*60*1000);
}

/**
 * Summary: convert the given date in the given returnValue format
 * @param date date to convert
 * @param isDateUTC true if the given date is in UTC
 * @param returnUTC if true, return date in UTC, else in local time (ignored if returnValue === 'epoch' or 'iso)
 * @param returnValue return date value in this format
 */
export const convertDate = (
  date: Date | string | number,
  isDateUTC: boolean,
  returnUTC: boolean,
  returnValue: 'epoch' | 'iso' | 'fullString' | 'date' | 'year' | 'month' | 'day'
) => {
  if (!date) {
    return date
  }

  if (Object.keys(date).includes("DateTime")) {
    date = (date as any).DateTime
  }

  // suffix with 'UTC' if given date is in UTC (case: date === '2021/01/01')
  let newDate = (isDateUTC && typeof(date) !== "number") ?
    new Date(`${date} UTC`) :
    new Date(date)

  if (isNaN(newDate.getTime())) {
    console.error("Invalid date conversion.")
    return date
  }

  // maintain some consistency between the two formats (instead of using a raw .toString())
  let fullString = returnUTC ?
    newDate.toUTCString() :
    newDate.toString().substr(0, newDate.toString().indexOf(" ("))
  let year = returnUTC ? newDate.getUTCFullYear() : newDate.getFullYear()
  let month = returnUTC ? newDate.getUTCMonth() + 1 : newDate.getMonth() + 1
  let day = returnUTC ? newDate.getUTCDate() : newDate.getDate()

  let values: any = {
    'epoch': newDate.getTime(),
    'iso': newDate.toISOString(),
    'fullString': `${fullString}`,
    'date': `${year}/${month}/${day}`,
    'year': `${year}`,
    'month': `${month}`,
    'day': `${day}`
  }

  return values[returnValue]
}

/**
 * Summary: return the current date in the requested timezone
 * @param returnUTC if true, return date in UTC, else in local time (ignored if returnValue === 'epoch' or 'iso')
 * @param returnValue return date value in this format
 */
export const getCurrentDate = (
  returnUTC: boolean,
  returnValue: 'epoch' | 'iso' | 'fullString' | 'date' | 'year' | 'month' | 'day'
) => {
  let currentDate = new Date()

  // isDateUTC === false, since new Date() generates local timezone
  return convertDate(currentDate, false, returnUTC, returnValue)
}

/**
 * Summary: return the current date added with the given offset in the requested timezone
 * @param offset the offset to be added to the current time in milliseconds
 * @param returnUTC if true, return date in UTC, else in local time (ignored if returnValue === 'epoch' or 'iso')
 * @param returnValue return date value in this format
 */
export const getOffsetDate = (
  offset: number,
  returnUTC: boolean,
  returnValue: 'epoch' | 'iso' | 'fullString' | 'date' | 'year' | 'month' | 'day'
) => {
  let currentDate = new Date()
  let offsetDate = new Date(currentDate.getTime() + offset)

  // isDateUTC === false, since new Date() generates local timezone
  return convertDate(offsetDate, false, returnUTC, returnValue)
}

// trim the date out of a datetime string
export const trimDate = (date:string) => {
  return date.substr(0, date.indexOf(' '))
}

/**
 * Summary: takes date and returns a element with a color
 *  coded readed date based on:
 *  red : date is within 30 days current date
 *  yellow : date is within 60 days of current date
 *  grey : default
 * @param date Unix time stamp for color coded date
 *
 * @return react element with color coded text
 */
export const getColorCodedDate = (date:any) => {
  if(!isNull(date)) {
    if(typeof date === 'string') {
      date = convertDate(date, false, false, 'epoch')
    }
    let currentDate = new Date();
    let dateIn30Days = add(currentDate,{days:30})
    let dateIn60Days = add(currentDate,{days:60})
    let formattedDate = convertDate(date, false, false, 'date')?.toString()
    let dateColor = 'date-normal'
  
    if (isAfter(dateIn30Days,date)) {
      dateColor = 'date-overdue'
    }
    else if (isAfter(dateIn60Days,date)) {
      dateColor = 'date-warning'
    }
    return (
      <span
        className = {`date-container ${dateColor}`}
        title = {formattedDate}
      >
        {formattedDate}
      </span>)
  }
  return (
    <span
      className = {`date-container empty`}
      title = {""}
    >
      {""}
    </span>)
}
