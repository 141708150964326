import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isEqual, isUndefined } from 'lodash';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import AttachmentIcon from '@material-ui/icons/Attachment';
import EmptyTable from './Table/EmptyTable';
import ChildTable from './Table/ChildTable';
import CheckBoxTable from "common/Tables/PaginatedTable/CheckBoxTable/CheckboxTable";
import Droplist, { countChildren } from './Droplist/Droplist';
import GreyLine from 'common/GreyLine/GreyLine';
import Attachment from 'common/Attachment/Attachment';
import DownloadWidget from'./DownloadWidget';
import BulkUpdateModal from './BulkUpdateModal';
import ParentTableCell from './Table/ParentTableCell';
import { convertDate } from 'utils/DateUtils';
import InfoSlideoutBodyContext from './InfoSlideoutBodyContext';
import Attachments from './Attachments';
import { getAttachments, deleteAttachments } from './AttachmentsDucks';
import { store } from "app/store";

import {
  RESET_INFO_INDEX,
  RESET_INFO_IDS,
  RESET_INFO_CHILD,
  RESET_INFO_FIELDS,
  SET_INFO_ZOOM_GEOMETRY,
  checkForEditProtection,
  getSpecificFeatureInfo,
  SET_HIGHLIGHT_INFO_TOOL,
  SET_MAP_NOTIFICATION,
  SET_FORCE_RELATIONSHIP_HIGHLIGHT
} from 'common/Map/MapDucks';
import { getReducer } from './../../../../utils/ReduxUtils';
import { 
  getLayerMapServiceIdByName,
  getLayerOrTableById,
  getLayerOrTableByName,
  isLayer
} from 'utils/GridUtils';
import { getNameMapping, mapAttribute } from 'utils/NameMappingUtils';
import './InfoSlideoutBody.scss';
import { esriGeometryType } from 'common/Map/SelectFeatures';
import { arcgisQueryParams, concatQueryingParameters, getArcgisQuery } from 'api';
import { axiosErrorHandler } from 'utils/AxiosUtils';

interface IInfoSlideoutBody {
  mapType:string
}

const InfoSlideoutBody = ({mapType}:IInfoSlideoutBody) => {
  const dispatch = useDispatch();
  const currentReducer = useSelector((state:any) => state[getReducer(mapType)]);
  const securityRoles = useSelector((state:any) => state.SettingsReducer.securityRoles)
  const selectedUserRole = useSelector((state:any) => state.LoginReducer.selectedUserRole)
  const infoAttachments = useSelector((state:any) => state.AttachmentsReducer.Attachments)
  const loggedInUser = useSelector((state:any) => state.LoginReducer.loggedInUser)
  const userID = securityRoles.Users.find((user:any) => user.UserName === loggedInUser).UserId
  const tableIds = useSelector((state: any) => state.SettingsReducer.tableMapServicesList).map((table:any) => table.id);
  // Data states
  const [infoResults, setInfoResults] = useState<any>(currentReducer.infoResults);
  const [infoResultsData, setInfoResultsData] = useState<any>([]);
  const [itemsWithRelationships, setItemsWithRelationships] = useState<any>([]);
  // Visual states
  const [isDirectoryActive, setIsDirectoryActive] = useState<boolean>(true);
  const [isGpsActive, setIsGpsActive] = useState<boolean>(false);
  const [isDownloadMenuOpen, setIsDownloadMenuOpen] = useState<boolean>(false);
  const [renderDownloadElement, setRenderDownloadElement] = useState<boolean>(false);
  const [isAttachmentActive, setIsAttachmentActive] = useState<boolean>(false);
  const [showAttachment, setShowAttachment] = useState<boolean>(false);
  const [numOfAttachment, setNumOfAttachment] = useState<number>(0);
  // Information states
  const [selectedLayerNames, setSelectedLayerNames] = useState<Array<any>>([]);
  const [selectedTableNames, setSelectedTableNames] = useState<Array<any>>([]);
  const [directoryIndex, setDirectoryIndex] = useState<number>(0);
  const [prevParentDirectoryIndex, setPrevParentDirectoryIndex] = useState<number>(0);
  const [isParent, setIsParent] = useState<boolean>(true);
  const [isRelationship, setIsRelationship] = useState<boolean>(false);
  const [relationshipId, setRelationshipId] = useState<number>(0);
  const [relationshipTable, setRelationshipTable] = useState<any>([]);
  const [relationshipFields, setRelationshipFields] = useState<any>([]);
  const layers = useSelector((state: any) => state.SettingsReducer.settings.Layers);
  const tables = useSelector((state: any) => state.SettingsReducer.settings.Tables);
  const zoomToLayerForSE = useSelector((state: any) => state.SettingsReducer.globalAliases)['ZoomTo-SurveyEvent'];
  const [originalCell, setOriginalCell] = useState<any>({featureLayerId: null, objectId: null, name: null, value: null});
  const [editCell, setEditCell] = useState<any>({featureLayerId: null, objectId: null, name: null, value: null});
  const selectedNames = [...selectedLayerNames, ...selectedTableNames]
  const [currentPageIndex, setPageIndex] = useState(0);
  const [selectedItems, setSelectedItems] = useState({});
  const [selectedDataItems, setSelectedDataItems] = useState({});
  const [numSelections, setNumSelections] = useState<number>(0);
  const [selectionIndex, setSelectionIndex] = useState<Array<number>>([]);
  const [resetSelect, setResetSelect] = useState<boolean>(false);
  const infoIds = currentReducer.infoIds;
  const infoChildren = currentReducer.infoChildren;
  const infoFields = currentReducer.infoFields;
  const infoIndex = currentReducer.infoIndex;
  let data: Array<Object> = [];
  let dataToExport: Array<Object> = []; //used for exporting csv 
  const downloadDataRef = useRef<Array<Object>>([])
  const columns: Array<Object> = [];
  const uniqueSelectedLayerNames = Array.from(new Set(selectedLayerNames));
  const uniqueSelectedTableNames = Array.from(new Set(selectedTableNames));
  const filename = uniqueSelectedLayerNames.length ? uniqueSelectedLayerNames : uniqueSelectedTableNames;
  const handleSetPageIndex = (rowIndex:number) => setPageIndex(rowIndex);
  const [savedParentRelationshipTable, setSavedParentRelationshipTable] = useState<any>([])
  const [isParentRelationshipEdited, setIsParentRelationshipEdited] = useState<boolean>(false);
  const [isShowUpload, setIsShowUpload] = useState(false);
  const [attachmentsApplyEditsProps, setAttachmentsApplyEditsProps] = useState<any>({});
  const [currentLayerOrTable, setCurrentLayerOrTable] = useState<{
    name: string | undefined;
    isLayer: boolean | undefined;
  }>({
    name: undefined,
    isLayer: undefined,
  });
  const gpsButtonRef = useRef<any>(null)
  
  const infoMainRef = useRef<any>(null);

  useEffect(() => {
    if (resetSelect) {
      setResetSelect(false)
    }
  }, [resetSelect])

  useEffect(() => {
    if (isParent && directoryIndex !== prevParentDirectoryIndex) {
      setResetSelect(true)
      setPrevParentDirectoryIndex(directoryIndex)
    }
  }, [directoryIndex])

  // Todo: fix handle selection issue for specific records export
  //const handleItemSelectionChange = (selectedItems:any) => setSelectedItems(selectedItems);

  /**
   * Summary: Populate the data for the table. We are using a separate table for export so the table 
   * in lantern stays the same. We're creating it here because going through the entire table 
   * again every time you need to export is not efficient, especially with bigger tables
   *
   * @param attributes attributes of the layer/table
   * @param fields permissions of the layer/table
   */
  const populateData = (attributes: any, fields: Array<any>) => {
    const attributesData: any = {}
    const attributesdataToExport: any = {}

    fields.forEach((field: any) => {
      const name = field.Name
      const value = attributes[name] ?? attributes[getNameMapping(name)]

      if (field.FieldType === 5) {
        attributesData[name] = value?.DateTime || convertDate(value, false, false, 'date') || null
        attributesdataToExport[name] = attributesData[name]
      } else if (field.FieldType === 4 && typeof value === 'string') {
        attributesData[name] = value
        //for export table, replace " with "", makes " work properly in csv
        //also prevents commas from acting like column separators
        attributesdataToExport[name] = value.replace(/"/g, '""');
      } else {
        attributesData[name] = value
        attributesdataToExport[name] = value
      }
    })
    data.push(attributesData)
    dataToExport.push(attributesdataToExport)
  }

  /**
   * Summary: Populate the columns for the table
   * @param featureLayerId layer/table id according to Lantern_Survey (MapServer)
   * @param fields field permissions of the item
   * @param featureCheckResult result of feature check
   */
  const populateColumns = (featureLayerId: number, fields: Array<any>) => {
    // push column keys only once
    if (columns.length === 0) {
      fields.forEach((field: any) => {
        if (field.IsVisible) {
          const name = field.Name
          const aliasName = field.AliasName

          columns.push({
            Header: aliasName,
            accessor: name,
            field: field,
            featureLayerId: featureLayerId,
            mapType: mapType,
            originalCell: originalCell,
            setOriginalCell: setOriginalCell,
            editCell: editCell,
            setEditCell: setEditCell,
            changeLocal: changeLocal
          })
        }
      })
    }
  }

    /**
   * Summary: reflect applied edits to the local data
   *
   * @param featureLayerId layer/table id according to Lantern_Survey (MapServer)
   * @param objectId id of that specific layer/table
   * @param name name of the cell to update locally
   * @param value value of the cell to update locally
   */
  const changeLocal = (
    featureLayerId: number,
    objectId: number,
    name: string,
    value: any
  ) => {
    setOriginalCell((prevState: any) => ({
      ...prevState,
      value: value
    }))

    if (!isRelationship) {
      const newInfoResultsData = infoResultsData;

      // array.find() (breaks after first find) but without a return
      for (let i = 0; i < newInfoResultsData.length; i++) {
        const data = newInfoResultsData[i]
        if (
          (data.layerName
            ? featureLayerId === data.id
            : featureLayerId === data.id + layers.length) &&
          (objectId === data.attributes[data.objectIdFieldName] ||
            objectId === data.attributes.OBJECTID)
        ) {
          data.attributes[name] = value;
          newInfoResultsData[i] = data;
          setInfoResultsData(newInfoResultsData);
          break;
        }
      }
    } else {
      // Case where Parent of a relationship has a table that needs to reflect any edit changes
      const newRelationshipTable: any[] = [];
      const newJob: any[] = [];
      let updatedJobIndex = -1;
      
      // create a new relationshipTable array with the updated field
      relationshipTable.forEach((job: any, jobIndex: number) => {
        if (job.OBJECTID !== objectId) {
          return newRelationshipTable.push(job)
        }
        // populate the new job
        Array.from(Object.keys(job)).forEach((attributeName: any) => {
          if (attributeName === name && value !== job[attributeName])
            updatedJobIndex = jobIndex  // record which index job is getting updated
          newJob[attributeName] = (attributeName === name)
              ? value
              : job[attributeName];
        });
        // add the new job to the new relationship table
        newRelationshipTable.push(newJob);
      });
      setRelationshipTable(newRelationshipTable);
      
      if (isParent) {
        /**
         * For the parent component, editing within the table would update the values in BE but when we go to the individual jobs,
         * they will not reflect the changes (until we reselect the parent from the droplist)
         * Save this relationship to update the child components data locally without a re-render (re-selection from droplist). 
         */
        let newSavedParentRelationshipTable: any[] = newRelationshipTable;
        if (!isEqual(savedParentRelationshipTable, [])) {
          // Being in this block => there have been preveious edits made already so we need to update the previous edits with these new changes.
          newSavedParentRelationshipTable = savedParentRelationshipTable;
          newSavedParentRelationshipTable[updatedJobIndex][name] = newRelationshipTable[updatedJobIndex][name]
        }
        setSavedParentRelationshipTable(newSavedParentRelationshipTable)
        setIsParentRelationshipEdited(true);
      }
    }
  }

  useEffect(() => {
    /**
     * Selecting the items in the info tool droplist calls `setRelationshipTable` and modifies it 
     * We want to see if we have saved any data in `savedParentRelationshipTable`, if so, we set the data back to the edited info
     */
    if (relationshipTable && isParentRelationshipEdited) {
      if (relationshipTable.OBJECTID) {  // Case where we are selecting a survey job
        savedParentRelationshipTable.forEach((job: any) => {
          if (job.OBJECTID === relationshipTable.OBJECTID) {
            setRelationshipTable(job)
          }
        })
      } else {  // Case where we are clicking on the parent
        setRelationshipTable(savedParentRelationshipTable)
        setIsParentRelationshipEdited(false)  // set to false to avoid endless rerender loop
      }
    }

    if (isParent && savedParentRelationshipTable.length > 0) {
      setRelationshipTable(savedParentRelationshipTable)
      setIsParentRelationshipEdited(true)
    }
  }, [relationshipTable, selectedItems])

  useEffect(() => {
    // Currently this runs every time infoResults changes, which scales really poorly
    // This causes a major performance issue and produces other potential bugs when 1000 items are being loaded into infoResults from 1 query
    // TODO: Refactor this
    setInfoResults(currentReducer.infoResults)
    setInfoResultsData([...currentReducer.infoResults.layers, ...currentReducer.infoResults.tables])
    setItemsWithRelationships([...currentReducer.infoResults.layers, ...currentReducer.infoResults.tables].filter((data: any) => {
      return !isEmpty(data.childRelationships)
    }))

    // reset states if new results
    setShowAttachment(false)
    setDirectoryIndex(0)
    setIsParent(true)
    setIsRelationship(false)
    setRelationshipTable([])
    setRelationshipFields([])
    setSavedParentRelationshipTable([])

    const features: {[index: number]:any} = {}

    currentReducer.infoResults.layers.forEach((feature:any) => {
      const layer = getLayerOrTableByName(feature.layerName)
      const layerId = layer.Id
      const objectId = layer.FeatureClass.OidFieldName
      if (layerId in features) {
        features[layerId].push(feature.attributes[objectId])
      }
      else {
        features[layerId] = [feature.attributes[objectId]]
      }
    })
    dispatch(checkForEditProtection(userID, features, mapType))
  }, [currentReducer.infoResults, selectedUserRole])

  useEffect(() => {
    getNames(infoResults)
    dispatch({
      type: RESET_INFO_INDEX + mapType
    })
    dispatch({
      type: RESET_INFO_IDS + mapType,
      infoLength: infoResults.layers.length + infoResults.tables.length
    })
    dispatch({
      type: RESET_INFO_CHILD + mapType,
      infoLength: infoResults.layers.length + infoResults.tables.length
    })
    dispatch({
      type: RESET_INFO_FIELDS + mapType,
      infoLength: infoResults.layers.length + infoResults.tables.length
    })
  }, [infoResults])

  useEffect(() => {
    const features: {[index: number]:any} = {}
    infoChildren.forEach((child:any, index:number) => {
      for (let i = 0; i < child.length; i++) {
        const layerId = infoIds[index][i]
        if (!tableIds.includes(layerId)) {
          if (layerId in features) {
            features[layerId] = features[layerId].concat(child[i].map((feature:any) => feature.OBJECTID))
          }
          else {
            if (child[i].length) {
              features[layerId] = child[i].map((feature:any) => feature.OBJECTID)
            }
          }
        }
      }
    })

    if (!isEmpty(features)){
      dispatch(checkForEditProtection(userID, features, mapType, true))
    }
  }, [infoChildren])

  /**
   * Summary: Get the names of all the selected records, in the order of selection
   *
   * @param infoResults raw data of the selected records
   */
  const getNames = (infoResults: any) => {
    setSelectedLayerNames([])
    setSelectedTableNames([])
    Object.keys(infoResults).forEach((resultType: string) => {
      if (resultType === "layers") {
        infoResults.layers.forEach((layer: any) => {
          setSelectedLayerNames(prevState => [
            ...prevState,
            layer.layerName
          ])
        })
      } else if (resultType === "tables") {
        infoResults.tables.forEach((table: any) => {
          setSelectedTableNames(prevState => [
            ...prevState,
            table.tableName
          ])
        })
      }
    })
  }

  /** Summary: Handle click for and toggle directory icon */
  const directoryClick = () => {
    setIsDirectoryActive((prevState) => !prevState)
  }

  /**
   * Summary: Find layer, get geometry info, highlight and zoom into the layer
   * @returns layer geometry to be zoomed into
   */
  const findLayerAndGetGeometry = async () => {
    if (isUndefined(currentLayerOrTable.name) || !currentLayerOrTable.isLayer) return;

    const layer = getLayerOrTableByName(currentLayerOrTable.name);
    const globalIdField = layer.Fields.find((field: any) => field.Name === layer.FeatureClass.GlobalIdFieldName);
    const layerGlobalId = `${(relationshipTable.GlobalID || relationshipTable.GLOBALID)}`;

    // Make API call to get layerInfo 
    const featureInfo = await getSpecificFeatureInfo(layer.Id, globalIdField, layerGlobalId);

    // Add `type` attribute to the geometry (for zooming into if layer is a point)
    if (!Object.keys(featureInfo?.geometry).includes('type')) {
      featureInfo.geometry["type"] = esriGeometryType[featureInfo.geometryType];
    }

    // flag a force highlight the object
    dispatch({
      type: SET_FORCE_RELATIONSHIP_HIGHLIGHT + mapType,
      relationshipForceHighlight:
        isRelationship &&
        uniqueSelectedTableNames.some((tableName: any) =>
          tableName.includes("Survey Event")
        ),
    });

    // Highlight this object
    dispatch({
      type: SET_HIGHLIGHT_INFO_TOOL + mapType,
      highlightedItemInfoTool: {
        attributes: featureInfo.attributes,
        geometry: featureInfo.geometry,
        // set polygon type to force drawing the polygon on the map
        polygonType: featureInfo.geometry.type
      }
    })
    return [featureInfo.geometry];
  }

  /**
   * Summary: Get the layer name for a relationship child
   */
  const getRelationshipLayerName = () =>
    getLayerOrTableById(relationshipId)?.Name ||
    getLayerOrTableById(relationshipId)?.DisplayName;
  ;

  useEffect(() => {
    // every time we select a new child in a relationship (in droplist), we update the currentLayerName
    const newLayerName = getRelationshipLayerName();
    setCurrentLayerOrTable({
      name: newLayerName,
      isLayer: isLayer(newLayerName),
    });
  }, [relationshipId])

  useEffect(() => {
    // reset the highlighted item
    if (isParent && currentLayerOrTable.name === "Survey Event") {
      dispatch({
        type: SET_HIGHLIGHT_INFO_TOOL + mapType,
        highlightedItemInfoTool: {}
      })
    }
  }, [selectionIndex])

  const showUnableToFindLocationMessage = (overrideMessage: string | undefined = undefined) => {
    dispatch({
      type: SET_MAP_NOTIFICATION + mapType,
      message: overrideMessage ?? "Unable to find the location of selected asset",
      success: false
    })
  }

  /**
   * Get the traversed index from the droplist for the selected item in the checkbox
   * to access the selected feature by `infoResutlsData`.
   */
  const getTraversedInfoResultsDataIndex = () => {
    const droplistNames = [...uniqueSelectedLayerNames, ...uniqueSelectedTableNames];

    let traversedIndexes = 0;

    const indexOfCurrentLayerTableName = droplistNames.indexOf(currentLayerOrTable.name);
    if (indexOfCurrentLayerTableName === -1) return 0;

    droplistNames.forEach((name: string, i: number) => {
      if (i < indexOfCurrentLayerTableName) {
        traversedIndexes += countChildren([...selectedLayerNames, ...selectedTableNames], name);
      }
    })
    
    return traversedIndexes;
  }

  /**
   * Summary: fetch geometry of the related layer for a survey event (SE) when a SE is
   * selected through the parent table or the child table (not a relationship) and the
   * gps button is clicked.
   */
  const surveyEventZoomFeature = () => {
    let infoBodyData = undefined;
    let relationshipExists = false;

    if (isParent) {
      const selectedIndex = selectionIndex[0];
      if (selectionIndex.length > 1) return;
      infoBodyData = infoResultsData[selectedIndex + getTraversedInfoResultsDataIndex()];
    } else {
      const childIndex = directoryIndex - Array.from(new Set([...selectedLayerNames, ...selectedTableNames])).length
      infoBodyData = infoResultsData[childIndex];
    }

    // confirm if a relationship exists
    relationshipExists = infoBodyData?.relationships.find((relationship: any) => {
      if (relationship.DestinationDisplayName === zoomToLayerForSE) return true;
    })
    if (!relationshipExists) {
      showUnableToFindLocationMessage()
      return;
    }

    // get the origin and destination field names
    let destinationFieldName = '';
    let originFieldName = '';
    infoBodyData?.childRelationships.find((childRelationship: any) => {
        if (childRelationship.DestinationDisplayName === zoomToLayerForSE) {
          destinationFieldName = childRelationship.DestinationFieldName;
          originFieldName = childRelationship.OriginFieldName;
          return true;
        }
      }
    );
    if (destinationFieldName === '' || originFieldName === '') {
      return showUnableToFindLocationMessage();
    };

    // get field information
    const destinationLayerField = layers
      .find((reduxLayer: any) => reduxLayer.Name === zoomToLayerForSE)
      ?.Fields.find((field: any) => field.Name === destinationFieldName);

    // make the api call to get the geometry info for the layer that survey event maps to
    const whereClause = destinationLayerField.FieldType <= 3
      ? `${destinationFieldName} = ${infoBodyData.attributes[originFieldName]}`
      : `${destinationFieldName} = '${infoBodyData.attributes[originFieldName]}'`

    const selectedMapService = store.getState().LoginReducer.selectedMapService.decodedLabel;
    const zoomToLayerId = getLayerMapServiceIdByName(zoomToLayerForSE);
    const queryingParams = concatQueryingParameters({
      [arcgisQueryParams.where]: whereClause,
      [arcgisQueryParams.outFields]: `*`,
    });
    
    getArcgisQuery(
      selectedMapService,
      zoomToLayerId,
      queryingParams
    ).then((res: any) => {
      axiosErrorHandler(res);
      const geometryType = esriGeometryType[res.data.geometryType]
      const geometry = {
        ...res.data.features[0]?.geometry,
        type: geometryType,
        spatialReference: res.data.spatialReference,
      };

      if (isUndefined(res.data.features[0]?.geometry)) {
        return showUnableToFindLocationMessage();
      }

      zoomAndHighlightAction(
        res.data.features[0]?.attributes,
        geometry,
        geometryType,
        zoomToLayerForSE
      )
    });
    
  }

  const zoomAndHighlightAction = (
    attributes: any,
    geometry: any,
    polygonType: any,
    layerName: String
  ) => {
    // flag a force highlight the object
    dispatch({
      type: SET_FORCE_RELATIONSHIP_HIGHLIGHT + mapType,
      relationshipForceHighlight: true,
    });

    // highlight feature
    dispatch({
      type: SET_HIGHLIGHT_INFO_TOOL + mapType,
      highlightedItemInfoTool: {
        attributes: attributes,
        geometry: geometry,
        polygonType: polygonType,
      },
    });

    // zoom to feature
    dispatch({
      type: SET_INFO_ZOOM_GEOMETRY,
      infoGeometry: [geometry],
      infoLayerName: layerName,
    });
  };

  /** Summary: If you are currently on a child table, then enable gps zoom button
   * If not, then you are on a parent, and only enable if 0-1 records are checked */ 
  const enableGPSClick = () => {
    return (!isParent || numSelections < 2);
  }
  
  /** Summary: Handle click for and toggle GPS icon */
  const gpsClick = async () => {

    if (currentLayerOrTable.name === "Survey Event" && !isRelationship) {
      surveyEventZoomFeature();
      return
    } else if (!isRelationship && isParent && currentLayerOrTable.isLayer && numSelections === 1) {
      // checkbox table zoom feature
      const selectedIndex = selectionIndex[0];
      if (selectionIndex.length > 1) return;      
      const infoBodyData = infoResultsData[selectedIndex + getTraversedInfoResultsDataIndex()];

      zoomAndHighlightAction(
        infoBodyData.attributes,
        infoBodyData.geometry,
        infoBodyData.geometry.type,
        currentLayerOrTable.name ?? ''
      );
      return;
    }

    let geometry:Array<any> = [];
    let layerName = ''
    //Get all items from layer if parent is selected
    if (isParent) {
      let name = Array.from(new Set([...selectedLayerNames, ...selectedTableNames]))[directoryIndex];
      infoResultsData.forEach((item: any) => {
        if (item.layerName === name) {
          geometry.push(item.geometry)
        }
      })
      layerName = name
    }
    //Get item if child is selected
    else {
      // this works when its not a relationship but for relationships it doens't (geometry missing undefined)
      if (!isRelationship) {
        const childIndex = directoryIndex - Array.from(new Set([...selectedLayerNames, ...selectedTableNames])).length
        const childItem = infoResultsData[childIndex]
        geometry = [childItem.geometry];
        layerName = childItem.layerName;
        
      } else {
        // childIndex causes index-out-of-bound exception for child relationships
        geometry = await findLayerAndGetGeometry() || [];
        layerName = currentLayerOrTable.isLayer && currentLayerOrTable.name ? currentLayerOrTable.name : '';
      }
    }
    
    // zoom to the geometry
    if (geometry.length > 0 && layerName) {
      dispatch({
        type: SET_INFO_ZOOM_GEOMETRY,
        infoGeometry: geometry,
        infoLayerName: layerName,
      });
    }
  }

  useEffect(() => {
    if (isGpsActive) {
      const activateGps = async () => {
        await gpsClick();
        setIsGpsActive(false);
        gpsButtonRef.current.classList.remove("info-main-header-active")
      }
      activateGps()
    }
  }, [isGpsActive])

  /** Summary: Handle click for and toggle attachment icon */
  const attachmentClick = () => {
    setIsAttachmentActive((prevState) => !prevState);
  }

  /**
   * Summary: check all attribute permissions (ie; IsVisible, SortOrder)
   *
   * @param attributes all the field attributes of a layer/table
   * @param permissions permissions for all the fields of a layer/table
   *
   * @return all attributes of a layer/table with permissions applied
   */
  const checkAttributes = (attributes: any, permissions: any) => {
    attributes = mapAttribute(attributes)
    if (isUndefined(permissions)) {
      return attributes
    } else {
      let visibleAttributes = {};
      let visiblePermissions: any = [];

      permissions.sort((a: any, b: any) => (a.SortOrder > b.SortOrder) ? 1 : -1)
      permissions.forEach((permission: any) => {
        if (permission.IsVisible  || permission.AliasName === 'OBJECTID') {
          visiblePermissions = [...visiblePermissions, permission];
        }
      })

      permissions = visiblePermissions;

      // Only visible attributes are included
      visiblePermissions.forEach((permission: any) => {
        visibleAttributes = {
          ...visibleAttributes,
          [permission.Name]: attributes[getNameMapping(permission.Name)]
        }
      })

      return visibleAttributes
    }
  }

  /**
   * Summary: Renders the appropriate attribute table of the currently selected item
   *
   * @param directoryIndex index of the currently selected item in the directory
   * @param isParent boolean if selected item is parent
   * @param isRelationship boolean if selected item is relationship item
   *
   * @return attributes table for either the parent or child item
   */
  const renderTable = (
    directoryIndex: number,
    isParent: boolean,
    isRelationship: boolean,
    setSelectedItems: Function
  ) => {
    if (isEmpty([...selectedLayerNames, ...selectedTableNames])) {
      return (
        <EmptyTable
          selection={false}
          directoryIndex={directoryIndex}
          setDirectoryIndex={setDirectoryIndex}
          isParent={isParent}
          setIsParent={setIsParent}
        />
      )
    } else {
      const name = Array.from(new Set(selectedNames))[directoryIndex];
      infoResultsData.forEach((item: any) => {
        const itemName = item.layerName || item.tableName;
  
        if (!isRelationship) {
          if (itemName === name) {
            const featureLayer = [...layers, ...tables].find((featureLayer: any) => {
              return itemName === featureLayer.Name || itemName === featureLayer.DisplayName
            })
            const featureLayerId = item.layerName ? featureLayer.Id : featureLayer.Id + layers.length
            let attributes = checkAttributes(item.attributes, item.fields)
            //Ensure OBJECTID will be in the data even if the field is not visible
            if (!('OBJECTID' in attributes)) {
              attributes = {
                ...attributes,
                OBJECTID: item.attributes['OBJECTID']
              }
            }
            populateColumns(featureLayerId, item.fields);
            populateData(item.attributes, item.fields);
          }
        } else {
          if (relationshipTable) {
            data = []
            dataToExport = []
            relationshipTable.forEach((item: any) => {
              populateColumns(relationshipId, relationshipFields);
              populateData(item, relationshipFields);
            })
          }
        }
      })
      downloadDataRef.current = dataToExport; //need for nested checkbox table export
      if (isParent) {
        return (
          <>
            <div className="table-label">
              <span className="attributes-label">Attributes</span>
              <span className={numSelections === 0 ? ' hidden' : ''}>{numSelections} Records Selected</span>
            </div>
            <div className="parent-container">
              <CheckBoxTable
                className="multi-plan-table"
                renderFilterBtn={false}
                data={data}
                tableColorTheme="GreyTopBorder"
                columns={columns}
                onPageIndexChange={handleSetPageIndex}
                onItemSelectionChange={setSelectedItems}
                defaultColumnOverride={{Cell: ParentTableCell}}
                setNumSelections={setNumSelections}
                setSelectionIndex={setSelectionIndex}
                disableSortReset={true}
                resetSelect={resetSelect}
                customPageSize={14}
              />
            </div>
          </>
        )
      }
      else {
        return (
          <ChildTable
            mapType={mapType}
            infoResultsData={infoResultsData}
            setInfoResultsData={setInfoResultsData}
            checkAttributes={checkAttributes}
            directoryIndex={directoryIndex}
            isRelationship={isRelationship}
            selectedNames={[...selectedLayerNames, ...selectedTableNames]}
            relationshipId={relationshipId}
            relationshipTable={relationshipTable}
            setRelationshipTable={setRelationshipTable}
            relationshipFields={relationshipFields}
            infoMainRef={infoMainRef}
            onFeatureEdiPropsChange={(featureId, objectId, globalId, fieldName) => { 
              setAttachmentsApplyEditsProps({ featureId, objectId, globalId, fieldName }); 
              dispatch(getAttachments(globalId)); 
            }
          }
          />
        )
      }
    }
  }

  /** Summary: Renders the attachments of the currently selected item */
  const renderAttachment = (currentReducer: any) => {
    if (isAttachmentActive) {
      return (
        <div id="attachment-gallery">
          <div className="attachment-header">
            <div className="text" id="screen-title">Attachments</div>
            <a href="#/" className="text link" id="screen-title" onClick={() => attachmentClick()}>
              Close
            </a>
          </div>
          <div className="container">
            <Attachments
              attachmentsEditsProps={attachmentsApplyEditsProps} 
              layerName={currentLayerOrTable.isLayer ? currentLayerOrTable.name : undefined}
              />
          </div>
          <div className="attachment-row">
            {infoAttachments.map((attachment: any, index: number) => {
              return (
                <Attachment
                  key={index}
                  className="attachment-individual"
                  title={attachment.fileName}
                  image={attachment.hyperlinkPath}
                  link={attachment.hyperlinkPath}
                  width={100}
                  height={80}
                  newWindow={true}
                  onDeleteAttachment={() => {
                    const stringObjectId = attachmentsApplyEditsProps.objectId.toString(); 
                    dispatch(deleteAttachments(stringObjectId));
                  }}
                />
              )
            })}
          </div>
        </div>
      )
    }
  }

  const handleDownloadButtonCancelClick = () => {
    setIsDownloadMenuOpen(prevState => !prevState);
  };

  const handleDownloadButtonExportClick = () => {
    setIsDownloadMenuOpen(prevState => !prevState);
  };

  // Summary: return the number of relationship children prior to the main child at mainChildIndex
  const countRelationshipChildren = (mainChildIndex: number) => {
    let sumOfRelationshipChildren = 0;
    for (let i = 0; i < mainChildIndex; i++) {
      sumOfRelationshipChildren += infoChildren[i].length;
    }
    return sumOfRelationshipChildren;
  }

  // get the filename for a nested checkbox table where idx format is '{arrSize}{idx},{childIdx}{numPriorChildrenIdx}'
  // b/c in droplist: setDirectoryIndex(uniqueNames.length+itemData.length+relationshipIndex+countRelationshipChildren(index))
  const getNestedCheckboxTableName = () => {
    const idxString:string = directoryIndex?.toString();

    const uniqueNames:string = (infoResults.tables.length+1).toString();
    const relationshipIdx:number = idxString.indexOf(uniqueNames) + uniqueNames.length;
    const commaIdx:number = idxString.indexOf(',');

    const parentIdx:number = +(idxString.substring(relationshipIdx, commaIdx));
    //in case of 11+ nested checkbox tables
    const numPriorChildrenIdx:number = idxString.length-countRelationshipChildren(parentIdx).toString().length;
    const nestedIdx:number = +(idxString.substring(commaIdx+1, numPriorChildrenIdx));

    const name = infoResults.tables[parentIdx]?.relationships[nestedIdx];
    return name?.DestinationDisplayName;
  }

  const getTimestamp = () => {
    const date = new Date();
    const day = date.getDate().toString();
    const month = (date.getMonth()+1).toString();
    const year = date.getFullYear().toString();
    const hours = date.getHours().toString();
    const minutes = date.getMinutes().toString();
    const seconds = date.getSeconds().toString();

    const timestamp = `${day}-${month}-${year} - ${hours}-${minutes}-${seconds}`;
    return timestamp;
  };

  return (
    <InfoSlideoutBodyContext.Provider value={selectedItems}>
      <div className={("info-body-container " + mapType)}>
        <div><GreyLine/></div>
        {/* Left side navigation section */}
        <div
          className={
            "info-directory " +
            (isDirectoryActive ? "" : "info-directory-inactive")
          }
        >
          <Droplist
            layerNames={selectedLayerNames}
            layerData={infoResults.layers}
            tableNames={selectedTableNames}
            tableData={infoResults.tables}
            itemWithRelationships={itemsWithRelationships}
            infoIds={infoIds}
            infoChildren={infoChildren}
            infoFields={infoFields}
            infoIndex={infoIndex}
            directoryIndex={directoryIndex}
            setDirectoryIndex={setDirectoryIndex}
            setIsParent={setIsParent}
            setIsRelationship={setIsRelationship}
            setRelationshipId={setRelationshipId}
            setRelationshipTable={setRelationshipTable}
            setRelationshipFields={setRelationshipFields}
            setIsAttachmentActive={setIsAttachmentActive}
            setShowAttachment={setShowAttachment}
            setNumOfAttachment={setNumOfAttachment}
            mapType={mapType}
            setSelectedDataItem={setSelectedDataItems}
            setCurrentLayerOrTable={setCurrentLayerOrTable}
          />
        </div>
        {/* Right side information section */}
        <div className="info-main" ref={infoMainRef}>
          {/* Information section: top side icon bar  */}
          <div className="info-main-header">
            <AccountTreeIcon
              className={
                "info-main-header-icon " +
                (isDirectoryActive ? "info-main-header-active" : "")
              }
              onClick={() => directoryClick()}
            />
            <GpsFixedIcon
              className={"info-main-header-icon info-main-header-icon-left " }
              ref={gpsButtonRef}
              onMouseDown={enableGPSClick() ? () => gpsButtonRef.current.classList.add("info-main-header-active") : undefined}
              onClick={enableGPSClick() ? () => setIsGpsActive(true) : undefined}
              color={enableGPSClick() ? 'inherit' : 'disabled'}
            />
            <BulkUpdateModal
              numSelections={numSelections}
              selectionIndex={selectionIndex}
              layerName={
                !isRelationship 
                  ? Array.from(new Set(selectedNames))[directoryIndex]
                  : getRelationshipLayerName()
                }
              data={data}
              infoResultsData={infoResultsData}
              setInfoResultsData={setInfoResultsData}
              relationshipTable={relationshipTable}
              setRelationshipTable={setRelationshipTable}
              isRelationship={isRelationship}
              isParent={isParent}
              mapType={mapType}
            />
            {infoResultsData.length !== 0 && isParent ? (
              <SaveAltIcon 
                  className={isDownloadMenuOpen ? "clickable info-main-header-active" : "clickable"} 
                  onMouseDown={() => handleDownloadButtonCancelClick()}
              />) : ""}
            <div
              className={
                "info-main-header-group info-main-header-icon-right " +
                (isAttachmentActive ? "info-main-header-active " : "info-main-header-inactive ") +
                (showAttachment ? "" : "info-main-header-icon-none")
              }
              onClick={() => attachmentClick()}
            >
              <AttachmentIcon className="info-main-header-icon"/>
              <span
                className={
                  "text info-main-header-icon-attachments " +
                  (isAttachmentActive ? "info-main-header-inactive " : "info-main-header-active ") +
                  (showAttachment ? "" : "info-main-header-icon-none")
                }
              >
                {infoAttachments.length}
              </span>
            </div>
          </div>
          {/* Information section: divides icon bar and info tables */}
          <hr className="info-main-divider"/>
          {/* Information section: bottom side info tables */}
          {isDownloadMenuOpen ? ( 
            <DownloadWidget 
              data={downloadDataRef.current}
              filename={(filename[directoryIndex]) ? `${filename[directoryIndex]} ${getTimestamp()}.csv` : `${getNestedCheckboxTableName()} ${getTimestamp()}.csv`}
              handleDownloadButtonCancelClick={handleDownloadButtonCancelClick} 
              handleDownloadButtonExportClick={handleDownloadButtonExportClick}
              currentPageIndex={currentPageIndex}
              selectedItems={selectedItems}
              selectedLayerOrTableName={currentLayerOrTable.name}
            /> 
          ) : ''}
          <div className="info-main-body">
          {useMemo(() =>
            renderAttachment(currentReducer) ||
            renderTable(
              directoryIndex, 
              isParent, 
              isRelationship, 
              setSelectedItems
            ), [data, selectedItems])}
          </div>
        </div>
      </div>
    </InfoSlideoutBodyContext.Provider>
  )
}

export default InfoSlideoutBody
