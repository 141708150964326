import { setDefaultOptions, loadScript, loadModules } from "esri-loader"

export let idenManager:any

/**
 * Summary: runs functions that are needed for esri to work properly
 * @param resolve Promise passed into function to return as resolved
 */
export const initalizeEsri = (resolve:any) => {
  setDefaultOptions({version:"4.19", css:true})
  loadIdentityManager(resolve)
}

/**
 * Summary: Preloads Identity Manager to be used globally across the app
 * NOTE: This can be used to preload other depencies from esri
 * NOTE: Has to be done through loadModules instead of webpack because of
 * ESRI just Dojo
 * @param resolve Promise passed into function to return as resolved
 */
export const loadIdentityManager = (resolve:any) => {
  loadScript()
  loadModules(['esri/identity/IdentityManager', 'esri/config'])
  .then(([IdentityManager, esriConfig])=>{
    esriConfig.request.interceptors.push({
      before: function(params:any) {
        if(params.url.includes(`${window.urlConfig.reactUrl}`)){
          params.requestOptions["method"] = "post";
          params.requestOptions.query = params.requestOptions.query || {};
          params.requestOptions.query['token'] = `${localStorage.getItem("ArcGISToken")}`;
        }
      },      
      after: function(response:any) {
        // Keeping this block for future, if we need to add something to the response object
      }
    });
    idenManager = IdentityManager
    resolve()
  })
}

/**
 * Summary: Loads any modules from esri js api
 *
 * @param moduleString file path for given module wanted to load, stated
 * by ArcGIS JS api documentation
 */
export const loadEsriModule = (moduleString:string) => {
  return new Promise((resolve) => {
    loadModules([moduleString]).then(([module]:any) => {
      resolve(module)
    })
  })
}
