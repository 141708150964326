import React, { useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { add, sub, isAfter, isBefore } from 'date-fns';
import { convertDate } from 'utils/DateUtils';
import Dropdown from 'common/Dropdown/Dropdown';
import { applyFilterToGridColumns } from 'utils/GridUtils';
import { getDefaultFilters } from './TableMultiSelectFilter';
import { SET_SURVEY_REVIEW_USER_SELECTIONS } from 'features/SurveyReview/SurveyReviewDucks';

/**
 * Summary: Filter out which compliance date drop down option should be displayed to the user
 * @param {Array} preFilteredRows data in the table
 * @param {Array} dropdownOptions all options to display to the user
 * @returns An array of sorted drop down options as per the provided data from the table.
 */
const getComplianceDateDropdownOptions = (preFilteredRows, dropdownOptions) => {
  const newDropDownOptions = new Set()
  const [pastDue, within30, within60, within90, within180, over180] = dropdownOptions
  
  const currentDate = new Date()
  const dayBeforeCurrentDate = sub(currentDate, {days: 1})
  const dateIn30Days = add(currentDate, {days: 30})
  const dateIn60Days = add(currentDate, {days: 60})
  const dateIn90Days = add(currentDate, {days: 90})
  const dateIn180Days = add(currentDate, {days: 180})

  preFilteredRows.forEach((row) => {
    const complianceDate =
      row?.attributes?.COMPLIANCEDATE ||
      row?.attributes?.ComplianceDate ||
      row?.values?.ComplianceDate ||
      row?.values?.COMPLIANCEDATE;
    if (!complianceDate) {
      return
    }
    const [year, month, day] = convertDate(complianceDate, false, true, 'date').split('/')
    const dateToCompare = new Date(year, month - 1, day)

    if (isBefore(dateToCompare, dayBeforeCurrentDate)) {
      newDropDownOptions.add(pastDue)
    } else if (isBefore(dateToCompare, dateIn30Days)) {
      newDropDownOptions.add(within30)
    } else if (isBefore(dateToCompare, dateIn60Days)) {
      newDropDownOptions.add(within60)
    } else if (isBefore(dateToCompare, dateIn90Days)) {
      newDropDownOptions.add(within90)
    } else if (isBefore(dateToCompare, dateIn180Days)) {
      newDropDownOptions.add(within180)
    } else if (isAfter(dateToCompare, dateIn180Days)) {
      newDropDownOptions.add(over180)
    }
  })

  // sort and return
  const newDropDownArray = [...newDropDownOptions]
  if (newDropDownArray.includes(pastDue)) {
    newDropDownArray.splice(newDropDownArray.indexOf(pastDue), 1)
    newDropDownArray.sort(
      (a, b) => (a.value.addAmount.days > b.value.addAmount.days) ? 1 : (b.value.addAmount.days > a.value.addAmount.days) ? -1 : 0)
    newDropDownArray.unshift(pastDue)
    return newDropDownArray
  } 
  return newDropDownArray.sort((a, b) => (a.value.addAmount.days > b.value.addAmount.days) ? 1 : (b.value.addAmount.days > a.value.addAmount.days) ? -1 : 0)
}

/**
 * Summary: This is a custom filter UI for selecting
 * a unique option from a list
 * @param {Object} column : This is the Column instance from React-table
 * @param {string} Header : header value
 * @param {()=>void} setFilter : React-table api callback
 * function to set filter on the table
 * @param {Object} preFilteredRows : Rows that have not been filtered yet for the specific column
 * @param {number} id : ID for each row for react keys
 * @param {Boolean} isReset : Is the state that
 * controls if we should reset the filter component this was discussed above at the
 * beginning of the table component
 * @param {String} type: can be set to "date" 
 */
export function SelectFilter({
  column: { Header, setFilter, preFilteredRows, id }, headerGroups, defaultColumn }, type) {
  preFilteredRows = defaultColumn?.preFilterRows.length ? defaultColumn.preFilterRows : preFilteredRows || []
  const [isDefaultFiltersSet, setIsDefaultFiltersSet] = useState(false)
  const [defaultFilters, setDefaultFilters] = useState(undefined)

  let dropdownOptions = []
  let preDefinedFilterValuesFlag = false
  let columns = headerGroups[0].headers
  let currentColumn
  let valueOnReset

  for(let index = 0;index < columns.length;index++){
    if(columns[index].id === id) {
      //sets the intitial filter in the filters panel
      currentColumn = applyFilterToGridColumns({defaultColumn, columns, currentColIndex: index});
      if (columns[index].preDefinedFilterValues){
        dropdownOptions = columns[index].preDefinedFilterValues 
        preDefinedFilterValuesFlag = true
      }
      // only set default values if they haven't been set already
      if (!isDefaultFiltersSet) {
        // if there is a pre-applied filter value, use it as default value, else use the column's default filters
        setDefaultFilters(currentColumn?.filterValue || columns[index]?.defaultFilters)
        setIsDefaultFiltersSet(true)
      }
      if (columns[index]?.defaultFilters) {
        valueOnReset = columns[index].defaultFilters
      }
      break;
    }
  }
  
  if (Header?.toLowerCase() === "compliance date" || Header?.toLowerCase() === "compliancedate") {
    dropdownOptions = getComplianceDateDropdownOptions(preFilteredRows, dropdownOptions)
  }

  // Calculate the options for filtering using the preFilteredRows
  const CreateOptions = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      let rowAttr;
      if(row?.attributes){
        rowAttr = Object.entries(row.attributes).reduce((a, [key, value]) => {
          a[key.toLowerCase()] = value
          return a
        }, {})
      } else if(row?.values){
        rowAttr = Object.entries(row.values).reduce((a, [key, value]) => {
          a[key.toLowerCase()] = value
          return a
        }, {})
      }

      let value = defaultColumn?.preFilterRows.length ? rowAttr[id?.toLowerCase()] : row.values[id]
      if (type === "date") {
        value = convertDate(value, false, false, 'date').toString()
      }
      options.add(value)
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  if (!preDefinedFilterValuesFlag) {
    dropdownOptions = CreateOptions
  }

  const selectFilterChangeCallback = function (id, e) {
    setFilter(e);
    if(defaultColumn.filterChangeCB){
      defaultColumn.filterChangeCB(id, e);
    }
  };

  useEffect(() => {
    if (defaultFilters) selectFilterChangeCallback(id, defaultFilters);
  }, []);

  return (
    <span className="dropdown-container">
      <div className="dropdown-header">
        {Header}
      </div>
      <Dropdown
        isReset = {defaultColumn.isReset}
        items = {dropdownOptions}
        placeHolder={"All"}
        onReset={() => selectFilterChangeCallback(id, valueOnReset)}
        defaultValue={defaultFilters && {
          label: currentColumn?.filterLabel || defaultFilters,
          value: defaultFilters,
        }}
        handleChange={(value) => selectFilterChangeCallback(id, value)}
        // on reset, dropdown's value will be set to forceValue
        forceValue={valueOnReset}
      />
    </span>
  )
}

/**
 * Summary: This is a custom filter UI for selecting date values 
 * (same as SelectFilter but with converted date values)
 */
export function SelectFilterForDates ({
  column: { Header,setFilter,preFilteredRows,id},headerGroups, defaultColumn}) {

  return SelectFilter({
    column: { Header,setFilter,preFilteredRows,id},headerGroups, defaultColumn}, "date")
}

/**
 * Summary: This is a custom filter UI for selecting
 * a unique user from a list
 * 
 * TODO: remove repeated code from here and SelectFilter
 * 
 */
export function SelectFilterForUsers ({
  column: { Header,setFilter,preFilteredRows,id}, headerGroups, defaultColumn}) {
  preFilteredRows = defaultColumn?.preFilterRows.length ? defaultColumn.preFilterRows : preFilteredRows || []

  const getSelection = function() {
    let selection = (existingSelection?.length) ? existingSelection.map(option => {
      return {value:option, label:option};
    }) : "";
    return selection;
  }

  //store selection in redux for persistence
  //needed for survey review single select config like in uat/egd
  const dispatch = useDispatch();
  let existingSelection = useSelector((state) => state.SurveyReviewReducer.surveyJobsUserSelections);

  const defaultFilters = getDefaultFilters(headerGroups[0].headers, id)

  useEffect(() => {
    if (defaultFilters) {
      selectFilterChangeCallback(id, defaultFilters);
    }
  }, []);
  
  // Calculate the options for filtering using the preFilteredRows
  const CreateOptions = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      let rowAttr;
      if(row?.attributes){
        rowAttr = Object.entries(row.attributes).reduce((attributes, [key, value]) => {
          attributes[key.toLowerCase()] = value
          return attributes
        }, {})
      } else if(row?.values){
        rowAttr = Object.entries(row.values).reduce((attributes, [key, value]) => {
          attributes[key.toLowerCase()] = value
          return attributes
        }, {})
      }

      let value = defaultColumn?.preFilterRows.length ? rowAttr[id?.toLowerCase()] : row.values[id]
      if (value?.length > 0) {
        value.forEach(surveyor => {
          options.add(surveyor.Attributes.UserID)
        })
      }
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  const selectFilterChangeCallback = function (id, e) {
    setFilter(e);
    dispatch({type: SET_SURVEY_REVIEW_USER_SELECTIONS, surveyJobsUserSelections: (e !== "") ? [e] : []});
    if(defaultColumn.filterChangeCB){
      defaultColumn.filterChangeCB(id, e);
    }
  };

  return (
    <span className="dropdown-container">
      <div className="dropdown-header">
        {Header}
      </div>
      <Dropdown
        isReset = {defaultColumn.isReset}
        items = {CreateOptions}
        onReset={() => selectFilterChangeCallback(id, defaultFilters || "")}
        defaultValue={defaultFilters || getSelection() || ""}
        resetToDefault={true}
        placeHolder={"All"}
        handleChange = {(e)=>selectFilterChangeCallback(id, e)}
      />
    </span>
  )
}

/**
*
* Summary: Filter algorithm for select dropdowns
* can be used for strings or numbers
*
* @param {Object} rows Data that is being filtered
* @param {number} id ID corresponding to row
* @param {any} filterValue Value used to filter rows
*
* @returns {Object} Data that is filtered and to be used in the new table
*/
export function filterSelectValue(rows,id,filterValue,type) {
  return rows.filter(row => {
    let rowValue = row.values[id]
    let filtered = String(rowValue).toLowerCase() === (String(filterValue).toLowerCase())
    return rowValue !== undefined
      ? filtered
      : true
  })
}

/**
*
* Summary: Filter algorithm for select dropdowns
* used for date values
*
* @param {Object} rows Data that is being filtered
* @param {number} id ID corresponding to row
* @param {any} filterValue Value used to filter rows
*
* @returns {Object} Data that is filtered and to be used in the new table
*/
export function filterSelectValueForDates(rows,id,filterValue) {
  return rows.filter(row => {
    const rowValue = row.values[id]
    const convertedRowValue = convertDate(rowValue, false, false, 'date').toString()
    let filtered = convertedRowValue === filterValue
    return convertedRowValue !== undefined
      ? filtered
      : true
  })
}

/**
 *
 * Summary: Filter algorithm for users select dropdowns
 *
 * @param {Object} rows Data that is being filtered
 * @param {number} id ID corresponding to row
 * @param {any} filterValue Value used to filter rows
 *
 * @returns {Object} Data that is filtered and to be used in the new table
 */
export function filterSelectValueForUsers(rows,id,filterValue) {
  return rows.filter(row => {
    let rowValue = row.values[id]
    if (rowValue.length > 0) { 
      const userIds = rowValue.map(surveyor => surveyor.Attributes.UserID)
      return userIds.includes(filterValue)
    } else {
      return false
    }
  })
}

/**
 *
 * Summary: Filter algorithm for select dropdowns
 * used for filtering x amount of days ahead
 *
 * @param {Object} rows Data that is being filtered
 * @param {number} id ID corresponding to row
 * @param {Object} filterValue Value used to filter rows
 * must have label and value property
 *
 * @returns {Object} Data that is filtered and to be used in the new table
 */
export function filterDateRangeValue(rows,id,filterValue) {
  let currentDate = new Date()
  let dayBeforeCurrentDate = sub(new Date(), {days: 1})
  let dateToCompare = add(currentDate,filterValue.addAmount)
  let filteredDates = []
  //Filter for everything before present date
  if (filterValue === -1) {
    for(let index = 0; index < rows.length; index++){
      let rowToBeFiltered = new Date(rows[index].original[id])
      if (isAfter(dayBeforeCurrentDate, rowToBeFiltered)) {
        filteredDates.push(rows[index])
      }
    }
  }

  // filter for everything over x amount of days
  else if (filterValue.over === true) {
    for(let index = 0; index < rows.length; index++){
      let rowToBeFiltered = new Date(rows[index].original[id])
      if (isAfter(rowToBeFiltered, dateToCompare)) {
        filteredDates.push(rows[index])
      }
    }
  }

  //filter for everything within x amount of days from current
  //dates before current date is filter out as well
  else {
    for(let index = 0; index < rows.length; index++){
      let rowToBeFiltered = new Date(rows[index].original[id])
      if (
        isAfter(dateToCompare, rowToBeFiltered) &&
        isAfter(rowToBeFiltered, dayBeforeCurrentDate)
      ) {
        filteredDates.push(rows[index])
      }
    }
  }
  return filteredDates
}

