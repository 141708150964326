import React, { useState, useImperativeHandle } from 'react';
import { useDispatch } from 'react-redux';
import './NotificationMessage.scss'
import CheckIcon from '@material-ui/icons/Check';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CloseIcon from '@material-ui/icons/Close';

//Determines notification colour
export enum notificationType {
  success = "success",
  failure = "failure"
}
interface INotificationMessageProp {
  children: React.ReactNode,
  boxColor: "success" | "failure",
  symbol?: "checkmark" | "exclamation",
  className?: string,
  initialShow?: boolean,
  clearStatusAction?: string
}

const NotificationMessage = React.forwardRef((props: INotificationMessageProp, ref: React.Ref<any>) => {
  const dispatch = useDispatch()
  const [className, setClassName] = useState("notification-message " + props.boxColor + props.className + (props.initialShow?"":" hidden"))

  useImperativeHandle(ref, () => ({
    // function to display the notification message then close it after a set time
    showNotif() {
      setClassName("notification-message " + props.boxColor)
      setTimeout(() => {
        setClassName("notification-message hidden " + props.boxColor)
        if (props.clearStatusAction) {
          dispatch({type: props.clearStatusAction})
        }       
      }, 3500);
    }
  }))

  function closeMessage() {
    setClassName("notification-message hidden " + props.boxColor)
  }

  let icon = null;
  if (props.symbol === "checkmark") {
    icon = <CheckIcon className="icon"></CheckIcon>
  } else if (props.symbol === "exclamation") {
    icon = <ErrorOutlineIcon className="icon"></ErrorOutlineIcon>
  }

  return(
    <div className = {className}>
      {icon}
      <span>{props.children}</span>
      <CloseIcon className="close-button" onClick={()=>closeMessage()}></CloseIcon>
    </div>
  )
})

export default NotificationMessage;
