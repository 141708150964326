//These are constants that are used app wide. other constants will be found in their respective files

/**
 * Lantern Admin tool names
 */
export enum LanternTools {
  MAP = 'Lantern::Map Tool',
  SURVEY_AREA = 'Lantern::Survey Area Tool',
  SURVEY_INITIATION = 'Lantern::Survey Initiation Tool',
  SURVEY_ASSIGNMENTS = 'Lantern::Survey Assignment Tool',
  QUEUE_PROCESSING = 'Lantern::Queue Processing Tool',
  WORKLOAD_MANAGEMENT = 'Lantern::Work Load Tool',
  SURVEY_REVIEW = 'Lantern::Survey Review Tool',
  SELECT = 'Lantern::Select Tool',
  SEARCH = 'Lantern::Search Tool',
  MEASURE = 'Lantern::Measure Tool',
  EDIT = 'Lantern::Edit Feature Tool',
  QUERY = 'Lantern::Query Tool',
  INFO = 'Lantern::Info Tool',

  //Currently these constants arent being used but are in admin
  GEOCODE = 'Lantern::Geocode Tool',
  PLOT = 'Lantern::Plot Tool',
  REPORTING = 'Lantern::Reporting Tool'
}
