import { HttpRequest } from "../httpRequest";
import { NEW_API_URL } from "api/constants"
import { encodeUriAll } from "utils/UrlUtils";
import axios from "axios";

export const getSurveyPlanCategoryConfigurations = async(mapServiceName: string, bySurveyArea : boolean) => {
    // const getSurveyPlanCategoryConfigurationsUrl = (`/v1/surveyplancategoryconfigurations/${mapServiceName}/surveyworktypeswithplancategories/${bySurveyArea}`);
    const getSurveyPlanCategoryConfigurationsUrl = (`/v1/surveyplancategoryvtwo/${mapServiceName}/surveyworktypeswithplancategories/${bySurveyArea}`);
    return await HttpRequest.get(NEW_API_URL, getSurveyPlanCategoryConfigurationsUrl);
};

export const getConfiguration = async(mapServiceName: string, surveyWorktype:string ) => {
    const getConfigurationsUrl = (`/v1/maps/configuration/${encodeUriAll(mapServiceName)}/${encodeUriAll(surveyWorktype)}`);
    return await HttpRequest.get(NEW_API_URL, getConfigurationsUrl);
};

export const getValidateCSLEntries = async (mapServiceName: string, layerId: string, tableAttributeName: string, entries: string[]) => {
    const getValidateCSLEntriesUrl = (`/v1/surveyvalidation/${mapServiceName}/validate/${layerId}/${tableAttributeName}`); 
    // console.log( axios.post(getValidateCSLEntriesUrl, entries))
    return await HttpRequest.post(NEW_API_URL, getValidateCSLEntriesUrl, entries);
};