import React from 'react';
import './GreyLine.scss'

interface IGreyLineProp {
  className?: string
}

const GreyLine = ({
  className
}:IGreyLineProp) => {
  return(
    <hr className={"grey-line " + className}/>
  )
}

export default GreyLine;
