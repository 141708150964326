import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { styles } from './../DropdownStyles';

interface IAsyncGridSelectProps {
  className?: string
  AsyncOptionCallBack:any
  handleChange:any
  showSelected:boolean
  isClearable?: boolean
  id?:string
  defaultValue?:any
  placeholder?:string
  noSearchMessage?:string
  theme?:'withIcon'
  isReset?:boolean
}


const AsyncGridSelect = (props:IAsyncGridSelectProps)=> {
  const [value, setValue] = useState<any>(null);
  // styles to add to customStyles
  let addedStyles: any = {};
  // all possible styles and their specific triggers
  const styleOptions = {
    withIconStyle: props?.theme === 'withIcon'
  };
  // append stylings of a styleOption if styleTrigger === true
  Object.entries(styleOptions).forEach(([styleOption, styleTrigger]: [string, boolean]) => {
    if (styleTrigger) {
      Object.entries(styles[styleOption]).forEach(([styleObject, styleValue]: [string, any]) => {
        addedStyles[styleObject] = {...addedStyles[styleObject], ...styleValue}
      })
    }
  })



  // Styling and using material ui's api
  var customStyles = {
    container: (base: any) => ({
      ...base,
      'height':'100%',
      ...addedStyles.container
    }),
    control:(base:Object)=>({
      ...base,
      'borderRadius': '0',
      'boxShadow': '0',
      'padding':'2px 4px',
      'height':'100%',
      ...addedStyles?.control
    }),
    singleValue: (base: any) => ({
      ...base,
      ...addedStyles?.singleValue
    }),
    input: (base: any) => ({
      ...base,
      ...addedStyles?.input
    }),
    menu: (base: any) => ({
      ...base,
      ...addedStyles?.menu
    }),
    option: (styles : Object, {isFocused} : any ) => {
      return {
        ...styles,
        background: isFocused ? '#0088c1' : 'white',
        color: isFocused? 'white':'black',
      };
    },
    dropdownIndicator: (base: any) => ({
      ...base,
      ...addedStyles?.dropdownIndicator
    }),
    indicatorSeparator: () => ({
      display:'none',
      ...addedStyles?.indicatorSeparator
    })
  };
  
  useEffect(() => {
    if(props.isReset){
      setValue([])
    }
  },[props.isReset]);

  const getValue = () => {
    let originalValue = props.showSelected? (value || props.defaultValue):'';
    return originalValue
  }

  return (
    <AsyncSelect
      className = {props.className}
      id={props.id}
      defaultValue={props.defaultValue}
      value={getValue()}
      isClearable = {props.isClearable}
      onChange={(value:any)=>{
        setValue(value)
        props.handleChange(value)}}
      loadOptions={props.AsyncOptionCallBack}
      placeholder={props.placeholder}
      noOptionsMessage={(data)=>
        data.inputValue?'Cannot find Results':(props.noSearchMessage??'')
      }
      styles={customStyles}
    />
  );
}

AsyncGridSelect.defaultProps = {
  showSelected: true
}

export default AsyncGridSelect
