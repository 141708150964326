import React from 'react';
import './RowButton.scss';
import RightArrow from '@material-ui/icons/ArrowRight';

interface IRowButtonProps {
  className?: string
  children?: any
  id?:string
  onClick?: () => void
}

const RowButton = ({
  className,
  children,
  id,
  onClick
}:IRowButtonProps) => {
  return (
  <button
    {...{onClick}}
    className = {`box-row ${className}`}
    id = {id}
  >
    {children}
    <RightArrow className = "icon"/>
  </button>
  )
}

export default RowButton
