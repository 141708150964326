import React from 'react';
import '../../Map.scss';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as QueryDBIcon } from 'assets/QueryDB.svg';
import { widgetTypes, IWidgetProps } from '../../MapWidgetLayer';
import {
  mapPageMapType,
  SHOW_SIDE_PANEL,
  CLOSE_SIDE_PANEL,
  SET_ACTIVE_TOOL,
  SET_ACTIVE_SLIDEOUT_TOOL
} from '../../MapDucks';
import { LanternTools } from 'app/constants';
import { isToolAvailable } from 'utils/IdentityManagerUtils';
import { getReducer } from 'utils/ReduxUtils';

export const QueryWidget = ({
  currentTool,
  isSidePanelVisible,
  sketchWidget,
  unselectWidget,
  measureWidget,
  className,
  mapType,
 toggleSidePanel
}:IWidgetProps) => {
  let dispatch = useDispatch()
  const roleId = useSelector((state:any) => state.LoginReducer.selectedUserRole)
  const currentReducer = useSelector((state:any) => state[getReducer(mapType)])

  function handleClick() {
    toggleSidePanel(true);
    if (currentTool === widgetTypes.query) {
      if (currentReducer.slideOutInfo.reopenOverview && mapType === mapPageMapType) {
        dispatch({type:SET_ACTIVE_TOOL + mapType, tool: widgetTypes.overview})
        dispatch({type:SET_ACTIVE_SLIDEOUT_TOOL + mapType, tool: widgetTypes.overview})
      }
      else {
        toggleSidePanel(false);
        dispatch({type:SET_ACTIVE_TOOL + mapType, tool: widgetTypes.move})
        dispatch({type:CLOSE_SIDE_PANEL + mapType})
      }
    }
    else {
      sketchWidget?.cancel()
      unselectWidget?.cancel()
      measureWidget?.clear()
      dispatch({type:SET_ACTIVE_TOOL + mapType, tool: widgetTypes.query})
      dispatch({type:SET_ACTIVE_SLIDEOUT_TOOL + mapType, tool: widgetTypes.query})
      dispatch({type:SHOW_SIDE_PANEL + mapType })
    }
  }

  if (isToolAvailable(LanternTools.QUERY,roleId)){
    return (
      <button
        title="Query DB"
        id="queryDB-btn"
        className={"widget-btn" + (currentTool===widgetTypes.query && isSidePanelVisible?" btn-active ":" ") + className}
        onClick={handleClick}
      >
        <QueryDBIcon/>
      </button>
    )
  }
  else {
    return null
  }
}
