import { createStore, applyMiddleware,compose} from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './rootReducer';

/**
 * Note read https://github.com/zalmoxisus/redux-devtools-extension
 * to use redux dev tools
 */

export const store = configureStore()

export default function configureStore() {
  // TODO: enable Redux trace later, for some reason causing an issue on web03
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  // const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true }) || compose;
  return createStore(
    rootReducer,
    composeEnhancers(
    applyMiddleware(thunk)
  ));
}
