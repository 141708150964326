import React from 'react';
import { inputType } from 'utils/FieldsUtils';
import './TextInput.scss'

interface ITextInputProp {
  onChange?: (...args: any[]) => void
  className?: string
  isDisabled?: boolean
  value?: string
  defaultValue?: string
  placeholder?: string
  maxLength?: number
  type?: string
}

const TextInput = ({
  onChange = () => {},
  className,
  isDisabled,
  maxLength,
  type,
  ...rest
}:ITextInputProp) => {

  if (type === inputType.number) {
    //No maxLength property for number inputs so instead just prevent in onChange
    //(max does not affect typing)
    return (
      <input
        onChange = {(e) => {
          if (maxLength && e.target.value.length > maxLength) {
            e.target.value = e.target.value.slice(0, maxLength)
          }
          onChange(e)
        }}
        className = {"text-input " +  className}
        disabled = {isDisabled}
        max = {maxLength? parseInt('9'.repeat(maxLength)) : undefined}
        type = {type}
        {...rest}
      />
    )
  }
  return (
    <input
      onChange = {(e) => onChange(e)}
      className = {"text-input " +  className}
      disabled = {isDisabled}
      maxLength = {maxLength}
      type = {type}
      {...rest}
    />
  )
}

export default TextInput;
