import { store } from 'app/store';
import { loadModules } from 'esri-loader';
import { loadEsriModule } from 'utils/EsriUtils';
import { SET_OVERVIEW_EXTENT } from 'features/MapPage/MapPageDucks';
import { reRegisterToken } from 'utils/IdentityManagerUtils';
import { surveyReviewMapType, mapPageMapType } from '../MapDucks';

/**
 * Summary: get the filtered selectedGeometries for Overview toggles
 * @param selectedGeometries currently selected geometries of a single geometry type
 * @returns array of Overview toggled features that are currently selected
 */
export const getSelectedToggles = (selectedGeometries: Array<any>) => {
  let layers = store.getState().SettingsReducer.settings.Layers
  let overviewTogglesStore = store.getState().MapPageReducer.reportedIssuesDetails.store

  return selectedGeometries.filter((selectedGeometry: any) => {
    let layerName = selectedGeometry.featureLayerName || layers[selectedGeometry.sourceLayer.layerId].Name

    return (overviewTogglesStore[layerName]?.hasOwnProperty(selectedGeometry.attributes.OBJECTID))
  })
}

/**
 * Summary: get the newly selected geometry
 * @param selectedGeometries currently selected geometries of a single geometry type
 * @param prevGeometries previously selected geometries of a single geometry type
 * @returns array of Overview features that just recently got selected by a Tool
 */
export const getNewGeometry = (selectedGeometries: Array<any>, prevGeometries: Array<any> | undefined) => {
  if (prevGeometries) {
    return selectedGeometries.filter((geometry: any) => {
      return !prevGeometries.includes(geometry)
    })
  } else {
    return selectedGeometries
  }
}

/**
 * Summary: create an extent for features selected by a toggle
 * @param selectedGrids currently selected features of grid geometry
 * @param selectedLines currently selected features of line geometry
 * @param selectedPoints currently selected features of point geometry
 * @param view ArcGIS map view
 */
 export const createToggleSelectionExtent = (
  selectedGrids: Array<any>,
  selectedLines: Array<any>,
  selectedPoints: Array<any>,
  view: any) =>
{
  const toggleGrids = getSelectedToggles(selectedGrids)
  const toggleLines = getSelectedToggles(selectedLines)
  const togglePoints = getSelectedToggles(selectedPoints)

  if ((toggleGrids.length + toggleLines.length + togglePoints.length) > 0) {
    createReviewExtent(toggleGrids, toggleLines, togglePoints, view)
  }
}

/**
 * Summary: create an extent for features selected by a Tool
 * @param selectedGrids currently selected features of grid geometry
 * @param selectedLines currently selected features of line geometry
 * @param selectedPoints currently selected features of point geometry
 * @param prevGrids previously selected features of grid geometry
 * @param prevLines previously selected features of line geometry
 * @param prevPoints previously selected features of point geometry
 * @param view ArcGIS map view
 */
export const createToolSelectionExtent = (
  selectedGrids: Array<any>,
  selectedLines: Array<any>,
  selectedPoints: Array<any>,
  prevGrids: Array<any> | undefined,
  prevLines: Array<any> | undefined,
  prevPoints: Array<any> | undefined,
  view: any) =>
{
  const newGrids = getNewGeometry(selectedGrids, prevGrids)
  const newLines = getNewGeometry(selectedLines, prevLines)
  const newPoints = getNewGeometry(selectedPoints, prevPoints)

  if ((newGrids.length + newLines.length + newPoints.length) > 0) {
    createReviewExtent(newGrids, newLines, newPoints, view)
  }
}

/**
 * Summary: create an extent viewing all the Overview toggles features
 * @param selectedGrids currently selected features of grid geometry
 * @param selectedLines currently selected features of line geometry
 * @param selectedPoints currently selected features of point geometry
 * @param prevGrids previously selected features of grid geometry
 * @param prevLines previously selected features of line geometry
 * @param prevPoints previously selected features of point geometry
 * @param view ArcGIS map view
 */
export const createOverviewExtent = (
  selectedGrids: Array<any>,
  selectedLines: Array<any>,
  selectedPoints: Array<any>,
  prevGrids: Array<any> | undefined,
  prevLines: Array<any> | undefined,
  prevPoints: Array<any> | undefined,
  view: any
) => async(dispatch: any) => {
  // flag for Overview page toggle selection
  const overviewExtent = store.getState().MapPageReducer.overviewExtent

  if (overviewExtent) {
    createToggleSelectionExtent(
      selectedGrids,
      selectedLines,
      selectedPoints,
      view
    )

    dispatch({
      type: SET_OVERVIEW_EXTENT,
      extent: false
    })
  } else {
    createToolSelectionExtent(
      selectedGrids,
      selectedLines,
      selectedPoints,
      prevGrids,
      prevLines,
      prevPoints,
      view
    )
  }
}

/**
 * Summary:Create an extent viewing all the selected features
 * except for the default set grid (for survey review) and moves the view to
 * the defined extent
 *
 * @return null
 */
export function createReviewExtent(
  selectedGrids:Array<any>,
  selectedLines:Array<any>,
  selectedPoints:Array<any>,
  view:any,
  mapType?: any)
{
  loadEsriModule('esri/geometry/geometryEngine').then((geometryEngine:any) => {
    let selectedReviewGrids = selectedGrids.filter((grid:any) => !grid.defaultGrid)
    let selectedLineGeometries: Array<any> = []
    let selectedPointGeometries: Array<any> = []
    let selectedGeometry:Array<any> = []

    //Convert lines and points to polygons using buffers
    selectedLines.forEach((line:any) => {
      selectedLineGeometries.push(geometryEngine.geodesicBuffer(line.geometry, [2], "meters", true))
    })
    selectedPoints.forEach((point:any) => {
      selectedPointGeometries.push(geometryEngine.geodesicBuffer(point.geometry, [2], "meters", true))
    })

    selectedReviewGrids.forEach((polygon:any) => {
      selectedGeometry.push(polygon.geometry)
    })
    selectedGeometry = selectedGeometry.concat(selectedLineGeometries, selectedPointGeometries)

    let geometry = selectedGeometry[0]
    if (selectedGeometry.length > 1) {
      geometry = geometryEngine.union(selectedGeometry)
    }

    createExtent([{geometry: geometry}]).then((newExtent:any) => {
      mapType === (surveyReviewMapType || mapPageMapType ) ? view.goTo(newExtent.expand(3)) : view.goTo(newExtent.expand(1.5))
    })
  })
}

/**
   * Create an extent from the last grid in grids
   * @param grids selected grids to create an extent from
*/
export function createExtent(grids:any) {
  return new Promise((resolve) => {
    reRegisterToken()
    loadModules(['esri/geometry/Extent']).then(([Extent]) => {
      // initialize extent properties
      let rings = grids[grids.length - 1].geometry.rings[0]
      let xmin = rings[0][0] || 0
      let xmax = rings[0][0] || 0
      let ymin = rings[0][1] || 0
      let ymax = rings[0][1] || 0
      // calculate the actual values
      grids[grids.length - 1].geometry.rings.forEach((ring:any) => {
        ring.forEach((coordinates:any) => {
          if (coordinates[0] < xmin) {
            xmin = coordinates[0]
          } else if (coordinates[0] > xmax) {
            xmax = coordinates[0]
          }
          if (coordinates[1] < ymin) {
            ymin = coordinates[1]
          } else if (coordinates[1] > ymax) {
            ymax = coordinates[1]
          }
        })
      })
      // create extent and go to an expanded version of it
      let newExtent = new Extent({
        xmin: xmin,
        ymin: ymin,
        xmax: xmax,
        ymax: ymax,
        spatialReference: grids[0].geometry.spatialReference
      })
      resolve(newExtent)
    })
  })
}
