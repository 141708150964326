import styled from 'styled-components';
import { SvgIcon } from '@material-ui/core';

interface IContainerProps {
    width: string;
    height: string;
    className?: string;
}
  

export const Container = styled.div<IContainerProps>`
    display: flex;
    flex-direction: column;
    width:  ${props => `${props.width}%`};
    height: ${props => `${props.height}`};
    transition: 0.5s;
    background: #fff;
    box-shadow: 1px 8px 20px -3px grey;
    z-index: 2;
    overflow-y: auto;
    overflow-x: hidden;
    position: fixed;
    right: 0px;
}
`;

export const Title = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    font-size: 24px;
    font-weight: 600;
    align-items: center;

    & > svg {
        margin: 0 10px;
        vertical-align: middle;
    }

    .cancel-button {
        float: right;
        font-size: 30px;
        color: #e4e5e6;
        cursor: pointer;
      }
    .edit-button {
        cursor: pointer;
    }
`;

export const StyledTitleContainer = styled.div`
      display: flex;
      align-items: center;
      flex: 1;

      & > p {
          margin: 0 10px;
      }
`;
export const StyledSpan = styled.span`
    padding-left: 10px;
    padding-right: 2px;
    padding-top: 2px;
`;

export const StyledActionsContainer = styled.div`
      flex: 1;
      display: flex;
      justify-content: flex-end;
`;

export const PanelHeader = styled.header`
    display: flex;
    padding: 15px 25px;
    width: 100%;
    height: 120px;
`;

export const PanelBody = styled.div`
    
    flex-direction: column;

`;

