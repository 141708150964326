import React, { useEffect } from 'react';
import { renderActionBar } from './renderActionBar';
import Button from 'common/Button/Button';
import FilterButton from 'common/Filter/Filterbtn';
import { usePrevious } from 'utils/HooksUtils';
import { isUndefined } from 'lodash';

export const FilterComponent = ({
  data,
  selectedRowIds,
  filterBtnLabel,
  renderFilterBtn,
  getTableBodyProps,
  actionBar,
  headerGroups,
  filterListOrdered,
  setisReset,
  setAllFilters,
  toggleAllRowsSelected,
  defaultFilterBtnToggleOverride = false,
  enableRemoteOperations = false,
  resetCallback = () => {}
}:any)=> {

  const prevSurveyWorkType = usePrevious(actionBar?.props?.surveyWorkType);
  const reset = () => {
    setisReset(true)
    setAllFilters([])
    //reset callback
    enableRemoteOperations && resetCallback && resetCallback();
  }

  useEffect(() => {
    if(!isUndefined(prevSurveyWorkType)){
      reset()
    }
  }, [actionBar?.props?.surveyWorkType])
  
  //button filter component
  return (
    <div {...getTableBodyProps()} className="filterbtn-container">
      <FilterButton
        label={filterBtnLabel}
        renderFilterBtn={renderFilterBtn}
        actionBar = {renderActionBar(actionBar,data,selectedRowIds,toggleAllRowsSelected)}
        overrideDefaultToggle = {defaultFilterBtnToggleOverride}
      >
      <div>
        {headerGroups.map((headerGroup:any) => (
          <span
            className="filterlist-container"
            {...headerGroup.getHeaderGroupProps()}
          >
            {filterListOrdered.map((column:any) => {
              if(column.canFilter) {
                return (
                <span
                  {...column.getHeaderProps()}
                >
                  {/* Render the columns filter UI */}
                  <div
                    //TODO: try classnames library again to avoid the use of ternary operators
                    className={
                      "filter-select-container " +
                      (column.lgFilterLength?"lg":"")
                    }
                  >
                    {column.render('Filter')}
                  </div>
                </span>
                )
              }
              return null
            })}
          <span className="btn-container">
            <Button
              boxColor="grey-border-dark-text"
              size="l"
              className="reset-btn"
              onClick={()=>reset()}
            >
              Reset
            </Button>
          </span>
        </span>
        ))}
      </div>
      </FilterButton>
    </div>
  )
}
