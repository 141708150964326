import { PublicClientApplication, InteractionRequiredAuthError } from '@azure/msal-browser';
import { authConfig } from 'index';


export async function refreshToken() {
  
  const msalInstance = new PublicClientApplication(authConfig.msalConfig);

    try {
      const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
      const accounts = msalInstance.getAllAccounts();
  
      if (!activeAccount && accounts.length === 0) {
        /*
         * User is not signed in. Throw error or wait for user to login.
         * Do not attempt to log a user in outside of the context of MsalProvider
         */
        await msalInstance.logoutRedirect({
          onRedirectNavigate: () => {
            // Return false to stop navigation after local logout
            return false;
          },
        });
        // throw new Error('Not Authenticated');
      }
      const request = {
        scopes: authConfig.loginRequest.scopes,
        account: activeAccount || accounts[0],
      };
  
      // Retrieve an access token
      const response = await msalInstance.acquireTokenSilent(request).catch(error => {
        if (error instanceof InteractionRequiredAuthError) {
          // fallback to interaction when silent call fails
          return msalInstance.logoutRedirect({
            onRedirectNavigate: () => {
              // Return false to stop navigation after local logout
              return false;
            },
          });
        }
      });
      const accessToken = response && response.accessToken;
  
      localStorage.setItem('token', accessToken as string);
      return accessToken;
    } catch (error) {
      console.log(error);
    }
  }