/**
 * Page change action object in the regular table component
 */
export interface TablePageChangeProps {
    action: string,
    pageNumber?: number,
    pageCount?: number
}

/**
 * List of all page actions in the regular table component
 */

export enum TablePageChangeActions {
    FirstPage = "firstPage",
    LastPage = "lastPage",
    PrevPage = "prevPage",
    NextPage = "nextPage",
    SpecificPage = "specificPage"
};

/**
 * List of all sort actions in the regular table component
 */

export enum TableSortActions {
    ASCENDING = "ASC",
    DESCENDIG = "DESC",
    CLEAR = "CLEAR"
};