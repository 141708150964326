import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import './SearchWidget.scss';
import { loadModules } from 'esri-loader';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { LanternTools } from 'app/constants';
import Dropdown from 'common/Dropdown/Dropdown';
import SearchBar from 'common/SearchBar/SearchBar';
import { selectFeatures, selectionType, selectOperations } from 'common/Map/SelectFeatures';
import { layerSearchSubmit } from './searchUtils/layerSearch';
import { tableSearchSubmit } from './searchUtils/tableSearch';
import { widgetTypes } from '../../MapWidgetLayer';
import {
  mapPageMapType,
  searchTypes,
  surveyAreaMapType,
  surveyAssignmentMapType,
  surveyInitiationMapType,
  surveyReviewMapType,
  RESET_TO_SURVEY_REVIEW_GRIDS,
  SET_GEOMETRY,
  SET_ACTIVE_TOOL,
  CLEAR_ALL_SELECTED_GEOMETRY,
  SET_ACTIVE_SLIDEOUT_TOOL
} from '../../MapDucks';
import { SQLConcatOperators } from '../QueryWidget/QuerySlideoutBody';
import { getLayerOptions, getFieldOptions, getTableOptions } from 'utils/GridUtils';
import { isToolAvailable, reRegisterToken } from 'utils/IdentityManagerUtils';
import { pages } from 'utils/UrlUtils';

interface ISearchWidgetProps {
  currentTool: widgetTypes
  activeSlideoutTool: widgetTypes
  isSlideoutOpen: boolean
  sketchWidget: any
  unselectWidget: any
  mapType: string
  selectedGrids:Array<any>
  selectedLines: Array<any>
  selectedPoints: Array<any>
  searchResults: Array<any>
  view?:any
  map?:any
  measureWidget?: any
  coordinateWidget?: any
  editWidget?: any
  checkCoordinateMode?: any
  className?: string
  layers?: any
}


export const SearchWidget = ({
  currentTool,
  activeSlideoutTool,
  isSlideoutOpen,
  sketchWidget,
  unselectWidget,
  view,
  mapType,
  className,
  map,
  selectedGrids,
  selectedLines,
  selectedPoints,
  searchResults
}:ISearchWidgetProps) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const [searchType, setSearchType] =
    useState<searchTypes>(searchTypes.addressSearch)
  //For setting both layers and tables
  const [selectedLayer, setSelectedLayer] = useState<any>('All')
  const [selectedField, setSelectedField] = useState<any>('All')
  const [layerReset, setLayerReset] = useState<boolean>(false)
  const [fieldReset, setFieldReset] = useState<boolean>(false)
  const [searchBarReset, setSearchBarReset] = useState<boolean>(false)
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
  const roleId = useSelector((state:any) => state.LoginReducer.selectedUserRole)
  const [searchTypeChanged, setSearchTypeChanged] = useState<boolean>(false)
  let searchWidgetRef = useRef(null)
  let prevActiveSlideoutTool = useRef().current 

  // store previous value of activeSlideoutTool
  const usePrevious = (value: any) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }
  prevActiveSlideoutTool = usePrevious(activeSlideoutTool)
  
  function resetSearch() {
    setSelectedLayer('All')
    setSelectedField('All')
    setLayerReset(true)
    setFieldReset(true)
    setSearchBarReset(true)
    setSearchTypeChanged(false)
  }

  function handleClick() {
    setSearchTypeChanged(false);
    if (searchWidgetRef.current){
      (searchWidgetRef.current as any).clear();
    }
    if (currentTool === widgetTypes.search) {
      setSearchType(searchTypes.addressSearch)
      dispatch({type: SET_ACTIVE_TOOL + mapType, tool: widgetTypes.move})
    }
    else {
      sketchWidget?.cancel()
      unselectWidget?.cancel()
      dispatch({type: SET_ACTIVE_TOOL + mapType, tool: widgetTypes.search})
    }
  }

  // run when activeSlideoutTool changes in value, reset search widget to address search 
  useEffect(() => {
    if (!isSubmitted && prevActiveSlideoutTool !== activeSlideoutTool && activeSlideoutTool === "overview") {
      setSearchType(searchTypes.addressSearch)
      // if user has not changed search type then dispatch so it closes the search widget
      if (!searchTypeChanged) dispatch({type: SET_ACTIVE_TOOL + mapType, tool: widgetTypes.move})
    }
  }, [activeSlideoutTool])

  useEffect(() => {
    if (mapType !== surveyAssignmentMapType) {
      //On survey initiation pages that are not new survey initiations, do not clear selected grids/points
      if (
        (mapType === surveyInitiationMapType ||
          mapType === surveyAreaMapType) &&
        location.search === ""
      ) {
        if (selectedGrids) {
          dispatch({type:SET_GEOMETRY + mapType, grids: []})
        }
        if (selectedLines) {
          dispatch({type:SET_GEOMETRY + mapType, lines: []})
        }
        if (selectedPoints) {
          dispatch({type:SET_GEOMETRY + mapType, points: []})
        }
      }
      if (mapType === surveyReviewMapType) {
        dispatch({type:RESET_TO_SURVEY_REVIEW_GRIDS})
      }
      if (map.layers.items[0]) {
        const maxSearchLength = 200
        //Break apart search results larger than maxSearchLength
        for (let i = 0, j = searchResults.length; i < j; i += maxSearchLength) {
          let searchDict: {[index: string]:any} = {}
          let resultArray = searchResults.slice(i, i + maxSearchLength)
          resultArray.forEach((result:any) => {
            if (result.layerName) {
              if (result.layerName in searchDict) {
                searchDict[result.layerName] += ` ${SQLConcatOperators.orOperator} OBJECTID = ` + result.attributes.OBJECTID
              }
              else {
                searchDict[result.layerName] = 'OBJECTID = ' + result.attributes.OBJECTID
              }
            }
          })
          for (let layer in searchDict) {
            if (searchDict[layer]) {
              selectFeatures(
                map.layers,
                mapType,
                layer,
                dispatch,
                null,
                selectOperations.add,
                false,
                selectionType.search,
                searchDict[layer]
              )
            }
          }
        }
      }
    }
  }, [searchResults])

  useEffect(() => {
    if (currentTool !== widgetTypes.search) {
      if (!isSubmitted) {
        resetSearch()
      }
    }

    if (isSubmitted) {
      setIsSubmitted(false)
    }
  },[currentTool])

  useEffect(() => {
    setLayerReset(false)
  },[layerReset])

  useEffect(() => {
    setFieldReset(false)
  },[fieldReset])

  useEffect(() => {
    setSearchBarReset(false)
  }, [searchBarReset])

  useEffect(() => {
    resetSearch()
    reRegisterToken()
    loadModules(['esri/widgets/Search'])
    .then(([Search]:any) => {
      let searchWidget = new Search({
        view: view,
      },'esri-search-container');
      const suggestionsMenu = document.getElementById("esri-search-container-suggest-menu")
      searchWidget.on("search-focus", () => {
        if(suggestionsMenu) {
          suggestionsMenu.style.display = "block";
        }
        const text = document.getElementsByClassName("esri-search__no-value-text")[0] as HTMLElement;
        if (text) text.innerText = "Please enter an address or a place";
      });
      searchWidget.on("search-complete", () => {
        if(suggestionsMenu) {
          suggestionsMenu.style.display = "none";
        }
      });
      searchWidgetRef.current = searchWidget;
    });
  },[searchType])

  useEffect(() => {
    const currentLocation = document.getElementsByClassName("esri-menu__list esri-search__suggestions-list esri-search__suggestions-list--current-location")[0] as HTMLInputElement;
    currentLocation?.addEventListener("click", currentLocationClickHandler);
  })

  /**
   * Summary: Search Type Dropdown on change handler
   * @param event on change event
   */

   function currentLocationClickHandler() {
    navigator.geolocation.getCurrentPosition(
      () => {},
      () => {
        setTimeout(() => {
          const errorBody = document.getElementsByClassName("esri-menu esri-search__warning-menu")[0] as HTMLElement;
          errorBody.innerHTML = "<div class=\"esri-search__warning-body\"><div><span aria-hidden=\"true\" class=\"esri-icon-notice-triangle\"></span><span class=\"esri-search__no-value-text\">Could not retrieve current location. Please check the location setting on your browser.</span></div></div>";
        }, 25)
      }
    );
  }

  const searchTypeChangeHandler = (event: any) => {
    setSearchTypeChanged(true);
    if (searchType !== event && event === searchTypes.addressSearch && activeSlideoutTool === widgetTypes.info) {
      dispatch({
        type: SET_ACTIVE_SLIDEOUT_TOOL + mapType,
        tool: widgetTypes.overview
      })
      if (location.pathname.includes(pages.surveyReviewJobDetails)) return;
      dispatch({type: CLEAR_ALL_SELECTED_GEOMETRY + mapType})
    }

    setSearchType(event)
  }

  //Get elements for different search types
  function getSearchElements() {
    if (searchType === searchTypes.addressSearch) {
      return (
        <div className='map-search-container'>
          <CloseIcon
            className = 'close-btn'
            onClick = {handleClick}
          />
          <div id="esri-search-container"/>
        </div>
      )
    }
    else if (searchType === searchTypes.layerSearch){
      return (
        <div className='map-search-container'>
          <CloseIcon
            className = 'close-btn'
            onClick = {handleClick}
          />
          <Dropdown
            items={['All'].concat(getLayerOptions())}
            defaultValue={'All'}
            forceValue={'All'}
            isReset={layerReset}
            handleChange={(e) => {
              setSelectedLayer(e)
              setSelectedField('All')
              setFieldReset(true)
              setSearchBarReset(true)
            }}
            className='map-search-options'
            allowOptionsTextWrap={true}
          />
          <Dropdown
            items={selectedLayer === 'All'?['All']:['All'].concat(getFieldOptions(selectedLayer))}
            defaultValue={'All'}
            forceValue={'All'}
            isReset={fieldReset}
            handleChange={(e) => {
              setSelectedField(e)
              setSearchBarReset(true)
            }}
            className='map-search-options'
            allowOptionsTextWrap={true}
          />
          <SearchBar
            handleSubmit={(e) => {
              setIsSubmitted(true)
              layerSearchSubmit(
                e,
                selectedLayer,
                selectedField,
                mapType,
                dispatch
              )
            }}
            placeHolder="Search"
            isReset={searchBarReset}
          />
        </div>
      )
    }
    else {
      return (
        <div className='map-search-container'>
          <CloseIcon
            className = 'close-btn'
            onClick = {handleClick}
          />
          <Dropdown
            items={['All'].concat(getTableOptions())}
            defaultValue={'All'}
            forceValue={'All'}
            isReset={layerReset}
            handleChange={(e) => {
              setSelectedLayer(e)
              setSelectedField('All')
              setFieldReset(true)
              setSearchBarReset(true)
            }}
            className='map-search-options'
            allowOptionsTextWrap={true}
          />
          <Dropdown
            items={selectedLayer === 'All'?['All']:['All'].concat(getFieldOptions(selectedLayer))}
            defaultValue={'All'}
            forceValue={'All'}
            isReset={fieldReset}
            handleChange={(e) => {
              setSelectedField(e)
              setSearchBarReset(true)
            }}
            className='map-search-options'
            allowOptionsTextWrap={true}
          />
          <SearchBar
            handleSubmit={(e) => {
              setIsSubmitted(true)
              tableSearchSubmit(
                e,
                selectedLayer,
                selectedField,
                mapType,
                dispatch
              )
            }}
            placeHolder="Search"
            isReset={searchBarReset}
          />
        </div>
      )
    }
  }

  if(isToolAvailable(LanternTools.SEARCH, roleId)) {
    return (
      <div id="search-container">
        <button
          title="Search"
          id="search-btn"
          className={"widget-btn" + (currentTool===widgetTypes.search?" btn-active":"") + className}
          onClick={handleClick}
        >
          <SearchIcon/>
        </button>
        {<div
          className=
          {`search-body `+
          `${(currentTool === widgetTypes.search)? '':'hidden'}`}
        >
          <span className = 'search-title'>
            Search Type
          </span>
          <div className='search-containers'>
            <div className='search-mode'>
              <Dropdown
                items={[
                  {label: searchTypes.addressSearch, value: searchTypes.addressSearch},
                  {label: searchTypes.layerSearch, value: searchTypes.layerSearch},
                  {label: searchTypes.tableSearch, value: searchTypes.tableSearch},
                ]}
                defaultValue={{label: searchTypes.addressSearch, value: searchTypes.addressSearch}}
                handleChange={(e) => searchTypeChangeHandler(e)}
                isReset={searchType === searchTypes.addressSearch}
                resetValue={true}
              />
            </div>
            {getSearchElements()}
          </div>
        </div>}
      </div>
    )
  }
  else {
    return null
  }
}
