import { store } from "app/store"
import { isNull, isUndefined, set } from "lodash"
import { convertDate } from "utils/DateUtils"
import {
  getTableIndexByName,
  getGridIdFieldByName,
  getGridLayerFieldValue,
  getLayerMapServiceIdByName,
  getMapServiceIdByPolygonType,
  getNameFieldByPolygonType,
  getGridLayerFieldByPolygonType,
  PolygonTypes, 
  gridLayerFields,
  getLayerOrTableByName
} from "utils/GridUtils"
import { escapeSpecialChars, fieldType, generateID, searchMatchType, whereQueryFieldTypeConversion } from "utils/FieldsUtils"
import { getNameMapping, mapAttribute } from "utils/NameMappingUtils"
import { getPromiseOptions } from "api/ArcGIS"
import { encodeUriAll } from "utils/UrlUtils"

/**
 * summary: generate the data for posting a new survey plan in the correct format
 *
 * @param data all the information for the survey plan to be stored
 * @param surveyPlanID the id of the survey plan that will be initiated
 */
export const populateInitiatePostPayload = (data:any, surveyPlanID:any) => {
  // one job is created for each polygon

  // "polygons" is different for each polygon type: (and jobs are created a bit differently in each case) 
  //   - grids: Object[] - info about each grid, including fields
  //   - corrosion areas or pipeline ids: string[] - the name of each value
  //   - List: [""] (since one job is created for this form, where the polygon name is the plan name)
  const polygons = data[0].polygons
  const polygonType:PolygonTypes|"List"|null = data[0].polygonType

  // what to set the job's polygon type field to (if different from what we get from the polygon type form field)
  let polygonTypeToSet: string|null = null
  if (polygonType === PolygonTypes.corrosionArea || polygonType === PolygonTypes.pipelineId) {
    // set to the name of the grid layer that has the given polygon type (there should only be one such layer)
    polygonTypeToSet = getNameFieldByPolygonType(polygonType)
  } else if (polygonType === "List") {
    polygonTypeToSet = "List"
  }

  // data entered in the survey initiation form
  const assetCSVFileLocation = data[1]
  const details = data[2]
  const surveyPlanName = details.SurveyPlanName
  const createdOn = convertDate(details.CreatedOn, false, true, 'iso') || ''
  const modifiedOn = convertDate(details.ModifiedOn, false, true, 'iso') || ''

  let gridLayers = []
  let gridNameFieldName = ''
  let surveyPlanTableID = 0
  let surveyJobTableID = 0

  gridLayers = store.getState().SettingsReducer.layers.GridLayers
  // get grid name field name
  gridLayers.forEach((type:any) => {
    if (details.PolygonType === type.Name) {
      gridNameFieldName = type.GridNameFieldName
    }
  })

  surveyPlanTableID = getTableIndexByName(
    store.getState().SettingsReducer.globalAliases.LanternSurveyPlan)

  surveyJobTableID = getTableIndexByName(
    store.getState().SettingsReducer.globalAliases.LanternSurveyJob)

  const surveyPlanFields = store.getState().SettingsReducer.settings.Tables[surveyPlanTableID].Fields
  const surveyJobFields = store.getState().SettingsReducer.settings.Tables[surveyJobTableID].Fields

  const nonDynamicFields = ["SurveyPlanID", "CreatedOn", "ModifiedOn"]
  const nonDynamicJobFields = ["PolygonName", "PolygonID", "SurveyJobID", "SurveyJobName"]

  // get job information for each selected polygon (either grids, corrosion areas or pipeline ids)
  let jobInfo = ``

  polygons?.forEach((polygon:any) => {
    let jobAttributes = ``
    surveyJobFields.forEach((field:any) => {
      if (!nonDynamicFields.includes(field.Name) && !nonDynamicJobFields.includes(field.Name) &&
        !(field.Name === "PolygonType" && !isNull(polygonTypeToSet))) {
        let value = ''
        if (!isUndefined(details[field.Name])) {
          value = details[field.Name]
        } else if (polygonType===PolygonTypes.grid && !isUndefined(polygon.attributes[field.Name])) {
          value = polygon.attributes[field.Name]
        }
        jobAttributes += field.FieldType === fieldType.date ?
          `"${field.Name}":"${convertDate(value, false, true, "iso")}",` :
          `"${field.Name}":"${(isUndefined(value) || isNull(value))? "": value}",`
      }
    })
    if (!isNull(polygonTypeToSet)) { jobAttributes += `"PolygonType":"${polygonTypeToSet}",` }

    const polygonName = polygonType === PolygonTypes.grid ? 
        polygon.attributes[gridNameFieldName] : 
      (polygonType === PolygonTypes.corrosionArea || polygonType === PolygonTypes.pipelineId) ?
        polygon.attributes[getGridLayerFieldByPolygonType(polygonType, gridLayerFields.GridNameFieldName)] : 
        surveyPlanName  // polygonType === "List"
    const polygonId = polygonType === PolygonTypes.grid ? 
        polygon.attributes[getGridIdFieldByName(details.PolygonType)] :
      (polygonType === PolygonTypes.corrosionArea || polygonType === PolygonTypes.pipelineId) ? 
        polygon.attributes[getGridLayerFieldByPolygonType(polygonType, gridLayerFields.GridIdFieldName)] :
        generateID()  // polygonType === "List"

    jobInfo += `
    ,
    {
      "objectId": -1,
      "attributes": {
        ${jobAttributes}
        "SURVEYJOBID": "{${generateID()}}",
        "SURVEYJOBNAME": "${escapeSpecialChars(polygonName)}: ${surveyPlanName}",
        "SURVEYPLANID": "{${surveyPlanID}}",
        "POLYGONNAME": "${escapeSpecialChars(polygonName)}",
        "POLYGONID": "${escapeSpecialChars(polygonId)}",
        "CREATEDON": "${createdOn}",
        "MODIFIEDON": "${modifiedOn}"
      },
      "type": 1,
      "oldAttributes": {},
      "tableId": ${surveyJobTableID},
      "hasBeenUndone": false
    }`
  })


  // note: this assumes PolygonType isn't one of the surveyPlanFields
  let surveyPlanAttributes = ``
  surveyPlanFields.forEach((field:any) => {
    if (!nonDynamicFields.includes(field.Name)) {
      let value = details[field.Name] ? details[field.Name] : ''
      surveyPlanAttributes += field.FieldType === 5 ?
        `"${field.Name}":"${convertDate(value, false, true, "iso")}",` :
        `"${field.Name}":"${value}",`
    }
  })
  
  if (assetCSVFileLocation) { 
    surveyPlanAttributes +=  `"LISTASSETSFILE":"${assetCSVFileLocation}",`
  }

  return(
    `{
      "objectId": -1,
      "attributes": {
        ${surveyPlanAttributes}
        "SURVEYPLANID": "{${surveyPlanID}}",
        "CREATEDON": "${createdOn}",
        "MODIFIEDON": "${modifiedOn}"
      },
      "type": 1,
      "oldAttributes": {},
      "tableId": ${surveyPlanTableID},
      "hasBeenUndone": false
    }
    ${jobInfo}`
  )
}

/**
 * Summary: fetch options based on user input and active surveygrid.
 *          Will also massage data to conform to what is needed to draw
 *          on the ESRI Map by adding polygon type and spatialreference
 *
 * @param inputValue User input from async input
 *
 * @return Promise that fetches based on user input and active polygon type
 *
 */
export const promiseOptions = (inputValue:string, activeGridType:string) => {
  const selectedMapService = store.getState().LoginReducer.selectedMapService.decodedLabel
  const MapServiceId = getLayerMapServiceIdByName(activeGridType)

  const GridNameFieldName = getGridLayerFieldValue(activeGridType, gridLayerFields.GridNameFieldName)
  const GridIdFieldName = getGridLayerFieldValue(activeGridType, gridLayerFields.GridIdFieldName)
  const OidFieldName = getLayerOrTableByName(activeGridType).FeatureClass.OidFieldName
  const outFields = `${GridNameFieldName}, ${GridIdFieldName}, ${OidFieldName}`

  if(inputValue){
    return getPromiseOptions(
      selectedMapService,
      MapServiceId,
      encodeUriAll(`${GridNameFieldName} like '${inputValue}%'`),
      outFields,
      GridNameFieldName,
      5
    )
      .then((res: any) => res.data)
      .then((grids: any) => {
        return grids.features.map((grid: any) => {
          let option = {
            label:grid.attributes[GridNameFieldName],
            value:{
              ...grid,
            }
          }
          // massaging data to fit what is needed to draw polygons on the map
          set(option,'value.geometry.spatialReference',grids.spatialReference)
          set(option,'value.geometry.type','polygon')
          return option
      })
    })
  }
}


/**
 * Summary: fetch options based on user input and polygon type.
 *
 * @param inputValue User input from async input
 *
 * @return Promise that fetches based on user input and active polygon type
 *
 */
 export const promiseOptionsCSL = (inputValue:string, polygonType:PolygonTypes) => {
  const selectedMapService = store.getState().LoginReducer.selectedMapService.decodedLabel
  const MapServiceId = getMapServiceIdByPolygonType(polygonType)
  const GridNameFieldName = getGridLayerFieldByPolygonType(polygonType, gridLayerFields.GridNameFieldName)?.toUpperCase();

  const GridLayers = store.getState().SettingsReducer?.layers?.GridLayers
  const GridLayerName = GridLayers.filter((item: any) => item.polygonType === polygonType)[0]?.Name;
  const gasMainsFields =  getLayerOrTableByName(GridLayerName).Fields;
  const gasMainsFieldType = gasMainsFields.filter((item:any) => item.Name === GridNameFieldName)[0].FieldType
  const comparisionCondition = whereQueryFieldTypeConversion(
    GridNameFieldName,
    gasMainsFieldType,
    inputValue,
    searchMatchType.beginningMatch
  );

  if(inputValue){
    return getPromiseOptions(
      selectedMapService,
      MapServiceId,
      comparisionCondition,
      "*",
      GridNameFieldName,
      500
    )
      .then((res: any) => res.data)
      .then((grids: any) => {
        const validateDict: { [index: string]: any } = {};
        grids.features.forEach((grid: any) => {
          if (!(grid.attributes[GridNameFieldName] in validateDict)) {
            validateDict[grid.attributes[GridNameFieldName]] = grid;
          }
        });

        const validateGrids = Object.values(validateDict).slice(0, 5);
        return validateGrids.map((grid: any) => {
          grid.attributes = mapAttribute(grid.attributes);
          return {
            label: grid.attributes[getNameMapping(GridNameFieldName)],
            value: grid,
          };
        });
      });
  }
}
