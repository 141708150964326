import axios from "axios";
import { getArcGISToken } from "api/v1";
import { refreshToken } from "app/Auth/refreshToken";
import { formatRequestBody } from "utils/AxiosUtils";

export * from "./arcGISCalls";
export * from "./constants";


const arcGisBaseUrl: any = axios.create({
  baseURL: `${window.urlConfig.reactArcgisRestUrl}`,
  params: {
    f: "json"
  },
});

arcGisBaseUrl.interceptors.request.use(
  async (config: { params: { token: any }, headers:any, baseURL: any,  method: any, data: any, requestOptions: any },) => {
    if(config.baseURL.includes(`${window.urlConfig.reactArcgisRestUrl}`)){
      let data = formatRequestBody(config);

      config = {
        ...config,
        method: 'post',
        data : config.data ? `${data}&token=${localStorage.getItem("ArcGISToken")}`  : `token=${localStorage.getItem("ArcGISToken")}`
      };
    }
    return config;
  }
);

arcGisBaseUrl.interceptors.response.use(
  async function (response: any) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    const originalRequest = response.config;
    if ((response.data?.error?.code === 498 || response.data?.error?.code === 499) && !originalRequest._retryArcGISToken) {
      originalRequest._retryArcGISToken = true;
      const ArcGISToken= await getArcGISToken()
      localStorage.setItem("ArcGISToken", ArcGISToken.data.token)
      return arcGisBaseUrl(originalRequest);
    }
    
    return response
  },
  async function (error: any) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const originalRequest = error?.config;
    if (error?.response?.status === 401 && !originalRequest._retryAzureToken) {
      originalRequest._retryAzureToken = true;
      const access_token = await refreshToken();
      axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
      return arcGisBaseUrl(originalRequest);
    }
    return Promise.reject(error);
  }
);

export default arcGisBaseUrl;



