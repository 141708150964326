import React from "react";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { useMsal } from "@azure/msal-react";
import { TiVendorMicrosoft } from "react-icons/ti";

import "./Login.scss";
import { authConfig } from "index";
import { logIn, LOGIN_ERROR } from "./LoginDucks";
import { ReactComponent as LanternLogo } from "../../assets/LanternLoginLogo.svg";
import Button from "common/Button/Button";
import ErrorPage from "common/ErrorPage/ErrorPage";

const LogInPage = () => {
  const dispatch = useDispatch();
  const { loginSuccess } = useSelector((state: any) => state.LoginReducer);
  const error = useSelector((state: any) => state.SettingsReducer.error);
  // const loading = createLoading(["SETTING"]);
  const { instance } = useMsal();

  /**
   * Summary: authenticates the user via microsoft login popup 
   * and then the logIn action craetor is dispatched 
   */

   const handleLogin = async (e: any) => {
    e.preventDefault();
    instance
      .loginPopup(authConfig.loginRequest)
      .then((res) => {
        dispatch(logIn(res.account, instance, authConfig.loginRequest))
      })
      .catch(() => dispatch({ type: LOGIN_ERROR }));
  };

  // if (loading) {
  //   // Dont want a loading bar at the beginning of the application, so we'll just have an empty page.
  //   return null;
  // }

  if (!isEmpty(error)) {
    return <ErrorPage errorObject={error} showBackLink={false} />;
  }

  return (
    <div id="login-page-container">
      <LanternLogo className="lantern-logo" />
      <form
        onSubmit={(e) => {
          handleLogin(e);
        }}
      >
        <Button boxColor="blue" size="l" className="login-btn">
          <TiVendorMicrosoft className="microsoft-icon" />
          Login With Azure AD
        </Button>
        {loginSuccess === false && (
          <div className="login-failed">Log in failed. Please try again.</div>
        )}
      </form>
    </div>
  );
};

export default LogInPage;
