import React from 'react';
import { TablePageChangeActions } from 'common/Tables/PaginatedTable/TableUtils/TableUtils';

/**
 * Summary: Component used for paginated tables
 *
 * @param pageIndex Number:Index of the page the user is currently on
 * @param pageSize Number:Number of items per page
 * @param pageCount Number:Number of paginated pages that exists
 * @param rows Array:Contains data about all rows
 * @param canPreviousPage Boolean: true if there are previous pages before the current one
 * @param canNextPage Boolean: true if there are pages after current one
 * @param gotoPage Function: function to jump to another page given page index
 * @param previousPage Function: function to go to previous page
 * @param nextpage Function: function to go to next page
 * @param enableRemoteOperations Boolean: pass true for enabling the server side pagination
 * @param totalCount Number: Total number of records in the table
 * @param pageChangeCallback Function: function to handle the page change action callbacks and supply new data to the table
 * 
 * @return Component to render footer for paginated tables
 */
export const Footer = ({
  pageIndex,
  pageSize,
  pageCount,
  rows,
  canPreviousPage,
  canNextPage,
  gotoPage,
  previousPage,
  nextPage,
  onPageIndexChange,
  enableRemoteOperations = false,
  totalCount = 0,
  pageChangeCallback = () => {}
}) => {
  //storing the rows length
  let rowsLength = rows?.length;
  //if server side pagination is enabled, determing the number of pages as we have to pass this value for the react table
  //also calculating canNextPage value for sever side pagination enabled
  if(enableRemoteOperations){
    pageCount = Math.ceil(totalCount / pageSize)
    rowsLength = totalCount
    canNextPage = pageCount === -1 ? rows.length >= pageSize : pageIndex < pageCount - 1
  }
  return(
  <div>
    <div className="footer">
      Page {" "}
      {pageCount?pageIndex+1:0} of {pageCount}
      <span className="table-info">
        (Displaying {pageCount?pageIndex*pageSize+1:0} - {" "}
        {getEndPageValue(pageIndex,pageSize,canNextPage,rowsLength )}
        {" "}
        of {rowsLength} results)
      </span>
    </div>
    {pageCount > 1 &&
    <div className="pagination">
      <button 
        onClick={() => {
          gotoPage(0); 
          enableRemoteOperations && pageChangeCallback({ action: TablePageChangeActions.FirstPage });
        }} 
        disabled={!canPreviousPage}
      >
        {'<<'}
      </button>
      <button 
        onClick={() => {
          previousPage(); 
          enableRemoteOperations && pageChangeCallback({ action:TablePageChangeActions.PrevPage });
        }}
        disabled={!canPreviousPage}
      >
        {'<'}
      </button>
      {renderPageNumbers(pageIndex,pageCount,gotoPage, onPageIndexChange, pageChangeCallback)}
      <button 
        onClick={() => { 
          nextPage(); 
          enableRemoteOperations && pageChangeCallback({ action:TablePageChangeActions.NextPage });
        }}
        disabled={!canNextPage}
      >
        {'>'}
      </button>
      <button 
        onClick={() => {
          gotoPage(pageCount - 1); 
          enableRemoteOperations && pageChangeCallback({ action:TablePageChangeActions.LastPage, pageCount });
        }}
        disabled={!canNextPage}
      >
        {'>>'}
      </button>
    </div>
    }
  </div>
  )
}

/**
 * Summary: calcualtes the x value for "Displaying 1 - x of 50 results"
 * at the table footer
 *
 * Trouble finding a simple equation to fit all cases.
 * Used if statements instead
 *
 * @param {number} pageIndex Current page number in an array index
 * @param {number} pageSize Max number of elements in a single page
 * @param {boolean} canNextPage
 * Tells if there is a next page,
 * used to check if we're on the last page
 * @param {number} totalData Total amount of rows in the table
 *
 * @returns {number} page nuumber, read summary
 */
function getEndPageValue(pageIndex,pageSize,canNextPage,totalData) {
  if(!canNextPage){
    return totalData
  }
  else if (pageIndex === 0 && canNextPage){
    return  pageSize
  }
  // adding one cause of array indexing
  return (pageIndex + 1)*pageSize
}


/**
 * Summary: Function that returns the corresponding list of page numbers
 *
 * @param { number } pageIndex array index of current page number.
 * @param { number } totalPages total amount of pages
 * @param { () => void } gotoPage callback function to add onClick gotopage() for render buttons
 *
 * @return { Element[] } array of button that are used to show clickable page numbers for pagination
 */
function renderPageNumbers(pageIndex, totalPages , gotoPage, onPageIndexChange, pageChangeCallback) {
  //number of pages we want to show in the list
  let listPaginationLength = 7;
  let currentPageNumber = pageIndex+1
  let pageList=[], index;
  let HalfPagiantionLength =  Math.floor(listPaginationLength / 2)
  //Case where number of pages listed is greater than amount of pages
  if(listPaginationLength > totalPages) {
    for (index = 1; index < totalPages+1;index++){
      pageList.push(index)
    }
  }
  //Case where we are approching max pages and
  //do not want to shift the selected page to the middle
  else if(currentPageNumber + HalfPagiantionLength > totalPages) {
    for (index = totalPages + 1 - listPaginationLength; index < totalPages+1;index++){
      pageList.push(index)
    }
  }
  //Case where we are approching min pages and
  //do not want to shift the selected page to the middle
  else if (currentPageNumber - HalfPagiantionLength < 0){
    for (index = 1 ; index<listPaginationLength+1; index++){
      pageList.push(index)
    }
  }
  // Base case where we want the selected value in the middle
  else {
    for (index = currentPageNumber - HalfPagiantionLength;index < currentPageNumber + (listPaginationLength / 2);index++){
      if(index < 1){
        for (index = 1 ; index < listPaginationLength+1;index++){
          pageList.push(index)
        }
        break;
      }
      else {
        pageList.push(index);
      }
    }
  }

  return pageList.map((pageNumber)=>{
    return(
      <button
        key={pageNumber}
        className="page-button"
        disabled={pageIndex === pageNumber-1}
        onClick={()=> {
          gotoPage(pageNumber-1); 
          //if user clicks on a page number in the footer, raising a callback with the required action and page number
          if(pageChangeCallback){
            pageChangeCallback({action: TablePageChangeActions.SpecificPage, pageNumber});
          }
          if(onPageIndexChange) {
            onPageIndexChange(pageNumber-1);
          }
        }}>
        {pageNumber}
      </button>
    )
  })
}
