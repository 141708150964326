import styled from "styled-components";
// import '../../app/colors';

export const Container = styled.div`
  display: inline-block
  margin: 2rem auto;
  max-width: 30rem;
`;

export const DraggableItem = styled.div `
  margin: 1em;
  border-bottom: 1px solid #F2F3F4;
`;

export const StyledLabel = styled.label`
  font-weight: 550;
  font-size: 12px;
  color: #231F20;
  margin: auto;
`;

export const ToggleSpan = styled.span`
  float: right;
  margin: auto;
`
export const StyledSlider = styled.div`
  display: inline-block;
  appearance: none;
  max-width: 40%;
  width: 100%;
  margin: auto;
  cursor: pointer;
`;
export const IconSpan = styled.span`
  display: inline-block;
  float: left;
  height: 2em;
  margin-top: 2%;
  margin-right: 2%;
`;

export const Styledul = styled.ul`
  list-style-type: none; 
  padding: 0;
  margin: 0;
  width: 100%;
`;