import React from 'react';
import './LoadingWheel.scss'

const LoadingWheel: React.FunctionComponent = () => {
  return(
    <div className="loader"/>
  )
}

export default LoadingWheel;
