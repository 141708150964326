import { useEffect, useRef } from 'react';

/**
* Summary: custom hook to get the previous value of the prop
* @param value prop to get previous value from
* @returns previous value of the prop
*/
export const usePrevious = (value: any) => {
  const ref = useRef()

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}
