import React, { useEffect, useState } from 'react';
import MultiSelectDropdown from 'common/Dropdown/MultiSelectDropdown';
import { convertDate } from 'utils/DateUtils';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { 
  getHistoricalData as getHistoricalDataSurveyArea, 
  surveyAreaDefaultStatusOptions
} from 'features/SurveyArea/SurveyAreaDucks';
import {
  getHistoricalData as getHistoricalDataSurveyReview,
  surveyReviewDefaultStatusOptions,
  surveyReviewDetailsDefaultStatusOptions,
  SET_SURVEY_REVIEW_USER_SELECTIONS
} from 'features/SurveyReview/SurveyReviewDucks';
import { pages } from 'utils/UrlUtils'
import { statusTypes } from '../PredefinedFilterTemplates/StatusTypes';
import {applyFilterToGridColumns} from 'utils/GridUtils';
import { surveyInitiationDefaultStatusOptions } from 'features/SurveyInitiation/SurveyInitiationDucks';
import { surveyAssignmentDefaultStatusOptions } from 'features/SurveyAssignment/SurveyAssignmentDucks';

/**
 * Summary: get default filters for column with id columnId
 */
export function getDefaultFilters(columns, columnId) {
  for (const column of columns) {
    if (column.id === columnId) {
      if (column.defaultFilters) {
        return column.defaultFilters
      }
    }
  }
  return undefined
}

export function MultiSelectDropdownFilter({
  column: {Header, setFilter, preFilteredRows, id}, headerGroups, defaultColumn}, _blank, pathValue = '', type) {
  preFilteredRows = defaultColumn?.preFilterRows.length ? defaultColumn.preFilterRows : preFilteredRows || []
  let columns = headerGroups[0].headers
  let preDefinedFilterValuesFlag = false
  let dropDownOptions = []
  let defaultFilters = []
  let currentColumn  
  for (let index = 0; index < columns.length; index++) {
    if (columns[index].id === id) {
      //sets the intitial filter in the filters panel
      currentColumn = applyFilterToGridColumns({defaultColumn, columns, currentColIndex: index});
      if (columns[index].preDefinedFilterValues) {
        dropDownOptions = columns[index].preDefinedFilterValues
        preDefinedFilterValuesFlag = true
      }
      if (columns[index].defaultFilters) {
        defaultFilters = columns[index].defaultFilters
      }
      break;
    }
  }

  // Calculate the options for filtering
  // using the preFilteredRows
  const CreateOptions = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      let option = defaultColumn?.preFilterRows.length ? row.attributes[id] : row.values[id]
      if (Array.isArray(option)) {
        option.forEach((item) => {
          let value = get(item,pathValue,item)
          if (type === "date") {
            value = convertDate(value, false, false, 'date').toString()
          }
          options.add(value)
        })
      } else if (option) {
        if (type === "date") {
          option = convertDate(option, false, false, 'date').toString()
        }
        options.add(option)
      }
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  if (!preDefinedFilterValuesFlag) {
    dropDownOptions = CreateOptions
  }

  useEffect(() => {
    if (defaultFilters && isEmpty(currentColumn.filterValue)) {
      multiSelectDropdownFilterChange(id, defaultFilters);
    }
  },[])

  const multiSelectDropdownFilterChange = function(id, e){
    setFilter(e);
    if(defaultColumn.filterChangeCB){
      defaultColumn.filterChangeCB(id, e);
    }
  };

  const getDefaultValues = function(){
    return (!isEmpty(currentColumn.filterValue) ? currentColumn.filterValue.map(option => ({value:option, label:option})) 
    : defaultFilters.map(option => ({value:option, label:option})));
  }

  return (
    <span className="dropdown-container">
      <div className="dropdown-header">
        {Header}
      </div>
      <MultiSelectDropdown
        isReset = {defaultColumn.isReset}
        onReset = {() => multiSelectDropdownFilterChange(id, defaultFilters)}
        items = {dropDownOptions}
        placeHolder={"All"}
        handleChange = {(e)=> { multiSelectDropdownFilterChange(id, e)} }
        defaultValue = {getDefaultValues()}
      />
    </span>
  )
}

/**
 * Summary: Filter function for status options. 
 * Precondition: the column data must have a `location` field.
 */
export function MultiSelectDropdownFilterForStatus(
  {column: {Header, setFilter, preFilteredRows, id}, headerGroups, defaultColumn}) {
  
  const dispatch = useDispatch()
  const [preFilteredData, setPreFilteredData] = useState([]);

  // Upon a new api call, filtering happens prior loading. Wait for table to render.
  useEffect(() => {
    setFilter(defaultFilters)
  }, [preFilteredData])
  
  useEffect(() => {
    setPreFilteredData(preFilteredRows)
  }, [])

  const columns = headerGroups[0].headers
  const column = columns.find(column => column.id === id)
  const dropDownOptions = column.preDefinedFilterValues ?? []
  const defaultFilters = column.defaultFilters ?? []
  const currentPage = column.location
  const containsHistorical = column.defaultFilters?.includes(statusTypes.historical) ?? false
  let defaultStatusOptions = []
  let fetchHistoricalData = () => {}

  switch (currentPage) {
    case pages.surveyArea:
      fetchHistoricalData = getHistoricalDataSurveyArea
      defaultStatusOptions = surveyAreaDefaultStatusOptions
      break;
    case pages.surveyReview:
      fetchHistoricalData = getHistoricalDataSurveyReview
      defaultStatusOptions = surveyReviewDefaultStatusOptions
      break;
    case pages.surveyReviewPlanDetails:
      defaultStatusOptions = surveyReviewDetailsDefaultStatusOptions
      break;  
    case pages.surveyInitiation:
      defaultStatusOptions = surveyInitiationDefaultStatusOptions
      break;
    case pages.surveyAssignment:
      defaultStatusOptions = surveyAssignmentDefaultStatusOptions
      break;
    default: // precondition ensures we never encounter default.
      console.error(`There is no function for retrieving the historical data for ${currentPage} page.`)
      break;
  }

  const multiSelectDropdownFilterChange = function(id, e){
    setFilter(e);
    if(defaultColumn.filterChangeCB){
      defaultColumn.filterChangeCB(id, e);
    }
  };

  return (
    <span className="dropdown-container">
      <div className="dropdown-header">
        {Header}
      </div>
      <MultiSelectDropdown
        isReset = {defaultColumn.isReset}
        onReset = {() => multiSelectDropdownFilterChange(id, defaultStatusOptions)}
        items = {dropDownOptions}
        placeHolder={"All"}
        handleChange = {(e)=> { multiSelectDropdownFilterChange(id, e)} }
        defaultValue = {defaultFilters.map(option => {
          return {value:option, label:option}
        })}
        // API call is only made ONCE i.e. when page does not contain historical data
        onValueChange = {value => fetchHistoricalData(value, dispatch, containsHistorical)}
        preselectedValuesOnReset = { defaultStatusOptions.map(option => ({value: option, label: option})) }
      />
    </span>
  )
}

export function MultiSelectDropdownFilterForDates({
  column: {Header, setFilter, preFilteredRows, id}, headerGroups, defaultColumn}, _blank) {
  
  return MultiSelectDropdownFilter({
    column: {Header, setFilter, preFilteredRows, id}, headerGroups, defaultColumn}, _blank, '', "date")
}

/**
 * Summary: React-table doesnt play nice when you try and
 * pass extra parameters to the filter function. It will re-mount the
 * component everytime the table data changes
 * THIS FUNCTION IS NOT REUSEABLE.
 *
 * TODO : find a way to make it reusable
 */
export function MultiSelectDropdownFilterForUsers({
  column: {Header, setFilter, preFilteredRows, id}, headerGroups, defaultColumn}) {
  preFilteredRows = defaultColumn?.preFilterRows.length ? defaultColumn.preFilterRows : preFilteredRows || []

  const defaultFilters = getDefaultFilters(headerGroups[0].headers, id)

  //store options/selections in redux for persistence
  const dispatch = useDispatch()
  let existingSelections = useSelector((state) => state.SurveyReviewReducer.surveyJobsUserSelections)

  const getSelections = function() {
    let selections = (existingSelections?.length) ? existingSelections.map(option => {
      return {value:option, label:option};
    }) : undefined
    return selections
  }

  useEffect(() => {
    if (defaultFilters) {
      multiSelectDropdownFilterChange(id, defaultFilters);
    }
  }, []);

  // Calculate the options for filtering
  // using the preFilteredRows
  const CreateOptions = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      let option = defaultColumn?.preFilterRows.length ? row.attributes[id] : row.values[id]
      if (Array.isArray(option)) {
        option.forEach((item) => {
          options.add(get(item,'Attributes.UserID',item))
        })
      } else if (option) {
        options.add(option)
      }
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  const multiSelectDropdownFilterChange = function(id, e){
    setFilter(e);
    dispatch({type: SET_SURVEY_REVIEW_USER_SELECTIONS, surveyJobsUserSelections: e})
    if(defaultColumn.filterChangeCB){
      defaultColumn.filterChangeCB(id, e);
    }
  };

  return (
    <span className="dropdown-container">
      <div className="dropdown-header">
        {Header}
      </div>
      <MultiSelectDropdown
        isReset = {defaultColumn.isReset}
        onReset = {() => multiSelectDropdownFilterChange(id, defaultFilters || [])}
        items = {CreateOptions}
        placeHolder={"All"}
        handleChange = {(e)=> { multiSelectDropdownFilterChange(id, e)} }
        existingSelections = {getSelections()}
      />
    </span>
  )
}

/**
 *
 * Summary: Filter algorithm for multi select dropdowns
 *
 * @param {Array} rows Data that is being filtered
 * @param {string} filterCategory column that the filter will be applied on
 * @param {Array} filterValue Values used to filter rows
 *
 * @returns {Array} Data that is filtered and to be used in the new table
 */
export function filterMultiSelectValue(rows,filterCategory,filterValue) {
  let filteredRows = []
  // If tree view has no selected values just return rows before treeselect was used
  if (isEmpty(filterValue)){
    filteredRows = rows
  }
  // If there are tree view filterValues filter them
  else if(filterValue.length > 0){
    rows.forEach((row)=>{
      for(let index = 0; index < filterValue.length; index++) {
        if (String(filterValue[index]) === String(row.values[filterCategory])){
          filteredRows.push(row)
          break;
        }
      }
    })
  }
  return filteredRows
}

/**
 *
 * Summary: Filter algorithm for multi select dropdowns
 *
 * @param {Array} rows Data that is being filtered
 * @param {string} filterCategory column that the filter will be applied on
 * @param {Array} filterValue Values used to filter rows
 *
 * @returns {Array} Data that is filtered and to be used in the new table
 */
 export function filterMultiSelectValueForDates(rows,filterCategory,filterValue) {
  let filteredRows = []
  // If tree view has no selected values just return rows before treeselect was used
  if (isEmpty(filterValue)){
    filteredRows = rows
  }
  // If there are tree view filterValues filter them
  else if(filterValue.length > 0){
    rows.forEach((row)=>{
      for(let index = 0; index < filterValue.length; index++) {
        const rowValue = convertDate(row.values[filterCategory], false, false, 'date').toString()
        if (String(filterValue[index]) === rowValue){
          filteredRows.push(row)
          break;
        }
      }
    })
  }
  return filteredRows
}