import React, { useState } from 'react';
import { IWidgetProps, widgetTypes } from 'common/Map/MapWidgetLayer';
import LayersIcon from '@material-ui/icons/Layers';
import { useDispatch } from 'react-redux';
import { SET_ACTIVE_TOOL } from 'common/Map/MapDucks';
import { Size } from 'styled_components/SidePanel/ResizeIcons';
import OverriddenTabs from 'common/Tabs/OverriddenTabs';
import { BasemapLayersTab } from './BasemapLayersTab';
import FeatureLayersTab from './FeatureLayersTab';
import './TableOfContentsWidget.scss';

export interface ITableOfContentsLayersTab {
  mapType: string
}

export const TableOfContentsWidget: React.FunctionComponent<IWidgetProps> = ({
  currentTool,
  activeSlideoutTool,
  isSlideoutOpen,
  sketchWidget,
  unselectWidget,
  measureWidget,
  mapType,
  setForcedActiveSize
}:IWidgetProps) => {

  enum tableOfContentsTabValue {
    basemapLayer = 0,
    featureLayer = 1
  }

  const dispatch = useDispatch()
  const [tabValue, setTabValue] = useState<tableOfContentsTabValue>(tableOfContentsTabValue.featureLayer)

  function handleClick() {
    if (currentTool !== widgetTypes.tableOfContents) {
      sketchWidget?.cancel()
      unselectWidget?.cancel()
      measureWidget?.clear()
      dispatch({type: SET_ACTIVE_TOOL + mapType, tool: widgetTypes.tableOfContents})
      if (setForcedActiveSize) {
        setForcedActiveSize(Size.small)
      }
    }
    else {
      dispatch({type: SET_ACTIVE_TOOL + mapType, tool: widgetTypes.move})
    }
  }

  return (
    <div id="table-of-contents-container">
      <button
        title="Table Of Contents"
        id="table-of-contents-btn"
        className={"widget-btn" + (currentTool===widgetTypes.tableOfContents?" btn-active":"")}
        onClick={handleClick}
      >
        <LayersIcon/>
      </button>
      {currentTool===widgetTypes.tableOfContents && 
        <div id="table-of-contents-panel">
          <div id="table-of-contents-header">
            Table of Contents
          </div>
          <OverriddenTabs
            labels={['Basemap Layers','Feature Layers']}
            tabValue={tabValue}
            handleTabValue={() => tabValue === tableOfContentsTabValue.basemapLayer ? 
              setTabValue(tableOfContentsTabValue.featureLayer) : 
              setTabValue(tableOfContentsTabValue.basemapLayer)}
          />
          <div>
          {/* <FeatureLayersTab
            mapType={mapType}
          /> */}
          
            {tabValue === tableOfContentsTabValue.basemapLayer ? 
              <BasemapLayersTab
                mapType={mapType}
              /> : 
              <FeatureLayersTab
                mapType={mapType}
              />
            }
          </div>
        </div>
      }
    </div>
  )
}
