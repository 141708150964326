import React, { ReactElement } from 'react';
import { store } from "app/store"
import set from "lodash/set"
import PopupModal from "common/PopupModal/PopupModal"
import { IButtonSizes } from 'common/Button/Button'
import {
  getGridLayerFieldValue,
  getLayerMapServiceIdByName,
  getLayerOrTableByName,
  gridLayerFields
} from "utils/GridUtils"
import { statusTypes } from 'common/Tables/PaginatedTable/TableUtils/PredefinedFilterTemplates/StatusTypes';
import { getPromiseOptions } from 'api';
import { encodeUriAll } from 'utils/UrlUtils';


/**
 * Summary: return the archive PopupModal in EditArea and ExistingArea
 */
 export const renderArchiveModal = (openBtn: Element | ReactElement, title: string, confirmClick: Function, openButtonSize?: IButtonSizes, surveyStatus?: any ) => {
  return <PopupModal
    openBtn = {openBtn}
    title = {`Archive ${title}`}
    message = "Are you sure you would like to archive the Survey Area?"
    confirmBtn = "Confirm"
    confirmClick = {confirmClick}
    cancelBtn = "Cancel"
    openButtonColor = "white"
    openButtonSize = {openButtonSize}
    isOpenButtonDisabled = {surveyStatus === statusTypes.historical}
  />
}

/**
 * Summary: fetch options based on user input and active surveygrid. Will also massage data to conform to what is needed to draw
 * on the ESRI Map by adding polygon type and spatialreference
 *
 * @param inputValue User input from async input
 *
 * @return Promise that fetches based on user input and active polygon type
 *
 */
export const promiseOptions = (inputValue:string, activeGridType:string) => {
  let outFields = '*'
  const gridLayers = store.getState().SettingsReducer.layers.GridLayers
  const selectedMapService = store.getState().LoginReducer.selectedMapService.decodedLabel
  const oidFieldName = getLayerOrTableByName(activeGridType).FeatureClass.OidFieldName
  let GridNameFieldName = getGridLayerFieldValue(activeGridType, gridLayerFields.GridNameFieldName)
  let MapServiceId = getLayerMapServiceIdByName(activeGridType)

  for(let i = 0; i < gridLayers.length; i++) {
    if(activeGridType === gridLayers[i].Name){
      outFields = `${gridLayers[i].GridNameFieldName},${gridLayers[i].GridIdFieldName},${oidFieldName}`;
    }
  }

  if(inputValue){
    return getPromiseOptions(
      selectedMapService,
      MapServiceId,
      encodeUriAll(`${GridNameFieldName} like '${inputValue}%'`),
      outFields,
      GridNameFieldName,
      5
    )
    .then((res: any) => res.data)
    .then((grids: any)=>{
      return grids.features.map(
        (grid:any)=>{
          let option = {
          label:grid.attributes[GridNameFieldName],
          value:{
            ...grid,
          }
        }
        //massaging data to fit what is needed to draw polygons on the map
        set(option,'value.geometry.spatialReference',grids.spatialReference)
        set(option,'value.geometry.type','polygon')
        return option
      })
    })
  }
}
