import React, { ReactElement, useEffect } from "react";
import './PopupModal.scss';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import Fade from '@material-ui/core/Fade';
import Button, { IButtonColors, IButtonSizes } from 'common/Button/Button';

// TODO: make confirmBtn and confirmClick required only if isConfirmButtonDisabled is false 

interface IPopupModalProps {
  openBtn: Element|ReactElement,
  title: string,
  titleDescription?: any,
  message: any,
  bottomMessage?:any,
  confirmBtn: string,
  confirmClick: Function,
  cancelBtn?: string,
  openButtonColor?: IButtonColors,
  openButtonSize?: IButtonSizes,
  openButtonClass?: string
  isOpenButtonDisabled?: boolean,
  isOpenButtonRemoved?: boolean,
  useOpenText?: boolean,
  isConfirmButtonDisabled?: boolean,
  isConfirmButtonRemoved?:boolean,
  onClose?: Function,
  verticallyCenter?: boolean,
  limitWidth?:boolean,
  modalClassName?: string,
  cancelClick?: Function,
}


const PopupModal = ({
  openBtn,
  title,
  titleDescription="",
  message,
  bottomMessage,
  confirmBtn,
  confirmClick,
  cancelBtn,
  openButtonColor = "blue",
  openButtonSize = 'l',
  openButtonClass,
  isOpenButtonDisabled = false,
  isOpenButtonRemoved = false,
  useOpenText = false,
  isConfirmButtonDisabled = false,
  isConfirmButtonRemoved = false,
  onClose = () => null,
  verticallyCenter = false,
  limitWidth = false,
  modalClassName = "",
  cancelClick
}:IPopupModalProps) => {

  // note: if useOpenText is true, the open button will be a clickable div instead of a button.
  // (to give the option to further customize the open button)
  // For this option, isOpenButtonDisabled does not work, and openButtonSize and openButtonColor do not apply

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if(cancelClick){
      cancelClick();
    } else{
      onClose();
    }
  };

  const onConfirmClick = () => {
    confirmClick(); 
    handleClose();
  }

  useEffect(() => {
    if (isOpenButtonRemoved) {
      handleOpen();
    }
  }, [])

  return (
    <div className="modal-container">
      {isOpenButtonRemoved ? null : 
        (useOpenText ?
          <div
            className="open-btn"
            onClick={handleOpen}
          >
            {openBtn}
          </div> :
          <Button
            boxColor = {openButtonColor}
            size= {openButtonSize}
            className={"open-btn " + openButtonClass}
            onClick={handleOpen}
            isDisabled = {isOpenButtonDisabled}
          >
            {openBtn}
          </Button>
        )
      }
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-message"
        open={open}
        onClose={handleClose}
        disableScrollLock={true}
        closeAfterTransition
        BackdropProps={{
          id:"backdrop"
        }}
      >
        <Fade in={open}>
          <div className={"modal" + (verticallyCenter?" vertically-centered-modal":"")
            + (limitWidth? " limited-width-modal":"") + ` ${modalClassName}`}
          >
            <span id="modal-title">{title} </span>
            {titleDescription && <span id="modal-title-grey">({titleDescription})</span>}
            <button className="close-btn" type="button" onClick={handleClose}><CloseIcon/></button>
            <div id="modal-description">{message}</div>
            <div id="modal-bottom">
              {/* when there is no bottomMessage, we need an empty div here to ensure the modal buttons 
              do not move to the left side */}
              {bottomMessage?bottomMessage:<div></div>}
              <div className="modal-btns">
                {cancelBtn &&
                  <Button
                    boxColor="grey"
                    size="l"
                    onClick={handleClose}
                  >
                    {cancelBtn}
                  </Button>
                }
                {!isConfirmButtonRemoved &&
                  <Button
                    boxColor="blue"
                    size="l"
                    onClick={onConfirmClick}
                    isDisabled={isConfirmButtonDisabled}
                  >
                    {confirmBtn}
                  </Button>
                }
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  )
}

export default PopupModal;
