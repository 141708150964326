import  { useState } from "react";
import { useDispatch } from "react-redux";
import { ListItemText, Collapse, ListItemButton } from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import { isEqual } from "lodash";
import {StyledList, StyledListItemText} from "styled_components/DropList/DropList"
import { SET_NEAREST_FEATURE_HIGHLIGHT, CLEAR_NEAREST_FEATURE_HIGHLIGHT } from "features/MapPage/MapPageDucks";


function NearestFeatureDropList({ layer, setSelectedLayer, selectedFeature, setSelectedFeature }: any) {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
    setSelectedLayer(null)
    dispatch({type: CLEAR_NEAREST_FEATURE_HIGHLIGHT})
    setSelectedFeature(null)
  };

  const getDroplistFeatureStyling = (feature:any) => {
    if (feature.isSet) {
      return "set-feature"
    }
    if (isEqual(selectedFeature?.attributes, feature?.attributes)) {
      return "selected-feature-text"
    }
    return ""
  }

  return (
    <>
      <ListItemButton onClick={handleClick}>
        {open ? <ExpandLess /> : <ExpandMore />}
        <ListItemText
          primary={`${layer.layerName}  (${layer.features.length})`}
        />
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <StyledList disablePadding>
          {layer.features.map((feature: any, index: number) => (
            <ListItemButton
              sx={{ pl: 4 }}
              key={index}
              onClick={() => {
                setSelectedLayer(layer)
                setSelectedFeature(feature);
                dispatch({type: SET_NEAREST_FEATURE_HIGHLIGHT, feature: feature, geometryType: layer.geometryType})
              }}
              className={isEqual(selectedFeature?.attributes, feature?.attributes) ? "selected-feature-background" : ""}
            >
              <StyledListItemText
                className={getDroplistFeatureStyling(feature)}
                primary={feature.attributes[layer.displayField] || layer.layerName}
              />
            </ListItemButton>
          ))}
        </StyledList>
      </Collapse>
    </>
  );
}

export default NearestFeatureDropList;
