import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { store } from "app/store";
import { urlConfig } from "index";
import { encodeUriAll } from "utils/UrlUtils";
import { getAttachmentRelationshipRequest } from "api"

import { uploadFeatureAttachment } from "api/legacy/AttachmentsDucks";

export const ATTACHMENTS_LOADING = "ATTACHMENTS_LOADING";
export const ATTACHMENTS_LOADING_SUCCESS = "ATTACHMENTS_LOADING_SUCCESS";
export const ATTACHMENTS_LOADING_ERROR = "ATTACHMENTS_LOADING_ERROR";

export const ATTACHMENTS_UPLOAD = "ATTACHMENTS_UPLOAD";
export const ATTACHMENTS_UPLOAD_SUCCESS = "ATTACHMENTS_UPLOAD_SUCCESS";
export const ATTACHMENTS_UPLOAD_ERROR = "ATTACHMENTS_UPLOAD_ERROR";

export const ATTACHMENTS_UPDATE_ARCGIS = "ATTACHMENTS_UPDATE_ARCGIS";
export const ATTACHMENTS_UPDATE_ARCGIS_SUCCESS =
  "ATTACHMENTS_UPDATE_ARCGIS_SUCCESS";
export const ATTACHMENTS_UPDATE_ARCGIS_ERROR =
  "ATTACHMENTS_UPDATE_ARCGIS_ERROR";

export const ATTACHMENTS_DELELTE = "ATTACHMENTS_DELELTE";
export const ATTACHMENTS_DELELTE_SUCCESS = "ATTACHMENTS_DELELTE_SUCCESS";
export const ATTACHMENTS_DELELTE_ERROR = "ATTACHMENTS_DELELTE_ERROR";

export const ATTACHMENTS_CLEAR_ERRORS = "ATTACHMENTS_CLEAR_ERRORS";

// function to get existing attachments for selected child item
export const getAttachments = (objectId: string) => async (dispatch: any) => {
  dispatch({ type: ATTACHMENTS_LOADING });
  const tkn= localStorage.getItem('ArcGISToken');
  const getAttachmentsUrl = `${
    urlConfig.reactArcgisRestUrl
  }/Lantern_Survey/FeatureServer/13/query?where=RelatedId+%3D+%27%7B${objectId.replace(
    /[{}]/g,
    ""
  )}%7D%27&outFields=*&f=pjson`;

  try {
    const res: any = await axios.get(getAttachmentsUrl, {
      headers: {
      "X-Esri-Authorization" : `${tkn}`,
      "Authorization": `Bearer ${localStorage.getItem("token")}`
      }
    });
    if (res) {
      const attachments = res?.data?.features.map((item: any) => {
        return {
          RelatedID: item.attributes.RelatedID,
          hyperlinkPath: item.attributes.Hyperlink,
          fileName: item.attributes.Filename,
          GlobalID: item.attributes.GlobalID,
          ObjectId: item.attributes.ObjectId,
        };
      });
      if (attachments.length !== 0) {
        dispatch({
          type: ATTACHMENTS_LOADING_SUCCESS,
          Attachments: attachments,
        });
      }
    }
  } catch (err) {
    dispatch({ type: ATTACHMENTS_LOADING_ERROR, error: err });
  }
};

/**
 * Fetch attachment relationships from admin
 * @returns Object containing the attachment relationships based on configurations
 */
export const getAttachmentRelationships = async () => {
  try {
    const mapServiceName = store.getState().LoginReducer.selectedMapService.decodedLabel
    // const url = `${urlConfig.reactLanternNewServerUrl}/v1/maps/${encodeUriAll(mapServiceName)}/attachmentrelationships`;
      // .get(url)
      // .then((res: any) => res.data)
      // .catch((error: any) => console.error(error));
      const res = await getAttachmentRelationshipRequest(mapServiceName);
      return res.data
  } catch (error) {
    console.error(error)
  }
}

interface IUpdateAttachmentsResp {
  addResults: [];
}

const updateAttachmentsArcGis =
  (hyperlinkPath: string, fileName: string, objectId: string) =>
  async (dispatch: any) => {
    const tkn= localStorage.getItem('ArcGISToken');
    const editAttachmentsUrl = `${urlConfig.reactArcgisRestUrl}/Lantern_Survey/FeatureServer/13/applyEdits`;
    const options: AxiosRequestConfig = {
      method: "POST",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        Accept:
          "text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9",
        "Access-Control-Allow-Origin": "*",
        "X-Esri-Authorization" : `${tkn}`,
        "Authorization": `Bearer ${localStorage.getItem("token")}`
      },
      data: `adds=%5B%0D%0A++%7B%0D%0A++++%22attributes%22%3A+%7B%0D%0A++++++%22Hyperlink%22%3A+%22${hyperlinkPath}%22%2C%0D%0A++++++%22Filename%22%3A+%22${fileName}%22%2C%0D%0A++++++%22Description%22%3A+%22abc123%22%2C%0D%0A++++++%22RelatedID%22%3A+%22${objectId}%22%0D%0A++++%7D%0D%0A++%7D%0D%0A%5D&updates=&deletes=&gdbVersion=&rollbackOnFailure=true&useGlobalIds=false&returnEditMoment=false&trueCurveClient=true&attachments=&f=json`,
      url: editAttachmentsUrl,
    };

    try {
      dispatch({ type: ATTACHMENTS_UPDATE_ARCGIS });
      const response = await axios(options);
      if (response) {
        dispatch({ type: ATTACHMENTS_UPDATE_ARCGIS_SUCCESS });
      }
    } catch (err) {
      dispatch({ type: ATTACHMENTS_UPDATE_ARCGIS_ERROR, error: err });
    }
  };

interface IFileData {
  file: string;
  fileName: string;
  hyperlinkPath: string;
}

export const uploadAttachments =
  (fileData: IFileData, objectId: string) => async (dispatch: any) => {
    dispatch({ type: ATTACHMENTS_UPLOAD });

    // Todo: Remove this code after testing
    // const uploadUrl = `${urlConfig.reactLanternServerUrl}/Editing.ashx/features/upload`;

    // Send post request to upload the file
    uploadFeatureAttachment(fileData)
      .then(async (res: any) => {
        dispatch({
          type: ATTACHMENTS_UPLOAD_SUCCESS,
          fileData: fileData,
        });

        dispatch(
          updateAttachmentsArcGis(
            fileData.hyperlinkPath,
            fileData.fileName,
            objectId
          )
        );
      })
      .catch((err: any) => {
        dispatch({
          type: ATTACHMENTS_UPLOAD_ERROR,
          error: err,
        });
      });
  };

export const deleteAttachments =
  (objectId: string) => async (dispatch: any) => {
    const tkn= localStorage.getItem('ArcGISToken');
    const deleteAttachmentsUrl = `${urlConfig.reactArcgisRestUrl}/Lantern_Survey/FeatureServer/13/applyEdits`;
    const options: AxiosRequestConfig = {
      method: "POST",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        Accept:
          "text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9",
        "Access-Control-Allow-Origin": "*",
        "X-Esri-Authorization" : `${tkn}`,
        "Authorization": `Bearer ${localStorage.getItem("token")}`
      },
      data: `adds=&updates=&deletes=%5B%22%${objectId}%22%5D&gdbVersion=&rollbackOnFailure=true&useGlobalIds=false&returnEditMoment=false&trueCurveClient=true&attachments=&f=pjson`,
      url: deleteAttachmentsUrl,
    };

    try {
      dispatch({ type: ATTACHMENTS_DELELTE });
      const response: any = await axios(options);
      if (response.deleteResults.length !== 0) {
        dispatch({ type: ATTACHMENTS_DELELTE_SUCCESS, objectId: objectId });
      }
    } catch (err) {
      dispatch({ type: ATTACHMENTS_DELELTE_ERROR, error: err });
    }
  };

const initialState: any = {
  Attachments: [],
};

export default function reducer(state = initialState, action: any) {
  switch (action.type) {
    case ATTACHMENTS_LOADING:
      return {
        ...state,
        Attachments: [],
      };

    case ATTACHMENTS_LOADING_SUCCESS:
      return {
        ...state,
        Attachments: [...state.Attachments, ...action.Attachments],
      };

    case ATTACHMENTS_LOADING_ERROR:
      return {
        ...state,
        error: action.error,
      };

    case ATTACHMENTS_UPLOAD_SUCCESS:
      return {
        ...state,
        Attachments: [...state.Attachments, action.fileData],
      };

    case ATTACHMENTS_UPLOAD_ERROR:
      return {
        ...state,
        error: action.error,
      };

    case ATTACHMENTS_CLEAR_ERRORS:
      return {
        ...state,
        error: "",
      };

    case ATTACHMENTS_UPDATE_ARCGIS_ERROR:
      return {
        ...state,
        error: action.error,
      };

    case ATTACHMENTS_DELELTE_SUCCESS:
      return {
        ...state,
        Attachments: state.Attachments.filter((item: any) => item.objectId === action.objectId),
      };

    case ATTACHMENTS_DELELTE_ERROR:
      return {
        ...state,
        error: action.error,
      };

    default:
      return state;
  }
}
