import React, { useState, useEffect, useRef } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Slideout from 'common/Slideout/Slideout';
import Dropdown from 'common/Dropdown/Dropdown';
import GridSearch from 'common/GridSearch/GridSearch';
import GridTable from 'common/Tables/GridTable/GridTable';
import GreyLine from 'common/GreyLine/GreyLine';
import Button from 'common/Button/Button';
import LoadingWheel from 'common/LoadingWheel/LoadingWheel';
import Map from 'common/Map/Map';
import NotificationMessage from 'common/NotificationMessage/NotificationMessage';
import TextInput from 'common/TextInput/TextInput';
import FeatureLayer from 'common/Map/MapFeatureLayer';
import {
  SET_GEOMETRY,
  SET_GRID_TYPE,
  surveyAreaMapType
} from 'common/Map/MapDucks';
import {
  GenerateSingleField,
  validateData,
  shouldGenerate,
  formatData,
  surveyAreaOperations,
  sortFieldsByDisplayName,
  requiredAsterisk} from 'utils/FieldsUtils';
import {
  getAllLayerIds,
  filterNonGridPolygonTypes
} from 'utils/GridUtils';
import { promiseOptions } from 'features/SurveyArea/SurveyAreaUtils';
import {
  SET_VIEW,
  postCreateSurveyArea,
  CLEAR_ERROR,
  CLEAR_STATUS_MESSAGE
} from '../../SurveyAreaDucks';
import { surveyAreaTabValue } from '../SurveyAreaTab';
import '../../SurveyArea.scss';
import './SurveyAreaCreation.scss';
import SidePanel from "../../../../styled_components/SidePanel/SidePanel";

const SurveyAreaCreation: React.FunctionComponent = () => {

  const dispatch = useDispatch()
  const selectedMapService = useSelector((state:any) => state.LoginReducer.selectedMapService)
  const surveyAreaReducer = useSelector((state:any) => state.SurveyAreaReducer)
  const loadingReducer = useSelector((state:any) => state.LoadingReducer)
  const fieldSettings = useSelector((state:any) => state.SettingsReducer.settings)
  const layerSettings = useSelector((state:any) => state.SettingsReducer.layers)
  const layers = useSelector((state:any) => state.SettingsReducer.settings.Layers)
  const surveyAreaAlias = useSelector((state:any) => state.SettingsReducer.globalAliases.LanternSurveyArea)
  const {selectedGrids, activeGridType} = useSelector((state:any) => state.SurveyAreaMapReducer)
  let polygonTypes:Array<string> = layerSettings.GridLayers
  polygonTypes = filterNonGridPolygonTypes(polygonTypes).map((type:any) => type.Name || type.name)
  const [displaySlideout, setDisplaySlideout] = useState(false)
  // NOTE: currently setting the first type as default, to be changed to a controlled variable
  const [details, setDetails] = useState({'SurveyAreaName':'', 'PolygonType':polygonTypes[0]})
  const [validData, setValidData] = useState(false)
  const [fields, setFields] = useState([])
  const [error, setError] = useState<string>('')
  const notifRef = useRef<any>()
  let selectedMapServiceUrl = selectedMapService.url
  const [isSidePanelVisible, setIsSidePanelVisible] = useState(true);
  const noMatchingTableError = `${surveyAreaAlias} name not found. ` + 
  `Check if table name matches global alias for LanternSurveyArea in Admin (currently ${surveyAreaAlias}).`

  useEffect(() => {
    generateFields()
    dispatch({type:SET_GRID_TYPE + surveyAreaMapType, gridType:details.PolygonType})
    setTimeout(() => {
      setDisplaySlideout(true)
    }, 100);

    return (() => {
      dispatch({type: CLEAR_STATUS_MESSAGE})
    })
  }, []);

  useEffect(() => {
    if (surveyAreaReducer.error.type === "post") {
      dispatch({type: CLEAR_ERROR})
      notifRef.current.showNotif()
    }
  }, [dispatch, surveyAreaReducer.error])

  /**
   * Summary: generate the fields from fetched data
   *
  */
  function generateFields() {
    let newFields = sortFieldsByDisplayName(
      fieldSettings.Tables, 
      surveyAreaAlias, 
      setError, 
      noMatchingTableError
    )

    // populate default values
    newFields.forEach((field:any) => {
      if (field.AliasName !== "Polygon Type" && field.AliasName !== "Survey Area Name") {
        updateState(field.DefaultValue, field.Name)
      }
    })
    // set the generated fields in the state
    setFields(newFields)
    validateCreateData()
  }

  /**
   * Summary : Changes redux tab value to Survey Area Creation tab value
   *
   */
  function existingAreaClick () {
    dispatch({type: SET_VIEW, tabValue: surveyAreaTabValue.existingTab})
  }

  /**
   * summary: updates the dynamic field with the user input
   *
   * @param value value to set the target field
   * @param fieldName variable used to set any field dynamically
   *              (as any) is used to avoid typescript errors
   */
  function updateState (value : any, fieldName : any) {
    let newDetails = details
    // convert state into type compatible with object indices
    let index:keyof typeof newDetails = fieldName
    // updates the value, automatically adds as a new field if it does not exists
    newDetails[index] = value
    setDetails(newDetails)
    validateCreateData()
  }

  /**
   * summary: updates the state of selectedGrids after user performs
   *          a grid operation
   *
   * @param selectedGrid the grid to perform the operation with
   * @param operation the type of operation to perform (add/remove)
   */
  function updateCreateGrids(selectedGrid: string, operation: string) {
    // update the selected grids
    dispatch({
      type: operation + '_GEOMETRY' + surveyAreaMapType,
      grids: selectedGrid,
      duplicationCheck: true
    })
    validateCreateData()
  }

  /**
   * Summary: validates the data to ensure all required fields are filled
   *
   */
  function validateCreateData () {
    let valid = validateData(details, fields, surveyAreaMapType)
    setValidData(valid)
  }

  return(
    <div className="survey-creation-container">
      <NotificationMessage
        ref = {notifRef}
        boxColor="failure"
        symbol="exclamation"
      >
      {error || ''}
      </NotificationMessage>
      <Map 
        showToolBar={true}
        mapType={surveyAreaMapType}
        isSidePanelVisible={false}
      >
        {/* Survey Area to include all layers in Layers instead of just GridLayers */}
        {getAllLayerIds().map((mapServiceId)=> {
          const displayFieldName = layers[mapServiceId].DisplayFieldName

          return (
            <FeatureLayer
              key={mapServiceId}
              displayFieldName={displayFieldName}
              featureLayerProperties={{
                url:`${selectedMapServiceUrl}/${mapServiceId}`
              }}
            />
          )
        })}
      </Map>
      <SidePanel
        title="New Survey Area"
        cancel={existingAreaClick}
        isSidePanelVisible = {isSidePanelVisible}
      >
        {loadingReducer.POST?
        <LoadingWheel/>:
        (<div className="slideout-content">
          <div className="input-fields">
            <div className="input-title">
              Name
              {requiredAsterisk()}
            </div>
            <TextInput
              onChange={(e:any)=>updateState(e.target.value, 'SurveyAreaName')}
              placeholder="New Survey"
              maxLength={50}
            />
            <div className="input-title">Polygon Type</div>
            <Dropdown
              id = "surveycreation_polygontype_select"
              handleChange={(val)=>{
                updateState(val, 'PolygonType');
                if (activeGridType !== val) {
                  dispatch({type: SET_GEOMETRY + surveyAreaMapType, grids: []})
                }
                dispatch({type: SET_GRID_TYPE + surveyAreaMapType, gridType: val})
              }}
              defaultValue={{value:details.PolygonType, label:details.PolygonType}}
              items={polygonTypes}
            />
          </div>
          <GreyLine/>
          <div className="input-fields">
            <GridSearch
              id = 'surveycreation_grid_select'
              selectedGrids={selectedGrids}
              handleChange={updateCreateGrids}
              mapType={surveyAreaMapType}
              asyncOptionCallback={(e:any)=>promiseOptions(e, activeGridType)}
              isEditable={true}
            />
          </div>
          <GreyLine/>
          <div className="input-fields">
            <div className="input-title attributes-title">Attributes</div>
            <GridTable>
              {fields.map((field:any, i:number) => {
                if (field.VisibleOnCreate && shouldGenerate(field)) {
                  return (
                    <tr key={i}>
                      <th>
                        {field.AliasName}
                        {requiredAsterisk(field)}
                      </th>
                      {GenerateSingleField(field, updateState, details, true)}
                    </tr>
                  );
                } else {
                  return null
                }
              })}
            </GridTable>
            <div className='survey-area-error'>
              {error}
            </div>
            <div className="slideout-btn">
              <Button
                boxColor="white"
                size="s"
                onClick={existingAreaClick}
              >
                Cancel
              </Button>
              <Button
                boxColor="blue"
                size="l"
                onClick={()=>dispatch(postCreateSurveyArea(formatData([selectedGrids, details], surveyAreaOperations.create)))}
                isDisabled={(loadingReducer.POST || !validData || selectedGrids.length === 0)}
              >
                Create Survey Area
              </Button>
            </div>
          </div>
        </div>)
        }
      </SidePanel>
    </div>
  )
}

export default SurveyAreaCreation
