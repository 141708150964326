import React from 'react';

const RowComponents = ({
  page,
  prepareRow,
  headerGroups,
  onRowClick
}:any) => {
  if(page.length > 0) {
    return page.map((row:any) => {
      let rowProps = {}
      let enterKeyCode = 13
      if(onRowClick){
        rowProps = {
          onClick: () => onRowClick(row),
          onKeyDown: (e:any) =>{
            if(e.keyCode === enterKeyCode){
              onRowClick(row)
            }
          },
        }
      }
      prepareRow(row)
      let rowIndex = 0;
      return (
        <tr
          {...row.getRowProps(rowProps)}
        >
          {row.cells.map((cell:any,index:number) => {
            let tableCell = null
            if(headerGroups[0].headers[index].showColumn !== false){
              tableCell =
                <td className = {`col-${rowIndex}`} {...cell.getCellProps()}>
                  {cell.render('Cell')}
                </td>
              rowIndex++;
            }
            return tableCell
          })}
        </tr>
      )
    })
  }
  else {
    let tableColumnLength = 0 ;
    if (headerGroups[0]){
      for(let index = 0; index < headerGroups[0].headers.length; index++) {
        if(headerGroups[0].headers[index].showColumn !== false){
          tableColumnLength++
        }
      }
    }
    return <tr><td id = 'empty-table' colSpan = {tableColumnLength}>No Results Available</td></tr>
  }
}

export default RowComponents
