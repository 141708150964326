import React from 'react';
import '../../Map.scss';
import { useDispatch, useSelector } from 'react-redux';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { widgetTypes, IWidgetProps } from '../../MapWidgetLayer';
import { SET_SIDE_NAV } from 'app/SettingsDucks';
import {
  mapPageMapType,
  SHOW_SIDE_PANEL,
  CLOSE_SIDE_PANEL,
  SET_ACTIVE_TOOL,
  SET_ACTIVE_SLIDEOUT_TOOL
} from '../../MapDucks';
import { LanternTools } from 'app/constants';
import { isToolAvailable } from 'utils/IdentityManagerUtils';
import { getReducer } from 'utils/ReduxUtils';

export const InfoWidget = ({
  currentTool,
  isSidePanelVisible,
  sketchWidget,
  unselectWidget,
  measureWidget,
  mapType,
  toggleSidePanel
}:IWidgetProps) => {
  let dispatch = useDispatch()
  const currentReducer = useSelector((state:any) => state[getReducer(mapType)])
  const roleId = useSelector((state:any) => state.LoginReducer.selectedUserRole)

  function handleClick() {
    if (currentTool === widgetTypes.info) {
      if (currentReducer.slideOutInfo.reopenOverview && mapType === mapPageMapType) {
        toggleSidePanel(true);
        dispatch({type: SET_ACTIVE_TOOL + mapType, tool: widgetTypes.overview})
        dispatch({type: SET_ACTIVE_SLIDEOUT_TOOL + mapType, tool: widgetTypes.overview})
      } else {
        toggleSidePanel(!isSidePanelVisible);
        sketchWidget?.cancel()
        unselectWidget?.cancel()
        measureWidget?.clear()
        dispatch({type: SET_ACTIVE_TOOL + mapType, tool: widgetTypes.info})
        dispatch({type: SET_ACTIVE_SLIDEOUT_TOOL + mapType, tool: widgetTypes.info})
        dispatch({type: CLOSE_SIDE_PANEL + mapType})
      }
    }
    else {
      toggleSidePanel(true);
      sketchWidget?.cancel()
      unselectWidget?.cancel()
      measureWidget?.clear()
      // close side nav when opening Info Tool
      dispatch({type: SET_SIDE_NAV, isSideNavOpen: false})
      dispatch({type: SET_ACTIVE_TOOL + mapType, tool: widgetTypes.info})
      dispatch({type: SET_ACTIVE_SLIDEOUT_TOOL + mapType, tool: widgetTypes.info})
      dispatch({type: SHOW_SIDE_PANEL + mapType})
    }
  }
  if(isToolAvailable(LanternTools.INFO,roleId)) {
    return (
      <button
        title="Info"
        id="info-btn"
        className={"widget-btn" + (currentTool === widgetTypes.info && isSidePanelVisible ? " btn-active" : "")}
        onClick={handleClick}
      >
        <InfoOutlinedIcon/>
      </button>
    )
  }
  else {
    return null
  }
}
