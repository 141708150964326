import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import Button from 'common/Button/Button';
import ErrorPage from 'common/ErrorPage/ErrorPage';
import RegularTable from 'common/Tables/PaginatedTable/RegularTable/RegularTable';
import { getColumnConfig } from 'common/Tables/PaginatedTable/TableUtils/TableConfigUtils';
import Loading from 'common/Loading/Loading';
import { formatData, surveyAreaOperations } from 'utils/FieldsUtils';
import FixedColumnsConfig from 'features/SurveyArea/SurveyAreaTabs/ExistingArea/tableConfig';
import { surveyAreaTabValue } from 'features/SurveyArea/SurveyAreaTabs/SurveyAreaTab';
import { renderArchiveModal } from 'features/SurveyArea/SurveyAreaUtils';
import {
  SET_VIEW,
  postArchiveSurveyArea,
  getExistingAreasTableConfigData,
  getExistingAreas,
  CLEAR_ERROR,
  surveyAreaDefaultStatusOptions,
  SET_SURVEY_AREA_ON_MOUNT_STATUS_OPTIONS
} from 'features/SurveyArea/SurveyAreaDucks';
import './ExistingArea.scss';
import { isEqual, isUndefined } from 'lodash';

//TODO: Make dynamic
import { statusTypes } from "common/Tables/PaginatedTable/TableUtils/PredefinedFilterTemplates/StatusTypes";
import { inherits } from 'util';
export const surveyAreaStatuses = {
  locked:  statusTypes.locked,
  unlocked: statusTypes.unlocked,
  historical: statusTypes.historical
}

const ExistingAreas = (props:any) => {
  const dispatch = useDispatch()

  const surveyWorkTypes = useSelector((state:any)=>state.LoginReducer.surveyWorkTypes)
  const existingAreasTableConfigData = useSelector((state:any)=>state.SurveyAreaReducer.existingAreasTableConfigData)
  const existingAreas = useSelector((state:any)=>state.SurveyAreaReducer.existingSurveyArea)
  const loading = useSelector((state:any)=>state.LoadingReducer.EXISTING_AREA)
  const error = useSelector((state:any) => state.SurveyAreaReducer.error)
  const fixedColumnsConfig = FixedColumnsConfig(updateArchive)
  const [tableConfig, setTableConfig] = useState<any>(fixedColumnsConfig)
  const [selectedStatusFilterOptions, setSelectedStatusFilterOptions] = useState(surveyAreaDefaultStatusOptions)

  // on the first render (either for the page or by an api call), gets the selected status options
  const onMountStatusOptions = useSelector((state: any) => state.SurveyAreaReducer.onMountStatusOptions)

  // selectedStatusFilterOptions are always default except one instance where a new api call is made for Historical data.
  useEffect(() => {
    if (!isUndefined(onMountStatusOptions) && !isEqual(selectedStatusFilterOptions, onMountStatusOptions)) {
      setSelectedStatusFilterOptions(onMountStatusOptions)
      // immeditaely clean up the redux state s.t. while switching back to this page, selected status options are back to default.
      dispatch({
        type: SET_SURVEY_AREA_ON_MOUNT_STATUS_OPTIONS,
        onMountStatusOptions: undefined
      })
    }
  }, [onMountStatusOptions])
  
  useEffect(() => {
    dispatch(getExistingAreasTableConfigData(surveyWorkTypes[0]))
    dispatch(getExistingAreas())
    // clear error on unmount
    return () => {dispatch({type: CLEAR_ERROR})}
  }, [])

  // get FE table config (tableConfig), based on BE table config data (existingAreasTableConfigData)
  useEffect(() => {
    if (existingAreasTableConfigData) {
      const columnsConfig = existingAreasTableConfigData.map((columnData:any)=>{
        // existingStatusFilterOptions populates the defaultFilters which preselect the filter -> status  options
        return getColumnConfig(columnData, selectedStatusFilterOptions)
      })
      setTableConfig([...columnsConfig, ...fixedColumnsConfig])
    }
  }, [existingAreasTableConfigData, selectedStatusFilterOptions])

  //Changes redux tab value to Survey Area Creation tab value
  function createSurveyAreaClick () {
    dispatch({type:SET_VIEW,tabValue:surveyAreaTabValue.createTab})
  }

  /**
   * summary: displays a notification that the survey area has been archived
   *
   * @param {string} cellValue details of the row we are
   */
  function updateArchive (cellValue:any) {
    let objectid = cellValue.value.objectId
    dispatch(
      postArchiveSurveyArea(
        formatData(objectid, surveyAreaOperations.archive),
        objectid,
        cellValue.row.values.surveyAreaName
      )
    )
  }

  if (loading) {
    return <Loading/>
  }
  else if (error.type === 'fetchExisting') {
    return <ErrorPage errorObject={error}/>
  }
  else {
    return (
      <div id = "existing-area-container">
        <RegularTable
          filterBtnLabel = 'Filter Areas'
          data = {existingAreas}
          columns = {tableConfig}
          actionBar={
            <Button
              className = "create-btn"
              boxColor = "blue"
              size = "s"
              onClick = {createSurveyAreaClick}
            >
              Create Survey Area
            </Button>}
          tableColorTheme = 'SteelGreyTopBorder'
          // Forcing the filtering options to be toggled upon new API call
          defaultFilterBtnToggleOverride = {!isEqual(selectedStatusFilterOptions, surveyAreaDefaultStatusOptions)}
        />
      </div>
    )
  }
}

export const ButtonCell: React.FunctionComponent<any> = (props) => {
    return (
      <span className = "row-btn-container">
        <NavLink
          className = "edit-link"
          to = {window.location.pathname + "/EditArea?id=" + props.data.row.original.id.surveyAreaID}
          onClick={(e) => {
            if (props.data.row.original.Status === surveyAreaStatuses.locked || props.data.row.original.Status === surveyAreaStatuses.historical ) {
              e.preventDefault()
            }
          }}
        >
          <Button
            onClick = {() => null}
            className = "edit-btn"
            boxColor = "grey-border"
            size = "s"
            isDisabled = {[surveyAreaStatuses.historical, surveyAreaStatuses.locked].includes(props.data.row.original.Status)}
          >
            <EditIcon
              className = "edit-icon"
            />
            <span>Edit</span>
          </Button>
        </NavLink>
        {renderArchiveModal(
          <Delete style = {{color: 'inherit', fill: 'currentcolor'}} className="archive-btn"/>,
          `${props.data.row.original.SurveyAreaName}`,
          () => props.archive(props.data.cell),
          "s",
          props.data.row.original.Status
        )}
      </span>
    )
}

export default ExistingAreas
