/**
 * reducer to handle all loading flags in the application
 *
 * HOW TO ADD A NEW LOADING FLAG
 *  1. create three variables in the corresponding reducer:
 *        [REQUEST]_LOADING
 *        [REQUEST]_SUCCESS
 *        [REQUEST]_ERROR
 *     where [REQUEST] is replaced with the name of the request to load
 *
 *  2. loadingReducer will automatically set the request's loading flag to true
 *     when [REQUEST]_LOADING is dispatched, it will set the request's loading
 *     flag to false when [REQUEST]_SUCCESS or [REQUEST]_ERROR is dispatched.
 *     success and error handlers must be implemented manually in the corresponding
 *     reducer.
 *
 *  3. to check if a request is loading, access its loading flag with
 *     useSelector((state:any) => state.LoadingReducer.[REQUEST])
 *
 */
export default function loadingReducer (state = {}, action:any) {
  const { type } = action;
  const matches = /(.*)_(LOADING|SUCCESS|ERROR)/.exec(type);

  // ignore requests that does not match *_LOADING / *_SUCCESS /  *_ERROR actions
  if (!matches) return state;

  const [, requestName, requestState] = matches;

  return {
    ...state,
    // return true if request is loading
    [requestName]: requestState === 'LOADING',
  };
};
