import React from 'react';
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { withStyles, createStyles } from '@material-ui/styles';

//Material style api for tab container
const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #d0d2d3',
    paddingLeft:50,
    height: '48px',
  },
  indicator: {
    backgroundColor: '#0088c1',
  },
})(Tabs);

//Material style api for a tab
const AntTab = withStyles(() =>
  createStyles({
    root: {
      color:'#929497',
      minWidth: 72,
      fontFamily:'inherit',
      fontWeight:600,
      textTransform: 'none',
      '&:hover': {
        color: '#929497',
        opacity: 1,
      },
      '&$selected': {
        color: '#0088c1',
      },
      '&:focus': {
        color: '#0088c1',
      },
    },
    selected: {},
  }),
)((props: any) => <Tab disableRipple {...props} />);

interface IOverriddenTabsProp {
  labels : string []
  tabValue : number
  handleTabValue : (newValue:number)=>void
}

const OverriddenTabs: React.FunctionComponent<IOverriddenTabsProp> = (props) => {

  /**
   * Summary: Handles the click of a tab and sends the newValue to parent component
   * through handeTableValue
   *
   * @param e event callback
   * @param newValue tabvalue that has been clicked
   */
  const handleChange:Function = (e:any, newValue:number) => {
    props.handleTabValue(newValue)
  }

  return (
      <AntTabs value={props.tabValue} onChange={(e,newValue)=>handleChange(e,newValue)}>
        {props.labels.map((labels:string,index:number)=>
          <AntTab key={index} label={labels}></AntTab>
        )}
      </AntTabs>
    )
}

export default OverriddenTabs
