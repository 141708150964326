import React from 'react';
import './CheckBox.scss'

interface ICheckBoxProp {
  boxColor: "grey" | "steel-grey" | "white" | "disabled-theme",
  inputRef?: any
  isChecked?: boolean
  isDisabled?: boolean
  className?: string
  onChange?: any
}

const CheckBox: React.FunctionComponent<ICheckBoxProp> = ({
  boxColor,
  className = '',
  isChecked,
  isDisabled = false,
  onChange,
  inputRef,
  children
}) => {
  return(
    <span id="checkbox-component">
      <label
        className = {"checkbox-container" + (isDisabled ? " disabled" : "")}
        onClick = {(e) => {e.stopPropagation()}}
      >
        <input
          className = {"checkmark-input " + className}
          type = "checkbox"
          checked = {isChecked}
          onChange = {(e)=>onChange(e)}
          ref = {inputRef}
          disabled = {isDisabled}
        />
        <span className={"box "+ boxColor}></span>
        {children}
      </label>
    </span>
    )
}

export default CheckBox;
