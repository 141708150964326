import React from 'react';
import PopupModal from 'common/PopupModal/PopupModal';
import {ReactComponent as RemoveSurveyorIcon} from 'assets/RemoveSurveyorIcon.svg';
import './UnassignButton.scss'

interface UnassignButtonI {
  itemName: string,  // name of what is being unassigned, should be in all lowercase
  unassign: () => void,
  useTextButton?: boolean,  // whether the button should be a text button (a button that says "Unassign")
  multiple?: boolean,
}

// note: this assumes the plural of itemName is itemName + "s"


const UnassignButton = ({
  itemName,
  unassign,
  useTextButton = false,
  multiple = false,
}:UnassignButtonI) => {

  const getTitleCase = (word: string) => {
    return word.charAt(0).toUpperCase() + word.substr(1)
  }

  const getConfirmationMessage = () => {
    return `Are you sure you would like to unassign ${multiple?"these":"this"} ${itemName}${multiple?"s":""}?`
  }

  return (
    <PopupModal 
      title = {`Unassign ${getTitleCase(itemName)}${multiple?"s":""}`}
      openBtn = {
        useTextButton ?
        <div>
          Unassign
        </div> :
        <div className = "remove-icon">
          <RemoveSurveyorIcon/>
        </div>}
      useOpenText = {!useTextButton}
      openButtonSize="m"
      openButtonColor = {useTextButton?'blue':'white'}
      message = {getConfirmationMessage()}
      confirmBtn = 'Confirm'
      confirmClick = {() => unassign()}
      cancelBtn = 'Cancel'
    />
  )
}

export default UnassignButton;
