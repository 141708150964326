import React, { useState, useEffect, useRef } from "react";
import { 
  Container, 
  Title, 
  PanelHeader, 
  PanelBody, 
  StyledTitleContainer, 
  StyledActionsContainer,
  StyledSpan } from "./SidePanel.styles";
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from "@material-ui/icons/Edit";
import ResizeIcons from './ResizeIcons';
import { Size } from './ResizeIcons';

type SidePanelProps = {
  children: React.ReactNode;
  title: string;
  isSidePanelVisible: boolean;
  icon?: object | null;
  isResizable?: boolean;
  forcedActiveSize?: Size | null;
  className?:string;
  customClass?:string;
  edit?: (()=>void) | null
  cancel: ()=>void
  setForcedActiveSize?: (size:Size | null)=>void
};

const SidePanel = ({
  children,
  title,
  isSidePanelVisible,
  icon,
  forcedActiveSize,
  customClass,
  edit, 
  cancel,
  setForcedActiveSize
}: SidePanelProps) => {
  const [activeSize, setActiveSize] = useState(isSidePanelVisible ? Size.small : Size.invisible);
  const [containerHeight, setContainerHeight] = useState("100%")
  const prevActiveSize = useRef(Size.small);  // the last non-invisible active size
  const containerRef = useRef<HTMLDivElement>(null);

  const editBtn = () => {
    if (edit) {
      edit()
    }
  }
 
  useEffect(() => {
    if (containerRef.current) {
      setContainerHeight(`calc(100vh - ${containerRef.current.getBoundingClientRect().top}px)`)
    }
  })

  useEffect(() => {
    if (activeSize !== Size.invisible) {
      prevActiveSize.current = activeSize;
    }
  }, [activeSize])

  useEffect(() => {
    setActiveSize(isSidePanelVisible ? prevActiveSize.current : Size.invisible)
  }, [isSidePanelVisible]);

  useEffect(() => {
    if (isSidePanelVisible && forcedActiveSize && setForcedActiveSize) {
      setActiveSize(forcedActiveSize)
      setForcedActiveSize(null)
    }
  }, [forcedActiveSize, isSidePanelVisible])

  return (
    <Container width={activeSize} height={containerHeight} className={customClass} ref={containerRef}>
       <PanelHeader>
        <Title>
          <StyledTitleContainer>{icon} <p>{title}</p></StyledTitleContainer> 
          <StyledActionsContainer>
            <ResizeIcons
              activeSize={activeSize}
              setActiveSize={setActiveSize}
            /> 
            <StyledSpan>{edit?<EditIcon className="edit-button" onClick={editBtn} />:""}</StyledSpan>
            <CloseIcon className="cancel-button" onClick={() => cancel()} />
          </StyledActionsContainer>
        </Title>
      </PanelHeader>
      <PanelBody> 
        {children}
      </PanelBody>
    </Container>
  );
};

export default SidePanel;
