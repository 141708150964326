import { HttpRequest } from "../httpRequest";
import { NEW_API_URL } from "api/constants"
import { encodeUriAll } from "utils/UrlUtils";

export const getUserValidate = async(encodedEmail: any) => {
    const  getUserValidateUrl = (`/v1/users/validate/${encodedEmail}`);
    return await HttpRequest.get(NEW_API_URL, getUserValidateUrl, undefined, undefined, true);
};

export const getUserRoles  = async(userResponse: any) => {
    const  getUserRolesUrl = (`/v1/roleusers/${userResponse.data.id}/roles`);
    return await HttpRequest.get(NEW_API_URL, getUserRolesUrl, undefined, undefined, true);
};

export const getSurveyWorktype  = async(mapServiceName: string) => {
    const  getSurveyWorktypeUrl = (`/v1/surveyworktype/${mapServiceName}`);
    return await HttpRequest.get(NEW_API_URL, getSurveyWorktypeUrl);
}; 

export const getArcGISToken= async()=>{
    const getArcGISTokenURl=(`/v1/arcgistoken`)
    const res= await HttpRequest.get(NEW_API_URL,getArcGISTokenURl,undefined, undefined, false)
    localStorage.setItem("ArcGISToken", res.data.token)
    localStorage.setItem("ArcGISTokenExpiry", res.data.expiryTimeUTC)
    return res
}