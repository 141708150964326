import React, { useEffect, useState } from 'react';
import './SideNavLink.scss';
import { useDispatch, useSelector } from 'react-redux';
import NavLink from "common/NavLinkWrapper/NavLinkWrapper";
import { history } from 'index';
import Dropdown, { blankLabel } from 'common/Dropdown/Dropdown';
import CompanyLogo from '../../assets/janaLogo.png';
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import MapIcon from '@material-ui/icons/Public';
import SurveyAreaIcon from '../../assets/SurveyAreaIcon.svg';
import SurveyInitiationIcon from '@material-ui/icons/AssignmentTurnedIn';
import SurveyAssignmentIcon from '@material-ui/icons/Assignment';
import QueueProcessingIcon from '@material-ui/icons/AllInbox';
import WorkloadManagementIcon from '@material-ui/icons/SupervisorAccount';
import SurveyReviewIcon from '@material-ui/icons/FindInPage';
import AdminIcon from '@material-ui/icons/Settings';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {ReactComponent as LanternLogo} from '../../assets/LanternSoloInverseLogo.svg';
import {ReactComponent as LanternIcon} from '../../assets/LanternIcon.svg';
import { SET_SIDE_NAV } from 'app/SettingsDucks';
import { RESET_LOGIN ,SET_SELECTED_USER_ROLE} from 'features/Login/LoginDucks';
import { LanternTools } from 'app/constants';
import { isToolAvailable } from 'utils/IdentityManagerUtils';

export interface ISideNavProp {
  settingClicked: boolean
  handleNonSettingNavLinkClick: (...args: any[]) => void
  handleSideNavCollapse: (...args: any[]) => void
  handleSettingClick?: (...args: any[]) => void
}

const SideNav = ({
    settingClicked,
    handleSettingClick,
    handleSideNavCollapse,
    handleNonSettingNavLinkClick
  }:ISideNavProp) => {
  const dispatch = useDispatch()
  const { isSideNavOpen } = useSelector((state: any) => state.SettingsReducer)

  /**
   * Toggles sidenav
   */
  function toggleSideNav() {
    dispatch({type: SET_SIDE_NAV, isSideNavOpen: !isSideNavOpen})
    handleNonSettingNavLinkClick();
    handleSideNavCollapse();
  }


  return(
    <div className={"sidenav-container" + (isSideNavOpen?'':' hidden')}>
      <SideNavHeader
        isSideNavOpen={isSideNavOpen}
        toggleSideNav={toggleSideNav}
        handleNonSettingNavLinkClick={handleNonSettingNavLinkClick}
      />
      <NavContainer
        isSideNavOpen={isSideNavOpen}
        handleSettingClick={handleSettingClick}
        handleNonSettingNavLinkClick={handleNonSettingNavLinkClick}
        settingClicked={settingClicked}
      />
    </div>
  )
}

interface ISideNavHeaderProps {
  isSideNavOpen: boolean
  toggleSideNav: any
  handleNonSettingNavLinkClick: (...args: any[]) => void
}

const SideNavHeader = ({
  isSideNavOpen,
  toggleSideNav,
  handleNonSettingNavLinkClick
}: ISideNavHeaderProps) => {
  const dispatch = useDispatch()
  const { userRoles, loggedInUser, selectedUserRole, selectRoleError } = useSelector((state:any) => state.LoginReducer)
  const { securityRoles } = useSelector((state:any) => state.SettingsReducer)
  const userID = securityRoles.Users.find((user:any) => user.UserName === loggedInUser ).UserId
  const [reset, setReset] = useState(false)

  useEffect(() => {
    if (selectRoleError){
      setReset(true)
    }
  }, [selectRoleError])

  /**
   * Summary: Data is massaged into a format that will work with the dropdown
   */
  const massageUserRoles = () => {
    if (userRoles){
      return userRoles.map((role:any) =>{
        return {
          label: role.RoleName,
          value: role.RoleId
        }
      })
    } else {
      return []
    }
  }

  const massageSelectedUserRole = () => {
    if (userRoles && selectedUserRole){
      return userRoles.map((role:any) => {
        if (role.RoleId === selectedUserRole) {
          return {
            label: role.RoleName,
            value: role.RoleId
          }
        }
      });
    } else {
      return {
        label: blankLabel,
        value: blankLabel
      };
    }
  }

  const handleUserRoleDropdownChange = (dropdownValue:string) => {
    setReset(false);
    history.push('/Map');
    dispatch({type:SET_SELECTED_USER_ROLE, userRole:dropdownValue});
    handleNonSettingNavLinkClick();
  }

  const getRoleName = (roleId:string) => {
    return securityRoles.Roles.find((role:any) => role.RoleId === roleId).RoleName
  }

  return(
    <div>
      <div className="lantern-logo-container">
        {isSideNavOpen ?
          <LanternLogo className="lantern-logo"/>
          :<LanternIcon className="lantern-icon"/>
        }
      </div>
      <div className=
        {"hamburger-icon-container" + (!isSideNavOpen ?
            " collapse-hamburger":
            ""
          )
        }
      >
        {isSideNavOpen ?
          <MenuOpenIcon
            onClick={toggleSideNav}
            className={
              "collapse-sidenav" +
              (!isSideNavOpen ?
                " disabled-grey" :
                ""
              )
            }
            fontSize="inherit"
          /> :
          <MenuIcon
            onClick={toggleSideNav}
            className={
              "collapse-sidenav" +
              (!isSideNavOpen ?
                " disabled-grey" :
                ""
              )
            }
            fontSize="inherit"
          />
        }
      </div>
      {isSideNavOpen &&
        <div className="role-select-container">
        <span className='role-select-title'>
          Configuration
        </span>
        <Dropdown
          className='role-select-dropdown'
          items={massageUserRoles()}
          defaultValue={massageSelectedUserRole()}
          handleChange={(value:any) => { handleUserRoleDropdownChange(value) }}
          isReset={reset}
          forceValue={selectedUserRole}
          forceLabel={getRoleName(selectedUserRole)}
        />
      </div>
      }
    </div>
  )
}

interface INavContainerProps {
  isSideNavOpen: boolean
  settingClicked: boolean
  handleSettingClick?: (...args: any[]) => void
  handleNonSettingNavLinkClick?: (...args: any[]) => void
}

const NavContainer = ({
  isSideNavOpen,
  handleSettingClick,
  handleNonSettingNavLinkClick,
  settingClicked
}: INavContainerProps) => {
  const dispatch = useDispatch()
  let hiddenClass = isSideNavOpen ? '' : ' hidden'
  let settingClickedClass = settingClicked ? 'setting-selected' : ''
  let settingClickedReverseClass = settingClicked ? '' : 'selected'
  const { selectedUserRole, loggedInUser, userRoles } = useSelector((state:any) => state.LoginReducer)

  // reset userRole when logging out or changing map service
  // (will be overriden with api call to get selected role)
  useEffect(() => {
    return () => {
      dispatch({
        type: SET_SELECTED_USER_ROLE,
        userRole: userRoles[0].RoleId
      })
    }
  },[])

  return(
    <div className="nav-container">
      <nav>
        <NavLink
          to="/Map"
          exact
          className="nav-container-link"
          activeClassName={settingClickedReverseClass}
          onClick={handleNonSettingNavLinkClick}
          title='Map'
        >
          <MapIcon className="link-icon"/>
          <span className={"link-name" + hiddenClass}>Overview</span>
        </NavLink>
        <NavLink
          to="/SurveyArea"
          className="nav-container-link"
          activeClassName={settingClickedReverseClass}
          onClick={handleNonSettingNavLinkClick}
          isRendered = {isToolAvailable(LanternTools.SURVEY_AREA, selectedUserRole)}
          title='Survey Area'
        >
          <img
            src={SurveyAreaIcon}
            className="link-icon"
            alt="SurveyAreaIcon"
          />
          <span className={"link-name" + hiddenClass}>Survey Area</span>
        </NavLink>
        <NavLink
          to="/SurveyInitiation"
          className="nav-container-link"
          activeClassName={settingClickedReverseClass}
          onClick={handleNonSettingNavLinkClick}
          isRendered = {isToolAvailable(LanternTools.SURVEY_INITIATION, selectedUserRole)}
          title='Survey Initiation'
        >
          <SurveyInitiationIcon className="link-icon"/>
          <span className={"link-name" + hiddenClass}>Survey Initiation</span>
        </NavLink>
        <NavLink
          to="/SurveyAssignment"
          className="nav-container-link"
          activeClassName={settingClickedReverseClass}
          onClick={handleNonSettingNavLinkClick}
          isRendered = {isToolAvailable(LanternTools.SURVEY_ASSIGNMENTS, selectedUserRole)}
          title='Survey Assignment'
        >
          <SurveyAssignmentIcon className="link-icon"/>
          <span className={"link-name" + hiddenClass}>Survey Assignments</span>
        </NavLink>
        <NavLink
          to="/QueueProcessing"
          className="nav-container-link"
          activeClassName={settingClickedReverseClass}
          onClick={handleNonSettingNavLinkClick}
          isRendered = {isToolAvailable(LanternTools.QUEUE_PROCESSING, selectedUserRole)}
          title='Queue Processing'
        >
          <QueueProcessingIcon className="link-icon"/>
          <span className={"link-name" + hiddenClass}>Queue Processing</span>
        </NavLink>
        <NavLink
          to="/WorkloadManagement"
          className="nav-container-link"
          activeClassName={settingClickedReverseClass}
          onClick={handleNonSettingNavLinkClick}
          isRendered = {isToolAvailable(LanternTools.WORKLOAD_MANAGEMENT, selectedUserRole)}
          title='Workload Management'
        >
          <WorkloadManagementIcon className="link-icon"/>
          <span className={"link-name" + hiddenClass}>Workload Management</span>
        </NavLink>
        <NavLink
          to="/SurveyReview"
          className="nav-container-link"
          activeClassName={settingClickedReverseClass}
          onClick={handleNonSettingNavLinkClick}
          isRendered = {isToolAvailable(LanternTools.SURVEY_REVIEW, selectedUserRole)}
          title='Survey Review'
        >
          <SurveyReviewIcon className="link-icon"/>
          <span className={"link-name" + hiddenClass}>Survey Review</span>
        </NavLink>
      </nav>
      <div className="nav-container-company-logo">
        <img
          className={"company-logo" + (isSideNavOpen?'':' hidden')}
          src={CompanyLogo}
          alt='company logo'
        />
      </div>
      <div className="nav-container-link user-link">
        <AccountCircleIcon className="admin-icon"/>
        <span className={"username" + hiddenClass}>{loggedInUser}</span>
        <NavLink
          to="/"
          className="logout-btn"
          onClick={()=>dispatch({type:RESET_LOGIN})}
        >
          Logout
        </NavLink>
      </div>
      <div className={"nav-container-link admin-link settings " + settingClickedClass} onClick={handleSettingClick}>
        <AdminIcon className="admin-icon"/>
        <span className={"link-name" + hiddenClass}>Settings</span>
        <ArrowRightIcon className="admin-icon arrow-icon"/>
      </div>
    </div>
  )
}

export default SideNav;
