import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ActionItems from './ActionItems/ActionItems';
import JobsSummary from './JobsSummary/JobsSummary';
import ReportedIssues from './ReportedIssues/ReportedIssues';
import GreyLine from 'common/GreyLine/GreyLine';
import { mapPageMapType } from 'common/Map/MapDucks';
import {
  getAllSurveyJobCounts,
  SET_LOAD_OVERVIEW
} from 'features/MapPage/MapPageDucks';
import './OverviewSlideoutBody.scss';
import { isEqual } from 'lodash';

interface IOverviewSlideoutBody {
  mapType: string
}

const OverviewSlideoutBody = ({mapType}: IOverviewSlideoutBody) => {
  const dispatch = useDispatch();
  const { loadOverview, overviewDetails } = useSelector((state: any) => state.MapPageReducer);

  const tableList = useSelector((state: any) => state.SettingsReducer.tableMapServicesList);
  const surveyJobName = useSelector((state: any) => state.SettingsReducer.globalAliases.LanternSurveyJob);
  const tableIdForSurveyJobs = tableList.find((table: any) => table.name === surveyJobName).id;

  useEffect(() => {
    // only re/load overview details on map page render
    if (loadOverview && mapType === mapPageMapType) {
      dispatch(getAllSurveyJobCounts());
      dispatch({
        type: SET_LOAD_OVERVIEW,
        loadOverview: false
      });
    }
  }, [loadOverview])

  useEffect(() => {
    // `loadOverview` gets set prior to the redux states when changing map services
    // we need to reload the survey job counts when the redux states do get updated 
    dispatch(getAllSurveyJobCounts());
  }, [tableIdForSurveyJobs]);

  return (
    <div className={("overview-body-container " + mapType)}>
      <ActionItems
        overviewDetails={overviewDetails}
        mapType={mapType}
      />
      <JobsSummary
        overviewDetails={overviewDetails}
        mapType={mapType}
      />
      <GreyLine/>
      <ReportedIssues
        mapType={mapType}
      />
    </div>
  )
}

export default OverviewSlideoutBody
