import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import Toggle from 'common/Toggle/Toggle';
import { geometryType } from 'common/Map/SelectFeatures';
import {
  mapPageMapType,
  SET_GEOMETRY,
  SET_SEARCH_GEOMETRY,
  REMOVE_GEOMETRY
} from 'common/Map/MapDucks';
import {
  SET_REPORTED_ISSUES_DETAILS_TOGGLE,
  SET_REPORTED_ISSUES_STORE,
  SET_OVERVIEW_EXTENT,
  queryReportedIssue
} from 'features/MapPage/MapPageDucks';
import { getOffsetDate } from 'utils/DateUtils';
import { usePrevious } from 'utils/HooksUtils';

interface IIssue {
  displayName: string
  layerId: number
  mapType: string
}

const Issue = ({
  displayName,
  layerId,
  mapType
}:IIssue) => {
  const dispatch = useDispatch();
  const layers = useSelector((state: any) => state.SettingsReducer.settings.Layers);
  const { selectedPoints, selectedLines, selectedGrids } = useSelector((state: any) => state.MapPageMapReducer);
  const { dateFilterTime, store } = useSelector((state: any) => state.MapPageReducer.reportedIssuesDetails);
  const issuesConfig = useSelector((state: any) => state.MapPageReducer.reportedIssuesConfig.find((config:any) => config.DisplayName === displayName) || {});
  const issuesCount = useSelector((state: any) => state.MapPageReducer.reportedIssuesDetails.issuesDetails[displayName]?.count || 0);
  const issues = useSelector((state: any) => state.MapPageReducer.reportedIssuesDetails.issuesDetails[displayName]?.features || []);
  const toggleStatus = useSelector((state: any) => state.MapPageReducer.reportedIssuesDetails.issuesDetails[displayName]?.toggle ?? false);
  let prevIssues = usePrevious(issues);
  let prevToggleStatus = usePrevious(toggleStatus);

  useEffect(() => {
    toggleReportedIssue(toggleStatus, issues)
  }, [toggleStatus])

  useEffect(() => {
    if (prevToggleStatus) {
      toggleReportedIssue(false, prevIssues || [])
    }
    if (prevIssues){
      let prevIssue:any = prevIssues[0]
      if (prevIssue?.geometry?.type){
        dispatch(getGeometryAction(prevIssue.geometry.type, prevIssues, false))
      }
    }
  }, [dateFilterTime])

  useEffect(() => {
    if (toggleStatus) {
      checkToggleToUpdate(geometryType.point, selectedPoints)
    }
  }, [selectedPoints])

  useEffect(() => {
    if (toggleStatus) {
      checkToggleToUpdate(geometryType.polyline, selectedLines)
    }
  }, [selectedLines])

  useEffect(() => {
    if (toggleStatus) {
      checkToggleToUpdate(geometryType.polygon, selectedGrids)
    }
  }, [selectedGrids])

  /**
   * Summary: check if the toggle needs to be updated to off based on the given selectedFeatures
   * @param type geometry type of the reported issue
   * @param selectedFeatures the currently selected feature layers of a specific geometry
   */
  const checkToggleToUpdate = (type: geometryType, selectedFeatures: Array<any>) => {
    if (layerId < layers.length) {
      if (issues.length > 0) {
        let issueObjectIds = getObjectIds(issues);
        let geometryType = issues[0].geometry.type;
        let featureLayerName = issues[0].featureLayerName;
        let selectedIssuesObjectIds = issueObjectIds.filter((objectId: number) => {
          return getObjectIds(selectedFeatures, featureLayerName).includes(objectId)
        })

        if (type === geometryType && (isEmpty(selectedIssuesObjectIds))) {
          changeToggleStatus(false)
        }
      } else {
        if (isEmpty(selectedFeatures)) {
          changeToggleStatus(false)
        }
      }
    }
  }

  /**
   * Summary: add/remove the reported issue in Info Tool based on the toggle
   * @param toggle true if reported issue is toggled on, else false
   * @param issues array of features whose geometries are to be un/selected
   */
  const toggleReportedIssue = (toggle: boolean, issues: Array<any>) => {

    if (layerId < layers.length && issues.length > 0) {
      let issueObjectIds = getObjectIds(issues);
      let geometryType = issues[0].geometry.type;
      let featureLayerName = issues[0].featureLayerName;
      let featureLayerStoreIdsArr:Array<number> = [];
      let featureLayerStore = store[featureLayerName] || {};
      if(!isEmpty(featureLayerStore)){
        Object.keys(store[featureLayerName]).forEach((key:string) => {
          const numEntries = store[featureLayerName][key]
          for (let i = 0; i < numEntries; i++){
            featureLayerStoreIdsArr.push(+key)
          }
        }) 
      }

      dispatch({
        type: SET_OVERVIEW_EXTENT,
        extent: true
      })

      if (toggle) {
        featureLayerStoreIdsArr = featureLayerStoreIdsArr.concat(issueObjectIds);
        //get object where issueIds are keys, and each value is the number of toggles targeting that issueId
        const issuesWithTargetCount = featureLayerStoreIdsArr.reduce(getIssueCounts, {})

        dispatch({
          type: SET_REPORTED_ISSUES_STORE,
          layerName: featureLayerName,
          layerStore: issuesWithTargetCount,
        });
        dispatch(getGeometryAction(geometryType, issues, true));
      } else {
        // locally update store for this particular featurelayer
        let issuesNotInStore:Array<number> = []
        issueObjectIds.forEach((objectId: string) => {
          if (featureLayerStore[objectId]){
            if (featureLayerStore[objectId] === 1){
              // if value is 1, then this toggle was the only one targeting the issues
              // so we can remove these issues from the store completely
              issuesNotInStore.push(+objectId) 
              delete featureLayerStore[objectId];
            }
            else {
              featureLayerStore[objectId]--;
            }
          }
        })

        if (!isEmpty(issuesNotInStore)) {
          dispatch(getGeometryAction(geometryType, getIssuesFromIds(issues, issuesNotInStore), false));
        }

        dispatch({
          type: SET_REPORTED_ISSUES_STORE,
          layerName: featureLayerName,
          layerStore: featureLayerStore,
        });
      }
    }
  }

  /**
   * Summary: get an count of how many toggles are targeting each issue, and put that 
   * into an object
   * @param issueIds object with issue id as key
   * @param idx keys in issueIds object
   * @returns object with issueIds as key and a count as value
   */
  const getIssueCounts = (issueIds:any, idx:any) => {
    let count = prevToggleStatus === undefined ? issueIds[idx] : issueIds[idx] + 1
    issueIds[idx] = (count || 1);
    return issueIds;
  }

  /**
   * Summary: get SET_GEOMETRY or REMOVE_GEOMETRY depending on the given parameters
   * @param type geometry type of the reported issue
   * @param issues the reported issue features to be set or removed
   * @param addingFeatures true if adding reported issue features, else false if removing
   * @returns SET_GEOMETRY or REMOVE_GEOMETRY object to be used in a dispatch
   */
   const getGeometryAction = (type: geometryType, issues: Array<any>, addingFeatures: boolean) => {
    if (addingFeatures) {
      const setGeometry: any = {
        [geometryType.point]: {
          type: SET_GEOMETRY + mapPageMapType,
          points: [...selectedPoints, ...issues],
          duplicationCheck: true
        },
        [geometryType.polyline]: {
          type: SET_GEOMETRY + mapPageMapType,
          lines: [...selectedLines, ...issues],
          duplicationCheck: true
        },
        [geometryType.polygon]: {
          type: SET_GEOMETRY + mapPageMapType,
          grids: [...selectedGrids, ...issues],
          duplicationCheck: true
        }
      }

      return setGeometry[type]
    } else {
      const removeGeometry: any = {
        [geometryType.point]: {
          type: REMOVE_GEOMETRY + mapPageMapType,
          points: issues
        },
        [geometryType.polyline]: {
          type: REMOVE_GEOMETRY + mapPageMapType,
          lines: issues
        },
        [geometryType.polygon]: {
          type: REMOVE_GEOMETRY + mapPageMapType,
          grids: issues
        }
      }

      return removeGeometry[type]
    }
  }

  /**
   * Summary: get the issues whose object IDs are in the given objectIds
   * @param issues array of feature layers to filter through
   * @param objectIds array of object IDs to match for
   * @return filtered array of issue feature layers whose object ID is in objectIds
   */
  const getIssuesFromIds = (issues: Array<any>, objectIds: Array<number>) => {
    return issues.filter((issue: any) => objectIds.includes(issue.attributes.OBJECTID))
  }

  /**
   * Summary: get an array of all the object IDs of the given features array with the given featureLayerName
   * @param features array of features to get object IDs from
   * @param featureLayerName feature layer name to filter, by default do not filter
   * @returns array of object IDs in all the features in features, with featureLayerName
   */
  const getObjectIds = (features: Array<any>, featureLayerName: string = '') => {
    let objectIds: any = []

    features.forEach((feature: any) => {
      if (featureLayerName === '' || featureLayerName === feature.featureLayerName) {
        objectIds.push(feature.attributes.OBJECTID)
      }
    })

    return objectIds
  }

  /**
   * Summary: set the toggleStatus to the given newToggleStatus
   * @param newToggleStatus boolean toggle status to set the toggle to
   */
  const changeToggleStatus = (newToggleStatus: boolean) => {
    dispatch({
      type: SET_REPORTED_ISSUES_DETAILS_TOGGLE,
      issueDisplayName: displayName,
      toggleStatus: newToggleStatus
    })
  }

  return (
    <div className="issue-body-container">
      <div className="issue-title">
        {displayName}
      </div>
      <div className="issue-number">
        {issuesCount}
      </div>
      <div className="issue-toggle">
        <Toggle
          checked = {toggleStatus}
          handleChange = {() => {
            if (!toggleStatus) {
              queryReportedIssue(
                issuesConfig, 
                getOffsetDate(-1 * dateFilterTime, true, "date"), 
                displayName, 
                dispatch
              )
              .then(() => {
                changeToggleStatus(!toggleStatus)
              })
            }
            else {
              changeToggleStatus(!toggleStatus)
            }        
          }}
        />
      </div>
    </div>
  )
}

export default Issue
