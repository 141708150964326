import React from 'react';
import { useDispatch } from 'react-redux';
import { ReactComponent as PanToolIcon } from 'assets/PanToolIcon.svg';
import {
  widgetTypes,
  IWidgetProps
} from '../MapWidgetLayer';
import {
  SET_ACTIVE_TOOL,
  surveyAreaMapType,
  surveyInitiationMapType,
  surveyReviewMapType,
  styleByTool,
  styleBySlideoutTool,
  styleByMapType
} from '../MapDucks';

export const MoveWidget = ({
  currentTool,
  activeSlideoutTool,
  isSlideoutOpen,
  sketchWidget,
  unselectWidget,
  measureWidget,
  mapType,
  positionClass
}:IWidgetProps) => {
  const dispatch = useDispatch()

  function handleClick() {
    unselectWidget?.cancel()
    measureWidget?.clear()
    sketchWidget?.create(widgetTypes.move)
    dispatch({type: SET_ACTIVE_TOOL + mapType, tool: widgetTypes.move})
  }

  return (
    <button
      title="Pan"
      id="move-btn"
      className={
        "widget-btn position-btn " +
        (styleByTool[currentTool] || "") + positionClass + " " +
        (isSlideoutOpen ?
          (
            (mapType === surveyAreaMapType || mapType === surveyInitiationMapType || mapType === surveyReviewMapType) ?
              styleBySlideoutTool[activeSlideoutTool] + styleByMapType[mapType] :
              styleBySlideoutTool[activeSlideoutTool]
          ) :
          ""
        ) + ' ' + mapType.slice(1).toLowerCase().replace('_', '-') + '-widget-tools'
      }
      onClick={handleClick}
    >
      <PanToolIcon/>
    </button>
  )
}
