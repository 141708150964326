import React, { useState, useEffect, useRef } from "react";
import "./Map.scss";
import { useDispatch, useSelector } from "react-redux";
import { Map } from "@esri/react-arcgis";
import set from "lodash/set";
import NotificationMessage from "common/NotificationMessage/NotificationMessage";
import {
  getSlideoutProps,
} from "common/Map/slideoutConfig";
import {
  SET_GEOMETRY,
  SET_SEARCH_GEOMETRY,
  SET_SURVEY_REVIEW_GEOMETRY,
  SET_GRID_TYPE,
  CLOSE_SIDE_PANEL,
  SET_INFO_ZOOM_GEOMETRY,
  CLEAR_ERROR,
  SET_ACTIVE_TOOL,
} from "common/Map/MapDucks";
import WidgetLayer, { widgetTypes } from "./MapWidgetLayer";
import { equationSignsType } from "./Widgets/QueryWidget/QuerySlideoutBody";
import { getReducer } from "utils/ReduxUtils";
import SidePanel from "../../styled_components/SidePanel/SidePanel";
import { Size } from "styled_components/SidePanel/ResizeIcons";

interface ISurveyMapProps {
  showToolBar: boolean;
  mapType: string;
  disableSelectionTools?: boolean;
  // avoid the split-second render of the overview on certain pages
  isSidePanelVisible?: boolean;
}

const SurveyMap: React.FunctionComponent<ISurveyMapProps> = (props) => {
  const [prevSlideoutTool, setPrevSlideoutTool] = useState<widgetTypes>(
    widgetTypes.overview
  );
  const dispatch = useDispatch();
  const slideoutRef = useRef(null);
  const mapNotifRef = useRef<any>();
  const didMount = useRef(false);
  const defaultPolygonType = useSelector(
    (state: any) => state.SettingsReducer.layers.GridLayers[0].Name
  );
  let SettingsReducer = useSelector((state: any) => state.SettingsReducer);
  let currentReducer = useSelector(
    (state: any) => state[getReducer(props.mapType)]
  );
  let extent = SettingsReducer.settings.Extent;
  const mapPageMapReducer = useSelector((state:any) => state.MapPageMapReducer)
  const customBaseMapList = mapPageMapReducer.customBaseMapList;
  //constraints to stop users for zooming or panning outside of the world
  const zoomedOutEarthScale = 120000000;
  const fullEarthGeometry = {
    type: "polygon",
    rings: [
      [100, 68],
      [100, -68],
      [-100, -68],
      [-100, 68],
      [180, 68],
    ],
  };

  useEffect(() => {
    // clear error on unmount
    return () => {
      dispatch({ type: CLEAR_ERROR });
    };
  }, []);

  useEffect(() => {
    if (didMount.current) {
      if (currentReducer.mapNotifMessage.message !== "") {
        mapNotifRef.current.showNotif();
      }
    } else {
      didMount.current = true;
    }
  }, [dispatch, currentReducer.mapNotifMessage]);

   // get basemaps when the component mounts

  //reset selected grids when the component unmounts
  useEffect(() => {
    set(extent, "spatialReference.wkid", SettingsReducer.settings.WKID);
    return () => {
      dispatch({ type: CLOSE_SIDE_PANEL + props.mapType });
      // clear selected grids and points and reset polygon type to default
      dispatch({
        type: SET_GEOMETRY + props.mapType,
        grids: [],
        lines: [],
        points: [],
      });
      dispatch({
        type: SET_SEARCH_GEOMETRY + props.mapType,
        grids: [],
        lines: [],
        points: [],
      });
      dispatch({
        type: SET_SURVEY_REVIEW_GEOMETRY,
        grids: [],
        lines: [],
        points: [],
      })
      dispatch({
        type: SET_GRID_TYPE + props.mapType,
        gridType: defaultPolygonType,
      });
      dispatch({
        type: SET_INFO_ZOOM_GEOMETRY,
        infoGeometry: [],
        infoLayerName: "",
      });
    };
  }, [dispatch]);

  const [forcedActiveSize, setForcedActiveSize] = useState<Size | null>(null);
  const [isSidePanelVisible, setIsSidePanelVisible] = useState(props.isSidePanelVisible ?? false);
  const [query, setQuery] = useState<any>('')
  const [layerId, setLayerId] = useState<number>(0)
  const [selectedLayer, setSelectedLayer] = useState<string>('')
  const [selectedRelationshipLayer, setSelectedRelationshipLayer] = useState<string>('')
  const [selectedField, setSelectedField] = useState<any>();
  const [valueField, setValueField] = useState<string>('')
  const [selectedEquationSign, setSelectedEquationSign] = useState<equationSignsType>(equationSignsType.equals)
  const [isQueryInsertDisabled, setIsQueryInsertDisabled] = useState<boolean>(true);
  const [isQueryAddDisabled, setIsQueryAddDisabled] = useState<boolean>(true);

  function renderSlideOutBody() {
    let activeSlideoutTool = currentReducer.slideOutInfo.activeSlideoutTool;

    if (
      activeSlideoutTool !== widgetTypes.edit &&
      activeSlideoutTool !== prevSlideoutTool
    ) {
      setPrevSlideoutTool(activeSlideoutTool);
    } else {
      activeSlideoutTool = prevSlideoutTool;
    }

    const queryProps = {
      query,
      setQuery,
      layerId,
      setLayerId,
      selectedLayer,
      setSelectedLayer,
      selectedRelationshipLayer,
      setSelectedRelationshipLayer,
      selectedField,
      setSelectedField,
      valueField,
      setValueField,
      selectedEquationSign,
      setSelectedEquationSign,
      isQueryInsertDisabled,
      setIsQueryInsertDisabled,
      isQueryAddDisabled,
      setIsQueryAddDisabled
    }

    let slideoutProps = getSlideoutProps(
      dispatch,
      activeSlideoutTool,
      currentReducer,
      props.mapType,
      queryProps
    );

    return (
      <SidePanel
        customClass="common-sidepanel"
        title={slideoutProps.title}
        icon={slideoutProps.icon}
        isSidePanelVisible={isSidePanelVisible}
        forcedActiveSize={forcedActiveSize}
        setForcedActiveSize={setForcedActiveSize}
        cancel={() => {
          setIsSidePanelVisible(false);
          dispatch({type:SET_ACTIVE_TOOL + props.mapType, tool: widgetTypes.move})
          dispatch({ type: CLOSE_SIDE_PANEL + props.mapType });
        }}
      >
        {slideoutProps.body}
      </SidePanel>
    );
  }

  return (
    <div id="viewDiv" className={props.mapType}>
      <NotificationMessage
        ref={mapNotifRef}
        boxColor={
          currentReducer.mapNotifMessage.success ? "success" : "failure"
        }
        symbol={
          currentReducer.mapNotifMessage.success ? "checkmark" : "exclamation"
        }
      >
        {currentReducer.mapNotifMessage.message}
      </NotificationMessage>
      <div ref={slideoutRef}>{renderSlideOutBody()}</div>
      {/**Map component that deals with basemap*/}
      <Map
        viewProperties={{
          extent,
          zoom: 12,
          popup: {
            dockOptions: {
              buttonEnabled: false,
            },
          },
          constraints: {
            geometry: fullEarthGeometry,
            minScale: zoomedOutEarthScale,
            rotationEnabled: false,
          },
        }}
      >
        {props.children}
        <WidgetLayer
          showToolbar={props.showToolBar}
          disableSelectionTools={props.disableSelectionTools}
          mapType={props.mapType}
          slideoutRef={slideoutRef}
          toggleSidePanel={(value : boolean) => setIsSidePanelVisible(value)}
          isSidePanelVisible={isSidePanelVisible}
          setForcedActiveSize={setForcedActiveSize}
        />
      </Map>
    </div>
  );
};

export default SurveyMap;
