import axios from 'axios';
import { urlConfig } from 'index';
import { store } from 'app/store';
import { axiosErrorHandler } from "utils/AxiosUtils";

import{
  getAllJobs,
  updatePriority,
  updateRescheduleJob,
  postClearProcessedJobs
} from 'api/legacy/QueueProcessingDucks';

export const SET_VIEW = 'lantern/queueprocessing/SET_VIEW';
export const GET_JOBS_LOADING = 'GET_JOBS_LOADING';
export const GET_JOBS_SUCCESS = 'GET_JOBS_SUCCESS';
export const GET_JOBS_ERROR = 'GET_JOBS_ERROR';
export const ACTION_BTN_LOADING = 'ACTION_BTN_LOADING';
export const ACTION_BTN_SUCCESS = 'ACTION_BTN_SUCCESS';
export const ACTION_BTN_ERROR = 'ACTION_BTN_ERROR';
export const CLEAR_PROCESSED = 'lantern/queueprocessing/CLEAR_PROCESSED';
export const UPDATE_PRIORITY = 'lantern/queueprocessing/UPDATE_PRIORITY';
export const REQUEUE_JOBS = 'lantern/queueprocessing/REQUEUE_JOBS';
export const REMOVE_JOBS = 'lantern/queueprocessing/REMOVE_JOBS';
export const CLEAR_ERROR = 'lantern/queueprocessing/CLEAR_ERROR';
export const CLEAR_STATUS_MESSAGE = 'lantern/queueprocessing/CLEAR_STATUS_MESSAGE';
export const SET_STATUS_MESSAGE = 'lantern/queueprocessing/SET_STATUS_MESSAGE';
export const ERROR = 'lantern/queueprocessing/ERROR';
export const TOGGLE_SHOW_ALL_JOBS = 'lantern/queueprocessing/TOGGLE_SHOW_ALL_JOBS';
export const SET_SHOW_ALL_JOBS = 'lantern/queueprocessing/SET_ALL_JOBS';
export enum errorTypes {
  getJobsError = 'getJobsError',
  queueProcessingError = 'queueProcessingError'
}
export enum jobStatus {
  Queued = "Queued",
  Processed = "Processed",
  Error = "Error"
}

export const getJobs = () => async(dispatch:any) => {
  dispatch({type: GET_JOBS_LOADING})
  const selectedMapServiceName = store.getState().LoginReducer.selectedMapService.decodedLabel;
  // Todo: Remove this code after testing
  // const url = `${urlConfig.reactLanternServerUrl}/Configuration.ashx/schedule/queue`
  getAllJobs()
  .then(res => {
    axiosErrorHandler(res)
    let processedJobs:Array<any> = []
    let inQueueJobs:Array<any> = []
    // for any jobs that have no map service name set, make them appear for all map services
    const jobs = res.data.filter((job:any) => job.MapServiceName === selectedMapServiceName || job.MapServiceName === "")
    jobs.forEach((job:any) => {
      if(!job.Processed){
        job.Processed = jobStatus.Queued
      }else if(job.Processed === 'Y'){
        job.Processed = jobStatus.Processed
      }else{
        job.Processed = jobStatus.Error
      }

      if (job.Processed === jobStatus.Processed){
        processedJobs.push(job)
      }
      else {
        inQueueJobs.push(job)
      }
    })
    dispatch({type:GET_JOBS_SUCCESS, processedJobs: processedJobs, inQueueJobs: inQueueJobs})
  })
  .catch(err => {
    dispatch({type: GET_JOBS_ERROR})
    dispatch({
      type: ERROR,
      error: {
        message: err,
        type: errorTypes.getJobsError
      }
    })
  })
}

export const prioritize = (rowIds:any, data:any) => async(dispatch:any) => {
  dispatch({type: ACTION_BTN_LOADING})
  // Todo: Remove this code after testing
  // const url = `${urlConfig.reactLanternServerUrl}/Configuration.ashx/schedule/setPriority`

  // rowIds include newly prioritized
  let prioritized:Array<number> = rowIds;
  let unprioritized:Array<number> = [];

  // pre-existing data
  data.forEach((job:any) => {
    // 1 true, 0 false
    if (job.Priority) {
      prioritized.push(job.Id)
    } else {
      unprioritized.push(job.Id)
    }
  })

  let requestBody = {
    priorityToRowIds: `{"0":[${unprioritized}],"1":[${prioritized}]}`
  }

  updatePriority(requestBody)
  .then(res => {
    axiosErrorHandler(res)
    dispatch({type: ACTION_BTN_SUCCESS})
    dispatch({type: SET_STATUS_MESSAGE, message: 'success'})
    dispatch({type: UPDATE_PRIORITY, prioritized: rowIds})
  })
  .catch(err => {
    dispatch({type: ACTION_BTN_ERROR})
    dispatch({
      type:ERROR,
      error:{
        message:err,
        type:errorTypes.queueProcessingError
      }
    })
  })
}

export const requeue = (rowIds:any) => async(dispatch:any) => {
  dispatch({type: ACTION_BTN_LOADING})
  // Todo: Remove this code after testing
  // const url =  `${urlConfig.reactLanternServerUrl}/Configuration.ashx/schedule/reschedule?rowIds=[${rowIds}]`
  updateRescheduleJob(rowIds)
  .then(res => {
    axiosErrorHandler(res)
    dispatch({type: ACTION_BTN_SUCCESS})
    dispatch({type: SET_STATUS_MESSAGE, message: 'success'})
    dispatch({type: REQUEUE_JOBS, rowIds: rowIds})
  })
  .catch(err => {
    dispatch({type: ACTION_BTN_ERROR})
    dispatch({
      type:ERROR,
      error:{
        message:err,
        type:errorTypes.queueProcessingError
      }
    })
  })
}

export const clearProcessed = (rowIds:any) => async(dispatch:any) => {
  dispatch({type: ACTION_BTN_LOADING})
  // Todo: Remove this code after testing
  // const url = `${urlConfig.reactLanternServerUrl}/Configuration.ashx/schedule/delete?rowIds=[${rowIds}]`
  postClearProcessedJobs(rowIds)
  .then(res => {
    axiosErrorHandler(res)
    dispatch({type: ACTION_BTN_SUCCESS})
    dispatch({type: SET_STATUS_MESSAGE, message: 'success'})
    dispatch({type: REMOVE_JOBS, rowIds: rowIds})
  })
  .catch(err => {
    dispatch({type: ACTION_BTN_ERROR})
    dispatch({
      type:ERROR,
      error:{
        message:err,
        type:errorTypes.queueProcessingError
      }
    })
  })
}

const initialState:any = {
  tabValue: 0,
  processedJobs: [],
  inQueueJobs: [],
  showAllJobs: false,
  notificationMessage:'',
  error: {}
}

export default function reducer (state = initialState, action:any) {
  switch(action.type) {
    case SET_VIEW:
      return {
        ...state,
        tabValue: action.tabValue
      }

    case GET_JOBS_SUCCESS:
      return {
        ...state,
        processedJobs: action.processedJobs,
        inQueueJobs: action.inQueueJobs
      }

    case UPDATE_PRIORITY:
      let newPriorityQueuedJobs = state.inQueueJobs
      newPriorityQueuedJobs.forEach((job:any) => {
        if (action.prioritized.includes(job.Id)) {
          job.Priority = 1
        }
      })

      return {
        ...state,
        inQueueJobs: newPriorityQueuedJobs
      }

    case REQUEUE_JOBS:
      let newInQueueJobs = state.inQueueJobs
      newInQueueJobs.forEach((job:any) => {
        if (action.rowIds.includes(job.Id)) {
          job.Processed = 'Queued'
          job.ErrorMessage = ''
        }
      })

      return {
        ...state,
        inQueueJobs: newInQueueJobs
      }

    case REMOVE_JOBS:
      let newProcessedJobs:Array<any> = []
      state.processedJobs.forEach((job:any) => {
        if (!action.rowIds.includes(job.Id)) {
          newProcessedJobs.push(job)
        }
      })

      return {
        ...state,
        processedJobs: newProcessedJobs
      }

    case SET_STATUS_MESSAGE:
      return {
        ...state,
        notificationMessage: action.message
      }

    case CLEAR_STATUS_MESSAGE:
      return {
        ...state,
        notificationMessage:''
      }

    case ERROR:
      return {
        ...state,
        error: action.error
      }

    case CLEAR_ERROR:
      return {
        ...state,
        error: ''
      }

    case TOGGLE_SHOW_ALL_JOBS:
      return {
        ...state,
        showAllJobs: !state.showAllJobs
      }

    case SET_SHOW_ALL_JOBS:
      return {
        ...state,
        showAllJobs: action.showAllJobs
      }

    default:
      return state
  }
}
