/**
 * Summary: return the encoded string url
 * @param value string to be encoded
 */
export const encodeUriAll = (value: string) => {
    return value.replace(/[^A-Za-z0-9]/g, (c: string) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`)
}

/**
 * List of all pages
 */
export enum pages {
    overview = 'Map',
    surveyArea = 'SurveyArea',
    surveyInitiation = 'SurveyInitiation',
    surveyAssignment = 'SurveyAssignment',
    surveyAssignPlanDetails = 'SurveyAssignment/SurveyPlanDetails',
    querProcessing = 'QueueProcessing',
    workloadManagement = 'WorkloadManagement',
    surveyReview = 'SurveyReview',
    surveyReviewPlanDetails = 'SurveyReview/SurveyPlanDetails',
    surveyReviewJobDetails = 'SurveyReview/SurveyJobDetails'
}
