
import ashxBaseUrl from "./legacy";

export const getMapDetails = async (selectedMapServiceName: string) => {
    const getMapDetailsUrl = (`/map.ashx/details?`+
    `mapservicename=${selectedMapServiceName}&`+
    `signalr=e1186dca%2D4a8b%2D4077%2Da38e%2D8831cb0ef055&ie=739`);
    return await ashxBaseUrl.get( getMapDetailsUrl);
};

export const getAllRelationship = async (requestBody: Object, relationshipId: any) => {
    const getAllRelationshipUrl = `/Map.ashx/tables/${relationshipId.id}/query`;
    return await ashxBaseUrl.post(getAllRelationshipUrl, requestBody);
};

export const getMapConfig = async (mapServiceUrl: any) => {
    const getMapConfigUrl = (`/Configuration.ashx/configuration`+
    `?mapserviceurl=%${mapServiceUrl}`+
    `&signalr=8f1f3843-d762-4f2b-a98f-186a7cbf3087&ie=149`);
    return await ashxBaseUrl.get(getMapConfigUrl);
};

export const getMapFieldAutoValues = async (
    requestBody: Object,
    mapServiceUrl: any
    ) => {
    const getMapFieldAutoValuesUrl = `/Editing.ashx/configuration/fieldautovalues`;
    return await ashxBaseUrl.post(getMapFieldAutoValuesUrl, requestBody);
};

export const getMapNearestFeatureSettings = async (
    requestBody: Object
    ) => {
    const getMapNearestFeaturesSettingsUrl = `/Editing.ashx/configuration/nearestfeatures`;
    return await ashxBaseUrl.post(getMapNearestFeaturesSettingsUrl, requestBody);
};