import React, { useState, useEffect } from 'react';
import './SearchBar.scss'
import Clear from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';

interface ISearchBarProp {
  handleSubmit: (e : string|number)=>void
  placeHolder?: string
  hasBeenSearched?: (isSearched:boolean) => void
  isReset?: boolean
  className?: string
}

const SearchBar = ({handleSubmit,
  placeHolder,
  hasBeenSearched,
  isReset,
  className
}:ISearchBarProp) => {
  const [value,setValue] = useState<string>("")
  const [isSearched,setisSearched] = useState<boolean>(false)

  const handleChange = (event : any) => {
    setValue(event.target.value);
  }

  const clearSearch = () => {
    setValue('')
    setisSearched(false)
  }

  const onSubmit = (event : any) => {
    event.preventDefault();
    handleSubmit(value);
    setisSearched(true);
  }

  useEffect(() => {
    if (isReset) {
      setValue('')
      setisSearched(false)
    }
  }, [isReset])

  useEffect(() => {
    if(hasBeenSearched) {
      hasBeenSearched(isSearched)
    }
  },[isSearched,hasBeenSearched])

  return(
    <form className={className} onSubmit={onSubmit}>
      <span className="search-container">
        <input
          className="input-field"
          type="text"
          value={value}
          onChange={handleChange}
          placeholder={placeHolder}
        />
        {isSearched &&
        <span
          className="clear-icon"
          onClick = {clearSearch}
        >
          <Clear fontSize = 'inherit'/>
        </span>
        }
        <div className="search-icon" onClick={onSubmit}>
          <SearchIcon/>
        </div>
      </span>
    </form>
  )
}

SearchBar.defaultProps = {
  className:"",
}

export default SearchBar
