import React from 'react';
import { ButtonCell } from './ExistingArea';

const FixedColumnsConfig = (updateArchive:Function) =>
  [
    {
      Header: '',
      accessor: 'id',
      disableSortBy: true,
      disableFilters: true,
      Cell: (data:any) =>
        <ButtonCell
          data = {data}
          archive = {(val:string) => updateArchive(val)}
        />
    }
  ]


export default FixedColumnsConfig
