import React from 'react';
import './Button.scss'

export interface IButtonProp {
  boxColor: IButtonColors
  size: IButtonSizes
  onClick?: (...args: any[]) => void,
  className?: string
  isDisabled?: boolean,
}
export type IButtonSizes = "s" | "m" | "l"
export type IButtonColors = "grey" | "grey-border" | "blue" | "white" | "grey-border-dark-text"

const Button: React.FunctionComponent<IButtonProp> = (props) => {

  return(
    <button
      className={props.size + " btn " + props.boxColor + " " + props.className}
      disabled={props.isDisabled}
      onClick={props.onClick}
    >
        {props.children}
    </button>
  )
}

Button.defaultProps = {
  className: "",
  isDisabled: false
}

export default Button;
