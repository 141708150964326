/**
 * all statuses
 */
export enum statusTypes {
  planned = 'Planned',
  active = 'Active',
  completed = 'Completed',
  locked = 'Locked',
  unlocked = 'Unlocked',
  historical = 'Historical',
  inprogress = 'In Progress'
}
