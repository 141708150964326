import { store } from 'app/store';
import {
  createMapConfguration,
  createMapQueries,
  createMapQueriesDelete,
  getReportableItems
} from 'api/legacy/mapPageDucks';
import {
  mapPageMapType,
  addToInfoTables
} from 'common/Map/MapDucks';
import {
  geometryType,
  getOriginDestinationQueries,
  getOriginDestinationQueryString
} from 'common/Map/SelectFeatures';
import { widgetTypes } from 'common/Map/MapWidgetLayer';
import { axiosErrorHandler } from 'utils/AxiosUtils';
import {
  getCurrentDate,
  getOffsetDate 
} from "utils/DateUtils";
import { findFeatureFromID, getTableIdByName, removeDuplicateFeatures } from 'utils/GridUtils';
import { massageQueryData } from "utils/MapServiceUtils";
import { arcgisQueryParams, concatQueryingParameters, getArcgisQuery, postArcgisQuery } from 'api';

export const SET_SAVED_QUERIES = 'SET_SAVED_QUERIES'
export const SET_ALL_SAVED_QUERIES = 'SET_ALL_SAVED_QUERIES'
export const RESET_SAVED_QUERIES = 'RESET_SAVED_QUERIES'
export const EXECUTE_LAYER_QUERY = 'EXECUTE_LAYER_QUERY'
export const EXECUTE_RELATIONSHIP_QUERY_FEATURES = 'EXECUTE_RELATIONSHIP_QUERY_FEATURES'
export const SET_QUERY_RESULT_MSG = 'SET_QUERY_RESULT_MSG'
export const RESET_QUERY_TEXT = 'RESET_QUERY_TEXT'
export const SET_LOAD_OVERVIEW = 'SET_LOAD_OVERVIEW'
export const SET_OVERVIEW_DETAILS = 'SET_OVERVIEW_DETAILS'
export const SET_OVERVIEW_EXTENT = 'SET_OVERVIEW_EXTENT'
export const SET_LOAD_REPORTED_ISSUES = 'SET_LOAD_REPORTED_ISSUES'
export const SET_REPORTED_ISSUES_CONFIG = 'SET_REPORTED_ISSUES_CONFIG'
export const SET_REPORTED_ISSUES_DETAILS = 'SET_REPORTED_ISSUES_DETAILS'
export const SET_REPORTED_ISSUES_DETAILS_FEATURES = 'SET_REPORTED_ISSUES_DETAILS_FEATURES'
export const SET_REPORTED_ISSUES_DETAILS_TOGGLE = 'SET_REPORTED_ISSUES_DETAILS_TOGGLE'
export const SET_REPORTED_ISSUES_STORE = 'SET_REPORTED_ISSUES_STORE'
export const RESET_REPORTED_ISSUES_STORE = 'RESET_REPORTED_ISSUES_STORE'
export const SET_NEAREST_FEATURE_IS_OPEN = 'SET_NEAREST_FEATURE_IS_OPEN'
export const SET_NEAREST_FEATURE = 'SET_NEAREST_FEATURE'
export const CLEAR_UNSELECTED_NEAREST_FEATURES = "CLEAR_UNSELECTED_NEAREST_FEATURES"
export const CLEAR_NEAREST_FEATURE = 'CLEAR_NEAREST_FEATURE'
export const SET_NEAREST_FEATURE_HIGHLIGHT = 'SET_NEAREST_FEATURE_HIGHLIGHT'
export const CLEAR_NEAREST_FEATURE_HIGHLIGHT = 'CLEAR_NEAREST_FEATURE_HIGHLIGHT'
export const ADD_SET_NEAREST_FEATURE = 'ADD_SET_NEAREST_FEATURE'
export const ERROR = 'MAP_PAGE_ERROR'
export const CLEAR_ERROR = "CLEAR_MAP_PAGE_ERROR"

export const MAX_LAYER_QUERY_LENGTH = 200
export const MAX_TABLE_QUERY_LENGTH = 25

export enum surveyJobCountTypes {
  pastCompliance = 'pastCompliance',
  nearCompliance = 'nearCompliance',
  pendingReview = 'pendingReview',
  completedJobs = 'completedJobs',
  activeJobs = 'activeJobs',
  plannedJobs = 'plannedJobs'
}

export enum reportedIssuesFunctions {
  getReportedIssuesConfig = 'getReportedIssuesConfig',
  queryReportedIssue = 'queryReportedIssue'
}

/**
 * Summary: Execute query for table
 * @param tableId: table id
 * @param where: query to execute
 * @param isRelationshipQuery: check if querying from relationship
 * @param originFieldName relationship's origin field name
 * @param destinationFieldName relationship's destination field name
 * @param originLayerId: origin layer/table id
 */
export const executeTableQuery = (
    tableId:number,
    where:string,
    isRelationshipQuery:boolean,
    originFieldName:string,
    destinationFieldName:string,
    originLayerId:number
  ) => async(dispatch:any) => {
  const tables = store.getState().SettingsReducer.settings.Tables
  const layers = store.getState().SettingsReducer.settings.Layers
  const selectedMapService = store.getState().LoginReducer.selectedMapService.decodedLabel;
  const queryingParams = {
    [arcgisQueryParams.where]: where,
    [arcgisQueryParams.outFields]: '*',
    [arcgisQueryParams.returnGeometry]:'true'
  }
  postArcgisQuery(
    selectedMapService,
    tableId,
    queryingParams
  )
    .then((res:any)=> {
      axiosErrorHandler(res)
      res.data.tableId = tableId
      if (isRelationshipQuery) {
        const originDestinationQueries = (
          getOriginDestinationQueries(
            res.data.features,
            originFieldName,
            destinationFieldName
          )
        )
        if (originDestinationQueries.length > 0) {
          const maxQueryLength = originLayerId < layers.length ?
            MAX_LAYER_QUERY_LENGTH :
            MAX_TABLE_QUERY_LENGTH
          //Break apart the query if larger than maxQueryLength
          for (let i = 0; i < originDestinationQueries.length; i += maxQueryLength) {
            const originDestinationQueriesArray = originDestinationQueries.slice(i, i + maxQueryLength)
            const originDestinationQueryString = getOriginDestinationQueryString(originDestinationQueriesArray)
            if (originLayerId < layers.length) {
              dispatch({
                type:EXECUTE_RELATIONSHIP_QUERY_FEATURES,
                originLayerId:originLayerId,
                where:originDestinationQueryString,
                originFieldName:originFieldName,
                destinationFieldName:destinationFieldName,
                results:res.data
              })
            }
            else {
              dispatch(
                executeRelationshipTableQuery(
                  originLayerId,
                  originDestinationQueryString,
                  originFieldName,
                  destinationFieldName,
                  res.data.features,
                  false,
                  res.data.displayFieldName,
                  tableId
                )
              )
            }
          }
        }
        else {
          dispatch({type: SET_QUERY_RESULT_MSG, message: '0 results found!'})
        }
      }
      else {
        if (res.data.features.length === 0) {
            dispatch({type: SET_QUERY_RESULT_MSG, message: '0 results found!'})
        }
        else {
          dispatch(
            addToInfoTables(
              res.data,
              tables,
              layers,
              mapPageMapType,
              widgetTypes.query
            )
          )
        }
      }
    })
    .catch((err:any)=>{
      dispatch({
        type:ERROR,
        error:err.message
      })
      dispatch({
        type: SET_QUERY_RESULT_MSG,
        message: err.message
      })
    })
}

/**
 * Query for for origin/parent ids and store any relationships that have a matching id
 * @param tableId id of origin/parent table
 * @param where SQL statement to query ids with
 * @param originFieldName relationship's origin field name
 * @param destinationFieldName relationship's destination field name
 * @param relationships relationships (from destination/relationship dropdown layer/table)
 * @param isDestinationLayer check if destination is layer or table
 * @param destinationDisplayFieldName display field name of destination layer
 * @param destinationTableId
 */
export const executeRelationshipTableQuery = (
    tableId:number,
    where:string,
    originFieldName:string,
    destinationFieldName:string,
    relationships:any,
    isDestinationLayer:boolean,
    destinationDisplayFieldName:string|null=null,
    destinationTableId:number|null=null
  ) => async(dispatch:any) => {
    const selectedMapService= store.getState().LoginReducer.selectedMapService.decodedLabel;
    const queryingParams = {
      [arcgisQueryParams.where]: where,
      [arcgisQueryParams.outFields]: '*',
      [arcgisQueryParams.returnGeometry]:'true'
    }
    postArcgisQuery(
      selectedMapService,
      tableId,
      queryingParams
    )
      .then((res:any) => {
        if (res.data.error) {
          dispatch({
            type:ERROR,
            error:res.data.error.message
          })
          dispatch({
            type: SET_QUERY_RESULT_MSG,
            message: res.data.error.message
          })
        }
        else {
          let childRelationships:Array<any> = []
          relationships.forEach((relationship:any) => {
            res.data.features.forEach((result:any) => {
            if (relationship.attributes[destinationFieldName] === result.attributes[originFieldName]) {
                childRelationships.push(relationship)
              }
            })
          })
        if (childRelationships.length > 0) {
          if (isDestinationLayer) {
            switch(childRelationships[0].geometry.type) {
              case geometryType.point:
                dispatch({
                  type: 'ADD_SEARCH_GEOMETRY' + mapPageMapType,
                  points: childRelationships,
                  query: true
                })
                break
              case geometryType.polyline:
                dispatch({
                  type: 'ADD_SEARCH_GEOMETRY' + mapPageMapType,
                  lines: childRelationships,
                  query: true
                })
                break
              case geometryType.polygon:
                dispatch({
                  type: 'ADD_SEARCH_GEOMETRY' + mapPageMapType,
                  grids: childRelationships,
                  query: true
                })
                break
              default:
                break
            }
          }
          else {
            let tables = store.getState().SettingsReducer.settings.Tables
            let layers = store.getState().SettingsReducer.settings.Layers
            dispatch(
              addToInfoTables(
                {
                  features:childRelationships,
                  tableId:destinationTableId,
                  displayFieldName:destinationDisplayFieldName
                },
                tables,
                layers,
                mapPageMapType,
                widgetTypes.query
              )
            )
          }
        }
        else {
          dispatch({type: SET_QUERY_RESULT_MSG, message: '0 results found!'})
          }
        }
      })
      .catch((err:any)=>{
        dispatch({
          type:ERROR,
          error:err.message
        })
        dispatch({
          type: SET_QUERY_RESULT_MSG,
          message: err.message
        })
      })
}

/**
 * Summary: Get saved queries from selected layer
 * @param layerId: id of layer to get queries from
 */
export const getSavedQueries = (layerId:number) => async(dispatch:any) => {
  const currentUser = store.getState().LoginReducer.loggedInUser
  const selectedMapService = store.getState().LoginReducer.selectedMapService.label
  const requestBody = {
    layerId: layerId,
    mapServiceName: selectedMapService
  }
  createMapConfguration(currentUser, requestBody)
    .then(res => {
      dispatch({type: SET_SAVED_QUERIES, savedQueries: res.data, layerId: layerId})
    })
    .catch((err)=>{
      dispatch({
        type:ERROR,
        error:err
      })
    })
}

/**
 * Summary: Get all saved queries
 */
 export const getAllSavedQueries = () => async(dispatch:any) => {
  const currentUser = store.getState().LoginReducer.loggedInUser
  const selectedMapService = store.getState().LoginReducer.selectedMapService.label
  const layers = store.getState().SettingsReducer.settings.Layers
  const tables = store.getState().SettingsReducer.settings.Tables
  let promises = []

  //TODO: Change implementation so it does not get saved queries by layer (would not work well if there are too many)
  for (let i = 0; i < layers.length + tables.length; i++) {
    // const url = `${urlConfig.reactLanternServerUrl}//View.ashx/configuration/${currentUser}/queries`
    const requestBody = {
      layerId: i,
      mapServiceName: selectedMapService
    }
    promises.push(createMapConfguration(currentUser, requestBody))
  }

  Promise.all(promises)
    .then(res => {
      let savedQueries: {[index: number]:any} = {}
      res.forEach((layerResults:any, index:number) => {
        savedQueries[index] = layerResults.data
      })
      dispatch({type: SET_ALL_SAVED_QUERIES, savedQueries: savedQueries})
    })
    .catch((err)=>{
      dispatch({
        type:ERROR,
        error:err
      })
    })
}

/**
 * Summary: Save a query to the selected layer
 * @param layerId: id of layer to get queries from
 * @param query: query to be saved
 * @param queryName: name of new saved query
 * @param isPublic: whether this query should be shared publicly
 */
export const saveNewQuery = (layerId:number, query:string, queryName:string, isPublic:boolean) => async(dispatch:any) => {
  const currentUser = store.getState().LoginReducer.loggedInUser
  const selectedMapService = store.getState().LoginReducer.selectedMapService.label
  // const url = `${urlConfig.reactLanternServerUrl}//View.ashx/queries/save`

  let requestBody ={
    query: `{
      "isPublic":${isPublic},
      "queryName":"${queryName}",
      "layerId":${layerId},
      "id":-1,
      "tableChain":null,
      "userName":"${currentUser}",
      "query":"${query}",
      "mapServiceName": "${selectedMapService}",
      "isPermanent":false
    }`
  }

  // axios.post(url, requestBody)
  createMapQueries(requestBody)
  .then(() => {
    dispatch(getSavedQueries(layerId))
  })
  .catch((err)=>{
    dispatch({
      type:ERROR,
      error:err
    })
  })
}

/**
 * Summary: Delete the given saved query
 * @param layerId: id of layer query is from
 * @param query: query to be deleted
 * @param queryId: id of query to be deleted
 * @param queryName: name of query
 * @param isPublic: share status of query to be deleted
 */
export const deleteQuery = (layerId:number, query:string, queryId:number, queryName:string, isPublic:boolean) => async(dispatch:any) => {
  const currentUser = store.getState().LoginReducer.loggedInUser
  const selectedMapService = store.getState().LoginReducer.selectedMapService.label
  // const url = `${urlConfig.reactLanternServerUrl}//View.ashx/queries/delete`

  let requestBody = {
    query: `{
      "isPublic":${isPublic},
      "queryName":"${queryName}",
      "layerId":${layerId},
      "id":${queryId},
      "tableChain":null,
      "userName":"${currentUser}",
      "query":"${query}",
      "mapServiceName": "${selectedMapService}",
      "isPermanent":false
    }`
  }

  // axios.post(url, requestBody)
  createMapQueriesDelete(requestBody)
  .then(res => {
    dispatch(getSavedQueries(layerId))
  })
  .catch((err)=>{
    dispatch({
      type:ERROR,
      error:err
    })
  })
}

/** Summary: Get the Survey Job count of the given query type */
export const getAllSurveyJobCounts = () =>
async(dispatch: any) => {
  const selectedMapService = store.getState().LoginReducer.selectedMapService.decodedLabel;
  const surveyJobId = getTableIdByName(
    store.getState().SettingsReducer.globalAliases.LanternSurveyJob
  );
  const nearComplianceDays = 30;
  const millisecondsInADay = 24 * 60 * 60 * 1000;
  // the following dates are in UTC time
  const currentDate = getCurrentDate(true, 'date');
  const currentYear = getCurrentDate(true, 'year');
  const nearComplianceDate = getOffsetDate(nearComplianceDays * millisecondsInADay, true, 'date');
  const where: any = {
    [surveyJobCountTypes.pastCompliance]: `CAST(ComplianceDate AS DATE) < DATE '${currentDate}' AND ` +
    `Status NOT IN ('Historical', 'Completed')`,
    [surveyJobCountTypes.nearCompliance]: `(CAST(ComplianceDate AS DATE) BETWEEN DATE '${currentDate}' AND ` +
    `DATE '${nearComplianceDate}') AND Status NOT IN ('Historical', 'Completed')`,
    [surveyJobCountTypes.pendingReview]: `Status = 'Completed'`,
    [surveyJobCountTypes.completedJobs]: `Status IN ('Historical', 'Completed') AND EXTRACT(YEAR FROM CompletedOn) = ${currentYear}`,
    [surveyJobCountTypes.activeJobs]: `Status = 'Active'`,
    [surveyJobCountTypes.plannedJobs]: `Status = 'Planned'`
  }

  let overviewDetails: any = {};
  let promises: any = [];
  const tkn = localStorage.getItem("ArcGISToken");
  Object.keys(where).forEach((queryType: any) => {
    const queryingParams =concatQueryingParameters( {
      [arcgisQueryParams.where]: where[queryType],
      [arcgisQueryParams.returnCountOnly]: 'true',
    })
    promises.push(
      getArcgisQuery(
        selectedMapService,
        surveyJobId,
        queryingParams
      )
        .catch((err:any) => {
          dispatch({
            type: ERROR,
            error: {
              message: err.message,
              type: `getSurveyJobCounts${queryType}`,
            },
          });
        })
    );
  });

  Promise.all(promises).then((results: any) => {
    results.forEach((result: any, index: number) => {
      const queryType = Object.keys(where)[index]
      overviewDetails[queryType] = result.data.count
    })

    dispatch({
      type: SET_OVERVIEW_DETAILS,
      overviewDetails: overviewDetails
    })
  })
}

/** Summary: Get the reported issues configuration data */
export const getReportedIssuesConfig = () =>
async(dispatch: any) => {
  const selectedMapService = store.getState().LoginReducer.selectedMapService.label;

  getReportableItems(selectedMapService)
  .then((res) => {
    dispatch({
      type: SET_REPORTED_ISSUES_CONFIG,
      config: res.data
    })
  })
  .catch((err) => {
    dispatch({
      type: ERROR,
      error: {
        message: err.message,
        type: reportedIssuesFunctions.getReportedIssuesConfig
      }
    })
  })
}

/**
 * Summary: query all the Reported Issues based on the date constraints
 * @param issueConfigs array of Reported Issues configuration data
 * @param date earliest date to filter from
 * @param dateFilterTime time of the date filter in milliseconds
 */
export const queryAllReportedIssues = (issueConfigs: Array<any>, date: string, dateFilterTime: number) =>
async(dispatch: any) => {
  const selectedMapService = store.getState().LoginReducer.selectedMapService.decodedLabel;

  let issuesDetails: any = {};
  let promises: any = [];
  Object.values(issueConfigs).forEach((issueConfig: any) => {
    const queryingParams =concatQueryingParameters( {
      [arcgisQueryParams.where]: encodeURIComponent(`${issueConfig.WhereClause} AND ` +
        `CAST(${issueConfig.DateFieldName} AS DATE) >= DATE '${date}'`),
      [arcgisQueryParams.outFields]: '*',
      [arcgisQueryParams.returnCountOnly]: "true"
    })
    promises.push(
      getArcgisQuery(
        selectedMapService,
        issueConfig.LayerId,
        queryingParams
      )
      .catch((err:any) => {
        dispatch({
          type: ERROR,
          error: {
            message: err.message,
            type: reportedIssuesFunctions.queryReportedIssue
          }
        })
      })
    )
  })

  Promise.all(promises).then((results: any) => {
    results.forEach((result: any, index: number) => {
      if (result) {
        const displayName = Object.values(issueConfigs)[index].DisplayName
        issuesDetails[displayName] = {
          count: result.data.count,
          features: [],
          toggle: false
        }
      }
    })

    dispatch({
      type: SET_REPORTED_ISSUES_DETAILS,
      dateFilterTime: dateFilterTime,
      issuesDetails: issuesDetails
    })
  })
}

/**
 * Summary: query all the Reported Issues based on the date constraints
 * @param issueConfig array of Reported Issues configuration data
 * @param date earliest date to filter from
 * @param displayName display name for the reported issue
 * @param dispatch dispatch from useDispatch()
 */
export const queryReportedIssue = (issueConfig: any, date: string, displayName: string, dispatch:Function) =>
new Promise((resolve:any, reject:any) => {
  const selectedMapService = store.getState().LoginReducer.selectedMapService.decodedLabel;
  const layers = store.getState().SettingsReducer.settings.Layers;
  const tables = store.getState().SettingsReducer.settings.Tables;
  const queryingParams =concatQueryingParameters( {
    [arcgisQueryParams.where]: encodeURIComponent(`${issueConfig.WhereClause} AND ` +
      `CAST(${issueConfig.DateFieldName} AS DATE) >= DATE '${date}'`),
    [arcgisQueryParams.outFields]: '*'
  })
  getArcgisQuery(
    selectedMapService,
    issueConfig.LayerId,
    queryingParams
  )
  .catch((err:any) => {
    dispatch({
      type: ERROR,
      error: {
        message: err.message,
        type: reportedIssuesFunctions.queryReportedIssue
      }
    })
    reject()
  })
  .then((results: any) => {
    const featureLayerName = [...layers, ...tables][issueConfig.LayerId].Name || [...layers, ...tables][issueConfig.LayerId].DisplayName
    const features = massageQueryData(results.data, issueConfig.LayerId, featureLayerName)
    dispatch({type: SET_REPORTED_ISSUES_DETAILS_FEATURES, features: features, displayName: displayName})
    resolve()
  })
})

export const addNearestFeatures = (
  newNearestFeatures:Array<any>, 
  layerGeometryType:geometryType, 
  layerId:number, 
  layerName:string, 
  displayField:string
) => async(dispatch: any) => {
  let {nearestFeatureGrids, nearestFeatureLines, nearestFeaturePoints} = store.getState().MapPageReducer
  const nearestFeaturesInfo = store.getState().MapPageReducer.nearestFeaturesInfo

  switch(layerGeometryType) {
    case geometryType.polygon: 
      nearestFeatureGrids = removeDuplicateFeatures(nearestFeatureGrids.concat(newNearestFeatures))
      break
    case geometryType.polyline: 
      nearestFeatureLines = removeDuplicateFeatures(nearestFeatureLines.concat(newNearestFeatures))
      break
    case geometryType.point: 
      nearestFeaturePoints = removeDuplicateFeatures(nearestFeaturePoints.concat(newNearestFeatures))
      break
  }

  nearestFeaturesInfo[layerId] = {
    layerName: layerName,
    displayField: displayField,
    geometryType: layerGeometryType,
    hasSetFeature: false,
    features: []
  }
  newNearestFeatures.forEach((feature:any) => {
    nearestFeaturesInfo[layerId].features.push(feature)
  })

  nearestFeaturesInfo[layerId].features = removeDuplicateFeatures(nearestFeaturesInfo[layerId].features)

  dispatch({
    type: SET_NEAREST_FEATURE, 
    nearestFeatureGrids: nearestFeatureGrids, 
    nearestFeatureLines: nearestFeatureLines, 
    nearestFeaturePoints: nearestFeaturePoints,
    nearestFeaturesInfo: nearestFeaturesInfo
  })
}

export const addSetNearestFeatures = (
  setFeature:any,
  layerId:number,
  layerGeometryType:geometryType
) => async(dispatch: any) => {
  let {nearestFeatureGrids, nearestFeatureLines, nearestFeaturePoints} = store.getState().MapPageReducer
  const nearestFeaturesInfo = store.getState().MapPageReducer.nearestFeaturesInfo
  let feature = null

  nearestFeaturesInfo[layerId].hasSetFeature = true

  switch(layerGeometryType) {
    case geometryType.polygon: 
      feature = findFeatureFromID(nearestFeatureGrids, setFeature)
      break
    case geometryType.polyline: 
      feature = findFeatureFromID(nearestFeatureLines, setFeature)
      break
    case geometryType.point: 
      feature = findFeatureFromID(nearestFeaturePoints, setFeature)
      break
  }

  if (feature) {
    feature.isSet = true
  
    dispatch({
      type: SET_NEAREST_FEATURE, 
      nearestFeatureGrids: nearestFeatureGrids, 
      nearestFeatureLines: nearestFeatureLines, 
      nearestFeaturePoints: nearestFeaturePoints,
      nearestFeaturesInfo: nearestFeaturesInfo
    })
  
    dispatch({type: CLEAR_NEAREST_FEATURE_HIGHLIGHT})
  }
}

const initialState:any = {
  savedQueries: {},
  queryWhere: '',
  queryResultMessage: '',
  queryRelationship: false,
  isRelationshipDestinationLayer: true,
  loadOverview: true,
  overviewDetails: {},
  overviewExtent: false,
  loadReportedIssues: true,
  reportedIssuesConfig: [],
  reportedIssuesDetails: {
    dateFilterTime: 0,
    issuesDetails: {},
    store: {}
  },
  nearestFeatureIsOpen: false,
  nearestFeatureGrids: [],
  nearestFeatureLines: [],
  nearestFeaturePoints: [],
  nearestFeaturesInfo: {},
  nearestFeatureHighlight: {}
}

export default function reducer (state = initialState, action:any) {
  switch(action.type) {
    case SET_SAVED_QUERIES: {
      let newSavedQueries = {...state.savedQueries}
      newSavedQueries[action.layerId] = action.savedQueries
      return {
        ...state,
        savedQueries: newSavedQueries
      }
    }

    case SET_ALL_SAVED_QUERIES: {
      return {
        ...state,
        savedQueries: action.savedQueries
      }
    }

    case EXECUTE_LAYER_QUERY: {
      //If current query and last query are equal, add a space to differentiate the current query
      //So useEffect will trigger properly
      if (state.queryWhere === action.where) {
        action.where += ' '
      }
      let polygons = []
      if (action.selectedPolygons) {
        polygons = action.selectedPolygons
      }
      return {
        ...state,
        queryWhere: action.where,
        queryLayer: action.layer,
        queryBound: action.bound,
        selectedPolygons: polygons,
        queryRelationship: action.relationship,
        queryOriginFieldName: action.originFieldName,
        queryDestinationFieldName: action.destinationFieldName,
        originLayerId: action.originLayerId
      }
    }

    case EXECUTE_RELATIONSHIP_QUERY_FEATURES: {
      if (state.tableRelationshipQueryWhere === action.where) {
        action.where += ' '
      }
      return {
        ...state,
        tableRelationshipQueryWhere: action.where,
        tableOriginFieldName: action.originFieldName,
        tableDestinationFieldName: action.destinationFieldName,
        tableRelationshipResults: action.results,
        tableRelationshipOriginId: action.originLayerId
      }
    }

    case SET_QUERY_RESULT_MSG: {
      return {
        ...state,
        queryResultMessage: action.message
      }
    }

    case RESET_QUERY_TEXT: {
      return {
        ...state,
        queryWhere: ""
      }
    }

    case SET_LOAD_OVERVIEW: {
      return {
        ...state,
        loadOverview: action.loadOverview
      }
    }

    case SET_OVERVIEW_DETAILS: {
      return {
        ...state,
        overviewDetails: action.overviewDetails
      }
    }

    case SET_OVERVIEW_EXTENT: {
      return {
        ...state,
        overviewExtent: action.extent
      }
    }

    case SET_LOAD_REPORTED_ISSUES: {
      return {
        ...state,
        loadReportedIssues: action.loadReportedIssues
      }
    }

    case SET_REPORTED_ISSUES_CONFIG: {
      return {
        ...state,
        reportedIssuesConfig: action.config
      }
    }

    case SET_REPORTED_ISSUES_DETAILS: {
      return {
        ...state,
        reportedIssuesDetails: {
          ...state.reportedIssuesDetails,
          dateFilterTime: action.dateFilterTime,
          issuesDetails: action.issuesDetails
        }
      }
    }

    case SET_REPORTED_ISSUES_DETAILS_FEATURES: {
      const issueDetails = state.reportedIssuesDetails.issuesDetails
      issueDetails[action.displayName].features = action.features
      return {
        ...state,
        reportedIssuesDetails: {
          ...state.reportedIssuesDetails,
          dateFilterTime: state.reportedIssuesDetails.dateFilterTime,
          issuesDetails: issueDetails
        }
      }
    }

    case SET_REPORTED_ISSUES_DETAILS_TOGGLE: {
      return {
        ...state,
        reportedIssuesDetails: {
          ...state.reportedIssuesDetails,
          issuesDetails: {
            ...state.reportedIssuesDetails.issuesDetails,
            [action.issueDisplayName]: {
              ...state.reportedIssuesDetails.issuesDetails[action.issueDisplayName],
              toggle: action.toggleStatus
            }
          }
        }
      }
    }

    case SET_REPORTED_ISSUES_STORE: {
      return {
        ...state,
        reportedIssuesDetails: {
          ...state.reportedIssuesDetails,
          store: {
            ...state.reportedIssuesDetails.store,
            [action.layerName]: action.layerStore
          }
        }
      }
    }

    case RESET_REPORTED_ISSUES_STORE: {
      return {
        ...state,
        reportedIssuesDetails: {
          ...state.reportedIssuesDetails,
          store: {}
        }
      }
    }

    case SET_NEAREST_FEATURE_IS_OPEN: {
      return {
        ...state,
        nearestFeatureIsOpen: action.isOpen
      }
    }

    case SET_NEAREST_FEATURE: {
      return {
        ...state,
        nearestFeatureGrids: action.nearestFeatureGrids,
        nearestFeatureLines: action.nearestFeatureLines,
        nearestFeaturePoints: action.nearestFeaturePoints,
        nearestFeaturesInfo: action.nearestFeaturesInfo
      }
    }

    case CLEAR_UNSELECTED_NEAREST_FEATURES: {
      return {
        ...state,
        nearestFeatureGrids: action.nearestFeatureGrids.filter((grid:any) => grid.isSet === true),
        nearestFeatureLines: action.nearestFeatureLines.filter((line:any) => line.isSet === true),
        nearestFeaturePoints: action.nearestFeaturePoints.filter((point:any) => point.isSet === true),
      }
    }

    case CLEAR_NEAREST_FEATURE: {
      return {
        ...state,
        nearestFeatureGrids: [],
        nearestFeatureLines: [],
        nearestFeaturePoints: [],
        nearestFeaturesInfo: {}
      }
    }

    case SET_NEAREST_FEATURE_HIGHLIGHT: {
      return {
        ...state,
        nearestFeatureHighlight: {
          feature: action.feature, 
          geometryType: action.geometryType
        }
      }
    }

    case CLEAR_NEAREST_FEATURE_HIGHLIGHT: {
      return {
        ...state,
        nearestFeatureHighlight: {}
      }
    }

    case ERROR: {
      return {
        ...state,
        error: action.error
      }
    }

    case CLEAR_ERROR: {
      return {
        ...state,
        error: {}
      }
    }

    default:
      return state
  }
}
