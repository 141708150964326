import { store } from "app/store";
import {
  addToInfoTables,
  mapPageMapType
} from "./MapDucks";
import { widgetTypes } from "./MapWidgetLayer";
import { SQLConcatOperators } from "./Widgets/QueryWidget/QuerySlideoutBody";
import {
  executeRelationshipTableQuery,
  MAX_LAYER_QUERY_LENGTH,
  MAX_TABLE_QUERY_LENGTH,
  SET_QUERY_RESULT_MSG
} from "features/MapPage/MapPageDucks";

export enum selectOperations {
  add = 'ADD',
  remove = 'REMOVE'
}

export enum geometryType {
  point = 'point',
  polyline = 'polyline',
  polygon = 'polygon'
}

export enum esriGeometryType {
  esriGeometryPoint = geometryType.point,
  esriGeometryPolyline = geometryType.polyline,
  esriGeometryPolygon = geometryType.polygon
}

export enum selectionType {
  select = 'select',
  search = 'search',
  query = 'query',
  relationshipQuery = 'relationshipQuery'
}

/**
 * Summary: Query function for selecting/unselecting features
 *
 * @param layers map layers
 * @param mapType map page
 * @param dispatch dispatch from usedispatch()
 * @param geometry geographical data to be queried with
 * @param operation determines if the queried grids will be added or removed
 * @param isPoint determines if point tool is being used
 * @param chosenLayer use chosen layer if one is provided
 * @param selectType check if current selection is from the select, search, or query tool
 * @param where SQL query to be used with queryFeatures
 */
export function selectFeatures(
    layers:any,
    mapType:any,
    chosenLayer:any,
    dispatch:Function,
    geometry:any,
    operation:string,
    isPoint:boolean,
    selectType:selectionType=selectionType.select,
    where:string|null=null
  ) {
  const layerSelectability = store.getState().SettingsReducer.layerSelectability
  const layerVisibility = store.getState().SettingsReducer.layerVisibility
  //default on the top most layer
  var targetLayer = layers.items[0];
  // find the target layer to query on
  layers.items.forEach((item:any) => {
    if (chosenLayer === item?.sourceJSON?.name) {
      targetLayer = item
    }
  })
  //Return if layer is not visible or selectable if using a selection tool
  if (
    selectType === selectionType.select && 
    (!layerVisibility[targetLayer.layerId + targetLayer?.sourceJSON?.name] || 
      !layerSelectability[targetLayer.layerId + targetLayer?.sourceJSON?.name]
  )) {
    return
  }
  var query = targetLayer.createQuery()
  if (geometry) {
    query.geometry = geometry
  }
  if (where) {
    query.where = where
  }
  query.outFields = ["*"]
  targetLayer
    .queryFeatures(query)
    .then(function(results:any) {
      let features = results.features
      if (features.length > 0) {
        if (selectType === selectionType.relationshipQuery) {
          let queryOriginFieldName = store.getState().MapPageReducer.queryOriginFieldName
          let queryDestinationFieldName = store.getState().MapPageReducer.queryDestinationFieldName
          let originLayerId = store.getState().MapPageReducer.originLayerId
          let settingsLayers = store.getState().SettingsReducer.settings.Layers
          const originDestinationQueries = (
            getOriginDestinationQueries(
              features,
              queryOriginFieldName,
              queryDestinationFieldName
            )
          )
          if (originDestinationQueries.length > 0) {
            const maxQueryLength = originLayerId < settingsLayers.length ?
              MAX_LAYER_QUERY_LENGTH :
              MAX_TABLE_QUERY_LENGTH
            //Break apart the query if larger than maxQueryLength
            for (let i = 0; i < originDestinationQueries.length; i += maxQueryLength) {
              const originDestinationQueryArray = originDestinationQueries.slice(i, i + maxQueryLength)
              const originDestinationQueryString = getOriginDestinationQueryString(originDestinationQueryArray)
              if (originLayerId < settingsLayers.length) {
                let originLayer = settingsLayers.find((layer:any) => layer.Id === originLayerId)
                relationshipQueryFeatures(
                  originLayer,
                  originDestinationQueryString,
                  queryOriginFieldName,
                  queryDestinationFieldName,
                  features,
                  dispatch,
                  true
                )
              }
              else {
                dispatch(
                  executeRelationshipTableQuery(
                    originLayerId,
                    originDestinationQueryString,
                    queryOriginFieldName,
                    queryDestinationFieldName,
                    features,
                    true
                  )
                )
              }
            }
          }
          else {
            dispatch({type: SET_QUERY_RESULT_MSG, message: '0 results found!'})
          }
        }
        else {
          const actionType = operation + (selectType === selectionType.select ? '' : '_SEARCH') +
          '_GEOMETRY' + mapType
          switch(features[0].geometry.type) {
            case geometryType.point:
              dispatch({
                type: actionType,
                points: features,
                pointTool: isPoint,
                query: selectType === selectionType.query
              })
              break
            case geometryType.polyline:
              dispatch({
                type: actionType,
                lines: features,
                pointTool: isPoint,
                query: selectType === selectionType.query
              })
              break
            case geometryType.polygon:
              dispatch({
                type: actionType,
                grids: features,
                pointTool: isPoint,
                query: selectType === selectionType.query
              })
              break
            default:
              break
          }
        }
      }
      if ((selectType === selectionType.query || selectType === selectionType.relationshipQuery) && results.features.length === 0) {
        dispatch({type: SET_QUERY_RESULT_MSG, message: '0 results found!'})
      }
    })
    .catch((err:any) => {
      if (selectType === selectionType.query  || selectType === selectionType.relationshipQuery) {
        dispatch({type: SET_QUERY_RESULT_MSG, message: err.message})
      }
    })
}

/**
 * Summary: Query function for selecting/unselecting features on the map page
 * Selects from all layers and allows point selection
 *
 * @param layers map layers
 * @param mapType map page
 * @param dispatch dispatch from usedispatch()
 * @param geometry geographical data to be queried with
 * @param operation determines if the queried grids will be added or removed
 * @param isPoint determines if point tool is being used
 */
export function mapPageSelectFeatures(
    layers:any,
    mapType:any,
    dispatch:Function,
    geometry:any,
    operation:string,
    isPoint:boolean
  ) {
  layers.items.forEach((item:any) => {
    //Iterate through layers with geometry
    if (item.geometryType) {
      const query = item.createQuery()
      query.geometry = geometry
      query.outFields = ["*"]
      // Add buffer region if geometry type is point
      if (isPoint) {
        // adjust these properties to adjust the precision of point select
        query.distance = "5";
        query.units = "meters";
      }
      item.queryFeatures(query)
        .then(function(results:any) {
          const layerSelectability = store.getState().SettingsReducer.layerSelectability
          const layerVisibility = store.getState().SettingsReducer.layerVisibility
          //Filter out features is layer is not visible or selectable
          const graphics = results.features.filter((feature:any) => {
            return layerVisibility[feature.layer.layerId + feature.layer?.sourceJSON?.name] && 
              layerSelectability[feature.layer.layerId + feature.layer?.sourceJSON?.name]
          })
          if (graphics.length > 0) {
            switch(graphics[0].geometry.type) {
              case geometryType.point:
                dispatch({type: operation + '_GEOMETRY' + mapType, points: graphics, pointTool: isPoint})
                break
              case geometryType.polyline:
                dispatch({
                  type: operation + '_GEOMETRY' + mapType, lines: graphics, pointTool: isPoint})
                break
              case geometryType.polygon:
                dispatch({type: operation + '_GEOMETRY' + mapType, grids: graphics, pointTool: isPoint})
                break
              default:
                break
            }
          }
        })
    }
  })
}

/**
 * Summary: Check for origin/parent ids with queryFeatures and store any relationships that have a matching id
 * @param layer: layer to query from (relationship origin layer)
 * @param where: query where SQL statement
 * @param originFieldName relationship's origin field name
 * @param destinationFieldName relationship's destination field name
 * @param relationships: relationships (from destination/relationship dropdown layer/table)
 * @param dispatch: dispatch from useDispatch()
 * @param isDestinationLayer: check if destination is layer or table
 * @param tableId: id from destination table if there is one
 */
export function relationshipQueryFeatures(
    layer:any,
    where:string,
    originFieldName:string,
    destinationFieldName:string,
    relationships:any,
    dispatch:any,
    isDestinationLayer:boolean,
    tableId:number=0
  ) {
  let relationshipQuery = layer.createQuery()
  relationshipQuery.where = where
  layer.queryFeatures(relationshipQuery).then((function(results:any) {
    let childRelationships:Array<any> = []
    relationships.forEach((relationship:any) => {
      results.features.forEach((result:any) => {
        if (relationship.attributes[destinationFieldName] === result.attributes[originFieldName]) {
          childRelationships.push(relationship)
        }
      })
    })
    let layers = store.getState().SettingsReducer.settings.Layers
    let tables = store.getState().SettingsReducer.settings.Tables
    if (childRelationships.length > 0) {
      if (isDestinationLayer) {
        switch(childRelationships[0].geometry.type) {
          case geometryType.point:
            dispatch({
              type: 'ADD_SEARCH_GEOMETRY' + mapPageMapType,
              points: childRelationships,
              query: true
            })
            break
          case geometryType.polyline:
            dispatch({
              type: 'ADD_SEARCH_GEOMETRY' + mapPageMapType,
              lines: childRelationships,
              query: true
            })
            break
          case geometryType.polygon:
            dispatch({
              type: 'ADD_SEARCH_GEOMETRY' + mapPageMapType,
              grids: childRelationships,
              query: true
            })
            break
          default:
            break
        }
      }
      else {
        dispatch(
          addToInfoTables(
            {
              features:childRelationships,
              tableId:tableId,
              displayFieldName:tables[tableId - layers.length].DisplayFieldName
            },
            tables,
            layers,
            mapPageMapType,
            widgetTypes.query
          )
        )
      }
    }
    else {
      dispatch({type: SET_QUERY_RESULT_MSG, message: '0 results found!'})
    }
  }))
  .catch((err:any) => {
    dispatch({type: SET_QUERY_RESULT_MSG, message: err.message})
  })
}

/**
 * Summary: Create array of queries to match origin field names with destination field names
 * @param relationships: relationships to get the related field name from
 * @param originFieldName: relationship's origin field name
 * @param destinationFieldName: relationship's destination field name
 */
export function getOriginDestinationQueries(relationships:any, originFieldName:string, destinationFieldName:string) {
  let relationshipQueries: Array<string> = []
    relationships.forEach((relationship:any) => {
    if (relationship.attributes[destinationFieldName]) {
        relationshipQueries.push(`${originFieldName} = '${relationship.attributes[destinationFieldName]}' ${SQLConcatOperators.orOperator} `)
      }
    })
  return relationshipQueries
  }

/**
 * Convert array of queries produced by getOriginDestinationQueries to a string
 * @param relationshipQueries array of relationship queries
 */
export function getOriginDestinationQueryString(relationshipQueries:Array<string>) {
  let relationshipQueryString = ''
  const removeLength = ` ${SQLConcatOperators.orOperator} `.length
  relationshipQueries.forEach((query:string) => {
    relationshipQueryString += query
  })
  relationshipQueryString = relationshipQueryString.substring(0, relationshipQueryString.length - removeLength)
  return relationshipQueryString
}
