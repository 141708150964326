import { useSelector } from "react-redux";

const createLoading = (requestArr) => {
  let indicator = false;
  let loadingArr = requestArr.map((request) =>
    useSelector((state) => state.LoadingReducer[request])
  );

  loadingArr.forEach((loading) => {
    if (loading || loading === undefined) indicator = true;
  });
  return indicator;
};


export default createLoading