import React from 'react';
import './Attachment.scss';
import DeleteIcon from '@material-ui/icons/Delete';
interface IAttachmentProp {
  className?: string
  title: string
  image: string
  alt?: string
  width?: number
  height?: number
  link?: string
  newWindow?: boolean
  onClick?: () => void
  onDeleteAttachment?: () => void
}

const Attachment = ({
  className = "",
  title,
  image,
  alt,
  width,
  height,
  link,
  newWindow,
  onClick,
  onDeleteAttachment,
  }:IAttachmentProp) => {

  const renderThumbnail = () => {
    if (link) {
      return (
        <>
          <DeleteIcon
            className="info-main-header-icon attachment-delete-icon"
            onClick={() => onDeleteAttachment && onDeleteAttachment()}
          />
          <a href={link} target={newWindow ? "_blank" : ""}>
            <img
              src={image}
              alt={alt}
              width={width}
              height={height}
            />
          </a>
        </>
      )
    } else {
      return (
        <img
          onClick={onClick}
          src={image}
          alt={alt}
          width={width}
          height={height}
        />
      )
    }
  }

  return(
    <div className={"attachment " + className}>
      <div className="attachment-thumbnail">
        {renderThumbnail()}
      </div>
      <label className="attachment-label">
        {title}
      </label>
    </div>
  )
}

export default Attachment;
