import qs from "qs";
import { urlConfig } from "index";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { refreshToken } from "app/Auth/refreshToken";

type apiUrlType =
  | "LEGACY_API_URL"
  | "NEW_API_URL"
  | "ARC_GIS_API_URL"
  | "MAPSERVER_URL";

const resolveApiUrl = (urlType: apiUrlType) => {
  switch (urlType) {
    case "LEGACY_API_URL":
      return urlConfig.reactLanternServerUrl;
    case "NEW_API_URL":
      return urlConfig.reactLanternNewServerUrl;
    case "ARC_GIS_API_URL":
      return urlConfig.reactArcgisRestUrl;
    default:
      return urlConfig.reactUrl;
  }
};

export class HttpRequest {
  // static apiUrl = API_URL;
  static tokenType = "Bearer ";

  // Refresh token
  public static async getAndRefreshToken() {
    return await refreshToken();
  }

  // Return token
  public static getInitialToken() {
    return localStorage.getItem("token");
  }
		

  // GET Method
  public static async get(
    urlType: apiUrlType,
    endpoint: string,
    params?: object,
    contentType?: string,
    ignoreThrowError?: boolean
  ) {
    // Get token
    const token = this.getInitialToken();
    const apiUrl = resolveApiUrl(urlType);
    const ask = endpoint.includes("?") ? `&` : `?`;
    const endpointUrl = params
      ? `${apiUrl}${endpoint}${ask}${qs.stringify(params)}`
      : `${apiUrl}${endpoint}${ask}`;
    const axiosOptions: AxiosRequestConfig = {
      headers: {
        Authorization: this.tokenType + token,
        "Content-Type": contentType ? contentType : "application/json",
      },
      method: "GET",
      url: endpointUrl,
    };

    try {
      const resp = await axios(axiosOptions);
      return resp;
    } catch (err: any) {
      if (err.response.data === "Invalid Azure Token") {
        try {
          const token = await this.getAndRefreshToken();
          axiosOptions.headers.Authorization = this.tokenType + token;
          const resp = await axios(axiosOptions);
          return resp;
        } catch (err) {
          if (!ignoreThrowError) {
            console.error(err);
          }
          throw err;
        }
      }
      // Todo: in future its good to centralize the ArcGis token refresh here by checking "Invalid Token"
      else {
        if (!ignoreThrowError) {
          console.error(err);
        }
        throw err;
      }
    }
  }

  // POST Method
  public static async post(
    urlType: apiUrlType,
    endpoint: string,
    data?: object,
    contentType?: string,
    ignoreThrowError?: boolean
  ) {
    // Get token
    const token = await this.getAndRefreshToken();
    const apiUrl = resolveApiUrl(urlType);
    const ask = endpoint.includes("?") ? `&` : ``;
    const endpointUrl = apiUrl + endpoint + `${ask}`;
    
    const axiosOptions: AxiosRequestConfig = {
      headers: {
        Authorization: this.tokenType + token,
        // "Content-Type": contentType ? contentType : "application/json",
      },
      method: "POST",
      url: endpointUrl,
      data:
        contentType === "application/json" && data instanceof FormData
          ? JSON.stringify(data)
          : data,
    };

    try {
      const resp = await axios(axiosOptions);
      return resp;
    } catch (err: any) {
      if (err.response.data === "Invalid Azure Token") {
        try {
          const token = await this.getAndRefreshToken();
          axiosOptions.headers.Authorization = this.tokenType + token;
          const resp = await axios(axiosOptions);
          return resp;
        } catch (err) {
          if (!ignoreThrowError) {
            console.error(err);
          }
          throw err;
        }
      }
      // Todo: in future its good to centralize the ArcGis token refresh here by checking "Invalid Token"
      else {
        if (!ignoreThrowError) {
          console.error(err);
        }
        throw err;
      }
    }
  }

  // PUT Method
  public static async put(
    urlType: apiUrlType,
    endpoint: string,
    data?: object,
    contentType?: string,
    ignoreThrowError?: boolean
  ) {
    // Get token
    const token = await this.getAndRefreshToken();
    const apiUrl = resolveApiUrl(urlType);
    const ask = endpoint.includes("?") ? `&` : `?`;
    const endpointUrl = apiUrl + endpoint + `${ask}`;

    const axiosOptions: AxiosRequestConfig = {
      headers: {
        Authorization: this.tokenType + token,
        "Content-Type": contentType ? contentType : "application/json",
      },
      method: "PUT",
      url: endpointUrl,
      data: JSON.stringify(data),
    };

    try {
      const resp = await axios(axiosOptions);
      return resp;
    } catch (err: any) {
      if (err.response.data === "Invalid Azure Token") {
        try {
          const token = await this.getAndRefreshToken();
          axiosOptions.headers.Authorization = this.tokenType + token;
          const resp = await axios(axiosOptions);
          return resp;
        } catch (err) {
          if (!ignoreThrowError) {
            console.error(err);
          }
          throw err;
        }
      }
      // Todo: in future its good to centralize the ArcGis token refresh here by checking "Invalid Token"
      else {
        if (!ignoreThrowError) {
          console.error(err);
        }
        throw err;
      }
    }
  }

  // DELETE Method
  public static async delete(
    urlType: apiUrlType,
    endpoint: string,
    params?: object,
    ignoreThrowError?: boolean
  ) {
    // Get token
    const token = await this.getAndRefreshToken();
    const apiUrl = resolveApiUrl(urlType);
    const ask = endpoint.includes("?") ? `&` : `?`;
    const endpointUrl = params
      ? `${apiUrl}${endpoint}${ask}${qs.stringify(params)}`
      : `${apiUrl}${endpoint}${ask}`;

    const axiosOptions: AxiosRequestConfig = {
      headers: {
        Authorization: this.tokenType + token,
        "Content-Type": "application/json",
      },
      method: "DELETE",
      url: endpointUrl,
    };

    try {
      const resp = await axios(axiosOptions);
      return resp;
    } catch (err: any) {
      if (err.response.data === "Invalid Azure Token") {
        try {
          const token = await this.getAndRefreshToken();
          axiosOptions.headers.Authorization = this.tokenType + token;
          const resp = await axios(axiosOptions);
          return resp;
        } catch (err) {
          if (!ignoreThrowError) {
            console.error(err);
          }
          throw err;
        }
      }
      // Todo: in future its good to centralize the ArcGis token refresh here by checking "Invalid Token"
      else {
        if (!ignoreThrowError) {
          console.error(err);
        }
        throw err;
      }
    }
  }
}
