import { store } from 'app/store';
import { isEmpty, isUndefined} from "lodash";
import _ from 'lodash';
import { SET_SIDE_NAV } from 'app/SettingsDucks';
import { widgetTypes } from "./MapWidgetLayer";
import { removeDuplicateFeatures } from "utils/GridUtils";
import { mapAttribute, mapQueryResults } from 'utils/NameMappingUtils';
import { fieldType, whereQueryFieldTypeConversion } from 'utils/FieldsUtils';
import {defineCustomBasemap} from 'utils/MapServiceUtils'
import {
  getSearchLayerFields,
  getSearchTableFields,
  getLayerEditRules,
  getMapExternalLayers,
  getAllRelationship,
  getInfoChildren,
  getArcgisQuery,
  concatQueryingParameters,
  arcgisQueryParams
} from "api";

export const SET_GEOMETRY = 'SET_GEOMETRY';
export const SET_SEARCH_GEOMETRY = 'SET_SEARCH_GEOMETRY';
export const CLEAR_SEARCH_GEOMETRY = 'CLEAR_SEARCH_GEOMETRY'
export const ADD_GEOMETRY = 'ADD_GEOMETRY';
export const ADD_SEARCH_GEOMETRY = 'ADD_SEARCH_GEOMETRY';
export const REMOVE_GEOMETRY = 'REMOVE_GEOMETRY';
export const SET_GRID_TYPE = 'SET_GRID_TYPE';
export const CLEAR_ALL_SELECTED_GEOMETRY = 'CLEAR_SELECTED_GEOMETRY';
export const RESET_SEARCH = 'RESET_SEARCH';
export const ADD_TO_LAYER_SEARCH = 'ADD_TO_LAYER_SEARCH';
export const ADD_TO_TABLE_SEARCH = 'ADD_TO_TABLE_SEARCH';
export const RESET_SEARCH_SEEN = 'RESET_SEARCH_SEEN';
export const ADD_TO_SEARCH_SEEN = 'ADD_TO_SEARCH_SEEN';
export const RESET_INFO_INDEX = 'RESET_INFO_INDEX';
export const ADD_TO_INFO_INDEX = 'ADD_TO_INFO_INDEX';
export const RESET_INFO_IDS = 'RESET_INFO_IDS';
export const ADD_TO_INFO_IDS = 'ADD_TO_INFO_IDS';
export const RESET_INFO_CHILD = 'RESET_INFO_CHILD';
export const ADD_TO_INFO_CHILD = 'ADD_TO_INFO_CHILD';
export const RESET_INFO_FIELDS = 'RESET_INFO_FIELDS';
export const ADD_TO_INFO_FIELDS = 'ADD_TO_INFO_FIELDS';
export const RESET_INFO_ATTACHMENTS = 'RESET_INFO_ATTACHMENTS';
export const ADD_TO_INFO_ATTACHMENTS = 'ADD_TO_INFO_ATTACHMENTS';
export const ERROR = 'MAP_ERROR';
export const CLEAR_ERROR = 'CLEAR_MAP_ERROR'
export const SHOW_SIDE_PANEL = 'SHOW_SIDE_PANEL';
export const CLOSE_SIDE_PANEL = 'CLOSE_SIDE_PANEL';
export const SET_ACTIVE_TOOL = 'SET_ACTIVE_TOOL';
export const SET_ACTIVE_SLIDEOUT_TOOL = 'SET_ACTIVE_SLIDEOUT_TOOL';
export const SET_REOPEN_OVERVIEW = 'SET_REOPEN_OVERVIEW';
export const STORE_INFO_LAYERS = 'STORE_INFO_LAYERS';
export const STORE_INFO_TABLES = 'STORE_INFO_TABLES';
export const ADD_TO_INFO_TABLES = 'ADD_TO_INFO_TABLES';
export const RESET_QUERY_RESULTS_COUNT = 'RESET_QUERY_RESULTS_COUNT';
export const SET_INFO_ZOOM_GEOMETRY = 'SET_INFO_ZOOM_GEOMETRY';
export const SET_MAP_NOTIFICATION = 'SET_MAP_NOTIFICATION';
export const SET_SURVEY_REVIEW_GEOMETRY = 'SET_SURVEY_REVIEW_GEOMETRY';
export const SET_SURVEY_REVIEW_TABLES = 'SET_SURVEY_REVIEW_TABLES';
export const RESET_TO_SURVEY_REVIEW_GRIDS = 'RESET_TO_SURVEY_REVIEW_GRIDS';
export const RESET_TO_INITIAL = 'RESET_TO_INITIAL';
export const REPLACE_FEATURE_CHECK_RESULTS = 'REPLACE_FEATURE_CHECK_RESULTS';
export const ADD_TO_FEATURE_CHECK_RESULTS = 'ADD_TO_FEATURE_CHECK_RESULTS';
export const SET_HIGHLIGHT_INFO_TOOL = 'SET_HIGHLIGHT_INFO_TOOL'
export const SET_HIGHLIGHT_TABLE_TOOL = 'SET_HIGHLIGHT_TABLE_TOOL'
export const SET_FORCE_RELATIONSHIP_HIGHLIGHT = 'SET_FORCE_RELATIONSHIP_HIGHLIGHT';
export const SET_MAP_GO_TO = 'SET_MAP_GO_TO'
export const RESET_MAP_GO_TO = 'RESET_MAP_GO_TO'
export const BASEMAPS_LOADING = "BASEMAPS_LOADING";
export const BASEMAPS_SUCCESS = "BASEMAPS_SUCCESS";
export const SET_BASEMAP="SET_BASEMAP"
export const BASEMAPS_ERROR = "BASEMAPS_ERROR";
export const IGNORE = 'IGNORE';
export const surveyAreaMapType = '_SURVEY_AREA';
export const surveyInitiationMapType = '_SURVEY_INITIATION';
export const surveyAssignmentMapType = '_SURVEY_ASSIGNMENT';
export const surveyReviewMapType = '_SURVEY_REVIEW';
export const mapPageMapType = '_MAPPAGE';

// MapWidgetLayer styles
export const styleByTool: any = {
  [widgetTypes.move]: " btn-active ",
  [widgetTypes.point]: " btn-active "
}

export const styleBySlideoutTool: any = {
  [widgetTypes.overview]: "overview-tools",
  [widgetTypes.info]: "slideout-tools",
  [widgetTypes.query]: "slideout-tools",
}

export const styleByMapType: any = {
  [surveyAreaMapType]: "-survey-area-init",
  [surveyInitiationMapType]: "-survey-area-init",
  [surveyReviewMapType]: "-survey-review"
}

export enum searchFunctions {
  searchLayerFields = 'searchLayerFields',
  searchTableFields = 'searchTableFields',
  searchResults = 'searchResults'
}

export enum searchTypes {
  addressSearch = 'Address Search',
  layerSearch = 'Layer Search',
  tableSearch = 'Table Search'
}

/**
 * Summary: Get the information for a certain layer feature
 * Similar to searching a layer except only return information instead of dispatching anything.
 */
export const getSpecificFeatureInfo = async (
  layerId: number,
  searchField: any,
  searchText: string,
) => {
  const selectedMapService = store.getState().LoginReducer.selectedMapService.decodedLabel;
  const whereClause = whereQueryFieldTypeConversion(searchField.Name, searchField.FieldType, searchText);
  const queryingParams = concatQueryingParameters({
    [arcgisQueryParams.where]: whereClause,
    [arcgisQueryParams.outFields]: "*",
  });

  return await getArcgisQuery(selectedMapService, layerId, queryingParams)
    .then((res: any) => {
      if (!res) return;
      // include geometry properties to appease the arcgis geometry engine while drawing
      res.data.features[0]["geometryType"] = res.data.geometryType;
      res.data.features[0].geometry["spatialReference"] = res.data.spatialReference;
      return res.data.features[0];
    })
    .catch((error: any) => console.error(error));
};

/**
  Summary: Searches field(s) of a layer for a value
  @param layerId id of layer
  @param searchFields field(s) to search (can be multiple)
  @param searchText text being searched for
  @param mapType page mapType
  @param multiSearch true if searching multiple layers
  @param firstSearch first call of a multiple layer search
**/
export const searchLayerFields = (
  layerId:string,
  searchFields:any,
  searchText:string,
  mapType:String,
  multiSearch:boolean=false,
  firstSearch:boolean=false
) =>
async(dispatch:any) => {
  const selectedMapService = store.getState().LoginReducer.selectedMapService.label;
  const layers = store.getState().SettingsReducer.settings.Layers;

  //Remove brackets from search text if the field is some type of global id
  const isGlobalFieldType = Array.isArray(searchFields) 
    ? searchFields.some((field:any) => field.FieldType >= fieldType.globalID) 
    : searchFields.FieldType >= fieldType.globalID
  if (isGlobalFieldType) {
    searchText = searchText.replace("{", "").replace("}", "")
  }
  searchFields  = Array.isArray(searchFields) ? 
    searchFields.map((field:any) => field.Name).toString() : 
    searchFields.Name
  
  // const tkn= localStorage.getItem('ArcGISToken');
  // axios.get(`${selectedMapService}/` +
  // `find?contains=true&searchFields=${searchFields}&searchText=${searchText}&f=json&token=${tkn}&sr=102100&` +
  // `returnGeometry=true&layers=${layerId}&returnFieldName=true`)
  getSearchLayerFields(selectedMapService, searchFields, searchText, layerId)
    .then((res:any) => {
      if (firstSearch) {
        dispatch({
          type: SET_MAP_NOTIFICATION + mapType,
          message: "Fetching results...",
        });
      }
      if (isEmpty(res.data.results)) {
        // true when searching for "All" layers
        if (multiSearch) {
          dispatch({
            type: ADD_TO_SEARCH_SEEN + mapType,
            searchSeen: layerId,
            endNum: layers.length,
          });
        } else {
          dispatch({
            type: SET_MAP_NOTIFICATION + mapType,
            message: "0 results found!",
          });
        }
      } else {
        dispatch({
          type: SET_MAP_NOTIFICATION + mapType,
          message: "Fetching results...",
        })
        dispatch({
          type: ADD_TO_LAYER_SEARCH + mapType,
          searchResults: res.data.results,
          searchType: searchTypes.layerSearch,
        });
      }
    })
    .catch((err:any) => {
      dispatch({
        type: ERROR,
        error: {
          message: err,
          type: searchFunctions.searchLayerFields,
        },
      });
    });
}

/**
  Summary: Searches field of a table for a value
  @param layerId id of layer
  @param whereClause SQL where clause
  @param mapType page mapType
  @param multiSearch true if searching multiple layers/fields
  @param firstSearch first call of a multiple table search
  @param endNum replace default endNum
**/
export const searchTableFields = (
  layerId:string,
  whereClause:string,
  mapType:String,
  multiSearch:boolean=false,
  firstSearch:boolean=false,
  endNum?:number
) =>
async(dispatch:any) => {
  const selectedMapService = store.getState().LoginReducer.selectedMapService.decodedLabel;
  const tables = store.getState().SettingsReducer.settings.Tables;
  // const tkn= localStorage.getItem('ArcGISToken');
  // axios.get(`${selectedMapService}/${layerId}/` +
  // `query?where=${whereClause}&outFields=*&returnGeometry=true&f=json&token=${tkn}`)
  getSearchTableFields(selectedMapService,layerId,whereClause)
  .then((res:any) => {
    res = mapQueryResults(res)
    res.data.tableId = layerId
    if (firstSearch) {
      dispatch({
        type: SET_MAP_NOTIFICATION + mapType,
        message: "Fetching results..."
      })
    }
    if (isEmpty(res.data.features)) {
      // true when searching for "All" layers/fields
      if (multiSearch) {
        dispatch({
          type: ADD_TO_SEARCH_SEEN + mapType,
          searchSeen: layerId,
          endNum: endNum || tables.length,
        })
      } else {
        dispatch({
          type: SET_MAP_NOTIFICATION + mapType,
          message: "0 results found!"
        })
      }
    } else {
      dispatch({
        type: SET_MAP_NOTIFICATION + mapType,
        message: "Fetching results...",
      })
      dispatch({
        type: ADD_TO_TABLE_SEARCH + mapType,
        searchResults: res.data
      })
    }
  })
  .catch((err:any) => {
    dispatch({
      type:ERROR,
      error:{
        message:err,
        type: searchFunctions.searchTableFields
      }
    })
  })
}

export const getBaseMaps =
(mapService: { label: string }) => async (dispatch: any) => {
  dispatch({ type: BASEMAPS_LOADING });

  // Todo: Remove commented out code once this is tested with real token
  // const result = await axios.get(
  //   `${urlConfig.reactLanternNewServerUrl}/v1/maps/${mapService.label}/externallayers`
  // );
  const result: any = await getMapExternalLayers(mapService.label);
  const basemapsList = result.data;
  const newBasemapList: any = [];

  basemapsList.forEach(
    (baseMap: {
      checked: boolean;
      defaultVisibility: boolean;
      defaultPosition: number;
    }) => {
      baseMap.checked = baseMap.defaultVisibility ? true : false;
      newBasemapList[baseMap.defaultPosition] = baseMap;
    }
  );

  const customBaseMapList = await defineCustomBasemap(newBasemapList);

  dispatch({
    type: BASEMAPS_SUCCESS,
    basemapsList: newBasemapList,
    customBaseMapList,
  });
};

export const setSelectedBaseMap =
  ({ basemapsList, basemap, checked, opacity }: any) =>
  async (dispatch: any) => {

    let basemapListObj = _.mapKeys(basemapsList, "name");

    if (basemap !== undefined && checked !== undefined) {
      basemapListObj = {
        ...basemapListObj,
        [basemap.name]: { ...basemap, checked },
      };
    }
    if (basemap !== undefined && opacity !== undefined) {
      basemapListObj = {
        ...basemapListObj,
        [basemap.name]: { ...basemap, defaultOpacity: opacity },
      };
    }

    const newBasemapList = Object.values(basemapListObj);
    const customBaseMapList = await defineCustomBasemap(newBasemapList);

    dispatch({
      type: SET_BASEMAP,
      basemapsList: newBasemapList,
      customBaseMapList,
    });
  };

/**
 * Summary: Store layer information in info results
 * @param infoResults: results to store
 * @param layers: layer information from settings reducer
 * @param tables: table information from settings reducer
 * @param mapType: page's map type
 * @param polygonType: polygon type for current page
 * @param activeTool: active tool populating info layers
 */
export const storeInfoLayers = (
  infoResults:any, 
  layers:any, 
  tables:any, 
  mapType:string, 
  polygonType:any, 
  activeTool:any
) => async(dispatch:any) =>  {
  const autoOpenTools = [widgetTypes.search, widgetTypes.query];
  const shouldAutoOpenSlideout = autoOpenTools.includes(activeTool);
  dispatch({
    type: STORE_INFO_LAYERS + mapType,
    infoResults: infoResults,
    layers: layers,
    tables: tables,
    polygonType: polygonType,
    replaceTables: activeTool !== widgetTypes.query && activeTool !== widgetTypes.info 
      && mapType !== surveyReviewMapType
  })
  if (shouldAutoOpenSlideout && !isEmpty(infoResults)) {
    // close side nav when opening Info Tool
    dispatch({type: SET_SIDE_NAV, isSideNavOpen: false})
    dispatch({type: SET_ACTIVE_TOOL + mapType, tool: widgetTypes.info})
    dispatch({type: SET_ACTIVE_SLIDEOUT_TOOL + mapType, tool: widgetTypes.info})
    dispatch({type: SHOW_SIDE_PANEL + mapType})
  }
}

/**
 * Summary: Store table information in info results
 * @param infoResults: results to store
 * @param tables: table information from settings reducer
 * @param layers: layer information from settings reducer
 * @param mapType: page's map type
 * @param activeTool: active tool populating info tables
 * @param location location from uselocation (get current url)
 * @param replaceTables whether to replace the tables currently stored
 */
export const storeInfoTables = (
  infoResults:any,
  tables:any,
  layers:any,
  mapType:string,
  activeTool:any=null,
  location:any=null,
  replaceTables:boolean=true
) => async(dispatch:any) =>  {
  const autoOpenTools = [widgetTypes.search, widgetTypes.query];
  const shouldAutoOpenSlideout = autoOpenTools.includes(activeTool);
  dispatch({
    type: STORE_INFO_TABLES + mapType,
    infoResults: infoResults,
    tables: tables,
    layers: layers,
    replaceLayers: activeTool !== widgetTypes.query && (((mapType === surveyInitiationMapType ||
      mapType === surveyAreaMapType) && location.search === "") || mapType === mapPageMapType),
    replaceTables: replaceTables
  })
  if (shouldAutoOpenSlideout && !isEmpty(infoResults) && infoResults.features?.length !== 0) {
    // close side nav when opening Info Tool
    dispatch({type: SET_SIDE_NAV, isSideNavOpen: false})
    dispatch({type: SET_ACTIVE_TOOL + mapType, tool: widgetTypes.info})
    dispatch({type: SET_ACTIVE_SLIDEOUT_TOOL + mapType, tool: widgetTypes.info})
    dispatch({type: SHOW_SIDE_PANEL + mapType})
  }
}

/**
 * Summary: Add table information to info results
 * @param infoResults: results to store
 * @param tables: table information from settings reducer
 * @param layers: layer information from settings reducer
 * @param mapType: page's map type
 * @param activeTool: active tool populating info tables
 */
export const addToInfoTables = (infoResults:any, tables:any, layers:any, mapType:string, activeTool:any) => async(dispatch:any) =>  {
  const autoOpenTools = [widgetTypes.search, widgetTypes.query];
  const shouldAutoOpenSlideout = autoOpenTools.includes(activeTool);
  dispatch({
    type: ADD_TO_INFO_TABLES + mapType,
    infoResults: infoResults,
    tables: tables,
    layers: layers,
    activeTool: activeTool
  })
  if (shouldAutoOpenSlideout && !isEmpty(infoResults) && infoResults.features?.length !== 0) {
    // close side nav when opening Info Tool
    dispatch({type: SET_SIDE_NAV, isSideNavOpen: false})
    dispatch({type: SET_ACTIVE_TOOL + mapType, tool: widgetTypes.info})
    dispatch({type: SET_ACTIVE_SLIDEOUT_TOOL + mapType, tool: widgetTypes.info})
    dispatch({type: SHOW_SIDE_PANEL + mapType})
  }
}

/**
  Summary: Retrieve all the children of an item

  @param itemRelationships relationship data of all the children of an item
  @param index index of the
  @param mapType page mapType
  @param setNumOfAttachment set state for number of attachments for this item

  @return child items of layer
**/
export const retrieveInfoChildren = (itemRelationships: any, index: number, mapType: string, setNumOfAttachment: any) =>
async(dispatch: any) => {
  const selectedMapServiceName = store.getState().LoginReducer.selectedMapService.label;
  const selectedMapService = store.getState().LoginReducer.selectedMapService.decodedLabel;
  const layers = store.getState().SettingsReducer.settings.Layers;

  let fields: any = [];
  let promises: any = [];
  let featureLayerIds: Array<number> = [];
  const tkn= localStorage.getItem('ArcGISToken');
  itemRelationships.forEach((itemRelationship: any) => {
    fields.push(itemRelationship.fields)
    if (itemRelationship.isLayer) {
      featureLayerIds.push(itemRelationship.id)
      promises.push(
        // axios.post(
        //   `${selectedMapService}/${itemRelationship.id}/query?` +
        //   `where=${whereQueryFieldTypeConversion(itemRelationship.fieldName, itemRelationship.fieldType, itemRelationship.fieldValue)}` +
        //   `&returnGeometry=true&outSR=102100&f=json&token=${tkn}&outFields=*`
        // )
        getInfoChildren(
          selectedMapService,
          itemRelationship.id,
          whereQueryFieldTypeConversion(
            itemRelationship.fieldName,
            itemRelationship.fieldType,
            itemRelationship.fieldValue
          )
        ).catch((err: any) => {
          dispatch({
            type: ERROR,
            error: {
              message: err.message,
              type: "retrieveLayerChild",
            },
          });
        })
      );
    } else {
      featureLayerIds.push(itemRelationship.id + layers.length)
      promises.push(
        getAllRelationship(
          {
            "where": `${itemRelationship.fieldName}='${itemRelationship.fieldValue}'`,
            "mapservicename": `${selectedMapServiceName}`,
            "enforcelimit": "false",
            "outfields": "*"
          }, itemRelationship)
          .catch((err) => {
          dispatch({
            type: ERROR,
            error: {
              message: err.message,
              type: "retrieveTableChild"
            }
          })
        })
      )
    }
  })
  dispatch({
    type: ADD_TO_INFO_FIELDS + mapType,
    infoFields: fields,
    itemIndex: index
  })
  Promise.all(promises).then((results) => {
    let ids: any = [];
    let data: any = [];
    let attachments: any = [];

    results.forEach((res: any, index: number) => {
      // child array for layer items
      if (res.data.features) {
        ids.push(featureLayerIds[index])
        data.push(res.data.features.map((feature: any) => {
          return mapAttribute(feature.attributes)
        }))
      // child array for table items
      } else if (res.data.Rows) {
        ids.push(featureLayerIds[index])
        data.push(res.data.Rows.map((row: any) => {
          return mapAttribute(row.Attributes)
        }))
        // table item is Attachments
        if (res.data.TableId === 0) {
          res.data.Rows.forEach((row: any) => {
            attachments.push(row.Attributes)
          })
        }
      // axios call error (eg; query fields are null)
      } else {
        ids.push([])
        data.push([])
      }
      setNumOfAttachment(attachments.length)
    })
    dispatch({
      type: ADD_TO_INFO_INDEX + mapType,
      infoIndex: data
    })
    dispatch({
      type: ADD_TO_INFO_IDS + mapType,
      infoIds: ids,
      itemIndex: index
    })
    dispatch({
      type: ADD_TO_INFO_CHILD + mapType,
      infoChildren: data,
      itemIndex: index
    })
    dispatch({
      type: ADD_TO_INFO_ATTACHMENTS + mapType,
      infoAttachments: attachments
    })
  })
}

/**
 * Summary: Check if user is allowed to edit these features
 * @param userId user's id
 * @param features dictionary of features to check where key is layer and value is an array of object ids
 * @param mapType page mapType
 * @param add whether to add to the current feature check results, otherwise replace them
 */
//////HEREHEREHERE - add edit protection for uneditable layers
export const checkForEditProtection = (
  userId:number,
  features:{[index: number]:any},
  mapType:string,
  add:boolean=false
) => async(dispatch: any) => new Promise((resolve:any) => {
  const selectedMapServiceName = store.getState().LoginReducer.selectedMapService.label;
  const selectedUserRole=store.getState().LoginReducer.selectedUserRole
  interface IRequestBody {
    features: Array<Object>
  }
  let requestBody: IRequestBody = {
    features: []
  }

  for (let layer in features) {
    requestBody.features.push(
      {
        layerId: layer,
        objectIDs: features[layer]
      }
    )
  }

  if (requestBody.features.length > 0) {
    // return axios.post(`${urlConfig.reactLanternNewServerUrl}/v1/maps/${selectedMapServiceName}/layereditrules/${userId}/${selectedUserRole}`, requestBody)
    getLayerEditRules(selectedMapServiceName, userId, selectedUserRole, requestBody) 
    .then((res) => {
      //Parse JSON booleans
      res.data.forEach((layer:any, index:number) => {
        Object.keys(layer.objectsEdit).forEach((objectId:any) => {
          layer.objectsEdit[objectId] = JSON.parse(layer.objectsEdit[objectId])
        })
        res.data[index].objectsEdit = layer.objectsEdit
      })
      if (add) {
        dispatch({type: ADD_TO_FEATURE_CHECK_RESULTS + mapType, featureCheck: res.data})
      }
      else {
        dispatch({type: REPLACE_FEATURE_CHECK_RESULTS + mapType, featureCheck: res.data})
      }
      resolve(res)
    })
    .catch((err:any) => {
      dispatch({
        type: ERROR,
        error: {
          message: err.message,
          type: "editProtection"
        }
      })
      resolve(err)
    })
  }
})

const initialState:any = {
  activeGridType: '',
  activeTool: widgetTypes.move,
  //Selected grids and points from manual selection
  selectedGrids: [],
  selectedLines: [],
  selectedPoints: [],
  searchResults: [],
  //Selected grids and points from search/query results
  searchGrids: [],
  searchLines: [],
  searchPoints: [],
  searchSeen: [],
  searchType: searchTypes.layerSearch,
  slideOutInfo: {
    isOpen: false,
    activeSlideoutTool: widgetTypes.overview,
    reopenOverview: false
  },
  infoResults: {
    layers: [],
    tables: []
  },
  infoGeometry: [],
  infoLayerName: '',
  infoIndex: [],
  infoIds: [],
  infoChildren: [],
  infoFields: [],
  infoAttachments: [],
  queryResultsCount: 0,
  surveyReviewGrids: [],
  surveyReviewLines: [],
  surveyReviewPoints: [],
  surveyReviewTables: [],
  mapNotifMessage: {
    message: '',
    success: true
  },
  featureCheck: [],
  highlightedItemInfoTool: {},
  relationshipForceHighlight: false,
  mapGoTo: {},
  basemapsList: [],
  customBaseMapList: "",
}

export default function createMapReducerWithType(mapType = '') {
  return function reducer (state = initialState, action:any) {
    switch(action.type) {
      case RESET_SEARCH + mapType: {
        return {
          ...state,
          searchResults: []
        }
      }

      case ADD_TO_LAYER_SEARCH + mapType: {
        if (isUndefined(action.searchResults)) {
          action.searchResults = []
        }
        return {
          ...state,
          searchResults: state.searchResults.concat(action.searchResults),
          searchType: searchTypes.layerSearch
        }
      }

      case ADD_TO_TABLE_SEARCH + mapType: {
        if (isUndefined(action.searchResults)) {
          action.searchResults = []
        }
        return {
          ...state,
          searchResults: state.searchResults.concat(action.searchResults),
          searchType: searchTypes.tableSearch
        }
      }

      case RESET_INFO_INDEX + mapType: {
        return {
          ...state,
          infoIndex: []
        }
      }

      case ADD_TO_INFO_INDEX + mapType: {
        let newInfoIndex: any = [];
        action.infoIndex.forEach((parent: Array<Object>) => {
          parent.forEach((child: any) => {
            let GlobalID = child.GlobalID
            if (!state.infoIndex.includes(GlobalID)) {
              newInfoIndex.push(GlobalID)
            }
          })
        })
        return {
          ...state,
          infoIndex: [...state.infoIndex, ...newInfoIndex]
        }
      }

      case RESET_INFO_IDS + mapType: {
        return {
          ...state,
          infoIds: new Array(action.infoLength).fill([])
        }
      }

      case ADD_TO_INFO_IDS + mapType: {
        let infoIds = [...state.infoIds];
        infoIds[action.itemIndex] = action.infoIds;
        return {
          ...state,
          infoIds: infoIds
        }
      }

      case RESET_INFO_CHILD + mapType: {
        return {
          ...state,
          infoChildren: new Array(action.infoLength).fill([])
        }
      }

      case ADD_TO_INFO_CHILD + mapType: {
        let infoChildren = [...state.infoChildren];
        infoChildren[action.itemIndex] = action.infoChildren;
        return {
          ...state,
          infoChildren: infoChildren
        }
      }

      case RESET_INFO_FIELDS + mapType: {
        return {
          ...state,
          infoFields: new Array(action.infoLength).fill([])
        }
      }

      case ADD_TO_INFO_FIELDS + mapType: {
        let infoFields = [...state.infoFields];
        infoFields[action.itemIndex] = action.infoFields;
        return {
          ...state,
          infoFields: infoFields
        }
      }

      case RESET_INFO_ATTACHMENTS + mapType: {
        return {
          ...state,
          infoAttachments: []
        }
      }

      case ADD_TO_INFO_ATTACHMENTS + mapType: {
        return {
          ...state,
          infoAttachments: action.infoAttachments
        }
      }

      case RESET_SEARCH_SEEN + mapType: {
        return {
          ...state,
          searchSeen: []
        }
      }

      case ADD_TO_SEARCH_SEEN + mapType: {
        let updateMessage = false;
        let searchSeenUpdate = [...state.searchSeen, action.searchSeen];
        if (searchSeenUpdate.length === action.endNum) {
          updateMessage = true;
        }
        return {
          ...state,
          searchSeen: searchSeenUpdate,
          mapNotifMessage: {
            message: updateMessage ? "0 results found!" : state.mapNotifMessage.message,
            // TODO: phase out false notifications for "Fetching results..." Search Tool notification
            // Currently all Search Tool notifications are false for "0 results found!"
            success: false
          }
        }
      }

      case SET_GEOMETRY + mapType: {
        let grids = [...state.selectedGrids]
        let lines = [...state.selectedLines]
        let points = [...state.selectedPoints]

        if (action.grids) {
          grids = action.grids
          // remove duplicate grids from list when rectangle select
          //is used above an already selected area ( using point )
          if(action.duplicationCheck) {
            grids = removeDuplicateFeatures(grids)
          }
        }
        if (action.lines) {
          lines = action.lines
          if(action.duplicationCheck) {
            lines = removeDuplicateFeatures(lines)
          }
        }
        if (action.points) {
          points = action.points
          if(action.duplicationCheck) {
            points = removeDuplicateFeatures(points)
          }
        }

        return {
          ...state,
          selectedGrids: grids,
          selectedLines: lines,
          selectedPoints: points
        }
      }

      case SET_SEARCH_GEOMETRY + mapType: {
        let grids = [...state.searchGrids]
        let lines = [...state.searchLines]
        let points = [...state.searchPoints]

        if (action.grids) {
          grids = action.grids
          // remove duplicate grids from list when rectangle select
          //is used above an already selected area ( using point )
          if(action.duplicationCheck) {
            grids = removeDuplicateFeatures(grids)
          }
        }
        if (action.lines) {
          lines = action.lines
          if(action.duplicationCheck) {
            lines = removeDuplicateFeatures(lines)
          }
        }
        if (action.points) {
          points = action.points
          if(action.duplicationCheck) {
            points = removeDuplicateFeatures(points)
          }
        }

        return {
          ...state,
          searchGrids: grids,
          searchLines: lines,
          searchPoints: points
        }
      }

      case CLEAR_SEARCH_GEOMETRY + mapType: {
        return {
          ...state,
          searchGrids: [],
          searchLines: [],
          searchPoints: []
        }
      }

      case ADD_GEOMETRY + mapType: {
        let grids = [...state.selectedGrids]
        let lines = [...state.selectedLines]
        let points = [...state.selectedPoints]

        /***
         * Summary: Return true if no duplicates, remove duplicates and return false if there are
         * @param newFeatures features currently being selected by point tool
         *
         */
        const checkForPointToolDuplicates = (newFeatures:any, features: any) => {
          const objectIdField = newFeatures[0]?.layer?.objectIdField || "OBJECTID"
          let duplicate = false
          features.forEach((feature:any, i:number) => {
            if (!feature.reviewGrid && (!feature.sourceLayer || newFeatures[0].sourceLayer.layerId === feature.sourceLayer.layerId) &&
              (newFeatures[0].attributes[objectIdField] === feature.attributes[objectIdField])) {
              // grid was already selected, unselect the grid
              duplicate = true
              features.splice(i, 1)
            }
          })
          // if no duplicates were found, add the grid to selected grids
          if (!duplicate) {
            return false
          }
          return true
        }
        let duplicationFlag = false;

        if (action.pointTool) {
          if (action.grids && !checkForPointToolDuplicates(action.grids, grids)) {
            grids = grids.concat(action.grids)
          }
          if (action.lines && !checkForPointToolDuplicates(action.lines, lines)) {
            lines = lines.concat(action.lines)
          }
          if (action.points && !checkForPointToolDuplicates(action.points, points)) {
            points = points.concat(action.points)
          }
        }
        else {
          if (action.grids) {
            if((mapType === surveyInitiationMapType || mapType === surveyAreaMapType) && action.query === false) {
              duplicationFlag = true;
            }
            grids = grids.concat(action.grids);
            if(action.duplicationCheck || duplicationFlag) {
              grids = removeDuplicateFeatures(grids)
            }
          }
          if (action.lines) {
            lines = lines.concat(action.lines)
            if(action.duplicationCheck) {
              lines = removeDuplicateFeatures(lines)
            }
          }
          if (action.points) {
            points = points.concat(action.points)
            if(action.duplicationCheck) {
              points = removeDuplicateFeatures(points)
            }
          }
        }

        return {
          ...state,
          selectedGrids: grids,
          selectedLines: lines,
          selectedPoints: points
        }
      }

      case ADD_SEARCH_GEOMETRY + mapType: {
        let grids = [...state.searchGrids]
        let lines = [...state.searchLines]
        let points = [...state.searchPoints]

        if (action.grids) {
          grids = grids.concat(action.grids)
          if(action.duplicationCheck) {
            grids = removeDuplicateFeatures(grids)
          }
        }
        if (action.lines) {
          lines = lines.concat(action.lines)
          if(action.duplicationCheck) {
            lines = removeDuplicateFeatures(lines)
          }
        }
        if (action.points) {
          points = points.concat(action.points)
          if(action.duplicationCheck) {
            points = removeDuplicateFeatures(points)
          }
        }

        let newCount = state.queryResultsCount + grids.length + lines.length + points.length -
        state.searchGrids.length - state.searchLines.length - state.searchPoints.length

        return {
          ...state,
          searchGrids: grids,
          searchLines: lines,
          searchPoints: points,
          queryResultsCount: action.query? newCount:state.queryResultsCount
        }
      }

      case REMOVE_GEOMETRY + mapType: {
        let grids = [...state.selectedGrids]
        let lines = [...state.selectedLines]
        let points = [...state.selectedPoints]
        /**
         * Summary: return the new selectedGeometries after removing the geometriesToRemove
         * @param selectedGeometries the currently selected geometries
         * @param geometriesToRemove feature geometries to remove from selectedGeometries
         * @returns selectedGeometries with geometriesToRemove removed from it
         */
        const removeGeometries = (selectedGeometries: Array<any>, geometriesToRemove: Array<any>) => {
          return selectedGeometries.filter((selectedGeometry: any) => {
            return !geometriesToRemove.some((geometryToRemove: any) => {
              return (
                (mapType !== surveyReviewMapType || !selectedGeometry.reviewGrid) &&
                selectedGeometry.sourceLayer?.layerId === geometryToRemove.sourceLayer?.layerId &&
                selectedGeometry.attributes.OBJECTID === geometryToRemove.attributes.OBJECTID
              )
            })
          })
        }

        if (action.grids) {
          grids = removeGeometries(grids, Array.isArray(action.grids) ? action.grids : [action.grids])
        }
        if (action.lines) {
          lines = removeGeometries(lines, Array.isArray(action.lines) ? action.lines : [action.lines])
        }
        if (action.points) {
          points = removeGeometries(points, Array.isArray(action.points) ? action.points : [action.points])
        }

        return {
          ...state,
          selectedGrids: grids,
          selectedLines: lines,
          selectedPoints: points
        }
      }

      case SET_GRID_TYPE + mapType: {
        return {
          ...state,
          activeGridType: action.gridType
        }
      }

      case SET_SURVEY_REVIEW_GEOMETRY: {
        let grids = state.surveyReviewGrids.concat()
        let lines = state.surveyReviewLines.concat()
        let points = state.surveyReviewPoints.concat()
        if (action.grids) {
          grids = action.grids
        }
        if (action.lines) {
          lines = action.lines
        }
        if (action.points) {
          points = action.points
        }
        return {
          ...state,
          surveyReviewGrids: grids,
          surveyReviewLines: lines,
          surveyReviewPoints: points
        }
      }

      case SET_SURVEY_REVIEW_TABLES: {
        return {
          ...state,
          surveyReviewTables: action.tables
        }
      }

      case RESET_TO_SURVEY_REVIEW_GRIDS: {
        if (mapType === surveyReviewMapType) {
          return {
            ...state,
            selectedGrids: state.surveyReviewGrids.concat(),
            selectedLines: state.surveyReviewLines.concat(),
            selectedPoints: state.surveyReviewPoints.concat()
          }
        }
        return {...state}
      }

      case CLEAR_ALL_SELECTED_GEOMETRY + mapType: {
        if(mapType === surveyReviewMapType) {
          return {
            ...state,
            selectedLines: action?.preselectedSurveyReviewLines || [],
            selectedPoints: action?.preselectedSurveyReviewPoints || [],
            selectedGrids: action?.preselectedSurveyReviewGrids || [],
            searchLines: [],
            searchPoints: [],
            searchGrids: [],
            highlightedItemInfoTool: {}
          }
        }
        return {
          ...state,
          selectedLines: [],
          selectedPoints: [],
          selectedGrids: [],
          searchLines: [],
          searchPoints: [],
          searchGrids: [],
          highlightedItemInfoTool: {}
        }
      }

      case SHOW_SIDE_PANEL + mapType: {
        return {
          ...state,
          slideOutInfo: {
            ...state.slideOutInfo,
            isOpen: true
          }
        }
      }

      case CLOSE_SIDE_PANEL + mapType: {
        return {
          ...state,
          slideOutInfo: {
            ...state.slideOutInfo,
            isOpen: false
          }
        }
      }

      case SET_ACTIVE_TOOL + mapType: {
        return {
          ...state,
          activeTool: action.tool
        }
      }

      case SET_ACTIVE_SLIDEOUT_TOOL + mapType: {
        return {
          ...state,
          slideOutInfo: {
            ...state.slideOutInfo,
            activeSlideoutTool: action.tool,
          }
        }
      }

      case SET_REOPEN_OVERVIEW + mapType: {
        return {
          ...state,
          slideOutInfo: {
            ...state.slideOutInfo,
            reopenOverview: action.reopen
          }
        }
      }

      case STORE_INFO_LAYERS + mapType: {
        let layers: any[] = []
        action.infoResults.forEach((result:any) => {
          let geometry
          switch (result.geometry.type) {
            case 'polygon':
              geometry = {
                type: result.geometry.type,
                rings: result.geometry.rings,
                spatialReference: result.geometry.spatialReference
              }
              break
            case 'point':
              geometry = {
                type: result.geometry.type,
                x: result.geometry.x,
                y: result.geometry.y,
                spatialReference: result.geometry.spatialReference
              }
              break
            case 'polyline':
              geometry = {
                type: result.geometry.type,
                paths: result.geometry.paths,
                spatialReference: result.geometry.spatialReference
              }
              break
          }
          let layerName: string
          if (!result.layer) {
            layerName = result.featureLayerName || action.polygonType
          }
          else {
            layerName = result.layer.sourceJSON.name
          }
          let globalIdFieldName
          let objectIdFieldName
          let displayFieldName
          let fields
          let id: any
          let relationships: any
          let childRelationships
          action.layers.forEach((layer:any) => {
            if (layerName === layer.Name) {
              globalIdFieldName = layer.FeatureClass.GlobalIdFieldName
              objectIdFieldName = layer.FeatureClass.OidFieldName
              displayFieldName = layer.DisplayFieldName
              fields = layer.Fields
              id = layer.Id
              relationships = layer.FeatureClass.Relationships.map((relationship: any) => {
                if (relationship.DestinationIsLayer) {
                  relationship.DestinationDisplayFieldName = action.layers[relationship.DestinationId].DisplayFieldName;
                } else {
                  relationship.DestinationDisplayFieldName = action.tables[relationship.DestinationId].DisplayFieldName;
                }
                return relationship
              })
              childRelationships = relationships.filter((relationship: any) => {
                return (relationship.OriginId === id && relationship.OriginIsLayer)
              })
              childRelationships.forEach((childRelationship: any) => {
                if (childRelationship.DestinationIsLayer) {
                  childRelationship["DestinationDisplayName"] = action.layers[childRelationship.DestinationId].Name;
                } else {
                  childRelationship["DestinationDisplayName"] = action.tables[childRelationship.DestinationId].DisplayName;
                }
              })
            }
          })
          layers.push({
            attributes: result.attributes,
            layerName: layerName,
            globalIdFieldName: globalIdFieldName,
            objectIdFieldName: objectIdFieldName,
            displayFieldName: displayFieldName,
            geometry: geometry,
            fields: fields,
            id: id,
            relationships: relationships,
            childRelationships: childRelationships
          })
        })
        const tables = action.replaceTables? []:state.infoResults.tables.concat()
        return {
          ...state,
          infoResults: {
            layers: layers,
            tables: tables
          }
        }
      }

      case STORE_INFO_TABLES + mapType: {
        if (isUndefined(action.infoResults)) {
          action.infoResults = []
        }
        let newTables: any[] = []
        if (!Array.isArray(action.infoResults)) {
          action.infoResults = [action.infoResults]
        }
        action.infoResults.forEach((result:any) => {
          result.features.forEach((feature:any) => {
            let globalIdFieldName
            let tableName = ''
            let fields
            let id: any
            let relationships: any
            let childRelationships
            //Find table display name
            action.tables.forEach((table:any) => {
              if (table.Id === result.tableId - action.layers.length) {
                globalIdFieldName = table.GlobalIdFieldName
                tableName = table.DisplayName
                fields = table.Fields
                id = table.Id
                relationships = table.Relationships.map((relationship: any) => {
                  if (relationship.DestinationIsLayer) {
                    relationship.DestinationDisplayFieldName = action.layers[relationship.DestinationId].DisplayFieldName;
                  } else {
                    relationship.DestinationDisplayFieldName = action.tables[relationship.DestinationId].DisplayFieldName;
                  }
                  return relationship
                })
                childRelationships = relationships.filter((relationship: any) => {
                  return (relationship.OriginId === id && !relationship.OriginIsLayer)
                })
                childRelationships.forEach((childRelationship: any) => {
                  if (childRelationship.DestinationIsLayer) {
                    childRelationship["DestinationDisplayName"] = action.layers[childRelationship.DestinationId].Name;
                  } else {
                    childRelationship["DestinationDisplayName"] = action.tables[childRelationship.DestinationId].DisplayName;
                  }
                })
              }
            })
            //Tables do not have any geometry
            newTables.push({
              tableName: tableName,
              globalIdFieldName: globalIdFieldName,
              displayFieldName: result.displayFieldName,
              attributes: feature.attributes,
              fields: fields,
              id: id,
              relationships: relationships,
              childRelationships: childRelationships
            })
          })
        })
        return {
          ...state,
          infoResults: {
            layers: action.replaceLayers? []:state.infoResults.layers.concat(),
            tables: action.replaceTables? newTables:state.infoResults.tables.concat(newTables)
          }
        }
      }

      case ADD_TO_INFO_TABLES + mapType: {
        if (isUndefined(action.infoResults)) {
          action.infoResults = []
        }
        let newTables: any[] = state.infoResults.tables.concat()
        let newCount = state.queryResultsCount
        if (!Array.isArray(action.infoResults)) {
          action.infoResults = [action.infoResults]
        }
        action.infoResults.forEach((result:any) => {
          result.features.forEach((feature:any) => {
            let globalIdFieldName
            let tableName = ''
            let fields
            let id: any
            let relationships: any
            let childRelationships
            //Find table display name
            action.tables.forEach((table:any) => {
              if (table.Id === result.tableId - action.layers.length) {
                globalIdFieldName = table.GlobalIdFieldName
                tableName = table.DisplayName
                fields = table.Fields
                id = table.Id
                relationships = table.Relationships.map((relationship: any) => {
                  if (relationship.DestinationIsLayer) {
                    relationship.DestinationDisplayFieldName = action.layers[relationship.DestinationId].DisplayFieldName;
                  } else {
                    relationship.DestinationDisplayFieldName = action.tables[relationship.DestinationId].DisplayFieldName;
                  }
                  return relationship
                })
                childRelationships = relationships.filter((relationship: any) => {
                  return (relationship.OriginId === id && !relationship.OriginIsLayer)
                })
                childRelationships.forEach((childRelationship: any) => {
                  if (childRelationship.DestinationIsLayer) {
                    childRelationship["DestinationDisplayName"] = action.layers[childRelationship.DestinationId].Name;
                  } else {
                    childRelationship["DestinationDisplayName"] = action.tables[childRelationship.DestinationId].DisplayName;
                  }
                })
              }
            })
            //Tables do not have any geometry
            newTables.push({
              tableName: tableName,
              globalIdFieldName: globalIdFieldName,
              displayFieldName: result.displayFieldName,
              attributes: feature.attributes,
              fields: fields,
              id: id,
              relationships: relationships,
              childRelationships: childRelationships
            })
          })
        })
        newTables = newTables.filter((table:any, index:number, self:any) =>
          index === self.findIndex((t:any) => (
            t.id === table.id && t.attributes.OBJECTID === table.attributes.OBJECTID
          ))
        )
        if (action.activeTool === widgetTypes.query) {
          newCount += newTables.length - state.infoResults.tables.length
        }
        return {
          ...state,
          infoResults: {
            layers: state.infoResults.layers.concat(),
            tables: newTables
          },
          queryResultsCount: newCount
        }
      }

      case RESET_QUERY_RESULTS_COUNT: {
        return {
          ...state,
          queryResultsCount: 0
        }
      }

      case SET_INFO_ZOOM_GEOMETRY: {
        return {
          ...state,
          infoGeometry: action.infoGeometry,
          infoLayerName: action.infoLayerName
        }
      }

      case SET_MAP_NOTIFICATION + mapType: {
        return {
          ...state,
          mapNotifMessage: {
            message: action.message,
            success: action.success? action.success: false
          }
        }
      }

      case REPLACE_FEATURE_CHECK_RESULTS + mapType: {
        const featureCheck: {[index: number]:any} = {}
        action.featureCheck.forEach((layerFeatures:any) => {
          featureCheck[layerFeatures.layerId] = layerFeatures.objectsEdit
        })
        return {
          ...state,
          featureCheck: featureCheck
        }
      }

      case ADD_TO_FEATURE_CHECK_RESULTS + mapType: {
        const featureCheck = { ...state.featureCheck }
        action.featureCheck.forEach((layerFeatures:any) => {
          if (layerFeatures.layer in featureCheck) {
            featureCheck[layerFeatures.layerId] = {
              ...featureCheck[layerFeatures.layerId],
              ...layerFeatures.objectsEdit
            }
          }
          else {
            featureCheck[layerFeatures.layerId] = layerFeatures.objectIDs
          }
        })
        return {
          ...state,
          featureCheck: featureCheck
        }
      }

      case SET_HIGHLIGHT_INFO_TOOL + mapType: {
        return {
          ...state,
          highlightedItemInfoTool: action.highlightedItemInfoTool
        }
      }

      case SET_FORCE_RELATIONSHIP_HIGHLIGHT + mapType: {
        return {
          ...state,
          relationshipForceHighlight: action.relationshipForceHighlight
        }
      }

      //create new state, surveytable  array of objects 
      case SET_HIGHLIGHT_TABLE_TOOL + mapType: {
        return {
          ...state,
          highlightedItemTable: action.highlightedItemTable
        }
      }

      case BASEMAPS_SUCCESS:
        return {
          ...state,
          basemapsList:action.basemapsList,
          customBaseMapList: action.customBaseMapList,
        };

      case SET_BASEMAP:
        return{
          ...state,
          customBaseMapList: action.customBaseMapList,
          basemapsList:action.basemapsList
        }

      case SET_MAP_GO_TO: {
        return {
          ...state,
          mapGoTo: {
            center: action.center,
            zoom: action.zoom
          }
        }
      }

      case RESET_MAP_GO_TO: {
        return {
          ...state,
          mapGoTo: {}
        }
      }

      case ERROR: {
        return {
          ...state,
          error: action.error
        }
      }

      case CLEAR_ERROR: {
        return {
          ...state,
          error: {}
        }
      }

      default:
        return state
      }
    }
  }
