import axios from "axios";
import qs from "qs";
import { store } from "app/store";
import { urlConfig } from "index";
import { esriGeometryType } from "common/Map/SelectFeatures";
import {
  SET_GRID_TYPE,
  surveyInitiationMapType,
  SET_GEOMETRY,
} from "common/Map/MapDucks";
import { axiosErrorHandler } from "utils/AxiosUtils";
import {
  getTableIdByName,
  getLayerMapServiceIdByName,
  getGridIdFieldByName,
  getMapServiceIdByPolygonType,
  getGridLayerFieldByPolygonType,
  PolygonTypes,
  Polygon,
  gridLayerFields,
  getLayerOrTableByName,
} from "utils/GridUtils";
import { convertDate } from "utils/DateUtils";
import {
  formatData,
  getUrlParam,
  surveyAreaOperations,
} from "utils/FieldsUtils";
import { 
  getSurveyPlanCategoryConfigurations,
  getConfiguration,
  getValidateCSLEntries,
  postSurveyValidation,
  createSurveyArea,
  configurationScheduleForecast,
  getArcgisQuery,
  getTableQueryBySurveyAreaID,
  getValidCSLEntriesFromSearch
} from 'api';

import { massageGridData } from "utils/MapServiceUtils";
import { postLockSurveyArea } from "features/SurveyArea/SurveyAreaDucks";
import { getTableConfigData } from "common/Tables/PaginatedTable/TableUtils/TableConfigUtils";
import { mapAttribute, mapQueryResults } from "utils/NameMappingUtils";
import { arcgisQueryParams, concatQueryingParameters } from "api/ArcGIS/constants";
import { encodeUriAll } from "utils/UrlUtils";
import { constructFiltersForQuery, constructSortForQuery, isObjectNotEmpty } from 'utils/GridUtils';
import { isNil, map } from "lodash";

export const SET_SURVEY_INIT_TABLE_CONFIG = "SET_SURVEY_INIT_TABLE_CONFIG";
export const EXISTING_AREA_SUCCESS = "SURVEYINITIATION_EXISTING_AREA_SUCCESS";
export const EXISTING_AREA_LOADING = "SURVEYINITIATION_EXISTING_AREA_LOADING";
export const EXISTING_AREA_BY_PAGE_LOADING = "SURVEYINITIATION_EXISTING_AREA_BY_PAGE_LOADING";
export const EXISTING_AREA_BY_PAGE_WITH_FILTERS_LOADING = "SURVEYINITIATION_EXISTING_AREA_BY_PAGE_WITH_FILTERS_LOADING";
export const EXISTING_AREA_PRE_FILTER_VALUES_SUCCESS = "SURVEYINITIATION_EXISTING_AREA_PRE_FILTERS_SUCCESS";
export const EXISTING_AREA_ALL_PRE_FILTER_VALUES_SUCCESS = "SURVEYINITIATION_EXISTING_AREA_ALL_PRE_FILTERS_SUCCESS";
export const EXISTING_AREA_PRE_FILTER_VALUES_LOADING = "SURVEYINITIATION_EXISTING_AREA_PRE_FILTERS_SUCCESS";
export const ERROR = "SURVEYINITIATION_ERROR_MSG";
export const SET_SLIDEOUT_MODE = "SURVEYINITIATION_SET_SLIDEOUT_MODE";
export const POST_LOADING = "SURVEYINITIATION_POST_LOADING";
export const POST_SUCCESS = "SURVEYINITIATION_POST_SUCCESS";
export const POST_ERROR = "SURVEYINITIATION_POST_ERROR";
export const CLEAR_SURVEY_AREA_DATA = "SURVEYINITIATION_CLEAR_SURVEY_AREA_DATA";
export const CLEAR_STATUS_MESSAGE = "SURVEYINITIATION_CLEAR_STATUS_MESSAGE";
export const CLEAR_ERROR = "SURVEYINITIATION_CLEAR_ERROR";
export const SET_SURVEY_PLAN_ID = "SURVEYINITIATION_SET_SURVEY_PLAN_ID";
export const SURVEY_INIT_TABLE_CONFIG = "SURVEY_INIT_TABLE_CONFIG";
export const SURVEY_INIT_TABLE_CONFIG_SUCCESS =
  "SURVEY_INIT_TABLE_CONFIG_SUCCESS";
export const SlideoutModes = Object.freeze({
  NEW: "new",
  NEW_VIEW: "new-view",
  EXISTING: "existing",
  EXISTING_VIEW: "existing-view",
});
const FETCH_SURVEY_AREA_LOADING = "SURVEYINITIATION_FETCH_SURVEY_AREA_LOADING";
const FETCH_SURVEY_AREA_SUCCESS = "SURVEYINITIATION_FETCH_SURVEY_AREA_SUCCESS";
const SET_STATUS_MESSAGE = "SURVEYINITIATION_SET_STATUS_MESSAGE";
const SET_PLAN_CATEGORIES = "SURVEYINITIATION_SET_PLAN_CATEGORIES";
export const SET_CSV_FILE_NAME = "SURVEYINITIATION_SET_CSV_FILE_NAME";
export const INVALID_CSV_FILE_UPLOAD =
  "SURVEYINITIATION_INVALID_CSV_FILE_UPLOAD";
const VALID_CSV_FILE_UPLOAD = "SURVEYINITIATION_VALID_CSV_FILE_UPLOAD";
export const RESET_CSV_FILE_UPLOADED =
  "SURVEYINITIATION_RESET_CSV_FILE_UPLOADED";
const SET_VALIDATED_CSL_ENTRIES = "SURVEYINITIATION_SET_VALIDATED_CSL_ENTRIES";
const ADD_TO_VALIDATED_CSL_ENTRIES = "SURVEYINITIATION_ADD_TO_VALIDATED_CSL_ENTRIES";
export const RESET_VALIDATED_CSL_ENTRIES =
  "SURVEYINITIATION_RESET_VALIDATED_CSL_ENTRIES";
export const SET_SELECTED_CORROSION_AREAS =
  "SURVEYINITIATION_SET_SELECTED_CORROSION_AREAS";
export const SET_SELECTED_PIPELINE_IDS =
  "SURVEYINITIATION_SET_SELECTED_PIPELINE_IDS";
export const DISABLE_SELECTION_TOOLS = "SURVEYINITIATION_DISABLE_SELECTION_TOOLS";
export const PLAN_SURVEY_RESET_SURVEY_WORK_TYPE = "PLAN_SURVEY_RESET_SURVEY_WORK_TYPE";
export const surveyInitiationDefaultStatusOptions = []
export const createdOnField = "CreatedOn";

/**
 * summary: get the configuraton data for the existing areas table
 */
export const getExistingInitTableConfigData =
  (surveyWorkType: string) => async (dispatch: any) => {
    const tableName = "Survey Initiation List";
    const actionType = SURVEY_INIT_TABLE_CONFIG;
    dispatch(getTableConfigData(tableName, actionType, surveyWorkType));
  };

// function to get a list of the filter values for all the rows
//TODO: refactor function
export const getExistingAreasDistinctFilteredRows = (filteredSortColumnMap?: any,
  existingInitTableColumns?: any,
  initialLoad?: boolean) => async (dispatch: any) => {
  dispatch({ type: EXISTING_AREA_PRE_FILTER_VALUES_LOADING });
  const mapServiceId = getTableIdByName("Survey Area");
  const selectedMapService =
    store.getState().LoginReducer.selectedMapService.decodedLabel;
  let formattedWhereClause = `1=1 and Status IN ('Active', 'Unlocked', 'Locked')`;
  let formattedOrderByClause = `${createdOnField} DESC`;

  if(isObjectNotEmpty(filteredSortColumnMap)){
    formattedWhereClause = constructFiltersForQuery(formattedWhereClause, filteredSortColumnMap);
    formattedOrderByClause = constructSortForQuery(formattedOrderByClause, filteredSortColumnMap);
  }
  let defaultOutFields = "SurveyAreaName, PolygonType, SurveyCategory, CreatedBy, Region, District, Status, SurveyYear, CreatedOn";
  defaultOutFields = existingInitTableColumns?.length > 0 ? existingInitTableColumns.toString() + `,${createdOnField}` : defaultOutFields;

  const queryingParams = concatQueryingParameters({
    [arcgisQueryParams.where]: encodeUriAll(formattedWhereClause),
    [arcgisQueryParams.outFields]: defaultOutFields,
    [arcgisQueryParams.returnDistinctValues]: "true",
    [arcgisQueryParams.orderByFields]: encodeUriAll(formattedOrderByClause)
  });
  
  getArcgisQuery(
    selectedMapService,
    mapServiceId,
    queryingParams
  )
    .then((res: any) => {
      axiosErrorHandler(res);
      if (res.data.error) {
        throw res.data.error;
      }
      res=mapQueryResults(res)
      if (initialLoad) {
        dispatch({
          type: EXISTING_AREA_ALL_PRE_FILTER_VALUES_SUCCESS,
          existingSurveyAreasAllPreFilterValues: res.data.features
        });
      } else {
        dispatch({
          type: EXISTING_AREA_PRE_FILTER_VALUES_SUCCESS,
          existingSurveyAreasPreFilterValues: res.data.features
        });
      }
    })
    .catch((err: any) => {
      dispatch({
        type: ERROR,
        error: {
          message: err,
          type: "fetchExisting",
        },
      });
      dispatch({ type: "SURVEYINITIATION_EXISTING_AREA_ERROR" });
    });
};

// function to get a list of existing survey areas based on page number
export const getExistingAreasByPage =
  (
    pageSize: number,
    includeTotalCount?: boolean,
    pageNumber?: number,
    filteredSortColumnMap?: any,
    totalCount?: number
  ) =>
  async (dispatch: any) => {
    dispatch({ type: EXISTING_AREA_BY_PAGE_LOADING });
    const mapServiceId = getTableIdByName("Survey Area");
    const selectedMapService =
      store.getState().LoginReducer.selectedMapService.decodedLabel;

    let formatWhereClause: any = `Status IN ('Active', 'Unlocked', 'Locked')`;
    let formatOrderByClause: any = "CreatedOn DESC";
    if (isObjectNotEmpty(filteredSortColumnMap)) {
      formatWhereClause = constructFiltersForQuery(
        formatWhereClause,
        filteredSortColumnMap
      );
      formatOrderByClause = constructSortForQuery(
        formatOrderByClause,
        filteredSortColumnMap
      );
    }

    let dataQueryParams: any = {
        [arcgisQueryParams.where]: encodeUriAll(formatWhereClause),
        [arcgisQueryParams.outFields]: "*",
        [arcgisQueryParams.orderByFields]: formatOrderByClause,
        [arcgisQueryParams.resultRecordCount]: pageSize,
      },
      totalCountQueryParams: any;

    if (includeTotalCount) {
      totalCountQueryParams = concatQueryingParameters({
        ...dataQueryParams,
        [arcgisQueryParams.returnCountOnly]: "true",
      });
    }
    if (pageNumber && pageNumber > 1) {
      dataQueryParams = {
        ...dataQueryParams,
        [arcgisQueryParams.resultOffset]: (pageNumber - 1) * pageSize,
      };
    }
    const queryingParams = concatQueryingParameters(dataQueryParams);

    if (includeTotalCount) {
      //gets the data for a specific page with total count
      getArcgisQuery(
        selectedMapService,
        mapServiceId,
        totalCountQueryParams
      ).then((res: any) => {
        axiosErrorHandler(res);
        if (res.data.error) {
          throw res.data.error;
        }
        const totalCount = res.data.count;
        dispatch(
          getSurveyExistingAreasData(
            queryingParams,
            filteredSortColumnMap,
            totalCount
          )
        );
      });
    } else {
      //gets the data for a specific page without total count
      dispatch(
        getSurveyExistingAreasData(
          queryingParams,
          filteredSortColumnMap,
          totalCount
        )
      );
    }
  };

export const getSurveyExistingAreasData = (queryParams: any, filteredSortColumnMap?: any, totalCount?: any) => async (dispatch: any) =>{
  const mapServiceId = getTableIdByName("Survey Area");
  const selectedMapService =
    store.getState().LoginReducer.selectedMapService.decodedLabel;
  getArcgisQuery(
    selectedMapService,
    mapServiceId,
    queryParams
  )
  .then((res: any) => {
    axiosErrorHandler(res);
    if (res.data.error) {
      throw res.data.error;
    }
    res = mapQueryResults(res);
    let existingAreas:object = { data: res.data.features };
    //adding the totalCount value to the object for the first page load
    existingAreas = !isNil(totalCount) ? {...existingAreas, totalCount} : existingAreas;
    //adding the filters to the object if filters exists
    existingAreas = isObjectNotEmpty(filteredSortColumnMap) ? {...existingAreas, existingSurveyAreasFilters: {...filteredSortColumnMap}} : {...existingAreas, existingSurveyAreasFilters: {}};
    
    dispatch({
      type: EXISTING_AREA_SUCCESS,
      existingAreas: existingAreas
    });
  })
  .catch((err: any) => {
    dispatch({
      type: ERROR,
      error: {
        message: err,
        type: "fetchExisting",
      },
    });
    dispatch({ type: "SURVEYINITIATION_EXISTING_AREA_ERROR" });
  });
};

/**
 * summary: function to retrieve information about a survey area
 *
 * @param location url containing survey area ID
 */
export const getSurveyAreaDetails =
  (location: any) => async (dispatch: any) => {
    dispatch({ type: FETCH_SURVEY_AREA_LOADING });
    dispatch({ type: CLEAR_ERROR });

    let id = getUrlParam(location, "id");
    let surveyAreaTableID = getTableIdByName(
      store.getState().SettingsReducer.globalAliases.LanternSurveyArea
    );

    let surveyPolygonTableID = getTableIdByName(
      store.getState().SettingsReducer.globalAliases.LanternSurveyAreaPolygon
    );
    const selectedMapService =
      store.getState().LoginReducer.selectedMapService.decodedLabel;

    axios
      .all([
        getTableQueryBySurveyAreaID(selectedMapService, surveyAreaTableID, id),
        getTableQueryBySurveyAreaID(
          selectedMapService,
          surveyPolygonTableID,
          id
        ),
      ])
      .then(
        axios.spread(function (data, grids) {
          data = mapQueryResults(data);
          grids = mapQueryResults(grids);
          axiosErrorHandler(arguments);
          let { PolygonType } = data.data.features[0].attributes;
          fetchGridGeometry(grids.data, dispatch, PolygonType);
          dispatch({
            type: FETCH_SURVEY_AREA_SUCCESS,
            surveyAreaData: data.data.features[0].attributes,
          });
          dispatch({
            type: SET_GRID_TYPE + surveyInitiationMapType,
            gridType: PolygonType,
          });
        })
      )
      .catch((err) => {
        dispatch({
          type: ERROR,
          error: {
            message: err,
            type: "fetchDetails",
          },
        });
        dispatch({ type: "SURVEYINITIATION_FETCH_SURVEY_AREA_ERROR" });
      });
  };

// helper function to get the selected grids geometry
function fetchGridGeometry(grids: any, dispatch: any, polygonType: string) {
  let gridData: Array<any> = [];
  let fetches: Array<any> = [];
  let MapServiceId = getLayerMapServiceIdByName(
    grids.features[0].attributes.PolygonType
  );
  let globalIdName = getGridIdFieldByName(polygonType);
  const selectedMapService =
    store.getState().LoginReducer.selectedMapService.decodedLabel;

  grids.features.forEach(async (grid: any) => {
    fetches.push(
      getArcgisQuery(
        selectedMapService,
        MapServiceId,
        concatQueryingParameters({
          [arcgisQueryParams.where]: `${globalIdName}='${grid.attributes.PolygonID}'`,
          [arcgisQueryParams.outFields]: "*",
        })
      )
        .then((res: any) => {
          // linked Survey Grids may now be deleted, hence a lack of features
          if (res.data.features[0]) {
            gridData.push(
              massageGridData(
                res.data,
                esriGeometryType.esriGeometryPolygon,
                grid.attributes.OBJECTID
              )
            );
          }
        })
        .catch((err: any) =>
          dispatch({
            type: ERROR,
            error: {
              message: err,
              type: "fetchDetails",
            },
          })
        )
    );
  });
  Promise.all(fetches).then(() => {
    dispatch({
      type: SET_GEOMETRY + surveyInitiationMapType,
      grids: gridData,
    });
  });
}
/** Summary: function for performing the forecasting call */
export const forecast = async (
  dataID: any,
  name: string,
  programType: string,
  surveyWorkType: string
) => {
  const forecastParams: any = getForecastParams(
    dataID,
    name,
    programType,
    surveyWorkType
  );
  // const res = await axios.post(
  //   forecastParams.url,
  //   forecastParams.requestBody,
  //   forecastParams.config
  // );
  // axiosErrorHandler(res);
  const res = await configurationScheduleForecast(forecastParams.requestBody);
  return res;
};

/**
 * summary: function for posting a survey plan
 *
 * @param data the survey plan data to be posted
 * @param name name of the survey plan to be displayed in the notification message
 * @param programType name of the program type of the survey plan to be posted
 * @param forecasting whether or not forecasting should be done after initiating this survey plan
 * @param objectId survey area object id (exists when creating survey plan through survey area)
 * @param surveyWorkType SWT currently selected
 */
export const postCreateSurveyPlan =
  (
    data: any,
    name: string,
    programType: string,
    forecasting: boolean,
    objectId: number,
    surveyWorkType: string
  ) =>
  async (dispatch: any) => {
    dispatch({ type: POST_LOADING });
    dispatch({ type: CLEAR_ERROR });

    // url to post to
    // const url = `${urlConfig.reactLanternServerUrl}/Editing.ashx/tables/commit`;

    //append token to post body
    //axios
      //.post(url, data.requestBody)
      createSurveyArea(data.requestBody)
      .then((res) => {
        axiosErrorHandler(res);
        if (objectId) {
          dispatch(
            postLockSurveyArea(formatData(objectId, surveyAreaOperations.lock))
          );
        }
        // only make forecasting API call for survey plans that have forecasting
        if (forecasting) {
          forecast(data.ID, name, programType, surveyWorkType)
        } 
        dispatch({ type: POST_SUCCESS });
        dispatch({ type: SET_SURVEY_PLAN_ID, id: data.ID });
        dispatch({
          type: SET_STATUS_MESSAGE,
          surveyPlanName: name,
          statusMessage: "initiateSuccess",
          status: "success",
        });
        dispatch({
          type: SET_SLIDEOUT_MODE,
          mode:
            store.getState().SurveyInitiationReducer.slideoutMode ===
            SlideoutModes.NEW
              ? SlideoutModes.NEW_VIEW
              : SlideoutModes.EXISTING_VIEW,
        });
      })
      .catch((err) => {
        dispatch({ type: ERROR, error: { message: err, type: "post" } });
        dispatch({
          type: SET_STATUS_MESSAGE,
          surveyPlanName: name,
          statusMessage: "initiateError",
          status: "failure",
        });
        dispatch({ type: POST_ERROR });
      });
  };

/**
 * Summary: helper function to get all the forecast call post parameters
 * @param surveyPlanID ID of the survay plan to be posted
 * @param surveyPlanName name of the survey plan to be posted
 * @param programType name of the program type of the survey plan to be posted
 */
const getForecastParams = (
  surveyPlanID: string,
  surveyPlanName: string,
  programType: string,
  surveyWorkType: string
) => {
  let Assets = store.getState().SettingsReducer.layers.Assets;
  let ProgramTypes = store.getState().SettingsReducer.layers.PSTs;
  let loggedInUser = store.getState().LoginReducer.loggedInUser;
  let mainAssetID = 0;
  let serviceAssetID = 0;
  let ServiceCriteria = "";
  let MainLineCriteria = "";

  // find the asset id for mains and services
  Assets.some((asset: any) => {
    if (asset.AssetType === "Mains") {
      mainAssetID = asset.Id;
    } else if (asset.AssetType === "Services") {
      serviceAssetID = asset.Id;
    }
    return mainAssetID && serviceAssetID;
  });

  // find the corresponding criteria with the asset id, for the program type
  ProgramTypes.forEach((type: any) => {
    if (type.Name === programType) {
      type.Criteria.some((criteria: any) => {
        if (surveyWorkType !== 'Leak Survey') {
          MainLineCriteria = criteria.Criteria;
        } else {
          if (criteria.AssetId === mainAssetID) {
            MainLineCriteria = criteria.Criteria;
          } else if (criteria.AssetId === serviceAssetID) {
            ServiceCriteria = criteria.Criteria;
          }
        }
        return MainLineCriteria && ServiceCriteria;
      });
    }
  });

  const selectedMapServiceName = store.getState().LoginReducer.selectedMapService.decodedLabel

  let payload = `{
    "MapServiceName": "${selectedMapServiceName}",
    "IsaId": "${surveyPlanID}",
    "CreatedBy": "${loggedInUser}",
    "IsaName": "${surveyPlanName}",
    "ServiceCriteria": "${ServiceCriteria}",
    "MainLineCriteria": "${MainLineCriteria}"
  }`;

  let params = {
    requestBody: {
      bufferToolRequest: payload,
    },
  };

  return params;
};

/**
 * Summary: Get the available Asset types and programs for the given survey work type
 * @param surveyWorkType 
 */
export const getAssetTypesAndPrograms = async (surveyWorkType: string) => {
  let assetsAndPrograms: any = [];
  const mapServiceName = store.getState().LoginReducer.selectedMapService.decodedLabel
  if (surveyWorkType !== '') {
      // const url = `${urlConfig.reactLanternNewServerUrl}/v1/maps/configuration/${encodeUriAll(mapServiceName)}/${encodeUriAll(surveyWorkType)}`
    // await axios.get(url)
    await getConfiguration(mapServiceName, surveyWorkType)
    .then((res) => {
      assetsAndPrograms = res.data;
     }).catch()
  }
  return assetsAndPrograms ?? []
}

/**
 * Summary: Gets the available plan categories for each survey work type,
 *      and the form type, default status, and queue for each plan catagory
 *
 * @param bySurveyArea if true, return plan categories for custom initiations
 *                     if false, return plan categories for planned initiations / initiations by survey area
 */
export const getPlanCategories =
  (bySurveyArea: boolean) => async (dispatch: any) => {
    const mapServiceName =
      store.getState().LoginReducer.selectedMapService.label;
    // Todo: Remove commented out code once this is tested with real token
    // const getPlanCategoriesUrl =
    //   `${urlConfig.reactLanternNewServerUrl}/v1/surveyplancategoryconfigurations/${mapServiceName}/` +
    //   `surveyworktypeswithplancategories/${bySurveyArea}`;

    // axios.get(getPlanCategoriesUrl).then((res) => {
      // filter out survey work types that have no plan categories
      const res = await getSurveyPlanCategoryConfigurations(mapServiceName, bySurveyArea);
      let surveyWorkTypePlanCategories = res.data.filter(
        (surveyWorkType: any) => {
          return (
            surveyWorkType.surveyPlanCategories &&
            surveyWorkType.surveyPlanCategories.length > 0
          );
        }
      );
      // map property names
      surveyWorkTypePlanCategories = surveyWorkTypePlanCategories.map(
        (surveyWorkType: any) => {
          return {
            name: surveyWorkType.name,
            planCategories: surveyWorkType.surveyPlanCategories.map(
              (planCategory: any) => {
                return {
                  name: planCategory.displayName,
                  formType: planCategory.surveyInitiationFormType,
                  defaultStatus: planCategory.defaultStatus,
                  queueType: planCategory.queueType,
                };
              }
            ),
          };
        }
      );
      dispatch({
        type: SET_PLAN_CATEGORIES,
        surveyWorkTypePlanCategories: surveyWorkTypePlanCategories,
      });
    // });
  };

export const validateCSLEntries =
  (polygonType: PolygonTypes, entries: string[], addToEntries:boolean = false) => async (dispatch: any) => {
    const mapServiceName =
      store.getState().LoginReducer.selectedMapService.label;
    const selectedMapService = store.getState().LoginReducer.selectedMapService.decodedLabel
    const layerId = getMapServiceIdByPolygonType(polygonType);
    const tableAttributeName = getGridLayerFieldByPolygonType(
      polygonType,
      gridLayerFields.GridNameFieldName
    );
    const GridNameFieldName = getGridLayerFieldByPolygonType(polygonType, gridLayerFields.GridNameFieldName)
    const res = await getValidateCSLEntries(mapServiceName, layerId, tableAttributeName, entries)

      const validEntryIds = res.data.validEntries;
      if (validEntryIds.length > 0) {
        const validateDict:{[index: string]:any} = {}
        
        const GridLayers = store.getState().SettingsReducer?.layers?.GridLayers
        const GridLayerName = GridLayers.filter((item: any) => item.polygonType === polygonType)[0]?.Name;
        const gasMainsFields =  getLayerOrTableByName(GridLayerName).Fields;
        const isStringTypeField = gasMainsFields.filter((item:any) => item.Name === GridNameFieldName)[0].FieldType >= 4;
        const searchClause = isStringTypeField ? validEntryIds.map((item:any) => `'${item}'`) : validEntryIds;
        const inClause = searchClause.join(",");
        
        getValidCSLEntriesFromSearch(
          selectedMapService,
          layerId,
          GridNameFieldName,
          inClause,
          '*',
        )
          .then((grids: any)=>{
            const validEntries = grids.data.features
            validEntries.forEach((grid:Polygon)=>{
              grid.attributes=mapAttribute(grid.attributes)
              const name = grid.attributes[GridNameFieldName]
              if (name) {
                if (name in validateDict) {
                  validateDict[name].push(grid)
                }
                else {
                  validateDict[name] = [grid]
                }
              }
            })

            //If there are duplicate results, take the first one (this may have to be changed later)
            const filteredValidEntries:Array<any> = []
            const unfoundValidEntryIds:Array<any> = []
            validEntryIds.forEach((validEntry:any) => {
              if (validEntry in validateDict) {
                filteredValidEntries.push(validateDict[validEntry][0])
              }
              else {
                unfoundValidEntryIds.push(validEntry)
              }
            })

            //If exceeding transfer limit, call again with valid entries that have yet to be found
            if (grids.data.exceededTransferLimit && unfoundValidEntryIds.length > 0) {
              dispatch(validateCSLEntries(polygonType, unfoundValidEntryIds, true))
            }
          
            if (addToEntries) {
              dispatch({
                type: ADD_TO_VALIDATED_CSL_ENTRIES,
                validEntries: filteredValidEntries
              });
            }
            else {
              dispatch({
                type: SET_VALIDATED_CSL_ENTRIES,
                validEntries: filteredValidEntries,
                invalidEntries: res.data.invalidEntries,
              });
            }
          })
      }
      if (!addToEntries) {
        dispatch({
          type: SET_VALIDATED_CSL_ENTRIES,
          validEntries: [],
          invalidEntries: res.data.invalidEntries,
        });
      }
      
    //})
  }


/**
 * Summary: Validates the given Asset CSV file using an API call. If it is valid, it uploades it
 * to the server (where it will be read by the scripts), otherwise it will give an error message.
 *
 * @param file the asset CSV file to validate and upload
 */
export const uploadAssetCSVFile = (file: any) => async (dispatch: any) => {
  if (!file) {
    return;
  }

  const mapServiceName = store.getState().LoginReducer.selectedMapService.label;
  //const uploadAssetCSVFileUrl = `${urlConfig.reactLanternNewServerUrl}/v1/surveyvalidation/${mapServiceName}`; 
   const fileFormData = new FormData();
   fileFormData.append("file", file);

  // const config = {
  //   headers: {
  //     "Content-Type": `multipart/form-data`,
  //   },
  // };
  
  // axios
  //   .post(uploadAssetCSVFileUrl, fileFormData, config)
  postSurveyValidation(mapServiceName, fileFormData, `multipart/form-data`)
    .then((res) => {
      // the CSV file is valid
      dispatch({
        type: VALID_CSV_FILE_UPLOAD,
        fileLocation: res.data[0]?.savedFilePath,
      });
    })
    .catch((error) => {
      // the CSV file is not valid
      if (error.response && error.response.data) {
        const invalidAssetTypes = error.response.data[0]?.invalidAssetTypes;
        const invalidAssets = error.response.data[0]?.invalidAssets;
        if (invalidAssetTypes?.length > 0) {
          dispatch({
            type: INVALID_CSV_FILE_UPLOAD,
            errorMessage: `${invalidAssetTypes.length} Invalid Asset Type${
              invalidAssetTypes.length > 1 ? "s" : ""
            }: ${invalidAssetTypes.join(", ")}`,
            invalidEntries: [],
          });
        } else if (invalidAssets?.length > 0) {
          const invalidAssetValues: any[] = [];
          invalidAssets?.forEach((invalidAssetList: any) =>
            invalidAssetValues.push(...invalidAssetList.attributeValues)
          );
          dispatch({
            type: INVALID_CSV_FILE_UPLOAD,
            errorMessage: "",
            invalidEntries: invalidAssetValues,
          });
        } else {
          dispatch({
            type: INVALID_CSV_FILE_UPLOAD,
            errorMessage: "The format of the file is invalid.",
            invalidEntries: [],
          });
        }
      } else {
        // some other error
        dispatch({
          type: INVALID_CSV_FILE_UPLOAD,
          errorMessage: "There was an error with validating the file",
          invalidEntries: [],
        });
      }
    });
};

const initialState: any = {
  error: {},
  notificationMessage: "",
  existingSurveyAreas: [],
  surveyAreaData: {},
  slideoutMode: "",
  surveyPlanId: "",
  assetCSVErrorMessage: "",
  assetCSVInvalidEntries: [],
  assetCSVFileLocation: "",
  totalCount: 0,
  existingSurveyAreasFilters: {},
  existingSurveyAreasPreFilterValues: {},
  planSurveyResetSurveyWorkType: false 
};

export default function reducer(state = initialState, action: any) {
  switch (action.type) {
    case EXISTING_AREA_SUCCESS:
      const massagedData = action.existingAreas.data.map((feature: any) => {
        return {
          ...feature.attributes,
        };
      });
      const {existingSurveyAreasFilters:actionFilters} = action;
      const existingSurveyAreasFilters = { ...state.existingSurveyAreasFilters, ...actionFilters};
      return {
        ...state,
        existingSurveyAreas: massagedData,
        totalCount: !isNil(action.existingAreas.totalCount) ? action.existingAreas.totalCount : state.totalCount,
        existingSurveyAreasFilters
      };
    case EXISTING_AREA_PRE_FILTER_VALUES_SUCCESS:
      return {
        ...state,
        existingSurveyAreasPreFilterValues: action.existingSurveyAreasPreFilterValues,
      };  
    case EXISTING_AREA_ALL_PRE_FILTER_VALUES_SUCCESS:
      return {
        ...state,
        existingSurveyAreasAllPreFilterValues: action.existingSurveyAreasAllPreFilterValues,
      }
    case SURVEY_INIT_TABLE_CONFIG_SUCCESS:
      return {
        ...state,
        existingInitTableConfigData: action.tableConfig,
        existingInitTableColumns: map(action.tableConfig, (col) => col.accessor).filter(col => col !== '')
      };
    case FETCH_SURVEY_AREA_SUCCESS:
      return {
        ...state,
        surveyAreaData: action.surveyAreaData,
      };
    case ERROR:
      return {
        ...state,
        error: action.error,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: {},
      };
    case SET_SLIDEOUT_MODE:
      return {
        ...state,
        slideoutMode: action.mode,
      };
    case SET_STATUS_MESSAGE:
      return {
        ...state,
        notificationMessage: {
          statusMessage: action.statusMessage,
          surveyPlanName: action.surveyPlanName,
          status: action.status,
        },
      };
    case CLEAR_SURVEY_AREA_DATA:
      return {
        ...state,
        surveyAreaData: {},
      };
    case CLEAR_STATUS_MESSAGE:
      return {
        ...state,
        notificationMessage: "",
      };
    case SET_SURVEY_PLAN_ID:
      return {
        ...state,
        surveyPlanId: action.id,
      };
    case SET_PLAN_CATEGORIES:
      return {
        ...state,
        surveyWorkTypePlanCategories: action.surveyWorkTypePlanCategories,
      };
    case SET_CSV_FILE_NAME:
      return {
        ...state,
        assetCSVFileName: action.fileName,
      }
    case INVALID_CSV_FILE_UPLOAD:
      return {
        ...state,
        assetCSVErrorMessage: action.errorMessage,
        assetCSVInvalidEntries: action.invalidEntries,
      };
    case VALID_CSV_FILE_UPLOAD:
      return {
        ...state,
        assetCSVFileLocation: action.fileLocation,
      };
    case RESET_CSV_FILE_UPLOADED:
      return {
        ...state,
        assetCSVFileName: "",
        assetCSVErrorMessage: "",
        assetCSVInvalidEntries: [],
        assetCSVFileLocation: "",
      };
    case SET_VALIDATED_CSL_ENTRIES:
      return {
        ...state,
        validCSLEntries: action.validEntries,
        invalidCSLEntries: action.invalidEntries,
      };
    case ADD_TO_VALIDATED_CSL_ENTRIES:
      return {
        ...state,
        validCSLEntries: state.validCSLEntries.concat(action.validEntries)
      }
    case RESET_VALIDATED_CSL_ENTRIES:
      return {
        ...state,
        validCSLEntries: undefined,
        invalidCSLEntries: undefined,
      };
    case SET_SELECTED_CORROSION_AREAS:
      return {
        ...state,
        selectedCorrosionAreas: action.values,
      };
    case SET_SELECTED_PIPELINE_IDS:
      return {
        ...state,
        selectedPipelineIds: action.values,
      };
    case DISABLE_SELECTION_TOOLS:
      return {
        ...state,
        disableSelectionTools: action.disableSelectionTools
      }
    case PLAN_SURVEY_RESET_SURVEY_WORK_TYPE:
      return {
        ...state,
        planSurveyResetSurveyWorkType: action.resetSurveyWorkType
      }
    default:
      return state;
  }
}