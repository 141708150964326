import React, { useEffect } from 'react';

interface IEmptyTable {
  className?: string
  selection: boolean,
  directoryIndex?: number,
  setDirectoryIndex?: any,
  isParent?: boolean,
  setIsParent?: any
}

const EmptyTable = ({
  className = "",
  selection,
  directoryIndex,
  setDirectoryIndex,
  isParent,
  setIsParent
}:IEmptyTable) => {
  useEffect(() => {
    if (!selection) {
      resetSelection()
    }
  }, [directoryIndex, isParent])

  /** Summary: reset the Droplist directory selection */
  const resetSelection = () => {
    if (directoryIndex !== 0) {
      setDirectoryIndex(0)
    }
    if (!isParent) {
      setIsParent(true)
    }
  }

  return (
    <div className={className}>
      <span className="text" id="screen-title">
        {/* selection is true when a successful selection returns empty data */}
        {selection ? "No results found!" : "Nothing is selected!"}
      </span>
    </div>
  )
}

export default EmptyTable;
