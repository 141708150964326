import React from 'react';
import './GoBackLink.scss';
import { history } from 'index';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

interface IGoBackLinkProps {
  link?: any
  children: string
}

const GoBackLink = ({
  link,
  children
}:IGoBackLinkProps) => {
  const goBack = (link:any) => {
    if(link) {
      history.push(link)
    }
    else {
      window.history.back()
    }
  }

  return(
    <button
      className="go-back-link"
      onClick={()=>goBack(link)}
    >
      <ChevronLeft/>
      <span className="text">
      {children}
      </span>
    </button>
  )
}

export default GoBackLink;
