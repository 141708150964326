import { combineReducers } from 'redux';
// import reducers here
import SettingsReducer from './SettingsDucks';
import LoadingReducer from './LoadingDucks';
import LoginReducer from 'features/Login/LoginDucks';
import MapPageReducer from 'features/MapPage/MapPageDucks';
import SurveyAreaReducer from 'features/SurveyArea/SurveyAreaDucks';
import SurveyInitiationReducer from 'features/SurveyInitiation/SurveyInitiationDucks';
import SurveyAssignmentReducer from 'features/SurveyAssignment/SurveyAssignmentDucks';
import QueueProcessingReducer from 'features/QueueProcessing/QueueProcessingDucks';
import WorkloadManagementReducer from 'features/WorkloadManagement/WorkloadManagementDucks';
import SurveyReviewReducer from 'features/SurveyReview/SurveyReviewDucks';
import AttachmentsReducer from 'common/Map/Widgets/InfoWidget/AttachmentsDucks';
import createMapReducerWithType,
  {surveyAreaMapType,
  surveyInitiationMapType,
  surveyAssignmentMapType,
  surveyReviewMapType,
  mapPageMapType
} from 'common/Map/MapDucks';

export default combineReducers({
  // export reducers here
  SettingsReducer,
  LoadingReducer,
  LoginReducer,
  MapPageReducer,
  MapPageMapReducer:createMapReducerWithType(mapPageMapType),
  SurveyAreaReducer,
  SurveyAreaMapReducer:createMapReducerWithType(surveyAreaMapType),
  SurveyInitiationReducer,
  SurveyInitiationMapReducer:createMapReducerWithType(surveyInitiationMapType),
  SurveyAssignmentReducer,
  SurveyAssignmentMapReducer:createMapReducerWithType(surveyAssignmentMapType),
  QueueProcessingReducer,
  WorkloadManagementReducer,
  SurveyReviewReducer,
  SurveyReviewMapReducer:createMapReducerWithType(surveyReviewMapType),
  AttachmentsReducer
});
