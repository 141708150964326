import React from 'react';
import { ReactComponent as LastExtentIcon } from 'assets/LastExtentIcon.svg';
import { widgetTypes } from '../MapWidgetLayer';
import {
  surveyAreaMapType,
  surveyInitiationMapType,
  surveyReviewMapType,
  styleByTool,
  styleBySlideoutTool,
  styleByMapType
} from '../MapDucks';

interface IWidgetProps {
  mapType: string
  currentTool: widgetTypes
  activeSlideoutTool: widgetTypes
  isSlideoutOpen: boolean
  prevExtents: any
  view: any
  positionClass?: string
}

export const LastExtentWidget: React.FunctionComponent<IWidgetProps> = ({
  mapType,
  currentTool,
  activeSlideoutTool,
  isSlideoutOpen,
  prevExtents,
  view,
  positionClass
}) => {
  function handleClick() {
      if (prevExtents.length >= 2) {
        //pop twice since we're already at the current extent , so we need to pop another to go to the one previous.
        prevExtents.pop()
        view.goTo(prevExtents.pop())
      }
  }

  return (
    <button
      title="Last extent"
      id="last-extent-btn"
      className={
        "widget-btn position-btn " +
          (styleByTool[currentTool] || "") + positionClass + " " + 
          (isSlideoutOpen ?
            (
              (mapType === surveyAreaMapType || mapType === surveyInitiationMapType || mapType === surveyReviewMapType) ?
                styleBySlideoutTool[activeSlideoutTool] + styleByMapType[mapType] :
                styleBySlideoutTool[activeSlideoutTool]
            ) :
            ""
          ) + ' ' + mapType.slice(1).toLowerCase().replace('_', '-') + '-widget-tools'
      }
      onClick={handleClick}
    >
      <LastExtentIcon/>
    </button>
  )
}
