import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SET_LAYER_VISIBILITY, SET_LAYER_SELECTABILITY } from 'app/SettingsDucks';
import Button from 'common/Button/Button';
import { SET_ACTIVE_TOOL } from 'common/Map/MapDucks';
import { widgetTypes } from 'common/Map/MapWidgetLayer';
import { ITableOfContentsLayersTab } from './TableOfContentsWidget';
import CheckBox from 'common/Checkbox/CheckBox';

const FeatureLayersTab = ({
  mapType
}: ITableOfContentsLayersTab) => {
  const dispatch = useDispatch()
  const layers =  useSelector((state:any) => state.SettingsReducer.settings.Layers)
  const layerVisibility = useSelector((state:any) => state.SettingsReducer.layerVisibility)
  const layerSelectability = useSelector((state:any) => state.SettingsReducer.layerSelectability)
  const defaultVisibility = useSelector((state:any) => state.SettingsReducer.defaultVisibility)
  const defaultSelectability = useSelector((state:any) => state.SettingsReducer.defaultSelectability)
  const mapIdToFeatureId = useSelector((state:any) => state.SettingsReducer.mapIdToFeatureId)
  const [visibilityStatus, setVisibilityStatus] = useState<{[index: string]:boolean}>({})
  const [selectabilityStatus, setSelectabilityStatus] = useState<{[index: string]:boolean}>({})
  const [selectAllCheckbox, setSelectAllCheckbox] = useState<{visible: boolean, selectable: boolean}>({
    visible: !Object.values(layerVisibility ?? defaultVisibility).includes(false),
    selectable: !Object.values(layerVisibility ?? defaultSelectability).includes(false)
  })

  const currentSettings = () => {
    setVisibilityStatus(layerVisibility ?? defaultVisibility)
    setSelectabilityStatus(layerSelectability ?? defaultSelectability)
  }

  const updateVisibilityStatus = (layerId: number) => {
    setVisibilityStatus({
      ...visibilityStatus,
      [layerId]: !visibilityStatus[layerId],
    });
  };

  const updateSelectabilityStatus = (layerId: number) => {
    setSelectabilityStatus({
      ...selectabilityStatus,
      [layerId]: !selectabilityStatus[layerId],
    });
  };

  const resetToDefault = () => {
    setVisibilityStatus(defaultVisibility)
    setSelectabilityStatus(defaultSelectability)
  }

  /**
   * Summary: select all visible layers and set states for the checkboxes
   */
  const selectAllVisibleLayers = () => {
    const newVisibilityStatus: any = {};
    Object.keys(visibilityStatus).forEach((key: any) => {
      newVisibilityStatus[key] = !selectAllCheckbox.visible
    })
    setSelectAllCheckbox({...selectAllCheckbox, visible: !selectAllCheckbox.visible})
    setVisibilityStatus(newVisibilityStatus)
  }

  /**
   * Summary: select all selectable layers and set states for the checkboxes
   */
  const selectAllSelectableLayers = () => {
    const newSelectabilityStatus: any = {};
    Object.keys(selectabilityStatus).forEach((key: any) => {
      newSelectabilityStatus[key] = !selectAllCheckbox.selectable
    })
    setSelectAllCheckbox({...selectAllCheckbox, selectable: !selectAllCheckbox.selectable})
    setSelectabilityStatus(newSelectabilityStatus)
  }

  useEffect(() => {
    // set boolean value for allCheckbox components 
    setSelectAllCheckbox({
      visible: !Object.values(visibilityStatus).includes(false),
      selectable: !Object.values(selectabilityStatus).includes(false)
    })

    // Get the selectAll checkbox components
    const visibleCheckBoxComponent: any = document.getElementsByClassName(
      "visible-select-all-checkbox"
    )?.[0];
    const selectableCheckBoxComponent: any = document.getElementsByClassName(
      "selectable-select-all-checkbox"
    )?.[0];
    
    // The checkboxes should be in `indeterminate` state when not all boxes are checked or unchecked.
    if (visibleCheckBoxComponent) {
      visibleCheckBoxComponent.indeterminate =
        Object.values(visibilityStatus).includes(false) &&
        Object.values(visibilityStatus).includes(true);
    }
    if (selectableCheckBoxComponent) {
      selectableCheckBoxComponent.indeterminate =
        Object.values(selectabilityStatus).includes(false) &&
        Object.values(selectabilityStatus).includes(true);
    }
  }, [visibilityStatus, selectabilityStatus])

  const save = () => {
    dispatch({type:SET_LAYER_VISIBILITY, layerVisibility:visibilityStatus})
    dispatch({type:SET_LAYER_SELECTABILITY, layerSelectability:selectabilityStatus})
    dispatch({type: SET_ACTIVE_TOOL + mapType, tool: widgetTypes.move})
  }

  useEffect(() => {
    currentSettings()
  }, [])

  return (
    <div className='tab-contents'>
      <div className='feature-layer-table layer-table'>
        <table>
          <thead>
            <tr className='feature-layer-header'>
              <th></th>
              <th className='header-cell'>Visible</th>
              <th className='header-cell'>Selectable</th>
            </tr>
            <tr>
              <th></th>
              <td className='select-all-checkbox'>
                <CheckBox
                  className='visible-select-all-checkbox'
                  boxColor='white'
                  onChange={() => selectAllVisibleLayers()}
                  isChecked={selectAllCheckbox.visible}
                />
              </td>
              <td className='select-all-checkbox'>
                <CheckBox
                  className='selectable-select-all-checkbox'
                  boxColor={!Object.values(visibilityStatus).includes(true) ? 'disabled-theme' : 'white'}
                  onChange={() => selectAllSelectableLayers()}
                  isChecked={selectAllCheckbox.selectable}
                  isDisabled={!Object.values(visibilityStatus).includes(true)}
                />
              </td>
            </tr>
          </thead>
          <tbody>
            {layers.map((layer:any, index:number) => {
              const isDisabled = !visibilityStatus[mapIdToFeatureId[layer.Id] + layer.Name]
              return(
                <tr key={mapIdToFeatureId[layer.Id] + layer.Name} className='checkbox-row'>
                  <td 
                    className={
                      'layer-label'
                        + (index === layers.length - 1 ? ' last' : '')
                        + (index === 0 ? ' first' : '')
                  }>
                    {layer.Name}
                  </td>
                  <td className={
                    'checkbox-cell'
                      + (index === layers.length - 1 ? ' last' : '')
                      + (index === 0 ? ' first' : '')
                  }>
                    <CheckBox 
                      boxColor='white'
                      onChange={() => updateVisibilityStatus(mapIdToFeatureId[layer.Id] + layer.Name)}
                      isChecked={visibilityStatus[mapIdToFeatureId[layer.Id] + layer.Name] || false}
                    />
                  </td>
                  <td className={
                    'checkbox-cell'
                      + (index === layers.length - 1 ? ' last' : '')
                      + (index === 0 ? ' first' : '')
                  }>
                    <CheckBox 
                      boxColor={isDisabled ? 'disabled-theme' : 'white'}
                      onChange={() => updateSelectabilityStatus(mapIdToFeatureId[layer.Id] + layer.Name)}
                      isChecked={selectabilityStatus[mapIdToFeatureId[layer.Id] + layer.Name] || false}
                      isDisabled={isDisabled}
                    />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <div className="table-of-contents-btns">
        <Button
          boxColor='blue'
          size='m'
          className='reset-btn'
          onClick={() => resetToDefault()}
        >
          Reset To Default
        </Button>
        <Button
          boxColor='grey'
          size='m'
          onClick={() => {
            dispatch({type: SET_ACTIVE_TOOL + mapType, tool: widgetTypes.move})
          }}
        >
          Cancel
        </Button>
        <Button
          boxColor='blue'
          size='m'
          onClick={() => save()}
        >
          Save
        </Button>
      </div>
    </div>
  )
}

export default FeatureLayersTab
