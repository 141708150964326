import React, { useEffect, Suspense, lazy, useState, useRef } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';


import {
  loadSettings,
  SET_SIDE_NAV,
  SET_SETTINGS_VISIBLE
} from '../../app/SettingsDucks';
import {
  getSurveyWorkTypes,
  SET_SELECT_ROLE_ERROR
} from 'features/Login/LoginDucks';
import './AppRouter.scss';
import SideNavLink from '../SideNavLink/SideNavLink';
import ErrorPage from '../../common/ErrorPage/ErrorPage';
import Loading from '../../common/Loading/Loading';
import RouteWrapper from 'common/RouteWrapper/RouteWrapper';
import NotificationMessage from 'common/NotificationMessage/NotificationMessage';
import LogInPage from "features/Login/Login";
import MapServicesSelection from 'features/Login/MapServicesSelection/MapServicesSelection';
import { isToolAvailable } from 'utils/IdentityManagerUtils';
import { LanternTools } from 'app/constants';
import NavWidget from 'common/NavWidget/NavWidget';
import TimeOutModal from 'app/TimeOutModal/TimeOutModal';


const MapPage = lazy(() => import('features/MapPage/MapPage'))
const SurveyArea = lazy(() => import('features/SurveyArea/SurveyArea'))
const SurveyInitiation = lazy(() => import('features/SurveyInitiation/SurveyInitiation'))
const SurveyAssignment = lazy(() => import('features/SurveyAssignment/SurveyAssignment'))
const QueueProcessing = lazy(() => import('features/QueueProcessing/QueueProcessing'))
const WorkloadManagement = lazy(() => import('features/WorkloadManagement/WorkloadManagement'))
const SurveyReview = lazy(() => import('features/SurveyReview/SurveyReview'))

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isLoggedIn = useSelector((state: any) => state.LoginReducer.loginSuccess);

  return (
    <div className="App">
      <Switch>
        <Route exact path="/" component={LogInPage} />
        <RouteWrapper path='/MapServices' isAuthenticated={isLoggedIn} >
          <MapServicesSelection />
        </RouteWrapper>
        <RouteWrapper isAuthenticated={isLoggedIn}>
          <LandingPage />
        </RouteWrapper>
      </Switch>
    </div>
  )
}

const LandingPage = () => {
  const dispatch = useDispatch()
  const error = useSelector((state: any) => state.SettingsReducer.error)
  const loading = useSelector((state: any) => state.LoadingReducer.MAPSERVICE_SETTINGS)
  const {
    selectedUserRole,
    selectRoleError ,
  userId} = useSelector((state: any) => state.LoginReducer)
  const securityRoles = useSelector((state: any) => state.SettingsReducer.securityRoles)
  const { isSideNavOpen, isSettingsVisible } = useSelector((state: any) => state.SettingsReducer)
  const [roleAttemptedToSelect, setRoleAttemptedToSelect] = useState(null)
  const notifRef = useRef<any>()

  const handleSettingClick = () => {
    dispatch({ type: SET_SETTINGS_VISIBLE, isSettingsVisible: !isSettingsVisible })
  }

  const handleNonSettingNavLinkClick = () => {
    dispatch({ type: SET_SETTINGS_VISIBLE, isSettingsVisible: false })
  }

  const handleSideNavCollapse = () => {
    dispatch({ type: SET_SIDE_NAV, isSideNavOpen: !isSideNavOpen })
  }

  const getRoleName = (roleId: string) => {
    return securityRoles.Roles.find((role: any) => role.RoleId === roleId).RoleName
  }

  useEffect(() => {
    return (() => {
      if (!isSettingsVisible) {
        dispatch({ type: SET_SETTINGS_VISIBLE, isSettingsVisible: false })
      }
    })
  }, [])

  useEffect(() => {
    dispatch(loadSettings())
  }, [])

  useEffect(() => {
    // set survey work types
    dispatch(getSurveyWorkTypes())
  }, []);

  useEffect(() => {
    if (selectRoleError) {
      notifRef.current.showNotif();
      setRoleAttemptedToSelect(getRoleName(selectRoleError))
      dispatch({
        type: SET_SELECT_ROLE_ERROR,
        selectRoleError: null
      })
    }
  }, [selectRoleError])

  if (loading || loading === undefined) {
    return <Loading />
  }
  else if (error) {
    return <ErrorPage errorObject={error} />
  }
  return (
    <div className="web-body">
      <NotificationMessage
        ref={notifRef}
        boxColor="failure"
        symbol="exclamation"
      >
        {roleAttemptedToSelect ?
          `You do not have access to the ${roleAttemptedToSelect} role` :
          "You do not have access to this role"}
      </NotificationMessage>
      <SideNavLink
        handleSettingClick={handleSettingClick}
        handleNonSettingNavLinkClick={handleNonSettingNavLinkClick}
        handleSideNavCollapse={handleSideNavCollapse}
        settingClicked={isSettingsVisible}
      />
      <NavWidget shouldShow={isSettingsVisible}></NavWidget>
      <Suspense fallback={<Loading />}>
        <Switch>
          <RouteWrapper
            path="/Map"
          >
            <MapPage />
          </RouteWrapper>
          <RouteWrapper
            path="/SurveyArea"
            isRendered={isToolAvailable(LanternTools.SURVEY_AREA, selectedUserRole)}
            redirectString='/Map'
          >
            <SurveyArea />
          </RouteWrapper>
          <RouteWrapper
            path="/SurveyInitiation"
            isRendered={isToolAvailable(LanternTools.SURVEY_INITIATION, selectedUserRole)}
            redirectString='/Map'
          >
            <SurveyInitiation />
          </RouteWrapper>
          <RouteWrapper
            path="/SurveyAssignment"
            isRendered={isToolAvailable(LanternTools.SURVEY_ASSIGNMENTS, selectedUserRole)}
            redirectString='/Map'
          >
            <SurveyAssignment />
          </RouteWrapper>
          <RouteWrapper
            path="/QueueProcessing"
            isRendered={isToolAvailable(LanternTools.QUEUE_PROCESSING, selectedUserRole)}
            redirectString='/Map'
          >
            <QueueProcessing />
          </RouteWrapper>
          <RouteWrapper
            path="/WorkloadManagement"
            isRendered={isToolAvailable(LanternTools.WORKLOAD_MANAGEMENT, selectedUserRole)}
            redirectString='/Map'
          >
            <WorkloadManagement />
          </RouteWrapper>
          <RouteWrapper
            path="/SurveyReview"
            isRendered={isToolAvailable(LanternTools.SURVEY_REVIEW, selectedUserRole)}
            redirectString='/Map'
          >
            <SurveyReview />
          </RouteWrapper>
        </Switch>
      </Suspense>
    </div>
  )
}
export default App
