import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isUndefined } from 'lodash';
import Issue from './Issue';
import Dropdown from 'common/Dropdown/Dropdown';
import { mapPageMapType } from 'common/Map/MapDucks';
import { convertDate, getCurrentDate, getOffsetDate } from 'utils/DateUtils';
import { usePrevious } from 'utils/HooksUtils';
import {
  getReportedIssuesConfig,
  queryAllReportedIssues,
  SET_LOAD_REPORTED_ISSUES,
  RESET_REPORTED_ISSUES_STORE
} from 'features/MapPage/MapPageDucks';
import './ReportedIssues.scss';

interface IReportedIssues {
  mapType: string
}

const ReportedIssues = ({ mapType }:IReportedIssues) => {
  const millisecondsInADay = 24 * 60 * 60 * 1000;
  // calculate the number of days since the year started
  const currentDateEpoch = getCurrentDate(true, 'epoch');
  const yearStartDateEpoch = convertDate(`${getCurrentDate(true, 'year')}/01/01`, true, true, 'epoch');
  const ytdTime = currentDateEpoch - yearStartDateEpoch;

  const dateFilter = [
    {value: 7 * millisecondsInADay, label: "Last 7 Days"},
    {value: 30 * millisecondsInADay, label: "Last 30 Days"},
    {value: 90 * millisecondsInADay, label: "Last 90 Days"},
    {value: 180 * millisecondsInADay, label: "Last 180 Days"},
    {value: ytdTime, label: "YTD"}
  ]

  const dispatch = useDispatch();
  const { loadReportedIssues, reportedIssuesConfig, reportedIssuesDetails } = useSelector((state: any) => state.MapPageReducer);
  const [dateFilterTime, setDateFilterTime] = useState<any>(
    loadReportedIssues ?
      dateFilter[0].value :
      reportedIssuesDetails.dateFilterTime || dateFilter[0].value
  );
  let prevIssuesConfig = usePrevious(reportedIssuesConfig);

  useEffect(() => {
    // only re/load reported issues config on map page render
    if (loadReportedIssues && mapType === mapPageMapType) {
      dispatch(getReportedIssuesConfig());
      setDateFilterTime(dateFilter[0].value);

      dispatch({
        type: SET_LOAD_REPORTED_ISSUES,
        loadReportedIssues: false
      });
    }
  }, [])

  useEffect(() => {
    if (
      (!isUndefined(prevIssuesConfig) && prevIssuesConfig !== reportedIssuesConfig) ||
      dateFilterTime !== reportedIssuesDetails.dateFilterTime
    ) {
      dispatch(queryAllReportedIssues(
        reportedIssuesConfig,
        getOffsetDate(-1 * dateFilterTime, true, "date"),
        dateFilterTime
      ));
      dispatch({type: RESET_REPORTED_ISSUES_STORE})
    }
  }, [reportedIssuesConfig, dateFilterTime])

  /** Summary: render the reported issues based on the configurations */
  const renderReportedIssues = () => {
    return (
      <div className="issues-list-container">
        {reportedIssuesConfig
        .sort((a: any, b: any) => a.SortOrder > b.SortOrder)
        .map((issue: any, index: number) => {
          return (
            <Issue
              key={index}
              displayName={issue.DisplayName}
              layerId={issue.LayerId}
              mapType={mapType}
            />
          )
        })}
      </div>
    )
  }

  /**
   * Summary: get the dateFilter item with the given time value
   * @param time time in milliseconds to reference
   * @returns dateFilter item object with value time
   */
  const getDateFilterItem = (time: number) => {
    let dateFilterItem = dateFilter.filter((item: any) => {
      return item.value === time
    })

    // return YTD by default, if no other times match
    return isEmpty(dateFilterItem) ? dateFilter[dateFilter.length - 1] : dateFilterItem
  }

  return (
    <div className="reported-issues-container">
      <div className="issues-header">
        <div className="overview-section-title">
          Reported Issues
        </div>
        <Dropdown
          className="issues-date-filter"
          borderless={true}
          isSearchable={false}
          items={dateFilter}
          defaultValue={getDateFilterItem(dateFilterTime)}
          handleChange={(value: any) => {setDateFilterTime(value)}}
          size={'small'}
          theme={'dateFilter'}
        />
      </div>
      {renderReportedIssues()}
    </div>
  )
}

export default ReportedIssues
