import React,{useEffect, useState, useRef} from 'react';
import {loadModules} from 'esri-loader';
import {useDispatch, useSelector} from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  mapPageMapType,
  surveyAreaMapType,
  surveyInitiationMapType,
  surveyAssignmentMapType,
  surveyReviewMapType,
  styleBySlideoutTool,
  styleByMapType,
  searchTypes,
  storeInfoLayers,
  storeInfoTables,
  SET_SEARCH_GEOMETRY,
  SET_ACTIVE_TOOL,
  SET_ACTIVE_SLIDEOUT_TOOL,
  SET_MAP_GO_TO,
  SET_INFO_ZOOM_GEOMETRY,
  SET_FORCE_RELATIONSHIP_HIGHLIGHT
} from 'common/Map/MapDucks';
import { isEmpty, isEqual, isUndefined } from 'lodash';
import { SET_SIDE_NAV } from 'app/SettingsDucks';
import ZoomInIcon from 'assets/ZoomInIcon.svg';
import ZoomOutIcon from 'assets/ZoomOutIcon.svg';
import { Size } from 'styled_components/SidePanel/ResizeIcons';
import { OverviewWidget } from './Widgets/OverviewWidget/OverviewWidget';
import { PointSelectWidget } from './Widgets/PointSelect';
import { RectangleSelectWidget } from './Widgets/RectangleSelectWidget'
import { PolygonSelectWidget } from './Widgets/PolygonSelectWidget';
import { UnselectWidget } from './Widgets/UnselectWidget';
import { UnselectAllWidget } from './Widgets/UnselectAllWidget'
import { SearchWidget } from './Widgets/SearchWidget/SearchWidget';
import { MeasureWidget } from './Widgets/MeasureWidget/MeasureWidget';
import { InfoWidget } from './Widgets/InfoWidget/InfoWidget';
import { QueryWidget } from './Widgets/QueryWidget/QueryWidget';
import { EditWidget } from './Widgets/EditWidget/EditWidget';
import { MoveWidget } from './Widgets/MoveWidget';
import { LastExtentWidget } from './Widgets/LastExtentWidget';
import { selectionBounds } from './Widgets/QueryWidget/QuerySlideoutBody'
import { TableOfContentsWidget } from './Widgets/TableOfContentsWidget/TableOfContentsWidget';
import {
  selectFeatures,
  mapPageSelectFeatures,
  selectOperations,
  geometryType,
  selectionType,
  relationshipQueryFeatures
} from './SelectFeatures'
import {
  createExtent,
  createOverviewExtent,
  createReviewExtent
} from './MapUtils/ExtentUtils';
import { drawPoints, drawPolygon, drawPolyLine } from './MapUtils/DrawUtils';
import { 
  getLayerMapServiceIdByName,
  getPropsMapLayerIdByLayerName, 
  removeDuplicateFeatures 
} from 'utils/GridUtils';
import { loadEsriModule } from 'utils/EsriUtils';
import { getReducer } from 'utils/ReduxUtils';
import { usePrevious } from 'utils/HooksUtils';
import './Map.scss';
import { pages } from 'utils/UrlUtils';
import { reRegisterToken } from 'utils/IdentityManagerUtils';

interface IWidgetLayerProps {
  showToolbar: boolean
  mapType: string
  map?:any
  view?:any
  slideoutRef?: any
  disableSelectionTools?: boolean
  handleClick?: Function
  toggleSidePanel: Function
  isSidePanelVisible?: boolean
  setForcedActiveSize?: (size:Size | null)=>void
}

export interface IWidgetProps {
  currentTool: widgetTypes
  activeSlideoutTool: widgetTypes
  isSlideoutOpen: boolean
  sketchWidget: any
  unselectWidget: any
  mapType: string
  toggleSidePanel: Function
  view?:any
  measureWidget?: any
  coordinateWidget?: any
  editWidget?: any
  checkCoordinateMode?: any
  className?: string
  layers?: any
  handleOverviewClick?: Function
  isSidePanelVisible?: boolean
  positionClass?: string
  prevExtents?: any
  isNearestFeatureOpen?: boolean
  setForcedActiveSize?: (size:Size | null)=>void
}

export enum widgetTypes {
  overview = 'overview',
  search = 'search',
  point = 'point',
  rectangle = 'rectangle',
  polygon = 'polygon',
  unselect = 'unselect',
  unselectAll = 'unselectAll',
  measure = 'measure',
  info = 'info',
  query = 'query',
  edit = 'edit',
  move = 'move',
  add = 'add',
  editMove = 'editMove',
  delete = 'delete',
  tableOfContents = 'tableOfContents'
}

export enum editOperation {
  create = 'create',
  edit = 'edit'
}

export enum layerType {
  feature = 'feature',
  graphics = 'graphics'
}

enum graphicsLayerID {
  highlight = 'highlightGraphicsLayer',
  nonHighlight = 'nonHighlightGraphicsLayer',
  sketch = 'sketchViewModelLayer'
}

const WidgetLayer: React.FunctionComponent<IWidgetLayerProps> = (props) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const layers =  useSelector((state:any) => state.SettingsReducer.settings.Layers)
  const tables =  useSelector((state:any) => state.SettingsReducer.settings.Tables)
  const layerVisibility = useSelector((state:any) => state.SettingsReducer.layerVisibility)
  const activeTool = useSelector((state:any) => state[getReducer(props.mapType)].activeTool)
  const { isOpen, activeSlideoutTool } = useSelector((state:any) => state[getReducer(props.mapType)].slideOutInfo)
  const { isSidePanelVisible } = props;
  let {
    selectedGrids,
    selectedLines,
    selectedPoints,
    searchResults,
    searchType,
    searchGrids,
    searchLines,
    searchPoints,
    infoGeometry,
    infoLayerName,
    highlightedItemInfoTool,
    highlightedItemTable,
    relationshipForceHighlight,
    mapGoTo,
    surveyReviewGrids,
    surveyReviewLines,
    surveyReviewPoints
  } = useSelector((state: any) => state[getReducer(props.mapType)])
  let {
    queryWhere,
    queryLayer,
    queryBound,
    queryRelationship,
    selectedPolygons,
    tableRelationshipQueryWhere,
    tableOriginFieldName,
    tableDestinationFieldName,
    tableRelationshipResults,
    tableRelationshipOriginId,
    isNearestFeatureOpen,
    nearestFeatureGrids,
    nearestFeatureLines,
    nearestFeaturePoints,
    nearestFeatureHighlight
  } = useSelector((state:any) => state.MapPageReducer)
  const { customBaseMapList } = useSelector((state:any) => state.MapPageMapReducer)
  const { mapIdToFeatureId } = useSelector((state:any) => state.SettingsReducer)
  const { infoResults } = useSelector((state: any) => state.SurveyAssignmentMapReducer);
  const { surveyReviewTables } = useSelector((state:any) => state.SurveyReviewMapReducer)
  //Used to keep surveyReviewTables up to date in sketch widgets (for selection)
  const surveyReviewTablesRef = useRef(surveyReviewTables)
  //Used to keep layerVisibility updated to in watch() callback function
  const layerVisibilityRef = useRef(layerVisibility);
  //Used for select/unselect tools for click vs drag
  const selectedSingle = useRef<boolean | undefined>(false);
  const unselectedSingle = useRef<boolean | undefined>(false);

  const [sketchWidget, setSketchWidget] = useState<any>()
  const [unselectWidget, setUnselectWidget] = useState<any>()
  const [measureWidget, setMeasureWidget] = useState<any>()
  const [coordinateWidget, setCoordinateWidget] = useState<any>()
  const [editWidget, setEditWidget] = useState<any>()
  const [prevExtents, setPrevExtents] = useState<Array<any>>([])
  const [positionClass, setPositionClass] = useState<string>('overview-tools');
  const [shouldZoomToFitAllGeometries, setShouldZoomToFitAllGeometries] =
    useState<Boolean>(false);

  // get the polygon type as a Ref so component in useEffect()
  // will receive the updated value without rerendering
  let polygonType = useRef()
  polygonType.current = useSelector((state: any) => state[getReducer(props.mapType)]).activeGridType

  let prevGrids = usePrevious(selectedGrids)
  let prevLines = usePrevious(selectedLines)
  let prevPoints = usePrevious(selectedPoints)

  //Setting map extent based on selected grids
  useEffect(() => {
    const hasRings = !isUndefined(selectedGrids[selectedGrids.length - 1]?.geometry?.rings)
    const selectedGeometryNum = selectedGrids.length + selectedLines.length + selectedPoints.length
    const surveyReviewGeometryNum = surveyReviewGrids.concat(surveyReviewLines).concat(surveyReviewPoints).filter((geometry:any) => {
      return !geometry.defaultGrid
    }).length

    let searchGeometry = searchGrids.concat(searchLines, searchPoints)
    if ((props.mapType === surveyReviewMapType || props.mapType === surveyAreaMapType ||props.mapType === surveyInitiationMapType) &&
        ((selectedGeometryNum) > 1 || surveyReviewGeometryNum) &&
        searchGeometry.length === 0)
    {
      createReviewExtent(selectedGrids, selectedLines, selectedPoints, props.view, props.mapType)
    }
    else if ((props.mapType === mapPageMapType) &&
      (selectedGrids.length + selectedLines.length + selectedPoints.length) > 0 &&
      searchGeometry.length === 0)
    {
      dispatch(createOverviewExtent(selectedGrids, selectedLines, selectedPoints, prevGrids, prevLines, prevPoints, props.mapType))
    } 
    else if ( 
      (searchPoints.length > 0 || 
        searchLines.length > 0 || 
        searchGrids.length > 0))
    {
     createReviewExtent(searchGrids, searchLines, searchPoints, props.view, props.mapType)
    }
    // polygons like pipelines do not contain the 'rings' attribute, instead they have 'paths', createExtent  should only run for polygons with 'rings'
    else if (searchGrids.length && hasRings) {
      createExtent(searchGrids).then((newExtent:any) => {
        props.view.goTo(newExtent.expand(3))
        setShouldZoomToFitAllGeometries(true);
      })
    }
    else if (selectedGrids.length && hasRings) {
      /**
       * Grids entered from grid search dropdown returns an element with 'geometry' attribute.
       * @param grid - an element from `selectedGrids`
       * @returns Boolean - true if grid is entered from the dropdown component.
       */
      const isGridDropdownSearched = (grid: Object) : Boolean => grid.hasOwnProperty("geometry");

      if (
        selectedGrids.find((grid: any) => isGridDropdownSearched(grid)) &&
        location.pathname === "/SurveyArea"
      ) {
        loadEsriModule('esri/geometry/geometryEngine').then((geometryEngine:any) => {
          const layerId = getLayerMapServiceIdByName(polygonType.current ?? '');
          const mapLayerId = props.map.layers.items.findIndex((layer: any) => layer?.layerId === layerId);
          const mapLayer = props.map.layers.items[mapLayerId];

          let query = mapLayer.createQuery();
          const unionedGeometry = geometryEngine.union(
            selectedGrids.map((grid: any) => isGridDropdownSearched(grid) ? grid.geometry : grid)
          );
          query.geometry = unionedGeometry;
          mapLayer.queryExtent(query).then((results: any) => props.view.goTo(results.extent))
        })
      } else {
        createExtent(selectedGrids).then((newExtent: any) => {
          props.view.goTo(newExtent.expand(3));
          setShouldZoomToFitAllGeometries(true);
        });
      }

    }
  }, [
    selectedGrids.length, 
    selectedLines.length, 
    selectedPoints.length, 
    searchGrids.length, 
    searchLines.length,
    searchPoints.length,
    surveyReviewGrids.length, 
    surveyReviewLines.length, 
    surveyReviewPoints.length
  ])

  useEffect(() => {
    // zoom in/out to fit all geometries on the map for survey assignment; simulate the gps click
    if (
      shouldZoomToFitAllGeometries &&
      location.pathname.includes(pages.surveyAssignment)
    ) {
      const geometry = selectedGrids
        .concat(selectedLines, selectedPoints)
        .map((selectedItem: any) => selectedItem.geometry)
        .filter((item: any) => !isUndefined(item));

      if (geometry.length > 0) {
        dispatch({
          type: SET_INFO_ZOOM_GEOMETRY,
          infoGeometry: geometry,
          infoLayerName: infoResults.layers[0]?.layerName ?? "",
        });
      }
    }
  }, [shouldZoomToFitAllGeometries])

  useEffect(() => {
    //Info tool locate geometry/geometries
    if (props.map.layers.items[0] && infoGeometry.length > 0 && infoLayerName !== '') {
      loadEsriModule('esri/geometry/geometryEngine').then((geometryEngine:any) => {
        // If the layer's geometry type is not polygon, convert to polygon using buffers
        const geometryArray = infoGeometry[0].geometryType !== geometryType.polygon
          ? infoGeometry.map((geometry: any) => geometryEngine.geodesicBuffer(geometry, [2], "meters", true))
          : infoGeometry
        
        const unionedGeometry = infoGeometry.length > 1
          ? geometryEngine.union(geometryArray)
          : geometryArray[0]
        
        const mapLayerId = getPropsMapLayerIdByLayerName(
          props.map.layers.items,
          infoLayerName
        );
        let query = props.map.layers.items[mapLayerId].createQuery()
        query.geometry = unionedGeometry

        props.map.layers.items[mapLayerId].queryExtent(query)
          .then((results: any) => {
            props.view.goTo(results.extent)
            // zoom into the selected point
            if (infoGeometry[0].type === geometryType.point) {
              props.view.zoom = 17
            }
          })
      })
    }
  }, [infoGeometry])

  /**
   * Summary: set info tool to be active after a successful non-empty select/search/query
   * @param autoOpenTools array of tools that should automatically open Info Tool
   * @param infoResults data to check if non-empty
   */
  const setInfoActive = (autoOpenTools: Array<String>, infoResults: any, toggleSidePanel: any) => {
    if (autoOpenTools.indexOf(activeTool) >= 0 && !isEmpty(infoResults)) {
      // close side nav when opening Info Tool
      toggleSidePanel(true); 
      dispatch({type: SET_SIDE_NAV, isSideNavOpen: false})
      dispatch({type: SET_ACTIVE_TOOL, tool: widgetTypes.info})
      dispatch({type: SET_ACTIVE_SLIDEOUT_TOOL, tool: widgetTypes.info})
    }
  }

  useEffect(() => {
    searchType = searchTypes.layerSearch
    setShouldZoomToFitAllGeometries(false);
  }, [])

  const nearestFeaturesAreSelected = (): Boolean =>
    isNearestFeatureOpen && location.pathname.includes(pages.overview);

  useEffect(() => {
    if (searchType === searchTypes.layerSearch || activeTool !== widgetTypes.search) {
      const {grids, lines, points} = prepareGraphicObjects()

      //draw selected features
      if (!nearestFeaturesAreSelected()) removeAllGraphics();
      drawGraphics(grids, lines, points)

      //store info results for selected features
      const infoResults = grids.concat(lines, points)
      dispatch(
        storeInfoLayers(
          infoResults, 
          layers, 
          tables, 
          props.mapType, 
          polygonType.current, 
          activeTool
        )
      )
      setInfoActive([widgetTypes.search, widgetTypes.query], infoResults, props.toggleSidePanel)
    }
  }, [selectedGrids, selectedLines, selectedPoints, searchGrids, searchLines, searchPoints])

  useEffect(() => {
    removeAllGraphics()
    if (!isEmpty(nearestFeatureHighlight)) {
      if (!props.view.extent.intersects(nearestFeatureHighlight.feature.geometry)) {
        loadEsriModule('esri/geometry/geometryEngine').then((geometryEngine:any) => {
          //Convert to polygon so we can create extents for lines/points
          const buffer = geometryEngine.geodesicBuffer(nearestFeatureHighlight.feature.geometry, 10, "meters", true)
          createExtent([{geometry: buffer}]).then((newExtent:any) => {
            props.view.goTo(newExtent.expand(3))
          })
        })
      }
      
      switch(nearestFeatureHighlight.geometryType) {
        case geometryType.polygon:
          drawPolygon(props.view.map.layers.items[getHighlightLayerIndex()], [nearestFeatureHighlight.feature], true)
          break
        case geometryType.polyline:
          drawPolyLine(props.view.map.layers.items[getHighlightLayerIndex()], [nearestFeatureHighlight.feature], true)
          break
        case geometryType.point:
          drawPoints(props.view.map.layers.items[getHighlightLayerIndex()], [nearestFeatureHighlight.feature], true)
          break
      }
    }
    drawGraphics(nearestFeatureGrids, nearestFeatureLines, nearestFeaturePoints)
    //filtering the nearest features with set property
    const filteredNearestFeatureGrids = nearestFeatureGrids.filter((grid:any) => grid.isSet === true)
    const filteredNearestFeatureLines = nearestFeatureLines.filter((line:any) => line.isSet === true)
    const filteredNearestFeaturePoints = nearestFeaturePoints.filter((point:any) => point.isSet === true)
    //redrawing the set features to be highlighted on top of the non highlighted one's
    drawPoints(props.view.map.layers.items[getHighlightLayerIndex()], filteredNearestFeaturePoints, true)
    drawPolyLine(props.view.map.layers.items[getHighlightLayerIndex()], filteredNearestFeatureLines, true)
    drawPolygon(props.view.map.layers.items[getHighlightLayerIndex()], filteredNearestFeatureGrids, true)
  }, [nearestFeatureGrids.length, nearestFeatureLines.length, nearestFeaturePoints.length, nearestFeatureHighlight?.feature])

  useEffect(() => {
    if (searchType === searchTypes.tableSearch) {
      //If in survey review, first reset tables for currently set toggles
      if (props.mapType === surveyReviewMapType) {
        dispatch(
          storeInfoTables(
            surveyReviewTables, 
            tables, 
            layers, 
            props.mapType, 
            activeTool, 
            location
          )
        )
      }
      dispatch(
        storeInfoTables(
          searchResults, 
          tables, layers, 
          props.mapType, 
          activeTool, 
          location, 
          props.mapType !== surveyReviewMapType
        )
      )
      setInfoActive([widgetTypes.search, widgetTypes.query], searchResults, props.toggleSidePanel)
    }
  }, [searchResults])

  useEffect(() => {
    layerVisibilityRef.current = layerVisibility
    setVisibility()
  }, [layerVisibility])

  useEffect(() => {
    props.map.basemap=customBaseMapList
  }, [customBaseMapList])

  useEffect(() => {
    surveyReviewTablesRef.current = surveyReviewTables
  }, [surveyReviewTables])

  useEffect(()=>{
    reRegisterToken()
    loadModules([
      'esri/widgets/Sketch/SketchViewModel',
      'esri/widgets/Measurement',
      'esri/widgets/CoordinateConversion',
      "esri/widgets/Editor",
      'esri/layers/GraphicsLayer',
      'esri/core/watchUtils',
      'dojo/query'
    ]).then(([
      SketchViewModel,
      Measurement,
      CoordinateConversion,
      Editor,
      GraphicsLayer,
      watchUtils,
      query
    ]:any) => {
      props.view.when(function(){
        if(props.view && query('.esri-component.esri-zoom.esri-widget>div')){
          query('.esri-component.esri-zoom.esri-widget>div')[0].innerHTML = `<img src= '${ZoomInIcon}' alt='+'/>`;
          query('.esri-component.esri-zoom.esri-widget>div')[1].innerHTML = `<img src= '${ZoomOutIcon}' alt='-'/>`;
        }
      })

      // create graphics layer and sketch toolbar
      const polygonGraphicsLayer = new GraphicsLayer({ id: graphicsLayerID.nonHighlight })
      const polygonHighlightLayer = new GraphicsLayer({ id: graphicsLayerID.highlight })
      //SketchViewModels should be on their own layer
      //Otherwise graphic objects get turned into sketch objects, enabling unwanted functionality
      const sketchViewModelLayer = new GraphicsLayer({ id: graphicsLayerID.sketch })

      // backend of sketch tool for grid selection
      const SketchWidget = new SketchViewModel({
        layer: sketchViewModelLayer,
        view: props.view
      })

      setSketchWidget(SketchWidget)

      // backend of sketch tool for unselecting grids
      const UnselectWidget = new SketchViewModel({
        layer: sketchViewModelLayer,
        view: props.view
      })
      setUnselectWidget(UnselectWidget)

      const MeasureWidget = new Measurement({
        view: props.view
      });

      setMeasureWidget(MeasureWidget)

      const CoordinateWidget = new CoordinateConversion({
        view: props.view,
        container: document.createElement("div"),
        mode: "capture"
      });

      setCoordinateWidget(CoordinateWidget)

      const EditorWidget = new Editor({
        view: props.view,
        container: document.createElement("div")
      });

      EditorWidget.container.style.display = 'none'

      setEditWidget(EditorWidget)


      props.view.ui.add(MeasureWidget, "top-left")
      props.view.ui.add(CoordinateWidget, "top-left")
      props.view.ui.add(EditorWidget, "top-right")
      props.view.ui.move('zoom',"manual")
      props.map.add(polygonGraphicsLayer)
      props.map.add(polygonHighlightLayer)

      SketchWidget.on("create", function(event:any) {
        let clickDetector = props.view.on("immediate-click", () => {
          selectedSingle.current = document.getElementById("rectangle-select-btn")?.classList.contains('btn-active');
        })
        if (event.state === "complete") {
          polygonGraphicsLayer.remove(event.graphic)
          SketchWidget.create(event.tool)
          //If in survey review, first reset tables for currently set toggles
          if (props.mapType === surveyReviewMapType) {
            dispatch(
              storeInfoTables(
                surveyReviewTablesRef.current, 
                tables, 
                layers, 
                props.mapType, 
                activeTool, 
                location
              )
            )
          }
          //Clear search selections
          dispatch({type: SET_SEARCH_GEOMETRY + props.mapType, grids: [], lines: [], points: []})
          if (props.mapType === mapPageMapType || props.mapType === surveyReviewMapType) {
            mapPageSelectFeatures(
              props.map.layers,
              props.mapType,
              dispatch,
              selectedSingle.current ? event.graphic.geometry.centroid : event.graphic.geometry,
              selectOperations.add,
              event.tool === geometryType.point
            )
          }
          else {
            selectFeatures(
              props.map.layers,
              props.mapType,
              polygonType.current,
              dispatch,
              selectedSingle.current ? event.graphic.geometry.centroid : event.graphic.geometry,
              selectOperations.add,
              event.tool === geometryType.point
            )
          }
          selectedSingle.current = false;
          clickDetector.remove()
          clickDetector = null;
        }
      })

      UnselectWidget.on("create", function(event:any) {
        let clickDetector = props.view.on("immediate-click", () => {
          unselectedSingle.current = document.getElementById("unselect-btn")?.classList.contains('btn-active');
        })
        if (event.state === "complete") {
          polygonGraphicsLayer.remove(event.graphic)
          //If in survey review, first reset tables for currently set toggles
          if (props.mapType === surveyReviewMapType) {
            dispatch(
              storeInfoTables(
                surveyReviewTablesRef.current, 
                tables, 
                layers, 
                props.mapType, 
                activeTool, 
                location
              )
            )
          }
          dispatch({type: SET_SEARCH_GEOMETRY + props.mapType, grids: [], lines: [], points: []})
          if (props.mapType === mapPageMapType || props.mapType === surveyReviewMapType) {
            mapPageSelectFeatures(
              props.map.layers,
              props.mapType,
              dispatch,
              unselectedSingle.current ? event.graphic.geometry.centroid : event.graphic.geometry,
              selectOperations.remove,
              event.tool === geometryType.point
            )
          }
          else {
            selectFeatures(
              props.map.layers,
              props.mapType,
              polygonType.current,
              dispatch,
              unselectedSingle.current ? event.graphic.geometry.centroid : event.graphic.geometry,
              selectOperations.remove,
              event.tool === geometryType.point
            )
          }
          UnselectWidget.create(event.tool)
          unselectedSingle.current = false;
          clickDetector.remove()
          clickDetector = null;
        }
      })

      // Keeps track of extents
      watchUtils.whenTrue(props.view,'stationary',()=>{
        if(props.view.extent){
          let tempExtents = prevExtents
          tempExtents.push(props.view.extent)
          setPrevExtents(tempExtents)
        }
      })

      //Set proper layer label info if labeling info is provided
      props.view.layerViews.watch("length", () => {
        if (props?.view?.layerViews?.items) {
          //Set visibility based on table of contents feature layer settings
          setVisibility()
          const reversedLayers = layers.slice().reverse();
          setLabelInfo(reversedLayers)
        }
      })
    }).catch((err: string) => console.error(err));
  },[])

  /**
   * Summary: get sanitized grids, lines, and points objects
   */
  const prepareGraphicObjects = () => {
    let grids = selectedGrids.concat(searchGrids)
    let lines = selectedLines.concat(searchLines)
    let points = selectedPoints.concat(searchPoints)

    grids = removeDuplicateFeatures(grids)
    lines = removeDuplicateFeatures(lines)
    points = removeDuplicateFeatures(points)
    return {grids, lines, points}
  }

  /**
   * Summary: getting unique ID for the graphic to be highlighted
   * Precondition: highlightedItemInfoTool should not be an empty object
   */
  const getUniqueHighlightID = (arrayToFilter: any[]) => {
    /**
     * Use `globalIdFieldName` for majority of the cases. Use `objectIdFieldName` in cases:
     * (1) globalIdFieldName is undefined or the value for that attribute is undefined
     *    - for Gas Mains, globalIdFieldName is defined as "GLOBALID" but no such attribute exists in the attribute list
     * (2) The filtered array is empty when the globalIdFieldName is used as a unique identifier, then use objectIdFieldName
     *    - for RegionBoundary, globalIdFieldName is defined but returns an empty filtered array for some reason. */
    const { globalIdFieldName, objectIdFieldName } = highlightedItemInfoTool;
    return isUndefined(highlightedItemInfoTool.attributes[globalIdFieldName]) ||
      getHighlightedItems(arrayToFilter, globalIdFieldName).length === 0
      ? objectIdFieldName
      : globalIdFieldName;
  }

  /**
   * Summary: returns an array with the element to highlight 
   * @param array array that contains the highlighted item
   * @param id unique id for the item that is to be highlighted
   */
  const getHighlightedItems = (array: any[], id: string) =>
    array.filter(
      (element: any) =>
        highlightedItemInfoTool.attributes[id] === element.attributes[id]
    );

  /**
   * Summary: Get an array of unhighlighted items or array of one item to be highlighted
   * @param array array containing the highlighting item
   * @param highlight boolean highlight graphic
   * @returns array of items to draw
   */
  const itemsToDraw = (array: any[], highlight: boolean) => {
    if (isEqual(highlightedItemInfoTool, {}))
      return highlight ? [] : array;
    return highlight
      ? getHighlightedItems(array, getUniqueHighlightID(array))
      : array;
  };

  /**
   * Force the highlightedItemInfoTool to be drawn. 
   * @returns boolean - If an object was forced to be highlighted or not.
   */
  const checkAndForceHighlightObjects = (
    grids: any[],
    lines: any[],
    points: any[],
    layerToDrawOn: any
  ) => {
    let forcedHighlight = true;
    // When grids, lines, and points are empty, it means nothing is selected
    // If highlightedItem is populated, we force draw it on the map.
    if (
      ((isEmpty(grids) && isEmpty(lines) && isEmpty(points)) ||
        relationshipForceHighlight) &&
      !isEqual(highlightedItemInfoTool, {})
    ) {

      // reset the redux state
      if (relationshipForceHighlight) {
        dispatch({
          type: SET_FORCE_RELATIONSHIP_HIGHLIGHT + props.mapType,
          relationshipForceHighlight: false
        });
      }

      switch (highlightedItemInfoTool.polygonType) {
        case geometryType.point:
          drawPoints(layerToDrawOn, [highlightedItemInfoTool], true);
          break;
        case geometryType.polygon:
          drawPolygon(layerToDrawOn, [highlightedItemInfoTool], true);
          break;
        case geometryType.polyline:
          drawPolyLine(layerToDrawOn, [highlightedItemInfoTool], true);
          break;
        default:
          forcedHighlight = false;
          console.error("No geometry type found to draw.");
      }
    } else {
      forcedHighlight = false;
    }
    return forcedHighlight;
  };

  /**
   * Summary: Draw the graphics on the map
   * @param grids Array of grids to draw
   * @param lines Array of lines to draw 
   * @param points Array of points to be draw
   * @param highlight true would draw the highlighted items, false would draw non-highlighted items
   */
  const drawGraphics = (
    grids: any[],
    lines: any[],
    points: any[],
    highlight: boolean = false
  ) => {
    const layerIndex = highlight ? getHighlightLayerIndex() : getNonHighlightLayerIndex();
    console.log("info "+layerIndex)   
    const layerToDrawOn = layerIndex !== -1
      ? props.view.map.layers.items[layerIndex]
      : props.view;

    // Force highlight graphics if highlightedItemInfoTool is populated but nothing is selected
    if (checkAndForceHighlightObjects(grids, lines, points, layerToDrawOn)) {
      console.log("infotoo")
      return;
    }
    /**
     * drawing highlighted items, added check for draw polygon, since it's used in
     * survey assignment page. This checks if the draw instructions is coming from
     * info tool or the checkbox table. The checkbox table takes precident over info tool
     */
    drawPolyLine(layerToDrawOn, itemsToDraw(lines, highlight), highlight);
    drawPoints(layerToDrawOn, itemsToDraw(points, highlight), highlight);

    if (highlightedItemTable && !isEqual(highlightedItemTable,{})){
      removeAllGraphics(getNonHighlightLayerIndex())
      drawPolygon((props.view.map.layers.items[getNonHighlightLayerIndex()]), grids, false)
      drawPolygon(layerToDrawOn, highlightedItemTable, highlight); 
    } else {
      drawPolygon(layerToDrawOn, itemsToDraw(grids, highlight), highlight);
    }
  };

  const setVisibility = () => {
    if (layerVisibilityRef.current) {
      props.map.layers.forEach((layer:any) => {
        if (layer.type === layerType.feature) {
          const urlSplit = layer?.url?.split('/')
          //If layer id is map server, convert to feature server id (if one exists, otherwise it will be the same)
          if (urlSplit && urlSplit[urlSplit.length - 1] === 'MapServer') {
            layer.visible = layerVisibilityRef.current[mapIdToFeatureId[layer.layerId] + layer?.sourceJSON?.name]
          } else {
            layer.visible = layerVisibilityRef.current[layer.layerId + layer?.sourceJSON?.name]
          }
        }
      })
    }
  }

  const setLabelAssignPlanDetailsPage = (reversedLayers: any) => {
    props.view.layerViews.items.forEach((item:any) => {
      reversedLayers.forEach((reduxLayer: any) => {
        if (item.layer.type === layerType.feature && item.layer?.title.includes(reduxLayer?.Name)) {
          item.layer.labelsVisible = reduxLayer.hasLabels
          if (reduxLayer.LabelingInfo) {
            item.layer.labelingInfo[0].maxScale = reduxLayer.LabelingInfo.maxScale
            item.layer.labelingInfo[0].minScale = reduxLayer.LabelingInfo.minScale
          }
        }
      })
    });
  }

  const setFeatureLabels = (reversedLayers: any) => {
    props.map.layers.forEach((layer:any, index:number) => {
      if (layer.type === layerType.feature) {
        layer.labelsVisible = reversedLayers[index].hasLabels
        if (reversedLayers[index].LabelingInfo) {
          //Label format in settings is expected to be 1 field with no expressions
          //Ex: [CORROSIONAREA]
          //Converted to this format here: $feature.CORROSIONAREA
          let labelExpression = reversedLayers[index].LabelingInfo.labelExpression
          labelExpression = labelExpression.replace("[", "")
          labelExpression = labelExpression.replace("]", "")
          labelExpression = `$feature.${labelExpression}`
          layer.labelingInfo[0].labelExpressionInfo.expression = labelExpression
          layer.labelingInfo[0].maxScale = reversedLayers[index].LabelingInfo.maxScale
          layer.labelingInfo[0].minScale = reversedLayers[index].LabelingInfo.minScale
        }
      }
    })
  }
  

  /**
   * Summary: Set label scale and visibility
   */
  const setLabelInfo = (reversedLayers:any) => {
    // reverse the layers to match layerView order in map props
    
    if (location.pathname.includes(pages.surveyAssignPlanDetails)) {
      setLabelAssignPlanDetailsPage(reversedLayers);
    } else {
      setFeatureLabels(reversedLayers);
    }
  }

  const getHighlightLayerIndex = () =>
    props.view.map.layers.items.findIndex(
      (layer: any) => layer.id === graphicsLayerID.highlight
    );
  const getNonHighlightLayerIndex = () =>
    props.view.map.layers.items.findIndex(
      (layer: any) => layer.id === graphicsLayerID.nonHighlight
    );

  /**
   * Summary: Remove graphics
   * @param specificIndex number (optional) - if defined, index for the layer from
   *  which we remove graphics from, otherwise remove from both graphic layers
   */
  const removeAllGraphics = (specificIndex: number | undefined = undefined) => {
    if (specificIndex) {
      if (specificIndex === -1) {  // -1 means there might be graphics in the default layer
        return props.view.graphics.removeAll()
      } return props.view.map.layers.items[specificIndex]?.graphics?.removeAll()
    }
    const highlightLayer = getHighlightLayerIndex() !== -1
      ? props.view.map.layers.items[getHighlightLayerIndex()]
      : props.view;
    const nonHighlightLayer = getNonHighlightLayerIndex() !== -1
      ? props.view.map.layers.items[getNonHighlightLayerIndex()]
      : props.view;
    
    highlightLayer?.graphics?.removeAll()
    nonHighlightLayer?.graphics?.removeAll()
    return
  }

  /** Filter the selected grids/lines/points according to the highlighted records in info tool
   * this useEffect runs when either the checkbox table on survey assignment page runs or info tool
   * it has multi select now due to checkbox table
   */
  useEffect(() => {
    const {grids, lines, points} = prepareGraphicObjects()
    removeAllGraphics(getHighlightLayerIndex())

    if (location.search.toLowerCase().includes('id')) {
      /**
       * For specific pages like review, init, assign, graphic layers don't exist
       * so they are drawn on the default layer (props.view)
       */
      removeAllGraphics(-1)  // removing from the default layer
    }
    // drawing highlighted graphics
    drawGraphics(grids, lines, points, true);
  }, [highlightedItemInfoTool, highlightedItemTable] )

  useEffect(() => {
    //On these pages, have the user resume from the location of the map they were previously on
    if (!isEmpty(mapGoTo) && 
    (props.mapType === mapPageMapType || 
    (props.mapType === surveyAreaMapType && location.pathname === '/SurveyArea') || 
    (props.mapType === surveyInitiationMapType && location.search === ''))) {
      props.view.goTo({
        target: mapGoTo.center,
        zoom: mapGoTo.zoom
      })
    }

    return () => {
      if (props.mapType === mapPageMapType) {
        dispatch({type: SET_ACTIVE_TOOL + mapPageMapType, tool: widgetTypes.overview})
      }
      else {
        dispatch({type: SET_ACTIVE_TOOL + props.mapType, tool: widgetTypes.move})
      }

      if (props.mapType === mapPageMapType) {
        dispatch({type: SET_ACTIVE_TOOL + mapPageMapType, tool: widgetTypes.overview})
      }
      else {
        dispatch({type: SET_ACTIVE_TOOL + props.mapType, tool: widgetTypes.move})
      }

      //On these pages, store the location of the map the user was on when they leave the page
      if (props.mapType === mapPageMapType || 
        (props.mapType === surveyAreaMapType && location.pathname === '/SurveyArea') || 
        (props.mapType === surveyInitiationMapType && location.search === '')) {
        dispatch({
          type: SET_MAP_GO_TO, 
          center: props.view.extent.center,
          zoom: props.view.zoom
        })
      }
    }
  }, [])

  useEffect(() => {
    if (props.mapType !== mapPageMapType) {
      if (activeTool === widgetTypes.info) {
        props.slideoutRef.current.className = 'map-slideout'
      }
      else {
        props.slideoutRef.current.className = 'map-slideout query-hide'
      }
    }

    props.view?.when(() => {
      let css = document.querySelector('div.esri-component.esri-zoom.esri-widget')

      // prune add-on styles from Zoom Tool's base styling
      css?.classList.forEach((className: string) => {
        let styles = [...Object.values(styleBySlideoutTool), ...Object.values(styleByMapType)]

        styles.forEach((style: any) => {
          // className can be a combination of multiple style in styles
          if (className.includes(style)) {
            css?.classList.remove(className)
          }
        })
      })

      // add add-on styles onto Zoom Tool's base styling
      if (isOpen) {
        let toolCss = styleBySlideoutTool[activeSlideoutTool]

        if ([surveyAreaMapType, surveyInitiationMapType, surveyReviewMapType].includes(props.mapType)) {
          toolCss += styleByMapType[props.mapType]
        }

        css?.classList.add(toolCss)
        css?.classList.add(props.mapType.slice(1).toLowerCase().replace('_', '-') + '-widget-tools')
      }
    })
  }, [isOpen, activeSlideoutTool])

  useEffect(() => {
    const esriZoomWidget = document.querySelector('div.esri-component.esri-zoom.esri-widget')
    if (isNearestFeatureOpen) {
      esriZoomWidget?.classList.add('nearest-feature-translate')
    }
    else {
      esriZoomWidget?.classList.remove('nearest-feature-translate')
    }
  }, [isNearestFeatureOpen])

  useEffect(()=>{
    if (isSidePanelVisible) {
      setPositionClass('overview-tools')
    }
    else if (isNearestFeatureOpen) {
      setPositionClass('nearest-feature-translate')
    }
    else {
      setPositionClass('default-position')
    }
  }
  ,[isSidePanelVisible, isNearestFeatureOpen])

  useEffect(() => {
    if (queryWhere !== '') {
      //Geometry will remain null if bound is the entire map
      let geometry:any = null
      //Polygons for selected polygons bound
      let polygons: Object[] = []
      loadEsriModule('esri/geometry/geometryEngine').then((geometryEngine:any) => {
        //Set geometry to current extent
        if (queryBound === selectionBounds.screen) {
          const xmax = props.view.extent.xmax
          const xmin = props.view.extent.xmin
          const ymax = props.view.extent.ymax
          const ymin = props.view.extent.ymin
          geometry = {
            type: "polygon",
            rings: [
              [xmax, ymax],
              [xmax, ymin],
              [xmin, ymin],
              [xmin, ymax],
              [xmax, ymax]
            ],
            spatialReference: props.view.extent.spatialReference
          }
        }
        else if (queryBound === selectionBounds.selectedPolygon && selectedPolygons.length > 0) {
          //Get all geometry from selected and search grids
          selectedPolygons.forEach((grid:any) => {
            polygons.push({
              type: "polygon",
              rings: grid.geometry.rings,
              spatialReference: grid.geometry.spatialReference
            })
          })
          if (polygons.length > 1) {
            geometry = geometryEngine.union(polygons)
          }
          else {
            geometry = polygons[0]
          }
        }
        if (props.map.layers.items[0]) {
          selectFeatures(
            props.map.layers,
            props.mapType,
            queryLayer,
            dispatch,
            geometry,
            selectOperations.add,
            false,
            queryRelationship? selectionType.relationshipQuery:selectionType.query,
            queryWhere
          )
        }
      })
    }
  }, [queryWhere])

  useEffect(() => {
    if (props.map.layers.items[0] && tableRelationshipQueryWhere) {
      relationshipQueryFeatures(
        props.map.layers.items.find((layer:any) => layer.sourceJSON.id === tableRelationshipOriginId),
        tableRelationshipQueryWhere,
        tableOriginFieldName,
        tableDestinationFieldName,
        tableRelationshipResults.features,
        dispatch,
        false,
        tableRelationshipResults.tableId
      )
    }
  }, [tableRelationshipQueryWhere])

  return (
    <div id="widget-layer-container">
      <div className="overview-tool">
        <OverviewWidget
          {...props}
          currentTool={activeTool}
          activeSlideoutTool={activeSlideoutTool}
          isSlideoutOpen={isOpen}
          sketchWidget={sketchWidget}
          unselectWidget={unselectWidget}
          measureWidget={measureWidget}
          handleOverviewClick={props.toggleSidePanel}
          className={props.mapType === mapPageMapType ? "" : " hidden"}
        />
      </div>
      <div className={"query-toolbar" + (props.showToolbar?"":" hidden")}>
        <div className="top-border"></div>
        <SearchWidget
          {...props}
          {...{
            selectedGrids,
            selectedLines,
            selectedPoints,
            searchResults
          }}
          currentTool={activeTool}
          activeSlideoutTool={activeSlideoutTool}
          isSlideoutOpen={isOpen}
          sketchWidget={sketchWidget}
          unselectWidget={unselectWidget}
          measureWidget={measureWidget}
          mapType={props.mapType}
          className={props.mapType === surveyAssignmentMapType ? ' widget-hide':''}
        />
        <PointSelectWidget
          {...props}
          currentTool={activeTool}
          activeSlideoutTool={activeSlideoutTool}
          isSlideoutOpen={isOpen}
          sketchWidget={sketchWidget}
          unselectWidget={unselectWidget}
          measureWidget={measureWidget}
          className={
            props.mapType === surveyAssignmentMapType ||
            props.disableSelectionTools ||
            nearestFeaturesAreSelected() 
              ? ' widget-hide'
              : ''
          }
        />
        <div className={
          "expanded-tools" +
          ((activeTool !== "point" &&
          activeTool !== "rectangle" &&
          activeTool !== "polygon" &&
          activeTool !== "unselect") ? ' hide':'') +
          (props.mapType === surveyAssignmentMapType ||
          props.disableSelectionTools ||
          nearestFeaturesAreSelected()
            ? ' hidden'
            : '')
        }>
          <RectangleSelectWidget
            {...props}
            currentTool={activeTool}
            activeSlideoutTool={activeSlideoutTool}
            isSlideoutOpen={isOpen}
            sketchWidget={sketchWidget}
            unselectWidget={unselectWidget}
          />
          <PolygonSelectWidget
            {...props}
            currentTool={activeTool}
            activeSlideoutTool={activeSlideoutTool}
            isSlideoutOpen={isOpen}
            sketchWidget={sketchWidget}
            unselectWidget={unselectWidget}
          />
          <UnselectWidget
            {...props}
            currentTool={activeTool}
            activeSlideoutTool={activeSlideoutTool}
            isSlideoutOpen={isOpen}
            sketchWidget={sketchWidget}
            unselectWidget={unselectWidget}
          />
        </div>
        <UnselectAllWidget
          {...props}
          currentTool={activeTool}
          activeSlideoutTool={activeSlideoutTool}
          isSlideoutOpen={isOpen}
          sketchWidget={sketchWidget}
          unselectWidget={unselectWidget}
          measureWidget={measureWidget}
          className={props.mapType === surveyAssignmentMapType ? ' widget-hide':''}
          unselectSearchGeometry={props.disableSelectionTools}
        />
        <MeasureWidget
          {...props}
          currentTool={activeTool}
          activeSlideoutTool={activeSlideoutTool}
          isSlideoutOpen={isOpen}
          sketchWidget={sketchWidget}
          unselectWidget={unselectWidget}
          measureWidget={measureWidget}
          coordinateWidget={coordinateWidget}
          view={props.view}
          className={props.mapType === surveyAssignmentMapType ? ' widget-hide':''}
        />
        <InfoWidget
          {...props}
          currentTool={activeTool}
          activeSlideoutTool={activeSlideoutTool}
          isSlideoutOpen={isOpen}
          sketchWidget={sketchWidget}
          unselectWidget={unselectWidget}
          measureWidget={measureWidget}
          toggleSidePanel={props.toggleSidePanel}
        />
        <QueryWidget
          {...props}
          currentTool={activeTool}
          activeSlideoutTool={activeSlideoutTool}
          isSlideoutOpen={isOpen}
          sketchWidget={sketchWidget}
          unselectWidget={unselectWidget}
          measureWidget={measureWidget}
          toggleSidePanel={props.toggleSidePanel}
          className={props.mapType === mapPageMapType? '':'widget-hide'}
        />
        <EditWidget
          {...props}
          currentTool={activeTool}
          activeSlideoutTool={activeSlideoutTool}
          isSlideoutOpen={isOpen}
          sketchWidget={sketchWidget}
          unselectWidget={unselectWidget}
          measureWidget={measureWidget}
          editWidget={editWidget}
          className={props.mapType === mapPageMapType? '':'widget-hide'}
          layers={props.map.layers}
          toggleSidePanel={props.toggleSidePanel}
        />
        <TableOfContentsWidget
          {...props}
          currentTool={activeTool}
          activeSlideoutTool={activeSlideoutTool}
          isSlideoutOpen={isOpen}
          sketchWidget={sketchWidget}
          unselectWidget={unselectWidget}
          measureWidget={measureWidget}
          setForcedActiveSize={props.setForcedActiveSize}
        />
        </div>
      <div className="navigation-tools">
        <MoveWidget
          {...props}
          positionClass={positionClass}
          currentTool={activeTool}
          activeSlideoutTool={activeSlideoutTool}
          isSlideoutOpen={isOpen}
          sketchWidget={sketchWidget}
          unselectWidget={unselectWidget}
          measureWidget={measureWidget}
        />
        <LastExtentWidget
          positionClass={positionClass}
          mapType={props.mapType}
          currentTool={activeTool}
          activeSlideoutTool={activeSlideoutTool}
          isSlideoutOpen={isOpen}
          prevExtents={prevExtents}
          view={props.view}
        />
      </div>
    </div>
  )
}

export default WidgetLayer
