import axios from "axios";
import { store } from "app/store";
import { urlConfig } from "index";
import { axiosErrorHandler } from "utils/AxiosUtils";
import { getTableIdByName, getTableIndexByName } from "utils/GridUtils";

import { fillAllSurveyorInfo } from "utils/MapServiceUtils";
import qs from "qs";
import { mapQueryResults } from "utils/NameMappingUtils";
import { createSurveyArea } from "api/legacy/surveyAreaDucks";
import { getConfgurationForMapService } from "api/legacy/surveyAssignmentDucks";
import { arcgisQueryParams, concatQueryingParameters } from "api/ArcGIS/constants";
import { getSurveyJobWorkAssignmentsInfo } from "api/ArcGIS/arcGISCalls";
import { encodeUriAll } from "utils/UrlUtils";

export const EXISTING_SURVEYORS_LOADING =
  "WORKLOADMANAGEMENT_EXISTING_SURVEYORS_LOADING";
export const EXISTING_SURVEYORS_SUCCESS =
  "WORKLOADMANAGEMENT_EXISTING_SURVEYORS_SUCCESS";
export const WORKLOADMANAGEMENT_ERROR = "WORKLOADMANAGEMENT_ERROR";
export const SURVEYOR_DETAIL_LOADING = "SURVEYOR_DETAIL_LOADING";
export const SURVEYOR_DETAIL_SUCCESS = "SURVEYOR_DETAIL_SUCCESS";
export const SURVEYOR_DETAIL_ERROR = "SURVEYOR_DETAIL_ERROR";
export const UNASSIGN_SURVEYOR_SUCCESS =
  "WORKLOAD_MANAGEMENT_UNASSIGN_SURVEYOR_SUCCESS";
export const UNASSIGN_SURVEYOR_ERROR =
  "WORKLOAD_MANAGEMENT_UNASSIGN_SURVEYOR_ERROR";
export const SET_STATUS_MESSAGE = "WORKLOAD_SET_STATUS_MESSAGE";
export const CLEAR_STATUS_MESSAGE = "WORKLOAD_CLEAR_STATUS_MESSAGE";
export const CLEAR_ERROR = "WORKLOAD_CLEAR_ERROR";
export const ERROR = "ERROR_WORKLOAD";

export enum successTypes {
  unAssignSuccess = "unAssignSuccess",
}

export enum errorTypes {
  fetchSurveyors = "fetchSurveyorsError",
  fetchDetails = "fetchDetailError",
  unAssign = "unAssignError",
}

/**
 * Summary: Fetch all surveyors that exist in Lantern and
 * their respective buffer and service numbers
 */
export const getSurveyors =
  (usersWithouStatistics: boolean) => async (dispatch: any) => {
    let workAssignmentServiceId = getTableIdByName(
      store.getState().SettingsReducer.globalAliases
        .LanternSurveyJobWorkAssignments
    );
    let allSurveyors: Array<any> = [];
    const selectedMapService =
      store.getState().LoginReducer.selectedMapService.decodedLabel;

    // Todo: Remove this code after testing
    // url to find SurveyorRole variable name
    // const roleNameUrl =
    //   `${urlConfig.reactLanternServerUrl}` +
    //   `/Configuration.ashx/configuration?` +
    //   `mapserviceurl=${selectedMapService}`;
    if (usersWithouStatistics) {
      dispatch({ type: EXISTING_SURVEYORS_LOADING });
      
      // query to find which surveyors have workloads
      const queryingParams = {
        [arcgisQueryParams.where]: encodeUriAll(`Status='Active'`),
        [arcgisQueryParams.outFields]: `waUserID`,
        [arcgisQueryParams.returnDistinctValues]: `true`
      }
      getSurveyJobWorkAssignmentsInfo(
        selectedMapService,
        workAssignmentServiceId,
        concatQueryingParameters(queryingParams)
      )
        .then(function (workloadRes: any) {
          axiosErrorHandler(arguments);
          workloadRes = mapQueryResults(workloadRes);

          let surveyorsWithWorkloads = workloadRes.data.features;
          let allSurveyorsWithWorkloads = surveyorsWithWorkloads.map(
            (surveyor: any) => {
              return {
                waUserID: surveyor.attributes.waUserID,
                AssignedGrids: 0,
                BuffersRemaining: 0,
                TotalBuffers: 0,
              };
            }
          );

          dispatch({
            type: EXISTING_SURVEYORS_SUCCESS,
            allSurveyorsWithWorkloads,
          });
        })
        .catch((err: any) => {
          dispatch({
            type: ERROR,
            error: {
              message: err,
              type: errorTypes.fetchSurveyors,
            },
          });
          dispatch({
            type: WORKLOADMANAGEMENT_ERROR,
          });
        });
    } else {
      dispatch({ type: EXISTING_SURVEYORS_LOADING });

      // query to find which surveyors have workloads
      const queryingParams = {
        [arcgisQueryParams.where]: encodeUriAll(`Status='Active' OR Status='In progress'`),
        [arcgisQueryParams.orderByFields]: ``,
        [arcgisQueryParams.groupByFieldsForStatistics]: `waUserID`,
        [arcgisQueryParams.outStatistics]: 
          `[ ` +  
            `{ ` + 
              `"statisticType": "count",` +
              `"onStatisticField": "waUserID",` +
              `"outStatisticFieldName": "AssignedGrids"` + 
            `}, ` +
            `{ ` + 
              `"statisticType":"sum",` + 
              `"onStatisticField": "SurveyBufferCount+%2B+ServiceCount", ` + 
              `"outStatisticFieldName":"TotalBuffers"` + 
            `}, `+ 
            `{ ` + 
              `"statisticType":"sum", `+
              `"onStatisticField": "UncompletedServices+%2B+UncompletedSurveyBuffers", ` + 
              `"outStatisticFieldName":"BuffersRemaining" ` + 
            `} ` + 
          `]`
      }

      axios
        .all([
          getConfgurationForMapService(selectedMapService),
          getSurveyJobWorkAssignmentsInfo(
            selectedMapService,
            workAssignmentServiceId,
            concatQueryingParameters(queryingParams)
          ),
        ])
        .then(
          axios.spread(function (roleNameRes: any, workloadRes: any) {
            axiosErrorHandler(arguments);
            workloadRes = mapQueryResults(workloadRes);
            fillAllSurveyorInfo(roleNameRes, allSurveyors);

            let surveyorsWithWorkloads = workloadRes.data.features;
            let allSurveyorsWithWorkloads = allSurveyors.map(
              (surveyor: any) => {
                for (
                  let index = 0;
                  index < surveyorsWithWorkloads.length;
                  index++
                ) {
                  if (
                    surveyor.UserName ===
                    surveyorsWithWorkloads[index].attributes.waUserID
                  ) {
                    return surveyorsWithWorkloads[index].attributes;
                  }
                }
                return {
                  waUserID: surveyor.UserName,
                  AssignedGrids: 0,
                  BuffersRemaining: 0,
                  TotalBuffers: 0,
                };
              }
            );

            dispatch({
              type: EXISTING_SURVEYORS_SUCCESS,
              allSurveyorsWithWorkloads,
            });
          })
        )
        .catch((err) => {
          dispatch({
            type: ERROR,
            error: {
              message: err,
              type: errorTypes.fetchSurveyors,
            },
          });
          dispatch({
            type: WORKLOADMANAGEMENT_ERROR,
          });
        });
    }
  };

/**
 * Summary: Fetch details about a specific surveyor
 *
 * @param surveyorName
 */
export const getSurveyorDetails =
  (surveyorName: string) => async (dispatch: any) => {
    dispatch({ type: SURVEYOR_DETAIL_LOADING });

    let workAssignmentServiceID = getTableIdByName(
      store.getState().SettingsReducer.globalAliases
        .LanternSurveyJobWorkAssignments
    );
    const selectedMapService =
      store.getState().LoginReducer.selectedMapService.decodedLabel;

    // query to fetch data about surveyor
    const queryingParams = {
      [arcgisQueryParams.where]:
        `waUserid='${surveyorName}' ` +
        encodeUriAll(`AND Status IN ('Active', 'In Progress')`),
      [arcgisQueryParams.outFields]: 
        `waObjectID,PolygonName,SurveyJobID,SurveyPlanName,Status,ComplianceDate,SurveyBufferCount,` + 
        `ServiceCount,UncompletedServices,UncompletedSurveyBuffers`,
    };
    getSurveyJobWorkAssignmentsInfo(
      selectedMapService,
      workAssignmentServiceID,
      concatQueryingParameters(queryingParams)
    )
      .then((res: any) => {
        axiosErrorHandler(res);
        res = mapQueryResults(res);
        // prune all Work Assignments that have the same assigned user and Survey Job
        let seenSurveyJobs: any = [];
        let data: any = [];

        res.data.features.forEach((workAssignment: any) => {
          let surveyJobID = workAssignment.attributes.SurveyJobID;
          if (!seenSurveyJobs.includes(surveyJobID)) {
            seenSurveyJobs = [...seenSurveyJobs, surveyJobID];
            data = [...data, workAssignment];
          }
        });

        let surveyJobs = data.map(
          (workAssignment: any) => workAssignment.attributes
        );
        let surveyJobIds = res.data.features.map(
          (workAssignment: any) => workAssignment.attributes.SurveyJobID
        );
        let workAssignmentServiceID = getTableIdByName(
          store.getState().SettingsReducer.globalAliases.LanternWorkAssignments
        );
        const selectedMapService =
          store.getState().LoginReducer.selectedMapService.decodedLabel;
        let promiseFetches: Array<any> = [];

        //Fetch surveyor group for each work assignment
        surveyJobIds.forEach((surveyJobID: any) => {
          const queryingParams = {
            [arcgisQueryParams.where]: encodeUriAll(`SurveyJobID='${surveyJobID}'`)
          };
          promiseFetches.push(
            getSurveyJobWorkAssignmentsInfo(
              selectedMapService,
              workAssignmentServiceID,
              concatQueryingParameters(queryingParams)
            )
              .then((res: any) => {
                res = mapQueryResults(res);
                // prune all duplicate surveyors for any single Work Assignment
                let seenSurveyors: any = [];
                let surveyors: any = [];

                res.data.features.forEach((surveyor: any) => {
                  let userID = surveyor.attributes.UserID;
                  if (!seenSurveyors.includes(userID)) {
                    seenSurveyors = [...seenSurveyors, userID];
                    surveyors = [...surveyors, userID];
                  }
                });

                //sort surveyor so selected surveyor is first and massaging data to
                //better fit table component
                let surveyorIndex = surveyors.findIndex(
                  (surveyor: string) => surveyor === surveyorName
                );
                let tempSurveyor = surveyors[surveyorIndex];

                let surveyJobIndex = surveyJobs.findIndex(
                  (surveyJob: any) => surveyJobID === surveyJob.SurveyJobID
                );
                let tempSurveyJob = {
                  ...surveyJobs[surveyJobIndex],
                  surveyors,
                };

                let {
                  SurveyBufferCount,
                  ServiceCount,
                  UncompletedServices,
                  UncompletedSurveyBuffers,
                  ...rest
                } = tempSurveyJob;

                tempSurveyJob = {
                  buffersSurveyedCount: SurveyBufferCount + ServiceCount,
                  remainingBufferCount:
                    UncompletedServices + UncompletedSurveyBuffers,
                  ...rest,
                };
                surveyors[surveyorIndex] = surveyors[0];
                surveyors[0] = tempSurveyor;
                surveyJobs[surveyJobIndex] = tempSurveyJob;
              })
          );
        });

        Promise.all(promiseFetches).then(() => {
          dispatch({
            type: SURVEYOR_DETAIL_SUCCESS,
            surveyDetail: surveyJobs,
          });
        });
      })
      .catch((err: any) => {
        dispatch({
          type: ERROR,
          error: {
            message: err,
            type: errorTypes.fetchDetails,
          },
        });
        dispatch({
          type: SURVEYOR_DETAIL_ERROR,
        });
      });
  };

export const unassignUsers =
  (workAssignmentObjectIDs: Array<number>) => async (dispatch: any) => {

    // Todo: Remove this code after testing
    // const url = `${urlConfig.reactLanternServerUrl}/Editing.ashx/tables/commit`;
    let workAssignmentServiceID = getTableIndexByName(
      store.getState().SettingsReducer.globalAliases.LanternWorkAssignments
    );
    const selectedMapServiceName =
      store.getState().LoginReducer.selectedMapService.label;

    let requestBody = {
      mapservicename: selectedMapServiceName,
      token: "",
      editcontainer:
        `{
      "modifyEditItems": [],
      "createEditItems": [],
      "deleteEditItems":
      [
    ` +
        workAssignmentObjectIDs.map(
          (workAssignmentObjectID) =>
            `{
        "objectId": ${workAssignmentObjectID},
        "attributes": {
          "OBJECTID": ${workAssignmentObjectID}
        },
        "tableId": ${workAssignmentServiceID},
        "oldAttributes": {},
        "type": 2,
        "hasBeenUndone": false
      }
      `
        ) +
        `]}`,
    };

    createSurveyArea(requestBody)
      .then(() => {
        let workAssignments = [
          ...store.getState().WorkloadManagementReducer.surveyDetail,
        ];
        let newWorkAssignments = [];

        for (let index = 0; index < workAssignments.length; index++) {
          if (
            !workAssignmentObjectIDs.includes(workAssignments[index].waOBJECTID)
          ) {
            newWorkAssignments.push(workAssignments[index]);
          }
        }
        dispatch({
          type: UNASSIGN_SURVEYOR_SUCCESS,
          workAssignments: newWorkAssignments,
        });
        dispatch({
          type: SET_STATUS_MESSAGE,
          message: successTypes.unAssignSuccess,
        });
      })
      .catch((err) => {
        dispatch({
          type: ERROR,
          error: {
            message: err,
            type: errorTypes.unAssign,
          },
        });
        dispatch({
          type: UNASSIGN_SURVEYOR_ERROR,
        });
      });
  };

const initialState: any = {
  allSurveyorsWithWorkloads: [],
  surveyDetail: [],
  error: {},
};

export default function reducer(state = initialState, action: any) {
  switch (action.type) {
    case EXISTING_SURVEYORS_SUCCESS:
      return {
        ...state,
        allSurveyorsWithWorkloads: action.allSurveyorsWithWorkloads,
      };
    case SURVEYOR_DETAIL_SUCCESS:
      return {
        ...state,
        surveyDetail: action.surveyDetail,
      };
    case UNASSIGN_SURVEYOR_SUCCESS:
      return {
        ...state,
        surveyDetail: action.workAssignments,
      };
    case ERROR:
      return {
        ...state,
        error: action.error,
      };
    case SET_STATUS_MESSAGE:
      return {
        ...state,
        notificationMessage: action.message,
      };
    case CLEAR_STATUS_MESSAGE:
      return {
        ...state,
        notificationMessage: "",
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: "",
      };

    default:
      return state;
  }
}
