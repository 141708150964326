import React from 'react';
import './GridTable.scss'


interface IGridTableProps {
  editable?: "editable" | "non-editable"
  className?: string
}

const GridTable: React.FunctionComponent<IGridTableProps> = (props) => {
  return (
    <div className={"gridTable-container " + props.className + " " + props.editable}>
      <table>
        <tbody>
          <tr className="grid-header">
            <th className="grid-header-field">Field</th>
            <td className="grid-header-value">Value</td>
          </tr>
          {props.children}
        </tbody>
      </table>
    </div>
  )
}

GridTable.defaultProps = {
  className: "",
  editable: "editable"
}

export default GridTable
