/**
 * summary: function to sort fractions
 */
export const sortFraction = (A:any, B:any, columnId:any) => {
  let AValue = A.values[columnId]
  let APercentage = AValue.progress / AValue.total
  let BValue = B.values[columnId]
  let BPercentage = BValue.progress / BValue.total
  if(APercentage > BPercentage || isNaN(BPercentage)) {
    return 1
  }
  else if (APercentage < BPercentage || isNaN(APercentage)) {
    return -1
  }
}
