import { formDataFormat } from "api/formDataUtils";
import ashxBaseUrl from ".";

export const createMapConfguration = async (
  currentUser: any,
  requestBody: Object
) => {
  const createMapConfgurationUrl = `/View.ashx/configuration/${currentUser}/queries`;
  return await ashxBaseUrl.post(
    createMapConfgurationUrl,
    formDataFormat(requestBody),
    {
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
    }
  );
};

export const createMapQueries = async (requestBody: Object) => {
  const createMapQueriesUrl = `/View.ashx/queries/save`;
  return await ashxBaseUrl.post(
    createMapQueriesUrl,
    formDataFormat(requestBody)
  );
};

export const createMapQueriesDelete = async (requestBody: Object) => {
  const createMapQueriesDeleteUrl = `/View.ashx/queries/delete`;
  return await ashxBaseUrl.post(
    createMapQueriesDeleteUrl,
    formDataFormat(requestBody),
  );
};

export const getReportableItems = async (selectedMapService: any) => {
  const getReportableItemsUrl = `/View.ashx/reportableitems?mapServiceName=${selectedMapService}`;
  return await ashxBaseUrl.get(getReportableItemsUrl);
};
