import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { List } from "@mui/material";
import PopupModal from "common/PopupModal/PopupModal"
import Button from "common/Button/Button"
import NearestFeatureDropList from './NearestFeatureDropList'
import NearestFeatureAttributesTable from "./NearestFeatureAttributesTable";
import { loadEsriModule } from "utils/EsriUtils"
import { 
  SET_NEAREST_FEATURE_IS_OPEN,
  CLEAR_UNSELECTED_NEAREST_FEATURES,
  addNearestFeatures,
  addSetNearestFeatures
} from "features/MapPage/MapPageDucks"
import "./NearestFeature.scss"
import '../InfoWidget/Droplist/Droplist.scss';
import { autopopulateValues } from "./EditUtils";

interface INearestFeature {
  editWidget:any,
  showNearestFeatModal:boolean, 
  setShowNearestFeatModal:Function,
  showNearestFeatPanel:boolean, 
  setShowNearestFeatPanel:Function,
  nearestFeatStepDone:boolean,
  setNearestFeatStepDone:Function,
  chosenFeatures:Array<any>,
  setChosenFeatures:Function,
  layers:any,
  autoValues:{[index: string]:any},
  nearestFeatureLayerIds:{[index: number]:any},
  applyAutoValuesRef:any
}

const NearestFeature = ({
  editWidget,
  showNearestFeatModal, 
  setShowNearestFeatModal,
  showNearestFeatPanel,
  setShowNearestFeatPanel,
  nearestFeatStepDone,
  setNearestFeatStepDone,
  chosenFeatures,
  setChosenFeatures,
  layers,
  autoValues,
  nearestFeatureLayerIds,
  applyAutoValuesRef
}:INearestFeature) => {
  const dispatch = useDispatch()
  const nearestFeatureSettings = useSelector((state:any) => state.SettingsReducer.nearestFeatureSettings)
  const { 
    nearestFeaturesInfo,
    nearestFeatureGrids,
    nearestFeatureLines,
    nearestFeaturePoints
  } = useSelector((state:any) => state.MapPageReducer)

  //Data
  const [selectedLayer, setSelectedLayer] = useState<any>(null)
  const [selectedFeature, setSelectedFeature] = useState<any>(null)
  const [newFeatureLayerId, setNewFeatureLayerId] = useState<number>(-1)

  //Button statuses
  const [isSetNearestFeaturesDisabled, SetIsSetNearestFeaturesDisabled] = useState<boolean>(true)
  const [isContinueDisabled, setIsContinueDisabled] = useState<boolean>(true)

  /**
   * Set nearest feature and highlight it in green
   */
  const setFeature = () => {
    const chosenFeature = selectedFeature
    chosenFeature.isSet = true
    dispatch(addSetNearestFeatures(chosenFeature, selectedFeature.layer.layerId, selectedFeature.layer.geometryType))
    setChosenFeatures((prevState:any) => {
      return prevState.concat(selectedFeature)
    })
  }

  /**
   * Get values from nearest features to autopopulate the new feature
   * @param nearestFeatureValues Dictionary of fields and values for autopopulating the new feature
   */
  const getNearestFeatureValues = (nearestFeatureValues:{[index: string]:any}) => {
    let nearestFeatureFields:{[index: number]:any} = {}
    nearestFeatureSettings.forEach((setting:any) => {
      if (setting.layerId === newFeatureLayerId) {
        if (setting.sourceLayerId in nearestFeatureFields) {
          nearestFeatureFields[setting.sourceLayerId] = nearestFeatureFields[setting.sourceLayerId].concat(setting.nearestFeatureFields)
        }
        else {
          nearestFeatureFields[setting.sourceLayerId] = setting.nearestFeatureFields
        }
      }
    })

    chosenFeatures.forEach((feature:any) => {
      let fields = nearestFeatureFields[feature.layer.layerId]
      fields.forEach((field:any) => {
        nearestFeatureValues[field.destField] = feature.attributes[field.sourceField]
      })
    })
  }

  useEffect(() => {
    dispatch({type: SET_NEAREST_FEATURE_IS_OPEN, isOpen: showNearestFeatPanel})
    if (showNearestFeatPanel) {
      loadEsriModule('esri/geometry/geometryEngine').then((geometryEngine:any) => {
        const geometry = editWidget.activeWorkflow.data.edits.feature.geometry
        const layerId = editWidget.activeWorkflow.data.edits.feature.layer.layerId
        setNewFeatureLayerId(layerId)
        nearestFeatureSettings.forEach((setting:any) => {
          if (setting.layerId === layerId && setting.bufferDistance) {
            layers.items.forEach((item:any) => {
              const buffer = geometryEngine.geodesicBuffer(geometry, [setting.bufferDistance], "meters", true)
              //Iterate through layers with geometry
              if (item.geometryType && nearestFeatureLayerIds[layerId].includes(item.layerId)) {
                const query = item.createQuery()
                query.geometry = buffer
                query.outFields = ["*"]
                item.queryFeatures(query)
                  .then(function(results:any) {
                    if (results.features.length > 0) {
                      dispatch(
                        addNearestFeatures(
                          results.features, 
                          item.geometryType, 
                          item.layerId,
                          item.sourceJSON.name,
                          item.sourceJSON.displayField
                        )
                      )
                    }
                  })
              }
            })
          }
        })
      })
    }
  }, [showNearestFeatPanel])

  useEffect(() => {
    if (editWidget) {
      if (showNearestFeatPanel) {
        editWidget.container.style.display = 'none'
      }
      else {
        editWidget.container.style.display = 'initial'
        if (editWidget?.layerInfos) {
          autopopulateValues(editWidget, autoValues, applyAutoValuesRef)
          if (nearestFeatStepDone) {
            //When nearest feature selection is complete, autopopulate the feature form
            const nearestFeatureValues:{[index: string]:any} = {}
            getNearestFeatureValues(nearestFeatureValues)
            autopopulateValues(editWidget, nearestFeatureValues, applyAutoValuesRef)
          }
        }
      }
    }
  }, [showNearestFeatPanel])

  useEffect(() => {
    if (selectedLayer && !selectedLayer?.hasSetFeature) {
      SetIsSetNearestFeaturesDisabled(false)
    }
    else {
      SetIsSetNearestFeaturesDisabled(true)
    }
  }, [selectedLayer, selectedLayer?.hasSetFeature])

  useEffect(() => {
    if (chosenFeatures.length !== Object.keys(nearestFeaturesInfo).length) {
      setIsContinueDisabled(true)
    }
    else {
      setIsContinueDisabled(false)
    }
  }, [chosenFeatures.length, Object.keys(nearestFeaturesInfo).length])

  return (
    <div>
      {showNearestFeatModal && (
        <PopupModal
          openBtn={<div></div>}
          title={"Nearby Features"}
          isOpenButtonRemoved={true}
          message={`Would you like to select Nearest Features to pre-populate new feature details? 
          Skipping this step will require you to enter the Nearest Feature information manually.`}
          confirmBtn="Yes"
          confirmClick={() => {
            setShowNearestFeatPanel(true);
            setShowNearestFeatModal(false);
          }}
          cancelBtn="No"
          onClose={() => {
            setShowNearestFeatModal(false)
            if (!showNearestFeatPanel) {
              autopopulateValues(editWidget, autoValues, applyAutoValuesRef)
            }
          }}
          modalClassName={"nearest-feature-modal"}
        />
      )}
      {showNearestFeatPanel && (
        <div id="nearest-feature-panel">
          <div id="nearest-feature-header">
            <div id="nearest-feature-title">Nearby Features</div>
            <PopupModal
              openBtn={<div>Cancel</div>}
              openButtonClass={"nearest-feature-cancel"}
              openButtonColor={"grey"}
              openButtonSize={"s"}
              title={"Cancel Nearest Feature Selection"}
              message={`Are you sure you want to cancel Nearest Feature selection? 
              You will need to enter the Nearest Feature information manually.`}
              confirmBtn="Yes"
              confirmClick={() => {setShowNearestFeatPanel(false); SetIsSetNearestFeaturesDisabled(true);}}
              cancelBtn="No"
              onClose={() => {setSelectedFeature(undefined)}}
              cancelClick={() => {}}
              modalClassName={"nearest-feature-modal"}
            />
          </div>
          <div id="nearest-feature-body">
            <div id="nearest-feature-droplist">
              <List>
                {Object.values(nearestFeaturesInfo).map(
                  (layer: any, index: number) => (
                    <NearestFeatureDropList
                      key={index}
                      {...{ layer, setSelectedLayer, selectedFeature, setSelectedFeature }}
                    />
                  )
                )}
              </List>
            </div>
            <div id="nearest-feature-table-container">
              {selectedFeature ? (
                <NearestFeatureAttributesTable
                  selectedFeature={selectedFeature}
                  selectedLayer = {selectedLayer}
                />
              ) : <div className="nearest-feature-message">No nearby feature selected.</div>}
              <div id="nearest-feature-buttons">
                <Button
                  boxColor={isSetNearestFeaturesDisabled ? "grey" : "blue"}
                  size="s"
                  className="nearest-feature-button"
                  isDisabled={isSetNearestFeaturesDisabled}
                  onClick={() => setFeature()}
                >
                  Set As Nearest Feature
                </Button>
                <Button
                  boxColor={isContinueDisabled ? "grey" : "blue"}
                  size="s"
                  className="nearest-feature-button"
                  isDisabled={isContinueDisabled}
                  onClick={() => {
                    setShowNearestFeatPanel(false)
                    setNearestFeatStepDone(true)
                    setSelectedFeature(null)
                    dispatch({
                      type: CLEAR_UNSELECTED_NEAREST_FEATURES,
                      nearestFeaturePoints: nearestFeaturePoints,
                      nearestFeatureLines: nearestFeatureLines,
                      nearestFeatureGrids: nearestFeatureGrids,
                    });
                  }}
                >
                  {`Continue (${chosenFeatures.length}/${Object.keys(nearestFeaturesInfo).length})`}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default NearestFeature;