import React from 'react';
import './Loading.scss';

const Loading = () => {
	return (
		<div className="loading-bar" />
	)
}

export default Loading;
