import { idenManager } from 'utils/EsriUtils';
import { loadModules } from 'esri-loader';
import {store} from 'app/store';
import { urlConfig } from 'index';
import { getArcGISToken } from 'api';

/**
 * Summary: Registers given token under userName in IdentityManager
 *
 * @param token Token to be registered
 * @param userName name that token will be stored under
 */
export const registerToken = async () => {
  const ArcGISToken= await getArcGISToken()
  idenManager.registerToken({
    server: `${window.urlConfig.reactUrl}`,
    token: ArcGISToken.data.token,
    userId: ArcGISToken.data.username,
  })
}

// /**
//  * Summary: Registers given token under userName in IdentityManager
//  *
//  * @param token Token to be registered
//  * @param userName name that token will be stored under
//  */
// export const refreshToken = async () => {
//   const date = new Date();
//   if (date.getTime() >= idenManager.credentials[0].expires) {
//     console.log("yes")
//     const ArcGISToken = await getArcGISToken();
//     localStorage.setItem("ArcGISToken", ArcGISToken.data.token);
//     registerToken(
//       ArcGISToken.data.token,
//       ArcGISToken.data.username,
//       ArcGISToken.data.expiryTimeUTC
//     );
//   }
// };

/**
 * Summary: Checks the expiry of ArcGIS token and if expired it will request a new token 
 * and reregister the token with arcGIS Javascript library
 */
export const reRegisterToken = async () => {
  if (isTokenExpired()) {
    registerToken();
  }
};

/**
 * Summary: Checks the expiry of ArcGIS token and if expired it will request a new token 
 */
export const refreshTokenIfExpired = async () => {
  if (isTokenExpired()) {
    await getArcGISToken();
  }
};

/**
 * Summary: Checks the expiry of ArcGIS token
 */
const isTokenExpired = () => {
  const currentDate = new Date();
  const ArcGISTokenExpiry: any = localStorage.getItem("ArcGISTokenExpiry");
  const expiryDate = new Date(ArcGISTokenExpiry );
  //subtracting a minute from the expiry time sothat we can request the token a minute earlier
  expiryDate.setMinutes(expiryDate.getMinutes() - 1);
  return currentDate.getTime() > expiryDate.getTime();
}


/**
 * Summary: Retrieve User Role name given Id
 *
 * @param UserRoleId Id for the respective user RoleName
 *
 * @return String: UserRole name
 */

 export const getUserRoleNameById = (roleId:number) => {
   const roles = store.getState().SettingsReducer.securityRoles.Roles
   for(let i = 0; i < roles.length; i++){
     if(roles[i].RoleId === roleId) {
       return roles[i].RoleName
     }
   }
 }

/**
 * Summary: Checks if the currently selected user role has access to the given tool
 * Note: roleId needed to be passed as isToolAvailable needs to be re-rendered when roleId is changed
 * typically when roles are changed
 * @param toolName Given tool name to check user availablility, should match names given in admin
 * @param roleId Id of role that we need to check if the tool is available for
 *
 * @return boolean
 *
 */

  export const isToolAvailable = (toolName:string, roleId:number) => {
    const currentRoleName = getUserRoleNameById(roleId)
    const {userPermissions} = store.getState().SettingsReducer
    let rolePermissions = []

    for(let i = 0; i < userPermissions.length; i++) {
      if(userPermissions[i].name === currentRoleName) {
        rolePermissions = userPermissions[i]
      }
    }

    for(let i = 0; i < rolePermissions.toolInfos.length; i++) {
      if(rolePermissions.toolInfos[i].className === toolName) {
        return rolePermissions.toolInfos[i].isActive
      }
    }

    return false
  }
