import React from 'react';
import './JobsSummary.scss';
import { surveyJobCountTypes } from 'features/MapPage/MapPageDucks';

interface IJobsSummary {
  overviewDetails: any
  mapType: string
}

const JobsSummary = ({
  overviewDetails,
  mapType
}:IJobsSummary) => {
  return (
    <div className="jobs-summary-container">
      <div className="overview-section-title">
        Jobs Summary
      </div>
      <div className="summaries-list-container">
        <div className="summary-body-container">
          <div className="summary-title">
            Completed (YTD)
          </div>
          <div className="summary-number">
            {overviewDetails[surveyJobCountTypes.completedJobs]}
          </div>
        </div>
        <div className="summary-body-container">
          <div className="summary-title">
            Active
          </div>
          <div className="summary-number">
            {overviewDetails[surveyJobCountTypes.activeJobs]}
          </div>
        </div>
        <div className="summary-body-container">
          <div className="summary-title">
            Planned
          </div>
          <div className="summary-number">
            {overviewDetails[surveyJobCountTypes.plannedJobs]}
          </div>
        </div>
      </div>
    </div>
  )
}

export default JobsSummary
