import ashxBaseUrl from ".";

export const getAllJobs = async () => {
  const getAllJobsUrl = `/Configuration.ashx/schedule/queue`;
  return await ashxBaseUrl.get(getAllJobsUrl);
};

export const updateRescheduleJob = async (rowIds: any) => {
  const updateRescheduleJobUrl = `/Configuration.ashx/schedule/reschedule?rowIds=[${rowIds}]`;
  return await ashxBaseUrl.get(updateRescheduleJobUrl);
};

  export const postClearProcessedJobs = async (rowIds: any) => {
    const postClearProcessedJobsUrl = `/Configuration.ashx/schedule/delete?rowIds=[${rowIds}]`;
    return await ashxBaseUrl.get(postClearProcessedJobsUrl);
  };

export const updatePriority = async (requestBody: Object) => {
  const updatePriorityUrl = `/Configuration.ashx/schedule/setPriority`;
  return await ashxBaseUrl.post(updatePriorityUrl, requestBody);
};