import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as PolygonSelectIcon } from 'assets/PolygonSelectIcon.svg';
import { widgetTypes, IWidgetProps } from '../MapWidgetLayer';
import { SET_ACTIVE_TOOL } from '../MapDucks';
import { isToolAvailable } from 'utils/IdentityManagerUtils';
import { LanternTools } from 'app/constants';
import { surveyAreaMapType, surveyInitiationMapType } from '../MapDucks';

export const PolygonSelectWidget = ({
  currentTool,
  activeSlideoutTool,
  isSlideoutOpen,
  sketchWidget,
  unselectWidget,
  mapType
}:IWidgetProps) => {
  const dispatch = useDispatch()
  const roleId = useSelector((state:any) => state.LoginReducer.selectedUserRole)

  function handleClick() {
    if (currentTool === widgetTypes.polygon) {
      sketchWidget?.cancel()
      dispatch({type: SET_ACTIVE_TOOL + mapType, tool: widgetTypes.move})
    }
    else {
      unselectWidget?.cancel()
      sketchWidget?.create(widgetTypes.polygon);
      dispatch({type: SET_ACTIVE_TOOL + mapType, tool: widgetTypes.polygon})
    }
  }

  if (
    isToolAvailable(LanternTools.SELECT,roleId) ||
    mapType === surveyAreaMapType ||
    mapType === surveyInitiationMapType
  ) {
    return (
      <button
        title="Polygon select"
        id="polygon-select-btn"
        className={"expanded-btn" + (currentTool===widgetTypes.polygon?" btn-active":"")}
        onClick={handleClick}
      >
        <PolygonSelectIcon/>
      </button>
    )
  }
  else {
    return null
  }
}
