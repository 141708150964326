import React, { useEffect } from "react";
import './DatePicker.scss'
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';
import format from 'date-fns/format';

interface IDatePickerProps {
  updateDate: Function
  defaultDate?: Date
  isReset?: boolean
}

const DatePicker: React.FunctionComponent<IDatePickerProps> = (props) => {

  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    props.defaultDate?props.defaultDate:null
  );

  /**
   * summary: update selected date
   *
   * @param date new date to be updated
   */
  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    if (date === null) {
      props.updateDate(date);
    } else if ((date !== null) && (!isNaN(date.getTime()))) {
        props.updateDate(format(date,'yyyy/M/dd'));
    }
  };

  useEffect(() => {
    if (props.isReset) {
      handleDateChange(props.defaultDate?props.defaultDate:new Date())
    }
  }, [props.isReset])

  useEffect(() => {
    setSelectedDate(props.defaultDate ? props.defaultDate : null)
  }, [props.defaultDate])

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        fullWidth={false}
        variant="inline"
        disableToolbar
        value={selectedDate}
        onChange={date => handleDateChange(date)}
        format="yyyy/MM/dd"
        autoOk={true}
      />
    </MuiPickersUtilsProvider>
  );
}

export default DatePicker;
