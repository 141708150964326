import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

/**
 * summary: Filter algorithm for rows with multiple values
 *
 * @param {Array} rows Data that is being filtered
 * @param {string} filterCategory column that the filter will be applied on
 * @param {Array} filterValue Values used to filter rows
 *
 * @returns {Array} Data that is filtered and to be used in the new table
 */
export function filterMultiValue(rows, filterCategory, filterValue, pathValue = '') {
  let filteredRows = []
  if (isEmpty(filterValue)){
    filteredRows = rows
  }

  else if (filterValue.length > 0) {
    filteredRows = rows.filter(row => {
      let include = false
      let cellData = row.values[filterCategory]
      if (cellData){
      // matches two arrays (filterValue and cellData) to check if either one has matching elements
        include = cellData.some(value => {
          return filterValue.includes(get(value,pathValue,value))})
      }
      else {
        include = false
      }

      return include
    })
  }

  return filteredRows
}

/**
 * summary: Filter algorithm for rows with users
 * 
 * Note: this assumes the column's value is an Array with users in the format:
 * { Attributes: [UserID: {string}, ...] }
 *
 * @param {Array} rows Data that is being filtered
 * @param {string} filterCategory column that the filter will be applied on
 * @param {Array} filterValue Values used to filter rows
 *
 * @returns {Array} Data that is filtered and to be used in the new table
 */
export function filterMultiSelectValueForUsers(rows, filterCategory, filterValue) {
  return filterMultiValue(rows, filterCategory, filterValue, 'Attributes.UserID')
}
