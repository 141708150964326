import axios from "axios";
import { isArray, isNil, isUndefined, map } from "lodash";
import { store } from "app/store";
import { history, urlConfig } from "index";
import {
  getActiveJobSurveyors,
  updateAssignedSurveyors,
  getStatusCountConfig,
  getFeatureCountConfig,
} from "api/legacy/surveyAssignmentDucks";
import {
  surveyReviewMapType,
  storeInfoTables,
  SET_GEOMETRY,
  SET_SURVEY_REVIEW_GEOMETRY,
  SET_GRID_TYPE,
  SET_SURVEY_REVIEW_TABLES,
} from "common/Map/MapDucks";
import {
  constructFiltersForQuery,
  constructSortForQuery,
  isObjectNotEmpty,
} from "utils/GridUtils";
import { esriGeometryType } from "common/Map/SelectFeatures";
import { RGBColors } from "common/Map/MapUtils/DrawUtils";
import { blankLabel } from "common/Dropdown/Dropdown";
import { getTableConfigData } from "common/Tables/PaginatedTable/TableUtils/TableConfigUtils";
import { statusTypes } from "common/Tables/PaginatedTable/TableUtils/PredefinedFilterTemplates/StatusTypes";
import { notificationType } from "common/NotificationMessage/NotificationMessage";
import { axiosErrorHandler } from "utils/AxiosUtils";
import { getAllLayerIds } from "utils/GridUtils";
import {
  getTableIdByName,
  getTableIndexByName,
  getLayerMapServiceIdByName,
  getGlobalIdFieldNameByLayerID,
  getGridIdFieldByName,
} from "utils/GridUtils";
import { joinSurveyJobAndPlan, surveyJobStatus, getUrlParam } from "utils/FieldsUtils";
import { getCurrentDate } from "utils/DateUtils";

import {
  massageGeoCordData,
  massageSurveyReviewGridData,
  massageSurveyReviewTableData,
} from "utils/MapServiceUtils";
import { mapAttribute, mapQueryResults } from "utils/NameMappingUtils";

import { encodeUriAll } from "utils/UrlUtils";
import {
  arcgisQueryParams,
  concatQueryingParameters,
} from "api/ArcGIS/constants";
import { getArcgisQuery, getSurveyJobInfo, getSurveyPlanInfo } from "api";

export const SURVEY_PLANS_LOADING = "SR_SURVEY_PLANS_LOADING";
export const SURVEY_PLANS_SUCCESS = "SR_SURVEY_PLANS_SUCCESS";
export const SURVEY_PLANS_ERROR = "SR_SURVEY_PLANS_ERROR";
export const SURVEY_PLAN_LOADING = "SR_SURVEY_PLAN_LOADING";
export const SURVEY_PLAN_SUCCESS = "SR_SURVEY_PLAN_SUCCESS";
export const SURVEY_PLAN_ERROR = "SR_SURVEY_PLAN_ERROR";
export const SURVEY_PLAN_FILTERS_LOADING = "SR_SURVEY_PLAN_FILTERS_LOADING";
export const SURVEY_PLAN_FILTERS_SUCCESS = "SR_SURVEY_PLAN_FILTERS_SUCCESS";
export const SURVEY_PLAN_FILTERS_ERROR = "SR_SURVEY_PLAN_FILTERS_ERROR";
export const SURVEY_PLAN_ALL_FILTERS_SUCCESS = "SR_SURVEY_PLAN_ALL_FILTERS_SUCCESS";
export const SURVEY_JOBS_LOADING = "SR_SURVEY_JOBS_LOADING";
export const SURVEY_REVIEW_JOBS_SUCCESS = "SR_SURVEY_JOBS_SUCCESS";
export const SURVEY_JOBS_ERROR = "SR_SURVEY_JOBS_ERROR";
export const SURVEY_JOB_DETAILS_LOADING = "SR_SURVEY_JOB_DETAILS_LOADING";
export const SURVEY_JOB_DETAILS_SUCCESS = "SR_SURVEY_JOB_DETAILS_SUCCESS";
export const SURVEY_REVIEW_SURVEY_JOBS_PRE_FILTER_VALUES_SUCCESS = "SURVEY_REVIEW_SURVEY_JOBS_PRE_FILTER_VALUES_SUCCESS";
export const SURVEY_REVIEW_SURVEY_JOBS_ALL_PRE_FILTER_VALUES_SUCCESS = "SURVEY_REVIEW_SURVEY_JOBS_ALL_PRE_FILTER_VALUES_SUCCESS"
export const SURVEY_JOB_DETAILS_ERROR = "SR_SURVEY_JOB_DETAILS_ERROR";
export const SURVEY_APPROVAL_SUCCESS = "SURVEY_APPROVAL_SUCCESS";
export const SURVEY_REJECT_SUCCESS = "SURVEY_REJECT_SUCCESS";
export const SURVEY_REVIEW_CONFIG_LOADING = "SURVEY_REVIEW_CONFIG_LOADING";
export const SURVEY_REVIEW_CONFIG_SUCCESS = "SURVEY_REVIEW_CONFIG_SUCCESS";
export const SURVEY_REVIEW_CONFIG_ERROR = "SURVEY_REVIEW_CONFIG_ERROR";
export const FEATURE_COUNT_LOADING = "FEATURE_COUNT_LOADING";
export const FEATURE_COUNT_SUCCESS = "FEATURE_COUNT_SUCCESS";
export const STATUS_COUNT_LOADING = "STATUS_COUNT_LOADING";
export const STATUS_COUNT_SUCCESS = "STATUS_COUNT_SUCCESS";
export const FILTERED_SURVEY_PLANS_SUCCESS =
  "SURVEY_REVIEW_FILTERED_SURVEY_PLANS_SUCCESS";
export const FILTER_SURVEYPLANS_BY_WORKGRIDS =
  "SURVEY_REVIEW_FILTER_SURVEYPLANS_BY_WORKGRIDS";
export const CLEAR_GRID_FILTER = "SURVEY_REVIEW_CLEAR_GRID_FILTER";
export const SET_STATUS_MESSAGE = "SURVEY_REVIEW_SET_STATUS_MESSAGE";
export const CLEAR_STATUS_MESSAGE = "SURVEY_REVIEW_CLEAR_STATUS_MESSAGE";
export const CLEAR_ERROR = "SURVEY_REVIEW_CLEAR_ERROR";
export const ERROR = "SURVEY_REVIEW_ERROR";
export const PLANS_TABLE_CONFIG = "SURVEY_REVIEW_PLANS_TABLE_CONFIG";
export const PLANS_TABLE_CONFIG_SUCCESS =
  "SURVEY_REVIEW_PLANS_TABLE_CONFIG_SUCCESS";
export const JOBS_TABLE_CONFIG = "SURVEY_REVIEW_JOBS_TABLE_CONFIG";
export const JOBS_TABLE_CONFIG_SUCCESS =
  "SURVEY_REVIEW_JOBS_TABLE_CONFIG_SUCCESS";
export const SET_SURVEY_REVIEW_ON_MOUNT_STATUS_OPTIONS =
  "SURVEY_REVIEW_SET_ON_MOUNT_STATUS_OPTIONS";
export const SET_SURVEY_REVIEW_USER_SELECTIONS = "SET_SURVEY_REVIEW_USER_SELECTIONS"
export const STATUS_TOGGLE_LOADING = "SURVEY_REVIEW_STATUS_TOGGLE_LOADING"
export const STATUS_TOGGLE_SUCCESS = "SURVEY_REVIEW_STATUS_TOGGLE_SUCCESS"
export const STATUS_TOGGLE_ERROR = "SURVEY_REVIEW_STATUS_TOGGLE_ERROR"
export const FEATURE_TOGGLE_LOADING = "SURVEY_REVIEW_FEATURE_TOGGLE_LOADING"
export const FEATURE_TOGGLE_SUCCESS = "SURVEY_REVIEW_FEATURE_TOGGLE_SUCCESS"
export const FEATURE_TOGGLE_ERROR = "SURVEY_REVIEW_FEATURE_TOGGLE_ERROR"
export const surveyReviewDefaultStatusOptions = [
  statusTypes.active,
  statusTypes.planned,
  statusTypes.completed,
];
export const surveyReviewDetailsDefaultStatusOptions = [];
export const filterGridsError = "filterGridsError";
export const createdOnField = "CreatedOn";
export const polygonNameField = "PolygonName";


/**
 * Summary: Fetch the Historical data
 * @param statusOptions array of status options
 * @param dispatch dipsatch
 * @param surveyReviewContainsHistorical boolean if historical data has bene fetched
 */
export const getHistoricalData = (
  statusOptions: any[],
  dispatch: any,
  surveyReviewContainsHistorical: boolean
) => {
  if (isArray(statusOptions)) {
    const newStatusOptions = statusOptions.map((element) => element.value);
    // Make the API call to include Historical data only if it hasn't arleady been loaded.
    if (
      newStatusOptions.includes(statusTypes.historical) &&
      !surveyReviewContainsHistorical
    ) {
      dispatch({
        type: SET_SURVEY_REVIEW_ON_MOUNT_STATUS_OPTIONS,
        onMountStatusOptions: newStatusOptions,
      });
      // fetch new data
      // dispatch(getSurveyPlans(true));
    }
  }
};

// function to get a list of the filter values for all the rows
export const getSurveyPlansDistinctFilteredRows =
  (
    surveyWorkType: string,
    gridFilterValue?: any,
    filteredSortColumnMap?: any,
    existingPlanTableColumns?: any,
    initialLoad?: boolean
  ) =>
  async (dispatch: any) => {
    // dispatch({ type: SURVEY_PLAN_FILTERS_LOADING });

  let surveyPlanTableId = getTableIdByName(
    store.getState().SettingsReducer.globalAliases.LanternSurveyPlan
  );
  let surveyJobTableId = getTableIdByName(
    store.getState().SettingsReducer.globalAliases.LanternSurveyJob
  );
  const selectedMapService =
    store.getState().LoginReducer.selectedMapService.decodedLabel;
  const tableId = gridFilterValue ? surveyJobTableId : surveyPlanTableId;

    let formattedWhereClause = `1=1 and SurveyWorkType='${surveyWorkType}'`;
    let formattedOrderByClause = `${createdOnField} DESC`;
    if (isObjectNotEmpty(filteredSortColumnMap)) {
      formattedWhereClause = constructFiltersForQuery(
        formattedWhereClause,
        filteredSortColumnMap
      );
      formattedOrderByClause = constructSortForQuery(
        formattedOrderByClause,
        filteredSortColumnMap
      );
    }
    let defaultOutFields =
      "SurveyPlanName, PlanCategory, Region, District, SurveyYear, Status, ComplianceDate, CreatedOn, SurveyExecType, ReportingType";
    defaultOutFields =
      existingPlanTableColumns?.length > 0
        ? existingPlanTableColumns.toString() + `,${createdOnField}`
        : defaultOutFields;
    const queryingParams = concatQueryingParameters({
      [arcgisQueryParams.where]: encodeUriAll(formattedWhereClause),
      [arcgisQueryParams.outFields]: defaultOutFields,
      [arcgisQueryParams.returnDistinctValues]: "true",
      [arcgisQueryParams.orderByFields]: encodeUriAll(formattedOrderByClause),
    });

    getArcgisQuery(selectedMapService, tableId, queryingParams)
      .then((res: any) => {
        axiosErrorHandler(res);
        if (res.data.error) {
          throw res.data.error;
        }
        res=mapQueryResults(res)
        if (initialLoad) {
          dispatch({
            type: SURVEY_PLAN_ALL_FILTERS_SUCCESS,
            surveyPlansPreFilterValues: res.data.features,
          });
        } else {
          dispatch({
            type: SURVEY_PLAN_FILTERS_SUCCESS,
            surveyPlansPreFilterValues: res.data.features,
          });
        }
      })
      .catch((err: any) => {
        dispatch({
          type: ERROR,
          error: {
            message: err,
            type: "fetchExisting",
          },
        });
        dispatch({ type: SURVEY_PLAN_FILTERS_ERROR });
      });
  };
export const getSurveyPlansByPage =
  (
    pageSize: number,
    surveyWorkType: string,
    gridFilterValue: any,
    includeTotalCount:boolean =true,
    pageNumber?: number,
    filteredSortColumnMap?: any,
    totalCount?: number
  ) =>
  async (dispatch: any) => {
    dispatch({ type: SURVEY_PLANS_LOADING });
    const selectedMapService =
    store.getState().LoginReducer.selectedMapService.decodedLabel;
    
    let surveyPlanTableId = getTableIdByName(
      store.getState().SettingsReducer.globalAliases.LanternSurveyPlan
      );
      let surveyJobTableId = getTableIdByName(
        store.getState().SettingsReducer.globalAliases.LanternSurveyJob
        );
        const tableId = gridFilterValue ? surveyJobTableId : surveyPlanTableId;
  
    let formattedWhereClause: any =
      `SurveyWorkType='${surveyWorkType}'` +
      `${gridFilterValue ? `AND PolygonName='${gridFilterValue}'` : ``}`;

    let formattedOrderByClause: any = "CreatedOn DESC";

    
      if (isObjectNotEmpty(filteredSortColumnMap)) {
        formattedWhereClause = constructFiltersForQuery(
          formattedWhereClause,
          filteredSortColumnMap
        );
        formattedOrderByClause = constructSortForQuery(
          formattedOrderByClause,
          filteredSortColumnMap
        );
      }
  
      let totalCountQueryParams: any, dataQueryParams:any = {
        [arcgisQueryParams.where]: encodeUriAll(formattedWhereClause),
        [arcgisQueryParams.outFields]: "*",
        [arcgisQueryParams.orderByFields]: formattedOrderByClause,
        [arcgisQueryParams.resultRecordCount]: pageSize
      };
    
      if(includeTotalCount){
        totalCountQueryParams = concatQueryingParameters({...dataQueryParams, [arcgisQueryParams.returnCountOnly]: "true"});
      }
      if (pageNumber !== 1 && pageNumber !==undefined) {
        dataQueryParams = {...dataQueryParams, [arcgisQueryParams.resultOffset]: (pageNumber - 1) * pageSize}
      }
      const queryingParams = concatQueryingParameters(dataQueryParams);

    if (includeTotalCount) {
      //gets the data for a specific page with total count
      getArcgisQuery(selectedMapService, tableId, totalCountQueryParams).then(
        (res: any) => {
          axiosErrorHandler(res);
          if (res.data.error) {
            throw res.data.error;
          }
          const totalCount = res.data.count;
          dispatch(
            getSurveyExistingPlansData(
              queryingParams,
              tableId,
              filteredSortColumnMap,
              totalCount
            )
          );
        })
        .catch(() => {
          dispatch({ type: SURVEY_PLANS_ERROR });
        });
    } else {
      //gets the data for a specific page without total count
      dispatch(
        getSurveyExistingPlansData(
          queryingParams,
          tableId,
          filteredSortColumnMap,
          totalCount,
        )
      );
    }
  };

  export const getSurveyExistingPlansData =
    (
      queryParams: any,
      tableId: number,
      filteredSortColumnMap?: any,
      totalCount?: any
    ) =>
    async (dispatch: any) => {
      const selectedMapService =
        store.getState().LoginReducer.selectedMapService.decodedLabel;

      getArcgisQuery(selectedMapService, tableId, queryParams)
        .then((res: any) => {
          axiosErrorHandler(res);
          if (res.data.error) {
            throw res.data.error;
          }
          res = mapQueryResults(res);
          let existingPlans: object = { data: res.data.features };
          //adding the totalCount value to the object for the first page load
          existingPlans = !isNil(totalCount)
            ? { ...existingPlans, totalCount }
            : existingPlans;
          //adding the filters to the object if filters exists
          existingPlans = isObjectNotEmpty(filteredSortColumnMap)
            ? {
                ...existingPlans,
                existingSurveyPlansFilters: { ...filteredSortColumnMap },
              }
            : { ...existingPlans, existingSurveyPlansFilters: {} };

          dispatch({
            type: SURVEY_PLANS_SUCCESS,
            surveyPlans: existingPlans,
          });
        })
        .catch(() => {
          dispatch({ type: SURVEY_PLANS_ERROR });
        });
    };

/**
 * Summary: get the surveyPlan using surveyPlanID.
 *
 * @param surveyPlanID the ID to get the corresponding surveyPlan
 *
 */

export const getSurveyPlan =
  (surveyplanID: string) => async (dispatch: any) => {
    dispatch({ type: SURVEY_PLAN_LOADING });
    let surveyPlanTableId = getTableIdByName(
      store.getState().SettingsReducer.globalAliases.LanternSurveyPlan
    );
    const selectedMapService =
      store.getState().LoginReducer.selectedMapService.decodedLabel;

    const queryingParams = {
      [arcgisQueryParams.where]: encodeUriAll(
        `SurveyPlanID = '{${surveyplanID.slice(1, 37)}}'`
      ),
      [arcgisQueryParams.outFields]: "*",
    };
    getSurveyPlanInfo(
      selectedMapService,
      surveyPlanTableId,
      concatQueryingParameters(queryingParams)
    )
      .then((res: any) => {
        res = mapQueryResults(res);
        dispatch({
          type: SURVEY_PLAN_SUCCESS,
          surveyPlan: res.data.features[0].attributes,
        });
      })
      .catch((e: any) => dispatch({ type: SURVEY_PLAN_ERROR }));
  };

// get a list of survey jobs based on selected survey plans
export const getSurveyJobs = (Id: string) => async (dispatch: any) => {
  dispatch({ type: SURVEY_JOBS_LOADING });

  // get map service id
  let surveyJobID = getTableIdByName(
    store.getState().SettingsReducer.globalAliases.LanternSurveyJob
  );

  const selectedMapService =
    store.getState().LoginReducer.selectedMapService.decodedLabel;

  const queryingParams = {
    [arcgisQueryParams.where]: encodeUriAll(`SurveyPlanID IN (${Id})`),
    [arcgisQueryParams.outFields]: "*",
    [arcgisQueryParams.orderByFields]: "PolygonName",
  };

  getSurveyJobInfo(
    selectedMapService,
    surveyJobID,
    concatQueryingParameters(queryingParams)
  )
    .then((res: any) => {
      axiosErrorHandler(res);
      res = mapQueryResults(res);
      fetchActiveJobSurveyors(res.data.features, dispatch);
    })
    .catch((err: any) => {
      dispatch({
        type: ERROR,
        error: {
          message: err,
          type: "fetchSurveyJobs",
        },
      });
      dispatch({ type: SURVEY_JOBS_ERROR });
    });
};

//Gets all details for a specific survey job
export const getSurveyJobReviewDetails =
  (Id: string) => async (dispatch: any) => {
    let surveyJobTableId = getTableIdByName(
      store.getState().SettingsReducer.globalAliases.LanternSurveyJob
    );
    const selectedMapService =
      store.getState().LoginReducer.selectedMapService.decodedLabel;

    dispatch({ type: SURVEY_JOB_DETAILS_LOADING });

    const queryingParams = {
      [arcgisQueryParams.where]: encodeUriAll(`SurveyJobID = '${Id}'`),
      [arcgisQueryParams.outFields]: "*",
      [arcgisQueryParams.orderByFields]: "PolygonName",
    };
    getSurveyJobInfo(
      selectedMapService,
      surveyJobTableId,
      concatQueryingParameters(queryingParams)
    )
      .then((res: any) => {
        res = mapQueryResults(res);
        let gridFeature = res.data.features[0].attributes;
        dispatch(getGridData(gridFeature.PolygonID, gridFeature.PolygonType));
        dispatch({
          type: SET_GRID_TYPE + surveyReviewMapType,
          gridType: gridFeature.PolygonType,
        });
        dispatch({
          type: SURVEY_JOB_DETAILS_SUCCESS,
          surveyReviewDetails: gridFeature,
        });
      })
      .catch((err: any) => {
        dispatch({
          type: ERROR,
          error: {
            message: err,
            type: "getSurveyJobReview",
          },
        });
        dispatch({ type: SURVEY_JOB_DETAILS_ERROR });
      });
  };

/**
 * summary: function to fetch surveyors currently assigned to an active job
 *
 * @param jobs list of jobs to find surveyors assigned to
 * @param dispatch dispatch action to update store values
 */
function fetchActiveJobSurveyors(
    jobs: Array<any>, 
    dispatch: any,
    filteredSortColumnMap?: any,
    forSurveyJobsDistinctFilteredRows:boolean = false,
    totalCount?: number
  ) {
  let jobsData: Array<any> = [];
  const workAssignmentsTableIndex = getTableIndexByName(
    store.getState().SettingsReducer.globalAliases.LanternWorkAssignments
  );

  const selectedMapServiceName =
    store.getState().LoginReducer.selectedMapService.label;

  jobs = jobs.map((job: any) => {
    return {attributes: mapAttribute(job.attributes)}
  });

  let surveyJobList: Array<string> = []
  jobs.forEach((job:any) => {
    if (job.attributes.Status === surveyJobStatus.active || job.attributes.Status === surveyJobStatus.inProgress) {
      surveyJobList = surveyJobList.concat(`'${job.attributes.SurveyJobID}'`)
    }
  })

  let requestBody = {
    where: `SurveyJobID IN (${surveyJobList.join(", ")})`,
    enforcelimit: "false",
    mapservicename: selectedMapServiceName,
    outfields: "UserID,ObjectId,SurveyJobID",
  };

  getActiveJobSurveyors(requestBody, workAssignmentsTableIndex).then((res) => {
    if (res?.data) {
      jobs.forEach((job: any) => {
        const assignedSurveyors = res.data.Rows.filter(
          (surveyor: any) =>
          job.attributes.SurveyJobID ===
            surveyor.Attributes.SURVEYJOBID
        );
        if (assignedSurveyors) {
          assignedSurveyors.forEach((surveyor: any, index: number) => {
            assignedSurveyors[index].Attributes = mapAttribute(
              assignedSurveyors[index].Attributes
            );
          });
          if (job?.attributes) {
            job.attributes.Surveyors = assignedSurveyors
          }
        }
        jobsData.push(job);
      });
    }
    else {
      jobsData = jobs
    }

    if (forSurveyJobsDistinctFilteredRows) {
      dispatch({
        type: SURVEY_REVIEW_SURVEY_JOBS_PRE_FILTER_VALUES_SUCCESS,
        surveyJobsPreFilterValues: jobsData,
        filteredSortColumnMap: filteredSortColumnMap
      });
    }
    else {
      dispatch({
        type: SURVEY_REVIEW_JOBS_SUCCESS,
        surveyJobs: { data: jobsData, totalCount: totalCount },
        filteredSortColumnMap: filteredSortColumnMap
      });
      dispatch({
        type: SET_GRID_TYPE + surveyReviewMapType,
        gridType: jobsData[0]?.attributes.PolygonType,
      });
    }
  });
}

/**
 * Summary: get selected grids geometry for survey review
 * @param polygonId polygon id
 * @param polygonType polygon type/grid layer
 * @returns selected grid geometry
 */
export const getGridData =
  (polygonId: string, polygonType: string) => async (dispatch: any) => {
    let MapServiceId = getLayerMapServiceIdByName(polygonType);
    const selectedMapService =
      store.getState().LoginReducer.selectedMapService.decodedLabel;
    const polygonIdFieldName =
      getGridIdFieldByName(polygonType) ??
      getGlobalIdFieldNameByLayerID(MapServiceId);

    const queryingParams = {
      [arcgisQueryParams.outFields]: "*",
      [arcgisQueryParams.returnGeometry]: "true",
      [arcgisQueryParams.outSR]: "102100",
      [arcgisQueryParams.where]: `${polygonIdFieldName}='${polygonId}'`,
      [arcgisQueryParams.returnDistinctValues]: "false",
    };
    getArcgisQuery(
      selectedMapService,
      MapServiceId,
      concatQueryingParameters(queryingParams)
    ).then((res: any) => {
      axiosErrorHandler(res);
      res = mapQueryResults(res);
      if (res?.data?.features?.length > 0) {
        const grid = massageSurveyReviewGridData(
          res.data,
          esriGeometryType.esriGeometryPolygon,
          true,
          true
        );
        dispatch({ type: SET_GEOMETRY + surveyReviewMapType, grids: grid });
        dispatch({ type: SET_SURVEY_REVIEW_GEOMETRY, grids: grid });
      }
    });
  };

/**
 * Summary: Get geometry coordinates for status toggles (ex: skips, manual, unsurveyed) for passed survey job id
 *
 * @param surveyJobID Id for surveyjob currently being viewed
 * @param isLeakSurvey two buffer types if true, otherwise one
 * @param displayName display name for toggle
 * @param whereClause SQL where clause filter for toggle
 * @param firstBufferType buffer type for first column
 * @param secondBufferType buffer type for second column if it exists
 */
export const getBufferGeoCords =
  (
    surveyJobID: string,
    displayName: string,
    whereClause: string,
    index: number,
    firstBufferType: string,
    secondBufferType?: string
  ) =>
  async (dispatch: any) => {
    dispatch({type: `${index}_${STATUS_TOGGLE_LOADING}`})
    const selectedMapService =
      store.getState().LoginReducer.selectedMapService.decodedLabel;
    const firstBufferLayerName =
      store.getState().SettingsReducer.globalAliases[firstBufferType];
    const firstLayerId = getLayerMapServiceIdByName(firstBufferLayerName);
    const secondBufferLayerName = secondBufferType
      ? store.getState().SettingsReducer.globalAliases[secondBufferType]
      : "";
    const secondLayerId = getLayerMapServiceIdByName(secondBufferLayerName);

    const queryingParams = {
      [arcgisQueryParams.where]:
        `SurveyJobID='${surveyJobID}'` +
        (whereClause ? `and ${encodeURIComponent(whereClause)}` : ""),
      [arcgisQueryParams.geometryType]: "esriGeometryEnvelope",
      [arcgisQueryParams.spatialRel]: "esriSpatialRelIntersects",
      [arcgisQueryParams.outFields]: "*",
      [arcgisQueryParams.returnGeometry]: "true",
    };
    let promises = [
      getArcgisQuery(
        selectedMapService,
        firstLayerId,
        concatQueryingParameters(queryingParams)
      ),
    ];
    if (secondBufferLayerName) {
      promises.push(
        getArcgisQuery(
          selectedMapService,
          secondLayerId,
          concatQueryingParameters(queryingParams)
        )
      );
    }

    axios.all(promises).then((res) => {
      const mappedRes = mapQueryResults(res[0]);
      let geoCords = [];
      if (mappedRes.data.features) {
        geoCords = massageGeoCordData(
          mappedRes.data,
          firstLayerId,
          firstBufferLayerName,
          esriGeometryType.esriGeometryPolygon,
          displayName,
          whereClause,
          RGBColors.cyan
        );
      }
      if (secondBufferType && res[1].data.features) {
        geoCords = geoCords.concat(
          massageGeoCordData(
            res[1].data,
            secondLayerId,
            secondBufferLayerName,
            esriGeometryType.esriGeometryPolygon,
            displayName,
            whereClause,
            RGBColors.cyan
          )
        );
      }

      if (geoCords.length > 0) {
        dispatch({
          type: SET_GEOMETRY + surveyReviewMapType,
          grids: [
            ...store.getState().SurveyReviewMapReducer.selectedGrids,
            ...geoCords,
          ],
          duplicationCheck: false,
        });
        dispatch({
          type: SET_SURVEY_REVIEW_GEOMETRY,
          grids: [
            ...store.getState().SurveyReviewMapReducer.surveyReviewGrids,
            ...geoCords,
          ],
        });
        dispatch({type: `${index}_${STATUS_TOGGLE_SUCCESS}`})
      }
    });
  };

/***
 * Summary: Get features from a layer for passed survey job id
 * @param surveyJobID Id for surveyjob currently being viewed
 * @param layerId id for layer to be queried from
 * @param displayName display name for toggle
 * @param whereClause SQL where clause filter for toggle
 */
export const getLayerFeatures =
  (
    surveyJobID: string,
    layerId: number,
    displayName: string,
    whereClause: string,
    index: number
  ) =>
  async (dispatch: any) => {
    dispatch({type: `${index}_${FEATURE_TOGGLE_LOADING}`})
    const layerName = store
      .getState()
      .SettingsReducer.settings.Layers.find(
        (layer: any) => layer.Id === layerId
      ).Name;
    const selectedMapService =
      store.getState().LoginReducer.selectedMapService.decodedLabel;

    const queryingParams = {
      [arcgisQueryParams.where]:
        `SurveyJobID='${surveyJobID}'` +
        (whereClause ? `and ${encodeURIComponent(whereClause)}` : ""),
      [arcgisQueryParams.geometryType]: "esriGeometryEnvelope",
      [arcgisQueryParams.spatialRel]: "esriSpatialRelIntersects",
      [arcgisQueryParams.outFields]: "*",
      [arcgisQueryParams.returnGeometry]: "true",
    };
    getArcgisQuery(
      selectedMapService,
      layerId,
      concatQueryingParameters(queryingParams)
    ).then((res: any) => {
      axiosErrorHandler(res);
      res = mapQueryResults(res);
      const geometryType: keyof typeof esriGeometryType = res.data.geometryType
      let geoCords = massageGeoCordData(
        res.data,
        layerId,
        layerName,
        esriGeometryType[geometryType],
        displayName,
        whereClause,
        RGBColors.cyan
      );

      switch (esriGeometryType[geometryType]) {
        case esriGeometryType.esriGeometryPolygon:
          dispatch({
            type: SET_GEOMETRY + surveyReviewMapType,
            grids: [
              ...store.getState().SurveyReviewMapReducer.selectedGrids,
              ...geoCords,
            ],
            duplicationCheck: false,
          });
          dispatch({
            type: SET_SURVEY_REVIEW_GEOMETRY,
            grids: [
              ...store.getState().SurveyReviewMapReducer.surveyReviewGrids,
              ...geoCords,
            ],
          });
          break;
  
        case esriGeometryType.esriGeometryPolyline:
          dispatch({
            type: SET_GEOMETRY + surveyReviewMapType,
            lines: [
              ...store.getState().SurveyReviewMapReducer.selectedLines,
              ...geoCords,
            ],
            duplicationCheck: false,
          });
          dispatch({
            type: SET_SURVEY_REVIEW_GEOMETRY,
            lines: [
              ...store.getState().SurveyReviewMapReducer.surveyReviewLines,
              ...geoCords,
            ],
          });
          break;
  
        case esriGeometryType.esriGeometryPoint:
          dispatch({
            type: SET_GEOMETRY + surveyReviewMapType,
            points: [
              ...store.getState().SurveyReviewMapReducer.selectedPoints,
              ...geoCords,
            ],
            duplicationCheck: false,
          });
          dispatch({
            type: SET_SURVEY_REVIEW_GEOMETRY,
            points: [
              ...store.getState().SurveyReviewMapReducer.surveyReviewPoints,
              ...geoCords,
            ],
          });
          break;
      }
      dispatch({type: `${index}_${FEATURE_TOGGLE_SUCCESS}`})
    });
  };

/***
 * Summary: Get features from a table for passed survey job id
 * @param surveyJobID Id for surveyjob currently being viewed
 * @param tableId id for table to be queried from
 * @param displayName display name for toggle
 * @param whereClause SQL where clause filter for toggle
 */
export const getTableFeatures =
  (
    surveyJobID: string,
    tableId: number,
    displayName: string,
    whereClause: string,
    index: number
  ) =>
  async (dispatch: any) => {
    dispatch({type: `${index}_${FEATURE_TOGGLE_LOADING}`})
    const selectedMapService =
      store.getState().LoginReducer.selectedMapService.decodedLabel;
    const queryingParams = {
      [arcgisQueryParams.where]:
        `SurveyJobID='${surveyJobID}'` +
        (whereClause ? `and ${encodeURIComponent(whereClause)}` : ""),
      [arcgisQueryParams.geometryType]: `esriGeometryEnvelope`,
      [arcgisQueryParams.spatialRel]: `esriSpatialRelIntersects`,
      [arcgisQueryParams.outFields]: `*`,
      [arcgisQueryParams.returnGeometry]: `true`,
    };
    getArcgisQuery(
      selectedMapService,
      tableId,
      concatQueryingParameters(queryingParams)
    ).then((res: any) => {
      const layers = store.getState().SettingsReducer.settings.Layers;
      const tables = store.getState().SettingsReducer.settings.Tables;
      const storedTables =
        store.getState().SurveyReviewMapReducer.surveyReviewTables;
      axiosErrorHandler(res);
      res = mapQueryResults(res);
      res.data.tableId = tableId;
      res.data.features = massageSurveyReviewTableData(
        res.data,
        displayName,
        whereClause
      );
      dispatch(
        storeInfoTables(
          res.data,
          tables,
          layers,
          surveyReviewMapType,
          null,
          null,
          false
        )
      );
      dispatch({
        type: SET_SURVEY_REVIEW_TABLES,
        tables: storedTables.concat(res.data),
      });
      dispatch({type: `${index}_${FEATURE_TOGGLE_SUCCESS}`})
    });
  };

/**
 * Summary: removes all geo cords related to one toggle layer
 * (checks against a toggle's display name and where clause)
 * @param displayName display name for toggle
 * @param whereClause whereClause for toggle
 * @param geometryType layer geometry type
 */
export const removeLayerGeoCords =
  (displayName: string, whereClause: string, geometryType: esriGeometryType) =>
  async (dispatch: any) => {
    let { selectedGrids, selectedLines, selectedPoints } =
      store.getState().SurveyReviewMapReducer;
    let { surveyReviewGrids, surveyReviewLines, surveyReviewPoints } =
      store.getState().SurveyReviewMapReducer;
    let newSelectedGrids: Array<any> = [];
    let newSelectedLines: Array<any> = [];
    let newSelectedPoints: Array<any> = [];
    let newSurveyReviewGrids: Array<any> = [];
    let newSurveyReviewLines: Array<any> = [];
    let newSurveyReviewPoints: Array<any> = [];

    switch (geometryType) {
      case esriGeometryType.esriGeometryPolygon:
        newSelectedGrids = filterByToggleInfo(
          selectedGrids,
          displayName,
          whereClause
        );
        newSurveyReviewGrids = filterByToggleInfo(
          surveyReviewGrids,
          displayName,
          whereClause
        );
        newSelectedLines = selectedLines
        newSelectedPoints = selectedPoints
        newSurveyReviewLines = surveyReviewLines
        newSurveyReviewPoints = surveyReviewPoints
        break;

      case esriGeometryType.esriGeometryPolyline:
        newSelectedLines = filterByToggleInfo(
          selectedLines,
          displayName,
          whereClause
        );
        newSurveyReviewLines = filterByToggleInfo(
          surveyReviewLines,
          displayName,
          whereClause
        );
        newSelectedGrids = selectedGrids
        newSelectedPoints = selectedPoints
        newSurveyReviewGrids = surveyReviewGrids
        newSurveyReviewPoints = surveyReviewPoints
        break;

      case esriGeometryType.esriGeometryPoint:
        newSelectedPoints = filterByToggleInfo(
          selectedPoints,
          displayName,
          whereClause
        );
        newSurveyReviewPoints = filterByToggleInfo(
          surveyReviewPoints,
          displayName,
          whereClause
        );
        newSelectedGrids = selectedGrids
        newSelectedLines = selectedLines
        newSurveyReviewGrids = surveyReviewGrids
        newSurveyReviewLines = surveyReviewLines
        break;
    }

    dispatch({
      type: SET_GEOMETRY + surveyReviewMapType,
      grids: newSelectedGrids,
      lines: newSelectedLines,
      points: newSelectedPoints,
      duplicationCheck: false,
    });
    dispatch({
      type: SET_SURVEY_REVIEW_GEOMETRY,
      grids: newSurveyReviewGrids,
      lines: newSurveyReviewLines,
      points: newSurveyReviewPoints,
    });
  };

/**
 * Summary: removes all tables related to one toggle layer
 * (checks against a toggle's display name and where clause)
 * @param displayName display name for toggle
 * @param whereClause whereClause for toggle
 */
export const removeTableFeatures =
  (displayName: string, whereClause: string) => async (dispatch: any) => {
    const layers = store.getState().SettingsReducer.settings.Layers;
    const tables = store.getState().SettingsReducer.settings.Tables;
    let storedTables =
      store.getState().SurveyReviewMapReducer.surveyReviewTables;
    let newStoredTables: Array<any> = [];
    storedTables.forEach((table: any) => {
      let newStoredFeatures: Array<any> = [];
      newStoredFeatures = filterByToggleInfo(
        table.features,
        displayName,
        whereClause
      );
      if (newStoredFeatures.length > 0) {
        table.features = newStoredFeatures;
        newStoredTables.push(table);
        newStoredFeatures = [];
      }
    });
    dispatch(
      storeInfoTables(newStoredTables, tables, layers, surveyReviewMapType)
    );
    dispatch({
      type: SET_SURVEY_REVIEW_TABLES,
      tables: newStoredTables,
    });
  };

/**
 * Summary: Filter features from oldFeatures array into newFeatures array through the toggle's info
 * @param oldFeatures original array of features
 * @param newFeatures features filtered to remove those that match the toggle's info
 */
const filterByToggleInfo = (
  oldFeatures: Array<any>,
  displayName: string,
  whereClause: string
) => {
  const newFeatures: Array<any> = []
  oldFeatures.forEach((oldFeature: any) => {
    if (
      oldFeature.toggleInfo?.displayName !== displayName ||
      oldFeature.toggleInfo?.whereClause !== whereClause
    ) {
      newFeatures.push(oldFeature);
    }
  });
  return newFeatures
};

/**
 * Summary: Get the count(s) from a toggle row
 * @param surveyJobID Id for surveyjob currently being viewed
 * @param whereClause SQL where clause filter for toggle
 * @param toggleIndex toggle's index or sort order
 * @param isLeakSurvey two counts/columns if true, otherwise one
 * @param layerId layer id for first count/column
 * @param secondLayerId layer id for second count/column if one exists
 * @returns
 */

export const getStatusToggleCount =
  (surveyJobID: string) => async (dispatch: any) => {
    dispatch({
      type: STATUS_COUNT_LOADING,
    });
    const selectedMapService =
      store.getState().LoginReducer.selectedMapService.decodedLabel;
    const globalAliases = store.getState().SettingsReducer.globalAliases;
    const statusCountConfiguration =
      store.getState().SurveyReviewReducer.statusCountConfiguration;
    const { Statuses, FirstBufferType, SecondBufferType } =
      statusCountConfiguration;
    const firstBufferLayerName = globalAliases[FirstBufferType];
    const secondBufferLayerName = globalAliases[SecondBufferType] || "";
    const layerId = getLayerMapServiceIdByName(firstBufferLayerName);
    const secondLayerId = getLayerMapServiceIdByName(secondBufferLayerName);
    let promises: Promise<[]>[] = [];
    Statuses?.forEach((status: any) => {
      const { WhereClause } = status;
      const queryingParams = {
        [arcgisQueryParams.where]:
          `SurveyJobID='${surveyJobID}'` +
          (WhereClause ? `and ${encodeURIComponent(WhereClause)}` : ""),
        [arcgisQueryParams.returnCountOnly]: "true",
      };
      promises.push(getArcgisQuery(
        selectedMapService,
        layerId,
        concatQueryingParameters(queryingParams)
      ));
      promises.push(getArcgisQuery(
        selectedMapService,
        secondLayerId,
        concatQueryingParameters(queryingParams)
      ));
    });
    let countIndex = 0
    axios.all(promises).then((res: any) => {
      console.log(res);
      let results: Array<Object> = [];
      Statuses.forEach((status: any, i: number) => {
        const { DisplayName, WhereClause } = status;
        results[i] = {
          displayName: DisplayName,
          counts: [
            res[countIndex].data?.count,
            res[countIndex + 1].data?.count,
          ],
          whereClause: WhereClause,
        };
        countIndex += 2
      });
      console.log(results)
      dispatch({
        type: STATUS_COUNT_SUCCESS,
        results,
      });
    });
  };

export const getFeatureToggleCount =
  (surveyJobID: string) => async (dispatch: any) => {
    dispatch({
      type: FEATURE_COUNT_LOADING,
    });
    const selectedMapService =
      store.getState().LoginReducer.selectedMapService.decodedLabel;
    const featureCountConfiguration =
      store.getState().SurveyReviewReducer.featureCountConfiguration;
    let promises: Promise<[]>[] = [];

    featureCountConfiguration.forEach((config: any) => {
      const { WhereClause, LayerId } = config;
      const queryingParams = {
        [arcgisQueryParams.where]:
          `SurveyJobID='${surveyJobID}'` +
          (WhereClause ? `and ${encodeURIComponent(WhereClause)}` : ""),
        [arcgisQueryParams.returnCountOnly]: "true",
      };
      promises.push(
        getArcgisQuery(
          selectedMapService,
          LayerId,
          concatQueryingParameters(queryingParams)
        )
      );
    });
    axios.all(promises).then((res: any) => {
      let results: Array<Object> = [];
      featureCountConfiguration.forEach((config: any, i: number) => {
        const { DisplayName, LayerId, WhereClause } = config;
        results[i] = {
          displayName: DisplayName,
          counts: [res[i].data?.count, undefined],
          layerID: LayerId,
          islayer: LayerId <= getAllLayerIds().length ? true : false,
          whereClause: WhereClause,
        };
      });
      dispatch({
        type: FEATURE_COUNT_SUCCESS,
        results,
      });
    });
  };

/**
 * summary: posts survey review accept
 *
 * @ObjectID Object ID for survey job
 * @surveyplanID ID to return to existing work grids table
 * @polygonName name of workgrid used for notification message
 */
export const postSurveyAccept =
  (
    objectID: number,
    surveyPlanId: string,
    polygonName: string,
    surveyWorkType: string
  ) =>
  async (dispatch: any) => {
    let userID = store.getState().LoginReducer.loggedInUser;
    const currentDate = getCurrentDate(true, "iso");
    const surveyJobTableID = getTableIndexByName(
      store.getState().SettingsReducer.globalAliases.LanternSurveyJob
    );

    // Todo: Remove this code after testing
    // const url = `${urlConfig.reactLanternServerUrl}/Editing.ashx/tables/commit`;
    const selectedMapServiceName =
      store.getState().LoginReducer.selectedMapService.label;

    let requestBody = {
      mapservicename: selectedMapServiceName,
      token: "",
      editcontainer: `
    {
      "modifyEditItems":
      [{
        "attributes":
        {
          "STATUS": "Historical",
          "OBJECTID": ${objectID},
          "ModifiedOn": "${currentDate}",
          "ModifiedBy": "${userID}",
        },
        "tableId": ${surveyJobTableID},
        "type": 3,
        "objectId": ${objectID},
        "oldAttributes": {},
        "hasBeenUndone": false
      }],
      "createEditItems":[],
      "deleteEditItems":[]
    }`,
    };

    updateAssignedSurveyors(requestBody).then(() => {
      dispatch({
        type: SURVEY_APPROVAL_SUCCESS,
      });
      dispatch({
        type: SET_STATUS_MESSAGE,
        polygonName: polygonName,
        message: "Survey Review Accepted",
        status: notificationType.success,
      });
      history.push(`/SurveyReview/SurveyPlanDetails?id=${surveyPlanId}`, {
        surveyWorkType: surveyWorkType,
      });
    });
  };

/**
 * summary: posts survey review reject
 *
 * @ObjectID Object ID for survey job
 * @surveyplanID ID to return to existing work grids table
 * @polygonName name of workgrid used for notification message
 * @comments Used for survey rejections only
 */
export const postSurveyReject =
  (
    objectID: number,
    surveyPlanId: string,
    polygonName: string,
    comment: string,
    surveyWorkType: string
  ) =>
  async (dispatch: any) => {
    const currentDate = getCurrentDate(true, "iso");
    let userID = store.getState().LoginReducer.loggedInUser;
    const surveyJobTableID = getTableIndexByName(
      store.getState().SettingsReducer.globalAliases.LanternSurveyJob
    );
    const selectedMapServiceName =
      store.getState().LoginReducer.selectedMapService.label;

    // Todo: Remove this code after testing
    // const url = `${urlConfig.reactLanternServerUrl}/Editing.ashx/tables/commit`;
    // const config = {
    //   headers: { "content-type": "application/x-www-form-urlencoded" },
    // };

    //Fix mapservicename
    let requestBody = {
      mapservicename: selectedMapServiceName,
      editcontainer: `
    {
      "modifyEditItems":
      [{
        "attributes":
        {
          "STATUS": "In Progress",
          "OBJECTID": ${objectID},
          "Comments ": "${comment}",
          "ModifiedOn": "${currentDate}",
          "ModifiedBy": "${userID}"
        },
        "tableId": ${surveyJobTableID},
        "type": 3,
        "objectId": ${objectID},
        "oldAttributes": {},
        "hasBeenUndone": false
      }],
      "createEditItems": [],
      "deleteEditItems": []
    }`,
    };

    updateAssignedSurveyors(
      requestBody,
      "application/x-www-form-urlencoded"
    ).then(() => {
      dispatch({
        type: SURVEY_REJECT_SUCCESS,
      });
      dispatch({
        type: SET_STATUS_MESSAGE,
        polygonName: polygonName,
        message: `Survey Job ${polygonName} rejected`,
        status: notificationType.failure,
      });
      history.push(`/SurveyReview/SurveyPlanDetails?id=${surveyPlanId}`, {
        surveyWorkType: surveyWorkType,
      });
    });
  };

/**
 * Get configurations for survey review table and toggles based off survey work type
 * @param surveyWorkType survey work type from a survey plan or job
 * @returns
 */
export const getSurveyReviewConfiguration =
  (surveyWorkType: string) => async (dispatch: any) => {
    dispatch({
      type: SURVEY_REVIEW_CONFIG_LOADING,
    });
    const selectedMapServiceName =
      store.getState().LoginReducer.selectedMapService.label;
    axios
      .all([
        getStatusCountConfig(selectedMapServiceName, surveyWorkType),
        getFeatureCountConfig(selectedMapServiceName, surveyWorkType),
      ])
      .then(
        axios.spread((statusCountConfiguration, featureCountConfiguration) => {
          dispatch({
            type: SURVEY_REVIEW_CONFIG_SUCCESS,
            statusCountConfiguration: statusCountConfiguration.data,
            featureCountConfiguration: featureCountConfiguration.data,
          });
        })
      )
      .catch(() => {
        dispatch({
          type: SURVEY_REVIEW_CONFIG_ERROR,
        });
      });
  };

/**
 * summary: get the configuraton data for the ExistingSurveyPlans table
 *
 * @param surveyWorkType the survey work type to get the config data for
 */
export const getExistingPlanTableConfigData =
  (surveyWorkType: string) => async (dispatch: any) => {
    const tableName = "Survey Review Plans";
    const actionType = PLANS_TABLE_CONFIG;
    dispatch(getTableConfigData(tableName, actionType, surveyWorkType));
  };

/**
 * summary: get the configuraton data for the existing jobs table in SurveyPlanDetails
 */
export const getExistingJobTableConfigData =
  (surveyWorkType: string) => async (dispatch: any) => {
    const tableName = "Survey Review Grids";
    const actionType = JOBS_TABLE_CONFIG;
    dispatch(getTableConfigData(tableName, actionType, surveyWorkType));
  };

const initialState: any = {
  surveyPlans: [],
  surveyPlan: {},
  surveyJobs: [],
  error: {},
  notificationStatus: "",
  statusCountConfiguration: {},
  featureCountConfiguration: [],
  surveyReviewCounts: {},
  surveyWorkType: "",
  gridFilterValue: blankLabel,
  filteredSurveyPlanIDs: [],
  existingPlanTableConfigData: [],
  existingPlanTableColumns: [],
  existingJobTableConfigData: [],
  surveyJobTotalCount: 0,
  surveyJobsPreFilterValues: [],
  surveyReviewDetails: [],
  surveyJobsUserSelections: []
};

export default function reducer(state = initialState, action: any) {
  switch (action.type) {
    case SURVEY_REVIEW_JOBS_SUCCESS:
      let massagedJobs: Array<any> = [];
      let surveyJobsData: Array<any> = action.surveyJobs.data;
      let totalCount: number = action.surveyJobs.totalCount;
      if (!isUndefined(action.filteredSortColumnMap) && Object.keys(action.filteredSortColumnMap)?.includes('Surveyors') && action.filteredSortColumnMap.Surveyors?.filter?.length) {
        surveyJobsData = action.surveyJobs.data.filter((feature:any) => {
          return Array.isArray(feature.attributes.Surveyors) && 
          feature.attributes.Surveyors.some((surveyor:any) => {
            return action.filteredSortColumnMap.Surveyors.filter.includes(surveyor.Attributes.UserID);
          })
        })
        totalCount = action.surveyJobs.data.length
      }
      surveyJobsData.forEach((job: any) => {
        // assign the appropriate values based on the status of the job
        let newJob = { ...job.attributes };
        if (job.attributes.Status === "Planned") {
          newJob["numberOfMains"] = job.attributes.ForecastMetersMain;
          newJob["numberOfServices"] = job.attributes.ForecastNoAssets;
          newJob["unsurveyedBuffers"] = job.attributes.ForecastMetersMain;
          newJob["unsurveyedServices"] = job.attributes.ForecastNoAssets;
        } else {
          newJob["numberOfMains"] = job.attributes.SurveyBufferCount;
          newJob["numberOfServices"] = job.attributes.ServiceCount;
          newJob["unsurveyedBuffers"] = job.attributes.UncompletedSurveyBuffers;
          newJob["unsurveyedServices"] = job.attributes.UncompletedServices;
        }
        massagedJobs.push(newJob);
      });

      return {
        ...state,
        surveyJobs: massagedJobs,
        surveyJobTotalCount: totalCount
      };

      case SURVEY_REVIEW_SURVEY_JOBS_PRE_FILTER_VALUES_SUCCESS:
        let preFilterValues: Array<any> = action.surveyJobsPreFilterValues
        if (!isUndefined(action.filteredSortColumnMap) && Object.keys(action.filteredSortColumnMap)?.includes('Surveyors') && action.filteredSortColumnMap.Surveyors?.filter?.length) {
          preFilterValues = preFilterValues.filter((feature:any) => {
            return Array.isArray(feature.attributes.Surveyors) && 
            feature.attributes.Surveyors.some((surveyor:any) => {
              return action.filteredSortColumnMap.Surveyors.filter.includes(surveyor.Attributes.UserID);
            })
          })
        }
        return {
          ...state,
          surveyJobsPreFilterValues: preFilterValues
        };

    case SURVEY_REVIEW_SURVEY_JOBS_ALL_PRE_FILTER_VALUES_SUCCESS:
      return {
        ...state,
        surveyJobsAllPreFilterValues: action.surveyJobsAllPreFilterValues
      };

    case JOBS_TABLE_CONFIG_SUCCESS:
      return {
        ...state,
        existingJobTableConfigData: action.tableConfig,
      };

    case SURVEY_PLANS_SUCCESS:
      let massagedSurveyPlans = action.surveyPlans.data.map((feature: any) => {
        return {
          ...feature.attributes,
        };
      });
      const { surveyPlanFilters: actionFilters } = action;
      const surveyPlanFilters = {
        ...state.surveyPlanFilters,
        ...actionFilters,
      };
      return {
        ...state,
        surveyPlans: joinSurveyJobAndPlan(massagedSurveyPlans),
        totalCount: !isNil(action.surveyPlans.totalCount) ? action.surveyPlans.totalCount : state.totalCount,
        surveyPlanFilters,
      };
    case SURVEY_PLAN_FILTERS_SUCCESS:
      return {
        ...state,
        surveyPlansFilterValues: action.surveyPlansPreFilterValues,
      };

    case SURVEY_PLAN_ALL_FILTERS_SUCCESS:
      return {
        ...state,
        surveyPlansAllFilterValues: action.surveyPlansPreFilterValues,
      };
    case SURVEY_PLAN_SUCCESS:
      return {
        ...state,
        surveyPlan: action.surveyPlan,
      };

    case PLANS_TABLE_CONFIG_SUCCESS:
      return {
        ...state,
        existingPlanTableConfigData: action.tableConfig,
        existingPlanTableColumns: map(action.tableConfig, (col) => col.accessor).filter(col => col !== '')
      };

    case SURVEY_REVIEW_CONFIG_SUCCESS:
      let statusCountConfiguration: any = {};
      let featureCountConfiguration = action.featureCountConfiguration
        ? action.featureCountConfiguration
        : [];
      if (
        action.statusCountConfiguration &&
        action.statusCountConfiguration.length > 0
      ) {
        statusCountConfiguration =
          action.statusCountConfiguration[0].SurveyWorkType;
        statusCountConfiguration.Statuses = [];

        action.statusCountConfiguration.forEach((config: any) => {
          statusCountConfiguration.Statuses.push({
            DisplayName: config.DisplayName,
            WhereClause: config.WhereClause,
            SortOrder: config.SortOrder,
          });
        });

        statusCountConfiguration.Statuses.sort((a: any, b: any) =>
          a.SortOrder > b.SortOrder ? 1 : -1
        );
      }

      featureCountConfiguration.sort((a: any, b: any) =>
        a.SortOrder > b.SortOrder ? 1 : -1
      );

      return {
        ...state,
        statusCountConfiguration: statusCountConfiguration,
        featureCountConfiguration: featureCountConfiguration,
      };

    case FEATURE_COUNT_SUCCESS:
      return {
        ...state,
        surveyFeatureCounts: action.results,
      };

    case STATUS_COUNT_SUCCESS:
      return {
        ...state,
        surveyStatusCounts: action.results,
      };

    case SET_SURVEY_REVIEW_ON_MOUNT_STATUS_OPTIONS:
      return {
        ...state,
        onMountStatusOptions: action.onMountStatusOptions,
      };

    case SET_SURVEY_REVIEW_USER_SELECTIONS:
      return {
        ...state,
        surveyJobsUserSelections: action.surveyJobsUserSelections
      }
      
    case CLEAR_ERROR:
      return {
        ...state,
        error: "",
      };

    case SURVEY_JOB_DETAILS_SUCCESS:
      return {
        ...state,
        surveyReviewDetails: action.surveyReviewDetails,
      };

    case SET_STATUS_MESSAGE:
      return {
        ...state,
        notificationStatus: {
          message: action.message,
          status: action.status,
          polygonName: action.polygonName,
        },
      };

    case CLEAR_STATUS_MESSAGE:
      return {
        ...state,
        notificationStatus: {},
      };

    case CLEAR_GRID_FILTER:
      return {
        ...state,
        gridFilterValue: blankLabel,
      };

    case ERROR:
      return {
        ...state,
        error: action.error,
      };

    default:
      return state;
  }
}
