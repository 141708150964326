import React from 'react';
import './Toggle.scss';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

interface IToggleProp {
  handleChange?: (e : any)=>void
  size?: "small"
  labelPosition?: "top" | "start" | "bottom" | "end"
  label?: string
  checked?: boolean
  className?: string
}

const Toggle: React.FunctionComponent<IToggleProp> = (props) =>  {
  return (
    <FormControlLabel
      className = {props.className}
      value="top"
      control={<Switch size={props.size} color='primary' onChange={props.handleChange}/>}
      label={props.label}
      labelPlacement={props.labelPosition}
      checked={props.checked}
    />
  )
}

export default Toggle;
