
export enum arcgisQueryParams {
    where = 'where',
    objectIds = 'objectIds',
    geometry = 'geometry',
    geometryType = 'geometryType',
    inSR = 'inSR',
    spatialRel = 'spatialRel',
    relationParam = 'relationParam',
    time = 'time',
    distance = 'distance',
    units = 'units',
    outFields = 'outFields',
    returnGeometry = 'returnGeometry',
    maxAllowableOffset = 'maxAllowableOffset',
    geometryPrecision = 'geometryPrecision',
    outSR = 'outSR',
    havingClause = 'havingClause',
    gdbVersion = 'gdbVersion',
    returnDistinctValues = 'returnDistinctValues',
    returnIdsOnly = 'returnIdsOnly',
    returnCountOnly = 'returnCountOnly',
    returnExtentOnly = 'returnExtentOnly',
    orderByFields = 'orderByFields',
    groupByFieldsForStatistics = 'groupByFieldsForStatistics',
    outStatistics = 'outStatistics',
    returnZ = 'returnZ',
    returnM = 'returnM',
    multipatchOption = 'multipatchOption',
    resultOffset = 'resultOffset',
    resultRecordCount = 'resultRecordCount',
    quantizationParameters = 'quantizationParameters',
    returnCentroid = 'returnCentroid',
    resultType = 'resultType',
    historicMoment = 'historicMoment',
    returnTrueCurves = 'returnTrueCurves',
    sqlFormat = 'sqlFormat',
    returnExceededLimitFeatures = 'returnExceededLimitFeatures'
  }

/**
 * Summary: return a concatenated string of all querying parameters for an arcgis query
 * @param queryingParams - Object with each querying parameter and its value as a key-value pair.
 * @param queryExtension - string we want to add on to.
 */
export const concatQueryingParameters = (
  queryingParams: Object,
  queryExtension: string = ''
) => {
  for (const [key, value] of Object.entries(queryingParams)) {
    queryExtension += (queryExtension === "" ? "" : `&`) + `${key}=${value}`;
  }
  return queryExtension;
};