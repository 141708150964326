import { HttpRequest } from "../httpRequest";
import { NEW_API_URL } from "api/constants";
import { encodeUriAll } from "utils/UrlUtils";
import { formDataFormat } from "api/formDataUtils";

export const getMapConfiguration = async(selectedMapServiceName: string) => {
    const getMapConfigurationUrl = (`/v1/maps/configuration/${selectedMapServiceName}`);
    return await HttpRequest.get(NEW_API_URL, getMapConfigurationUrl, undefined, undefined, true);
};

export const getMapFeatureServerConfiguration = async(selectedMapServiceName: string) => {
    const getFeatureServerConfigurationUrl = (`/v1/maps/featureserverconfiguration/${selectedMapServiceName}`);
    return await HttpRequest.get(NEW_API_URL, getFeatureServerConfigurationUrl, undefined, undefined, true);
};


export const getMapExternalLayers = async(mapServiceLabel: string) => {
    const getMapExternalLayersUrl = (`/v1/maps/${mapServiceLabel}/externallayers`);
    return await HttpRequest.get(NEW_API_URL, getMapExternalLayersUrl, undefined, undefined, true);
};

export const getLayerEditRules = async(selectedMapServiceName: string, userId: number, selectedUserRole: any, requestBody: object) => {
    const getLayerEditRulesUrl = (`/v1/maps/${selectedMapServiceName}/layereditrules/${userId}/${selectedUserRole}`);
    return await HttpRequest.post(NEW_API_URL, getLayerEditRulesUrl, requestBody, undefined);
};
