import { useEffect } from 'react';
import {loadModules} from 'esri-loader';
import {reRegisterToken } from 'utils/IdentityManagerUtils';

interface IFeatureLayerProps {
  featureLayerProperties : any
  displayFieldName: string
  map?:any
  view?:any
}
/*Custom component*/
const FeatureLayer = (props:IFeatureLayerProps) => {
  useEffect(()=>{
    //Loading the feature layer from the server
    reRegisterToken()
    loadModules(['esri/layers/FeatureLayer']).then(([ FeatureLayer ]) => {
      const SurveyAreaFeatureLayers = new FeatureLayer({
        //using the parameter provided from parent component
        url: props.featureLayerProperties.url,
        labelingInfo: [{
          symbol: {
            type: "text",
            size: 10,
            font: {
              family: "Arial Unicode MS",
              style: "normal",
              weight: "normal"
            }
          },
          labelExpressionInfo: {
            expression: `$feature.${props.displayFieldName}`
          }
        }]
      });
      props.map.add(SurveyAreaFeatureLayers)
    }).catch((err) => console.error(err));

  },[props.featureLayerProperties.url, props.map, props.displayFieldName]
  )
  return null;
}

export default FeatureLayer
