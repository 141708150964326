import { store } from 'app/store';
import { esriGeometryType } from 'common/Map/SelectFeatures';
import { loadModules } from "esri-loader";
import {  reRegisterToken } from './IdentityManagerUtils';

/**
 * Summary: massage a feature layer query call to fit how it is expected to look like
 * @param data feature layer query call response data
 * @param layerId id of feature
 * @param featureLayerName name of the feature layer
 * @returns array of feature layer objects
 */
export const massageQueryData = (
  data: any,
  layerId: number,
  featureLayerName: string
) => {
  return data.features.map((feature: any) => {
    return {
      featureLayerName: featureLayerName,
      attributes: feature.attributes,
      geometry: {
        spatialReference: data.spatialReference,
        type: esriGeometryType[data.geometryType],
        ...feature.geometry
      },
      sourceLayer: {
        layerId: layerId
      }
    }
  })
}

/**
 * Summary: massage geometry coordinate data and add properties to its geometry and attributes
 * @param data feature layer query call response data
 * @param layerId id of feature
 * @param featureLayerName name of the feature layer
 * @param geometryType geometry type
 * @param displayName display name for toggle row
 * @param whereClause where clause for toggle row
 * @param layerOutlineColor layer outline color for graphics
 */
export const massageGeoCordData = (
  data: any,
  layerId: number,
  featureLayerName: string,
  geometryType: esriGeometryType,
  displayName: string,
  whereClause: string,
  layerOutlineColor?: Array<number>
) => {
  return data.features.map((feature: any) => {
    return {
      featureLayerName: featureLayerName,
      attributes: {
        ...feature.attributes,
        layerOutlineColor: layerOutlineColor
      },
      geometry: {
        ...feature.geometry,
        spatialReference: data.spatialReference,
        type: geometryType
      },
      toggleInfo: {
        displayName: displayName,
        whereClause: whereClause
      },
      sourceLayer: {
        layerId: layerId
      },
      reviewGrid: true
    }
  })
}

 /** Summary: massage and return data for a grid
 * @param data grid data
 * @param geometryType geometry type
 * @param oldObjectId old grid object id
 * @returns massaged grid data
 */
  export const massageGridData = (data:any, geometryType:esriGeometryType, oldObjectId:number) => {
    let gridData = data.features[0]
    gridData.geometry["spatialReference"] = data.spatialReference
    gridData.geometry["type"] = geometryType
    gridData.oldObjectID = oldObjectId
    return gridData
  }

/**
 * Summary: massage and return data for survey review grids
 * @param data grid data
 * @param geometryType geometry type
 * @param reviewGrid whether the grid is preset
 * @param defaultGrid whether grid is the default grid
 * @returns massaged survey review grid data (array format)
 */
export const massageSurveyReviewGridData = (
  data:any,
  geometryType:esriGeometryType,
  reviewGrid:boolean,
  defaultGrid:boolean
) => {
  let gridData = data.features
  gridData[0].geometry["spatialReference"] = data.spatialReference
  gridData[0].geometry["type"] = geometryType
  gridData[0].reviewGrid = reviewGrid
  gridData[0].defaultGrid = defaultGrid
  return gridData
}

/**
 * massage and return data for survey review table toggles (giving it toggle info
 * so features can be removed when toggle is disabled)
 * @param data table data
 * @param displayName display name for toggle row
 * @param whereClause where clause for toggle row
 * @returns table data with toggle info
 */
export const massageSurveyReviewTableData = (data:any, displayName: string, whereClause: string,) => {
  return data.features.map((feature: any) => {
    return {
      ...feature,
      toggleInfo: {
        displayName: displayName,
        whereClause: whereClause
      }
    }
  })
}

/**
 * Summary: parse the response data and fill allSurveyors with all the surveyor info
 * @param response response from call that fetches all roles
 * @param allSurveyors array to fill with the surveyor info objects
 */
export const fillAllSurveyorInfo = (
  response: any,
  allSurveyors: Array<Object>
) => {
  let roleName = response.data.LsmsRoles[0].SurveyorRole
  let roleData = store.getState().SettingsReducer.securityRoles
  roleData.Roles.forEach((role:any) => {
    if (role.RoleName === roleName) {
      role.UserIds.forEach((id:number) => {
        roleData.Users.forEach((user:any) => {
          if (user.UserId === id) {
            allSurveyors.push(user)
          }
        })
      })
    }
  })
}

export const defineCustomBasemap = async (basemapList: any) => {
  reRegisterToken()
  const [MapImageLayer, Basemap, BingMapsLayer] = await loadModules([
    "esri/layers/MapImageLayer",
    "esri/Basemap",
    "esri/layers/BingMapsLayer",
  ]);
  let baseLayers: any = [];
  basemapList.forEach((basemap: any) => {
    if (
      basemap.name == "Bing Aerial" ||
      basemap.name == "Bing Hybrid" ||
      basemap.name == "Bing Roads"
    ) {
      const bing = new BingMapsLayer({
        style: basemap.proxyUrl,
        key: `${basemap.url}`,
        opacity: (basemap.defaultOpacity / 100) * 1,
        visible: basemap.checked ? true : false,
      });
      baseLayers.unshift(bing);
    } else {
      const mapImageLayer = new MapImageLayer({
        url: `${basemap.url}`,
        title: "Basemap",
        opacity: (basemap.defaultOpacity / 100) * 1,
        visible: basemap.checked ? true : false,
      });
      baseLayers.unshift(mapImageLayer);
    }
  });

  return new Basemap({
    baseLayers,
    title: "basemap",
    id: "basemap",
  });
};