import { isEqual, isNull, isUndefined, isArray } from "lodash";

const NameMapping = new Map<string, string> ([
    ['OBJECTID', 'OBJECTID'],
    ['GLOBALID', 'GlobalID'],
    ['SURVEYAREAID', 'SurveyAreaID'],
    ['SURVEYAREANAME', 'SurveyAreaName'],
    ['STATUS', 'Status'],
    ['CREATEDBY', 'CreatedBy'],
    ['CREATEDON', 'CreatedOn'],
    ['MODIFIEDBY', 'ModifiedBy'],
    ['MODIFIEDON', 'ModifiedOn'],
    ['POLYGONTYPE', 'PolygonType'],
    ['POLYGONID', 'PolygonID'],
    ['SURVEYPOLYGONID', 'SurveyPolygonID'],
    ['POLYGONNAME', 'PolygonName'],
    ['SURVEYPLANID', 'SurveyPlanID'],
    ['SURVEYPLANNAME', 'SurveyPlanName'],
    ['SURVEYPLANDESC', 'SurveyPlanDesc'],
    ['PROGRAMTYPE', 'ProgramType'],
    ['REGION', 'Region'],
    ['PLANCATEGORY', 'PlanCategory'],
    ['SURVEYEXECTYPE', 'SurveyExecType'],
    ['FREQUENCY', 'Frequency'],
    ['FREQUNITS', 'FreqUnits'],
    ['COMPLIANCEDATE', 'ComplianceDate'],
    ['SURVEYAREADESC', 'SurveyAreaDesc'],
    ['DISTRICT', 'District'],
    ['SURVEYCATEGORY', 'SurveyCategory'],
    ['COMPLIANCEINTERVALDAYS', 'ComplianceIntervalDays'],
    ['SURVEYYEAR', 'SurveyYear'],
    ['SURVEYAREAPOSITION', 'SurveyAreaPosition'],
    ['SURVEYAREATOTALPOSITION', 'SurveyAreaTotalPosition'],
    ['PLANSTARTDATE', 'PlanStartDate'],
    ['PLANENDDATE', 'PlanEndDate'],
    ['EDITTRACKINGDATE', 'EditTrackingDate'],
    ['EDITTRACKINGUSER', 'EditTrackingUser'],
    ['ASSETTYPE', 'AssetType'],
    ['SURVEYWORKTYPE', 'SurveyWorkType'],
    ['PROGRAMTYPEID', 'ProgramTypeID'],
    ['SURVEYORS', 'Surveyors'],
    ['SURVEYJOBNAME', 'SurveyJobName'],
    ['SURVEYJOBID', 'SurveyJobID'],
    ['WAUSERID', 'waUserID'],
    ['USERID', 'UserID'],
    ['BUFFERID', 'BufferID'],
    ['LEAKID', 'LeakID'],
    ['BUFFERWIDTHDEFAULT', 'BufferWidthDefault'],
    ['BUFFERLENGTHDEFAULT', 'BufferLengthDefault'],
    ['TOTALJOBS', 'TotalJobs'],
    ['UNCOMPLETEDSURVEYBUFFERS', 'UncompletedSurveyBuffers'],
    ['UNCOMPLETEDSERVICES', 'UncompletedServices'],
  ]);
  
  export const getNameMapping = (key:string) => {
    let mappedKey = NameMapping.get(key)?? key
    return mappedKey
  }
  
  export const mapAttribute = (data:object) => {
    let fields:any = []
    if (isEqual(data, {}) || isUndefined(data) || isNull(data)) return fields;

    Object.entries(data).forEach(([key,value]) => {
        const mappedKey = getNameMapping(key)
        fields[mappedKey] = value
    })
    return fields
  }

  export const mapQueryResults = (res:any) => {
    if (isArray(res?.data?.features)) {
      res.data.features.forEach((feature:any, index:number) => {
        res.data.features[index].attributes = mapAttribute(feature.attributes)
      })
    }
    return res
  }
