/**
 * summary: checks the length of number inputs because html number input type ignores
 *          maxLength property
 *
 * @param input the user entered number to be length checked
 */
export const maxLengthCheck =  (input: any) => {
  if (input.target.value.length > input.target.maxLength) {
    input.target.value = input.target.value.slice(0, input.target.maxLength)
  }
}
