import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './QuerySlideoutBody.scss';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import ResetIcon from '@material-ui/icons/SettingsBackupRestoreOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import Toggle from 'common/Toggle/Toggle';
import Button from 'common/Button/Button';
import GreyLine from 'common/GreyLine/GreyLine';
import TextInput from 'common/TextInput/TextInput'
import Dropdown from 'common/Dropdown/Dropdown';
import MultiSelectDropdown from 'common/Dropdown/MultiSelectDropdown';
import CheckBox from 'common/Checkbox/CheckBox';
import PopupModal from 'common/PopupModal/PopupModal';
import {
  RESET_QUERY_RESULTS_COUNT,
  SET_GEOMETRY,
  SET_SEARCH_GEOMETRY,
  storeInfoTables
} from 'common/Map/MapDucks';
import {
  executeTableQuery,
  getAllSavedQueries,
  saveNewQuery,
  deleteQuery,
  EXECUTE_LAYER_QUERY,
  SET_QUERY_RESULT_MSG
} from 'features/MapPage/MapPageDucks';
import { getSlideoutProps } from 'common/Map/slideoutConfig';
import { widgetTypes } from 'common/Map/MapWidgetLayer';
import { geometryType } from 'common/Map/SelectFeatures';
import {
  getAllTableAndLayerNames,
  getMapServiceRelationship,
  getAllFieldsForMapService,
  getFieldMetadata,
  getLayerMapServiceIdByName,
  getTableIndexByName,
  tableNameAttribute
} from 'utils/GridUtils';
import { fieldType, getFieldMaxLength, renderConfigurableField } from 'utils/FieldsUtils';
import { getReducer } from 'utils/ReduxUtils';
import { addDays, convertDate } from 'utils/DateUtils';
import { encodeUriAll } from 'utils/UrlUtils';

export enum equationSignsType {
  equals = '=',
  notEquals = '<>',
  greaterThan = '>',
  lessThan = '<',
  greaterThanOrEquals = '>=',
  lessThanOrEquals = '<=',
  like = 'LIKE'
}

export enum SQLConcatOperators {
  andOperator = 'AND',
  orOperator = 'OR'
}

export enum selectionBounds {
  map = 'map',
  screen = 'screen',
  selectedPolygon = 'selectedPolygon'
}

export enum selectionOptions {
  add = 'add',
  replace = 'replace'
}

interface IQuerySlideoutBody {
  mapType:string,
  query:string,
  setQuery:(query:any)=>void,
  layerId:number,
  setLayerId:(layerId:any)=>void,
  selectedLayer:string,
  setSelectedLayer:(selectedLayer:any)=>void,
  selectedRelationshipLayer:string,
  setSelectedRelationshipLayer:(selectedRelationship:any)=>void,
  selectedField:any,
  setSelectedField:(selectedField:any)=>void,
  valueField:string,
  setValueField:(value:any)=>void,
  selectedEquationSign:equationSignsType,
  setSelectedEquationSign:(selectedEquation:any)=>void,
  isQueryInsertDisabled:boolean,
  setIsQueryInsertDisabled:(isDisabled:any)=>void,
  isQueryAddDisabled:boolean,
  setIsQueryAddDisabled:(isDisabled:any)=>void,
}

const QuerySlideoutBody = ({
  mapType, 
  query,
  setQuery,
  layerId,
  setLayerId,
  selectedLayer,
  setSelectedLayer,
  selectedRelationshipLayer,
  setSelectedRelationshipLayer,
  selectedField,
  setSelectedField,
  valueField,
  setValueField,
  selectedEquationSign,
  setSelectedEquationSign,
  isQueryInsertDisabled,
  setIsQueryInsertDisabled,
  isQueryAddDisabled,
  setIsQueryAddDisabled
}:IQuerySlideoutBody) => {
  const dispatch = useDispatch()
  const mapPageMapReducer = useSelector((state:any) => state.MapPageMapReducer)
  const mapPageReducer = useSelector((state:any) => state.MapPageReducer)
  const settingsReducer = useSelector((state:any) => state.SettingsReducer)
  const savedQueries = useSelector((state:any) => state.MapPageReducer.savedQueries)
  const currentReducer = useSelector((state:any) => state[getReducer(mapType)])
  const layers = settingsReducer.settings.Layers
  const tables = settingsReducer.settings.Tables
  const layerDropdownOptions = getAllTableAndLayerNames(tableNameAttribute.FullName)
  let selectedGrids = mapPageMapReducer.selectedGrids
  let searchGrids = mapPageMapReducer.searchGrids
  let infoResultsLayers = mapPageMapReducer.infoResults.layers
  let queryResultsCount = mapPageMapReducer.queryResultsCount
  let resultMessage = mapPageReducer.queryResultMessage
  // Visual states
  const [isTextAreaDisabled, setisTextAreaDisabled] = useState<boolean>(true);
  const [isSavedQueriesSelected, setIsSavedQueriesSelected] = useState<boolean>(false);
  const [showSavedQueries, setShowSavedQueries] = useState<boolean>(false);
  const [resetLayerDropdown, setResetLayerDropdown] = useState<boolean>(true);
  const [resetRelationshipDropdown, setResetRelationshipDropdown] = useState<boolean>(true);
  const [resetFieldDropdown, setResetFieldDropdown] = useState<boolean>(true);
  const [resetEquationDropdown, setResetEquationDropdown] = useState<boolean>(true);
  const [resetValueField, setResetValueField] = useState<boolean>(true);
  const [isSettingsEnabled, setIsSettingsEnabled] = useState<boolean>(false);
  const [resetSelectPolygonDropdown, setResetSelectPolygonDropdown] = useState<boolean>(false);
  const [resetSavedQueries, setResetSavedQueries] = useState<boolean>(false);
  //Information states
  const [queryObject, setQueryObject] = useState<any>()
  const [layerRelationships, setLayerRelationships] = useState<any>([])
  const [fieldOptions, setFieldOptions] = useState<any>([])
  const [selectionBound, setSelectionBound] = useState<selectionBounds>(selectionBounds.map)
  const [selectionOption, setSelectionOption] = useState<selectionOptions>(selectionOptions.replace)
  const [gridIndexes, setGridIndexes] = useState<any>([])
  const [queryName, setQueryName] = useState<string>('')
  const [isQueryPublic, setIsQueryPublic] = useState<boolean>(false)
  const [minTableId, setMinTableId] = useState<number>(0)

  //Constants
  const equationSigns = Object.values(equationSignsType)

  // Controller for deciding what the value field should
  //be but defaults to text
  const renderValuesField = () => {
    const onChangeFunc = (e:any) => {
      setValueField(e)
      setQueryName('')
    }
    if(!selectedField) {
      return <TextInput value={valueField} onChange={(e)=>{
        setValueField(e.target.value)
        setQueryName('')
      }}/>
    }
    if (selectedRelationshipLayer) {
      return renderConfigurableField(getFieldMetadata(selectedRelationshipLayer,selectedField, tableNameAttribute.FullName), onChangeFunc, valueField, resetValueField);
    }
    return renderConfigurableField(getFieldMetadata(selectedLayer,selectedField, tableNameAttribute.FullName), onChangeFunc, valueField, resetValueField);
  }

  //Combines all the dropdown and selects to create a query
  const addQuery = () => {
    let fieldMetaData = selectedRelationshipLayer ? 
      getFieldMetadata(selectedRelationshipLayer,selectedField, tableNameAttribute.FullName) :
      getFieldMetadata(selectedLayer,selectedField, tableNameAttribute.FullName);
    let numberFieldTypes = [fieldType.shortInteger, fieldType.longInteger, fieldType.float, fieldType.double]
    let conditionalPercent:string = ''
    if (selectedEquationSign === equationSignsType.like) {
      conditionalPercent = '%'
    }
    if(numberFieldTypes.includes(fieldMetaData.FieldType)) {
      setQuery((prevState:any) =>`${prevState} ${selectedField} ${selectedEquationSign} ${conditionalPercent}${valueField}${conditionalPercent}`)
    }
    else {
      setQuery((prevState:any) =>`${prevState} ${selectedField} ${selectedEquationSign} '${conditionalPercent}${valueField}${conditionalPercent}'`)
    }
  }

  const resetQuery = () => {
    setQuery('')
    setIsQueryInsertDisabled(true)
    setResetSavedQueries(true)
    setIsSavedQueriesSelected(false)
    dispatch({type: SET_QUERY_RESULT_MSG, message: ''})
  }

  const fullReset = () => {
    setQuery('')
    setIsQueryInsertDisabled(true)
    setIsQueryAddDisabled(true)
    setResetSavedQueries(true)
    setIsSavedQueriesSelected(false)
    setResetLayerDropdown(true)
    setResetRelationshipDropdown(true)
    setResetFieldDropdown(true)
    equationDropdownReset()
    setSelectedLayer('')
    setSelectedRelationshipLayer('')
    setLayerRelationships([])
    setValueField('')
    setSelectedField('')
    setQueryName('')
    dispatch({type: SET_QUERY_RESULT_MSG, message: ''})
  }

  const equationDropdownReset = () => {
    setSelectedEquationSign(equationSignsType.equals)
    setResetEquationDropdown(true)
  }

  const executeQuery = () => {
    let layer = selectedLayer
    let originFieldName = ''
    let destinationFieldName = ''
    let selectedLayerId = getId(selectedLayer)
    let selectedPolygons: any[] = []
    if (selectedRelationshipLayer) {
      let relationshipIsTable = layerId >= minTableId
      let selectedRelationshipLayerId = relationshipIsTable ?
        layerId - minTableId :
        layerId
      let originRelationships = selectedLayerId >= minTableId ?
        tables[selectedLayerId - minTableId].Relationships :
        layers[selectedLayerId].FeatureClass.Relationships
      //Get the relationship OriginFieldName and DestinationFieldName between the layer from the layer dropdown (origin)
      //and layer from the relationship dropdown (destination)
      originRelationships.forEach((relationship:any) => {
        if (
          ((selectedLayerId < minTableId && relationship.OriginIsLayer) || (selectedLayerId > minTableId && !relationship.OriginIsLayer)) &&
          relationship.DestinationId === selectedRelationshipLayerId &&
          ((!relationshipIsTable && relationship.DestinationIsLayer) || (relationshipIsTable && !relationship.DestinationIsLayer))
        ) {
          originFieldName = relationship.OriginFieldName
          destinationFieldName = relationship.DestinationFieldName
        }
      })
      layer = selectedRelationshipLayer
    }
    dispatch({type: SET_QUERY_RESULT_MSG, message: ''})
    dispatch({type: RESET_QUERY_RESULTS_COUNT})
    if (selectionOption === selectionOptions.replace) {
      dispatch({type:SET_GEOMETRY + mapType, grids: [], lines: [], points: []})
      dispatch({type:SET_SEARCH_GEOMETRY + mapType, grids: [], lines: [], points: []})
      dispatch(storeInfoTables([], tables, layers, mapType, widgetTypes.query))
    }

    //Standardize AND and OR operators to all uppercase
    query = query
      .replaceAll(
        ` ${SQLConcatOperators.andOperator.toLowerCase()} `, 
        ` ${SQLConcatOperators.andOperator} `)
      .replaceAll(
        ` ${SQLConcatOperators.orOperator.toLowerCase()} `, 
        ` ${SQLConcatOperators.orOperator} `)
    query = modifyQuery() ?? query;

    if (layerId < minTableId) {
      //Don't clear grid when bound is selected polygon because the current geometry needs to be used first
      if (selectionBound === selectionBounds.selectedPolygon) {
        let grids = infoResultsLayers.filter((result:any) => result.geometry.type === geometryType.polygon)
        gridIndexes.forEach((i:number) => {
          selectedPolygons.push(grids[i])
        })
      }
      dispatch({
        type:EXECUTE_LAYER_QUERY,
        where:query,
        layer:layer,
        bound:selectionBound,
        selectedPolygons:selectedPolygons,
        relationship:selectedRelationshipLayer !== '',
        originFieldName:originFieldName,
        destinationFieldName:destinationFieldName,
        originLayerId:selectedLayerId
      })
    }
    else {
      dispatch(
        executeTableQuery(
          layerId,
          query,
          selectedRelationshipLayer !== '',
          originFieldName,
          destinationFieldName,
          selectedLayerId
        )
      )
    }
  }

  /**
   * Summary: Modify queries under the hood that might be more complex
   * @returns updated query
   */
  const modifyQuery = () => {
    // Queries can be compounded, we need to traverse and consider all individual queries.
    const individualQueries = query
      .replaceAll(` ${SQLConcatOperators.andOperator} `, ` ${SQLConcatOperators.orOperator} `)
      .split(` ${SQLConcatOperators.orOperator} `);

    let newQuery = '';
    let prevIndividualQuery = '';

    individualQueries.forEach((individualQuery: string) => {
      const [queryField, queryEquationSign, ...value] = individualQuery.trim().split(" ");
      const fieldLayer = selectedRelationshipLayer ? selectedRelationshipLayer : selectedLayer;
      const field = getFieldMetadata(fieldLayer, queryField, tableNameAttribute.FullName);
      let valueField = value.join(" ")
      let queryToAdd
      //Replace all double quotes with single quotes so we won't have to account for both cases
      valueField = valueField.replaceAll(`"`, `'`)
      const valueWrappedInQuotes = valueField.startsWith(`'`) && valueField.endsWith(`'`)
      //Apostrophes must be doubled for ArcGIS queries to work properly
      //Except for global ids
      if (field.FieldType < fieldType.globalID) {
        //regex only matches apostrophes and not something like ('a', 'b')
        valueField = valueField.replace(/\b'\b/g, `''`)
        valueField = valueField.replace(/(\r\n|\n|\r)/gm, "");
      }

      const isLayer = layerId < minTableId;
      // ArcGIS encodes for layers so we don't need to encode here
      if (field?.FieldType === fieldType.text && !isLayer) {
        valueField = encodeUriAll(valueField)
      }

      if (field?.FieldType === fieldType.date) {
        queryToAdd = modifyDateQuery(queryField, queryEquationSign, valueField);
      } 
      // Encode LIKE queries valueFields
      else if (field?.FieldType <= 3 && queryEquationSign === equationSignsType.like && !valueWrappedInQuotes) {
        if (field?.FieldType <= fieldType.longInteger) {
          // Cast type an integer to a varchar to execute like operation
          const maxCharLimit = getFieldMaxLength(field.FieldType);
          queryToAdd = `CAST(${queryField} AS VARCHAR(${maxCharLimit})) LIKE '${valueField}'`;
        }
        else {
          // Include single quotes for all field types that don't add them with LIKE operator
          queryToAdd = `${queryField} ${queryEquationSign} '${valueField}'`;
        }
      }
      else {
        queryToAdd = `${queryField} ${queryEquationSign} ${valueField}`
      }

      const queryToAddPrefix =
        newQuery !== ""
          ? ` ${getConcatOperator(prevIndividualQuery, query)} `
          : "";
      
      prevIndividualQuery = individualQuery;
      newQuery += queryToAddPrefix + queryToAdd;
    });
    return newQuery;
  }

  /**
   * Summary: modify date queries
   * @param queryEquationSign equation sign of the date query
   * @param queryField field name
   * @returns modified date query
   */
  const modifyDateQuery = (queryField: string, queryEquationSign: string, valueField: string) => {
    // clean the date
    const dateInQuery = queryEquationSign === equationSignsType.like
      ? valueField?.slice(2, -2)  // removing '% chars
      : valueField?.slice(1, -1); // removing '' chars

    if (
      queryEquationSign === equationSignsType.equals ||
      queryEquationSign === equationSignsType.like
    ) {
      // Create a 24 hour time range (1 day) for query instead of using = to match dates
      const dateAfterOneDay = convertDate(addDays(new Date(dateInQuery), 1), false, false, "date");
      return `${queryField} BETWEEN (DATE '${dateInQuery}') AND (DATE '${dateAfterOneDay}')`;
    }
    // update the query to include `DATE` in to appease Oracle DB.
    return `${queryField} ${queryEquationSign} DATE '${dateInQuery}'`;
  }
  
  /**
   * Summary: get the concatenation operator for conjoining individual queries.
   * @param slicedQuery string - query after which we want the concat operator for.
   * @param query string - combined untouched query
   * @returns string - AND | OR
   */
  const getConcatOperator = (slicedQuery: string, query: string) => {
    const remainingQuery = query.slice(query.indexOf(slicedQuery) + slicedQuery.length);
    
    if (remainingQuery.includes(` ${SQLConcatOperators.andOperator} `) || remainingQuery.includes(` ${SQLConcatOperators.orOperator} `))
      return remainingQuery.trim().split(" ")[0];
    return;
  }

  const saveQuery = () => {
    if (query && queryName) {
      dispatch(saveNewQuery(layerId, query, queryName, isQueryPublic))
      setQueryName('')
    }
  }

  const getSavedQueryOptions = () => {
    let savedQueriesArray: Array<any> = []
    for (let id in savedQueries) {
      savedQueriesArray = savedQueriesArray.concat(savedQueries[id].map((query:any) => {
        return {label: query.queryName, value: query}
      }))
    }
    return savedQueriesArray
  }

  //Get grids for selected polygons dropdown
  const getGridOptions = () => {
    const grids = infoResultsLayers.filter((result:any) => result.geometry.type === geometryType.polygon)
    const gridOptions = grids.map((grid:any, i:number) => {
      let gridLabel = grid.attributes[grid.displayFieldName] || `${grid.layerName} - OBJECTID ${grid.attributes[grid.objectIdFieldName]}`
      return {label: gridLabel, value: i}
    })
    return gridOptions
  }

  const getId = (layerName:string) => {
    let id = getLayerMapServiceIdByName(layerName)
    if (isNaN(id)) {
      id = getTableIndexByName(layerName, tableNameAttribute.FullName) + minTableId
    }
    return id
  }

  const getNameById = (id:any) => {
    if (id < minTableId) {
      return layers[id].Name
    }
    return tables[id - minTableId].FullName
  }

  //Check if current query ends with AND/OR
  const checkQueryEndsWithConjunction = (query:String) => {
    const lastWord = query.trimEnd().split(' ').pop()
    return lastWord?.toUpperCase() === SQLConcatOperators.andOperator || lastWord?.toUpperCase() === SQLConcatOperators.orOperator
  }

  useEffect(() => {
    if (showSavedQueries) {
      dispatch(getAllSavedQueries())
    }
  }, [showSavedQueries])

  useEffect(() => {
    setResetLayerDropdown(false)
  }, [resetLayerDropdown])

  useEffect(() => {
    setResetRelationshipDropdown(false)
  }, [resetRelationshipDropdown])

  useEffect(() => {
    setResetFieldDropdown(false)
  }, [resetFieldDropdown])

  useEffect(() => {
    setResetEquationDropdown(false)
  }, [resetEquationDropdown])

  useEffect(() => {
    setResetValueField(false)
  }, [resetValueField])

  useEffect(() => {
    setResetSelectPolygonDropdown(false)
  }, [resetSelectPolygonDropdown])

  useEffect(() => {
    setResetSavedQueries(false)
  }, [resetSavedQueries])

  useEffect(() => {
    if (layers) {
      let maxLayerId = 0
      layers.forEach((layer:any) => {
        if (maxLayerId < layer.Id) {
          maxLayerId = layer.Id
        }
      })
      setMinTableId(maxLayerId + 1)
    }
  }, [layers])

  useEffect(() => {
    if (selectedLayer) {
      setLayerRelationships(() => getMapServiceRelationship(selectedLayer, tableNameAttribute.FullName))
    }
  }, [selectedLayer])

  useEffect(() => {
    if (selectedRelationshipLayer) {
      setFieldOptions(getAllFieldsForMapService(selectedRelationshipLayer, tableNameAttribute.FullName))
    }
    else {
      setFieldOptions(getAllFieldsForMapService(selectedLayer, tableNameAttribute.FullName))
    }
    if (!selectedField) {
      setIsQueryAddDisabled(true)
    }
  },[selectedLayer, selectedRelationshipLayer])

  useEffect(() => {
    if (!isTextAreaDisabled) {
      if (checkQueryEndsWithConjunction(query) || !query) {
        setIsQueryInsertDisabled(true)
      }
      else {
        setIsQueryInsertDisabled(false)
      }
    }
  }, [query])

  useEffect(() => {
    if (selectionBound === selectionBounds.selectedPolygon && selectedGrids.length === 0 && searchGrids.length === 0) {
      setSelectionBound(selectionBounds.map)
    }
  }, [selectedGrids, searchGrids])

  useEffect(() => {
    if (queryResultsCount !== 0) {
      dispatch({type: SET_QUERY_RESULT_MSG, message: `${queryResultsCount} results found!`})
    }
  }, [queryResultsCount])

  return (
    <div id = "query-body-container">
      <textarea
        className = "query-textarea"
        disabled = {isTextAreaDisabled}
        onChange = {(e:any) => setQuery(e.target.value)}
        value = {query}
      />
      <div
        className = {
          'textarea-btn-container ' +
          (isTextAreaDisabled? 'disabled-textarea' : '')
        }
      >
        <Toggle
          handleChange = {() => setisTextAreaDisabled((prevState) => !prevState)}
          checked = {!isTextAreaDisabled}
          label = 'Editable'
          labelPosition = 'end'
        />
        <PopupModal
          openBtn={
            <SaveIcon className = 'savedquery-icon'/>
          }
          title="Save Query"
          message={
            <div>
              <label>Name</label>
              <label className = 'asterisk'>*</label>
              <TextInput
                type = "text"
                defaultValue={queryName}
                onChange={(e) => {setQueryName(e.target.value)}}
              />
              <div className = 'shared-container'>
                <CheckBox
                  boxColor = "white"
                  onChange = {() => {setIsQueryPublic(!isQueryPublic)}}
                  isChecked = {isQueryPublic}
                />
                <label className = 'shared-label'>Shared</label>
              </div>
            </div>}
          confirmBtn="Save"
          confirmClick={() => {saveQuery()}}
          cancelBtn="Cancel"
          openButtonColor = 'grey'
          openButtonSize = 's'
          isOpenButtonDisabled = {query === ''}
          isConfirmButtonDisabled = {query === '' || queryName === ''}
        />
        <Button
          className = 'savedquery-btn'
          onClick = {() => setShowSavedQueries((prevState) => !prevState)}
          size = 's'
          boxColor = "grey-border"
        >
          SAVED QUERIES
        </Button>
      </div>
      <GreyLine/>
      {showSavedQueries &&
        <div className = 'savedqueries-container'>
          <div className = 'savedqueries-body'>
            Saved Queries
            <div className = 'savedqueries-actions-container'>
              <Dropdown
                className = 'savedqueries-dropdown'
                items = {getSavedQueryOptions()}
                handleChange = {(e) => {
                  setIsSavedQueriesSelected(true)
                  setQuery(e.query)
                  setQueryObject(e)
                  setLayerId(e.layerId)
                  setSelectedLayer(getNameById(e.layerId))
                  setIsQueryAddDisabled(true)
                  setIsQueryInsertDisabled(checkQueryEndsWithConjunction(e.query))
                  setResetLayerDropdown(true)
                  //TODO: Remove when relationship dropdown actually gets populated
                  setResetRelationshipDropdown(true)
                  setResetFieldDropdown(true)
                  equationDropdownReset()
                  setSelectedField('')
                  setValueField('')
                  setQueryName('')
                  dispatch({type: SET_QUERY_RESULT_MSG, message: ''})
                }}
                isReset = {resetSavedQueries}
              />
              <Button
                className = 'delete-btn'
                onClick = {() => {
                  dispatch(deleteQuery(queryObject.layerId, query, queryObject.id, queryObject.queryName, queryObject.isPublic))
                  setResetSavedQueries(true)
                  resetQuery()
                }}
                size = 's'
                boxColor = "grey"
                isDisabled = {!isSavedQueriesSelected}
              >
                <DeleteIcon className = {'delete-icon ' + (isSavedQueriesSelected?'':'disabled')}/>
              </Button>
            </div>
          </div>
          <GreyLine/>
        </div>
      }
      <div className = 'query-settings'>
        <ResetIcon className = 'query-reset-icon' onClick={fullReset}/>
        <SettingsIcon
          className = {'query-settings-icon ' + (isSettingsEnabled?'':'disabled')}
          onClick={() => (setIsSettingsEnabled(!isSettingsEnabled))}
        />
      </div>
      <div className={'query-settings-container ' + (isSettingsEnabled?'':'hidden')}>
        <CloseIcon
          className="settings-cancel-button"
          onClick={() => (setIsSettingsEnabled(false))}
        />
        <div className='selection-label'>Selection Bounds</div>
        <div className="radio-btn">
          <input
            type='radio'
            id='map'
            checked={selectionBound === selectionBounds.map}
            onChange={() => (setSelectionBound(selectionBounds.map))}
          />
          <label htmlFor='map'>Map</label>
        </div>
        <div className="radio-btn">
          <input
            type='radio'
            id='screen'
            checked={selectionBound === selectionBounds.screen}
            onChange={() => (setSelectionBound(selectionBounds.screen))}
          />
          <label htmlFor='screen'>Screen</label>
        </div>
        <div className="radio-btn">
          <input
            type='radio'
            id='selected-polygon'
            checked={selectionBound === selectionBounds.selectedPolygon}
            onChange={() => (setSelectionBound(selectionBounds.selectedPolygon))}
            disabled={selectedGrids.length === 0 && searchGrids.length === 0}
          />
          <label htmlFor='selected-polygon'>Selected Polygon</label>
        </div>
        <div className='selection-label options-label'>Selection Options</div>
        <div className="radio-btn">
          <input
            type='radio'
            id='add'
            onChange={() => (setSelectionOption(selectionOptions.add))}
            checked={selectionOption === selectionOptions.add}
          />
          <label htmlFor='add'>Add</label>
        </div>
        <div className="radio-btn">
          <input
            type='radio'
            id='replace'
            checked={selectionOption === selectionOptions.replace}
            onChange={() => (setSelectionOption(selectionOptions.replace))}
          />
          <label htmlFor='replace'>Replace</label>
        </div>
      </div>
      <div className = 'query-selectors'>
        <div className ='query-selectors-title'>Search Parameters</div>
        <div className = 'layer-selector'>
          <Dropdown
            className = 'layer-dropdown'
            items = {layerDropdownOptions}
            handleChange = {(e) => {
              setSelectedLayer(e)
              setSelectedRelationshipLayer('')
              setResetRelationshipDropdown(true)
              setResetFieldDropdown(true)
              equationDropdownReset()
              setLayerRelationships(() => getMapServiceRelationship(e, tableNameAttribute.FullName))
              setSelectedField('')
              setValueField('')
              setQueryName('')
              resetQuery()
              let id = getId(e)
              setLayerId(id)
            }}
            isReset = {resetLayerDropdown}
            placeHolder = 'Select'
            forceLabel={layerDropdownOptions.find((option:any) => option.value === selectedLayer)?.label}
            forceValue={selectedLayer}
            allowOptionsTextWrap={true}
          />
          <Dropdown
            className = 'relationship-dropdown'
            items = {layerRelationships}
            handleChange = {(e) => {
              let id = getId(e)
              setLayerId(id)
              setSelectedRelationshipLayer(e)
              setResetFieldDropdown(true)
              equationDropdownReset()
              setSelectedField('')
              setValueField('')
              setQueryName('')
              setQuery('')
              setIsQueryInsertDisabled(true)
              setIsQueryAddDisabled(true)
            }}
            isReset = {resetRelationshipDropdown}
            placeHolder = 'Select'
            forceValue={selectedRelationshipLayer}
            allowOptionsTextWrap={true}
          />
        </div>
        <hr className = 'divider'/>
        <div className = 'value-selector'>
          <div className = 'field-container'>
            <div className = 'field-title'>Field</div>
            <Dropdown
              items = {fieldOptions}
              handleChange = {(e) => {
                setSelectedField(e)
                setIsQueryAddDisabled(false)
                equationDropdownReset()
                setResetValueField(true)
                setValueField('')
                setQueryName('')
              }}
              placeHolder = 'Select'
              isReset = {resetFieldDropdown}
              theme = {'matchTextbox'}
              forceValue={selectedField}
              allowOptionsTextWrap={true}
            />
          </div>
          <Dropdown
            className = 'equation-dropdown'
            items = {equationSigns}
            defaultValue = {{label: equationSignsType.equals, value: equationSignsType.equals}}
            forceValue={selectedEquationSign}
            handleChange = {(e) => {
              setSelectedEquationSign(e)
              setQueryName('')
            }}
            isReset = {resetEquationDropdown}
            theme = {'matchTextbox'}
          />
          <div className = 'value-container'>
            <div className='value-title'>Value</div>
            {renderValuesField()}
          </div>
          <Button
            className = "add-btn"
            size = 's'
            boxColor = 'white'
            isDisabled = {!isQueryInsertDisabled || isQueryAddDisabled}
            onClick = {() => {
              addQuery()
              setIsQueryInsertDisabled(false)
            }}
          >
            ADD
          </Button>
        </div>
        <div className = "insert-container">
          Insert:
        </div>
        <div className = "andor-container">
          <Button
            className = "AND-btn"
            size = 's'
            boxColor = 'grey-border-dark-text'
            isDisabled = {isQueryInsertDisabled}
            onClick = {() => {
              setQuery((prevState:any) => `${prevState} ${SQLConcatOperators.andOperator}`)
              setIsQueryInsertDisabled(true)
            }}
          >
            AND
          </Button>
          <Button
            className = 'OR-btn'
            size = 's'
            boxColor = 'grey-border-dark-text'
            isDisabled = {isQueryInsertDisabled}
            onClick = {() => {
              setQuery((prevState:any) => `${prevState} ${SQLConcatOperators.orOperator}`)
              setIsQueryInsertDisabled(true)
            }}
          >
            OR
          </Button>
          </div>
          <div className = {'select-polygon-container ' + (selectionBound === selectionBounds.selectedPolygon?'':'hidden')}>
            <div className = 'select-polygon-title'>Select Polygon(s)</div>
            <MultiSelectDropdown
              items = {getGridOptions()}
              handleChange = {(e) => setGridIndexes(e)}
              isReset = {resetSelectPolygonDropdown}
            />
          </div>
          <div className='result-label'>{resultMessage}</div>
      </div>
      <div className = 'execute-container'>
        <Button
          size = 'l'
          boxColor = 'white'
          onClick = {() => {
            getSlideoutProps(dispatch, currentReducer.slideOutInfo.activeSlideoutTool, currentReducer, mapType).cancel()}
          }
        >
          Cancel
        </Button>
        <Button
          size = 'l'
          boxColor = 'blue'
          className = 'execute-btn'
          onClick = {executeQuery}
          isDisabled = {!query}
        >
          Execute
        </Button>
      </div>
    </div>
  )
}

export default QuerySlideoutBody
