import React, { useEffect, useState } from 'react';
import { loadModules } from 'esri-loader';
import { useDispatch, useSelector } from 'react-redux';
import { isNull, isUndefined } from 'lodash';
import PopupModal from 'common/PopupModal/PopupModal';
import Dropdown from 'common/Dropdown/Dropdown';
import { SET_MAP_NOTIFICATION } from 'common/Map/MapDucks';
import { fieldType, findPermissions, renderConfigurableField } from 'utils/FieldsUtils';
import { 
  getAllFieldDataForMapService, 
  getFieldMetadata, 
  getFieldOptions, 
  getLayerOrTableByName 
} from 'utils/GridUtils';
import { getReducer } from 'utils/ReduxUtils';
import './InfoSlideoutBody.scss';
import { reRegisterToken } from 'utils/IdentityManagerUtils';
import { getModifiedByAndOnData } from './Table/ChildTable';
import { getNameMapping } from 'utils/NameMappingUtils';

interface IBulkUpdateModal {
  numSelections:number,
  selectionIndex:Array<number>
  layerName:string,
  data:any,
  infoResultsData:any,
  setInfoResultsData:Function,
  relationshipTable:any,
  setRelationshipTable:Function,
  isRelationship:boolean,
  isParent:boolean,
  mapType:string
}

const BulkUpdateModal = ({
  numSelections, 
  selectionIndex, 
  layerName, 
  data, 
  infoResultsData, 
  setInfoResultsData, 
  relationshipTable, 
  setRelationshipTable, 
  isRelationship,
  isParent,
  mapType
}:IBulkUpdateModal) => {
  const dispatch = useDispatch();
  const editableLayers = useSelector((state: any) => state.SettingsReducer.editableLayers);
  const editableTables = useSelector((state: any) => state.SettingsReducer.editableTables);
  const layers = useSelector((state: any) => state.SettingsReducer.settings.Layers);
  const loggedInUser = useSelector((state: any) => state.LoginReducer.loggedInUser);
  const currentReducer = useSelector((state:any) => state[getReducer(mapType)]);
  const featureCheckResults = currentReducer.featureCheck;
  const [layer, setLayer] = useState<any>()
  const [layerId, setLayerId] = useState<number>(-1)
  const [fields, setFields] = useState<any>([])
  const [selectedDataIds, setSelectedDataIds] = useState<Array<any>>([])
  const [selectedField, setSelectedField] = useState<string>('')
  const [valueField, setValueField] = useState<string>('')
  const [fieldAlias, setFieldAlias] = useState<string>('')
  const [currentFieldType, setCurrentFieldType] = useState<number>(-1)
  //Used for resetting dropdown values
  const [resetValueField, setResetValueField] = useState<boolean>(false);

  useEffect(() => {
    setResetValueField(false)
  }, [resetValueField])

  useEffect(() => {
    setLayer(getLayerOrTableByName(layerName))
    setFields(getAllFieldDataForMapService(layerName))
  }, [layerName])

  useEffect(() => {
    if (layer?.Name) {
      setLayerId(layer.Id)
    }
    else if (layer?.DisplayName) {
      setLayerId(layer.Id + layers.length)
    }
  }, [layer])

  useEffect(() => {
    const dataObjectIds = data.map((feature:any) => feature.OBJECTID)
    let selectedIds = selectionIndex.map((index) => dataObjectIds[index])
    if (Object.keys(featureCheckResults).includes(layerId.toString())) {
      selectedIds = selectedIds.filter((id:number) => {
        return featureCheckResults[layerId][id]
      })
    }
    setSelectedDataIds(selectedIds)
  }, [data, selectionIndex, featureCheckResults, layerId])

  /**
   * Summary: On submit handler for editable attribute fields
   *
   * @param featureId layer/table id according to Lantern_Survey (MapServer)
   * @param fieldName field name to apply edits to
   * @param aliasName alias name to apply edits to
   * @param value new value to update field by
   * @param isDate whether the field is a date
   */
   const bulkApplyEdit = (featureId: number, fieldName: string, aliasName: string, value: any, isDate: boolean = false) => {
    reRegisterToken()
    const currentFeature = [...editableLayers, ...editableTables].find((layer:any) => layer.mapServerId === featureId)
    // convert date back to epoch format
    let newValue = isDate ? Date.parse(value) : value;
    if (!isUndefined(newValue?.value)){
      newValue = newValue.value
    }

    //For the event that all selected records are under edit protection.
    if (selectedDataIds.length === 0) {
      dispatch({
        type: SET_MAP_NOTIFICATION + mapType,
        message: `0 record(s) updated due to having edit protection.`,
        success: true
      })
    }
    // check isNullable permission (checks for null, undefined, and empty)
    else if (!findPermissions(fieldName, fields)[1] && (isNull(newValue) || isUndefined(newValue) || newValue === "")) {
      dispatch({
        type: SET_MAP_NOTIFICATION + mapType,
        message: `Failed to update records.`,
        success: false
      })
    } 
    else {
      loadModules(['esri/layers/FeatureLayer'])
      .then(([FeatureLayer]) => {
        const featureLayer = new FeatureLayer({
          url: currentFeature.url
        })

        const editFeatures = changeBulkLocal(
          featureId, 
          selectedDataIds, 
          fieldName, 
          value
        )

        featureLayer.applyEdits({updateFeatures: editFeatures})
        .then ((editsResult: any) => {
          const error = editsResult.updateFeatureResults[0].error
          if (!isNull(error)) {
            dispatch({
              type: SET_MAP_NOTIFICATION + mapType,
              message: `Failed to update records.`,
              success: false
            })
          } else {
            dispatch({
              type: SET_MAP_NOTIFICATION + mapType,
              message: `${selectedDataIds.length} record(s) successfully updated.`,
              success: true
            })
          }
        })
        .catch(() => {
          dispatch({
            type: SET_MAP_NOTIFICATION + mapType,
            message: `Failed to update records.`,
            success: false
          })
        })
      })
    }
  }

  /**
   * Summary: Reflect applied edits to the local data
   * @param featureLayerId layer/table id
   * @param objectIds array of object ids
   * @param name field name
   * @param value field value
   */
  const changeBulkLocal = (
    featureLayerId: number, 
    objectIds: Array<number>, 
    name: string, 
    value: any
  ) => {
    let changeCount = 0;
    const editFeatures = []
    let modifiedByFieldName = ""
    let modifiedOnFieldName = ""
    let newModifiedByValue = ""
    let newModifiedOnDate = ""

    if (!isRelationship) {
      const newInfoResultsData = infoResultsData;

      // array.find() (breaks after first find) but without a return
      for (let i = 0; i < newInfoResultsData.length; i++) {
        const data = newInfoResultsData[i]

        if (
          (data.layerName ? featureLayerId === data.id : featureLayerId === data.id + layers.length) &&
          (objectIds.includes(data.attributes[data.objectIdFieldName]) || objectIds.includes(data.attributes.OBJECTID))
        ) {
          if (changeCount === 0) {
            ({ modifiedByFieldName, modifiedOnFieldName, newModifiedByValue, newModifiedOnDate } = 
              getModifiedByAndOnData(data, name, value, loggedInUser));
          }
          data.attributes[
            data.attributes.hasOwnProperty(name) ? name : getNameMapping(name)
          ] = value;
          data.attributes[modifiedByFieldName] = newModifiedByValue;
          data.attributes[modifiedOnFieldName] = newModifiedOnDate;
          newInfoResultsData[i] = data;
          editFeatures.push({attributes: Object.assign({}, data.attributes)})
          changeCount++

          //Break if all affected items have been updated
          if (changeCount === objectIds.length) {
            break
          }
        }
      }
      setInfoResultsData(newInfoResultsData);
    } else {
      const newRelationshipTable = relationshipTable;
      for (let i = 0; i < newRelationshipTable.length; i++) {
        if (objectIds.includes(newRelationshipTable[i].OBJECTID)) {
          newRelationshipTable[i][name] = value
          newRelationshipTable[i][modifiedByFieldName] = newModifiedByValue
          newRelationshipTable[i][modifiedOnFieldName] = newModifiedOnDate
          editFeatures.push({attributes: Object.assign({}, data.attributes)})
          changeCount++
        }

        //Break if all affected items have been updated
        if (changeCount === objectIds.length) {
          setRelationshipTable(newRelationshipTable)
          break
        }
      }
    }
    return editFeatures
  }

  return (
    <PopupModal
      openBtn={
        <div>Bulk Edit</div>
      }
      title="Bulk Edit"
      message={
        <div>
            <p>Field</p>
            <Dropdown
                items={layer ? getFieldOptions(layer).filter((field:any) => field.value.IsEditable) : []}
                handleChange={(e) => {
                  setSelectedField(e.Name)
                  setResetValueField(true)
                  setValueField('')
                  setFieldAlias(e.AliasName)
                  setCurrentFieldType(e.FieldType)
                }}
            />
            <p>Value</p>
            {renderConfigurableField(getFieldMetadata(layerName, selectedField), setValueField, valueField, resetValueField)}
        </div>
      }
      confirmBtn="Save"
      confirmClick={() => bulkApplyEdit(layerId, selectedField, fieldAlias, valueField, currentFieldType === fieldType.date)}
      cancelBtn="Cancel"
      openButtonColor={numSelections === 0 ? 'grey' : 'blue'}
      openButtonSize='s'
      openButtonClass={isParent? '' : 'hidden'}
      isConfirmButtonDisabled={selectedField === ''}
      isOpenButtonDisabled={numSelections === 0}
      onClose={() => {
        setSelectedField('')
        setValueField('')
      }}
    />
  )
}

export default BulkUpdateModal
