import React from 'react';
import Checkbox from 'common/Checkbox/CheckBox';

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, checked, ...rest }:any, ref:any) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <Checkbox
        isChecked = {checked}
        onChange = {rest.onChange}
        inputRef = {resolvedRef}
        boxColor="white"
      />
    )
  }
)

export default IndeterminateCheckbox
