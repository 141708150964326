import { encodeUriAll } from "utils/UrlUtils";
import arcGisBaseUrl from ".";
import { arcgisQueryParams, concatQueryingParameters } from "./constants";

/**
 * Summary: Basic ArcGIS query
 * @param selectedMapService
 * @param tableOrLayerID - id for the table or layer the query is for
 * @param queryExtension - query parameters in a string
 * @returns 
 */
export const getArcgisQuery = (
  selectedMapService: string,
  tableOrLayerID: string | number,
  queryExtension: string
) => {
  return arcGisBaseUrl.get(
    `/${selectedMapService}/MapServer/${tableOrLayerID}/query?${queryExtension}`
  );
};

export const postArcgisQuery = (
  selectedMapService: string,
  tableOrLayerID: string | number,
  requestBody: Object
) => {
  return arcGisBaseUrl.post(
    `/${selectedMapService}/MapServer/${tableOrLayerID}/query`,
    requestBody
  );
};

export const getMapLayers = (selectedMapService: string) => {
  return arcGisBaseUrl.get(`/${selectedMapService}/MapServer/layers`);
};

export const postMapLayers = (selectedMapService: string) => {
  return arcGisBaseUrl.get(`/${selectedMapService}/MapServer/`);
};

export const getSearchLayerFields = (
  selectedMapService: string,
  searchFields: string,
  searchText: string,
  layerId: string
) => {
  return arcGisBaseUrl.get(
    `/${selectedMapService}/MapServer/find?contains=true&searchFields=${searchFields}&searchText=${searchText}&sr=102100&` +
      `returnGeometry=true&layers=${layerId}&returnFieldName=true`
  );
};

export const getSearchTableFields = (
  selectedMapService: string,
  layerId: string,
  whereClause: string
) => {
  const queryExtension = concatQueryingParameters({
    [arcgisQueryParams.where]: whereClause,
    [arcgisQueryParams.outFields]: "*",
    [arcgisQueryParams.returnGeometry]: "true",
  });
  return getArcgisQuery(selectedMapService, layerId, queryExtension);
}

export const getInfoChildren = (
  selectedMapService: string,
  id: number,
  whereClause: string
) => {
  const queryExtension = concatQueryingParameters({
    [arcgisQueryParams.where]: whereClause,
    [arcgisQueryParams.returnGeometry]: "true",
    [arcgisQueryParams.outSR]: "102100",
    [arcgisQueryParams.outFields]: "*",
  });
  return getArcgisQuery(selectedMapService, id, queryExtension);
};

export const getMapServices = (folderName: string) => {
  return arcGisBaseUrl.get(`/${folderName}`);
};

export const getFolders = () => {
  return arcGisBaseUrl.get(`/`);
};

/**
 * Performing a surveyJob query
 * @param selectedMapService - map service selected
 * @param surveyJobID - table id for surveyJob
 * @param queryExtension - string with all query parameters
 * @returns 
 */
export const getSurveyJobInfo = (
  selectedMapService: string,
  surveyJobID: number | string,
  queryExtension: string
) => getArcgisQuery(selectedMapService, surveyJobID, queryExtension);

/**
 * Performing a surveyPlan query
 * @param selectedMapService - map service selected
 * @param surveyPlanID - table id for surveyPlan
 * @param queryExtension - string with all query parameters
 * @returns 
 */
export const getSurveyPlanInfo = (
  selectedMapService: string,
  surveyPlanID: number | string,
  queryExtension: string
) => getArcgisQuery(selectedMapService, surveyPlanID, queryExtension);

/**
 * Performing a surveyJobWorkAssignment query
 * @param selectedMapService - map service selected
 * @param workAssignmentServiceID - table id for SurveyJobWorkAssignments
 * @param queryExtension - string with all query parameters for the query
 * @returns 
 */
export const getSurveyJobWorkAssignmentsInfo = (
  selectedMapService: string,
  workAssignmentServiceID: string,
  queryExtension: string
) => getArcgisQuery(selectedMapService, workAssignmentServiceID, queryExtension);

/**
 * Summary: perform a query search for a table that has id `arcgisTableID` that 
 * has a Survey Area that matches `surveyAreaID`
 * @param selectedMapService 
 * @param arcgisTableID - arcgis table id for the table we are querying for
 * @param surveyAreaID - survey area id to compare in the `where` clause
 * @returns 
 */
export const getTableQueryBySurveyAreaID = (
  selectedMapService: string,
  arcgisTableID: string | number,
  surveyAreaID: string | number | null
) => {
  const queryExtension = concatQueryingParameters({
    [arcgisQueryParams.where]: `SurveyAreaID='${surveyAreaID}'`,
    [arcgisQueryParams.outFields]: '*'
  })
  return getArcgisQuery(selectedMapService, arcgisTableID, queryExtension);
};

/**
 * Summary: get a list of valid csl entries for bulk edit 
 * Used in SurveyInitiationUtils.
 * @param fieldToSearchFor - field we are querying for and that we will sort by
 * @param inClause - entries input that will go `IN` clause of the query
 * @param outFields - outFields for the query
 * @returns 
 */
export const getValidCSLEntriesFromSearch = (
  selectedMapService: string,
  layerId: number | string,
  fieldToSearchFor: string,
  inClause: string,
  outFields: string,
) => {
  const queryExtension = concatQueryingParameters({
    [arcgisQueryParams.where]: encodeUriAll(`${fieldToSearchFor} IN (${inClause})`),
    [arcgisQueryParams.orderByFields]: fieldToSearchFor,
    [arcgisQueryParams.outFields]: outFields,
  });
  return getArcgisQuery(selectedMapService, layerId, queryExtension);
}

/**
 * Summary: Gets the promise options for Corrosion Areas, Pipelines, Grids, etc.
 * @param where - arcgis where query param
 * @param outFields - arcgis outFields query param
 * @param orderByFields - arcgis orderByFields query param
 * @param resultRecordCount - arcgis resultRecordCount query param
 * @returns 
 */
export const getPromiseOptions = (
  selectedMapService: string,
  id: string | number,
  where: string,
  outFields: string,
  orderByFields: string = '',
  resultRecordCount: number | string = ''
) => {
  const queryExtension = concatQueryingParameters({
    [arcgisQueryParams.where]: where,
    [arcgisQueryParams.outFields]: outFields,
    [arcgisQueryParams.orderByFields]: orderByFields,
    [arcgisQueryParams.resultRecordCount]: resultRecordCount
  });
  return getArcgisQuery(selectedMapService, id, queryExtension);
}