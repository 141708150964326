import axios from "axios";
import { store } from "./store";
import { axiosErrorHandler } from "utils/AxiosUtils";
import { getNameMapping } from "utils/NameMappingUtils";
import { isNull, isUndefined } from "lodash";
import {
  getFeatureServerConfiguration,
  getSettingConfiguration,
  getConfigSettings,
  getConfigPerspectives,
  getSecurityConfig,
  getMapDetails,
  getMapConfig,
  getMapFieldAutoValues,
  getMapNearestFeatureSettings,
  getMapLayers, 
  postMapLayers
} from "api";


export const MAPSERVICE_SETTINGS_SUCCESS = "MAPSERVICE_SETTINGS_SUCCESS";
export const MAPSERVICE_SETTINGS_ERROR = "MAPSERVICE_SETTINGS_ERROR";
export const SETTING_SUCCESS = "SETTING_SUCCESS";
export const SETTING_ERROR = "SETTING_ERROR";
export const SET_SIDE_NAV = "SET_SIDE_NAV";
export const SET_SETTINGS_VISIBLE = "SET_SETTINGS_VISIBLE";
export const SET_LAYER_VISIBILITY = "SET_LAYER_VISIBILITY";
export const SET_LAYER_SELECTABILITY = "SET_LAYER_SELECTABILITY";

export const loadInitalSettings = () => async (dispatch: any) => {
  dispatch({ type: "SETTING_LOADING" });
  axios
    .all([
      getConfigSettings(),
      getSecurityConfig({ post: "thundercats70542564" }),
      getConfigPerspectives(),
    ])
    .then(
      axios.spread(function (globalAliases, securityRoles, userPermissions) {
        dispatch({
          type: SETTING_SUCCESS,
          globalAliases: globalAliases.data,
          securityRoles: securityRoles.data,
          userPermissions: userPermissions.data,
        });
      })
    )
    .catch((err) => {
      dispatch({
        type: SETTING_ERROR,
        error: err,
      });
    });
};

export const loadSettings = () => async (dispatch: any) => {
  const mapServiceUrl = store.getState().LoginReducer.selectedMapService.url;
  const selectedMapService =
    store.getState().LoginReducer.selectedMapService.label;
  dispatch({ type: "MAPSERVICE_SETTINGS_LOADING" });
  getMapLayers(selectedMapService)
  axios
    .all([
      getMapDetails(selectedMapService),
      getMapConfig(mapServiceUrl),
      getSettingConfiguration(selectedMapService),
      // axios.get(`${mapServiceUrl}/layers?f=json&token=${tkn}`),
      getMapLayers(selectedMapService),
      // axios.post(`${mapServiceUrl}?f=json&token=${tkn}`),
      postMapLayers(selectedMapService),
      getMapFieldAutoValues(
        {
          mapserviceurl: mapServiceUrl,
        },
        mapServiceUrl
      ),
      getMapNearestFeatureSettings(
        {
          mapserviceurl: mapServiceUrl,
        }
      ),
      getFeatureServerConfiguration(selectedMapService),
    ])
    .then(
      axios.spread(function (
        settings,
        layers,
        layersWithPolygonType,
        layersWithLabelingInfo,
        mapServices,
        autoValues,
        nearestFeatureSettings,
        mapping
      ) {
        axiosErrorHandler(arguments);
        layers.data.GridLayers = mergeLayersData(
          layers.data,
          layersWithPolygonType.data
        );

        addLabelingInfoToSettings(
          settings.data.Layers,
          layersWithLabelingInfo.data.layers
        );
        replaceDisplayFieldNames(settings.data, layersWithLabelingInfo.data)
        dispatch({
          type: MAPSERVICE_SETTINGS_SUCCESS,
          settings: settings.data,
          layers: layers.data,
          mapServices: mapServices.data,
          autoValues: autoValues.data,
          nearestFeatureSettings: nearestFeatureSettings.data,
          featureServers: mapping.data.featureServers,
        });
      })
    )
    .catch((err) => {
      dispatch({
        type: MAPSERVICE_SETTINGS_ERROR,
        error: err,
      });
    });
};

// add polygon type from layersWithPolygonTypes to the gridLayers in layers (and return the result)
const mergeLayersData = (layers: any, layersWithPolygonType: any) => {
  const gridLayers = layers.GridLayers;
  const gridLayersWithPolygonType = layersWithPolygonType.gridLayers;
  gridLayers.forEach((gridLayer: any, index: number) => {
    const gridLayerWithPolygonType = gridLayersWithPolygonType.find(
      (gridLayerWithPolygonType: any) => {
        return gridLayerWithPolygonType.name === gridLayer.Name;
      }
    );
    gridLayers[index].polygonType = gridLayerWithPolygonType.polygonType;
  });
  return gridLayers;
};

/**
 * Add labeling info for layers (if it exists) to the layers in the settings
 * @param settingsLayers layers in the settings
 * @param layersWithLabelingInfo layers containing labeling info
 */
const addLabelingInfoToSettings = (
  settingsLayers: any,
  layersWithLabelingInfo: any
) => {
  layersWithLabelingInfo.forEach((layer: any, index: number) => {
    if (layer?.drawingInfo?.labelingInfo) {
      settingsLayers[index].LabelingInfo = {
        labelExpression: layer.drawingInfo.labelingInfo[0].labelExpression,
        minScale: layer.drawingInfo.labelingInfo[0].minScale,
        maxScale: layer.drawingInfo.labelingInfo[0].maxScale,
      };
    }
    settingsLayers[index].hasLabels = layer.hasLabels;
  });
};

/**
 * Replace display field name from getMapDetails with those from getMapLayers
 * (due to strange data from the first call in a few cases, still being looked into)
 * @param settingsData getMapDetails data
 * @param arcgisData getMapLayers data
 */
const replaceDisplayFieldNames = (settingsData:any, arcgisData:any) => {
  settingsData.Layers.forEach((layer:any, index:number) => {
    layer.DisplayFieldName = arcgisData.layers[index].displayField
  })
  settingsData.Tables.forEach((table:any, index:number) => {
    table.DisplayFieldName = arcgisData.tables[index].displayField
  })
}

const initialState: any = {
  settings: [],
  layers: [],
  securityRoles: {},
  mapServiceLayersList: [],
  tableMapServicesList: [],
  error: "",
  isSideNavOpen: true,
  isSettingsVisible: false,
};

export default function reducer(state = initialState, action: any) {
  switch (action.type) {
    case MAPSERVICE_SETTINGS_SUCCESS: {
      let massagedSettings = action.settings;
      const allLayers = action.mapServices.layers;
      const editableLayerTables = action.featureServers || [];

      let editableLayers: any[] = [];
      let editableTables: any[] = [];
      const mapIdToFeatureId: {[index: number]:number} = {}
      const names = allLayers.map((element: any) => element.name);

      editableLayerTables.forEach((layer: any, index: number) => {
        if (!isNull(layer.featureServerLayerTableId)) {
          const props = {
            name: layer.layerTableName,
            mapServerId: layer.mapServerLayerTableId,
            featureServerId: layer.featureServerLayerTableId,
            url: layer.featureServerUrl,
          };
          mapIdToFeatureId[layer.mapServerLayerTableId] = layer.featureServerLayerTableId
          if (names.includes(layer.layerTableName.trim())) {
            editableLayers.push(props);
          } else {
            editableTables.push(props);
          }
        }
      });
      action.settings.Tables.forEach((table: any, tIndex: number) => {
        table.Fields.forEach((field: any, fIindex: number) => {
          massagedSettings.Tables[tIndex].Fields[fIindex].Name = getNameMapping(
            field.Name
          );
        });
      });

      const defaultVisibility: { [index: string]: boolean } = {};
      const defaultSelectability: { [index: string]: boolean } = {};
      massagedSettings.Layers.forEach((layer: any) => {
        //If the layer id is undefined, that means there's no feature server id for this layer
        //Default to map server id
        if (isUndefined(mapIdToFeatureId[layer.Id])) {
          mapIdToFeatureId[layer.Id] = layer.Id
        }
        defaultVisibility[mapIdToFeatureId[layer.Id] + layer.Name] = layer.Visible;
        defaultSelectability[mapIdToFeatureId[layer.Id] + layer.Name] = layer.Selectable;
      });

      return {
        ...state,
        settings: massagedSettings,
        layers: action.layers,
        mapServiceLayersList: action.mapServices.layers,
        tableMapServicesList: action.mapServices.tables
          ? action.mapServices.tables
          : [],
        editableLayers: editableLayers,
        editableTables: editableTables,
        autoValues: {
          usernameAutoValues:
            action.autoValues[
              "com.threegis.nx.editmap.logic.fieldAutoValues::UsernameAutoValue"
            ] || [],
          timestampAutoValues:
            action.autoValues[
              "com.threegis.nx.editmap.logic.fieldAutoValues::TimestampAutoValue"
            ] || [],
          guidAutoValues:
            action.autoValues[
              "com.threegis.nx.editmap.logic.fieldAutoValues::GUIDAutoValue"
            ] || [],
          longitudeAutoValues: action.autoValues[
            "com.threegis.nx.editmap.logic.fieldAutoValues::WebLongitudeAutoValue"
          ] || [],
          latitudeAutoValues: action.autoValues[
            "com.threegis.nx.editmap.logic.fieldAutoValues::WebLatitudeAutoValue"
          ] || []
        },
        nearestFeatureSettings: action.nearestFeatureSettings,
        defaultVisibility: defaultVisibility,
        defaultSelectability: defaultSelectability,
        layerVisibility: defaultVisibility,
        layerSelectability: defaultSelectability,
        mapIdToFeatureId: mapIdToFeatureId
      };
    }
    case MAPSERVICE_SETTINGS_ERROR: {
      return {
        ...state,
        error: action.error,
      };
    }
    case SETTING_SUCCESS: {
      let mapped = action.globalAliases.map((item: any) => ({
        [item.name]: item.value,
      }));
      let massagedGlobalAliases = Object.assign({}, ...mapped);
      return {
        ...state,
        securityRoles: action.securityRoles,
        globalAliases: massagedGlobalAliases,
        userPermissions: action.userPermissions,
      };
    }
    case SETTING_ERROR: {
      return {
        ...state,
        error: action.error,
      };
    }
    case SET_SIDE_NAV: {
      return {
        ...state,
        isSideNavOpen: action.isSideNavOpen,
        isSettingsVisible: false,
      };
    }
    case SET_SETTINGS_VISIBLE: {
      return {
        ...state,
        isSettingsVisible: action.isSettingsVisible,
      };
    }
    case SET_LAYER_VISIBILITY: {
      return {
        ...state,
        layerVisibility: action.layerVisibility,
      };
    }

    case SET_LAYER_SELECTABILITY: {
      return {
        ...state,
        layerSelectability: action.layerSelectability,
      };
    }
    default: {
      return state;
    }
  }
}
