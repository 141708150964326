import { ListItemText, Collapse, ListItemButton, List } from "@mui/material";
import { styled } from "@mui/system";

export const StyledList = styled(List)({
  marginLeft: "10px",
});

export const StyledListItemText = styled(ListItemText)({
  fontFamily: "Montserrat",
  fontWeight: "medium",
  fontSize: "10px",
  color: "#929497",
});
