// referenced from 'app/_colors.scss'
export const colors = {
  white: '#fff',
  black: '#000',
  secondaryBlue: '#0088c1',
  primaryGrey: '#929497',
  secondaryGrey: '#d0d2d3',
  darkGreyishBlue: '#a6a8ab'
}

export const styles: any = {
  // if fixedValue is defined
  fixedValueStyle: {
    singleValue: {
      color: colors.primaryGrey
    }
  },
  // borderless: true
  borderlessStyle: {
    control: {
      border: 'none',
      '&:hover': {
        border: 'none'
      },
      padding: '0',
    }
  },
  // size: small
  sizeSmallStyle: {
    control: {
      height: '1.5rem',
      minHeight: '1.5rem',
      caretColor: 'transparent'
    },
    input: {
      margin: '0 2px',
      padding: '0 0 1rem 0'
    },
    dropdownIndicator: {
      padding: '0 0 1rem 0'
    }
  },
  // theme: date filter (Reported Issues in Overview Dashboard)
  dateFilterStyle: {
    singleValue: {
      top: '40%',
      width: '80%',
      maxWidth: '80%',
      color: colors.darkGreyishBlue,
      textAlign: 'right',
      fontSize: '12px',
      fontWeight: '500'
    },
    menu: {
      right: '0',
      maxWidth: '90%',
      fontSize: '12px',
    },
    dropdownIndicator: {
      color: colors.darkGreyishBlue
    }
  },
  // theme: match textbox
  // (set dropdown's height to 50px to match textboxes - or other components - of that height)
  matchTextboxStyle: {
    control: {
      height: '50px'
    },
    dropdownIndicator: {
      padding: '10px'
    }
  },
  // theme: ParentTable cell (ParentTable in Info Tool)
  parentTableCellStyle: {
    container: {
      width: 'max-content'
    },
    menu: {
      position: 'static'
    }
  },
  // theme: dropdowns that are searchable
  searchStyle: {
    container: {
      width: '250px'
    },
    control: {
      height: '45px'
    },
    dropdownIndicator: {
      padding: '1vh 1vh 1vh 0'  // to reduce space between close button and indicator
    }
  },
  // theme: withIcon 
  // - dropdowns in the slideout that have an icon next to it
  // - the dropdown's border is removed, instead a border needs to be added around the dropdown and the icon
  // - used in search dropdown of comma-separated list component (in survey initiation)
  withIconStyle: {
    control: {
      border: 'none'
    },
    menu: {
      width: '365px'  // the width of the container that holds the dropdown + icon
    }
  }
}
