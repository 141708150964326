import React, { useEffect } from 'react';
import '../../Map.scss';
import { useDispatch } from 'react-redux';
import VerticalSplitRoundedIcon from '@material-ui/icons/VerticalSplitRounded';
import { widgetTypes, IWidgetProps } from 'common/Map/MapWidgetLayer';
import {
  SET_ACTIVE_TOOL,
  SET_ACTIVE_SLIDEOUT_TOOL,
  SHOW_SIDE_PANEL,
  SET_REOPEN_OVERVIEW
} from 'common/Map/MapDucks';
import {
  SET_LOAD_OVERVIEW,
  SET_LOAD_REPORTED_ISSUES
} from 'features/MapPage/MapPageDucks';

export const OverviewWidget = ({
  currentTool,
  activeSlideoutTool,
  isSlideoutOpen,
  sketchWidget,
  unselectWidget,
  measureWidget,
  className,
  mapType,
  handleOverviewClick,
}:IWidgetProps) => {
  let dispatch = useDispatch()

  useEffect(() => {
    // reset overview details and reported issues config load flags on unmount
    return () => {
      dispatch({
        type: SET_LOAD_OVERVIEW,
        loadOverview: true
      });
      dispatch({
        type: SET_LOAD_REPORTED_ISSUES,
        loadReportedIssues: true
      });
    }
  }, [])

  function handleClick() {
    sketchWidget?.cancel()
    unselectWidget?.cancel()
    measureWidget?.clear()
    dispatch({type: SET_ACTIVE_TOOL + mapType, tool: widgetTypes.overview})
    dispatch({type: SET_ACTIVE_SLIDEOUT_TOOL + mapType, tool: widgetTypes.overview})
    dispatch({type: SHOW_SIDE_PANEL + mapType})
    handleOverviewClick && handleOverviewClick(true);
  }

  return (
    <button
      title="Overview"
      id="overview-btn"
      className={
        "query-btn " +
        className
      }
      onClick={handleClick}
    >
      <VerticalSplitRoundedIcon/>
    </button>
  )
}
