import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isNull, isUndefined } from 'lodash';
import { loadModules } from 'esri-loader';
import DatePicker from 'common/DatePicker/DatePicker';
import { SET_MAP_NOTIFICATION } from 'common/Map/MapDucks';
import { convertDate } from 'utils/DateUtils';
import { getReducer } from 'utils/ReduxUtils';
import Dropdown, { blankLabel, emptyLabel } from 'common/Dropdown/Dropdown';
import {getFieldMaxLength} from 'utils/FieldsUtils';
import { refreshToken } from 'app/Auth/refreshToken';
import { getModifiedByAndOnData } from './ChildTable';
interface IParentTableCell {
  cell: any
}

const ParentTableCell = ({
  cell
}:IParentTableCell) => {
  const dispatch = useDispatch()
  const mapType = cell.column.mapType
  const attributes = cell.row.original
  const field = cell.column.field
  // Identifier Data
  const featureLayerId = cell.column.featureLayerId
  const objectId = attributes.OBJECTID
  const name = field.Name
  const aliasName = field.AliasName
  const value = cell.value?.DateTime || cell.value || (cell.value === 0 ? 0 : null)
  // Field Permissions Data
  const domain = field.Domain
  const maxLength = !isUndefined(getFieldMaxLength(field.FieldType)) ? getFieldMaxLength(field.FieldType) : field.Length
  const fieldType = field.FieldType
  const isEditable = field.IsEditable
  const isNullable = field.IsNullable
  // changeLocal function, passed down through 'react-table' columns
  const changeLocal = cell.column.changeLocal
  // ParentTable state, passed down through 'react-table' columns
  const originalCell = cell.column.originalCell
  const setOriginalCell = cell.column.setOriginalCell
  const editCell = cell.column.editCell
  const setEditCell = cell.column.setEditCell
  // Key-values from ParentTable states above
  const originalFeatureLayerId = originalCell.featureLayerId
  const originalObjectId = originalCell.objectId
  const originalName = originalCell.name
  const originalValue = originalCell.value
  const editFeatureLayerId = editCell.featureLayerId
  const editObjectId = editCell.objectId
  const editName = editCell.name
  const editValue = editCell.value

  const editableLayers = useSelector((state: any) => state.SettingsReducer.editableLayers);
  const editableTables = useSelector((state: any) => state.SettingsReducer.editableTables);
  const tableIds = useSelector((state: any) => state.SettingsReducer.tableMapServicesList).map((table:any) => table.id);
  const currentReducer = useSelector((state:any) => state[getReducer(mapType)]);
  const loggedInUser = useSelector((state: any) => state.LoginReducer.loggedInUser);
  const [failedEditFields, setFailedEditFields] = useState<any>([])


  const featureCheckResults = currentReducer.featureCheck;
  let currentFeatureCheckResult:boolean
  if (tableIds.includes(featureLayerId)) {
    currentFeatureCheckResult = true
  }
  else {
    currentFeatureCheckResult = featureCheckResults[featureLayerId] ?
    featureCheckResults[featureLayerId][objectId] : false
  }

  
  const layer = [...editableLayers, ...editableTables].find((feature:any) => feature.mapServerId === featureLayerId)
  let isLayerEditable = false
  if(layer) {
    isLayerEditable = true
  }

  // TODO: ParentTable and ChildTable editability have a lot of repeated code
  // Need to eventually consolidate them

  /**
   * Summary: On change handler for editable attribute fields
   *
   * @param featureLayerId layer/table id according to Lantern_Survey (MapServer)
   * @param objectId object ID field value of the cell
   * @param name name of the cell to store
   * @param value value of the cell to store
   * @param event true if value is from an event
   * @param initial true if storing the initial value
   */
   const storeFieldInfo = (featureLayerId: number | null, objectId: number | null, name: string | null, value: any, event: boolean, initial: boolean) => {
    const storedValue = event ? value.target.value : value;

    if (initial) {
      if (!(objectId === originalObjectId && name === originalName)) {
        setOriginalCell({featureLayerId: featureLayerId, objectId: objectId, name: name, value: storedValue})
        setEditCell({featureLayerId: null, objectId: null, name: null, value: null})
      }
    } else {
      setEditCell({featureLayerId: featureLayerId, objectId: objectId, name: name, value: storedValue})
    }
  }

  /**
   * Summary: Set the user feedback notification message after an Info Tool edit submission
   *
   * @param message message to show the user
   * @param success type of notification message
   */
   const infoEditMessage = (message: string, success: boolean) => {
    dispatch({
      type: SET_MAP_NOTIFICATION + mapType,
      message: message,
      success: success
    })
  }

  /**
   * Summary: On submit handler for editable attribute fields
   *
   * @param featureLayerId layer/table id according to Lantern_Survey (MapServer)
   * @param objectId id of that specific layer/table
   * @param name name of cell to apply edits to
   * @param aliasName alias name of cell to apply edits to
   * @param value new value to update cell by
   * @param event HTML event
   * @param isDate true if value is a date type
   */
  const applyEdit = (
    featureLayerId: number,
    objectId: number,
    fieldName: string,
    aliasName:string,
    value: any,
    event: any = null,
    isDate: boolean = false
  ) => {
    refreshToken()
    // prevent page refresh on submit
    if (event) {
      event.preventDefault();
    }

     /**
     * convert date back to epoch format
     * convert to empty label if value is undefined/empty 
     */
    let newValue = isDate ? Date.parse(value) : value;
    newValue = isUndefined(newValue) || isNull(newValue) ? emptyLabel: newValue;
    const featureUrl = [...editableLayers, ...editableTables].find((layer:any) => layer.mapServerId === featureLayerId);
    
    // check isNullable permission (checks for null, undefined, and empty)
    if (!isNullable && (isNull(newValue) || isUndefined(newValue) || newValue === "")) {
      infoEditMessage(`Setting of value for ${aliasName} cannot be null.`, false)
    } else {
      loadModules(['esri/layers/FeatureLayer'])
      .then(([FeatureLayer]) => {
        const featureLayer = new FeatureLayer({
          url: featureUrl.url
        })

        let editFeature: any;
        const query = featureLayer.createQuery();
        query.objectIds = [objectId];
        query.outFields = ["*"];

        featureLayer.queryFeatures(query).then((results: any) => {
          if (isEmpty(results.features)) {
            infoEditMessage(`The feature you are trying to edit cannot be found. Please verify the feature exists in ArcGIS.`, false)
            return;
          }
  
          editFeature = results.features[0];
          const fieldNameUnaltered = fieldName;
  
          editFeature.attributes[
            editFeature.attributes.hasOwnProperty(fieldName)
              ? fieldName
              : fieldName.toUpperCase()
          ] = newValue;
          
          // update the MODIFIEDBY and MODIFIEDON fields
          const {
            modifiedByFieldName,
            modifiedOnFieldName,
            newModifiedByValue,
            newModifiedOnDate,
          } = getModifiedByAndOnData(editFeature, fieldName, newValue, loggedInUser);
          
          // update the changes to be reflected in the Backend
          editFeature.attributes[modifiedByFieldName] = newModifiedByValue;
          editFeature.attributes[modifiedOnFieldName] = newModifiedOnDate;
  
          /** update the changes to be reflected in the Frontend */
          const updateChildTableLocally = () => {
            changeLocal(featureLayerId, objectId, fieldName, newValue);
            changeLocal(
              featureLayerId,
              objectId,
              modifiedByFieldName,
              newModifiedByValue
            );
            changeLocal(
              featureLayerId,
              objectId,
              modifiedOnFieldName,
              newModifiedOnDate
            );
          }
  
          featureLayer
            .applyEdits({ updateFeatures: [editFeature] })
            .then((editsResult: any) => {
              const error = editsResult.updateFeatureResults[0].error;
              if (!isNull(error)) {
                infoEditMessage(error.message, false);
              } else {
                updateChildTableLocally();
                  const successMsg = `Setting value for ${aliasName} was successful.`
                  infoEditMessage(successMsg, true);
                }
            })
            .catch(() => {
              if  (!failedEditFields.hasOwnProperty(fieldName)) {
                  setFailedEditFields((prev: any) => ([ ... prev, fieldName]))
              }
              else {
                infoEditMessage(`Setting value for ${aliasName} failed.`, false);
              }
            });
        })
      })
    }
  }

  /**
   * Summary: return the editable cell based on the cell's values and field persmissions
   */
  const renderEditable = () => {
    if (!isEmpty(domain)) {
      if (isNullable && !domain.Names.includes(blankLabel)) {
        domain.Names = [blankLabel, ...domain.Names]
        domain.Values = [null, ...domain.Values]
      }
      const dropdownItems = 
        domain.Names.map((_: any, index: number) => {
          if (isNull(domain.Values[index]) || isUndefined(domain.Values[index])) return emptyLabel
          return {
            label: domain.Names[index],
            value: domain.Values[index]
          }
        })
      return (
        <span onFocus={() => {
          // store originalCell values
          storeFieldInfo(featureLayerId, objectId, name, domain.Values[domain.Values.indexOf(value)], false, true)
          // reset editCell values
          storeFieldInfo(null, null, null, null, false, false)
        }}>
          <Dropdown
            theme={'parentTableCell'}
            size={'small'}
            items={dropdownItems}
            defaultValue={{
              value: domain.Values[domain.Values.indexOf(value)],
              label: domain.Names[domain.Values.indexOf(value)],
            }}
            handleChange={(value: any) => {
              // reset editCell values
              storeFieldInfo(null, null, null, null, false, false)
              applyEdit(featureLayerId, objectId, name, aliasName, domain.Values[domain.Values.indexOf(value)])
            }}
            isReset={!(featureLayerId === originalFeatureLayerId && objectId === originalObjectId)}
            resetValue={true}
          />
        </span>
      )
    } else if (fieldType === 5) {
      return (
        <span onFocus={() => {
          // store originalCell values
          storeFieldInfo(featureLayerId, objectId, name, value, false, true)
          // reset editCell values
          storeFieldInfo(null, null, null, null, false, false)
        }}>
          <DatePicker
            updateDate={(date: dateFns) => applyEdit(featureLayerId, objectId, name, aliasName, date, null, true)}
            defaultDate={value ? new Date(value) : undefined}
          />
        </span>
      )
    } else {
      return (
        <span onFocus={(event) => storeFieldInfo(featureLayerId, objectId, name, event, true, true)}>
          <form onSubmit={(event) => applyEdit(featureLayerId, objectId, name, aliasName, editValue, event, false)}>
            <input
              className={
                (featureLayerId === editFeatureLayerId && objectId === editObjectId && name === editName) ?
                  (originalValue === editValue ?
                    "input-selected" :
                    "input-editing"
                  ) :
                  undefined
              }
              value={
                (featureLayerId === editFeatureLayerId && objectId === editObjectId && name === editName) ?
                  editValue :
                  value || ""
              }
              maxLength={maxLength}
              onChange={(event) => storeFieldInfo(featureLayerId, objectId, name, event, true, false)}
            />
          </form>
        </span>
      )
    }
  }

  /**
   * Summary: return the non-editable cell based on the cell's values and field permissions
   */
   const renderNonEditable = () => {
    if (field.FieldType === 5) {
      const dateValue = value ? convertDate(value, false, false, 'date') : null

      return (
        <span title={dateValue}>
          {dateValue}
        </span>
      )
    } else {
      return (
        <span title={value}>
          {value}
        </span>
      )
    }
  }

  return (isEditable && currentFeatureCheckResult && isLayerEditable) ? renderEditable() : renderNonEditable()
}

export default ParentTableCell;
