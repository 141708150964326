import React, { useEffect } from "react";
import "./MapServicesSelection.scss";
import { useSelector, useDispatch } from "react-redux";
import PublicIcon from "@material-ui/icons/Public";
import { ReactComponent as LanternLogo } from "assets/LanternLoginLogo.svg";
import { history } from "index";
import Dropdown from "common/Dropdown/Dropdown";
import RowButton from "common/Button/RowButton";
import TextInput from "common/TextInput/TextInput";
import Button from "common/Button/Button";
import {
  getAvailableFolders,
  getAvailableMapServices,
  SET_SELECTED_MAP_FOLDER,
  SET_SELECTED_MAP_SERVICES,
} from "../LoginDucks";
import { RESET_MAP_GO_TO } from "common/Map/MapDucks";
import createLoading from "app/createLoading";
import Loading from "common/Loading/Loading";
import { getBaseMaps } from "common/Map/MapDucks";
import { SET_ALL_SAVED_QUERIES } from "features/MapPage/MapPageDucks";

import {
  loadInitalSettings,
} from '../../../app/SettingsDucks';

const MapServicesSelection = () => {
  const dispatch = useDispatch();
  let {
    rootUrl,
    selectedMapFolder,
    mapServices,
    mapServerFolder,
    loggedInUser,
    selectedMapService,
  } = useSelector((state: any) => state.LoginReducer);

  
  useEffect(() => {
    dispatch(loadInitalSettings())
  }, []);

  
  const loading = createLoading(["MAP_SERVICES", "MAP_SERVICES_FOLDER"]);
  const switchMapService = (mapService:any) => {
    dispatch({
      type: SET_SELECTED_MAP_SERVICES,
      url: mapService.url,
      featureUrl: mapService.featureUrl,
      label: mapService.label,
      decodedLabel: mapService.label.replace("%2F", "/"),
    });
    dispatch(getBaseMaps(mapService));
    dispatch({type: RESET_MAP_GO_TO});
    dispatch({type: SET_ALL_SAVED_QUERIES, savedQueries: {}})
    history.push("/Map");
  }

  useEffect(() => {
    const folderName = selectedMapFolder ? selectedMapFolder.label : '';
    dispatch(getAvailableMapServices(loggedInUser, folderName));
    dispatch(getAvailableFolders());
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <div id="mapservice-page-container">
      <LanternLogo className="lantern-logo" />
      <div className="mapservice-container">
        <span className="heading">Welcome, {loggedInUser}!</span>
        <div className="map-url input-label">
          Map Server
          <TextInput isDisabled={true} defaultValue={rootUrl} />
        </div>
        <div className="folder input-label">
          Folder
          <Dropdown
            className="input"
            placeHolder="Default"
            items={mapServerFolder}
            defaultValue={selectedMapFolder}
            handleChange={(e) => {
              const selectedFolder = mapServerFolder.find((item:any) => item && item.label === e)
              dispatch({
                type: SET_SELECTED_MAP_FOLDER,
                selectedMapFolder: selectedFolder,
              })
              const folderName = e.value !== '' ? e : '';
              dispatch(getAvailableMapServices(loggedInUser, folderName));
            }}
          />
        </div>
        <div className="mapservice-selection">
          Map Service Selection
          {mapServices.map((mapService: any, index: number) => (
            <RowButton
              key={index}
              id={`${mapService.label}_mapservice`}
              className={`mapservice-button`}
              onClick={() => switchMapService(mapService)}
            >
              <PublicIcon className="public-icon" />
              {mapService.label.replace("%2F", "/")}
            </RowButton>
          ))}
        </div>
        <Button
          boxColor="grey-border-dark-text"
          size="m"
          onClick={() => {
            history.push("/");
          }}
          className="logout-button"
        >
          Logout
        </Button>
      </div>
    </div>
  );
};

export default MapServicesSelection;
