import { loadModules } from "esri-loader";
import { reRegisterToken } from "utils/IdentityManagerUtils";

//RGB color values for fill symbols
export const RGBColors = {
  white: [255, 255, 255],
  cyan: [0, 255, 255],
  green: [79, 188, 143], // #4FBC8F
  HIGHLIGHT_COLOR: [0, 136, 193]  // #0088C1
}

/**
 * Summary: Draws any list of grid object onto the provided view, has default colors
 * but will be overwritten if provided in grids object
 * @param layer esri layer to be drawn on
 * @param grids list of polygon object that will be drawn on the provided view
 */
export const drawPolygon = (
  layer: any,
  grids:Array<any>,
  highlightPolygon: boolean = false
) => {
  // Remove all graphics if its a highlight layer to be overwritten by the new graphic
  if (highlightPolygon) layer?.graphics?.removeAll()
  reRegisterToken()
  loadModules(['esri/Graphic']).then(([Graphic]:any)=> {    
    for (var i = 0; i < grids.length; i++) {
      let fillSymbol = {
        type: "simple-fill",
        color: getRGBAColor(RGBColors.HIGHLIGHT_COLOR, 0.4),
        outline: {
          color: highlightPolygon ? RGBColors.HIGHLIGHT_COLOR : getRGBAColor(RGBColors.cyan, 0.5),
          width: 3.5
        }
      };

      //Change color to green if the feature is set in nearest features
      if (grids[i]?.isSet) {
        fillSymbol.color = getRGBAColor(RGBColors.green, 0.2)
        fillSymbol.outline.color = RGBColors.green
      }

      //Replace default color if provided in attributes
      fillSymbol.color = grids[i]?.attributes?.layerFillColor || 
        (highlightPolygon || grids[i]?.isSet ? fillSymbol.color : getRGBAColor(RGBColors.HIGHLIGHT_COLOR, 0))
      fillSymbol.outline.color = grids[i]?.attributes?.layerOutlineColor || fillSymbol.outline.color
      // Add the geometry and symbol to a new graphic
      if (grids[i]?.geometry) {
        var polygonGraphic = new Graphic({
          geometry: grids[i]?.geometry,
          symbol: fillSymbol
        });
        layer?.graphics?.push(polygonGraphic)
      }
    }
  })
}

/**
 *  Summary: Draws any list of polyline object onto the provdied view, has default
 * colors but will be overwritten if provided in polyLines object
 * @param layer Esri layer to be drawn on
 * @param polyLines list of polyline objects that will be drawn on the provided view
 */
export const drawPolyLine = (
  layer: any,
  polyLines: Array<any>,
  highlightPolyLine: boolean = false
) => {
  // Remove all graphics if its a highlight layer to be overwritten by the new graphic
  if (highlightPolyLine) layer?.graphics?.removeAll()
  reRegisterToken()
  loadModules(['esri/Graphic']).then(([Graphic]:any)=> {
    for (let i = 0; i < polyLines.length; i++) {
      let polylineSymbol = {
        type: "simple-line",  // autocasts as SimpleLineSymbol()
        color: highlightPolyLine ? RGBColors.HIGHLIGHT_COLOR : RGBColors.cyan,
        width: highlightPolyLine ? 4 : 2
      };

      //Change color to green if the feature is set in nearest features
      if (polyLines[i]?.isSet) {
        polylineSymbol.color = RGBColors.green
      }

      //Replace default color if provided in attributes
      polylineSymbol.color = polyLines[i]?.attributes.layerFillColor || polylineSymbol.color

      // Add the geometry and symbol to a new graphic
      if (polyLines[i]?.geometry) {
        let polylineGraphic = new Graphic({
          geometry: polyLines[i]?.geometry,
          symbol: polylineSymbol
        });
        layer?.graphics?.push(polylineGraphic)
      }
    }
  })
}

/**
 * Summary: Draws any list of point object onto the provided view, has default colors
 * but will be overwritten if provided in grids object
 *
 * @param layer Esri layer to be drawn on
 * @param points list of point objects to be drawn on the provided view
 */
export const drawPoints = (
  layer: any,
  points:Array<any>,
  highlightPoint: boolean = false
) => {
  // Remove all graphics if its a highlight layer to be overwritten by the new graphic
  if (highlightPoint) layer?.graphics?.removeAll()
  reRegisterToken()
  loadModules(['esri/Graphic']).then(([Graphic]:any)=> {
    for (let i = 0; i < points.length; i++) {
      //drawing points start here
      let pointMarkerSymbol = {
        type: "simple-marker",
        color: highlightPoint ? RGBColors.HIGHLIGHT_COLOR : RGBColors.cyan,
        outline: {
          color: RGBColors.white,
          width: 1
        }
      };

      //Change color to green if the feature is set in nearest features
      if (points[i]?.isSet) {
        pointMarkerSymbol.color = RGBColors.green
        pointMarkerSymbol.outline.color = RGBColors.green
      }

      //Replace default color if provided in attributes
      pointMarkerSymbol.color = points[i]?.attributes.layerFillColor || pointMarkerSymbol.color
      pointMarkerSymbol.outline.color = points[i]?.attributes.layerOutlineColor || pointMarkerSymbol.color

      // Add the geometry and symbol to a new graphic
      if (points[i]?.geometry) {
        let pointGraphic = new Graphic({
          geometry: points[i]?.geometry,
          symbol: pointMarkerSymbol
        });
        layer?.graphics?.push(pointGraphic)
      }
    }
  })
}

/**
 * Summary: Combine RGB color number array with transparency value to get RGBA color
 * @param color RGB color number array ([r, g, b])
 * @param transparency transparency/alpha channel value
 * @returns RGBA color number array (RGB + transparency value) ([r, g, b, a])
 */
export const getRGBAColor = (color: Array<number>, transparency: number) => {
  return color.concat([transparency])
}
