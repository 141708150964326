import React from 'react';
import './ActionItems.scss';
import { surveyJobCountTypes } from 'features/MapPage/MapPageDucks';

interface IActionItems {
  overviewDetails: any
  mapType: string
}

const ActionItems = ({
  overviewDetails,
  mapType
}:IActionItems) => {
  return (
    <div className="action-items-container">
      <div className="overview-section-title">
        Action Items
      </div>
      <div className="items-list-container">
        <div className="item-body-container">
          <div className="item-number past-compliance">
            {overviewDetails[surveyJobCountTypes.pastCompliance]}
          </div>
          <div className="item-title">
            Past
            <br/>
            Compliance
          </div>
        </div>
        <div className="item-body-container">
          <div className="item-number near-compliance">
            {overviewDetails[surveyJobCountTypes.nearCompliance]}
          </div>
          <div className="item-title">
            Near
            <br/>
            Compliance
          </div>
        </div>
        <div className="item-body-container">
          <div className="item-number pending-review">
            {overviewDetails[surveyJobCountTypes.pendingReview]}
          </div>
          <div className="item-title">
            Pending
            <br/>
            Review
          </div>
        </div>
      </div>
    </div>
  )
}

export default ActionItems
