import React , { useState, useEffect } from 'react';
import { OptionTypeBase } from 'react-select';
import WindowedSelect from 'react-windowed-select';
import { isEqual, isUndefined } from 'lodash';
import { colors, styles } from './DropdownStyles';

interface IDropdownProp {
  items: Array<string | number | Object>
  handleChange: (e : any)=>void
  placeHolder?: string
  id?: string
  className?: string
  isSearchable?: boolean
  isClearable?: boolean
  defaultValue?: Object
  isReset?:boolean
  forceValue?: string | number | null
  forceLabel?: string
  resetValue?: any
  fixedValue?: Object
  borderless?: boolean
  resetToDefault?: boolean
  resetToFirstItem?: boolean
  size?: 'small'
  theme?: 'dateFilter' | 'parentTableCell' | 'matchTextbox' | 'search'
  indent?: boolean
  onMenuOpen?: (e : any)=>void
  noOptionsMessage?: (e : any)=>void
  onClear?: ()=>void
  onReset?: () => void
  onInputChange?: Function
  allowOptionsTextWrap?: Boolean
}

export const blankLabel = ' ';
export const emptyLabel = '';

const Dropdown: React.FunctionComponent<IDropdownProp> = (props) => {

  const [value, setValue] = useState<any>(null);
  const [defaultValue, setDefaultValue] = useState<any>(props.defaultValue);
  let items = props?.items
  //sort items alphabetically 
  items = items.concat().sort()
  const includesEmptyBlank = items.some(item =>
    typeof(item) === "string" && [blankLabel, emptyLabel].includes(item)
  )

  /**
   * summary: updates the selection value
   *
   * @param selectedOption
   */
  const handleChange:Function = (selectedOption: any, triggeredAction: any) => {
    setValue(selectedOption);
    props.handleChange(selectedOption?.value);
    if (props.onClear && triggeredAction.action === "clear") {
      props.onClear()
    }
  }

  // styles to add to customStyles
  let addedStyles: any = {};
  // all possible styles and their specific triggers
  const styleOptions = {
    fixedValueStyle: !isUndefined(props.fixedValue),
    borderlessStyle: props?.borderless === true,
    sizeSmallStyle: props?.size === 'small',
    dateFilterStyle: props?.theme === 'dateFilter',
    parentTableCellStyle: props?.theme === 'parentTableCell',
    matchTextboxStyle: props?.theme === 'matchTextbox',
    searchStyle: props?.theme === 'search'
  };
  // append stylings of a styleOption if styleTrigger === true
  Object.entries(styleOptions).forEach(([styleOption, styleTrigger]: [string, boolean]) => {
    if (styleTrigger) {
      Object.entries(styles[styleOption]).forEach(([styleObject, styleValue]: [string, any]) => {
        addedStyles[styleObject] = {...addedStyles[styleObject], ...styleValue}
      })
    }
  })

  // base Lantern styling — using material ui's api (see: https://react-select.com/styles#style-object)
  var customStyles = {
    container: (base: any) => ({
      ...base,
      ...addedStyles.container
    }),
    control: (base: any) => ({
      ...base,
      // This is the same as the text-input component's border style
      border: `1px solid ${colors.secondaryGrey}`,
      borderRadius: '2px',
      // This line disable the default blue border
      boxShadow: '0',
      '&:hover': {
        border: ''
      },
      padding: '0px 4px',
      minHeight: '',
      ...addedStyles?.control
    }),
    singleValue: (base: any) => ({
      ...base,
      maxWidth: 'calc(100% - 40px)',
      singleValue: colors.black,
      ...addedStyles?.singleValue
    }),
    input: (base: any) => ({
      ...base,
      ...addedStyles?.input
    }),
    menu: (base: any) => ({
      ...base,
      ...addedStyles?.menu
    }),
    option: (base: any, {isFocused, data}: OptionTypeBase) =>
    ({
      ...base,
      minHeight: includesEmptyBlank ? "2rem" : "",
      background: isFocused ? colors.secondaryBlue : colors.white,
      color: isFocused ? colors.white : colors.black,
      paddingLeft: data?.indent ? "2.25em" : "",
      ...addedStyles?.option,
      // text wrap configuration
      overflowWrap: props.allowOptionsTextWrap ? "break-word" : "",
      overflow: props.allowOptionsTextWrap ? "" : "hidden",
      textOverflow: props.allowOptionsTextWrap ? "" : "ellipsis",
      whiteSpace: props.allowOptionsTextWrap ? "" : "nowrap",
    }),
    dropdownIndicator: (base: any) => ({
      padding: '1vh',
      ...addedStyles?.dropdownIndicator
    }),
    indicatorSeparator: () => ({
      display: 'none',
      ...addedStyles?.indicatorSeparator
    }),
    valueContainer: () => ({
      position: 'static',
      ...addedStyles?.valueContainer
    })
  };

  /**
   * summary: clear the selection field when reset is updated
   */
  useEffect(() => {
    if(props.isReset === true){
      if (props.resetToDefault) {
        setValue(props.defaultValue)
      } else if (props.resetToFirstItem) {
        setValue({value:items[0], label:items[0]})
      } else if (props.forceLabel && props.forceValue){
        setValue({value:props.forceValue, label:props.forceLabel})
      } else if (props.forceValue) {
        setValue({value:props.forceValue, label:props.forceValue})
      } else if (props.resetValue) {
        setValue(null)
      } else {
        setValue([])
      }
      if (!isUndefined(props.onReset)) {
        props.onReset()
      }
    }
  },[props.isReset]);

  useEffect(() => {
    // set the value to be null every time the default value is being changed
    if (!isEqual(props.defaultValue, defaultValue)) {
      setValue(null)
      setDefaultValue(props.defaultValue)
    }
  }, [props.defaultValue])

  /** Summary: set the client-facing value for any empty-labelled value to appear blank */
  const getValue = () => {
    let originalValue = 
      props.fixedValue 
      || value
      || props.defaultValue
      || emptyLabel;

    if (originalValue === blankLabel) {
      originalValue = " ";
    } else if (originalValue?.label === blankLabel) {
      originalValue.label = " ";
    }
    // if there are no items, the default value should be nothing
    if (items.length === 0) {
      originalValue = " "
    }
    return originalValue ?? emptyLabel
  }

  return(
    <div className = {props.className}>
      <WindowedSelect
        id = {props.id}
        // if there is no fixed label, use user selection
        // if a value is not selected, use the default value
        value={getValue()}
        // if value is not selected and no default value
        // display placeHolder text
        placeholder={props.placeHolder}
        onChange={handleChange}
        isSearchable={props.isSearchable}
        isClearable={props.isClearable}
        styles={customStyles}
        classNamePrefix="react-windowed-select"
        options={
          items?.map((item:any) => {
            if (!(isUndefined(item) || item === null)) {
              return {
                value: item.value || item,
                label: item.label || item,
                indent: item.indent || false
              }
            } else {
              return {
                label: blankLabel,
                value: blankLabel
              }
            }
          }) ?? []
        }
        onMenuOpen={props.onMenuOpen}
        noOptionsMessage={props.noOptionsMessage}
        onInputChange={props.onInputChange}
      />
    </div>
  );
}

Dropdown.defaultProps = {
  placeHolder: "Select an option",
  borderless: false,
  isSearchable: true,
  className: ""
}

export default Dropdown;
