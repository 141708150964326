import React, { ReactElement } from 'react';
import FilterList from '@material-ui/icons/FilterList';
import './Filterbtn.scss'

interface IFilterbtnProp {
  label?:string
  renderFilterBtn?:boolean
  className?: string
  actionBar?:ReactElement | Function
  overrideDefaultToggle?: boolean
}

const Filterbtn: React.FunctionComponent<IFilterbtnProp> = (props) => {

  const [isToggled, setToggle] = React.useState<boolean>(props.overrideDefaultToggle ?? false);

  return(
    <div id="filter-container">
      <div className="btn-container">
        { // render filter button if label is not none
          props.renderFilterBtn &&
          <button
            className={
              props.className+
              " filter-btn " +
              (isToggled?"disabled":"")
            }
            onClick={() => setToggle(!isToggled)}
          >
            {props.label}
          <FilterList
            className={
              "filter-icon "+
              (isToggled?"disabled":"")}
          />
          </button>
        }
        <div className="action-btn">
          {props.actionBar}
        </div>
      </div>
      <div className={
        "filter-children "+
        (isToggled?"shown":"")
      }>
        {props.children}
      </div>

    </div>
  )
}

Filterbtn.defaultProps = {
  className:"",
}

export default Filterbtn;
