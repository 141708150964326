import React, { useEffect, ReactElement, forwardRef, useImperativeHandle } from 'react';
import './CheckboxTable.scss';
import '../TableUtils/TableThemes/MainThemes.scss'
import isEmpty from 'lodash/isEmpty'
import {
  useTable,
  useRowSelect,
  useSortBy,
  usePagination,
  useFilters
} from 'react-table';
import cloneDeep from 'lodash/cloneDeep';
import IndeterminateCheckbox from '../TableComponents/IndeterminateCheckBox';
import {SelectFilter,filterSelectValue} from '../TableUtils/FilterTypes/TableSelectFilter';
import {FilterComponent} from '../TableComponents/FilterComponent';
import {TableHeader} from '../TableComponents/TableHeader';
import {Footer} from '../TableUtils/Footer';
import RowComponents from '../TableComponents/RowComponent';
import ParentTableCell from 'common/Map/Widgets/InfoWidget/Table/ParentTableCell';
import { isNil, isUndefined } from 'lodash';
import Loading from 'common/Loading/Loading';

/**
 * Summary: Renders a table that has sorting, pagination and row selection
 *  attached to it
 * Note: It is highly recommended to review the storybook to
 * understand the structure needed for columns and data
 * @param columns
 * Table header names and key refering to data
 * also how the rows should be rendered
 * @param data
 * Consists of the data needed to populate the table rows,
 * has the cell value and key that refers the column
 * @param children
 * Any element can be placed after the table
 * with the props of selectedrows and selectedrowsID
 * @param filterBtnLabel
 *  Used to add a label on the filter button
 * @param actionButtn
 * A button can be added beside the filter button to allow for any actions
 * @param enableRemoteOperations
 * A boolean value for enabling the server side pagination
 * @param totalCount
 * An integer value representing the total number of rows in the table
 * @param pageChangeCallback
 * Function: function to handle the page change action callbacks and supply new data to the table
 * @param filterChangeCallback
 * Function: function to handle the filter changes callback
 * @param sortChangeCallback
 * Function: function to handle the sort changes callback
 * @param resetCallback
 * Function: function to handle the reset filters callback
 * @param idColumn
 * string: A unique id for the grid
 * @param disableSortReset
 * boolean: disable the sort rest
 * @param ref
 * A grid reference element for controlling the component reference
 * @param appliedFilterValues
 * array: initial preset filters in the filters panel
 * @param appliedSortedValues
 * array: initial preset sort for the column
 * @param autoResetPage
 * boolean: pagination will be reset to the first page when page-altering state changes eg. data is updated, filters change, grouping changes, etc.
 * @return Table that has sorting, pagination, row selection and filtering
 */

interface ITableProps {
  columns:any,
  data:any,
  preFilteredRows?:any,
  filterBtnLabel?:string,
  renderFilterBtn?:boolean,
  customPageSize?:number,
  children?:any,
  actionBar?:ReactElement,
  onRowClick?:Function,
  onPageIndexChange?:Function,
  onItemSelectionChange?:Function,
  defaultColumnOverride?:any,
  tableColorTheme?:'SteelGreyBoxRow'|'GreyTopBorder'|'SteelGreyTopBorder'|'',
  className?:String,
  setNumSelections?:Function,
  setSelectionIndex?:Function,
  setSelectedRows?:Function,
  resetSelect?:boolean,
  enableRemoteOperations?: boolean,
  totalCount?: number,
  pageChangeCallback?:any,
  filterChangeCallback?:any,
  sortChangeCallback?:any,
  resetCallback?:any,
  idColumn?: string,
  disableSortReset?: boolean,
  ref?: any,
  appliedFilterValues?: any,
  appliedSortedValues?: any,
  autoResetPage?: boolean,
  isLoading?: boolean
}

const Table = forwardRef(({
  columns,
  data,
  preFilteredRows = [],
  children,
  filterBtnLabel,
  renderFilterBtn = true,
  customPageSize = 20,
  actionBar,
  onRowClick,
  onPageIndexChange,
  onItemSelectionChange,
  defaultColumnOverride,
  tableColorTheme = '',
  className,
  setNumSelections,
  setSelectionIndex,
  setSelectedRows,
  resetSelect = false,
  enableRemoteOperations,
  totalCount,
  pageChangeCallback,
  filterChangeCallback,
  sortChangeCallback,
  resetCallback,
  disableSortReset,
  idColumn = '',
  appliedFilterValues,
  appliedSortedValues,
  autoResetPage = false,
  isLoading
  }:any, ref:any) => {
  //The functionality of the reset all button is a hack as it
  // toggles a state to run a function in dropdown that
  //clears the state in dropdown
  const [isReset,setisReset] = React.useState(false)
  useEffect(()=>{
    setisReset(false)
  },[isReset])
  // If there is no filter options given from the parent and filtering is not disabled
  // filtering will default to this
  const defaultColumn = Object.assign({Cell: ParentTableCell,
    ...React.useMemo(
    () => ({
      Filter: SelectFilter,
      filter: filterSelectValue,
      isReset: isReset,
      filterChangeCB: filterChangeCallback,
      sortChangeCB: sortChangeCallback,
      preFilterRows: preFilteredRows,
      appliedFilterValues: appliedFilterValues,
      appliedSortedValues: appliedSortedValues
    }),[isReset, preFilteredRows]
  )}, defaultColumnOverride)

  //this reducer helps to mutate the state for certain actions
  const reducer = (newState:any, action:any) => {
    if (action.type === 'deselectAllRows') {
      return { ...newState, selectedRowIds: {} };
    }
    else if (action.type === 'clearSorting') {
      return { ...newState, sortBy: [] };
    }
    return newState; 
  }

  let initialConfig: any = {
    columns,
    data,
    initialState: {
      pageIndex: 0,
      pageSize:customPageSize
    },
    defaultColumn,
    filterSelectValue,
    autoResetFilters:false,
    autoResetPage:autoResetPage,
    autoResetSelectedRows:resetSelect,
    autoResetSelectedCell: false,
    autoResetSelectedColumn: false,
    autoResetSortBy: !disableSortReset,
    //react table expects us to set the totalCount value in case of server side pagination
    pageCount: !isNil(totalCount) ? totalCount: -1,
    manualPagination: enableRemoteOperations ? true : false,
    manualFilters: enableRemoteOperations ? true : false,
    manualSortBy: enableRemoteOperations ? true : false,
    stateReducer: reducer
  };
  if(!isUndefined(appliedSortedValues)){
    initialConfig.initialState.sortBy = appliedSortedValues
  }
  //if the consumer component sets unique rowID,
  //setting it to uniquely identifying the rows.
  if(idColumn !== '')
    initialConfig.getRowId = (row: any) => row[idColumn] ;
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    toggleAllRowsSelected,
    headerGroups,
    rows,
    page,
    prepareRow,
    canNextPage,
    gotoPage,
    canPreviousPage,
    pageCount,
    previousPage,
    nextPage,
    setAllFilters,
    selectedFlatRows,
    state: {pageIndex, pageSize, selectedRowIds},
    dispatch
  } = useTable(
    initialConfig,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks:any) => {
      hooks.visibleColumns.push((columns:any) => [
        {
          id: 'selection',
          disableFilters:true,
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllPageRowsSelectedProps, getToggleAllRowsSelectedProps }:any) => {
            //enabling the row selection only for the current page in case of server side pagination
            const toggleRowSelectionProps = enableRemoteOperations ? getToggleAllPageRowsSelectedProps(): getToggleAllRowsSelectedProps();
            return <IndeterminateCheckbox {...toggleRowSelectionProps} />
          },
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }:any) => {  
            return <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
          },
        },
        ...columns,
      ])
    }
  )

  useEffect(() => {
    setAllFilters([])
  }, [])
  
  // filterListOrdered is used to reorder the filters above a table component
  /** Summary: Deep copies headerGroup Object and sorts it based on filterOrder property. */
  // The use of Deep copying is used to de-couple the order of the filters and table headers.
  const filterListOrdered = cloneDeep(headerGroups[0].headers);
  filterListOrdered.sort(function(a:any, b:any) {
    return parseFloat(a.filterOrder) - parseFloat(b.filterOrder);
  });

  //reset the page to 1 if number of pages change
  useEffect(()=>gotoPage(0),[gotoPage, pageCount])
  
  useEffect(()=>{
    if(setSelectedRows && selectedRowIds!==null){
    setSelectedRows((Object.keys(selectedRowIds).map((id) => parseInt(id))))
    }
  },[selectedRowIds]) 

  useEffect(()=> {
    if (setNumSelections && setSelectionIndex && selectedRowIds !== null) {
      setNumSelections(Object.keys(selectedRowIds).length)
      setSelectionIndex(Object.keys(selectedRowIds).map((id) => parseInt(id)))
    } 
  }, [selectedRowIds])

  //this function helps to provide a handler for calling the
  //reset checkboxes selection on the grid state
  useImperativeHandle(
    ref,
    () => ({
      resetSelectedRows: () => {
        toggleAllRowsSelected(false); 
        dispatch({ type: 'deselectAllRows' });
      },
      //this function helps to clear the sorting on the table
      resetSorting: () => {
        dispatch({ type: 'clearSorting' });
      },
    }),
    []
  )

  // Render the UI for your table
  return (
    <div id="checkbox-table-container">
      {isLoading && 
        <div className='overlay'>
          <Loading />
        </div>
      }
      {/*Filter component*/}
      {filterBtnLabel === '' ?
        '':
        <FilterComponent
          {...{
            data,
            selectedRowIds,
            filterBtnLabel,
            renderFilterBtn,
            getTableBodyProps,
            actionBar,
            headerGroups,
            filterListOrdered,
            setisReset,
            setAllFilters,
            toggleAllRowsSelected,
            enableRemoteOperations,
            resetCallback
          }}
        />}
      {/*Table Component*/}
      {(children && !isEmpty(selectedRowIds)) &&
        React.cloneElement(children,
          {
            data: data,
            selectedIds: selectedRowIds,
            selectedRows: selectedFlatRows,
            toggleRows: toggleAllRowsSelected
          }
        )
      }
      {/* checkboxTable */}
      <table id={tableColorTheme} className= {`table ${className}`} {...getTableProps()}>
        <TableHeader {...{headerGroups}}/>
        <tbody className="table-body" {...getTableBodyProps()}>
          <RowComponents
            {...{
              page,
              prepareRow,
              headerGroups,
              onRowClick
            }}
          />
        </tbody>
      </table>
      <Footer
        {...{
          pageIndex,
          pageSize,
          canNextPage,
          rows,
          gotoPage,
          canPreviousPage,
          pageCount,
          previousPage,
          nextPage,
          onPageIndexChange,
          enableRemoteOperations,
          totalCount,
          pageChangeCallback
        }}
      />
    </div>
  )
})

export default Table
