import React, { ReactChild } from 'react';
import { Redirect, Route } from 'react-router-dom';

interface IRouteWrapper {
  children: ReactChild,
  isAuthenticated?: boolean,
  isRendered?: boolean,
  redirectString?: string,
  path?: string
}

const RouteWrapper = ({
  children,
  isAuthenticated = true,
  isRendered = true,
  redirectString = '/',
  ...rest }:IRouteWrapper) =>
{
  if(isRendered) {
    return (
    <Route
      {...rest}
      render = {() =>
        isAuthenticated ?
          children :
          <Redirect to = {{pathname: redirectString}}/>
      }
    />
    );
  }
  else {
    return <Redirect to = {{pathname: redirectString}}/>
  }
}

export default RouteWrapper
