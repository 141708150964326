import { HttpRequest } from "../httpRequest";
import { LEGACY_API_URL } from "../constants";
import { formDataFormat } from "api/formDataUtils";
import ashxBaseUrl from ".";
import { refreshTokenIfExpired } from "utils/IdentityManagerUtils";

export const createSurveyArea = async (requestBody: Object) => {
  //refresh the ArcGIS Token if expired
  await refreshTokenIfExpired();
  const createMapConfgurationUrl = `/Editing.ashx/tables/commit`;
  return await ashxBaseUrl.post(createMapConfgurationUrl, requestBody);
};