import {
  RESET_SEARCH,
  RESET_SEARCH_SEEN,
  SET_MAP_NOTIFICATION,
  SET_SEARCH_GEOMETRY,
  searchLayerFields
} from "common/Map/MapDucks"
import { getLayerOptions, getFieldOptions } from "utils/GridUtils"

/**
 * Summary: function to search by layers
 *
 * @param e value being searched against
 * @param layer layer being searched against
 * @param field field being searched against
 * @param mapType map we are searching on
 * @param dispatch dispatch from useDispatch()
 */
export function layerSearchSubmit(
  e:any,
  layer:any,
  field:any,
  mapType:string,
  dispatch:any
) {
  dispatch({type:RESET_SEARCH + mapType})
  dispatch({type:RESET_SEARCH_SEEN + mapType})
  dispatch({type:SET_MAP_NOTIFICATION + mapType, message: ""})
  dispatch({type:SET_SEARCH_GEOMETRY + mapType, grids: [], lines: [], points: []})

  if (layer === 'All') {
    getLayerOptions().forEach((layer:any, index:number) => {
      dispatch(searchLayerFields(layer.value.Id, layer.value.Fields, e, mapType, true, index === 0))
    })
  }
  else if (field === 'All') {
    const fields = getFieldOptions(layer).map((field:any) => {
      return field.value
    })
    dispatch(searchLayerFields(layer.Id, fields, e, mapType))
  }
  else {
    dispatch(searchLayerFields(layer.Id, field, e, mapType))
  }
}
