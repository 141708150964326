import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadModules } from 'esri-loader';
import { isEmpty, isNull } from 'lodash';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import { LanternTools } from 'app/constants';
import { widgetTypes, IWidgetProps, layerType } from '../../MapWidgetLayer';
import { geometryType } from 'common/Map/SelectFeatures';
import NearestFeature from './NearestFeature';
import {
  mapPageMapType,
  checkForEditProtection,
  SET_GEOMETRY,
  SET_SEARCH_GEOMETRY,
  SET_MAP_NOTIFICATION,
  SET_ACTIVE_TOOL,
  SET_ACTIVE_SLIDEOUT_TOOL,
  CLOSE_SIDE_PANEL,
  SHOW_SIDE_PANEL,
  ERROR
} from '../../MapDucks';
import { CLEAR_NEAREST_FEATURE, CLEAR_NEAREST_FEATURE_HIGHLIGHT } from "features/MapPage/MapPageDucks"
import { isToolAvailable , reRegisterToken, refreshTokenIfExpired } from 'utils/IdentityManagerUtils';
import { fieldType, generateID } from 'utils/FieldsUtils';
import { getReducer } from 'utils/ReduxUtils';
import { autopopulateValues } from './EditUtils';
import './EditWidget.scss';

enum editValueType {
  create = 1,
  edit = 3
}

export enum autoValueType {
  username = 'username',
  timestamp = 'timestamp',
  guid = 'guid',
  latitude = 'latitude',
  longitude = 'longitude'
}

export const EditWidget = ({
  currentTool,
  activeSlideoutTool,
  isSlideoutOpen,
  sketchWidget,
  unselectWidget,
  measureWidget,
  editWidget,
  className,
  layers,
  mapType,
  toggleSidePanel
}:IWidgetProps) => {
  const roleId = useSelector((state:any) => state.LoginReducer.selectedUserRole)
  const settingsLayers = useSelector((state:any) => state.SettingsReducer.settings.Layers)
  const securityRoles = useSelector((state:any) => state.SettingsReducer.securityRoles)
  const fieldAutoValues = useSelector((state:any) => state.SettingsReducer.autoValues)
  const nearestFeatureSettings = useSelector((state:any) => state.SettingsReducer.nearestFeatureSettings)
  const loggedInUser = useSelector((state:any) => state.LoginReducer.loggedInUser)
  const currentReducer = useSelector((state:any) => state[getReducer(mapType)])
  const [noActiveWorkflow, setNoActiveWorkflow] = useState<boolean>(true)
  const [showNearestFeatModal, setShowNearestFeatModal] = useState<boolean>(false)
  const [showNearestFeatPanel, setShowNearestFeatPanel] = useState<boolean>(false)
  const [nearestFeatStepDone, setNearestFeatStepDone] = useState<boolean>(false)
  const [chosenFeatures, setChosenFeatures] = useState<Array<any>>([])
  const [nearestFeatureLayerIds, setNearestFeatureLayerIds] = useState<{[index: number]:any}>({})
  //Dictionary of feature field names to autovalue type (view autoValueType enum)
  const [autoValues, setAutoValues] = useState<{[index: string]:any}>({})
  const originalAttributesRef = useRef<any>()
  const applyAutoValuesRef = useRef<any>()
  const originalCreateGeometryRef = useRef<any>()
  const dispatch = useDispatch()
  const titleObserver = new MutationObserver(() => {
    replaceUntitledFeature()
  });

  useEffect(() => {
    if (editWidget) {
      reRegisterToken().catch((err: any) => console.error(err));
      loadModules(["esri/core/watchUtils"])
      .then(([watchUtils]: any) => {
        replaceDeleteWarningMessage()
        editWidget.viewModel.watch("state", async (state: any) => {
          await refreshTokenIfExpired();
          setNoActiveWorkflow(isNull(editWidget?.activeWorkflow))
          if (editWidget?.activeWorkflow) {
            setTimeout(() => {
              setSubmitButtonListeners(watchUtils)
              if (editWidget.activeWorkflow.stepId === 'editing-new-feature' || 
              editWidget.activeWorkflow.stepId === 'editing-existing-feature') {
                replaceDateFormatHints()
                replaceOutsideRangeErrorMessage()
                markMandatoryFields()
              }
              switch (editWidget.activeWorkflow.stepId) {
                case 'ready': {
                  setNearestFeatStepDone(false)
                  clearNearestFeatureHighlights()
                  break;
                }
                case 'editing-new-feature': {
                  originalAttributesRef.current = editWidget._featureForm.feature.attributes
                  replaceUntitledFeature()
                  setAutoValues(getAutoValueFields(editWidget._featureForm.feature, editValueType.create))
                  openNearestFeatureModalIfConfigured(editValueType.create)
                  break;
                }
                case 'editing-existing-feature': {
                  originalAttributesRef.current = editWidget._featureForm.feature.attributes
                  replaceUntitledFeature()
                  setAutoValues(getAutoValueFields(editWidget._featureForm.feature, editValueType.edit))
                  nearestFeatureEditCheck()
                  .catch((err:any) => {
                    dispatch({
                      type: ERROR,
                      error: {
                        message: err.message,
                        type: "editProtection"
                      }
                    })
                  })
                  break;
                }
                case 'awaiting-feature-to-create':
                case 'awaiting-feature-creation-info':
                case 'awaiting-feature-to-update': {
                  setNearestFeatStepDone(false)
                  clearNearestFeatureHighlights()
                  //Configure editor widget fields 
                  configFields(watchUtils, editWidget.activeWorkflow.stepId)
                  break;
                }
                case 'awaiting-update-feature-candidate': {
                  replaceNullMultipleSelectionLabels()
                  isAllowedToEdit(editWidget, mapType, dispatch)
                  .catch((err:any) => {
                    dispatch({
                      type: ERROR,
                      error: {
                        message: err.message,
                        type: "editProtection"
                      }
                    })
                  })
                  setNearestFeatStepDone(false)
                  clearNearestFeatureHighlights()
                  break;
                }
              }
            }, 25)
          }
        })
      })

      reRegisterToken().catch((err: any) => console.error(err));
    }
  }, [editWidget])

  /**
 * Replace 'Untitled feature ' title in editor widget with the name of the layer
 * */
  function replaceUntitledFeature() {
    let editTitle = editWidget.container.getElementsByClassName(
      "esri-editor__title esri-heading"
    )[0];
    if (editTitle.textContent.startsWith("Untitled Feature ")) {
      editTitle.textContent = editTitle.textContent.replace(
        "Untitled Feature",
        editWidget._featureForm.layer.sourceJSON.name
      );
    }
    titleObserver.observe(editTitle, { childList: true });
  }

  /**
   * Check if feature is edit protected and open nearest feature modal if not (and configured)
   */
  async function nearestFeatureEditCheck() {
    if (await isAllowedToEdit(editWidget, mapType, dispatch)) {
      openNearestFeatureModalIfConfigured(editValueType.edit)
    }
  }

  /**
   * Open the nearest feature popup if the layer has been configured for nearest
   * features and the nearest features step has not yet been completed.
   * @param workflow current edit widget workflow (create or eidt)
   */
  const openNearestFeatureModalIfConfigured = (workflow:editValueType) => {
    if (!nearestFeatStepDone) {
      let featureLayerIds:{[index: number]:any} = {}
      nearestFeatureSettings.forEach((setting:any) => {
        if (setting.layerId in featureLayerIds) {
          featureLayerIds[setting.layerId].push(setting.sourceLayerId)
        }
        else {
          featureLayerIds[setting.layerId] = [setting.sourceLayerId]
        }
      })
      setNearestFeatureLayerIds(featureLayerIds)
      if (editWidget._featureForm.layer.layerId in featureLayerIds) {
        setShowNearestFeatModal(true)
      }
      else {
        //Apply autovalues now if nearest features is not configured
        autopopulateValues(
          editWidget, 
          getAutoValueFields(editWidget._featureForm.feature, workflow), 
          applyAutoValuesRef
        )
      }
    }
  }

  function clearNearestFeatureHighlights() {
    if (chosenFeatures) {
      setChosenFeatures([])
      dispatch({type: CLEAR_NEAREST_FEATURE})
      dispatch({type: CLEAR_NEAREST_FEATURE_HIGHLIGHT})
    }
  }

  function handleClick() {
    if (!showNearestFeatPanel) {
      if (currentTool === widgetTypes.edit) {
        closeWidget();
      } else {
        toggleSidePanel(false);
        sketchWidget?.cancel();
        unselectWidget?.cancel();
        measureWidget?.clear();
        dispatch({ type: CLOSE_SIDE_PANEL + mapType });
        dispatch({ type: SET_ACTIVE_TOOL + mapType, tool: widgetTypes.edit });
        dispatch({
          type: SET_ACTIVE_SLIDEOUT_TOOL + mapType,
          tool: widgetTypes.edit,
        });
      }
    }
  }

  function closeWidget() {
    if (currentReducer.slideOutInfo.reopenOverview && mapType === mapPageMapType) {
      toggleSidePanel(true);
      dispatch({type: SET_ACTIVE_TOOL + mapType, tool: widgetTypes.overview})
      dispatch({type: SET_ACTIVE_SLIDEOUT_TOOL + mapType, tool: widgetTypes.overview})
      dispatch({type: SHOW_SIDE_PANEL + mapType})
    }
    else {
      toggleSidePanel(false);
      dispatch({type: SET_ACTIVE_TOOL + mapType, tool: widgetTypes.move})
      dispatch({type: SET_ACTIVE_SLIDEOUT_TOOL + mapType, tool: widgetTypes.overview})
    }
  }

  function replaceDeleteWarningMessage() {
    if (editWidget.messages) {
      editWidget.messages.deleteWarningMessage = 'Deleting this feature may affect survey areas and ' +
      'surveys plans tied to the feature. Do you want to continue the action?'
    }
  }

  function setSubmitButtonListeners(watchUtils:any) {
    const button = editWidget.container.getElementsByClassName("esri-editor__control-button")[0];
    if (button) {
      //For fields not shown or disabled in edit widget
      button.addEventListener('mousedown', () => {
        setTimeout(async () => {
          //Apply autovalues to non-visible or disabled fields
          applyAutoValuesToData(editWidget._featureForm.feature, applyAutoValuesRef.current)
          applyAutoValuesRef.current = {}
          //Set reshape autovalues if applicable
          if (editWidget.activeWorkflow.stepId === "editing-new-feature") {
            await refreshTokenIfExpired()
            watchUtils.whenEqualOnce(editWidget.activeWorkflow, "stepId", "awaiting-feature-creation-info", () => {
              //feature create: failure
              if(isFeatureUpdateFailed(editWidget)){
                setNotificationMessage('Feature has been not created.', false)
              } else{ //feature create: success
                setNotificationMessage('Feature has been created.', true)
              }
            })
          }
          else {
            await refreshTokenIfExpired()
            watchUtils.whenEqualOnce(editWidget.activeWorkflow, "stepId", "awaiting-feature-to-update", () => {
              //feature edit: failure
              if(isFeatureUpdateFailed(editWidget)){
                  setNotificationMessage('Feature has been not updated.', false)
                } else{ //feature edit: success
                  setNotificationMessage('Feature has been updated.', true)
                }
            })
          }
        }, 25)
      })
    }
  }

  const isFeatureUpdateFailed = (editWidget:any) => editWidget?._prompt !== null && (editWidget?._prompt?.message?.includes('Edits could not be saved') || editWidget?._prompt?.title?.includes('Oops, something went wrong')); 

  /**
   * Add notifications to edit widget that fire upon feature creation/update
   * @param displayMsg message to be displayed
   * @param isSuccess is the action success/failure   
   */
  function setNotificationMessage(displayMsg: any, isSuccess: boolean = true) {
    dispatch({
      type: SET_MAP_NOTIFICATION + mapPageMapType,
      message: displayMsg,
      success: isSuccess,
    });
  }

  /**
   * Apply autovalues for fields not shown or disabled in edit widget
   * @param button button element
   */
  function applyAutoValuesToData(feature:any, autoValues:{[index: string]:any}) {
    for (let field in autoValues) {
      if (originalAttributesRef.current[field] !== feature.attributes[field]) {
        break
      }
      //For date values which come in an array of [mm/dd/yyyy, h:mm:ss am/pm, epoch date] (use the epoch date)
      if (autoValues[field].length === 3) {
        feature.attributes[field] = autoValues[field][2]
      }
      else {
        feature.attributes[field] = autoValues[field]
      }
    }
  }

  /**
   * Summary: Add a red asterisk to mark the mandatory fields
   */
  function markMandatoryFields() {
    /** Create a new text component inside a span tag (to add colored asterisk) */
    const updateTextComponent = (textComponentToChange: HTMLElement, previousDisplayText: string) => {
      const newTextComponent = document.createElement("span")
      newTextComponent.innerHTML = previousDisplayText + `<span style="color: red">*</span>`;
      textComponentToChange.replaceWith(newTextComponent)
    }
    
    // get the settings for which fields are required
    const fields = settingsLayers[editWidget._featureForm.layer.layerId].Fields;
    const requiredInfo = fields.reduce((result: any, entry: any) => {
      result[entry.AliasName] = { IsRequired: entry.IsRequired, IsNullable: entry.IsNullable };
      return result;
    }, {});

    const form = Array.from(editWidget.container.getElementsByClassName("esri-feature-form__label"));
    form.forEach((field: any) => {
      // get the child component to see if its requried
      const textComponentToChange = field.childNodes[0];
      const aliasName = textComponentToChange.textContent.trim();

      if(requiredInfo.hasOwnProperty(aliasName)){
        if (requiredInfo[aliasName].IsRequired || !requiredInfo[aliasName].IsNullable){
          updateTextComponent(textComponentToChange, field.textContent);
        }
      }      
    })
  }

  /**
   * Replace date format hints/examples in editor widget with date format
   */
  function replaceDateFormatHints() {
      let dateFormatHints = editWidget.container.getElementsByClassName('esri-feature-form__date-format-hint')
      Array.from(dateFormatHints).forEach((hint:any) => {
        if (hint.innerText === '12/31/1969') {
          hint.innerText = 'MM/DD/YYYY'
        }
        else if (hint.innerText === '7:00:00 PM') {
          hint.innerText = 'H:MM:SS AM/PM'
        }
      })
  }

  function replaceOutsideRangeErrorMessage() {
    editWidget.viewModel.featureFormViewModel.messages.validationErrors.outsideRange = 'Value is outside of numerical range'
  }

  /**
   * Replace 'null' feature names with the name of the layer
   * on the awaiting update feature candidate/multiple selections page of the editor widget
   * */
  function replaceNullMultipleSelectionLabels() {
      let editorListScroller = editWidget.container.children[0].children[1].children[0].children[1].children
      let listItemCount = 0
      Array.from(editorListScroller).forEach((listGroup:any, i:any) => {
        let listItems = listGroup.children[1].children
        Array.from(listItems).forEach((listItem:any) => {
          let featureLabel = listItem.children[0].children[0]
          let layerName = editWidget.activeWorkflow.data.candidates[listItemCount].layer.sourceJSON.name
          listItemCount++
          if (featureLabel.innerText === 'null'){
            featureLabel.innerText = layerName
          }
        })
      })
  }

  /**
   * Configure fields for edit widget
   * @param watchUtils Module to watch for when fields get defined
   */
  function configFields(watchUtils:any, step:any ) {

    let numberFieldTypes = [
      fieldType.shortInteger,
      fieldType.longInteger,
      fieldType.float,
      fieldType.double
    ];

    let featureLayers = layers.items
    .slice()
    .reverse()
    .filter((layer:any) => layer.type === 'feature');
    // featureLayers = featureLayers.filter((layer:any) => layer.type === 'feature')
    let editConfigs: Array<any> = []
    
    for (let i = 0; i < featureLayers.length; i++) {
      if (featureLayers[i].type === layerType.feature) {
        watchUtils.whenDefined(featureLayers[i], 'fields', () => {
          if (featureLayers[i].fields) {
            let fields: Array<any> = []
            settingsLayers[i].Fields.forEach((settingField:any) => {
              featureLayers[i].fields.forEach((field:any) => {
                if (settingField.Name === field.name && 
                    (
                      (step === 'awaiting-feature-to-create' && settingField.VisibleOnCreate) ||
                      (step === 'awaiting-feature-to-update' && settingField.IsVisible)
                    )
                   )
                  {
                      fields.push({
                        name: field.name,
                        label: field.alias,
                        maxLength: field.length,
                        required: settingsLayers[i].DisplayFieldName === field.name && !field.nullable,
                        description: (numberFieldTypes.includes(settingField.FieldType) && settingField.IsNullable)? 'Please enter (or select) a value':'',
                        editable: settingField.IsEditable,
                        SortOrder: settingField.SortOrder,
                        FieldType: settingField.FieldType
                      })
                  }      
              })
            })
            fields = fields.sort((a : any, b : any) => (a.SortOrder > b.SortOrder) ? 1 : -1)
            editConfigs.push({
              layer: featureLayers[i],
              fieldConfig: fields
            })
          }
          editWidget.layerInfos = editConfigs
        })
      }
    }
  }

  /**
 * Summary: Call edit protection feature check
 * @param editWidget editor widget
 * @param mapType page mapType
 * @param dispatch dispatch from useDispatch
 */
  async function isAllowedToEdit(editWidget:any, mapType:string, dispatch: Function) {
    if (editWidget.viewModel.state === 'editing-existing-feature') {
      return await singleFeatureEditCheck(editWidget, mapType, dispatch)
    }
    else if (editWidget.viewModel.state === 'awaiting-update-feature-candidate') {
      return await multipleFeatureEditCheck(editWidget, mapType, dispatch)
    }
    return true
  }

/**
 Summary: Call edit protection feature check for a single feature
 * @param editWidget editor widget
 * @param mapType page mapType
 * @param dispatch dispatch from useDispatch
 * @returns true if allowed to edit, false otherwise
 */
function singleFeatureEditCheck(editWidget:any, mapType:string, dispatch: Function) {
  return new Promise(resolve => {
    const userID = securityRoles.Users.find((user:any) => user.UserName === loggedInUser).UserId
    let features: {[index: number]:any} = {}
    let objectId = editWidget._featureForm.feature.attributes[editWidget._featureForm.layer.objectIdField]

    features[editWidget._featureForm.layer.layerId] = [objectId]
    const featureCheck = dispatch(checkForEditProtection(
      userID,
      features,
      mapType
    ))
    featureCheck.then((res:any) => {
      if (res.message) {
        editWidget.activeWorkflow.previous()
        dispatch({
          type:SET_MAP_NOTIFICATION + mapPageMapType,
          message:`Edit protection error: ${res.message}`,
          success:false
        })
        resolve(false)
      }
      else if (!res?.data[0].objectsEdit[objectId]) {
        editWidget.activeWorkflow.previous()
        dispatch({
          type:SET_MAP_NOTIFICATION + mapPageMapType,
          message:"User does not have permission to edit this feature.",
          success:false
        })
        resolve(false)
      }
      resolve(true)
    })
  })
}

/**
 Summary: Call edit protection feature check for multiple features
 * @param editWidget editor widget
 * @param mapType page mapType
 * @param dispatch dispatch from useDispatch
 * @returns true if any feature is allowed to be edited, false otherwise
 */
function multipleFeatureEditCheck(editWidget:any, mapType:string, dispatch: Function) {
  return new Promise(resolve => {
    const userID = securityRoles.Users.find((user:any) => user.UserName === loggedInUser).UserId
    let features: {[index: number]:any} = {}
    let featureOrder: {[index: number]:any} = {}

    let layerIndex = 0
    editWidget.activeWorkflow.data.candidates.forEach((feature:any) => {
      if (feature.layer.layerId in features) {
        features[feature.layer.layerId].push(feature.attributes[feature.layer.objectIdField])
      }
      else {
        features[feature.layer.layerId] = [feature.attributes[feature.layer.objectIdField]]
        featureOrder[feature.layer.layerId] = layerIndex
        layerIndex++
      }
    })
    const featureCheck = dispatch(checkForEditProtection(
      userID,
      features,
      mapType
    ))
    featureCheck.then((res:any) => {
      if (res.message) {
        editWidget.activeWorkflow.previous()
        dispatch({
          type:SET_MAP_NOTIFICATION + mapPageMapType,
          message:`Edit protection error: ${res.message}`,
          success:false
        })
        resolve(false)
      }
      else {
        setTimeout(() => {
          let editorListScroller = editWidget.container.children[0].children[1].children[0].children[1]
          let featuresRemoved = false
  
          //Remove features from multiple feature selection page if they fail the edit protection check
          res.data.forEach((layerFeatures:any) => {
            let removedCount = 0
            Object.keys(layerFeatures.objectsEdit).forEach((objectId:string, index:number) => {
              if (!layerFeatures.objectsEdit[objectId]) {
                featuresRemoved = true
                const layerGroup = editorListScroller.children[featureOrder[layerFeatures.layerId]]
                layerGroup.children[1].removeChild(layerGroup.children[1].children[index - removedCount])
                removedCount++
                //Remove section for the layer if it becomes empty
                if (layerGroup.children[1].children.length === 0) {
                  editorListScroller.removeChild(layerGroup)
                }
              }
            })
            //Return to feature select page and give notification if no features are editable
            if (editorListScroller.children.length === 0) {
              editWidget.activeWorkflow.previous()
              dispatch({
                type:SET_MAP_NOTIFICATION + mapPageMapType,
                message:"User does not have permission to edit these features.",
                success:false
              })
              resolve(false)
            }
            else if (featuresRemoved) {
              dispatch({
                type:SET_MAP_NOTIFICATION + mapPageMapType,
                message:"User does not have permission to edit some of these features.",
                success:false
              })
            }
          })
        }, 25)
      }
      resolve(true)
    })
  })
}

  /**
   * Summary: Function to get values for autovalues applicable to current feature
   * @param feature feature being created/edited/reshaped
   * @param editOperation current operation (create/edit/reshape)
   * @returns autovalues values
   */
  function getAutoValueFields(feature:any, editOperation:editValueType) {
    const fields: {[index: string]:any} = {}
    const autoValues: {[index: string]:any} = {}
    const findAutoValueFields = (autoValueSettings:any, autoValueType:autoValueType) => {
      autoValueSettings.forEach((autoValue:any) => {
        if (autoValue.islayer && autoValue.ownerid === feature.layer.layerId && editOperation === autoValue.edittype) {
          fields[autoValue.fieldname] = autoValueType
        }
      })
    }
    findAutoValueFields(fieldAutoValues.usernameAutoValues, autoValueType.username)
    findAutoValueFields(fieldAutoValues.timestampAutoValues, autoValueType.timestamp)
    findAutoValueFields(fieldAutoValues.guidAutoValues, autoValueType.guid)
    findAutoValueFields(fieldAutoValues.latitudeAutoValues, autoValueType.latitude)
    findAutoValueFields(fieldAutoValues.longitudeAutoValues, autoValueType.longitude)
    
    if (isEmpty(fields)) {
      return {}
    }
    feature.layer.fields.forEach((field:any) => {
      //Converting field name to lowercase because autovalue field names from admin settings 
      //are all in lowercase (not sure why)
      if (Object.keys(fields).includes(field.name.toLowerCase())) {
        switch(fields[field.name.toLowerCase()]) {
          case autoValueType.username:
            autoValues[field.name] = loggedInUser
            break;
          case autoValueType.timestamp:
            const date = new Date()
            autoValues[field.name] = [date.toLocaleDateString(), date.toLocaleTimeString(), date.getTime()]
            break;
          case autoValueType.guid:
            autoValues[field.name] = `{${generateID()}}`
            break;
          case autoValueType.latitude:
            const latitude = getCoordinates(feature)[0]
            autoValues[field.name] = latitude || autoValues[field.name]
            break;
          case autoValueType.longitude:
            const longitude = getCoordinates(feature)[1]
            autoValues[field.name] = longitude || autoValues[field.name]
            break;
        }
      }
    })
    return autoValues
  }

  /**
   * Return feature's coordinate array in order of [latitude, longitude]
   * @param feature feature to get coordinates from
   * @returns coordinate array in order of [latitude, longitude]
   */
  function getCoordinates(feature:any) {
    switch(feature.geometry.type) {
      case geometryType.polygon:
        //Returns polygon's centroid coordinate
        return [
          feature.geometry.centroid.latitude, 
          feature.geometry.centroid.longitude
        ]
      case geometryType.point:
        return [
          feature.geometry.latitude, 
          feature.geometry.longitude
        ]
      default:
        return [null, null]
    }
  }

  useEffect(() => {
    if (editWidget) {
      if (currentTool !== widgetTypes.edit && activeSlideoutTool !== widgetTypes.edit && !showNearestFeatPanel) {
        if(editWidget.activeWorkflow) {
          editWidget.cancelWorkflow()
        }
        editWidget.container.style.display = 'none'
      }
      else if (!showNearestFeatPanel) {
        editWidget.container.style.display = 'initial'
        dispatch({type:SET_GEOMETRY + mapPageMapType, grids: [], lines: [], points: []})
        dispatch({type:SET_SEARCH_GEOMETRY + mapPageMapType, grids: [], lines: [], points: []})
      }
    }
  }, [currentTool, activeSlideoutTool])

  //The span around CloseIcon is for css priority purposes
  if (isToolAvailable(LanternTools.EDIT,roleId)) {
    return (
      <div>
        <button
          title="Edit"
          id="edit-btn"
          className={"widget-btn" + (currentTool===widgetTypes.edit?" btn-active ":" " + className)}
          onClick={handleClick}
        >
          <EditIcon/>
          <span className={(currentTool===widgetTypes.edit && noActiveWorkflow) ? "" : "hide"}>
            <CloseIcon 
              className={"edit-close-icon"} 
              onClick={() => closeWidget()}
            />
          </span>
        </button>
        <NearestFeature
          editWidget={editWidget}
          showNearestFeatModal={showNearestFeatModal}
          setShowNearestFeatModal={setShowNearestFeatModal}
          showNearestFeatPanel={showNearestFeatPanel}
          setShowNearestFeatPanel={setShowNearestFeatPanel}
          nearestFeatStepDone={nearestFeatStepDone}
          setNearestFeatStepDone={setNearestFeatStepDone}
          chosenFeatures={chosenFeatures}
          setChosenFeatures={setChosenFeatures}
          layers={layers}
          autoValues={autoValues}
          nearestFeatureLayerIds={nearestFeatureLayerIds}
          applyAutoValuesRef={applyAutoValuesRef}
        />
      </div>
    )
  }
  else {
    return null
  }
}