import React from 'react';

import { 
  Container, 
  StyledPanelSmallIcon, 
  StyledPanelMediumIcon,
  StyledPanelLargeIcon } from './ResizeIcons.styles';

type ISizeIconsProps = {
  activeSize: Size,
  setActiveSize: (size: Size) => any,
};

export enum Size {
  invisible = "0",
  small = "31",
  medium = "50",
  large = "75"
}

const ResizeIcons = ({
  activeSize,
  setActiveSize,
}: ISizeIconsProps) => {

  return (
    <Container>
      <StyledPanelLargeIcon
        onClick={() => { setActiveSize(Size.large); }}
        selected={activeSize === Size.large}
        width={"30px"}
      />

      <StyledPanelMediumIcon
        onClick={() => { setActiveSize(Size.medium); }}
        selected={activeSize === Size.medium}
        width={"30px"}
      />

      <StyledPanelSmallIcon
        onClick={() => { setActiveSize(Size.small); }}
        selected={activeSize === Size.small}
        width={"30px"}
      />
    </Container>
  );
};

export default ResizeIcons;
