import { refreshTokenIfExpired } from "utils/IdentityManagerUtils";
import ashxBaseUrl from ".";

export const getConfgurationForMapService = async (selectedMapService: any) => {
  const getConfgurationForMapServiceUrl = `/Configuration.ashx/configuration?mapserviceurl=${selectedMapService}`;
  return await ashxBaseUrl.get(getConfgurationForMapServiceUrl);
};

export const getActiveJobSurveyors = async (
  requestBody: Object,
  workAssignmentsTableIndex: any
) => {
  const getActiveJobSurveyorsUrl = `/Map.ashx/tables/${workAssignmentsTableIndex}/query`;
  return await ashxBaseUrl.post(getActiveJobSurveyorsUrl, requestBody);
};

  export const updateAssignedSurveyors = async (
    requestBody: Object,
    contentType?: string
  ) => {
    //refresh the ArcGIS Token if expired
    await refreshTokenIfExpired();
    const updateAssignedSurveyorsUrl = `/Editing.ashx/tables/commit`;
    return await ashxBaseUrl.post(updateAssignedSurveyorsUrl, requestBody, {
      headers: {
        "content-type": contentType,
      },
    });
  };

  export const createBufferToolInfoSchedule = async (
    requestBody: Object,
    contentType: string
  ) => {
    const createBufferToolInfoScheduleUrl = `/Configuration.ashx/schedule/bufferTool`;
    return await ashxBaseUrl.post(
      createBufferToolInfoScheduleUrl,
      requestBody,
      {
        headers: {
          "content-type": contentType,
        },
      }
    );
  };

  export const getStatusCountConfig = async (
    selectedMapServiceName: any,
    surveyWorkType: string
  ) => {
    const getStatusCountConfigUrl =
      `/View.ashx/surveyreview/statuscountconfiguration?` +
      `mapServiceName=${selectedMapServiceName}&surveyWorkType=${surveyWorkType}`;
    return await ashxBaseUrl.get(getStatusCountConfigUrl);
  };

  export const getFeatureCountConfig = async (
    selectedMapServiceName: any,
    surveyWorkType: string
  ) => {
    const getFeatureCountConfigUrl =
      `/View.ashx/surveyreview/featurecountconfiguration?` +
      `mapServiceName=${selectedMapServiceName}&surveyWorkType=${surveyWorkType}`;
    return await ashxBaseUrl.get(getFeatureCountConfigUrl);
  };