import { loadModules } from "esri-loader"
import { autoValueType } from "./EditWidget"
import { geometryType } from "common/Map/SelectFeatures"
import { reRegisterToken } from "utils/IdentityManagerUtils"
import { fieldType } from "utils/FieldsUtils"

/**
   * Visually autopopulate the new feature in the edit widget
   * @param editWidget esri editWidget
   * @param autoValues Dictionary of fields and values for autopopulating the new feature
   * @param applyAutovaluesRef useRef for fields to autopopulate the data (in applyAutoValuesToData)
   */
export function autopopulateValues(editWidget:any, autoValues:{[index: string]:any}, applyAutoValuesRef:any) {
  const featureForm = editWidget.container.getElementsByClassName("esri-editor__content esri-editor__scroller")[0]
  const featureFormLabels = editWidget.container.getElementsByClassName("esri-feature-form__label")
  let layerFieldConfig:Array<any> = []
  editWidget.layerInfos.forEach((info:any) => {
    if (info.layer.layerId === editWidget._featureForm.layer.layerId) {
      layerFieldConfig = info.fieldConfig
    }
  })

  layerFieldConfig.forEach((field:any, index:number) => {
    if (field.name in autoValues) {
      const input = featureFormLabels[index].getElementsByClassName("esri-input esri-feature-form__input")[0]
      //Expecting an array of three items, using the first two here because date field in edit tool 
      //is split in mm/dd/yyyy and h:mm:ss am/pm fields (third item is the epoch date used elsewhere)
      if (field.FieldType === fieldType.date && autoValues[field.name].length === 3) {
        input.value = autoValues[field.name][0]
        const secondInput = featureFormLabels[index].getElementsByClassName("esri-input esri-feature-form__input")[1]
        secondInput.value = autoValues[field.name][1]
      }
      //Set to no value if field is a dropdown and auto value doesn't match any of the coded values
      else if (input.classList.contains('esri-select') 
      && Array.from(input.children).every((child:any) => child.value !== autoValues[field.name])) {
        input.value = ""
      }
      else {
        input.value = autoValues[field.name]
      }
    }
  })
  applyAutoValuesRef.current = {...applyAutoValuesRef.current, ...autoValues}
  //Reset focus to top of the form
  featureForm.scrollTop = 0
}